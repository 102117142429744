import { IconButton, Stack } from "@chakra-ui/react";

import CustomErrorBoundary from "@/components/Common/ErrorComponent/CustomErrorBoundary";
import Icons from "@/components/Icons";

import TextIcon from "@/components/Icons/SvgIcons/TextIcon";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import EnrichmentResponse from "./EnrichmentResponse";
import { filterComponentMaps } from "./mappings";

const FilterModals = () => {
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );

  const updatePresetState = usePresetsStore((state) => state.updateState);

  const enrichmentName =
    selectedEnrichments?.enrichmentName as keyof typeof filterComponentMaps;

  const FilterComponent = filterComponentMaps[enrichmentName];

  if (!FilterComponent && !selectedEnrichments?.showResponse) return null;
  let modalWidth = null;
  if (FilterComponent && "modal" in FilterComponent) {
    modalWidth = FilterComponent.modal?.width;
  }

  return (
    <>
      <Stack
        bg="white"
        rounded={"8px"}
        m={2}
        width={modalWidth ?? "32em"}
        position={"absolute"}
        top={"5rem"}
        right={"2rem"}
        minH={"20rem"}
        maxH={"calc(100vh - 8rem)"}
        className="custom-shadow custom-scrollbar "
        height={"fit-content"}
        zIndex={10}
      >
        <div className="flex h-full min-h-[20rem] flex-col">
          <div className="flex justify-between border-b border-[#e7e8ec] p-4">
            <div className="flex items-center space-x-4">
              {FilterComponent ? (
                <FilterComponent.Icon />
              ) : (
                <TextIcon className="h-[17px]" />
              )}
              <p className="font-semibold">
                {selectedEnrichments?.showResponse
                  ? "Cell Details"
                  : selectedEnrichments?.name}
              </p>
            </div>
            <IconButton
              aria-label="Close modal"
              variant={"link"}
              icon={<Icons.CloseIcon size={20} className="text-primary" />}
              onClick={() => {
                useEnrichStore.setState({
                  selectedEnrichments: null,
                  examples: [
                    {
                      input: "",
                      output: "",
                    },
                  ],
                });
                updatePresetState({
                  presetsModalData: {},
                  selectedPresetValues: null,
                  endpoint: null,
                  body: null,
                  headers: [],
                  isOpenPresetsModal: false,
                  selectedMethod: null,
                });
              }}
            />
          </div>

          {/* Actual Component */}
          {selectedEnrichments?.showResponse ? (
            <EnrichmentResponse />
          ) : (
            <FilterComponent.Component />
          )}
        </div>
      </Stack>
    </>
  );
};

const WrappedFilterModals = () => (
  <CustomErrorBoundary>
    <FilterModals />
  </CustomErrorBoundary>
);

export default WrappedFilterModals;
