import { IconButton, Stack } from "@chakra-ui/react";
import { Reorder, useDragControls } from "framer-motion";
import { ComponentProps } from "react";
import VerticalDragSvg from "@/assets/svgs/drag-vertical.svg";

export const DraggableItem = ({
  children,
  isFirst,
  isLast,
  ...props
}: ComponentProps<typeof Reorder.Item> & {
  isFirst?: boolean;
  isLast?: boolean;
}) => {
  const controls = useDragControls();
  return (
    <Reorder.Item
      as="div"
      className="flex items-center"
      dragListener={false}
      dragControls={controls}
      dragElastic={0.01}
      transition={{
        type: "spring",
        stiffness: 15200,
        damping: 5000,
      }}
      {...props}
    >
      <Stack direction="row" className="w-full" gap={0}>
        <IconButton
          tabIndex={-1}
          aria-label="Drag"
          size="sm"
          variant={"ghost"}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="reorder-handle user-select-none h-full w-[6em] hover:!bg-white "
          onPointerDown={(e) => controls.start(e)}
          icon={
            <div className="flex w-full flex-row items-center justify-between gap-0 pr-2 text-[#6B7280] ">
              <img
                draggable={false}
                src={VerticalDragSvg}
                alt="2"
                className="size-[20px]"
              />
              <div className={isFirst ? "visible" : "hidden"}>First try</div>
              <div className={!isLast && !isFirst ? "visible" : "hidden"}>
                Then try
              </div>
              <div className={isLast && !isFirst ? "visible" : "hidden"}>
                Last &nbsp;try
              </div>
            </div>
          }
        />
        {children}
      </Stack>
    </Reorder.Item>
  );
};
