import dayjs from "dayjs";

import { userStore } from "@/stores/user.store";

const FreeTrial = () => {
  const currentUser = userStore((state) => state.currentUser);

  const userOldInDays = dayjs().diff(dayjs(currentUser?.createdAt), "day");
  const trailDayLeft = 14 - userOldInDays;

  const isPaid = currentUser?.planType?.toLowerCase() !== "free";

  if (isPaid) return null;

  return (
    <>
      {userOldInDays > 14 ? (
        <p className="text-left  text-[16px] font-semibold text-error">
          Your free trial has ended
        </p>
      ) : (
        <p className="text-left text-[13px] font-semibold text-dark">
          {trailDayLeft === 0 ? (
            <span className="font-bold text-error">
              Your free trial is expiring today
            </span>
          ) : (
            <>
              In{" "}
              <span className="font-bold text-error"> {trailDayLeft} days</span>
              , your free trial will expire
            </>
          )}
        </p>
      )}
    </>
  );
};

export default FreeTrial;
