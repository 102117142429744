import { CSSObjectWithLabel, ControlProps } from "react-select";

export const customStyles = {
  control: (provided: CSSObjectWithLabel, state: ControlProps) => ({
    ...provided,
    fontFamily: "Manrope",
    fontWeight: "400",
    fontSize: "14px",
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    minHeight: "20px",
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginTop: 0, // Adjust the top margin if needed
    paddingBottom: "100px",
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: "#0284FE", // Change the color of the arrow icon
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: "black", // Change the placeholder color
  }),
  option: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: "Manrope",
    fontSize: "14px",
    whiteSpace: "", // Wrap text
    fontWeight: "500",
  }),
};
