import SmartleadLogo from "@/assets/images/smartlead.png";
import APIKeyIntegration from "./APIKeyIntegration";

const SmartleadIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"smartLeadsApiKey"}
      providerTitle="Smartlead"
      providerIcon={SmartleadLogo}
    />
  );
};

export default SmartleadIntegration;
