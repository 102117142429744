import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import MultiSelectDropdownRefreshable from "@/components/Common/MultiSelectDropdown/Refreshable";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";
import { ObjectFieldsType } from "../CreateOrUpdateRecord";
import SaveAndRunEnrichment from "../../../Common/SaveAndRun";
import { OperatorOptions } from "./constants";
import CustomTextEditor from "@/components/Enrichment/Common/CustomTextEditor";
import { initialSlateValue } from "../../HttpApi/consts";
import SwitchWithDetails from "@/components/Enrichment/Common/SwitchWithDetails";
import ConditionalFormula from "@/components/Enrichment/Common/ConditionalFormula";
import { useEnrichStore } from "@/stores/enrich.store";

function SalesforceLookupRecord() {
  const [sObjects, setSObjects] = useState<Option[]>([]);
  const [sObjectFields, setSObjectFields] = useState<ObjectFieldsType[]>([]);
  const [selectedSObject, setSelectedSObject] = useState<Option | null>(null);
  const [selectedSObjectFields, setSelectedSObjectFields] = useState<Option[]>(
    [],
  );
  const [selectedSearchOperator, setSelectedSearchOperator] =
    useState<Option | null>(null);
  const [fieldsValue, setFieldsValue] = useState<Record<string, any>>({});
  const [isExactMatch, setIsExactMatch] = useState(false);

  const toast = useToast();
  const { data: providerList, isLoading } = useProviderList();
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;
  const salesforceProvider = providerList?.data?.find(
    (provider) => provider.name === "salesforce",
  );
  const isSalesforceConnected = salesforceProvider?.metaData;

  const handleConnect = async () => {
    providerService.openSalesforceOAuth();
  };

  const { mutateAsync: fetchSObjects, isPending: isFetchSObjectsLoading } =
    useMutation({
      mutationFn: (keyId: string) => {
        return providerService.getSalesForceObjects(keyId);
      },
      onSuccess: (response) => {
        if (response?.success) {
          setSObjects(response?.data);

          if (viewMode === "edit") {
            if (enrichmentData) {
              const selectedObject = response?.data.find(
                (obj: any) => obj.value === enrichmentData.sObjectName,
              );
              setSelectedSObject(selectedObject);

              fetchSObjFields({
                keyId: salesforceProvider?._id || "",
                objName: selectedObject.value,
              });
            }
          }
        } else {
          toast({
            // @ts-ignore
            title: response?.message || "Something went wrong.",
            status: "error",
            isClosable: true,
            position: "top-right",
          });
        }
      },
    });

  const { mutateAsync: fetchSObjFields, isPending: isFetchingSObjFields } =
    useMutation({
      mutationFn: ({ keyId, objName }: { keyId: string; objName: string }) => {
        return providerService.getSalesForceFields({
          keyId,
          objectName: objName,
        });
      },
      onSuccess: (response) => {
        if (response?.success) {
          setSObjectFields(response?.data || []);

          if (viewMode === "edit") {
            if (enrichmentData) {
              const selectedFields = response?.data?.filter((field: any) => {
                return enrichmentData.fields.includes(field.name);
              });

              const selectedSearchOperator = OperatorOptions.find(
                (operator) => operator.value === enrichmentData.searchOperator,
              );

              setSelectedSObjectFields(
                selectedFields.map((field: any) => ({
                  label: field.displayName,
                  value: field.name,
                })),
              );

              setIsExactMatch(enrichmentData.isExactMatch || false);
              setSelectedSearchOperator(selectedSearchOperator || null);

              setTimeout(() => {
                setFieldsValue(enrichmentData.fieldValues);
              }, 150);
            }
          }
        } else {
          toast({
            // @ts-ignore
            title: response?.message || "Something went wrong.",
            status: "error",
            isClosable: true,
            position: "top-right",
          });
        }
      },
    });

  const handleObjectChange = async (value: Option) => {
    setSelectedSObject(value);
    setSelectedSObjectFields([]);
    setSObjectFields([]);
    await fetchSObjFields({
      keyId: salesforceProvider?._id || "",
      objName: value.value,
    });
  };

  useEffect(() => {
    if (isSalesforceConnected) {
      fetchSObjects(salesforceProvider?._id || "");
    }
  }, [providerList]);

  return (
    <>
      <div className="grow overflow-y-auto p-4 font-title space-y-6">
        {!isSalesforceConnected && (
          <button
            className={`w-full py-1 text-center bg-primary text-white rounded-md font-semibold`}
            disabled={isLoading}
            onClick={() => {
              handleConnect();
            }}
          >
            Connect to Salesforce
          </button>
        )}

        <MultiSelectDropdownRefreshable
          label="Select Salesforce Object"
          description="The object type to look for in your Salesforce instance."
          onRefresh={() => {
            if (isSalesforceConnected) {
              fetchSObjects(salesforceProvider._id);
            }
          }}
          options={sObjects}
          isLoading={isFetchSObjectsLoading || isFetchingSObjFields}
          isRefreshing={isFetchSObjectsLoading}
          selectedObject={selectedSObject}
          onSelect={(value) => {
            handleObjectChange(value as Option);
          }}
          refreshLabel="Refresh Objects"
          isDisabled={!isSalesforceConnected}
        />

        <MultiSelectDropdownRefreshable
          label="Select Object Field(s)"
          description="The field(s) to lookup."
          onRefresh={() => {
            if (selectedSObject) {
              fetchSObjFields({
                keyId: salesforceProvider?._id || "",
                objName: selectedSObject.value,
              });
            }
          }}
          options={
            sObjectFields?.map((field) => ({
              label: field.displayName,
              value: field.name,
            })) as Option[]
          }
          isLoading={isFetchingSObjFields}
          isRefreshing={isFetchingSObjFields}
          selectedObject={selectedSObjectFields}
          onSelect={(value) => {
            const selectedFields = value as Option[];

            setSelectedSObjectFields(selectedFields);
            setFieldsValue(
              selectedFields?.reduce((acc, field) => {
                (acc as Record<string, any>)[field.value] = initialSlateValue;
                return acc;
              }, {}),
            );
          }}
          refreshLabel="Refresh Fields"
          isDisabled={!selectedSObject}
          isMulti={true}
        />

        {selectedSObjectFields?.length > 0 && (
          <>
            {selectedSObjectFields.length > 1 && (
              <MultiSelectDropdownRefreshable
                label="Select Search Operator"
                description="AND requires all fields to match, OR requires at least one field to match. By default, we do OR."
                options={OperatorOptions}
                selectedObject={selectedSearchOperator}
                onSelect={(value) => {
                  setSelectedSearchOperator(value as Option);
                }}
              />
            )}

            {selectedSObjectFields.map((field) => (
              <div>
                <p className="pb-1 text-base font-semibold">
                  {field?.label} to search for
                </p>
                <CustomTextEditor
                  editorHeight={"4rem"}
                  slateValue={fieldsValue[field.value] || initialSlateValue}
                  setSlateValue={(value) => {
                    setFieldsValue({
                      ...fieldsValue,
                      [field.value]: value,
                    });
                  }}
                  placeholder={`Start typing or use the dropdown to select a column.`}
                  autoFocus={false}
                />
              </div>
            ))}

            <SwitchWithDetails
              label="Exact Match?"
              description="When looking for an item in SFDC, look for an exact match in all search fields. By default, we do a contains search for all search fields."
              isChecked={isExactMatch}
              onChange={(e) => setIsExactMatch(e.target.checked)}
            />
          </>
        )}

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={
          !isSalesforceConnected ||
          !selectedSObject ||
          !selectedSObjectFields.length
        }
        apiPayload={{
          sObjectName: selectedSObject?.value,
          fields: selectedSObjectFields.map((field) => field.value),
          fieldValues: fieldsValue,
          searchOperator: selectedSearchOperator?.value,
          isExactMatch,
        }}
      />
    </>
  );
}

export default SalesforceLookupRecord;
