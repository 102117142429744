import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useMiscData } from "@/hooks/useMisc";

interface Props {
  value: Option | Option[];
  onChange: (selectedValues: Option[]) => void;
  label?: string;
}

const Seniorities = ({ value, onChange, label }: Props) => {
  const { data, isLoading } = useMiscData<Option[]>("seniorities");

  const options = data?.data || [];

  const handleChange = (selected: Option | Option[]) => {
    const selectedValues = Array.isArray(selected) ? selected : [selected];
    onChange(selectedValues as Option[]);
  };

  return (
    <>
      <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
        {label || "Seniorities"}
      </p>
      <MultiSelectDropdown
        placeholder={`e.g. ${options[0]?.label || ""}`}
        options={options}
        onChange={handleChange}
        closeMenuOnSelect
        value={value}
        isLoading={isLoading}
        isCreateAble={false}
      />
    </>
  );
};

export default Seniorities;
