import { twMerge } from "tailwind-merge";

type Props = {
  name: string;
  labelClassName: string;
  label: string;
  description: string;
  optionComponent: any;
};

const FieldDetails = ({
  name,
  labelClassName,
  label,
  description,
  optionComponent,
}: Props) => {
  return (
    <>
      <div className="flex items-center justify-between gap-x-3 pb-2">
        <label
          htmlFor={name}
          className={`${twMerge("smallTitle block", labelClassName)}`}
        >
          {label}
        </label>
        {optionComponent ? optionComponent : null}
      </div>
      {description && (
        <span className="block pb-1 font-title text-sm font-medium text-[#141313b2]">
          {description}
        </span>
      )}
    </>
  );
};

export default FieldDetails;
