import userService from "@/services/user.service";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

const useAdminAccessCheck = (): UseQueryResult<
  {
    isAccess: true;
  },
  Error
> => {
  return useQuery({
    queryKey: ["adminAccessCheck"],
    queryFn: async () => userService.getAdminAccessCheck(),
  });
};

export default useAdminAccessCheck;
