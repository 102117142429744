import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Switch,
  useDisclosure,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import Icons from "@/components/Icons";

import { tableActionIcons } from "@/components/Icons/tableActionIcons";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";

const ColumnList = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useToast({
    position: "top-right",
  });
  const popoverRef = useRef(null);
  useOutsideClick({
    ref: popoverRef,
    handler: () => {
      onClose();
    },
  });
  const { mutateAsync: updateViewData, isPending } = useMutation({
    mutationFn: async (
      payload: Parameters<typeof tableService.createOrUpdateView>[0],
    ) => tableService.createOrUpdateView(payload),
  });

  const { mutateAsync: deleteColumns, isPending: isDeleting } = useMutation({
    mutationFn: ({
      tableId,
      columnIds,
    }: {
      tableId: string;
      columnIds: string[];
    }) => tableService.deleteColumns(tableId, columnIds),
  });

  const [selectedColIds, setSelectedColIds] = useState<string[]>([]);

  const columns = useTableStore((state) => state.tableData.columns);
  const updateState = useTableStore((state) => state.updateState);
  const tableData = useTableStore((state) => state.tableData);
  const selectedViewId = useTableStore(
    (state) => state.tableData.selectedViewId,
  );
  const tableViews = useTableStore((state) => state.tableData.views || []);
  const updateViews = useTableStore((state) => state.updateViews);

  const [draggedColumn, setDraggedColumn] = useState<number | null>(null);
  const [draggedColumnId, setDraggedColumnId] = useState<string>("");
  const [columnOrder, setColumnOrder] = useState(columns);

  const tableId = tableData._id;
  const currentView = tableViews.find((view) => view._id === selectedViewId)!;

  const handleColumnAction = async (columnId: string, isChecked: boolean) => {
    const updatedColumns = columns.map((column) => {
      if (column._id === columnId) {
        column.metaData = { ...(column.metaData || {}), isHidden: !isChecked };
      }
      return column;
    });
    updateState({
      tableData: {
        ...tableData,
        columns: updatedColumns,
      },
    });

    const hideColumns = currentView?.hideColumnIds || [];

    if (isChecked) {
      hideColumns.splice(hideColumns.indexOf(columnId), 1);
    } else {
      hideColumns.push(columnId);
    }

    updateViewData(
      {
        tableId,
        viewId: selectedViewId,
        bodyData: {
          hideColumnIds: hideColumns,
        },
      },
      {
        onSuccess: (response) => {
          if (response.data) {
            updateViews(response.data);
          }
        },
        onError: () => {
          toast({
            position: "top-right",
            title: "Failed to update column visibility",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );

    // await mutateAsync({
    //   tableId: tableData._id,
    //   payload: [
    //     {
    //       columnId,
    //       column: {
    //         metaData: {
    //           isHidden: !isChecked,
    //         },
    //       },
    //     },
    //   ],
    // });
  };

  const onDragStart = (index: number) => {
    setDraggedColumn(index);
  };

  const onDragOver = (index: number) => {
    const draggedOverColumn = columnOrder[index];
    setDraggedColumnId(draggedOverColumn?._id || "");
    const columns = columnOrder.filter((_, idx) => idx !== draggedColumn);
    columns.splice(index, 0, columnOrder[draggedColumn || 0]);

    setDraggedColumn(index);
    setColumnOrder(columns);
  };

  const onDragEnd = async () => {
    updateState({
      tableData: {
        ...tableData,
        columns: columnOrder,
      },
      movedColumnIndex: draggedColumn,
    });

    const movedIndex = Math.max(draggedColumn || 0, 0);
    const _columnOrder = currentView?.columnOrder || [];

    if (_columnOrder.length) {
      const foundIndex = _columnOrder.findIndex(
        (column) => column.position === movedIndex,
      );
      if (foundIndex !== -1) {
        _columnOrder[foundIndex].position = movedIndex + 1;
      }

      const foundColumn = _columnOrder.find(
        (column) => column.columnId === draggedColumnId,
      );

      if (foundColumn) {
        foundColumn.position = movedIndex;
      } else {
        _columnOrder.push({
          columnId: draggedColumnId,
          position: movedIndex,
        });
      }
    } else {
      _columnOrder.push({
        columnId: draggedColumnId,
        position: movedIndex,
      });
    }

    updateViewData(
      {
        tableId,
        viewId: selectedViewId,
        bodyData: {
          columnOrder: _columnOrder,
        },
      },
      {
        onSuccess: (response) => {
          console.log("response", response);

          if (response.data) {
            updateViews(response.data);
          }
        },
        onError: () => {
          toast({
            position: "top-right",
            title: "Failed to update column position",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
    setDraggedColumn(null);

    setTimeout(() => {
      updateState({
        movedColumnIndex: null,
      });
    }, 2000);
  };

  const handleDeleteColumns = useCallback(
    async (tableId: string, columnIds: string[]) => {
      await deleteColumns(
        {
          tableId,
          columnIds,
        },
        {
          onSuccess: (response) => {
            if (response.success == false) {
              toast({
                title: "Something went wrong",
                description: response.error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
              return;
            }

            const updatedColumns = columns.filter(
              (column) => !columnIds.includes(column._id),
            );
            updateState({
              tableData: {
                ...tableData,
                columns: updatedColumns,
              },
            });
            setSelectedColIds([]);
          },
          onError: (error) => {
            toast({
              title: "Something went wrong",
              description: error.message,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    },
    [columns, deleteColumns, tableData, toast, updateState],
  );

  const activeColumnsLength =
    columns?.filter((column) => !column.metaData?.isHidden).length ?? 0;

  useEffect(() => {
    setColumnOrder(columns ?? []);
  }, [columns ?? null]);
  if (!columns) return null;
  return (
    <Popover
      placement="auto-end"
      closeOnBlur
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
      closeOnEsc
    >
      <PopoverTrigger>
        <Button
          fontWeight={500}
          size={"sm"}
          colorScheme="primary"
          variant={"ghost"}
          className=" gap-2 !text-black"
        >
          <Icons.ColumnIcon size={10} className="cursor-pointer" />
          <span className="!text-xs">
            {activeColumnsLength}/{columns.length} Columns
          </span>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          ref={popoverRef}
          className="!w-fit min-w-[fit-content] !p-3"
        >
          <PopoverArrow />
          <PopoverBody className="w-fit">
            <div className="flex justify-end">
              {isPending && <Spinner size={"sm"} />}
            </div>
            <div className="mb-2 hidden cursor-pointer items-center gap-2 rounded-[3px] px-2 py-[2px] hover:bg-slate-100">
              <Icons.OpenEyeIcon />
              <p>View all columns</p>
            </div>
            <div className="hidden cursor-pointer items-center gap-2 rounded-[3px] px-2 py-[2px] hover:bg-slate-100">
              <Icons.CloseEyeIcon />
              <p>Hide all columns</p>
            </div>
            <Divider className="my-3 hidden" />
            {selectedColIds.length > 0 && (
              <Button
                className="mb-2 !h-[30px] w-full"
                colorScheme="red"
                onClick={() => {
                  handleDeleteColumns(tableData._id, selectedColIds);
                }}
                isLoading={isDeleting}
              >
                <Icons.DeleteIcon className="mr-2" />
                <span>Delete</span>
              </Button>
            )}
            <div className="columns max-h-[300px] w-fit overflow-y-auto">
              {columnOrder.map((column, index) => {
                const HeaderIcon =
                  tableActionIcons[column?.metaData?.iconType || "text"] ??
                  tableActionIcons.text;

                return (
                  <FormControl
                    key={column._id}
                    className={`mb-2 flex w-fit cursor-grab items-center justify-between gap-3 rounded-[3px] px-2 py-[2px] hover:bg-slate-100${draggedColumn === index ? "border-2 border-dotted border-black !bg-primaryLightPink" : ""}`}
                    draggable
                    onDragStart={() => onDragStart(index)}
                    onDragOver={() => onDragOver(index)}
                    onDragEnd={onDragEnd}
                  >
                    <Checkbox
                      checked={selectedColIds.includes(column._id)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSelectedColIds((prev) => {
                          if (isChecked) {
                            return [...prev, column._id];
                          } else {
                            return prev.filter((id) => id !== column._id);
                          }
                        });
                      }}
                      size={"lg"}
                    />
                    <div className="drag-icon mr-2 cursor-grab">
                      <Icons.DragIcon />
                    </div>
                    <FormLabel
                      className="!m-0 flex w-full cursor-grab items-center justify-between gap-2"
                      htmlFor={column._id}
                    >
                      <div className="flex items-center gap-1">
                        {column?.metaData?.iconImg ? (
                          <img
                            src={column?.metaData?.iconImg}
                            alt={column?.metaData?.iconType}
                            className="h-[15px]"
                          />
                        ) : (
                          <HeaderIcon className="h-[15px]" />
                        )}
                        <p className="max-w-[200px] text-ellipsis">
                          {column.name}
                        </p>
                      </div>
                    </FormLabel>
                    <Switch
                      size="md"
                      isChecked={!column.metaData?.isHidden}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleColumnAction(column._id, e.currentTarget.checked);
                      }}
                      disabled={isPending}
                      id={column._id}
                    />
                  </FormControl>
                );
              })}
            </div>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ColumnList;
