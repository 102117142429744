import { useQuery } from "@tanstack/react-query";

import leadService from "@/services/leads.service";

export const useGetHubspotLists = () => {
  return useQuery({
    queryKey: ["hubspot-lists"],
    queryFn: () => leadService.getHubspotLists(),
    refetchOnMount: true,
    gcTime: 0, // 1 day
    staleTime: 10, // 1 day
  });
};
