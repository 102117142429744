import { Box } from "@chakra-ui/react";
import CreditUsageGrid from "./list";

const ProvidersCreditUsage = () => {
  return (
    <Box className="mt-4">
      <CreditUsageGrid />
    </Box>
  );
};

export default ProvidersCreditUsage;
