import React from "react";
import { Switch } from "@chakra-ui/react";

interface Props {
  label: string;
  description: React.ReactNode;
  isOptional?: boolean;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function SwitchWithDetails({
  label,
  description,
  isOptional = true,
  isChecked,
  onChange,
}: Props) {
  return (
    <div>
      <div className="w-full flex justify-between items-center pb-0.5">
        {label && (
          <p className="font-semibold">
            {label}{" "}
            {isOptional && (
              <span className="text-[13px] text-[#525a69]">( Optional )</span>
            )}
          </p>
        )}
        <Switch isChecked={isChecked} onChange={(e) => onChange(e)} />
      </div>
      <p className="text-xs font-medium text-[#717989]">{description}</p>
    </div>
  );
}

export default SwitchWithDetails;
