import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useGridFilter } from "../DataTable/Table/hooks/useGridFilter";

const statusOptions = [
  {
    label: "Show all",
    value: "all",
  },
  {
    label: "Changed Company",
    value: "changed_company",
  },
  {
    label: "Change Title",
    value: "change_title",
  },
  {
    label: "No Change",
    value: "no_change",
  },
  {
    label: "Newly Added",
    value: "newly_added",
  },
  {
    label: "No Profile Found",
    value: "error",
  },
];
export const StatusFilter = () => {
  const defaultOption = useMemo(() => statusOptions[0], []);
  const { applyFilters } = useGridFilter();
  const [selectedOption, setSelectedOption] =
    useState<(typeof statusOptions)[number]>(defaultOption);
  useEffect(() => {
    if (selectedOption.value === "all") {
      applyFilters(null);
    } else {
      const filter = {
        action: {
          filterType: "text",
          type: "contains",
          filter: selectedOption.value,
        },
      };
      applyFilters(filter);
    }
  }, [selectedOption]);
  return (
    <Select
      defaultValue={defaultOption}
      options={statusOptions}
      value={selectedOption}
      onChange={(e) => {
        setSelectedOption(e ?? defaultOption);
      }}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          paddingLeft: "1rem",
          minWidth: "10em",
          backgroundColor: "transparent",
          border: "#E2E8F0 gray solid",
          borderRadius: "20px",
          boxShadow: "none",
          "&:hover": {},
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          ...(state.isFocused || state.isSelected
            ? {
                backgroundColor: "#ede0fa",
                color: "black",
              }
            : {}),
        }),
      }}
    />
  );
};
