import { useCallback, useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import OtherOptions from "./OtherOptions";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { REGEX, jobOpeningsTutorialLink } from "../../constants";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";

import type { TSelectedColumnOption } from "@/types/common.types";
import type { Option } from "@/components/Common/MultiSelectDropdown/types";
import ConditionalFormula from "../../Common/ConditionalFormula";

interface EnrichmentDataType extends EnrichmentMetaData {
  categories?: string[];
  jobDescriptions?: string[];
  jobTitles?: string[];
}

const JobOpenings = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<{
    categories: Option[];
    jobTitles: Option[];
    jobDescriptions: Option[];
  }>({
    categories: [],
    jobTitles: [],
    jobDescriptions: [],
  });
  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const updateSelectedFilters = useCallback((key: string, value: Option[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const normalizeStates = () => {
    setSelectedOption(null);
    setSelectedFilters({
      categories: [],
      jobTitles: [],
      jobDescriptions: [],
    });
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;
      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        if (enrichmentData.categories) {
          updateSelectedFilters(
            "categories",
            enrichmentData.categories.map((item) => ({
              value: item,
              label: item,
            })),
          );
        }
        if (enrichmentData.jobTitles) {
          updateSelectedFilters(
            "jobTitles",
            enrichmentData.jobTitles.map((item) => ({
              value: item,
              label: item,
            })),
          );
        }
        if (enrichmentData.jobDescriptions) {
          updateSelectedFilters(
            "jobDescriptions",
            enrichmentData.jobDescriptions.map((item) => ({
              value: item,
              label: item,
            })),
          );
        }
      }
    } else {
      normalizeStates();
      const initialColumn = getInitialColumn(REGEX.WEBSITE);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  return (
    <>
      {/* <EnrichmentResultBox/> */}
      <div className="flex-grow overflow-y-auto p-4 min-h-[40vh]">
        <WatchTutorial
          subText={
            "Watch the tutorial to learn how to get job openings for your leads."
          }
          link={jobOpeningsTutorialLink}
        />
        <p className="pb-1 text-base font-semibold">Website URL</p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <OtherOptions
          updateSelectedFilters={updateSelectedFilters}
          selectedFilters={selectedFilters}
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId || "",
          categories: selectedFilters.categories.map((item) => item.value),
          jobTitles: selectedFilters.jobTitles.map(
            (item: Option) => item.value,
          ),
          jobDescriptions: selectedFilters.jobDescriptions.map(
            (item) => item.value,
          ),
        }}
      />
    </>
  );
};

export default JobOpenings;
