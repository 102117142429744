import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { Controls, MarkerType, Node, ReactFlow } from "@xyflow/react";
import { useNavigate } from "@tanstack/react-router";
import "@xyflow/react/dist/style.css";

// import Walkthrough from "./Walkthrough";
import SlackNotifications from "../Common/AutoPilot/SlackNotifications/SlackNotifications";
import PersanaTable from "../Common/AutoPilot/PersanaTable/PersanaTable";
import HubspotStorage from "../Common/AutoPilot/HubspotStorage/HubspotStorage";
import CompanyDetailsSourceNode from "./CompanyDetailsSourceNode/CompanyDetailsSourceNode";

import Icons from "../Icons";
import { createSearchLeadsStore, TabContext } from "../PeopleSearch/store";
import { useTableList } from "@/hooks/useTableList";
import Walkthrough from "./Walkthrough";
import LGMStorage from "../Common/AutoPilot/LGMStorage/LGMStorage";

const AUTO_PILOT_NODE_ID = {
  COMPANY_DETAILS_SOURCE: "companyDetailsSource",
  POSTS_ENGAGEMENT_TABLE: "postsEngagementTable",
  HUBSPOT_STORAGE: "hubspotStorage",
  LGM_STORAGE: "lgmStorage",
  SLACK_NOTIFICATIONS: "slackNotifications",
};

const activeColor = "#078A52";

const activeStrokeStyle = {
  strokeWidth: 2,
  stroke: activeColor,
};

const INITIAL_TOOLBAR_STATE = {
  [AUTO_PILOT_NODE_ID.COMPANY_DETAILS_SOURCE]: false,
  [AUTO_PILOT_NODE_ID.POSTS_ENGAGEMENT_TABLE]: false,
  [AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE]: false,
  [AUTO_PILOT_NODE_ID.LGM_STORAGE]: false,
  [AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS]: false,
};

const AutoPilotPostEngagementWorkflow: React.FC = () => {
  const navigate = useNavigate();
  const { data: allTables, refetch } = useTableList();
  const autoPilotPostEngagementTable = allTables?.find(
    (item) => item.workflowType === "post-engagement",
  );
  const isConfigured = !!autoPilotPostEngagementTable;
  const [needHelp, setNeedHelp] = useState(false);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState([
    {
      id: "e1-2",
      source: AUTO_PILOT_NODE_ID.COMPANY_DETAILS_SOURCE,
      target: AUTO_PILOT_NODE_ID.POSTS_ENGAGEMENT_TABLE,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
    {
      id: "e2-3",
      source: AUTO_PILOT_NODE_ID.POSTS_ENGAGEMENT_TABLE,
      target: AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
    {
      id: "e2-4",
      source: AUTO_PILOT_NODE_ID.POSTS_ENGAGEMENT_TABLE,
      target: AUTO_PILOT_NODE_ID.LGM_STORAGE,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
    {
      id: "e2-5",
      source: AUTO_PILOT_NODE_ID.POSTS_ENGAGEMENT_TABLE,
      target: AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
  ]);
  const [toolbarState, setToolbarState] = useState(INITIAL_TOOLBAR_STATE);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleNodeClick = (e: React.MouseEvent, node: Node) => {
    e.stopPropagation();
    setToolbarState(() => ({
      ...INITIAL_TOOLBAR_STATE,
      [node.id]: true,
    }));
  };

  const handleCanvasClick = () => {
    setToolbarState(INITIAL_TOOLBAR_STATE);
  };

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const nodeWidth = 100;
      const topMargin = 150;

      const startX = (containerWidth - nodeWidth) / 2.5;

      const isHubspotConfigured =
        autoPilotPostEngagementTable?.metaData?.storeOnHubspot !== undefined &&
        autoPilotPostEngagementTable?.metaData?.storeOnHubspot !== null;

      const isLGMConfigured =
        autoPilotPostEngagementTable?.metaData?.storeOnLGM !== undefined &&
        autoPilotPostEngagementTable?.metaData?.storeOnLGM !== null;

      const isSlackConfigured =
        autoPilotPostEngagementTable?.metaData?.sendSlackNotifications !==
          undefined &&
        autoPilotPostEngagementTable?.metaData?.sendSlackNotifications !== null;

      const updatedNodes: Node[] = [
        {
          id: AUTO_PILOT_NODE_ID.COMPANY_DETAILS_SOURCE,
          type: "customA",
          position: { x: startX, y: topMargin },
          data: {
            showToolbar: !isConfigured
              ? true
              : toolbarState[AUTO_PILOT_NODE_ID.COMPANY_DETAILS_SOURCE],
            isConfigured,
            tableData: autoPilotPostEngagementTable,
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.POSTS_ENGAGEMENT_TABLE,
          type: "customB",
          position: { x: startX, y: topMargin + 200 },
          data: {
            tableTitle: "Posts Engagement Table",
            showToolbar:
              toolbarState[AUTO_PILOT_NODE_ID.POSTS_ENGAGEMENT_TABLE],
            isDisabled: !isConfigured,
            tableData: autoPilotPostEngagementTable,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE,
          type: "customC",
          position: { x: startX - 550, y: topMargin + 450 },
          data: {
            isConfigured: isHubspotConfigured,
            isDisabled: !isConfigured,
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE],
            tableData: autoPilotPostEngagementTable,
            workflowType: "post-engagement",
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.LGM_STORAGE,
          type: "customD",
          position: { x: startX, y: topMargin + 450 },
          data: {
            isConfigured: isLGMConfigured,
            isDisabled: !isConfigured,
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.LGM_STORAGE],
            tableData: autoPilotPostEngagementTable,
            workflowType: "post-engagement",
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS,
          type: "customE",
          position: { x: startX + 550, y: topMargin + 450 },
          data: {
            isConfigured: isSlackConfigured,
            isDisabled: !isConfigured,
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS],
            tableData: autoPilotPostEngagementTable,
            refetch,
          },
        },
      ];

      setNodes(updatedNodes);

      const updatedEdges = edges.map((edge) => {
        if (
          edge.source === AUTO_PILOT_NODE_ID.COMPANY_DETAILS_SOURCE &&
          isConfigured
        ) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        if (
          edge.target === AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE &&
          isHubspotConfigured
        ) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        if (edge.target === AUTO_PILOT_NODE_ID.LGM_STORAGE && isLGMConfigured) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        if (
          edge.target === AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS &&
          isSlackConfigured
        ) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        return edge;
      });

      setEdges(updatedEdges);
    }
  }, [toolbarState, allTables, isConfigured, autoPilotPostEngagementTable]);

  return (
    <>
      <div className="flex items-center justify-between bg-white px-[32px] py-[8px]">
        <p className="text-[24px] font-bold leading-[32px]">
          Autopilot - Post Engagements
        </p>

        <ButtonGroup>
          <Button
            variant={"outline"}
            size={"sm"}
            onClick={() => setNeedHelp(true)}
          >
            Need help ?
          </Button>

          <Button
            size={"sm"}
            colorScheme="primary"
            variant="outline"
            leftIcon={<Icons.BackIcon />}
            className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
            onClick={() => {
              navigate({ to: "/auto-pilot" });
            }}
          >
            Go Back
          </Button>
        </ButtonGroup>
      </div>
      <div
        ref={containerRef}
        style={{ width: "calc(100vw - 80px)", height: "92vh" }}
        onClick={handleCanvasClick}
      >
        <TabContext.Provider value={createSearchLeadsStore("autopilot_1")}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={{
              customA: CompanyDetailsSourceNode,
              customB: PersanaTable,
              customC: HubspotStorage,
              customD: LGMStorage,
              customE: SlackNotifications,
            }}
            fitView={true}
            onNodeClick={handleNodeClick}
          >
            <Controls />
          </ReactFlow>
        </TabContext.Provider>
      </div>
      <Walkthrough needHelp={needHelp} setNeedHelp={setNeedHelp} />
    </>
  );
};

export default AutoPilotPostEngagementWorkflow;
