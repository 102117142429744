import { FileRoute } from "@tanstack/react-router";

import { DotLottiePlayer } from "@dotlottie/react-player";
import sorryEmoji from "@/assets/animated/sorryEmoji.lottie";

export const Route = new FileRoute("/closed-beta/").createRoute({
  component: BlockedPage,
});

function BlockedPage() {
  return (
    <section className="flex h-screen w-full items-center justify-center bg-white">
      <div className="max-w-[35rem] py-6 px-10 rounded-md shadow-lg flex flex-col items-center">
        <div className="w-[150px]">
          <DotLottiePlayer
            src={sorryEmoji}
            autoplay
            loop
            style={{ height: "auto", width: "auto" }}
          />
        </div>
        <p className="font-title text-center font-medium text-lg">
          Hey! Thanks for your interest in Persana AI - right now we are still
          in closed beta for our platform and are only allowing limited users on
          a rolling basis. We’ll add you to our waitlist and if you’d like
          expedited access please email{" "}
          <span className="text-primary">
            <a href="mailto:contact@persana.ai">contact@persana.ai</a>
          </span>
          .
        </p>
      </div>
    </section>
  );
}

export default BlockedPage;
