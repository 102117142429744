import { Spinner, Switch } from "@chakra-ui/react";
import { useState } from "react";

import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { useMutation } from "@tanstack/react-query";

const AutoRun = () => {
  const tableData = useTableStore((state) => state.tableData);

  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);

  const [isEnableAutoRun, setIsEnableAutoRun] = useState(
    () => !!tableData.metaData?.isEnableAutoRun,
  );
  /**
   * 
   *tableService.updateTableDataV2(tableData._id, {
      metaData: {
        isEnableAutoRun: e.target.checked,
      },
    });
   */
  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ tableId, enable }: { tableId: string; enable: boolean }) =>
      tableService.updateTableDataV2(tableId, {
        metaData: {
          isEnableAutoRun: enable,
        },
      }),
  });
  const handleAutoRunChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsEnableAutoRun(e.target.checked);

    updateTableMetaDta({
      isEnableAutoRun: e.target.checked,
    });
    mutateAsync(
      {
        tableId: tableData._id,
        enable: e.target.checked,
      },
      {
        onError: (error) => {
          console.log(error);
          setIsEnableAutoRun(!e.target.checked);
          updateTableMetaDta({
            isEnableAutoRun: !e.target.checked,
          });
        },
      },
    );
  };
  return (
    <div className="flex  cursor-pointer items-center ">
      <label
        className="flex cursor-pointer items-center gap-2 text-sm font-thin"
        htmlFor="auto-run-switch"
      >
        {!isPending ? (
          <Switch
            colorScheme="purple"
            isChecked={isEnableAutoRun}
            size="sm"
            onChange={handleAutoRunChange}
            id="auto-run-switch"
          />
        ) : (
          <Spinner color="#805ad6" size="sm" />
        )}
        Auto Run
      </label>
    </div>
  );
};

export default AutoRun;
