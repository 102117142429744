import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { RunningProcessesData } from "@/types/table.types";

type State = {
  isProcessing: boolean;
  processingData: RunningProcessesData[] | null;
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
  updateProcessingData: (data: RunningProcessesData) => void;
};

export const useProcessingStore = create<State & Actions>((set) => ({
  isProcessing: false,
  processingData: null,
  updateState: (payload) => set(() => ({ ...payload })),
  updateProcessingData: (data) =>
    set((state) => {
      const processingData = state.processingData || [];
      const updatedProcessingData = processingData.map((item) => {
        if (item._id === data._id) {
          return data;
        }
        return item;
      });
      return { processingData: updatedProcessingData };
    }),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("ProcessingStore", useProcessingStore);
}
