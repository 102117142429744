import { LegacyRef, MouseEvent } from "react";
import { Portal, SlideFade } from "@chakra-ui/react";

type Props = {
  dropdownBoundary: DOMRect | undefined;
  dropdownContentRef: LegacyRef<HTMLDivElement>;
  inputtedString: string;
  showOptions: boolean;
  arrayOfItems: any[];
  isDynamic: boolean;
  disabledCustomSelect: boolean;
  handleButtonClick: (e: MouseEvent<HTMLButtonElement>) => void;
  handleOptionButtonClick: (
    e: MouseEvent<HTMLButtonElement>,
    item: any,
  ) => void;
};

const OptionsPortal = ({
  dropdownBoundary,
  dropdownContentRef,
  inputtedString,
  showOptions,
  arrayOfItems,
  isDynamic,
  disabledCustomSelect,
  handleButtonClick,
  handleOptionButtonClick,
}: Props) => {
  return (
    <Portal>
      <div
        className="absolute z-[2000]"
        style={{
          top:
            dropdownBoundary &&
            dropdownBoundary?.y + dropdownBoundary?.height + 10,
          left: dropdownBoundary?.x,
          width: dropdownBoundary?.width,
        }}
        ref={dropdownContentRef}
      >
        <SlideFade in={inputtedString !== "" || showOptions} offsetY="20px">
          <div className="h-fit max-h-[10rem] w-full space-y-0.5 overflow-y-auto rounded-md bg-[#343841] p-2 text-white shadow">
            {arrayOfItems?.length === 0
              ? null
              : arrayOfItems?.map((item: any) => {
                  return (
                    <button
                      type="button"
                      key={item.name}
                      onClick={(e) => {
                        handleOptionButtonClick(e, item);
                      }}
                      className={`arraybutton inline-flex w-full items-center truncate rounded-md py-1.5 text-sm duration-300 hover:bg-white/10 ${
                        item?.logo_url ? "" : "pl-3"
                      }`}
                    >
                      {item?.logo_url && (
                        <span className="mr-2 block ">
                          <img
                            src={item?.logo_url}
                            alt=""
                            className="h-[2rem] w-[2rem] rounded-full"
                          />
                        </span>
                      )}
                      <span className="truncate">{item?.name}</span>
                    </button>
                  );
                })}
            {!isDynamic && (
              <button
                type="button"
                onClick={
                  disabledCustomSelect
                    ? () => {}
                    : (e) => {
                        handleButtonClick(e);
                      }
                }
                className="flex w-full items-center gap-x-1 rounded-md py-1.5 pl-3 pr-14 text-sm duration-300 hover:bg-white/10"
              >
                {disabledCustomSelect
                  ? arrayOfItems?.length === 0
                    ? "No Result Found"
                    : null
                  : `Add "${inputtedString}"`}
              </button>
            )}
          </div>
        </SlideFade>
      </div>
    </Portal>
  );
};

export default OptionsPortal;
