import Select from "react-select";
import { useRef } from "react";
import { Box, Button, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { Option } from "./Option";
import { useWaterfallStore } from "../store";
import { baseStyles } from "../styles";
import Icons from "@/components/Icons";

export const AddMoreWaterfall = () => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const selectRef = useRef<HTMLElement | null>(null);
  const providerOptions = useWaterfallStore((state) => state.providerOptions);
  const defaultProviders = useWaterfallStore((state) => state.defaultProviders);
  const setState = useWaterfallStore((state) => state.setState);
  useOutsideClick({
    ref: selectRef,
    handler: (e) => {
      if (isOpen && !selectRef.current?.contains(e.target as Node)) onClose();
    },
  });
  const selectedProviders = useWaterfallStore(
    (state) => state.selectedProviders,
  );

  return (
    <Box className="justify-space-between flex flex-row items-center gap-4">
      <Select
        // @ts-ignore
        ref={(ref) => (selectRef.current = ref)}
        menuPlacement="auto"
        menuIsOpen={isOpen}
        menuPortalTarget={document.body}
        components={{
          ValueContainer: () => (
            <Button
              height={"2em"}
              fontWeight={500}
              isDisabled={selectedProviders.length >= providerOptions.length}
              onClick={onToggle}
              className="flex-1"
              leftIcon={<Icons.PlusIcon className="size-5" />}
            >
              Add Provider
            </Button>
          ),
          Option,
          Input: () => null,
          IndicatorsContainer: () => null,
        }}
        isMulti={false}
        options={providerOptions.filter(
          (provider) =>
            !selectedProviders.find((p) => p.value === provider.value),
        )}
        value={null}
        className="flex-1 pl-[32px]"
        onChange={(chosen) => {
          onClose();
          if (chosen !== null && typeof chosen !== "undefined") {
            setState({
              selectedProviders: [...selectedProviders, chosen],
            });
          }
        }}
        styles={{
          control: (provided) => ({
            ...provided,
            border: "none",
            backgroundColor: "transparent",
          }),
          menu: (provided) => ({
            ...provided,
            ...baseStyles,
            zIndex: 9999,
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
      <Button
        fontWeight={500}
        height={"2em"}
        className="flex-1"
        onClick={() =>
          setState({
            selectedProviders: defaultProviders,
            initialUserKeys: null,
            chosenUserKeys: [],
            chosenNonUserKeys: [],
          })
        }
        leftIcon={<Icons.UndoIcon className="text-normal size-[1em]" />}
      >
        Use Default
      </Button>
    </Box>
  );
};
