import _ from "lodash";
import { useEffect, useRef, useCallback } from "react";

export const useDebounce = <T extends (...args: any) => any>(
  delay: number,
  cb: T,
) => {
  const debouncedCbRef = useRef<T>(cb);
  useEffect(() => {
    debouncedCbRef.current = cb;
  }, [cb]);

  return useCallback(
    _.debounce((...args) => debouncedCbRef.current(...args), delay, {
      leading: false,
      trailing: true,
    }),
    [delay],
  );
};
