export const OperatorOptions = [
  {
    label: "AND",
    value: "AND",
  },
  {
    label: "OR",
    value: "OR",
  },
];
