import { useMemo } from "react";
import _ from "lodash";
import { IconButton, ButtonGroup, Tooltip, Kbd } from "@chakra-ui/react";
import { DeleteIcon, CopyIcon, InfoIcon } from "@chakra-ui/icons";
import { useCopyAsCSV } from "../useCopyCSV";
import { useSelectionStore } from "../useSelectionStore";
import { useDeleteSelected } from "../useDelete";
const CopyToolTip = () => {
  const modKey = useMemo(() => {
    if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
      return "⌘";
    } else {
      return "Ctrl";
    }
  }, []);
  return (
    <div className="flex flex-col gap-2">
      <span className="flex flex-row items-center justify-between">
        <p>Copy as TSV</p>
        <span className=" ml-1">
          <Kbd color="black">{modKey} + C</Kbd>
        </span>
      </span>
      <span className="flex flex-row items-center justify-between">
        <span>
          <InfoIcon className="mr-2" />
          To copy as csv use
        </span>{" "}
        <Kbd className="ml-2 text-black">{modKey} + Shift + C</Kbd>
      </span>
    </div>
  );
};
export const OverlayActions = () => {
  const isSelecting = useSelectionStore((state) => state.isSelecting);
  const range = useSelectionStore((state) => state.range);
  const clearSelection = useSelectionStore((state) => state.clearSelection);
  const copyAsCSV = useCopyAsCSV();
  const deleteSelection = useDeleteSelected();
  const isSingleCell = useMemo(
    () => _.isEqual(range?.startCell, range?.endCell),
    [range],
  );

  if (!range || isSingleCell || isSelecting) return null;
  return (
    <ButtonGroup
      id="overlay-actions"
      position="absolute"
      top="10px"
      right="10px"
      pointerEvents="auto"
      isAttached
      onMouseDown={(e) => {
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <IconButton
        cursor="pointer"
        color="#b91c1c"
        size={"sm"}
        aria-label="Open actions"
        onClick={() => {
          deleteSelection();
          clearSelection();
        }}
        icon={<DeleteIcon />}
      />
      <Tooltip
        openDelay={500}
        className="!rounded"
        hasArrow
        placement="top"
        label={<CopyToolTip />}
      >
        <IconButton
          cursor="pointer"
          colorScheme="primary"
          size={"sm"}
          onClick={() => {
            copyAsCSV();
            clearSelection();
          }}
          aria-label="Open actions"
          icon={<CopyIcon />}
        />
      </Tooltip>
    </ButtonGroup>
  );
};
