import { Button, MenuItem, useToast } from "@chakra-ui/react";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import tableService from "@/services/table.service";
import Swal from "sweetalert2";

interface MenuItemProps {
  onClose?: () => void;
  title?: string;
  isForDelFiltered?: boolean;
  isAnyProcessRunning: boolean;
  removeAll?: boolean;
}

const RemoveRows = ({
  onClose,
  title,
  isForDelFiltered,
  isAnyProcessRunning,
  removeAll = false,
}: MenuItemProps) => {
  const queryClient = useQueryClient();

  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const tableId = useTableStore((state) => state.tableData._id);
  const toast = useToast({
    position: "top-right",
    duration: 3000,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      tableService.removeRows(
        selectedRowsId,
        tableId,
        isForDelFiltered,
        removeAll,
      ),
  });

  const handleDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await mutateAsync(undefined, {
          onSuccess: (response: any) => {
            if (response.success) {
              toast({
                title: "Rows deleted",
                status: "success",
              });
              onClose && onClose();
              queryClient.refetchQueries({
                queryKey: ["table", tableId],
              });
            } else {
              toast({
                title: "Failed to delete rows",
                description: response.message,
                status: "error",
              });
            }
          },
          onError: (error) => {
            toast({
              title: "Failed to delete rows",
              description: error.message,
              status: "error",
            });
          },
        });
      },
    });
  };

  return (
    <MenuItem
      className="!px-0 !pb-0"
      closeOnSelect={false}
      disabled={isPending}
    >
      <Button
        size="sm"
        variant="outline"
        leftIcon={<Icons.DeleteIcon size={20} className="text-red-700" />}
        className="m-auto w-[94%]"
        onClick={handleDelete}
        isLoading={isPending}
        isDisabled={isAnyProcessRunning}
      >
        <span className="flex-1 text-red-700">{title}</span>
      </Button>
    </MenuItem>
  );
};

export default RemoveRows;
