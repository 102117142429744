export const categoriesList = [
  {
    value: "administration",
    label: "Administration",
  },
  {
    value: "consulting",
    label: "Consulting",
  },
  {
    value: "data_analysis",
    label: "Data analysis",
  },
  {
    value: "design",
    label: "Design",
  },
  {
    value: "directors",
    label: "Directors",
  },
  {
    value: "education",
    label: "Education",
  },
  {
    value: "engineering",
    label: "Engineering",
  },
  {
    value: "finance",
    label: "Finance",
  },
  {
    value: "food",
    label: "Food",
  },
  {
    value: "healthcare_services",
    label: "Healthcare services",
  },
  {
    value: "human_resources",
    label: "Human resources",
  },
  {
    value: "information_technology",
    label: "Information technology",
  },
  {
    value: "internship",
    label: "Internship",
  },
  {
    value: "legal",
    label: "Legal",
  },
  {
    value: "management",
    label: "Management",
  },
  {
    value: "manual_work",
    label: "Manual work",
  },
  {
    value: "marketing",
    label: "Marketing",
  },
  {
    value: "military_and_protective_services",
    label: "Military and protective services",
  },
  {
    value: "operations",
    label: "Operations",
  },
  {
    value: "purchasing",
    label: "Purchasing",
  },
  {
    value: "product_management",
    label: "Product management",
  },
  {
    value: "quality_assurance",
    label: "Quality assurance",
  },
  {
    value: "real_estate",
    label: "Real estate",
  },
  {
    value: "research",
    label: "Research",
  },
  {
    value: "sales",
    label: "Sales",
  },
  {
    value: "software_development",
    label: "Software development",
  },
  {
    value: "support",
    label: "Support",
  },
];

export const apolloCompanySize = [
  {
    name: "1-10",
  },
  {
    name: "11-20",
  },
  {
    name: "21-50",
  },
  {
    name: "51-100",
  },
  {
    name: "101-200",
  },
  {
    name: "201-500",
  },
  {
    name: "501-1000",
  },
  {
    name: "1001-2000",
  },
  {
    name: "2001-5000",
  },
  {
    name: "5001-10000",
  },
  {
    name: "10001+",
  },
];

export const employeeSize = [
  {
    name: "Self Employed",
    value: "A",
  },
  {
    name: "2-10",
    value: "B",
  },
  {
    name: "11-50",
    value: "C",
  },
  {
    name: "51-200",
    value: "D",
  },
  {
    name: "201-500",
    value: "E",
  },
  {
    name: "501-1000",
    value: "F",
  },
  {
    name: "1001-5000",
    value: "G",
  },
  {
    name: "5001-10000",
    value: "H",
  },
  {
    name: "10001+",
    value: "I",
  },
];

export const jobTitles = [
  {
    label: "Chief Executive Officer",
    value: "Chief Executive Officer",
  },
  {
    label: "Chief Operating Officer",
    value: "Chief Operating Officer",
  },
  {
    label: "General Manager",
    value: "General Manager",
  },
  {
    label: "Administrative Assistant",
    value: "Administrative Assistant",
  },
  {
    label: "Office Manager",
    value: "Office Manager",
  },
  {
    label: "Operations Manager",
    value: "Operations Manager",
  },
  {
    label: "Executive Assistant",
    value: "Executive Assistant",
  },
  {
    label: "Receptionist",
    value: "Receptionist",
  },
  {
    label: "Project Manager",
    value: "Project Manager",
  },
  {
    label: "Product Manager",
    value: "Product Manager",
  },
  {
    label: "Program Manager",
    value: "Program Manager",
  },
  {
    label: "Chief Financial Officer",
    value: "Chief Financial Officer",
  },
  {
    label: "Accountant",
    value: "Accountant",
  },
  {
    label: "Financial Analyst",
    value: "Financial Analyst",
  },
  {
    label: "Bookkeeper",
    value: "Bookkeeper",
  },
  {
    label: "Controller",
    value: "Controller",
  },
  {
    label: "Auditor",
    value: "Auditor",
  },
  {
    label: "Tax Specialist",
    value: "Tax Specialist",
  },
  {
    label: "Payroll Clerk",
    value: "Payroll Clerk",
  },
  {
    label: "Credit Analyst",
    value: "Credit Analyst",
  },
  {
    label: "Treasurer",
    value: "Treasurer",
  },
  {
    label: "Marketing Manager",
    value: "Marketing Manager",
  },
  {
    label: "Sales Manager",
    value: "Sales Manager",
  },
  {
    label: "Business Development Manager",
    value: "Business Development Manager",
  },
  {
    label: "Account Executive",
    value: "Account Executive",
  },
  {
    label: "Marketing Coordinator",
    value: "Marketing Coordinator",
  },
  {
    label: "Social Media Manager",
    value: "Social Media Manager",
  },
  {
    label: "Brand Manager",
    value: "Brand Manager",
  },
  {
    label: "Public Relations Specialist",
    value: "Public Relations Specialist",
  },
  {
    label: "SEO Specialist",
    value: "SEO Specialist",
  },
  {
    label: "Digital Marketing Specialist",
    value: "Digital Marketing Specialist",
  },
  {
    label: "Chief Information Officer",
    value: "Chief Information Officer",
  },
  {
    label: "Software Developer",
    value: "Software Developer",
  },
  {
    label: "Systems Administrator",
    value: "Systems Administrator",
  },
  {
    label: "IT Manager",
    value: "IT Manager",
  },
  {
    label: "Network Engineer",
    value: "Network Engineer",
  },
  {
    label: "Data Analyst",
    value: "Data Analyst",
  },
  {
    label: "Cybersecurity Specialist",
    value: "Cybersecurity Specialist",
  },
    {
    label: "CTO",
    value: "CTO",
  },
  {
    label: "Founder",
    value: "Founder",
  },
  
  {
    label: "Co-Founder",
    value: "Co-Founder",
  },
  {
    label: "Web Developer",
    value: "Web Developer",
  },
  {
    label: "Database Administrator",
    value: "Database Administrator",
  },
  {
    label: "AI Engineer",
    value: "AI Engineer",
  },
  {
    label: "Human Resources Manager",
    value: "Human Resources Manager",
  },
  {
    label: "Recruiter",
    value: "Recruiter",
  },
  {
    label: "HR Coordinator",
    value: "HR Coordinator",
  },
  {
    label: "Training and Development Manager",
    value: "Training and Development Manager",
  },
  {
    label: "Payroll Specialist",
    value: "Payroll Specialist",
  },
  {
    label: "Benefits Coordinator",
    value: "Benefits Coordinator",
  },
  {
    label: "HR Analyst",
    value: "HR Analyst",
  },
  {
    label: "Employee Relations Manager",
    value: "Employee Relations Manager",
  },
  {
    label: "Talent Acquisition Specialist",
    value: "Talent Acquisition Specialist",
  },
  {
    label: "Doctor",
    value: "Doctor",
  },
  {
    label: "Nurse",
    value: "Nurse",
  },
  {
    label: "Pharmacist",
    value: "Pharmacist",
  },
  {
    label: "Medical Assistant",
    value: "Medical Assistant",
  },
  {
    label: "Healthcare Administrator",
    value: "Healthcare Administrator",
  },
  {
    label: "Physical Therapist",
    value: "Physical Therapist",
  },
  {
    label: "Occupational Therapist",
    value: "Occupational Therapist",
  },
  {
    label: "Dentist",
    value: "Dentist",
  },
  {
    label: "Surgeon",
    value: "Surgeon",
  },
  {
    label: "Psychiatrist",
    value: "Psychiatrist",
  },
  {
    label: "Teacher",
    value: "Teacher",
  },
  {
    label: "Professor",
    value: "Professor",
  },
  {
    label: "Instructional Designer",
    value: "Instructional Designer",
  },
  {
    label: "School Principal",
    value: "School Principal",
  },
  {
    label: "Academic Advisor",
    value: "Academic Advisor",
  },
  {
    label: "Educational Consultant",
    value: "Educational Consultant",
  },
  {
    label: "Librarian",
    value: "Librarian",
  },
  {
    label: "Trainer",
    value: "Trainer",
  },
  {
    label: "Curriculum Developer",
    value: "Curriculum Developer",
  },
  {
    label: "Tutor",
    value: "Tutor",
  },
  {
    label: "Mechanical Engineer",
    value: "Mechanical Engineer",
  },
  {
    label: "Electrical Engineer",
    value: "Electrical Engineer",
  },
  {
    label: "Civil Engineer",
    value: "Civil Engineer",
  },
  {
    label: "Chemical Engineer",
    value: "Chemical Engineer",
  },
  {
    label: "Biomedical Engineer",
    value: "Biomedical Engineer",
  },
  {
    label: "Quality Engineer",
    value: "Quality Engineer",
  },
  {
    label: "Research Scientist",
    value: "Research Scientist",
  },
  {
    label: "Laboratory Technician",
    value: "Laboratory Technician",
  },
  {
    label: "Architect",
    value: "Architect",
  },
  {
    label: "Environmental Engineer",
    value: "Environmental Engineer",
  },
  {
    label: "Graphic Designer",
    value: "Graphic Designer",
  },
  {
    label: "Interior Designer",
    value: "Interior Designer",
  },
  {
    label: "Fashion Designer",
    value: "Fashion Designer",
  },
  {
    label: "UX/UI Designer",
    value: "UX/UI Designer",
  },
  {
    label: "Art Director",
    value: "Art Director",
  },
  {
    label: "Photographer",
    value: "Photographer",
  },
  {
    label: "Videographer",
    value: "Videographer",
  },
  {
    label: "Copywriter",
    value: "Copywriter",
  },
  {
    label: "Illustrator",
    value: "Illustrator",
  },
  {
    label: "Animator",
    value: "Animator",
  },
  {
    label: "Lawyer",
    value: "Lawyer",
  },
  {
    label: "Legal Assistant",
    value: "Legal Assistant",
  },
  {
    label: "Paralegal",
    value: "Paralegal",
  },
  {
    label: "Compliance Officer",
    value: "Compliance Officer",
  },
  {
    label: "Legal Secretary",
    value: "Legal Secretary",
  },
  {
    label: "Contract Manager",
    value: "Contract Manager",
  },
  {
    label: "Patent Attorney",
    value: "Patent Attorney",
  },
  {
    label: "Litigation Associate",
    value: "Litigation Associate",
  },
  {
    label: "Intellectual Property Lawyer",
    value: "Intellectual Property Lawyer",
  },
  {
    label: "Legal Analyst",
    value: "Legal Analyst",
  },
  {
    label: "Chef",
    value: "Chef",
  },
  {
    label: "Event Planner",
    value: "Event Planner",
  },
  {
    label: "Pilot",
    value: "Pilot",
  },
  {
    label: "Construction Worker",
    value: "Construction Worker",
  },
  {
    label: "Electrician",
    value: "Electrician",
  },
  {
    label: "Plumber",
    value: "Plumber",
  },
  {
    label: "Carpenter",
    value: "Carpenter",
  },
  {
    label: "Real Estate Agent",
    value: "Real Estate Agent",
  },
  {
    label: "Flight Attendant",
    value: "Flight Attendant",
  },
  {
    label: "Truck Driver",
    value: "Truck Driver",
  },
  {
    label: "Sustainability Coordinator",
    value: "Sustainability Coordinator",
  },
  {
    label: "Data Scientist",
    value: "Data Scientist",
  },
  {
    label: "Blockchain Developer",
    value: "Blockchain Developer",
  },
  {
    label: "Drone Operator",
    value: "Drone Operator",
  },
  {
    label: "AI Research Scientist",
    value: "AI Research Scientist",
  },
  {
    label: "Virtual Reality Developer",
    value: "Virtual Reality Developer",
  },
  {
    label: "eSports Coach",
    value: "eSports Coach",
  },
  {
    label: "Social Media Influencer",
    value: "Social Media Influencer",
  },
  {
    label: "Podcast Producer",
    value: "Podcast Producer",
  },
  {
    label: "User Researcher",
    value: "User Researcher",
  },
  {
    label: "Intern",
    value: "Intern",
  },
  {
    label: "Junior Manager",
    value: "Junior Manager",
  },
  {
    label: "Trainee",
    value: "Trainee",
  },
  {
    label: "Assistant",
    value: "Assistant",
  },
  {
    label: "Coordinator",
    value: "Coordinator",
  },
  {
    label: "Associate",
    value: "Associate",
  },
  {
    label: "Apprentice",
    value: "Apprentice",
  },
  {
    label: "Junior Accountant",
    value: "Junior Accountant",
  },
  {
    label: "Junior Developer",
    value: "Junior Developer",
  },
  {
    label: "Research Assistant",
    value: "Research Assistant",
  },
  {
    label: "Teaching Assistant",
    value: "Teaching Assistant",
  },
  {
    label: "Graduate Assistant",
    value: "Graduate Assistant",
  },
  {
    label: "Junior Analyst",
    value: "Junior Analyst",
  },
  {
    label: "Intern Engineer",
    value: "Intern Engineer",
  },
  {
    label: "Sales Associate",
    value: "Sales Associate",
  },
  {
    label: "Customer Service Representative",
    value: "Customer Service Representative",
  },
  {
    label: "Junior Designer",
    value: "Junior Designer",
  },
  {
    label: "Junior Consultant",
    value: "Junior Consultant",
  },
  {
    label: "Technical Assistant",
    value: "Technical Assistant",
  },
  {
    label: "Administrative Intern",
    value: "Administrative Intern",
  },
  {
    label: "Marketing Intern",
    value: "Marketing Intern",
  },
  {
    label: "HR Intern",
    value: "HR Intern",
  },
  {
    label: "Finance Intern",
    value: "Finance Intern",
  },
  {
    label: "Legal Intern",
    value: "Legal Intern",
  },
];

export const companyNewsCategories = [
  {
    value: "hires",
    label: "Hires",
  },
  {
    value: "promotes",
    label: "Promotes",
  },
  {
    value: "leaves",
    label: "Leaves",
  },
  {
    value: "retires",
    label: "Retires",
  },
  {
    value: "acquires",
    label: "Acquires",
  },
  {
    value: "merges_with",
    label: "Merges with",
  },
  {
    value: "sells_assets_to",
    label: "Sells assets to",
  },
  {
    value: "expands_offices_to",
    label: "Expands offices to",
  },
  {
    value: "expands_offices_in",
    label: "Expands offices in",
  },
  {
    value: "expands_facilities",
    label: "Expands facilities",
  },
  {
    value: "opens_new_location",
    label: "Opens new location",
  },
  {
    value: "increases_headcount_by",
    label: "Increases headcount by",
  },
  {
    value: "launches",
    label: "Launches",
  },
  {
    value: "integrates_with",
    label: "Integrates with",
  },
  {
    value: "is_developing",
    label: "Is developing",
  },
  {
    value: "receives_financing",
    label: "Receives financing",
  },
  {
    value: "invests_into",
    label: "Invests into",
  },
  {
    value: "invests_into_assets",
    label: "Invests into assets",
  },
  {
    value: "goes_public",
    label: "Goes public",
  },
  {
    value: "closes_offices_in",
    label: "Closes offices in",
  },
  {
    value: "decreases_headcount_by",
    label: "Decreases headcount by",
  },
  {
    value: "partners_with",
    label: "Partners with",
  },
  {
    value: "receives_award",
    label: "Receives award",
  },
  {
    value: "recognized_as",
    label: "Recognized as",
  },
  {
    value: "signs_new_client",
    label: "Signs new client",
  },
  {
    value: "files_suit_against",
    label: "Files suit against",
  },
  {
    value: "has_issues_with",
    label: "Has issues with",
  },
  {
    value: "identified_as_competitor_of",
    label: "Identified as competitor of",
  },
];

export const defaultSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
