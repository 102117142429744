export const allLeadOptions = [
  {
    label: "First Name",
    fieldName: "firstname",
  },
  {
    label: "Last Name",
    fieldName: "lastname",
  },
  {
    label: "Gender",
    fieldName: "gender",
  },
  {
    label: "Bio",
    fieldName: "bio",
  },
  {
    label: "Company Name",
    fieldName: "companyName",
  },
  {
    label: "Company URL",
    fieldName: "companyUrl",
  },
  {
    label: "Job Title",
    fieldName: "jobTitle",
  },
  {
    label: "Profile Picture",
    fieldName: "profilePicture",
  },
  {
    label: "LinkedIn URL",
    fieldName: "linkedinUrl",
  },
  {
    label: "Twitter",
    fieldName: "twitter",
  },
  {
    label: "Professional Email",
    fieldName: "proEmail",
  },
  {
    label: "Industry",
    fieldName: "industry",
  },
  {
    label: "Phone",
    fieldName: "phone",
  },
  {
    label: "Location",
    fieldName: "location",
  },
  {
    label: "Personal Email",
    fieldName: "persoEmail",
  },
  {
    label: "CRM ID",
    fieldName: "crm_id",
  },
  {
    label: "Custom Attribute 1",
    fieldName: "customAttribute1",
  },
  {
    label: "Custom Attribute 2",
    fieldName: "customAttribute2",
  },
  {
    label: "Custom Attribute 3",
    fieldName: "customAttribute3",
  },
  {
    label: "Custom Attribute 4",
    fieldName: "customAttribute4",
  },
  {
    label: "Custom Attribute 5",
    fieldName: "customAttribute5",
  },
  {
    label: "Custom Attribute 6",
    fieldName: "customAttribute6",
  },
  {
    label: "Custom Attribute 7",
    fieldName: "customAttribute7",
  },
  {
    label: "Custom Attribute 8",
    fieldName: "customAttribute8",
  },
  {
    label: "Custom Attribute 9",
    fieldName: "customAttribute9",
  },
  {
    label: "Custom Attribute 10",
    fieldName: "customAttribute10",
  },
] as const;

export type FieldName = (typeof allLeadOptions)[number]["fieldName"];
