import * as Sentry from "@sentry/react";
import { hotjar } from "react-hotjar";
import { QueryClientProvider } from "@tanstack/react-query";
import { NotFoundRoute, Router, RouterProvider } from "@tanstack/react-router";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";

import ErrorComponent from "./components/Common/ErrorComponent";
import RedirectPage from "./components/Redirect";

import { queryClient } from "./lib/queryClient";
import StyleProvider from "./providers/StyleProvider";
import { routeTree } from "./routeTree.gen";

import "@dotlottie/react-player/dist/index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./App.css";
import "./index.css";

const notFoundRoute = new NotFoundRoute({
  component: () => <RedirectPage />,
  getParentRoute: () => routeTree,
});

// Set up a Router instance
const router = new Router({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  notFoundRoute,
});

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Sentry

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.MODE || "development",
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["url", "navigation"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  hotjar.initialize({
    id: 3668914,
    sv: 6,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StyleProvider>
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Suspense>
  </StyleProvider>,
);
