import { useEffect, useState } from "react";
import { Textarea } from "@chakra-ui/react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SelectTemplate from "./SelectTemplate";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import NewCustomPreset from "./NewCustomPreset";
import { getInitialColumn } from "@/utils";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { REGEX, companyQuestionTutorialLink } from "../../constants";
import type { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";

interface EnrichmentDataType extends EnrichmentMetaData {
  prompts: string;
}

const AskAQuestionAiAgent = () => {
  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  const { updateState: updatePresetState, selectedPresetValues } =
    usePresetsStore();

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [question, setQuestion] = useState("");

  useEffect(() => {
    if (selectedPresetValues) {
      setQuestion(selectedPresetValues);
    }
  }, [selectedPresetValues]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        // FUTURE: This function needs to be changed if we have more than one state to be updated
        const relyOnColumn = columns.find(
          (column) => column._id === enrichmentData.columnId,
        );

        if (relyOnColumn) {
          setSelectedOption({
            key: relyOnColumn.name,
            keyId: relyOnColumn._id,
            iconType: relyOnColumn.metaData?.iconType || "url",
          });
        }

        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });

        setQuestion(enrichmentData.prompts);
      }
    } else {
      const initialColumn = getInitialColumn(REGEX.WEBSITE);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  return (
    <>
      <div className="overflow-y-auto p-4">
        <WatchTutorial
          subText="Our company research agent is able to answer questions by searching the web. This easy to use agent is suitable for common questions about a company. Eg. Who are the competitors of the company?"
          link={companyQuestionTutorialLink}
        />
        <SelectTemplate />

        <div className="mb-4">
          <p className="pb-1 text-base font-semibold">Website URL or Name</p>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div>
          <p className="pb-1 text-base font-semibold">Question</p>
          <Textarea
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
              updatePresetState({
                selectedPresetValues: e.target.value,
              });
            }}
            className="min-h-[100px]  w-full resize-none rounded-md border !border-primary px-2 py-1.5 text-base text-[#1D2026] focus:outline-none focus:ring-2 focus:ring-primary/50"
            placeholder="How many employees does this company have?"
          />
        </div>
        <NewCustomPreset />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId || !question}
        apiPayload={{
          columnId: selectedOption?.keyId || "",
          prompts: question,
        }}
      />
    </>
  );
};

export default AskAQuestionAiAgent;
