import { Text } from "@chakra-ui/react";

const Header = () => {
  return (
    <div className="px-[32px] py-[8px] bg-white flex items-center justify-between border border-[##F0EFF1] bg-white">
      <Text className="text-[24px] font-bold leading-[32px]">
        People Search (Mixrank)
      </Text>
    </div>
  );
};

export default Header;
