import { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, Spinner } from "@chakra-ui/react";
import { ColDef, GridApi } from "ag-grid-community";
import { ProvidersCreditUsage } from "@/types/providersCreditUsage.types";
import useProvidersCreditUsage from "./hooks/useProvidersCreditUsage";

const CreditUsageGrid = () => {
  const {
    data: creditUsage,
    isLoading,
    isFetching,
    refetch,
  } = useProvidersCreditUsage();

  const [gridApi, setGridApi] = useState<GridApi<ProvidersCreditUsage> | null>(
    null,
  );

  const columnDefs = useMemo(
    () =>
      [
        {
          headerName: "Provider Name",
          field: "name",
          sortable: true,
          filter: true,
          width: 400,
          cellRenderer: (params: any) => (
            <div className="flex flex-row items-center truncate">
              <span className="ml-2 truncate">{params.value}</span>
            </div>
          ),
        },
        {
          headerName: "Used For",
          field: "usedFor",
          width: 400,
          sortable: true,
          filter: true,
        },
        {
          headerName: "Credits Remaining",
          field: "creditsLeft",
          width: 400,
          sortable: true,
          filter: true,
        },
      ] as ColDef<ProvidersCreditUsage>[],
    [],
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (isFetching) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
    // @ts-ignore
    if (!creditUsage?.data?.length) {
      gridApi?.showNoRowsOverlay();
    }
  }, [isFetching, gridApi, creditUsage?.data ?? null]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Box className="ag-theme-quartz" style={{ height: "400px", width: "100%" }}>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <AgGridReact
          columnDefs={columnDefs}
          // @ts-ignore
          rowData={creditUsage?.data ?? []}
          suppressRowClickSelection
          domLayout="autoHeight"
          onGridReady={onGridReady}
        />
      )}
    </Box>
  );
};

export default CreditUsageGrid;
