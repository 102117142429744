import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { FieldInfo, Parser } from "@json2csv/plainjs";
import { ColDef } from "ag-grid-community";
import { APIResponse } from "@/lib/apiCalls";
import { TableDataQueryParams } from "@/types/table.types";
import { useTableStore } from "./useGridState.store";
import { buildServerQueryParams } from "../functions/buildUrlParams";
import { TPartialRow } from "../types";

interface IUseExport<TData> {
  fileName: string;
  colDefs: ColDef[];
  /**
   * Export only selected rows, overrides filters
   * Ignored if no rows are selected
   */
  selected?: boolean;
  /**
   * Apply Filters
   **/
  filters?: boolean;
  sort?: boolean;
  exportColumns?: Array<string | FieldInfo<object, unknown>>;
  dataSource: (
    payload: Partial<TableDataQueryParams>,
  ) => Promise<APIResponse<TData>>;
}
export const useExport = <TData extends TPartialRow>({
  fileName,
  dataSource,
  colDefs,
  filters,
  sort,
  exportColumns,
}: IUseExport<TData[]>) => {
  const sortModel = useTableStore(
    (state) => state.state?.sort?.sortModel ?? [],
  );
  const filterModel = useTableStore(
    (state) => state.state?.filter?.filterModel ?? null,
  );
  const isSelectAll = useTableStore((state) => state.isSelectAll);
  const selectedRowIds = useTableStore((state) =>
    state.isSelectAll ? "all" : (state.state?.rowSelection as string[]) ?? [],
  );
  const defaultExportColumns = useTableStore(() =>
    colDefs
      .map((col, index) => ({
        label: col.headerName || col.field || index.toString(),
        value: col.field as string,
      }))
      .filter((col) => col.value),
  );
  const mutation = useMutation({
    mutationFn: async () => {
      const params = buildServerQueryParams({
        startRow: 0,
        endRow: Number.MAX_SAFE_INTEGER,
        sortModel: sort ? sortModel : undefined,
        filterModel: filters ? filterModel : undefined,
      });
      const result = await dataSource?.(params as TableDataQueryParams);
      let data = result?.data ?? [];
      if (!isSelectAll && selectedRowIds.length) {
        data = data.filter((row) => selectedRowIds.includes(row._id));
      }
      const parser = new Parser({
        fields: exportColumns ?? defaultExportColumns,
      });
      const csv = parser.parse(data);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });
  return mutation;
};
