import Badreddine from "@/assets/images/Badreddine.jpg";
import Deb from "@/assets/images/Deb.jpg";
import Bryant from "@/assets/images/Bryant.png";

export const CarouselDataData = [
  {
    id: 1,
    avatar: Badreddine,
    quote:
      "Persana AI is honestly been a game-changer. It’s super easy to get started with, and it has made our outreach efforts way more targeted and efficient.",
    author: "I. Badreddine",
    position: "Co-founder and CEO, Alpaca Leads",
  },

  {
    id: 2,
    avatar: Deb,
    quote:
      "What would take me three weeks, Persana did in 3 mins. With Persana I was able to generate emails for an ICP in a matter of minutes using my tone and voice",
    author: "Deb P.",
    position: "Head of Sales, Quest Labs",
  },
  {
    id: 3,
    avatar: Bryant,
    quote:
      "Persana helped us book more meetings than I thought possible. We were able to personalize and segment our leads instantly with their platform, a process that used to take weeks.",
    author: "Bryant",
    position: "CEO, Vaero",
  },
];
