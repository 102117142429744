import { ZenRowParams } from "./types";

export const defaultZenRowParams: ZenRowParams = {
  zenRowsProxy: true,
  zenRowsProxyCountry: "",
  zenRowsRenderJS: true,
  zenRowsOutput: "auto_parse",
  zenRowsCssSelectors: `{
  "links": "a @href",
  "images":"img @src"
}`,
};
