import { Condition } from "@/types/table.types";

export const conditionOptions: Condition[] = [
  {
    name: "equal to",
    value: "equal_to",
    hasInput: true,
  },
  {
    name: "not equal to",
    value: "not_equal_to",
    hasInput: true,
  },
  {
    name: "greater than",
    value: "greater_than",
    hasInput: true,
  },
  {
    name: "greater than or equal to",
    value: "greater_than_or_equal_to",
    hasInput: true,
  },
  {
    name: "less than",
    value: "less_than",
    hasInput: true,
  },
  {
    name: "less than or equal to",
    value: "less_than_or_equal_to",
    hasInput: true,
  },
  {
    name: "contains",
    value: "contains",
    hasInput: true,
  },
  {
    name: "does not contain",
    value: "does_not_contain",
    hasInput: true,
  },
];
