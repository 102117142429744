import { Card, CardBody, Text, Divider } from "@chakra-ui/react";

type Props = {
  data: {
    full_name: string;
    description: string;
    stargazers_count: number;
  };
};
const RepoDetails = ({ data }: Props) => {
  return (
    <div className="d-flex mt-4 justify-center min-w-[50%]">
      <Card>
        <CardBody>
          <Text className="mb-1 text-[17px] font-medium text-primaryText">
            {data.full_name}
          </Text>
          <Text className="py-1 text-xs font-normal text-primaryText">
            {data.description}
          </Text>
          <Divider className="my-2" />
          <Text className="text-sm font-medium text-primaryText">
            {data.stargazers_count} Stargazers
          </Text>
        </CardBody>
      </Card>
    </div>
  );
};

export default RepoDetails;
