import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

import { useEnrichStore } from "@/stores/enrich.store";
import { gptVersion } from "@/types/enrichment.types";

const VersionSelect = () => {
  const gptVersion = useEnrichStore((state) => state.gptVersion);
  const updateState = useEnrichStore((state) => state.updateState);

  return (
    <>
      <div className="mt-4 px-4">
        <RadioGroup
          className="flex flex-col gap-2"
          onChange={(newValue: gptVersion) => {
            updateState({
              gptVersion: newValue,
            });
          }}
          value={gptVersion}
        >
          <Stack direction="row" gap={12}>
            <Radio
              size="md"
              name="gpt3.5"
              value="gpt-3.5-turbo-0125"
              colorScheme="primary"
            >
              gpt-3.5
            </Radio>
            <Radio
              size="md"
              name="gpt-4-turbo"
              value="gpt-4-turbo"
              colorScheme="primary"
            >
              gpt-4-turbo
            </Radio>
          </Stack>
          <Stack direction="row" gap={12}>
            <Radio size="md" name="gpt-4o" value="gpt-4o" colorScheme="primary">
              gpt-4o
            </Radio>
            <Radio
              size="md"
              name="gpt-4o-mini"
              value="gpt-4o-mini"
              colorScheme="primary"
            >
              gpt-4o-mini
            </Radio>
          </Stack>
        </RadioGroup>
      </div>
    </>
  );
};

export default VersionSelect;
