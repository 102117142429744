import { useTable } from "@/components/DataTable/Table/hooks/useTable";
import { useRunningProcessesByTypes } from "@/hooks/useRunningProcesses";
import { queryClient } from "@/lib/queryClient";
import useSocket from "@/lib/socket";
import { useProcessingStore } from "@/stores/processing.store";
import { userStore } from "@/stores/user.store";
import { IsocketJobChangesData } from "@/types/socket.types";
import { useEffect } from "react";

export const useJobSync = () => {
  const { updateTableRow, finished } = useTable();
  const { subscribe } = useSocket();
  const updateProcessingData = useProcessingStore(
    (state) => state.updateProcessingData,
  );
  const currentUser = userStore((state) => state.currentUser);
  const updateProcessingState = useProcessingStore(
    (state) => state.updateState,
  );
  const { data: processingData } =
    useRunningProcessesByTypes("track_job_changes");

  useEffect(() => {
    if (processingData?.data) {
      updateProcessingState({
        processingData: processingData.data,
      });
    }
  }, [processingData?.data]);
  useEffect(() => {
    if (currentUser?._id) {
      subscribe(
        `jobChanges-${currentUser._id}`,
        async (data: IsocketJobChangesData) => {
          if (data.leadData) {
            updateTableRow(
              data.leadData.lead ? data.leadData.lead : data.leadData,
            );
          }
          if (data.processingData) {
            updateProcessingData(data.processingData);
            if (!data.processingData.isProcessing) {
              queryClient.invalidateQueries({
                predicate: (query) => {
                  return query.queryKey[0] === "table-running-processes";
                },
              });
              finished();
              queryClient.invalidateQueries({
                queryKey: ["filterValues", "jobChanges"],
              });
            }
          }
        },
      );
    }
  }, [currentUser?._id]);
  return processingData;
};
