import TextRenderer from "./TextRenderer";

const CompanyDetails = ({ cellDetails }: { cellDetails: any }) => {
  const companyDetails = cellDetails?.metaData?.responseValue || {};

  return (
    <div className="py-2 px-4 mt-4">
      {/* Name Section */}
      <a href={companyDetails?.urls?.company_page} target="_blank">
        <div className="flex items-center gap-[10px] cursor-pointer">
          <img
            src={companyDetails?.images?.logo}
            alt=""
            width={40}
            height={40}
          />
          <div className="flex flex-col">
            <p className="text-[20px] font-bold text-[#677084]">
              {companyDetails.name}
            </p>
            <p className="text-[14px] font-medium text-[#677084]">
              {companyDetails?.tagline}
            </p>
          </div>
        </div>
      </a>

      {/* Details Setion */}
      <p className="text-[16px] text-darkGray my-4">
        {companyDetails?.description}
      </p>

      <div className="grid grid-cols-2 gap-2">
        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Company Type
            </p>
          </div>
          <TextRenderer value={companyDetails?.type || ""} />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Industries
            </p>
          </div>
          <TextRenderer value={companyDetails?.industries?.join(",")} />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Followers
            </p>
          </div>
          <TextRenderer value={companyDetails?.followers || ""} />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Founded On
            </p>
          </div>
          <TextRenderer value={companyDetails?.founded?.year || ""} />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Headquarter
            </p>
          </div>
          <TextRenderer
            value={
              `${companyDetails?.locations?.headquarter?.city || ""}, ${companyDetails?.locations?.headquarter?.country || ""}` ||
              ""
            }
          />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Total Staff
            </p>
          </div>
          <TextRenderer value={companyDetails?.staff?.total || ""} />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
