import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {},
  list: {},
  item: {},
  groupTitle: {},
  command: {},
  divider: {},
});

const variants = {
  persana: {
    ...baseStyle,
    item: {
      ...baseStyle.item,
      height: "2.2em",
      fontWeight: "400",
      _focus: {
        bg: "#EDE0FA",
        color: "black",
      },
    },
  },
};
export const menuTheme = defineMultiStyleConfig({ variants });
