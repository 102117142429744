import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Input,
  Textarea,
} from "@chakra-ui/react";

interface Props {
  profileInfo: {
    firstName: string;
    companyName: string;
    companyDescription: string;
    callToAction: string;
  };
  handleUpdateSettings: (fieldName: string, value: string) => void;
}

const Settings = ({ profileInfo, handleUpdateSettings }: Props) => {
  return (
    <div className="pt-3">
      <Accordion allowToggle>
        <AccordionItem
          rounded={"md"}
          sx={{
            border: "none",
            backgroundColor: "transparent",
            "&:not(:last-child)": {
              marginBottom: "1rem",
            },
          }}
        >
          <h2>
            <AccordionButton
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                borderRadius: "0.3rem",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                _hover: {
                  backgroundColor: "#97bde93f",
                },
                paddingLeft: "7px",
              }}
            >
              <p className="truncate text-left font-semibold text-dark">
                Your Company Information
              </p>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <div className="space-y-3">
              <div className="font-title font-medium">
                <label htmlFor="firstName" className="smallTitle">
                  Your Name
                </label>
                <Input
                  id="firstName"
                  value={profileInfo.firstName}
                  onChange={(e) =>
                    handleUpdateSettings("firstName", e.target.value)
                  }
                  className="w-full rounded border border-[#c6c6ca] px-2 py-1.5 text-sm focus:border-primary focus:outline-none focus:ring-0"
                  placeholder="Your Name"
                />
              </div>
              <div className="font-title font-medium">
                <label htmlFor="companyName" className="smallTitle">
                  Your Company Name
                </label>
                <Input
                  id="companyName"
                  value={profileInfo.companyName}
                  onChange={(e) =>
                    handleUpdateSettings("companyName", e.target.value)
                  }
                  className="w-full rounded border border-[#c6c6ca] px-2 py-1.5 text-sm focus:border-primary focus:outline-none focus:ring-0"
                  placeholder="Your Company Name"
                />
              </div>
              <div className="font-title font-medium">
                <label htmlFor="companyDescription" className="smallTitle">
                  Your Company Description
                </label>
                <Textarea
                  id="companyDescription"
                  value={profileInfo.companyDescription}
                  onChange={(e) =>
                    handleUpdateSettings("companyDescription", e.target.value)
                  }
                  className="w-full rounded border border-[#c6c6ca] px-2 py-1.5 text-sm focus:border-primary focus:outline-none focus:ring-0"
                  placeholder="Company Description"
                />
              </div>
              <div className="font-title font-medium">
                <label htmlFor="callToAction" className="smallTitle">
                  Call to Action
                </label>
                <Input
                  id="callToAction"
                  value={profileInfo.callToAction}
                  onChange={(e) =>
                    handleUpdateSettings("callToAction", e.target.value)
                  }
                  className="w-full rounded border border-[#c6c6ca] px-2 py-1.5 text-sm focus:border-primary focus:outline-none focus:ring-0"
                  placeholder="Call to Action"
                />
              </div>
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Settings;
