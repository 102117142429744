import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Input, Text } from "@chakra-ui/react";
import { useModalStore } from "@/stores/modal.store";

type Props = {
  quickAddInput: string;
  setQuickAddInput: (val: string) => void;
  placeholder: string;
  loader: boolean;
  handleAdd: () => void;
};

const QuickAddModal = ({
  quickAddInput,
  placeholder,
  loader,
  setQuickAddInput,
  handleAdd,
}: Props) => {
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  const onClose = () => {
    updateState({
      isOpenModal: false,
      modalData: {
        modalType: "",
        metaData: {},
      },
    });
  };

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      isOpen={isOpenModal && modalData.modalType === "quick-add"}
      size={"2xl"}
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="pb-4 font-poppins">
        <ModalHeader className="border-b-[1px] border-b-dividerColor text-center">
          <div className="d-flex items-center">
            <Text className="flex-1">Quick Add</Text>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="w-full">
            <p className="mt-4 mb-1 text-sm font-semibold ">{placeholder}</p>
            <Input
              onChange={(e) => setQuickAddInput(e.target.value)}
              placeholder={placeholder}
              size="md"
              rounded="md"
            />
            <div className="mt-[20px] flex w-full items-center justify-center">
              <Button
                isLoading={loader}
                onClick={async () => {
                  await handleAdd();
                  onClose();
                }}
                variant="solid"
                h={"38px"}
                fontSize={14}
                className="!bg-primary-gradient hover:opacity-80"
                colorScheme="primary"
                borderRadius={"6px"}
                isDisabled={!quickAddInput}
              >
                <span className="font-semibold">Add</span>
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QuickAddModal;
