import React from "react";
import { Handle, NodeToolbar, Position } from "@xyflow/react";

import Toolbar from "./Toolbar";
import CustomNodeCard from "../CustomNodeCard";

import TableImg from "@/assets/svgs/auto-pilot-table.svg";
import { CustomNodeProps } from "../types";

const PersanaTable: React.FC<CustomNodeProps> = ({ data }) => {
  return (
    <>
      <Handle type="target" position={Position.Top} />
      {!data.isDisabled && data.showToolbar && (
        <NodeToolbar isVisible={true} position={Position.Right}>
          <Toolbar tableId={data?.tableData?._id || ""} />
        </NodeToolbar>
      )}
      <CustomNodeCard
        isActive={data.showToolbar || false}
        title={data?.tableTitle || "People Table"}
        subtitle="Table"
        configStatus="Table Generated"
        isConfigured={!data.isDisabled}
        isDisabled={!!data.isDisabled}
        imgSrc={TableImg}
      />
      <Handle type="source" position={Position.Bottom} id="a" />
      <Handle type="source" position={Position.Bottom} id="b" />
    </>
  );
};

export default PersanaTable;
