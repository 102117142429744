import { Button } from "@chakra-ui/react";

import { colConditions, conditions } from "./constants";

import { generateUniqueId } from "@/lib/utils";
import { useTableStore } from "@/stores/table.store";

const AddFilter = () => {
  const tableFilters = useTableStore((state) => state.tableFilters);
  const updateState = useTableStore((state) => state.updateState);
  const columns = useTableStore((state) => state.tableData.columns);

  const handleAddFilter = () => {
    updateState({
      tableFilters: {
        ...tableFilters,
        filters: [
          ...(tableFilters.filters || []),
          {
            id: generateUniqueId(),
            column: columns[0],
            type: "input",
            colCondition: colConditions[0],
            condition: conditions[0],
            value: "",
          },
        ],
      },
    });
  };

  return (
    <Button
      // eslint-disable-next-line tailwindcss/classnames-order
      className="flex w-full items-center justify-center gap-x-2 truncate rounded-md px-3 py-1.5 !text-sm duration-300 mb-2 !bg-[#AA6BFA] hover:!bg-[#AA6BFA]/80 !text-white"
      size="sm"
      onClick={handleAddFilter}
    >
      <span>Add Filter</span>
    </Button>
  );
};

export default AddFilter;
