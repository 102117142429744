import { create } from "zustand";
import { TableState } from "../types";
import { ROOT_FOLDER_ID } from "../constants";

export const useTableState = create<TableState>((set) => ({
  gridApi: null,
  editing: null,
  get gridIsReady(): boolean {
    if (!this.gridApi) return false;
    return !this.gridApi.isDestroyed();
  },
  showFolders: true,
  folderClosedStatus: new Map([[ROOT_FOLDER_ID, false]]),
  selected: new Set(),
  folderMap: null,
  sort: new Map([
    ["type", "asc"],
    ["updatedAt", "desc"],
  ]),
  search: "",
  tableList: [],
  folderList: [],
  updateState: (newState) => set((state) => ({ ...state, ...newState })),
  setFolderClosed: (folderId, closed) =>
    set((state) => {
      const openFolderStatus = new Map(state.folderClosedStatus);
      openFolderStatus.set(folderId, closed);
      return { ...state, folderClosedStatus: openFolderStatus };
    }),
}));
