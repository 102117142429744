import React from "react";

import SelectColumnDropdown from "../../Common/SelectColumnDropdown";

import type { TSelectedColumnOption } from "@/types/common.types";

interface Props {
  selectedOption: TSelectedColumnOption | null;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<TSelectedColumnOption | null>
  >;
}

const EmailTab = ({ selectedOption, setSelectedOption }: Props) => {
  return (
    <div>
      <p className="pb-1 text-base font-semibold">Select the Email column</p>
      <SelectColumnDropdown
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    </div>
  );
};

export default EmailTab;
