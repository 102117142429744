import { useQueryClient } from "@tanstack/react-query";
import { useTableStore } from "./useGridState.store";

export const useDeleteDisplayedRow = () => {
  const id = useTableStore((state) => state.id);
  const queryClient = useQueryClient();
  const gridApi = useTableStore((state) => state.gridApi);
  const updateState = useTableStore((state) => state.updateState);
  const removeSelectedRows = async () => {
    gridApi?.deselectAll();
    updateState({ isSelectAll: false });
    queryClient.cancelQueries({ queryKey: ["filterValues", id] });
    queryClient.invalidateQueries({ queryKey: ["filterValues", id] });
    gridApi?.setFilterModel(null);
    gridApi?.onFilterChanged();
  };
  return removeSelectedRows;
};
