import { useEffect } from "react";
import {
  prepareFolderTree,
  prepareTableRows,
  sortFolderItems,
} from "../../utils";
import { useTableState } from "../useTableState.store";
import { ROOT_FOLDER_ID } from "../../constants";
export const useBuildFolderTree = () => {
  const tableList = useTableState((state) => state.tableList);
  const folderList = useTableState((state) => state.folderList);
  const sort = useTableState((state) => state.sort);
  const updateState = useTableState((state) => state.updateState);

  useEffect(() => {
    try {
      if (tableList && folderList) {
        const folders = prepareFolderTree(folderList);
        prepareTableRows(tableList, folders);
        if (sort) {
          sortFolderItems(folders.get(ROOT_FOLDER_ID) ?? null, sort);
        }
        updateState({ folderMap: folders });
      }
    } catch (e) {
      console.error(`Failed to prepare folder tree`, e);
      updateState({ folderMap: null });
    }
  }, [tableList, folderList, updateState, sort]);
};
