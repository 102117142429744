import { FileRoute } from "@tanstack/react-router";

import Login from "@/pages/Login/Login";

export const Route = new FileRoute("/login/").createRoute({
  component: LoginPage,
});

function LoginPage() {
  return <Login />;
}

export default LoginPage;
