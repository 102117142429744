import APIKeyIntegration from "./APIKeyIntegration";
import HunterIoLogo from "@/assets/images/Hunter.png";

const HunterIoIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"hunterIo"}
      providerTitle="Hunter IO"
      providerIcon={HunterIoLogo}
    />
  );
};

export default HunterIoIntegration;
