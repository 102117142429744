import { useEffect } from "react";
import { RowClickedEvent } from "ag-grid-community";
import _ from "lodash";
import { TRow, TRowTree } from "../../types";
import { useTableState } from "../useTableState.store";
import { ROOT_FOLDER_ID } from "../../constants";

export const useUpdateRowData = () => {
  const gridApi = useTableState((state) => state.gridApi);
  const rootFolder = useTableState(
    (state) => state.folderMap?.get(ROOT_FOLDER_ID) ?? null,
  );
  useEffect(() => {
    if (!(rootFolder && gridApi)) return;
    const rowData = _.flattenDeep(rootFolder.folderItems.map(getFlatRowList));
    if (rowData.length === 0) gridApi.showNoRowsOverlay();
    gridApi.updateGridOptions({
      rowData: rowData,
    });

    function getFlatRowList(folderItem: TRow): TRowTree {
      if (folderItem.type === "folder") {
        return [folderItem, folderItem.folderItems.map(getFlatRowList)];
      }
      return [folderItem];
    }
  }, [rootFolder, gridApi]);

  useEffect(() => {
    if (!gridApi) return;
    gridApi.addEventListener("rowClicked", onRowDataUpdated);

    function onRowDataUpdated(e: RowClickedEvent<TRow>) {
      e.api.sizeColumnsToFit();
    }
    return () => {
      gridApi.removeEventListener("rowClicked", onRowDataUpdated);
    };
  }, [gridApi]);
};
