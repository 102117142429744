import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  IDatasource,
  SortChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Select } from "@chakra-ui/react";
import dayjs from "dayjs";

import { useTableList } from "@/hooks/useTableList";
import { useCallback, useMemo, useState } from "react";
import tableService from "@/services/table.service";
import { CreditHistoryData } from "@/types/table.types";
import { filterComponentMaps } from "@/components/Enrichment/FilterModals/mappings";
import { processingTypeLabelMap } from "@/components/Table/constants";

const CreditHistory = () => {
  const [selectedTableId, setSelectedTableId] = useState("");
  const [sortingState, setSortingState] = useState<{
    colId: string;
    sort: string;
    sortIndex: number;
  }>({
    colId: "",
    sort: "",
    sortIndex: 0,
  });

  const { data: tableData } = useTableList();

  const statusRenderer = useCallback(
    (props: ICellRendererParams<any, CreditHistoryData>) => {
      console.log("myProps", props.value);
      const cellValue = props.value;
      if (!cellValue) return "";

      if (cellValue.isCancelled) {
        return (
          <span className="flex max-w-full items-center text-red-500">
            Cancelled
          </span>
        );
      }

      if (cellValue.isQueued) {
        return (
          <span className="flex max-w-full items-center text-darkGray">
            Queued
          </span>
        );
      }

      if (cellValue.isProcessing) {
        return (
          <span className="flex h-full max-w-full items-center text-yellow-500">
            Processing
          </span>
        );
      }
      if (!cellValue.isProcessing) {
        return (
          <span className="flex max-h-[20px] max-w-full items-center text-green-500">
            Processed
          </span>
        );
      }
      return <div></div>;
    },
    [],
  );

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "#",
      maxWidth: 100,
      valueGetter: "node.id",
      cellRenderer: (props: any) => {
        if (props.value !== undefined) {
          return props.value;
        } else {
          return (
            <img src="https://www.ag-grid.com/example-assets/loading.gif" />
          );
        }
      },
    },
    { field: "enrichmentName", headerName: "Enrichment Name" },
    { field: "tableName", headerName: "Table Name" },
    { field: "creditUsed", headerName: "Credit Used", sortable: true },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: statusRenderer,
      cellClass: "text-left flex items-center",
    },
    { field: "createdAt", headerName: "Processed at" },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: false,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    };
  }, []);

  const getEnrichmentName = (row: CreditHistoryData) => {
    const enrichmentLabel = (row?.metaData?.payload?.bodyData?.enrichmentName ||
      "") as keyof typeof filterComponentMaps;

    return (
      filterComponentMaps[enrichmentLabel]?.title ||
      processingTypeLabelMap[row.processingType] ||
      row.processingType ||
      enrichmentLabel
    );
  };

  const onGridReady = useCallback(
    async (params: GridReadyEvent) => {
      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: async (params: any) => {
          const nextPage = Math.ceil(params.endRow / 50);

          const funcParams = {
            pageNumber: nextPage,
            tableId: selectedTableId,
            sortingKey: "",
            sortOrder: sortingState.sort,
          };

          if (sortingState.colId) {
            funcParams.sortingKey = `metaData.${sortingState.colId}`;
          }

          const response = await tableService.getCreditHistory({
            ...funcParams,
          });

          if (response.success && response.data) {
            const rowsData = response.data.map((row: CreditHistoryData) => {
              return {
                id: row._id,
                enrichmentName: getEnrichmentName(row),
                tableName: row.tableId.name,
                creditUsed: row.metaData?.creditUsed || 0,
                status: row,
                createdAt: dayjs(row.createdAt).format("DD MMM YY hh:mm A"),
              };
            });
            const lastRow =
              params.endRow >= response.totalCount ? response.totalCount : -1;
            params.successCallback(rowsData, lastRow);
          }
        },
      };
      params.api.setGridOption("datasource", dataSource);
    },
    [selectedTableId, sortingState],
  );

  if (!tableData) return null;

  return (
    <div className="w-full">
      <div className="mb-4 ml-auto flex max-w-[50%] items-center justify-end">
        <p className="w-[100px]">Filter By:</p>
        <Select
          placeholder="Select Table"
          onChange={(e) => {
            setSelectedTableId(e.target.value);
          }}
          value={selectedTableId}
        >
          {tableData.map((table) => (
            <option key={table._id} value={table._id}>
              {table.name}
            </option>
          ))}
        </Select>
      </div>
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: `calc(100vh - 200px)` }}
      >
        <AgGridReact
          key={`credit-history-${selectedTableId}-${sortingState.colId}-${sortingState.sort}`}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowBuffer={0}
          rowSelection={"multiple"}
          rowModelType={"infinite"}
          cacheBlockSize={50}
          rowClass="border-1 border-gray-200"
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={1000}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
          onSortChanged={(event: SortChangedEvent) => {
            const sortingCol = event.api
              .getColumnState()
              .filter(function (s) {
                return s.sort != null;
              })
              .map(function (s) {
                return {
                  colId: s.colId,
                  sort: s.sort,
                  sortIndex: s.sortIndex,
                };
              });
            const sortingItem = sortingCol[0];
            if (sortingItem) {
              // @ts-ignore
              setSortingState((prevState) => {
                // if sortingItem.colId === prevState.colId and sortingItem.sort === prevState.sort, then swap it
                if (
                  sortingItem.colId === prevState.colId &&
                  sortingItem.sort === prevState.sort
                ) {
                  return {
                    colId: sortingItem.colId,
                    sort: sortingItem.sort === "asc" ? "desc" : "asc",
                    sortIndex: sortingItem.sortIndex,
                  };
                }
                return {
                  colId: sortingItem.colId,
                  sort: sortingItem.sort,
                  sortIndex: sortingItem.sortIndex,
                };
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default CreditHistory;
