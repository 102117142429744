import { Switch } from "@chakra-ui/react";

function SelectResponseColumn({
  responseColumns,
  handleSwitch,
}: {
  responseColumns: {
    isChecked: boolean;
    label: string;
    id: string;
  }[];
  handleSwitch: (id: string) => void;
}) {
  return (
    <div className="flex flex-col gap-[8px]">
      <div className="mb-2 flex items-center justify-between">
        <p className="text-base font-semibold">
          Add data as columns to your table
        </p>
        <p className="text-[14px] text-[#676d7e]">
          {responseColumns.filter((item) => item.isChecked).length} columns
          selected
        </p>
      </div>
      <div className="space-y-4">
        {responseColumns.map(({ id, label, isChecked }) => (
          <div
            key={id}
            className="flex w-full items-center justify-between rounded-[6px] bg-[#FAFAFB] px-4 py-2"
          >
            <p className="text-[16px] font-[500]">{label}</p>
            <Switch
              size={"md"}
              isChecked={isChecked}
              id={id}
              onChange={() => handleSwitch(id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectResponseColumn;
