import LeadMagicLogo from "@/assets/images/leadmagic.png";
import APIKeyIntegration from "./APIKeyIntegration";
const LeadMagicIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"leadMagicKey"}
      providerTitle="LeadMagic"
      providerIcon={LeadMagicLogo}
    />
  );
};

export default LeadMagicIntegration;
