import {
  Box,
  Button,
  ButtonGroup,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { colDefs } from "./columns/colDef";

import AIIcon from "@/assets/svgs/ai.svg";
import ExportIcon from "@/assets/svgs/export.svg";
import AccountMonitoringImportModal from "@/components/Common/AccountMonitoring/AccountMonitoringImportModal";
import RunningProcesses from "@/components/Common/RunningProcesses";
import { DeleteRowButton } from "@/components/DataTable/DeleteRowButton";
import { FilterSidebar } from "@/components/DataTable/FilterSidebar";
import { Table } from "@/components/DataTable/Table";
import { useExport } from "@/components/DataTable/Table/hooks/useExport";
import { TableSearch } from "@/components/DataTable/TableSearch";
import { useTabState } from "@/pages/AccountMonitoring";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { LeadEntry } from "@/types/accountMonitoring.types";
import { TableDataQueryParams } from "@/types/table.types";
import { FilterModel } from "ag-grid-community";
import { useCreateTable } from "../Common/AccountMonitoring/hooks/useCreateTable";
import { useTable } from "../DataTable/Table/hooks/useTable";
import Icons from "../Icons";
import { ExportColumnsList, SearchableColumns } from "./constants";
import { useImportModelProps } from "./hooks/useImportModel";
import { useJobSync } from "./hooks/useSync";
import { useJobTracker } from "./hooks/useTrack";
import { StatusFilter } from "./StatusFilter";
import Walkthrough from "./Walkthrough/Walkthrough";
import { userStore } from "@/stores/user.store";

const JobChangesComponent = ({
  tabIndex,
  isModalViewed,
}: {
  tabIndex?: number;
  isModalViewed?: boolean;
}) => {
  const toast = useToast({
    position: "top-right",
    isClosable: true,
    duration: 3000,
  });
  const exportMutation = useExport({
    selected: true,
    filters: true,
    sort: true,
    exportColumns: ExportColumnsList,
    fileName: "job-changes",
    colDefs,
    dataSource: (param) => {
      const result = accountMonitoringService.getAllLeads(param);
      toast.promise(result, {
        loading: {
          title: "Processing CSV",
          description:
            param.limit ?? 1 > 500
              ? "We've started processing your file..."
              : "Your CSV is being processed, Please wait...",
          duration: 4000,
          isClosable: true,
        },
        success: {
          title: "Success",
          description: "Your CSV is ready to download",
          duration: 3000,
          isClosable: true,
        },
        error: (error) => {
          return {
            title: "Failed to export csv",
            description:
              // @ts-expect-error error
              error?.data?.message ?? error?.message ?? "Something went wrong",
            duration: 3000,
            isClosable: true,
          };
        },
      });
      return result;
    },
  });
  const tableLoading = useTable().loading;
  const { trackJobChanges } = useJobTracker();

  const { selectedRowCount } = useTable();
  const importModelProps = useImportModelProps();
  const tabControllerSetHandlers = useTabState((state) => state.setHandlers);
  const tabControllerSetLoading = useTabState((state) => state.setLoading);
  const isUpgradeBannerShown = userStore((state) =>
    state.isUpgradeBannerShown(),
  );

  // sync tab with tab controller
  useEffect(() => {
    if (typeof tabIndex === "undefined") return;
    tabControllerSetHandlers(tabIndex, {
      onImport: () => importModelProps.setIsOpen(true),
    });
    tabControllerSetLoading(tabIndex, tableLoading);
  }, [importModelProps.setIsOpen, tableLoading, tabIndex]);
  const processingData = useJobSync();
  const { mutation: createTable, exportableRowCount } = useCreateTable(
    "jobChange",
    true,
    true,
  );
  const onDelete = useCallback(
    (params: { rowIds: string[] | "all"; filters?: FilterModel | null }) =>
      accountMonitoringService.deleteLeads({
        leads: params.rowIds,
        filters: params.filters,
      }),
    [],
  );
  return (
    <>
      <div className="flex size-full">
        <FilterSidebar
          id="jobChanges"
          valuesSource={() =>
            accountMonitoringService
              .getAllLeadCompaniesOptions()
              .then((res) => res.data)
          }
          gridFields={["current.companyName"]}
        />
        <div className="flex-1 rounded-2xl bg-white">
          <Stack
            px={5}
            py={3}
            direction="row"
            justifyContent={"space-between"}
            borderBottom={"1px solid #E5E5E5"}
          >
            <ButtonGroup fontWeight={"200"} gap={"16px"}>
              <Button
                isDisabled={trackJobChanges.isPending || selectedRowCount == 0}
                pointerEvents={trackJobChanges.isPending ? "none" : "auto"}
                fontSize={"14px"}
                fontWeight={500}
                bg="#F5F3FF"
                onClick={() => trackJobChanges.mutate()}
                borderRadius={"20px"}
                leftIcon={
                  trackJobChanges.isPending ? (
                    <Spinner size="sm" color="#5024DC" />
                  ) : (
                    <img src={AIIcon} alt="AI Icon" />
                  )
                }
              >
                Refresh Job Change
              </Button>
              <Button
                isDisabled={createTable.isPending || exportableRowCount == 0}
                pointerEvents={createTable.isPending ? "none" : "auto"}
                fontSize={"14px"}
                fontWeight={500}
                bg="#F5F3FF"
                onClick={() => createTable.mutate()}
                leftIcon={
                  createTable.isPending ? (
                    <Spinner size="sm" color="#5024DC" />
                  ) : (
                    <Icons.PlusIcon />
                  )
                }
                rightIcon={
                  <Text opacity={exportableRowCount == 0 ? 0 : 1} color="grey">
                    {exportableRowCount}
                  </Text>
                }
                borderRadius={"20px"}
              >
                Add to Table
              </Button>
              <DeleteRowButton action={onDelete} filter />
            </ButtonGroup>
            <Stack direction={"row"} gap={"16px"}>
              <StatusFilter />
              <TableSearch gridFields={SearchableColumns} />
              <ButtonGroup>
                <Button
                  isDisabled={exportMutation.isPending}
                  px={8}
                  bg="#F5F3FF"
                  fontSize="14px"
                  fontWeight={500}
                  leftIcon={
                    exportMutation.isPending ? (
                      <Spinner
                        className="m-0 !size-[1em] p-0"
                        size="sm"
                        color="#5024DC"
                      />
                    ) : (
                      <img
                        className="size-[1em]"
                        src={ExportIcon}
                        alt="Export Icon"
                        aria-label="Export Icon"
                      ></img>
                    )
                  }
                  borderRadius={"20px"}
                  onClick={() => exportMutation.mutate()}
                  rightIcon={
                    <Text
                      opacity={exportableRowCount == 0 ? 0 : 1}
                      color="grey"
                    >
                      {exportableRowCount}
                    </Text>
                  }
                >
                  Export Data
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
          <Box
            pos={"relative"}
            h={`calc(100vh - 183px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
            p={3}
            pr={1}
            className="custom-scrollbar"
          >
            <Table<LeadEntry>
              id="jobChanges"
              rowModelType="infinite"
              columns={colDefs}
              dataSource={(params: TableDataQueryParams) =>
                accountMonitoringService.getAllLeads(params)
              }
            />
            <Box className="absolute bottom-[-10px] left-0 scale-75">
              {!!processingData?.data?.length && (
                <RunningProcesses
                  processesData={processingData.data}
                  types="track_job_changes"
                />
              )}
            </Box>
          </Box>
        </div>
      </div>
      <AccountMonitoringImportModal {...importModelProps} />
      <Walkthrough isModalViewed={isModalViewed} />
    </>
  );
};

export const JobChanges = ({ tabIndex }: { tabIndex?: number }) => {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <JobChangesComponent tabIndex={tabIndex} />
    </ErrorBoundary>
  );
};
