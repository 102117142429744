import { PricingType } from "@/types/pricing.types";

export const pricings: PricingType[] = [
  {
    title: "Starter",
    description: "Best for individuals",
    features: [
      "Create unlimited tables",
      "Unlimited users",
      "50+ Bulk enrichment",
      "Share workspace",
      "Use your own API keys",
      "AI research agent",
      "Export to CSV",
      "Chrome extension (LinkedIn)",
      "Email responder (Gmail)",
    ],
    mostPopular: false,
    defaultPrice: 85,
    annualDefaultPrice: 64,
    tiers: [
      {
        price: 85,
        credits: 2000,
        priceId: "price_1OsCoTDoimYFMUv0ZvaeGNe3",
      },
    ],
    tiersAnnual: [
      {
        credits: 24000,
        price: 64,
        priceId: "price_1PnpziDoimYFMUv0W7NALEmS",
      },
    ],
  },
  {
    title: "Growth",
    description: "Best for small teams.",
    features: [
      "Everything from Starter",
      "Email tool integration",
      "HTTP API",
      "Webhooks",
      "Company news tracking",
      "Custom Intent Scoring",
    ],
    defaultPrice: 189,
    annualDefaultPrice: 151,
    tiers: [
      {
        credits: 5000,
        price: 189,
        priceId: "price_1OsCpwDoimYFMUv0cmPW9nrE",
      },
      {
        credits: 10000,
        price: 299,
        priceId: "price_1PlcgnDoimYFMUv0tP0MwZRy",
      },
      {
        credits: 12500,
        price: 350,
        priceId: "price_1Plcn4DoimYFMUv07OutdK34",
      },
    ],
    tiersAnnual: [
      {
        credits: 60000,
        price: 151,
        priceId: "price_1PnqWdDoimYFMUv0kfGPs20T",
      },
      {
        credits: 120000,
        price: 239,
        priceId: "price_1PnqbKDoimYFMUv084Zcoqji",
      },
      {
        credits: 150000,
        price: 280,
        priceId: "price_1PnqdaDoimYFMUv0pDIjE59D",
      },
    ],
  },
  {
    title: "Pro",
    description: "Best for growing teams.",
    features: [
      "Everything from growth",
      "CRM Integration",
      "Intent/Triggers",
      "Job Change alerts",
      "Hiring triggers",
      "Priority Support",
    ],
    defaultPrice: 500,
    annualDefaultPrice: 400,
    tiers: [
      {
        credits: 18000,
        price: 500,
        priceId: "price_1PlcuVDoimYFMUv0dXi1yC1v",
      },
      {
        credits: 50000,
        price: 750,
        priceId: "price_1PlcyfDoimYFMUv0DYEuARVo",
      },
      {
        credits: 70000,
        price: 950,
        priceId: "price_1Pld0NDoimYFMUv03qZdnxlX",
      },
      {
        credits: 100000,
        price: 1300,
        priceId: "price_1PldjaDoimYFMUv0t8qqMziP",
      },
      {
        credits: 150000,
        price: 1750,
        priceId: "price_1PldmgDoimYFMUv0UCkxNHOe",
      },
    ],
    tiersAnnual: [
      {
        credits: 216000,
        price: 400,
        priceId: "price_1PnqgPDoimYFMUv0q0L6A65k",
      },
      {
        credits: 600000,
        price: 600,
        priceId: "price_1PnqijDoimYFMUv0ayewIdwn",
      },
      {
        credits: 840000,
        price: 760,
        priceId: "price_1PnqlXDoimYFMUv0pG3uiNtQ",
      },
      {
        credits: 1200000,
        price: 1040,
        priceId: "price_1PnqnSDoimYFMUv0CfzYkOgi",
      },
      {
        credits: 1800000,
        price: 1400,
        priceId: "price_1PnqpmDoimYFMUv0bg8n5yRQ",
      },
    ],
  },
  {
    title: "Unlimited",
    description: "Most cost effective.",
    features: [
      "Everything from Pro",
      "Everything from growth",
      "Unlimited users",
      "Unlimited enrichments",
      "Job change tracking",
      "CRM Integration",
      "Unlimited company funding and growth information",
    ],
    defaultPrice: 750,
    annualDefaultPrice: 600,
    tiers: [
      {
        credits: 50000,
        price: 750,
        priceId: "price_1Q9hSDDoimYFMUv0QrJ9xFQi",
      },
    ],
    tiersAnnual: [
      {
        credits: 600000,
        price: 600,
        priceId: "price_1Q9hUqDoimYFMUv0PLe6nKdy",
      },
    ],
    mostPopular: true,
  },
  {
    title: "Enterprise",

    description: "Best for large teams.",
    features: [
      "Everything from Pro",
      "Super Priority support",
      "Higher volume of credits",
    ],
    defaultPrice: "Contact us",
    annualDefaultPrice: "Contact us",
    isContactRequired: true,
  },
];
