import { Button } from "@chakra-ui/button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Input } from "@chakra-ui/input";

import { Option } from "../Common/MultiSelectDropdown/types";
import { userStore } from "@/stores/user.store";

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  selectedFilters: {
    jobTitle: string;
    location: string;
    jobTitleFilter: string;
    experience: Option[];
    jobType: Option[];
    postedAgo: Option;
    resultLimit: number;
  };
  setIsOpen: (val: boolean) => void;
  handleTrack: () => void;
  updateSelectedFilters: (
    key: string,
    value: Option[] | Option | string | number,
  ) => void;
};

const TrackActiveJobsModal = ({
  isOpen,
  isLoading,
  selectedFilters,
  updateSelectedFilters,
  setIsOpen,
  handleTrack,
}: Props) => {
  const currentUser = userStore((state) => state.currentUser);

  return (
    <Modal
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
      scrollBehavior="inside"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <ModalHeader>
          <p className="w-full text-center text-[22px]">Track Active Jobs</p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="mb-6 flex flex-col gap-3">
          <Input
            size="md"
            type="text"
            placeholder="Please enter the job title *"
            value={selectedFilters.jobTitle}
            onChange={(e) => {
              updateSelectedFilters("jobTitle", e.target.value);
            }}
          />
          <Input
            size="md"
            type="text"
            placeholder="Please enter the full location, eg. United States *"
            value={selectedFilters.location}
            onChange={(e) => {
              updateSelectedFilters("location", e.target.value);
            }}
          />

          {currentUser?.role === "admin" && (
            <Input
              size="md"
              type="number"
              placeholder="Please enter the limit"
              defaultValue={100}
              value={selectedFilters.resultLimit}
              onChange={(e) => {
                updateSelectedFilters("resultLimit", e.target.value);
              }}
            />
          )}

          <Button
            isDisabled={!selectedFilters.jobTitle || !selectedFilters.location}
            isLoading={isLoading}
            onClick={() => handleTrack()}
            className="mt-3 w-full hover:opacity-80 !py-4"
            style={{
              background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
              color: "#fff",
            }}
          >
            <div>Track</div>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TrackActiveJobsModal;
