import { useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";
import { convertSlateToText } from "@/utils";

import { useEnrichStore } from "@/stores/enrich.store";

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const FindShoppingResults = () => {
  const [query, setQuery] = useState<any>(initialSlateValue);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        // @ts-ignore
        setQuery(enrichmentData?.query || initialSlateValue);
      }
    }
  }, [selectedColumnToEdit, viewMode]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Find Shopping Results using Google Shopping from a query."
          link={""}
        />
        <div className="py-4">
          <p className="pb-1 text-base font-semibold">Query</p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={query}
            setSlateValue={setQuery}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!convertSlateToText(query).text}
        apiPayload={{
          query,
        }}
      />
    </>
  );
};

export default FindShoppingResults;
