import { getInitialColumn } from "@/utils";
import { REGEX } from "../../constants";

export const options = [
  {
    name: "Company's Recent Posts",
    label: "Company's Recent Posts",
    value: "get-company-posts",
  },
  {
    name: "Person's Recent Posts",
    label: "Person's Recent Posts",
    value: "get-profile-posts",
  },
];

export const functions = [
  {
    value: "get-company-posts",
    getColumn: () => {
      return getInitialColumn(REGEX.WEBSITE);
    },
  },
  {
    value: "get-profile-posts",
    getColumn: () => {
      return getInitialColumn(REGEX.LINKEDINPROFILE);
    },
  },
];
