import { uses_data } from "json-logic-js";
import { formatQuery, RuleGroupTypeIC } from "react-querybuilder";

export const getUUID = () => {
  if (crypto?.randomUUID) {
    return crypto.randomUUID();
  }
  return Array.from({ length: 6 })
    .map(() => Math.random().toString(36))
    .join("-");
};

export const getQueryColumnUsed = (query: RuleGroupTypeIC) => {
  // @ts-expect-error ignore type
  return uses_data(formatQuery(query, "jsonlogic")) as string[];
};
