import PeopleDetails from "./PeopleDetails";

const PeopleAtCompanyRenderer = ({ details }: { details: any }) => {
  return (
    <>
      {details.cellDetails?.metaData && (
        <div className="bg-white shadow-lg rounded-lg p-4 mb-4">
          <div className="mb-4">
            <div className="flex items-center gap-6">
              <div className="w-[20%]">
                <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
                  {details.name}
                </p>
              </div>
            </div>

            <PeopleDetails cellDetails={details.cellDetails} />
          </div>
        </div>
      )}
    </>
  );
};

export default PeopleAtCompanyRenderer;
