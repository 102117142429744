import { Button, Input, Spinner, useToast } from "@chakra-ui/react";
import { useEffect, useMemo, useReducer, useState } from "react";

import KeySelect from "@/components/Common/KeySelect";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import PersanaAccordion from "@/components/Common/PersanaAccordion";
import WatchTutorial from "@/components/Common/WatchTutorial";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";

import { useLemListCampaigns, useProviderList } from "@/hooks/useProviderData";
import { arrayToObject, transformCustomFieldsObject } from "@/lib/utils";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";
import { convertSlateToText } from "@/utils";
import { Descendant } from "slate";

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

type CustomField = {
  id: string;
  name?: string;
  value?: Descendant[];
};

interface EnrichmentDataType extends EnrichmentMetaData {
  campaignId: string;
  leadDetailsBySlateValue: {
    firstName: typeof initialSlateValue;
    lastName: typeof initialSlateValue;
    email: typeof initialSlateValue;
    linkedinUrl: typeof initialSlateValue;
    companyName: typeof initialSlateValue;
    icebreaker: typeof initialSlateValue;
    phone: typeof initialSlateValue;
    picture: typeof initialSlateValue;
    customFields: typeof initialSlateValue;
  };
}

const LeadToLemList = () => {
  const toast = useToast();
  const [fetchCampaigns, setFetchCampaigns] = useState(false);
  const [campaigns, setCampaigns] = useState<Option[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Option | null>(null);
  const [email, setEmail] = useState<any>(initialSlateValue);
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const id = selectedEnrichments?.id;
  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const initialState = {
    firstName: initialSlateValue,
    lastName: initialSlateValue,
    linkedinUrl: initialSlateValue,
    companyName: initialSlateValue,
    icebreaker: initialSlateValue,
    phone: initialSlateValue,
    picture: initialSlateValue,
  };

  function reducer(state: any, action: any) {
    return { ...state, [action.key]: action.value };
  }
  const [leadDetails, dispatch] = useReducer(reducer, initialState);
  const updateDetails = (key: string, value: any) => dispatch({ key, value });

  const leadOptions = [
    {
      name: "First Name",
      state: leadDetails.firstName,
      description: "The first name of the lead",
      setState: (value: any) => {
        updateDetails("firstName", value);
      },
    },
    {
      name: "Last Name",
      state: leadDetails.lastName,
      description: "The last name of the lead",
      setState: (value: any) => {
        updateDetails("lastName", value);
      },
    },
    {
      name: "Linkedin URL",
      state: leadDetails.linkedinUrl,
      description: "The URL of the lead's LinkedIn Profile.",
      setState: (value: any) => {
        updateDetails("linkedinUrl", value);
      },
    },
    {
      name: "Company Name",
      description: "The Company Name of the company where the lead is employed",
      state: leadDetails.companyName,
      setState: (value: any) => {
        updateDetails("companyName", value);
      },
    },
    {
      name: "Icebreaker",
      state: leadDetails.icebreaker,
      description: "Icebreaker text of the lead",
      setState: (value: any) => {
        updateDetails("icebreaker", value);
      },
    },
    {
      name: "Phone Number",
      state: leadDetails.phone,
      description: "The phone number of the lead",
      setState: (value: any) => {
        updateDetails("phone", value);
      },
    },
    {
      name: "Picture URL",
      state: leadDetails.picture,
      description: "A public url containing a picture of the lead",
      setState: (value: any) => {
        updateDetails("picture", value);
      },
    },
  ];

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  const { data: providerList } = useProviderList();
  const isUserApiKeyExists = useMemo(() => {
    if (!providerList?.data) return false;

    return !!providerList.data?.filter(
      (provider) => provider.name === "lemListApiKey",
    ).length;
  }, [providerList]);

  const {
    isLoading,
    data: lemListCampaigns,
    refetch,
  } = useLemListCampaigns(fetchCampaigns);

  const handleCampaignChange = (selected: any) => {
    setSelectedCampaign(selected);
  };
  const handleCustomField = (
    id: string,
    name: string,
    value: Descendant[] | string,
  ): void => {
    setCustomFields((prevFields: CustomField[]) =>
      prevFields.map((field: CustomField) =>
        field.id === id ? { ...field, [name]: value } : field,
      ),
    );
  };

  const handleRemoveCustomField = (name: string): void => {
    const newFields = customFields?.filter(
      (field: CustomField) => field.name !== name,
    );
    setCustomFields(newFields);
  };

  const handleAddCustomField = (): void => {
    const newField: CustomField = {
      id: `custom_field_name_${customFields.length + 1}`,
      name: `Custom Field Name ${customFields.length + 1}`,
      value: initialSlateValue,
    };

    setCustomFields((prevFields: CustomField[]) => [...prevFields, newField]);
  };

  useEffect(() => {
    if (isUserApiKeyExists) {
      if (lemListCampaigns?.success) {
        const formattedData = lemListCampaigns?.data?.map((item: any) => ({
          value: item._id,
          label: item.name,
        }));

        setCampaigns(formattedData);
      } else if (lemListCampaigns && !lemListCampaigns?.success) {
        setCampaigns([]);
        toast({
          position: "top-right",
          title: "Error",
          description:
            lemListCampaigns?.error.message || "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [lemListCampaigns, isUserApiKeyExists]);

  useEffect(() => {
    if (isUserApiKeyExists) {
      if (!fetchCampaigns) {
        setFetchCampaigns(true);
      } else {
        refetch();
        setSelectedCampaign(null);
      }
    }
  }, [isUserApiKeyExists, providerList]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType | undefined;

      if (enrichmentData) {
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });

        const slateValueDetails = enrichmentData.leadDetailsBySlateValue;

        const _customFields = slateValueDetails.customFields;

        const formattedCustomFields = transformCustomFieldsObject(
          _customFields || {},
        );
        setCustomFields(formattedCustomFields);

        setEmail(slateValueDetails.email);

        for (const [key, value] of Object.entries(slateValueDetails)) {
          updateDetails(key, value);
        }

        if (campaigns?.length > 0) {
          const selectedCampaign = campaigns.find(
            (item) => item.value === enrichmentData.campaignId,
          );
          selectedCampaign && setSelectedCampaign(selectedCampaign);
        }
      }
    }
  }, [viewMode, selectedColumnToEdit, campaigns]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={
            id === "updateLeadInLemListCampaign"
              ? `Update a lead in a campaign in Lemlist.
`
              : `Add a lead to a campaign in Lemlist.`
          }
          link={""}
        />
        <KeySelect
          providerName={"lemListApiKey"}
          providerTitle="Lemlist"
          disablePersanaKey={true}
        />

        <div className="mt-5">
          <div className="flex items-center gap-1">
            <p className="mb-1 text-base font-semibold">Campaign ID</p>
            {isLoading && <Spinner size="sm" />}
          </div>

          <MultiSelectDropdown
            isMulti={false}
            placeholder="choose a campaign"
            closeMenuOnSelect
            options={campaigns || []}
            value={selectedCampaign}
            onChange={handleCampaignChange}
          />
          <div className="flex items-center justify-between">
            <p className="block py-1 text-sm">
              The Campaign you want to add the lead to
            </p>
          </div>
        </div>

        <div className="my-3">
          <p className="pb-1.5 text-[0.95rem] font-medium">
            Email Address{" "}
            {id !== "updateLeadInLemListCampaign" && (
              <span className="text-xs font-bold text-darkTextGray">
                (Optional)
              </span>
            )}
          </p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={email}
            setSlateValue={setEmail}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
          <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
            {"The email address of the lead."}
          </p>
        </div>

        <PersanaAccordion
          defaultIndex={[0]}
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
            marginTop: "20px",
          }}
          items={[
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Lead Details (Optional)
                </button>
              ),

              panel: (
                <div className="space-y-3">
                  {leadOptions.map((item) => (
                    <div key={item.name}>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {item?.name}
                      </p>
                      <CustomTextEditor
                        editorHeight={"4rem"}
                        slateValue={item?.state}
                        setSlateValue={item?.setState}
                        placeholder={`Start typing or use the dropdown to select a column.`}
                      />
                      <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                        {item.description}
                      </p>
                    </div>
                  ))}
                </div>
              ),
            },
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Custom Fields (Optional)
                </button>
              ),

              panel: (
                <>
                  {customFields?.map((field: any, index: number) => (
                    <div
                      className="mt-3 flex items-center justify-start gap-[5px]"
                      key={`other-fields-${index}`}
                    >
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">name</p>
                        <Input
                          maxW={"200px"}
                          value={field?.name || ""}
                          placeholder={"Enter name..."}
                          name={"name"}
                          onChange={(e) =>
                            handleCustomField(
                              field.id,
                              e.target.name,
                              e.target.value,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">value</p>
                        <CustomTextEditor
                          editorHeight={"1rem"}
                          slateValue={field.value}
                          setSlateValue={(val) =>
                            handleCustomField(field.id, "value", val)
                          }
                          showDetails={false}
                        />
                      </div>
                      <Button
                        className="mb-1 self-end"
                        onClick={() => {
                          handleRemoveCustomField(field.name);
                        }}
                        size={"sm"}
                      >
                        X
                      </Button>
                    </div>
                  ))}

                  {customFields?.length < 20 ? (
                    <div className="mt-5">
                      <Button size={"sm"} onClick={handleAddCustomField}>
                        + Add New Custom Field
                      </Button>
                      <span className={"block py-1 text-sm text-[#676d7e]"}>
                        Any fields you want to add to the lead. Put the name of
                        the field on the left and the value on the right.
                      </span>
                    </div>
                  ) : null}
                </>
              ),
            },
          ]}
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={
          id === "updateLeadInLemListCampaign"
            ? !selectedCampaign || !convertSlateToText(email).text
            : !selectedCampaign
        }
        apiPayload={{
          apiKeyName: "lemListApiKey",
          campaignId: selectedCampaign?.value,
          operation: id === "updateLeadInLemListCampaign" ? "update" : "add",
          leadDetailsBySlateValue: {
            ...leadDetails,
            email,
            customFields:
              customFields?.length > 0 ? arrayToObject(customFields) : {},
          },
        }}
      />
    </>
  );
};

export default LeadToLemList;
