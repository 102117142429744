import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import TextRenderer from "./TextRenderer";

const PeopleDetailsRenderer = ({ cellDetails }: { cellDetails: any }) => {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 gap-0">
        {cellDetails?.metaData?.responseValue?.map(
          (item: any, index: number) => (
            <Accordion allowToggle key={index}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      className="font-medium text-md text-[#677084]"
                    >
                      {item.first_name || ""} {item.last_name || ""}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {Object.entries(item)?.map(([key, value]: any) => (
                    <div
                      className="grid grid-cols-2 gap-0 border border-gray-300"
                      key={key}
                    >
                      <div className="border-r border-gray-300">
                        <p className="text-md px-4 py-2 text-[#677084] font-medium">
                          {key}
                        </p>
                      </div>
                      <div className="border-l border-gray-300 overflow-y-auto max-h-[200px] !max-w-[100%]">
                        <p className="text-md px-4 py-2 text-[#677084] whitespace-normal break-words">
                          {typeof value === "object" ? (
                            JSON.stringify(value)
                          ) : (
                            <TextRenderer value={value} />
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ),
        )}
      </div>
    </div>
  );
};

export default PeopleDetailsRenderer;
