import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import TailwindModalLayout from "@/components/Common/TailwindModalLayout";
import { topupOptions } from "./constants";
import { useState } from "react";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { Button, useToast } from "@chakra-ui/react";
import userService from "@/services/user.service";

interface PopupModalProps {
  isOpenModal: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  currentPlan: {
    credits?: string;
    planType?: string;
    amount?: number;
    priceId?: string;
  };
}

function TopupModal({
  isOpenModal,
  setIsModalOpen,
  currentPlan,
}: PopupModalProps) {
  const [selectedTopup, setSelectedTopup] = useState<Option | null>(null);
  const [calculatedAmount, setCalculatedAmount] = useState({
    baseCost: "",
    totalCost: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();

  const handleSelectTopupChange = (selected: Option) => {
    setSelectedTopup(selected);

    if (currentPlan && selected) {
      const perCreditPrice = Math.max(
        // @ts-ignore
        (currentPlan?.amount || 0) / parseInt(currentPlan?.credits || "0") || 0,
        0,
      )?.toFixed(4);

      const baseCost = parseInt(selected.value) * parseFloat(perCreditPrice);

      const totalCost = baseCost * 1.25;

      setCalculatedAmount({
        baseCost: baseCost?.toFixed(2),
        totalCost: totalCost?.toFixed(2),
      });
    } else {
      setCalculatedAmount({
        baseCost: "",
        totalCost: "",
      });
    }
  };

  const handleTopup = async () => {
    setIsLoading(true);
    try {
      const response = await userService.generateTopUpCreditsLink({
        credits: selectedTopup?.value || "",
      });

      if (response?.success && response?.data?.url) {
        window.location.href = response?.data?.url;
      } else {
        toast({
          title: "Error",
          // @ts-ignore
          description: response?.message || "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      toast({
        title: "Error",
        // @ts-ignore
        description: error?.message || "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  function getStarterLimitValues(planType: string) {
    const result = [];
    for (const option of topupOptions) {
      result.push(option);
      if (option.limit === planType) {
        break;
      }
    }
    return result;
  }

  return (
    <TailwindModalLayout
      modalOpen={isOpenModal}
      setModalOpen={setIsModalOpen}
      closeOnOuterClick
      overlayClassName="bg-[rgba(0,_0,_0,_0.18)]"
      dialogClassName="!z-[2000]"
    >
      <div className="relative inline-block h-full overflow-hidden p-4 text-left align-bottom font-title transition-all sm:w-full sm:max-w-[44rem] sm:align-middle">
        <div className="space-y-8 rounded-md border border-[#C03EDC] bg-white p-5 font-title shadow">
          <div className="flex flex-col items-center space-y-1">
            <h3 className="text-center text-2xl font-bold">Top up credits</h3>
            <p className="text-center text-sm">
              Add more credits to your account <br /> to continue enjoying
              premium features without interruption.
            </p>
          </div>

          <div className="mx-auto flex max-w-[28rem] items-center justify-center space-x-3">
            <p>
              <strong>Select credits to top up:</strong>
            </p>
            <div className="grow">
              <MultiSelectDropdown
                options={
                  currentPlan?.planType === "starter"
                    ? getStarterLimitValues(currentPlan?.planType)
                    : topupOptions
                }
                value={selectedTopup}
                onChange={(selected) => {
                  const selectedTopup = selected as Option;
                  handleSelectTopupChange(selectedTopup);
                }}
                placeholder="Select credits to top up"
                isMulti={false}
                isCreateAble={false}
                closeMenuOnSelect
              />
            </div>
          </div>

          <div
            className="mx-auto max-w-[30rem] rounded-md border border-[#C03EDC] px-4 py-2"
            style={{
              boxShadow: "2px 2px 40px 0px #373F510D",
            }}
          >
            <p className="text-sm">
              <span className="font-bold">Top-up Summary:</span>
              You selected{" "}
              <span className="font-bold">
                {selectedTopup?.value || 0}
              </span>{" "}
              credits for a base cost of{" "}
              <span className="font-bold">
                {calculatedAmount?.baseCost || 0}
              </span>
              , total cost with 25% extra charge is{" "}
              <span className="font-bold">
                {calculatedAmount?.totalCost || 0}
              </span>
              .
            </p>
          </div>

          <div className="flex items-center justify-center space-x-6">
            <button className="rounded-md border border-[#D0D4DC] px-8 py-2.5 leading-none">
              Cancel
            </button>
            <Button
              className="!h-auto !rounded-md !bg-[#C03EDC] !px-8 !py-[10.5px] !font-title !leading-none !text-white"
              isDisabled={!selectedTopup}
              onClick={handleTopup}
              isLoading={isLoading}
            >
              Top up{" "}
              {calculatedAmount?.totalCost && calculatedAmount?.totalCost !== ""
                ? `($${calculatedAmount?.totalCost})`
                : ""}
            </Button>
          </div>
        </div>
      </div>
    </TailwindModalLayout>
  );
}

export default TopupModal;
