import { useEffect, useState } from "react";
import { Radio, RadioGroup, Stack, Switch } from "@chakra-ui/react";

import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import { useTableStore } from "@/stores/table.store";

import type { TSelectedColumnOption } from "@/types/common.types";

import { RESPONSE_COLUMNS, functions, options } from "./constant";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { EnrichmentMetaData } from "@/types/table.types";

interface EnrichmentDataType extends EnrichmentMetaData {
  extraColumns?: string[];
}

const CompanyDetails = () => {
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const [selectedSearchType, setSelectedSearchType] = useState(
    () => options[0],
  );
  const [responseColumns, setResponseColumns] = useState(
    () => RESPONSE_COLUMNS,
  );

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);

  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  function findObjectByKey(array: any, key: string, value: string) {
    return array.find((item: any) => item[key] === value);
  }
  const handleSwitch = (id: string) => {
    setResponseColumns((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item,
      ),
    );
  };

  useEffect(() => {
    setResponseColumns((prevState) =>
      prevState.map((item) =>
        item.id === selectedEnrichments?.id
          ? { ...item, isChecked: true }
          : item,
      ),
    );
  }, [selectedEnrichments]);

  useEffect(() => {
    if (viewMode !== "edit") {
      functions.map((func) => {
        if (func.value === selectedSearchType.value) {
          const initialColumn = func.getColumn();
          if (initialColumn) {
            setSelectedOption({
              key: initialColumn.name,
              keyId: initialColumn._id,
              iconType: initialColumn.metaData?.iconType || "url",
            });
          }
        }
      });
    } else {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;
      if (enrichmentData) {
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    }
  }, [columns, selectedSearchType]);

  // Handle Edit Mode
  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        if (enrichmentData.extraColumns) {
          setResponseColumns((prevState) =>
            prevState.map((item) => ({
              ...item,
              isChecked:
                enrichmentData?.extraColumns?.includes(item.label) ?? false,
            })),
          );
        }
        // FUTURE: This function needs to be changed if we have more than one state to be updated
        const relyOnColumn = columns.find(
          (column) => column._id === enrichmentData.columnId,
        );
        if (relyOnColumn) {
          setSelectedOption({
            key: relyOnColumn.name,
            keyId: relyOnColumn._id,
            iconType: relyOnColumn.metaData?.iconType || "url",
          });
        }

        setSelectedSearchType((prevState) => ({
          ...prevState,
          value: enrichmentData.searchType,
        }));
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <div className="mb-4">
          <RadioGroup
            onChange={(value) => {
              const foundObject = findObjectByKey(options, "value", value);
              setSelectedSearchType(foundObject);
              setSelectedOption(null);
            }}
            value={selectedSearchType.value}
          >
            <Stack direction="row">
              {options.map((option) => (
                <Radio value={option.value} key={option.value}>
                  {option.name}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </div>

        <p className="pb-1 text-base font-semibold">
          {selectedSearchType?.label}
        </p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <div className="flex flex-col gap-[8px] mt-4">
          <div className="flex items-center justify-between mb-2">
            <p className="text-base font-semibold">
              Add data as columns to your table
            </p>
            <p className="text-grey-400 text-[14px] text-[#676d7e]">
              {responseColumns.filter((item) => item.isChecked).length} columns
              selected
            </p>
          </div>
          <div className="space-y-4">
            {responseColumns.map(({ id, label, isChecked }) => (
              <div
                key={id}
                className="flex w-full items-center justify-between rounded-[6px] bg-[#FAFAFB] px-4 py-2"
              >
                <p className="text-[16px] font-[500]">{label}</p>
                <Switch
                  size={"md"}
                  isChecked={isChecked}
                  id={id}
                  onChange={() => handleSwitch(id)}
                />
              </div>
            ))}
          </div>
        </div>

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
          searchType: selectedSearchType?.value,
          extraColumns: responseColumns
            .filter((item) => item.isChecked)
            .map((item) => item.label),
        }}
      />
    </>
  );
};

export default CompanyDetails;
