import { useNavigate } from "@tanstack/react-router";
import JobChangeIcon from "@/assets/svgs/auto-pilot-find-jobs.svg";
import HiringTrendIcon from "@/assets/svgs/auto-pilot-find-hiring.svg";
import FindLeadsIcon from "@/assets/svgs/auto-pilot-find-leads.svg";
import TriggerIcon from "@/assets/svgs/auto-pilot-trigger.svg";

interface ActionItem {
  id: string;
  title: string;
  description: string;
  icon: string;
  isDisabled: boolean;
  onClick: () => void;
}

const Triggers = () => {
  const navigate = useNavigate();

  const allActions: ActionItem[] = [
    {
      id: "NewLeads",
      title: "Find New Leads",
      description: "Find new leads that fit your Ideal Customer Profile (ICP).",
      icon: FindLeadsIcon,
      isDisabled: false,
      onClick: () =>
        navigate({
          to: "/auto-pilot/leads-workflow",
        }),
    },
    {
      id: "CompanyLikes",
      title: "Social Posts Engagements",
      description:
        "Automatically engage and convert Likes & Comments to Leads.",
      icon: FindLeadsIcon,
      isDisabled: false,
      onClick: () => navigate({ to: "/auto-pilot/post-engagement-workflow" }),
    },
    {
      id: "JobTracking",
      title: "Find Job Changes",
      description: "Easily track & monitor career changes among your clients.",
      icon: JobChangeIcon,
      isDisabled: false,
      onClick: () => navigate({ to: "/account-monitoring" }),
    },
    {
      id: "HiringTrend",
      title: "Find Hiring Trends",
      description:
        "Track hiring trends in your customer base and target market.",
      icon: HiringTrendIcon,
      isDisabled: false,
      onClick: () => navigate({ to: "/account-monitoring" }),
    },
  ];

  return (
    <div className="min-h-[520px] rounded-[8px] border border-[#F0EFF1] p-[24px]">
      <div className="mb-4 flex items-center gap-2">
        <img src={TriggerIcon} />
        <p className="text-[14px] font-medium text-black">Signals & Triggers</p>
      </div>
      <div className="flex flex-wrap gap-4">
        {allActions.map((action) => (
          <div
            key={action.id}
            className="flex min-h-[180px] w-[calc(50%-1rem)] flex-col justify-between rounded-lg border border-[#D0D4DC] bg-white shadow-md"
          >
            <div className="flex grow flex-col gap-3 p-4">
              <div className="flex items-center justify-between">
                <img src={action.icon} alt={action.title} className="size-8" />
                <div className="rounded-[10px] bg-[#F5F3FF] px-[12px] py-[8px] text-[14px] font-medium text-[#5024DC]">
                  Auto Pilot
                </div>
              </div>
              <div className="my-2">
                <p className="text-[16px] font-medium text-[#0F0D1C]">
                  {action.title}
                </p>
                <p className="mt-1 text-[13px] font-normal text-[#0F0D1C]">
                  {action.description}
                </p>
              </div>
            </div>
            <div
              className="border-gray-300 flex cursor-pointer items-center justify-center rounded-b-lg border-t bg-[#F5F3FF] px-4 py-3"
              onClick={action.onClick}
              role="button"
              aria-disabled={action.isDisabled}
            >
              <p className="text-gray-800 text-sm font-semibold">Get Started</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Triggers;
