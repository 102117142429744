import { Button } from "@chakra-ui/react";
import Icons from "@/components/Icons";

type Props = {
  isConfigured: boolean;
  handleConfig: () => void;
};
const Toolbar = ({ isConfigured, handleConfig }: Props) => {
  return (
    <Button
      size={"sm"}
      variant={"outline"}
      leftIcon={<Icons.SettingsIcon />}
      onClick={() => handleConfig()}
    >
      {isConfigured ? "Edit Configuration" : "Configure"}
    </Button>
  );
};

export default Toolbar;
