import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";

import { useModalStore } from "@/stores/modal.store";
import { useProcessingStore } from "@/stores/processing.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { defaultPersonalizationPrompts, preferenceOptions } from "./constants";
import { userStore } from "@/stores/user.store";
import { usePresetsStore } from "@/stores/presets.store";
import Icons from "@/components/Icons";
import Settings from "./Settings";
import DefaultPrompt from "./DefaultPrompt";
import PersonalizationPrompt from "./PersonalizationPrompt";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import misServices from "@/services/misc.services";
import { useMutation } from "@tanstack/react-query";
import { useEnrichStore } from "@/stores/enrich.store";
import { allEnrichments } from "@/components/Enrichment/constants";

const DraftEmail = () => {
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const [tabData, setTabData] = useState([
    { id: 1, label: "Preferences" },
    { id: 2, label: "Your Details" },
    { id: 3, label: "Template" },
    { id: 4, label: "Personalization" },
    { id: 5, label: "Other Details (Optional)" },
  ]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [mailDetails, setMailDetails] = useState({
    subject: "",
    body: "",
  });
  const [personalization, setPersonalization] = useState<any>("");
  const [selectedOption] = useState<TSelectedColumnOption | null>(null);
  const [selectedPreference, setSelectedPreference] = useState<Option | null>(
    null,
  );

  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const processesData = useProcessingStore((state) => state.processingData);
  const updateState = useModalStore((state) => state.updateState);
  const { updateState: updateEnrichState } = useEnrichStore();
  const currentUser = userStore((state) => state.currentUser);
  const [profileInfo, setProfileInfo] = useState(() => ({
    firstName: currentUser?.profileInfo?.firstName || "",
    companyName: currentUser?.profileInfo?.companyName || "",
    companyDescription: currentUser?.profileInfo?.companyDescription || "",
    callToAction: currentUser?.profileInfo?.callToAction || "",
  }));

  const selectedPresetValues = usePresetsStore(
    (state) => state.selectedPresetValues,
  );
  const updatePresetState = usePresetsStore((state) => state.updateState);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => misServices.getDraftEmailTemplate(profileInfo),
    onSuccess: (response) => {
      if (response?.data) {
        // @ts-ignore
        handleDetails("body", response?.data);
        return;
      }
    },
    onError: () => {
      handleDetails("body", "");
    },
  });

  const handleUpdateSettings = (fieldName: string, value: string) => {
    setProfileInfo({
      ...profileInfo,
      [fieldName]: value,
    });
  };

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
    updatePresetState({
      presetsModalData: {},
      selectedPresetValues: null,
      endpoint: null,
      body: null,
      headers: [],
      isOpenPresetsModal: false,
      selectedMethod: null,
    });
  };

  const handleDetails = (key: string, value: string) => {
    setMailDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  const handleTabsChange = (index: number) => {
    selectedPreference !== null && setActiveIndex(index);
  };

  const includeTemplateStep =
    selectedPreference?.label !== "Generate Email for LinkedIn Connect";

  const includePersonalizationStep =
    selectedPreference?.label !== "Static Email Template";

  useEffect(() => {
    if (selectedPreference?.label === "Static Email Template") {
      setTabData([
        { id: 1, label: "Preferences" },
        { id: 2, label: "Your Details" },
        { id: 3, label: "Template" },
        // { id: 4, label: "Other Details (Optional)" },
      ]);
    } else if (
      selectedPreference?.label === "Generate Email for LinkedIn Connect"
    ) {
      setTabData([
        { id: 1, label: "Preferences" },
        { id: 2, label: "Your Details" },
        { id: 3, label: "Personalization" },
        // { id: 4, label: "Other Details (Optional)" },
      ]);
    } else {
      setTabData([
        { id: 1, label: "Preferences" },
        { id: 2, label: "Your Details" },
        { id: 3, label: "Template" },
        { id: 4, label: "Personalization" },
        // { id: 5, label: "Other Details (Optional)" },
      ]);
    }
  }, [selectedPreference]);

  useEffect(() => {
    if (processesData?.length) {
      handleModalClose();
    }
  }, [processesData]);

  useEffect(() => {
    if (selectedPresetValues) {
      setPersonalization(selectedPresetValues);
    }
  }, [selectedPresetValues]);

  useEffect(() => {
    if (
      selectedPreference?.label !== "Custom Email Template" &&
      selectedPreference?.label !== "Static Email Template" &&
      selectedPreference?.label !== "Generate Email for LinkedIn Connect" &&
      activeIndex === 2
    ) {
      mutateAsync();
    }
  }, [activeIndex, selectedPreference]);

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "draft-email"}
      motionPreset="scale"
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent className="rounded-[20px]">
        <ModalCloseButton />
        <ModalHeader className="flex items-center gap-3">
          <Icons.MailIcon className="text-[#5024DC]" />
          <p>AI Draft Message</p>
        </ModalHeader>
        <Divider />
        <ModalBody
          className={`!px-0 !pt-0 overflow-auto custom-scrollbar ${activeIndex + 1 !== tabData.length ? "!min-h-[580px] !max-h-[580px]" : "!min-h-[500px] !max-h-[500px]"}`}
        >
          <div className="flex">
            <Box w="full" bg={bgColor} p={8}>
              {/* Navigation Buttons and Stepper at the top */}
              <Flex
                justify="space-between"
                alignItems="center"
                mb={4}
                flexWrap="wrap"
                marginBottom={10}
              >
                <Tabs
                  className="w-full"
                  // onChange={handleTabsChange}
                  index={activeIndex}
                >
                  <TabList className="flex gap-8 mb-4">
                    {tabData?.map(({ label }, index) => (
                      <Tab
                        className="flex items-center gap-2"
                        onClick={() => handleTabsChange(index)}
                      >
                        <div
                          className={`px-2 rounded-full ${
                            activeIndex === index
                              ? "bg-[#5024DC]"
                              : "bg-[#D0D4DC]"
                          } text-white`}
                        >
                          {index + 1}
                        </div>
                        <p
                          className={`text-[16px] font-medium ${
                            activeIndex === index
                              ? "text-[#5024DC]"
                              : "text-[#373F51]"
                          }`}
                        >
                          {label}
                        </p>
                      </Tab>
                    ))}
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <VStack align="stretch">
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          color={textColor}
                        >
                          Select Email Preference <sup>*</sup>
                        </Text>
                        {preferenceOptions?.map((item) => (
                          <div key={item.value} className="mb-1">
                            <Stack
                              ml={1}
                              direction={"column"}
                              p={"16px"}
                              cursor={"pointer"}
                              gap={"8px"}
                              className={`w-full gap-2 px-[24px] py-[12px] rounded-[8px] border border-[#D0D4DC] text-[16px] font-medium transition-all duration-300 ease-in-out ${
                                selectedPreference?.value?.includes(item.value)
                                  ? "bg-[#F5F3FF] border-[#BD90F4] text-[#5024DC] shadow-lg"
                                  : "bg-white text-[#373F51] hover:bg-[#F8F9FB] hover:border-[#B0B4C1]"
                              }`}
                              onClick={() => {
                                setSelectedPreference(item);
                                setPersonalization(
                                  // @ts-ignore
                                  defaultPersonalizationPrompts[item?.label],
                                );
                                if (item.label === "Custom Email Template") {
                                  handleDetails(
                                    "body",
                                    "{{ai_personalization}}",
                                  );
                                } else if (
                                  item.label === "Static Email Template"
                                ) {
                                  handleDetails("body", "");
                                } else if (
                                  item.label ===
                                  "Generate Email for LinkedIn Connect"
                                ) {
                                  handleDetails("body", "\n");
                                }
                              }}
                            >
                              <p>{item.label}</p>
                              <p className="text-xs text-[#4A5264]">
                                {item.value === "People Information" && (
                                  <>
                                    {`Make sure to have {{summary}} column in
                                    your table. Use `}
                                    <span
                                      className="cursor-pointer text-[#5024DC] underline hover:text-[#7B5CED]"
                                      onClick={() => {
                                        handleModalClose();
                                        updateEnrichState({
                                          selectedEnrichments:
                                            allEnrichments?.find(
                                              (item) =>
                                                item.id === "linkedInDetails",
                                            ),
                                          isOpenEnrichModal: false,
                                          viewMode: "create",
                                        });
                                      }}
                                    >
                                      Person Profile
                                    </span>{" "}
                                    enrichment to get the summary details.
                                  </>
                                )}
                                {item.value === "Company Information" && (
                                  <>
                                    {`Make sure to have {{company_info}} column
                                    in your table. Use `}
                                    <span
                                      className="cursor-pointer text-[#5024DC] underline hover:text-[#7B5CED]"
                                      onClick={() => {
                                        handleModalClose();
                                        updateEnrichState({
                                          selectedEnrichments:
                                            allEnrichments?.find(
                                              (item) =>
                                                item.id === "companyDetails",
                                            ),
                                          isOpenEnrichModal: false,
                                          viewMode: "create",
                                        });
                                      }}
                                    >
                                      Company Profile
                                    </span>{" "}
                                    enrichment to get the company details.
                                  </>
                                )}
                                {item.value === "LinkedIn Connect" && (
                                  <>
                                    {`Make sure to have {{person_name}} and
                                    {{linkedin_summary}} columns in your
                                    table. Use `}
                                    <span
                                      className="cursor-pointer text-[#5024DC] underline hover:text-[#7B5CED]"
                                      onClick={() => {
                                        handleModalClose();
                                        updateEnrichState({
                                          selectedEnrichments:
                                            allEnrichments?.find(
                                              (item) =>
                                                item.id === "linkedInDetails",
                                            ),
                                          isOpenEnrichModal: false,
                                          viewMode: "create",
                                        });
                                      }}
                                    >
                                      Person Profile
                                    </span>{" "}
                                    enrichment to get the person details.
                                  </>
                                )}
                                {item.value === "Custom Email Template" && (
                                  <>Create your own template with AI</>
                                )}
                                {item.value !== "People Information" &&
                                  item.value !== "Company Information" &&
                                  item.value !== "LinkedIn Connect" &&
                                  item.value !== "Custom Email Template" && (
                                    <>Create your own template without AI</>
                                  )}
                              </p>
                            </Stack>
                          </div>
                        ))}
                      </VStack>
                    </TabPanel>

                    <TabPanel>
                      <Settings
                        handleUpdateSettings={handleUpdateSettings}
                        profileInfo={profileInfo}
                      />
                    </TabPanel>
                    {includeTemplateStep && (
                      <TabPanel>
                        <DefaultPrompt
                          selectedPreference={selectedPreference?.label || ""}
                          isLoading={isPending}
                          mailDetails={mailDetails}
                          handleDetails={handleDetails}
                        />
                      </TabPanel>
                    )}
                    {includePersonalizationStep && (
                      <TabPanel>
                        <PersonalizationPrompt
                          selectedPreference={selectedPreference?.value || ""}
                          personalization={personalization}
                          setPersonalization={setPersonalization}
                        />
                      </TabPanel>
                    )}
                    {/* <TabPanel>
                      <VStack spacing={8} align={"stretch"}>
                        <VStack spacing={2} align="stretch">
                          <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            color={textColor}
                          >
                            Email Recipient{" "}
                            <span className="text-xs">(Optional)</span>
                          </Text>
                          <SelectColumnDropdown
                            selectedOption={selectedOption}
                            setSelectedOption={(newValue: any) =>
                              setSelectedOption(newValue)
                            }
                          />
                        </VStack>
                        <VStack spacing={2} align="stretch">
                          <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            color={textColor}
                          >
                            Email Subject{" "}
                            <span className="text-xs">(Optional)</span>
                          </Text>
                          <Input
                            placeholder="Enter email subject"
                            size="md"
                            variant="outline"
                            value={mailDetails.subject}
                            onChange={(e) =>
                              handleDetails("subject", e.target.value)
                            }
                            borderRadius="md"
                            focusBorderColor="blue.400"
                          />
                        </VStack>
                      </VStack>
                    </TabPanel> */}
                  </TabPanels>
                </Tabs>
              </Flex>
            </Box>
          </div>
          <Divider />
        </ModalBody>
        <ModalFooter className="!flex !items-center !justify-end">
          {activeIndex + 1 === tabData?.length ? (
            <SaveAndRunEnrichment
              showDivider={false}
              isDisabled={selectedPreference === null}
              apiPayload={{
                prompts: `${mailDetails.body}`,
                columnId: selectedOption?.keyId,
                emailData:
                  (selectedOption?.keyId && mailDetails.subject) ||
                  mailDetails.subject
                    ? {
                        subject: mailDetails.subject,
                        sendTo: selectedOption?.keyId,
                      }
                    : selectedOption?.keyId && !mailDetails.subject
                      ? { sendTo: selectedOption?.keyId }
                      : {},
                ...profileInfo,
                aiPersonalization: personalization,
                isStatic: selectedPreference?.value === "Static Email Template",
              }}
              onClear={handleModalClose}
            />
          ) : (
            <ButtonGroup>
              <Button
                leftIcon={<Icons.LeftArrowIcon />}
                variant={"outline"}
                isDisabled={activeIndex === 0}
                onClick={() => setActiveIndex((prevIndex) => prevIndex - 1)}
                className="hover:!shadow-lg"
              >
                Prev
              </Button>
              <Button
                isDisabled={selectedPreference === null}
                rightIcon={<Icons.RightArrowIcon />}
                variant={"outline"}
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="hover:!shadow-lg"
              >
                Next
              </Button>
            </ButtonGroup>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DraftEmail;
