import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { FC, useRef } from "react";

import { useTableStore } from "@/stores/table.store";
import { TableViewItem } from "./ViewItem";
import Icons from "@/components/Icons";

const TableViews: FC = () => {
  const popOverRef = useRef(null);
  const disclosure = useDisclosure();
  useOutsideClick({
    ref: popOverRef,
    handler: () => {
      disclosure.onClose();
    },
  });
  const tableData = useTableStore((state) => state.tableData);
  const tableViews = tableData?.views;
  const currentViewId = tableData?.selectedViewId;
  if (!tableViews) return null;
  const currentView = tableViews.find((view) => view._id === currentViewId);

  return (
    <Popover
      closeOnBlur
      autoFocus
      isLazy
      onClose={disclosure.onClose}
      onOpen={disclosure.onOpen}
      isOpen={disclosure.isOpen}
    >
      <PopoverTrigger>
        <Button
          colorScheme="purple"
          variant={"ghost"}
          leftIcon={<Icons.ViewTableIcon />}
          rightIcon={<Icons.ChevronRightIcon />}
          size="sm"
          mt={1}
          fontSize={"small"}
          fontWeight={"thin"}
          p={2}
        >
          {currentView ? currentView.name : "Select View"}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="!mt-2 max-w-[35vw]">
        <PopoverHeader className="flex flex-row justify-between">
          Select a View
        </PopoverHeader>
        <PopoverBody ref={popOverRef} zIndex={"dropdown"}>
          <Stack>
            {tableViews.map((view, index) => (
              <TableViewItem key={index} view={view} />
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default TableViews;
