const EmailIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="0.125rem"
      strokeLinecap="round"
      strokeLinejoin="round"
      // style="flex-shrink: 0; width: 1rem; height: 1rem;"
      style={{ flexShrink: 0 }}
      className={className}
    >
      <circle cx="12" cy="12" r="4"></circle>
      <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
    </svg>
  );
};

export default EmailIcon;
