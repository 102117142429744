import { CustomCellRendererProps } from "ag-grid-react";
import { TRow, TRowId } from "../types";
import { useState } from "react";
import {
  Box,
  ButtonGroup,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BiCheck, BiFolder, BiFolderOpen } from "react-icons/bi";
import Icons from "@/components/Icons";
import { useTableState } from "../hooks/useTableState.store";
import {
  DEFAULT_FOLDER_OPEN,
  NEW_FOLDER_ID_PLACEHOLDER,
  ROOT_FOLDER_ID,
} from "../constants";
import { CgRename } from "react-icons/cg";
import {
  useCreateFolder,
  useRenameFolder,
  useRenameTable,
} from "../hooks/useTableData";
import { IoMdClose } from "react-icons/io";
import Swal from "sweetalert2";

export const TableRowEditRenderer = (params: CustomCellRendererProps<TRow>) => {
  const data = params.data!;
  const [value, setValue] = useState(data.name);
  const [
    { mutateAsync: renameFolder },
    { mutateAsync: renameTable },
    { mutateAsync: createFolder },
  ] = [useRenameFolder(), useRenameTable(), useCreateFolder()];
  const {
    updateState,
    folderMap,
    folderList,
    showFolders,
    folderClosedStatus,
  } = useTableState((state) => ({
    updateState: state.updateState,
    folderMap: state.folderMap,
    folderList: state.folderList,
    showFolders: state.showFolders,
    folderClosedStatus: state.folderClosedStatus,
  }));
  const isFolder = data?.type === "folder";
  const isClosed =
    folderClosedStatus.get(params.data?._id as TRowId) ?? !DEFAULT_FOLDER_OPEN;
  if (!data) return null;

  const onSubmit = async (cancel = false) => {
    if (cancel && data._id === NEW_FOLDER_ID_PLACEHOLDER) {
      const { isConfirmed } = await Swal.fire({
        title: "Folder unsaved",
        icon: "warning",
        animation: false,
        text: "You have unsaved changes. Are you sure you want to cancel?",
        showCancelButton: true,
        confirmButtonText: "No, Go back",
        cancelButtonText: "Yes, Discard",
      });
      if (!isConfirmed) {
        return updateState({
          editing: null,
          folderList: folderList.filter(
            (item) => item._id !== NEW_FOLDER_ID_PLACEHOLDER,
          ),
        });
      }
      return;
    }
    updateState({
      editing: null,
    });
    if (!cancel) {
      params.node.setData({ ...data, name: value });
      if (data._id === NEW_FOLDER_ID_PLACEHOLDER) {
        updateState({
          folderMap: new Map([...(folderMap?.entries() ?? [])]),
        });
        return createFolder({
          folderName: value,
          parentFolderId: data.parentFolder?._id,
        });
      }
      if (value === data.name) return updateState({ editing: null });
      if (data.type === "folder") {
        return renameFolder({ folderId: data._id, newName: value });
      }
      if (data.type === "table") {
        return renameTable({ tableId: data._id, newName: value });
      }
    }
  };

  const folderIcons = (
    <>
      {isClosed ? (
        <BiFolder className="size-4 shrink-0" />
      ) : (
        <BiFolderOpen className="size-4 shrink-0" />
      )}
      <Icons.ChevronDownIcon
        className={`size-4 shrink-0 transition-transform ${
          isClosed ? "rotate-[-90deg]" : ""
        }`}
      />
    </>
  );
  const tableIcon = <Icons.ViewTableIcon className="size-4 shrink-0" />;

  return (
    <Stack
      onClickCapture={(e) => e.preventDefault()}
      onKeyDown={(e) => e.key === "Backspace" && e.stopPropagation()}
      onKeyDownCapture={(e) => {
        if (e.key === "Tab") e.preventDefault();
        if (e.key === "Enter") onSubmit();
        if (e.key === "Escape") onSubmit(true);
        e.key === "Backspace" && e.stopPropagation();
      }}
      className="folder flex !size-full !flex-row place-items-center items-center gap-2 px-2"
    >
      {isFolder ? folderIcons : tableIcon}
      <InputGroup className="shrink bg-transparent">
        <InputLeftAddon className="!w-[2em] shrink-0 !border-none !bg-[transparent] !pr-0">
          <CgRename scale={0.2} />
        </InputLeftAddon>
        <Input
          className="mx-2 !rounded-lg !border-none !bg-[#ffffff82] !shadow-none"
          autoFocus
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => e.currentTarget.select()}
          value={value}
        />
      </InputGroup>
      <Box className="ml-auto flex shrink-0 flex-row place-content-end items-center gap-3">
        <ButtonGroup gap={1}>
          <IconButton
            aria-label="Save"
            variant={"ghost"}
            size={"sm"}
            type="submit"
            icon={<IoMdClose />}
            className="hover:!bg-[white]"
            onClick={() => onSubmit(true)}
          />
          <IconButton
            pointerEvents={
              value === data.name && data._id !== NEW_FOLDER_ID_PLACEHOLDER
                ? "none"
                : "auto"
            }
            isDisabled={
              value === data.name && data._id !== NEW_FOLDER_ID_PLACEHOLDER
            }
            aria-label="Save"
            variant={"ghost"}
            size={"sm"}
            type="submit"
            icon={<BiCheck />}
            className="!border-[#5024dc] !text-[1.2em] !font-normal !text-[green] hover:!bg-[#5024dc] hover:!text-white"
            onClick={() => onSubmit()}
          />
        </ButtonGroup>
        {!showFolders && data.parentFolder?._id !== ROOT_FOLDER_ID && (
          <Text className="flex max-w-[10em] shrink-0 flex-row items-center gap-2 truncate text-[#8080808a]">
            <BiFolder className="size-4 shrink-0" />
            {data.parentFolder?.name}
          </Text>
        )}
      </Box>
    </Stack>
  );
};
