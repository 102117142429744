import { TProviderOption } from "@/components/Common/ProviderWaterfall/types";
import { ProviderNameType } from "@/stores/provider.store";
import { PROVIDERS } from "../constants";

export const transformProviders = (
  provider: (typeof PROVIDERS)[number],
): TProviderOption => {
  return {
    label: provider.label,
    value: provider.value,
    icon: provider.icon,
    apikeyParams: {
      keyName: provider.keyName as ProviderNameType,
      enableUserApiKey: provider.isUseOwnKey ?? false,
      enablePersanaApiKey: provider.isUseOnlyOwnKey ? false : true,
      extraKeyField: provider.extraKeyField as any,
    },
  };
};
