import { useCallback, useEffect, useMemo } from "react";
import { useBulkUpdate } from "./useBulkUpdate";
import { getGridRoot, useSelectionStore } from "./useSelectionStore";
import { BulkRowUpdatePayload } from "@/types/table.types";
import { useSwalPopover } from "@/hooks/useSwalPopover";
import _ from "lodash";

export const useDeleteSelected = () => {
  const gridApi = useSelectionStore((state) => state.gridApi);
  const range = useSelectionStore((state) => state.range);
  const features = useSelectionStore((state) => state.features);
  const mutateBulkRows = useBulkUpdate();
  const showPopover = useSwalPopover();

  const deleteSelected = useCallback(async () => {
    if (!features.delete) return;
    if (!gridApi || !range) return;

    const { startCell, endCell } = range;
    const isSingleCell = _.isEqual(startCell, endCell);
    const startRowIndex = Math.min(startCell.rowIndex, endCell.rowIndex);
    const endRowIndex = Math.max(startCell.rowIndex, endCell.rowIndex);

    const allColumns = gridApi.getColumns() ?? [];
    const startColIndex = allColumns.findIndex(
      (col) => col.getColId() === startCell.colId,
    );
    const endColIndex = allColumns.findIndex(
      (col) => col.getColId() === endCell.colId,
    );
    if (!isSingleCell) {
      const { isConfirmed } = await showPopover({
        animation: false,
        icon: "warning",
        title: "Are you sure you want to delete multiple cells?",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#b91c1c",
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }
    }
    const updatePayload: BulkRowUpdatePayload[] = [];
    for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
      const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
      if (rowNode) {
        for (
          let colIndex = startColIndex;
          colIndex <= endColIndex;
          colIndex++
        ) {
          const column = allColumns[colIndex];
          if (column) {
            updatePayload.push({
              rowId: rowNode.data.rowData._id,
              columnId: column.getColId(),
              cellValue: {
                value: "",
              },
            });
          }
        }
      }
    }
    mutateBulkRows({ payload: updatePayload });
  }, [gridApi, range, mutateBulkRows, showPopover, features]);

  return deleteSelected;
};

export const useDeleteShortcut = () => {
  const gridApi = useSelectionStore((state) => state.gridApi);
  const container = useMemo(() => {
    const gridRoot = getGridRoot(gridApi?.getGridId());
    if (!gridRoot) return null;
    return (
      (gridRoot.querySelector(".ag-center-cols-container") as HTMLElement) ??
      null
    );
  }, [gridApi]);

  const deleteSelected = useDeleteSelected();

  const handleDelete = useCallback(
    (event: KeyboardEvent) => {
      if (!container?.contains(document.activeElement)) return;
      if (document.activeElement?.tagName === "INPUT") return;
      if (event.key === "Delete" || event.key === "Backspace") {
        deleteSelected();
      }
    },
    [deleteSelected, container],
  );
  useEffect(() => {
    document.addEventListener("keydown", handleDelete);
    return () => {
      document.removeEventListener("keydown", handleDelete);
    };
  }, [handleDelete]);
};
