import { useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";

import Header from "./Header";
import FiltersSection from "./FiltersSection";
import PeopleSearchResultTable from "./PeopleSearchResultTable";
import Sidebar from "./Sidebar";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { userStore } from "@/stores/user.store";

const PeopleSearch = () => {
  const [currentSearchPayload, setCurrentSearchPayload] = useState<any>({});
  const [isSearching, setIsSearching] = useState(false);

  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const isUpgradeBannerShown = userStore((state) =>
    state.isUpgradeBannerShown(),
  );

  const handleClear = () => {
    setCurrentSearchPayload({});
    updateLeadState({
      searchLeadsResult: null,
    });
  };

  return (
    <div>
      <Header />
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(1, 25% 1fr)"
        overflowY={"auto"}
        height={`calc(100vh - 60px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
      >
        <GridItem className="border border-[#F0EFF1] bg-[#F5F3FF]">
          <Sidebar
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            setCurrentSearchData={setCurrentSearchPayload}
          />
        </GridItem>
        <GridItem overflow="auto" className="relative flex flex-col">
          <div>
            <FiltersSection
              handleClear={handleClear}
              currentSearchPayload={currentSearchPayload}
            />
            <div className="px-[32px]">
              {/* Table details section */}

              {/* Table */}
              <div className="mt-[10px] h-[600px]">
                <PeopleSearchResultTable isSearching={isSearching} />
              </div>
            </div>
          </div>
        </GridItem>
      </Grid>
    </div>
  );
};

export default PeopleSearch;
