import { useEffect, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { EnrichmentMetaData } from "@/types/table.types";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { TSelectedColumnOption } from "@/types/common.types";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import { preselectEnrichmentState } from "@/utils";
import PersanaAccordion from "@/components/Common/PersanaAccordion";

const FindPeopleAtCompanyWithPersana = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [dropdownStates, setDropdownStates] = useState({
    includedJobTitles: [] as Option[],
    pastJobTitles: [] as Option[],
    pastCompanies: [] as Option[],
    certifications: [] as Option[],
    degrees: [] as Option[],
    industries: [] as Option[],
    skills: [] as Option[],
    schools: [] as Option[],
    studyFields: [] as Option[],
  });

  const { viewMode, selectedColumnToEdit, updateState } = useEnrichStore(
    (state) => ({
      viewMode: state.viewMode,
      selectedColumnToEdit: state.selectedColumnToEdit,
      updateState: state.updateState,
    }),
  );

  const updateJobTitlesState = (
    titles: string | string[],
    key: keyof typeof dropdownStates,
  ) => {
    const formattedTitles = Array.isArray(titles) ? titles : titles.split(",");
    setDropdownStates((prevState) => ({
      ...prevState,
      [key]: formattedTitles.map((item) => ({
        label: item,
        value: item,
      })),
    }));
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentMetaData;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });

        updateJobTitlesState(
          enrichmentData.payload.current_job_titles || [],
          "includedJobTitles",
        );
        updateJobTitlesState(
          enrichmentData.payload.past_job_titles || [],
          "pastJobTitles",
        );
        updateJobTitlesState(
          enrichmentData.payload.past_companies || [],
          "pastCompanies",
        );
        updateJobTitlesState(
          enrichmentData.payload.certifications || [],
          "certifications",
        );
        updateJobTitlesState(
          enrichmentData.payload.industries || [],
          "industries",
        );
        updateJobTitlesState(
          enrichmentData.payload.degree_names || [],
          "degrees",
        );
        updateJobTitlesState(enrichmentData.payload.skills || [], "skills");
        updateJobTitlesState(
          enrichmentData.payload.school_names || [],
          "schools",
        );
        updateJobTitlesState(
          enrichmentData.payload.study_fields || [],
          "studyFields",
        );
      }
    }
  }, [viewMode, selectedColumnToEdit]);

  const renderDropdown = (
    label: string,
    placeholder: string,
    valueKey: keyof typeof dropdownStates,
    limit: number,
  ) => (
    <div>
      <p className="pb-1 text-base font-semibold">{label}</p>
      <MultiSelectDropdown
        value={dropdownStates[valueKey]}
        options={[]}
        onChange={(selectedValues) =>
          setDropdownStates((prevState) => ({
            ...prevState,
            [valueKey]: selectedValues as Option[],
          }))
        }
        placeholder={placeholder}
        limit={limit}
      />
    </div>
  );

  return (
    <>
      <div className="max-h-[70vh] grow overflow-y-auto p-4">
        <div className="flex w-full items-center justify-between pb-1">
          <p className="pb-1 text-base font-semibold">Company ID</p>
        </div>
        <p className="pb-1.5 text-xs">
          Company id can be found using our enrich company enrichment.
        </p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <div className="mt-4">
          <PersanaAccordion
            btnStyle={{
              backgroundColor: "rgb(250 250 251)",
              borderRadius: "6px",
            }}
            items={[
              {
                button: (
                  <button className="w-full truncate text-left font-semibold text-[#000000]">
                    Additional Criterias
                  </button>
                ),

                panel: (
                  <div className="space-y-4 pt-3">
                    {renderDropdown(
                      "Past companies to include",
                      "Add past company IDs to include",
                      "pastCompanies",
                      20,
                    )}
                    {renderDropdown(
                      "Job titles",
                      "Add job titles to include",
                      "includedJobTitles",
                      50,
                    )}
                    {renderDropdown(
                      "Past job titles to include",
                      "Add past job titles to include",
                      "pastJobTitles",
                      50,
                    )}
                    {renderDropdown(
                      "Certifications",
                      "Add certifications",
                      "certifications",
                      50,
                    )}
                    {renderDropdown("Degrees", "Add degrees", "degrees", 50)}
                    {renderDropdown(
                      "Industries",
                      "Add industries",
                      "industries",
                      50,
                    )}
                    {renderDropdown("Skills", "Add skills", "skills", 50)}
                    {renderDropdown("Schools", "Add schools", "schools", 50)}
                    {renderDropdown(
                      "Study Fields",
                      "Add study fields",
                      "studyFields",
                      50,
                    )}
                  </div>
                ),
              },
            ]}
          />
        </div>
        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.key}
        apiPayload={{
          columnId: selectedOption?.keyId,
          payload: {
            past_job_titles: dropdownStates.pastJobTitles.map(
              (item) => item.value,
            ),
            current_job_titles: dropdownStates.includedJobTitles.map(
              (item) => item.value,
            ),
            past_companies: dropdownStates.pastCompanies.map(
              (item) => item.value,
            ),
            certifications: dropdownStates.certifications.map(
              (item) => item.value,
            ),
            degree_names: dropdownStates.degrees.map((item) => item.value),
            industries: dropdownStates.industries.map((item) => item.value),
            skills: dropdownStates.skills.map((item) => item.value),
            school_names: dropdownStates.schools.map((item) => item.value),
            study_fields: dropdownStates.studyFields.map((item) => item.value),
          },
        }}
      />
    </>
  );
};

export default FindPeopleAtCompanyWithPersana;
