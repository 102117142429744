import misServices from "@/services/misc.services";
import { useQuery } from "@tanstack/react-query";

const useProvidersCreditUsage = () => {
  return useQuery({
    queryKey: ["providersCreditUsage"],
    queryFn: async () => misServices.getProvidersCreditUsage(),
  });
};

export default useProvidersCreditUsage;
