import { useCallback, useEffect, useState } from "react";
import { Text, VStack } from "@chakra-ui/react";
import SelectableTextEditor from "@/components/Enrichment/Common/SelectableTextEditor";
import { TSelectedColumnOption } from "@/types/common.types";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";

interface Props {
  mailDetails: {
    subject: string;
    body: string;
  };
  selectedPreference: string;
  isLoading: boolean;
  handleDetails: (key: string, value: string) => void;
}

const DefaultPrompt = ({
  mailDetails,
  handleDetails,
  isLoading,
  selectedPreference,
}: Props) => {
  const showContent =
    selectedPreference !== "Custom Email Template" &&
    selectedPreference !== "Static Email Template";

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const [dots, setDots] = useState("");

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
      }, 300);
      return () => clearInterval(interval);
    } else {
      setDots("");
    }
  }, [isLoading]);

  const replacePlaceholders = useCallback(
    (text: string) => {
      if (!selectedOption) return text;

      return text.replace(/\{\{(.*?)\}\}/, (match) => {
        return `{{${selectedOption.key}}}` || match;
      });
    },
    [selectedOption],
  );

  useEffect(() => {
    if (selectedOption) {
      const updatedBody = replacePlaceholders(mailDetails.body);
      handleDetails("body", updatedBody);
    }
  }, [handleDetails, mailDetails.body, replacePlaceholders, selectedOption]);

  return (
    <VStack align="stretch" spacing={3}>
      {showContent && (
        <p className="text-sm italic text-[#4A5264]">
          {`Note:  In this step, we will create your template. Please don't remove {{first_name}} and {{ai_personalization}}.  Select the first name field on your table. In the next step, we will generate the AI personalization.`}
        </p>
      )}

      {showContent && (
        <div className="flex flex-col">
          <h4 className="pb-0.5 font-semibold text-[#1d2026]">
            {"first_name"}
          </h4>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={(option: any) => {
              setSelectedOption(option);
            }}
            callFrom="ai-draft-email"
            notInportal
            hideInnerContent
          />
        </div>
      )}

      <Text fontSize="sm" fontWeight="bold">
        Default Template
      </Text>

      <SelectableTextEditor
        title=""
        placeholder="Subject Text, type / for commands"
        setText={(value) => {
          handleDetails("body", value);
        }}
        isDisabled={isLoading}
        rows={12}
        text={isLoading ? `Generating template${dots}` : mailDetails.body}
        position="Right"
        className="rounded-md border shadow-sm"
      />
    </VStack>
  );
};

export default DefaultPrompt;
