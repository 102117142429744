import APIKeyIntegration from "./APIKeyIntegration";

const LemListIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"lemListApiKey"}
      providerTitle="LemList"
      providerIcon="https://persana-assets.s3.us-west-1.amazonaws.com/Lemlist-logo.png"
    />
  );
};

export default LemListIntegration;
