import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Button, Input, ModalFooter, useToast } from "@chakra-ui/react";

import RepoDetails from "./RepoDetails";
import LoadingSpin from "@/assets/animated/LoadingSpin";
import enrichment from "@/services/enrichment.service";

type Props = {
  handleNext: (repoPath: string, starGazersCount: number) => void;
};

const SearchRepo = ({ handleNext }: Props) => {
  const [repoPath, setRepoPath] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const fetchRepoDetails = async (repoPath: string) => {
    try {
      setLoading(true);
      const response = await enrichment.repoSearch(repoPath.trim());
      setIsDisabled(
        !response?.success || !response?.data?.data?.stargazers_count,
      );

      if (!response.success) {
        toast({
          // @ts-ignore
          title: response?.message || "Something went wrong",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } else {
        setData(response);
      }
    } catch (error: any) {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
    setLoading(false);
  };

  const handleContinue = () => {
    !isDisabled &&
      data &&
      handleNext(repoPath, data?.data?.data?.stargazers_count);
  };

  const throttledFetchRepoDetails = useCallback(
    debounce(fetchRepoDetails, 500),
    [],
  );

  useEffect(() => {
    if (repoPath) {
      throttledFetchRepoDetails(repoPath);
    }
  }, [repoPath, throttledFetchRepoDetails]);

  return (
    <div>
      <p className="mb-2 text-sm font-medium text-primary">
        Repository URL or Name
      </p>
      <Input
        placeholder="https://github.com/persana-ai/persana-ai-sdk"
        value={repoPath}
        onChange={(e) => {
          setRepoPath(e.target.value);
        }}
      />
      <p className="pt-1 text-xs font-normal text-secondary">
        e.g. https://github.com/persana-ai/persana-ai-sdk or
        persana-ai/persana-ai-sdk
      </p>
      {loading && <LoadingSpin />}
      <div className="flex justify-center">
        {!!data?.success && <RepoDetails data={data.data?.data} />}
      </div>
      <ModalFooter className="border-t-dividerColor mt-5 border-t-[1px] !pb-0">
        <Button
          onClick={handleContinue}
          className={`text-l flex w-full min-w-fit cursor-pointer items-center justify-center gap-2 rounded !bg-primary px-4 py-1 !font-normal !text-white ${
            isDisabled ? "!cursor-not-allowed opacity-50" : "!cursor-pointer"
          }`}
          disabled={isDisabled}
        >
          Continue
        </Button>
      </ModalFooter>
    </div>
  );
};

export default SearchRepo;
