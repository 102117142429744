import React, { ReactElement, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";

interface CustomErrorBoundaryProps {
  children: ReactNode;
  childProps?: object;
  errorMessage?: string;
}

const CustomErrorBoundary = ({
  children,
  childProps,
  errorMessage,
}: CustomErrorBoundaryProps) => (
  <ErrorBoundary
    fallback={<p>{errorMessage || ""}</p>}
    onError={(error: Error, componentStack: React.ErrorInfo) => {
      Sentry.captureException(error, {
        tags: { priority: "high", name: "CustomErrorBoundary" },
        extra: {
          componentStack,
          error,
        },
      });
    }}
  >
    {React.Children.map(children, (child) =>
      React.isValidElement(child)
        ? React.cloneElement(child as ReactElement, childProps)
        : child,
    )}
  </ErrorBoundary>
);

export default CustomErrorBoundary;
