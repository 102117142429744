import { RenderLeafProps } from "slate-react";

const Leaf = (props: RenderLeafProps & { isCodeView?: boolean }) => {
  const { attributes, children, leaf } = props;

  return (
    <span
      // @ts-ignore
      className={`px-0 py-[0.5px] text-lg ${leaf.queryText ? "text-primary" : ""}`}
      {...attributes}
      style={{
        fontSize: props.isCodeView ? "1rem" : "0.875rem",
        lineHeight: "1.8",
      }}
    >
      {children}
    </span>
  );
};

export default Leaf;
