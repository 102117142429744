import AutoPilotPostEngagementWorkflow from "@/components/AutoPilotPostEnagagementWorkflow.tsx";
import { userStore } from "@/stores/user.store";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute(
  "/auto-pilot/post-engagement-workflow",
).createRoute({
  component: PostEngagementWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function PostEngagementWorkflowPage() {
  const currentUser = userStore((state) => state.currentUser);
  const isAdmin = currentUser.role === "admin";

  if (!isAdmin) {
    return <div>Not Found</div>;
  }

  return <AutoPilotPostEngagementWorkflow />;
}

export default PostEngagementWorkflowPage;
