import { useState } from "react";
import { Button, Divider, Input } from "@chakra-ui/react";

import Icons from "@/components/Icons";
import { useEnrichStore } from "@/stores/enrich.store";

interface ExampleState {
  input: string;
  output: string;
}

const Examples = () => {
  const examples = useEnrichStore((state) => state.examples);
  const updateState = useEnrichStore((state) => state.updateState);

  const [showExamples, setShowExamples] = useState(false);

  const handleExampleChange = (
    index: number,
    field: keyof ExampleState,
    value: string,
  ) => {
    updateState({
      examples: examples!.map((example, i) => {
        if (i === index) {
          return {
            ...example,
            [field]: value,
          };
        }
        return example;
      }),
    });
  };

  const handleAddExample = () => {
    updateState({
      examples: [...examples!, { input: "", output: "" }],
    });
  };

  return (
    <div>
      <div
        className="mb-1 mt-5 flex cursor-pointer flex-row items-center gap-x-2"
        onClick={() => setShowExamples(!showExamples)}
      >
        <Icons.ArrowForwardIcon
          className={`transform text-[#676d7e] duration-300 ${
            showExamples ? "rotate-90" : ""
          }`}
        />
        <p className="text-base font-semibold">Examples</p>
      </div>
      {showExamples && (
        <div className="overflow-y-auto max-h-[150px]">
          {examples.map((example, index) => (
            <div key={index} className="mt-4">
              <Input
                value={example.input}
                onChange={(e) =>
                  handleExampleChange(index, "input", e.target.value)
                }
                placeholder="Example Input"
                className="border-gray-300 w-full rounded-md border p-2 focus:border-blue-500 focus:outline-none"
              />
              <Input
                value={example.output}
                onChange={(e) =>
                  handleExampleChange(index, "output", e.target.value)
                }
                placeholder="Example Output"
                className="border-gray-300 mt-2 w-full rounded-md border p-2 focus:border-blue-500 focus:outline-none"
              />
            </div>
          ))}
          <Divider className="my-2" />
          <Button size="xs" onClick={handleAddExample}>
            Add More Example
          </Button>
        </div>
      )}
    </div>
  );
};

export default Examples;
