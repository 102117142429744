import apiKeyService from "@/services/api-keys.service";
import { useQuery } from "@tanstack/react-query";

const useApiKeys = (userId?: string) => {
  return useQuery({
    queryKey: ["apiKeys", ...(userId ? [userId] : [])],
    queryFn: async () => apiKeyService.getApiKeys(userId),
  });
};

export default useApiKeys;
