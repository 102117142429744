import { useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";
import { convertSlateToText } from "@/utils";

import { useEnrichStore } from "@/stores/enrich.store";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const FindNewsResults = () => {
  const [query, setQuery] = useState<any>(initialSlateValue);
  const [dateRange, setDateRange] = useState<Option>();

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  const dateRangeOptions = [
    {
      value: "qdr:h",
      label: "Past Hour",
    },
    {
      value: "qdr:d",
      label: "Past 24 Hour",
    },
    {
      value: "qdr:w",
      label: "Past Week",
    },
    {
      value: "qdr:m",
      label: "Past Month",
    },
    {
      value: "qdr:y",
      label: "Past Year",
    },
  ];

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;
      if (enrichmentData) {
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        // @ts-ignore
        setQuery(enrichmentData?.query || initialSlateValue);
        const filteredDateRange = dateRangeOptions?.filter(
          // @ts-ignore
          (item) => item.value === enrichmentData?.dateFilter,
        );
        setDateRange(filteredDateRange?.[0]);
      }
    }
  }, [selectedColumnToEdit, viewMode]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Given a query, find results from Google News"
          link={""}
        />
        <div className="py-4">
          <p className="pb-1 text-base font-semibold">Query</p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={query}
            setSlateValue={setQuery}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>
        <div className="">
          <p className="pb-1 text-base font-semibold">
            Date Filter -{" "}
            <span className="text-gray-600 text-xs">Optional</span>
          </p>
          <MultiSelectDropdown
            isMulti={false}
            value={dateRange}
            options={dateRangeOptions}
            onChange={(selectedValues) => {
              setDateRange(selectedValues as Option);
            }}
            placeholder=""
          />
        </div>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!convertSlateToText(query).text}
        apiPayload={{
          query,
          dateFilter: dateRange?.value,
        }}
      />
    </>
  );
};

export default FindNewsResults;
