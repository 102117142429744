import { Text } from "@chakra-ui/react";
import { NaturalFilterValue } from "./FilterTag";
import { useMemo } from "react";
const PLACEHOLDER_PATTERN = /{.*?}/g;
export const useNatural = (template: string = "") => {
  const finalComponents = useMemo(() => {
    const extractedPlaceHolders = template.match(PLACEHOLDER_PATTERN);
    const stringParts = template.split(PLACEHOLDER_PATTERN);
    return stringParts.flatMap((part, index) => {
      const text = (
        <Text className="inline" key={index + part}>
          {part}
        </Text>
      );
      const placeholderComponent = (
        <NaturalFilterValue
          key={index}
          field={extractedPlaceHolders?.[index]?.slice(1, -1)}
        />
      );
      console.log("myLog extractedPlaceHolders", extractedPlaceHolders);
      console.log("myLog stringParts", stringParts);
      return [text, placeholderComponent];
    });
  }, [template]);
  console.log("myLog finalComponents", finalComponents);
  if (!template) return null;

  return finalComponents;
};

// const getFilterValue /
