/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridApi, GridState } from "ag-grid-community";
import _ from "lodash";
import { createContext, useContext } from "react";
import { StoreApi, createStore } from "zustand";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { TSupportedRowModelType } from "../types";

export interface ITableState {
  id: string;
  loading: boolean;
  state: GridState | null;
  totalRows: number;
  setLoading: (loading: boolean) => void;

  isSelectAll: boolean;
  setIsSelectAll: (isSelectAll: boolean) => void;

  gridApi: GridApi | null;
  setGridApi: (gridApi: GridApi | null) => void;

  getSelectedRowCount: () => number;
  resetSorting: () => void;
  refreshData: () => Promise<void>;
  updateState: (update: Partial<ITableState>) => void;
  setState: (state: Partial<GridState>) => void;
}

export const TableStateContext = createContext<
  StoreApi<ITableState> | undefined
>(undefined);

type TSelector<R> = (state: ITableState) => R;

export const useTableStore = <R,>(selector: TSelector<R>) => {
  const store = useContext(TableStateContext);
  return useStoreWithEqualityFn(
    store as unknown as StoreApi<ITableState>,
    selector,
    (a, b) => _.isEqual(a, b),
  );
};

export const createTableStateStore = () =>
  createStore<ITableState>((set, get) => ({
    id: "",
    loading: true,
    totalRows: 0,
    setLoading: (loading) => set({ loading }),
    state: null,
    isSelectAll: false,
    setIsSelectAll: (isSelectAll) => set({ isSelectAll }),

    gridApi: null,
    setGridApi: (gridApi) => set({ gridApi }),

    getSelectedRowCount: () => {
      const state = get();
      return state.isSelectAll
        ? state.totalRows
        : (state.state?.rowSelection as string[])?.length ?? 0;
    },

    resetSorting: () => {
      const gridApi = get().gridApi;
      if (!gridApi) return;
      gridApi.getColumnState().forEach((column) => {
        gridApi.applyColumnState({
          state: [{ colId: column.colId, sort: null }],
        });
      });
    },

    async refreshData() {
      const gridApi = get().gridApi;
      if (!gridApi) return;
      const rowModalType: TSupportedRowModelType =
        gridApi.getGridOption("rowModelType") === "clientSide"
          ? "clientSide"
          : "infinite";
      if (rowModalType === "infinite") {
        await gridApi?.refreshInfiniteCache();
      }
    },
    updateState: (update) => set((state) => ({ ...state, ...update })),
    setState: (state) => set({ state }),
  }));
