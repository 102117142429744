import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import HubspotIcon from "@/assets/svgs/hubspot.svg";
import { useProviderList } from "@/hooks/useProviderData";
import leadService from "@/services/leads.service";
import { useProviderStore } from "@/stores/provider.store";
import { useTable } from "@/components/DataTable/Table/hooks/useTable";

const SyncHubspot = () => {
  const { data } = useProviderList();
  const toad = useToast({
    position: "top-right",
  });
  const { selectedRowIds } = useTable();
  console.log(selectedRowIds, "selectedRowIds"); // prints "all" or an array of ids
  const { mutateAsync: handleSync, isPending } = useMutation({
    mutationFn: () =>
      leadService.syncLeads({
        crmType: "hubspot",
        leadType: "jobChanges",
      }),
  });

  const updateProviderState = useProviderStore((state) => state.updateState);

  const isHubspotKeyAvailable = data?.data?.find(
    (provider) => provider.name === "hubspotApiKey",
  );

  const handleSyncHubspot = async () => {
    if (!isHubspotKeyAvailable) {
      updateProviderState({
        isProviderApiKeyModalOpen: true,
        providerApiKeyModalData: {
          title: "Hubspot API Key",
          providerName: "hubspotApiKey",
        },
      });
      return;
    }
    handleSync(undefined, {
      onSuccess: () => {
        toad({
          title: "Success",
          description:
            "Synced with Hubspot. We will notify to your email once the sync is completed.",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      },
      onError: (error) => {
        toad({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <>
      <Button
        variant={"outline"}
        size={"sm"}
        leftIcon={<img src={HubspotIcon} alt="" width={12} height={12} />}
        onClick={handleSyncHubspot}
        isLoading={isPending}
      >
        Sync Hubspot
      </Button>
    </>
  );
};

export default SyncHubspot;
