import { Tooltip, Box } from "@chakra-ui/react";
import Icons from "@/components/Icons";
import { ZenRowParams } from "../types";
import { RadioCardGroup } from "@/components/Common/RadioCard";
import { IRadioCardGroup } from "@/components/Common/RadioCard/types";
interface IOutputProps
  extends Pick<
    IRadioCardGroup<ZenRowParams["zenRowsOutput"]>,
    "setValue" | "options" | "value" | "defaultValue"
  > {
  cssSelectors: ZenRowParams["zenRowsCssSelectors"];
  setCssSelectors: (value: ZenRowParams["zenRowsCssSelectors"]) => void;
}
export const Output = (props: IOutputProps) => {
  return (
    <div>
      <div className="mb-2 flex items-center">
        <span className="font-semibold text-dark">Output</span>
        <Tooltip
          className="inline-flex rounded-lg"
          label={
            <div className="w-64 rounded-lg p-3 sm:w-96">
              <p className="mb-4">
                Get plain HTML or parse the content to JSON format (CSS
                Selectors and Autoparse).
              </p>
              <p className="mb-4">
                Provide CSS Selectors to precisely define the data you want to
                extract or use our Autoparse algorithm.
              </p>
              <p>
                <a
                  href="https://docs.zenrows.com/features/output"
                  target="_blank"
                  className="underline"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </p>
            </div>
          }
        >
          <div>
            <Icons.InformationIcon className="size-4" />
          </div>
        </Tooltip>
      </div>
      <RadioCardGroup name="zenRowsOutput" {...props} />
      {props.value === "css" && (
        <Box
          whiteSpace={"pre-wrap"}
          resize={"vertical"}
          contentEditable
          dangerouslySetInnerHTML={{ __html: props.cssSelectors }}
          onBlur={(e) => props.setCssSelectors(e.currentTarget.innerText)}
          className="mt-2 rounded-lg border border-solid !bg-white p-3 focus:!outline-[#3182CE]"
        />
      )}
    </div>
  );
};
