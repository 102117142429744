import APIKeyIntegration from "./APIKeyIntegration";

const ClaudeIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"claude"}
      providerTitle="Claude"
      providerIcon={
        "https://persana-assets.s3.us-west-1.amazonaws.com/claude-logo.png"
      }
    />
  );
};

export default ClaudeIntegration;
