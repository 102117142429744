import { ReactNode } from "react";

type AccordionButtonProps = {
  children: ReactNode;
  icon? : JSX.Element;
};

const AccordionButton = ({ children, icon }: AccordionButtonProps) => {
  return (
    <p className="flex w-[95%] items-center truncate text-left font-semibold text-[#141313b2]">
      <span className="block w-[1.8rem]">{icon}</span>
      <span>{children}</span>
    </p>
  );
};

export default AccordionButton;
