import _ from "lodash";
import {
  TTableRow,
  TRow,
  TFolderRow,
  TableListItem,
  TFoldersListItem,
  TRowId,
} from "./types";
import {
  NEW_FOLDER_ID_PLACEHOLDER,
  ROOT_FOLDER_ID,
  ROOT_FOLDER_NAME,
} from "./constants";
import { IRowNode } from "ag-grid-community";
export function prepareFolderTree(
  folders: TFoldersListItem[],
): Map<TRowId, TFolderRow> {
  folders = [...folders, getRootFolder()];

  const folderIdMap = new Map<TRowId, TFolderRow>(
    folders.map((folder) => [
      folder._id,
      {
        type: "folder",
        _id: folder._id,
        path: [],
        name: folder.name,
        createdAt: new Date(folder.createdAt || 0),
        updatedAt: new Date(folder.updatedAt || 0),
        folderItems: [],
        parentFolder: null,
      },
    ]),
  );
  const folderParentIds = new Map(
    folders.map(
      (folder) =>
        [
          folder._id as TRowId,
          folder.parentFolderId && folderIdMap.has(folder.parentFolderId)
            ? folder.parentFolderId
            : ROOT_FOLDER_ID,
        ] as const,
    ),
  );

  folders.forEach((folder) => getFolderPath(folder._id));

  folderIdMap.forEach((folder: TFolderRow) => {
    updateFolderHierarchy(folder);
  });
  return folderIdMap;

  function getFolderPath(id: TRowId) {
    const path: TRowId[] = [];
    while (id !== ROOT_FOLDER_ID) {
      if (path.includes(id)) {
        const recursiveFrom = path.indexOf(id);
        return path.slice(recursiveFrom + 1).concat([ROOT_FOLDER_ID]);
      }
      path.push(id);
      id = folderParentIds.get(id) || ROOT_FOLDER_ID;
    }
    return path;
  }
  function updateFolderHierarchy(folder: TFolderRow) {
    folder.path = getFolderPath(folder._id).reverse();

    if (folderParentIds.get(folder._id) === folder._id) return;

    folder.parentFolder =
      folderIdMap.get(folderParentIds.get(folder._id)!) ?? null;
    folder.parentFolder?.folderItems.push(folder);
  }
}

export function prepareTableRows(
  tables: TableListItem[],
  folders: Map<TRowId, TFolderRow>,
): TTableRow[] {
  return tables.map((table) => {
    const path = (
      table.folderId && folders.has(table.folderId)
        ? folders.get(table.folderId)?.path
        : folders.get(ROOT_FOLDER_ID)?.path
    )!;

    const parentFolder = (
      table.folderId && folders.has(table.folderId)
        ? folders.get(table.folderId)
        : folders.get(ROOT_FOLDER_ID)
    )!;

    const tableRowItem: TTableRow = {
      type: "table",
      _id: table._id,
      path,
      parentFolder,
      name: table.name,
      createdAt: new Date(table.createdAt),
      updatedAt: new Date(table.updatedAt),
    };
    parentFolder.folderItems.push(tableRowItem);
    return tableRowItem;
  });
}

function getRootFolder(): TFoldersListItem {
  return {
    _id: ROOT_FOLDER_ID,
    name: ROOT_FOLDER_NAME,
    parentFolderId: null,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
}

export function getNewFolder(
  name: string,
  parentFolderId: TFolderRow | null = null,
): TFoldersListItem {
  return {
    _id: NEW_FOLDER_ID_PLACEHOLDER,
    parentFolderId: parentFolderId?._id ?? ROOT_FOLDER_ID,
    name,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
}

export function getMoveTarget(
  sourceNode: IRowNode<TRow>,
  targetNode: IRowNode<TRow> | null,
) {
  const sourceData = sourceNode.data || null;
  let targetData = targetNode?.data || null;
  if (targetData?.type === "table") {
    // move to row => move to row's folder
    targetData = targetData.parentFolder;
  }
  if (sourceNode === targetNode) {
    // cannot move to self
    return null;
  }
  if (sourceData?.parentFolder?._id === targetData?._id) {
    // cannot move to immediate parent
    return null;
  }
  const targetParents = targetData?.path || [];
  if (targetParents.includes(sourceData?._id as TRowId)) {
    // cannot move to child
    return null;
  }
  return targetData;
}

export const sortFolderItems = (
  folder: TFolderRow | null,
  sort: Map<string, "asc" | "desc"> | null,
) => {
  if (!folder) return null;
  const fields = Array.from(sort?.keys() || []);
  const orders = Array.from(sort?.values() || []);
  folder.folderItems = sortFolderItems(folder.folderItems);
  return folder;
  function sortFolderItems(folderItems: TRow[]): TRow[] {
    return _.orderBy(folderItems, fields, orders).map((row) => {
      if (row.type === "folder") {
        return {
          ...row,
          folderItems: sortFolderItems(row.folderItems),
        };
      }
      return { ...row };
    });
  }
};

export const warnValue = <T>(
  value: T,
  warning = `This should never have happened`,
) => {
  console.warn(warning, `Warned Value: ${value}`);
  return value;
};

export const getRowEditDate = (row?: TRow): Date => {
  // latest among it and its children
  if (!row) return new Date();
  if (row.type === "table" || row.folderItems.length === 0)
    return row.updatedAt;
  const childLatest = _.maxBy(row.folderItems, getRowEditDate)?.updatedAt;
  return _.max([row.updatedAt, childLatest]) as Date;
};

export const getChildCount = (row?: TRow): number => {
  if (!row) return 0;
  if (row.type === "table") return 1;
  return row.folderItems.map(getChildCount).reduce((a, b) => a + b, 0);
};

export const getAvailableName = (name: string, allNames: string[]) => {
  let newName = name;
  let i = 1;
  while (allNames.includes(newName)) {
    newName = `${name} (${i})`;
    i++;
  }
  return newName;
};
