import { useNavigate } from "@tanstack/react-router";

import InHouseOnboardingModal from "@/components/Leads/InHouseOnboardingModal";

import OnboardingBg from "@/assets/images/onboarding-bg.png";
import PersanaLogo from "@/assets/svgs/persana-logo.svg";

import userService from "@/services/user.service";
import { useModalStore } from "@/stores/modal.store";
import { queryClient } from "@/lib/queryClient";

const CompleteOnboarding = ({ token }: { token: string }) => {
  const navigate = useNavigate();
  const updateState = useModalStore((state) => state.updateState);

  if (!token) {
    navigate({ to: "/login" });
    return;
  }

  const onOnboardingSubmit = async (redirectUrl: string) => {
    //@ts-ignore
    navigate({ to: redirectUrl });
    updateState({
      isOpenModal: true,
      modalData: {
        modalType: "sales-gpt-modal",
      },
    });
    // @ts-ignore
    await queryClient.invalidateQueries(["user-details"]);
    await userService.userOnboardingAlert();
    //@ts-ignore
    // if (typeof window.Intercom === "function") {
    //   // @ts-ignore
    //   window.Intercom("startChecklist", 39141957);
    // }
  };

  return (
    <section className="relative h-screen w-full">
      {" "}
      <img src={OnboardingBg} className="z-1 absolute bottom-0 left-0 w-full" />
      <div className="flex items-center justify-center py-[38px]">
        <img src={PersanaLogo} />
      </div>
      <InHouseOnboardingModal
        callFrom="screen"
        token={token}
        redirectUrl={`/`}
        onSubmit={onOnboardingSubmit}
      />
    </section>
  );
};

export default CompleteOnboarding;
