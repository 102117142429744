import React from 'react'

function Checkbox({ children, isChecked, isDisabled , onChange }: {
    children: React.ReactNode
    isChecked: boolean
    isDisabled?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}) {
    return (
        <label className="material-checkbox">
            <input type="checkbox" checked={isChecked} disabled={isDisabled} onChange={onChange} />
            <span className="checkmark"></span>
            {children}
        </label>
    )
}

export default Checkbox
