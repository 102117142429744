import { Button } from "@chakra-ui/react";

import SyncHubspot from "@/assets/images/job-change-hubspot.png";
import HubspotLogo from "@/assets/svgs/hubspot.svg";
import { useProviderStore } from "@/stores/provider.store";

const Step4 = () => {
  const updateProviderState = useProviderStore((state) => state.updateState);
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold text-gray-800">
          Sync Leads with Job Change Status to HubSpot
        </p>
        <Button
          variant={"outline"}
          size={"sm"}
          leftIcon={<img src={HubspotLogo} alt="" width={12} height={12} />}
          onClick={() => {
            updateProviderState({
              isProviderApiKeyModalOpen: true,
              providerApiKeyModalData: {
                title: "Hubspot",
                providerName: "hubspotApiKey",
                secretKeyInput: false,
              },
            });
          }}
        >
          Connect Hubspot
        </Button>
      </div>

      <hr className="border-t-2 border-purple-700 my-4" />
      <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
        <img src={SyncHubspot} alt="Select Leads" className="rounded-lg mb-4" />
      </div>
      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <p className="text-base font-medium text-gray-800">
          To sync your leads with HubSpot:
        </p>
        <p>
          <span className="font-semibold">1. Connect HubSpot account</span> if
          not already connected.
        </p>
        <p>
          <span className="font-semibold">2. Click on "Sync HubSpot"</span> to
          initiate the sync process.
        </p>
      </div>
    </div>
  );
};

export default Step4;
