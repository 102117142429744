import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useToast,
} from "@chakra-ui/react";

import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";

import providerService from "@/services/providers.service";
import tableService from "@/services/table.service";
import { TableData } from "@/types/table.types";
import { SlackChannelType } from "@/types/slack.types";
import SlackIntegration from "@/components/Integrations/SlackIntegration";
import { useProviderList } from "@/hooks/useProviderData";

type Props = {
  isConfigured: boolean;
  isOpen: boolean;
  tableData?: TableData;
  onClose: (isRefetch: boolean) => void;
};

const Configurations = ({
  isConfigured,
  isOpen,
  tableData,
  onClose,
}: Props) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [slackNotificationPreference, setSlackNotificationPreference] =
    useState<boolean>(!!tableData?.metaData?.sendSlackNotifications);
  const [slackChannelList, setSlackChannelList] = useState<Option[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Option | null>(
    tableData?.metaData?.selectedChannel || {},
  );
  const { data: providerList } = useProviderList();
  const slackProvider = providerList?.data?.find(
    (provider) => provider.name === "slack",
  );

  const handleChange = (isChecked: boolean) => {
    setSlackNotificationPreference(isChecked);
    !isChecked && setSelectedChannel(null);
  };

  const handleChannelSelection = (selected: Option) => {
    if (selected) {
      setSelectedChannel(selected as Option);
    } else {
      setSelectedChannel(null);
    }
  };

  const updatePreferences = async () => {
    if (!slackProvider) {
      toast({
        title: "Error",
        description: "Please connect your slack account.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setIsLoading(true);
    const response = await tableService.updateTableDataV2(
      tableData?._id || "",
      {
        metaData: {
          sendSlackNotifications: slackNotificationPreference,
          selectedChannel,
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    onClose(true);
    setIsLoading(false);
  };

  const getSlackChannelList = async () => {
    const response = await providerService.getSlackChannelList();
    const channels = response.data?.map((channel: SlackChannelType) => ({
      label: channel.name,
      value: channel.id,
    }));
    setSlackChannelList(channels);
  };

  useEffect(() => {
    if (slackNotificationPreference) {
      getSlackChannelList();
    }
  }, [slackNotificationPreference]);

  useEffect(() => {
    if (isConfigured) {
      setSlackNotificationPreference(
        !!tableData?.metaData?.sendSlackNotifications,
      );
      setSelectedChannel(tableData?.metaData?.selectedChannel || {});
    }
  }, [tableData, isConfigured]);

  return (
    <Modal
      size={"3xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose(false);
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Auto Pilot - Slack Notifications</ModalHeader>
        <ModalCloseButton />
        <ModalBody className=" max-h-[500px] overflow-y-auto custom-scrollbar">
          <div className="w-[50%] mx-auto flex flex-col mb-4 gap-4">
            <div className="flex !h-[35px] items-center  !bg-white px-2 !text-sm">
              <label
                className="mr-2 cursor-pointer font-bold text-[16px]"
                htmlFor="auto-run-switch"
              >
                Get Slack Alerts:
              </label>
              <Switch
                isChecked={slackNotificationPreference}
                size="md"
                onChange={(e) => {
                  handleChange(e.target.checked);
                }}
                id="auto-run-switch"
              />
            </div>
            {slackNotificationPreference && (
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Select slack channel"
                closeMenuOnSelect
                options={slackChannelList || []}
                value={selectedChannel}
                onChange={(selected) =>
                  handleChannelSelection(selected as Option)
                }
              />
            )}
            <SlackIntegration />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size={"sm"}
            mr={3}
            onClick={() => onClose(false)}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isLoading}
            onClick={updatePreferences}
            isDisabled={slackNotificationPreference && !selectedChannel?.value}
          >
            {isConfigured ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Configurations;
