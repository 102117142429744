import { useEffect, useState } from "react";

import { useGridFilter } from "@/components/DataTable/Table/hooks/useGridFilter";
import { useTable } from "@/components/DataTable/Table/hooks/useTable";
import useSocket from "@/lib/socket";
import { userStore } from "@/stores/user.store";
import { ISocketActiveJobsData } from "@/types/socket.types";

export const useJobSync = () => {
  const { refresh, flashSelectedRows } = useTable();
  const { applyFilters: applyGridFilters } = useGridFilter();
  const currentUser = userStore((state) => state.currentUser);

  const [syncStatus, setStatus] = useState<"loading" | "finished">("finished");
  const { subscribe } = useSocket();
  useEffect(() => {
    if (currentUser?._id) {
      subscribe(
        `activeJobs-processing-${currentUser._id}`,
        async (data: ISocketActiveJobsData) => {
          if (data.isRefetch) {
            if (data.leadSearchId) {
              applyGridFilters({
                leadSearchId: {
                  filter: data.leadSearchId,
                  filterType: "text",
                  type: "contains",
                },
              });
            }
            flashSelectedRows(2000);
            setStatus("finished");
            refresh();
            console.log("socketData", data);
          } else if (data.status === "started") {
            setStatus("loading");
          }
        },
      );
    }
  }, [currentUser?._id]);
  return syncStatus;
};
