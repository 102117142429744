import React from "react";
import ReactSelect from "react-select";
import type { Option } from "@/components/Common/MultiSelectDropdown/types";
import { dropdownOptions } from "./constants";

interface Props {
  updateSelectedOptions: (key: string, value: Option) => void;
  selectedOptions: {
    leadStatus: Option;
    companyType: Option;
    industry: Option;
    lifecycle: Option;
  };
}

const DropdownOptions = ({ updateSelectedOptions, selectedOptions }: Props) => {
  return (
    <div className="mt-2 space-y-3">
      {dropdownOptions.map((option) => (
        <div>
          <p className="pb-1.5 text-[0.95rem] font-medium">{option.name}</p>
          <ReactSelect
            isSearchable
            options={option.optionList}
            onChange={(selectedValues) => {
              updateSelectedOptions(option.key, selectedValues as Option);
            }}
            // @ts-ignore
            value={selectedOptions[option.key]}
          />
          <p className="text-xs font-title font-medium tracking-wide pt-0.5 pl-0.5 text-darkTextGray">
            {option.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default React.memo(DropdownOptions);
