import { Box } from "@chakra-ui/react";
import React from "react";

import { useEnrichStore } from "@/stores/enrich.store";
import { TopEnrichmentTypes, topEnrichments } from "./constants";

const TopEnrichments = () => {
  const updateState = useEnrichStore((state) => state.updateState);

  const handleEnrichmentClick = (item: TopEnrichmentTypes) => {
    updateState({
      selectedEnrichments: item,
      viewMode: "create",
      isOpenEnrichModal: false,
    });
  };

  return (
    (<div className="mb-[10px] w-[96%] grid grid-cols-3 gap-2 px-2 pr-0">
      {topEnrichments.map((item, index) => {
        return (
          (<React.Fragment key={index}>
            <Box
              className={
                "enrich-" +
                item?.name?.toLowerCase().replace(/ /g, "-") +
                " flex items-center gap-[16px] py-3 bg-gray"
              }
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="md"
              padding={"12px 24px"}
              background={"#F5F3FF"}
              cursor={"pointer"}
              // onClick={item?.onClick}
              onClick={() => {
                handleEnrichmentClick(item);
              }}
            >
              <Box boxShadow="xl">
                {typeof item.icon === "string" ? (
                  <img src={item.icon} alt="icon" />
                ) : (
                  <item.icon
                    size={30}
                    color={`${item.name === "Use AI" ? "#0284FE" : "gray"}`}
                  />
                )}
              </Box>
              <p className="text-[14px] font-medium">{item.name}</p>
            </Box>
          </React.Fragment>)
        );
      })}
    </div>)
  );
};

export default TopEnrichments;
