import { useState, useEffect } from "react";

const useTypedText = (
  texts: string[],
  typingSpeed = 30,
  erasingSpeed = 30,
  delay = 5000,
) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loop, setLoop] = useState(0);

  useEffect(() => {
    let timer: any;
    const currentPlaceholder = texts[currentIndex];

    if (isDeleting) {
      timer = setTimeout(() => {
        setCurrentText((prev) => prev.slice(0, prev.length - 1));
      }, erasingSpeed);
    } else {
      timer = setTimeout(() => {
        setCurrentText((prev) => currentPlaceholder.slice(0, prev.length + 1));
      }, typingSpeed);
    }

    if (!isDeleting && currentText === currentPlaceholder) {
      timer = setTimeout(() => {
        setIsDeleting(true);
      }, delay);
    } else if (isDeleting && currentText === "") {
      setIsDeleting(false);
      setCurrentIndex((prev) => (prev + 1) % texts.length);
      setLoop(loop + 1);
    }

    return () => clearTimeout(timer);
  }, [
    currentText,
    isDeleting,
    currentIndex,
    texts,
    typingSpeed,
    erasingSpeed,
    delay,
    loop,
  ]);

  return currentText;
};

export default useTypedText;
