import { Link } from "@chakra-ui/react";

export const CompanyWebsiteRenderer = (props: any) => {
  return (
    <div className="">
      <Link href={props?.data?.website} target="_blank">
        <span className="text-[14px] font-[400] text-[#5024DC]">
          {props?.data?.website}
        </span>
      </Link>
    </div>
  );
};
