import {
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
  IndicatorsContainerProps,
  components,
} from "react-select";
import { IconButton } from "@chakra-ui/react";
import { TProviderOption } from "../types";
import { useWaterfallStore } from "../store";
import { ApiKeySelector } from "./ApiKeySelector";
import Icons from "@/components/Icons";

export const IndicatorsContainer = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  ...props
}: IndicatorsContainerProps<TProviderOption, false>) => {
  const setState = useWaterfallStore((state) => state.setState);
  const provider = useWaterfallStore((state) =>
    state.selectedProviders.find(
      (p) => p.value === props.getValue()?.[0]?.value,
    ),
  );
  const selectedProviders = useWaterfallStore(
    (state) => state.selectedProviders,
  );
  if (!provider) return null;
  return (
    <span onMouseDown={(e) => e.stopPropagation()}>
      <components.IndicatorsContainer {...props}>
        <components.DropdownIndicator {...(props as DropdownIndicatorProps)} />
        <components.IndicatorSeparator
          {...(props as unknown as IndicatorSeparatorProps)}
        />
        <ApiKeySelector mx={2} providerOption={props.getValue()?.[0]} />
        <IconButton
          aria-label="remove-provider"
          size="sm"
          className="ml-[-2px] mr-0 w-[12px] hover:!bg-white"
          variant={"ghost"}
          onClick={(e) => {
            e.stopPropagation();
            setState({
              selectedProviders: selectedProviders.filter(
                (p) => p.value !== provider.value,
              ),
            });
          }}
          icon={<Icons.CloseIcon />}
        />
      </components.IndicatorsContainer>
    </span>
  );
};
