function CheckCircleOutline({ className }: { className?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.0001 18.3337C11.0946 18.335 12.1787 18.1201 13.1899 17.7012C14.2011 17.2823 15.1196 16.6678 15.8926 15.8928C16.6675 15.1198 17.2821 14.2014 17.701 13.1901C18.1198 12.1789 18.3348 11.0949 18.3334 10.0003C18.3348 8.90579 18.1198 7.82176 17.701 6.81054C17.2821 5.79931 16.6675 4.88082 15.8926 4.10783C15.1196 3.3329 14.2011 2.71834 13.1899 2.29946C12.1787 1.88059 11.0946 1.66565 10.0001 1.667C8.90554 1.66565 7.82152 1.88059 6.81029 2.29946C5.79907 2.71834 4.88058 3.3329 4.10759 4.10783C3.33265 4.88082 2.7181 5.79931 2.29922 6.81054C1.88034 7.82176 1.6654 8.90579 1.66675 10.0003C1.6654 11.0949 1.88034 12.1789 2.29922 13.1901C2.7181 14.2014 3.33265 15.1198 4.10759 15.8928C4.88058 16.6678 5.79907 17.2823 6.81029 17.7012C7.82152 18.1201 8.90554 18.335 10.0001 18.3337Z"
        stroke="url(#paint0_linear_4732_5533)"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 10L9.16675 12.5L14.1667 7.5"
        stroke="url(#paint1_linear_4732_5533)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4732_5533"
          x1="1.66675"
          y1="10.0003"
          x2="18.3334"
          y2="10.0003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E32A4" />
          <stop offset="1" stopColor="#5024DC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4732_5533"
          x1="6.66675"
          y1="10"
          x2="14.1667"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E32A4" />
          <stop offset="1" stopColor="#5024DC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CheckCircleOutline;
