import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button,
  NumberInput,
  NumberInputField,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";

import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import accountMonitoringService from "@/services/accountMonitoring.service";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useModalStore } from "@/stores/modal.store";
import { useGetHubspotLists } from "../HubspotContactImportModal/hooks";

const HubspotImportModal = () => {
  const toast = useToast();
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfLeads, setNumberOfLeads] = useState(1000);
  const { isLoading: isHubspotListFetching, data } = useGetHubspotLists();
  const [selectedList, setSelectedList] = useState<Option | null>(null);

  const hubspotListData = useMemo(() => {
    if (!data || !data?.lists?.length) return [];
    return data.lists.map((list) => ({
      label: list.name,
      value: list.listId,
    }));
  }, [data]);

  const onClose = () => {
    setNumberOfLeads(1);
    updateState({
      isOpenModal: false,
      modalData: {
        modalType: "",
        metaData: {},
      },
    });
  };

  const handleImport = async () => {
    const apiPath =
      isOpenModal && modalData.modalType === "job-tracking"
        ? "api/users/hubspot-leads"
        : "api/users/hubspot-companies";
    setIsLoading(true);
    let res;
    if (modalData.modalType === "job-tracking") {
      res = await accountMonitoringService.importDataFromHubspotV2(
        numberOfLeads,
        selectedList?.value,
      );
    } else {
      res = await accountMonitoringService.importDataFromHubspot(
        apiPath,
        numberOfLeads,
      );
    }
    if (res?.success) {
      modalData?.metaData?.callback();
      onClose();
    } else {
      toast({
        position: "top-right",
        title: res?.data?.message || "Something went wrong",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      onClose();
    }
    setIsLoading(false);
    setNumberOfLeads(1);
  };

  return (
    <>
      <Modal
        onClose={() => {
          onClose();
        }}
        isOpen={
          isOpenModal &&
          (modalData.modalType === "job-opening" ||
            modalData.modalType === "job-tracking")
        }
        size={"2xl"}
        scrollBehavior="outside"
        isCentered
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent className="pb-4 font-poppins">
          <ModalHeader className="border-b-dividerColor border-b-[1px] text-center">
            <div className="d-flex items-center">
              <Text className="flex-1">Hubspot Import</Text>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mt-4 flex items-center gap-2 pb-1">
              <p className="text-sm font-semibold ">
                {`Number of ${
                  modalData.modalType === "job-tracking" ? "leads" : "companies"
                } to fetch`}
              </p>
            </div>
            <div className="relative h-full">
              <NumberInput
                min={0}
                value={numberOfLeads}
                onChange={(val) => {
                  setNumberOfLeads(Number(val));
                }}
              >
                <NumberInputField />
              </NumberInput>
            </div>
            {modalData.modalType === "job-tracking" && (
              <>
                <div className="mt-4 flex items-center gap-2 pb-1">
                  <p className="text-sm font-semibold ">
                    Select List (optional)
                  </p>
                </div>
                <MultiSelectDropdown
                  isMulti={false}
                  placeholder="Select List"
                  closeMenuOnSelect
                  options={hubspotListData}
                  value={selectedList}
                  onChange={(selected) => setSelectedList(selected as Option)}
                  isLoading={isHubspotListFetching}
                />
              </>
            )}
            <div className="mt-[20px] flex w-full items-center justify-center">
              <Button
                isDisabled={!numberOfLeads}
                isLoading={isLoading}
                variant="solid"
                h={"38px"}
                fontSize={14}
                className="!bg-primary-gradient hover:opacity-80"
                colorScheme="primary"
                borderRadius={"6px"}
                onClick={() => {
                  handleImport();
                }}
              >
                <span className="font-semibold">Import</span>
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HubspotImportModal;
