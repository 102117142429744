import { useRef } from "react";

import DataTable from "@/components/Table";
import PageActions from "@/components/Table/PageActions";
import { AgGridReact } from "ag-grid-react";
import { useTableStore } from "@/stores/table.store";

interface Props {
  isRefetching: boolean;
}

const TablePage = ({ isRefetching }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const tableData = useTableStore((state) => state.tableData);
  return (
    <div className="z-20 flex  h-full flex-col  px-2 pt-2">
      <PageActions isRefetching={isRefetching} gridRef={gridRef} />
      {Object.entries(tableData).length > 0 && (
        <DataTable isRefetching={isRefetching} gridRef={gridRef} />
      )}
    </div>
  );
};

export default TablePage;
