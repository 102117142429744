import { InputtedData } from "./store";

export const calculateAppliedFiltersCount = (data: InputtedData) => {
  let totalLength = 0;

  for (const key in data) {
    if (key !== "company_names" && key !== "fetchedCompanies") {
      // @ts-expect-error ignore
      const val = data[key];
      if (Array.isArray(val)) {
        totalLength += val.length;
      } else if (typeof val === "string") {
        totalLength += val.length > 0 ? 1 : 0;
      }
    }
  }

  return totalLength;
};
