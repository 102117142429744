import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import type { RuleGroupTypeIC } from "react-querybuilder";
import { defaultValidator, QueryBuilder } from "react-querybuilder";
import { useFields } from "./fields";
import "react-querybuilder/dist/query-builder.css";
import "./styles.css";
import { QueryBuilderChakra } from "@react-querybuilder/chakra";
import { CloseButton } from "./CloseButton";

export const PReactQueryBuilder = ({
  query,
  setQuery,
}: {
  query: RuleGroupTypeIC;
  setQuery: (query: RuleGroupTypeIC) => void;
}) => {
  const fields = useFields();
  return (
    <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
      <QueryBuilderChakra>
        <QueryBuilder
          autoSelectOperator={false}
          listsAsArrays
          enableDragAndDrop={false}
          autoSelectField={false}
          fields={fields}
          query={query}
          validator={defaultValidator}
          onQueryChange={setQuery}
          controlClassnames={{
            queryBuilder: "queryBuilder-branches",
          }}
          controlElements={{
            // fieldSelector: ValueSelector,
            removeRuleAction: CloseButton,
          }}
        />
      </QueryBuilderChakra>
    </QueryBuilderDnD>
  );
};
