import React from "react";
import { Handle, NodeToolbar, Position } from "@xyflow/react";
import { useDisclosure } from "@chakra-ui/react";

import Toolbar from "../Toolbar";
import Configurations from "./Configurations";
import CustomNodeCard from "../CustomNodeCard";

import AlertImg from "@/assets/svgs/auto-pilot-alerts.svg";
import { CustomNodeProps } from "../types";

const SlackNotifications: React.FC<CustomNodeProps> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleConfig = () => {
    onOpen();
  };

  const handleClose = (isRefetch: boolean) => {
    isRefetch && data?.refetch();
    onClose();
  };

  return (
    <>
      <Handle type="target" position={Position.Top} />
      {!data.isDisabled && data.showToolbar && (
        <NodeToolbar isVisible={true} position={Position.Right}>
          <Toolbar
            isConfigured={!!data.isConfigured}
            handleConfig={handleConfig}
          />
        </NodeToolbar>
      )}
      <CustomNodeCard
        isActive={data.showToolbar || false}
        title="Slack Notifications"
        subtitle="Alerts"
        configStatus={
          data?.tableData?.metaData?.sendSlackNotifications
            ? "Configured & Enabled Slack Alerts"
            : "Configured & Disabled Slack Alerts"
        }
        isConfigured={!!data.isConfigured}
        isDisabled={!!data.isDisabled}
        rightIcon={
          "https://persana-assets.s3.us-west-1.amazonaws.com/slack.png"
        }
        imgSrc={AlertImg}
      />
      <Configurations
        isConfigured={!!data.isConfigured}
        tableData={data?.tableData}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default SlackNotifications;
