import { useEffect, useState } from "react";

import KeySelect from "@/components/Common/KeySelect";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import ConditionalFormula from "../../Common/ConditionalFormula";

import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import { useEnrichStore } from "@/stores/enrich.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { preselectEnrichmentState } from "@/utils";

const CapterraScrape = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    }
  }, [selectedColumnToEdit, updateState, viewMode]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Run a Capterra scrape via Zenrows by Company Name"
          link={""}
        />
        <KeySelect
          providerName={"zenrowsApiKey"}
          providerTitle="Zenrows"
          disablePersanaKey={true}
        />
        <div className="py-4">
          <p className="pb-1 text-base font-semibold">Company Name</p>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          apiKeyName: "zenrowsApiKey",
          columnId: selectedOption?.keyId,
        }}
      />
    </>
  );
};

export default CapterraScrape;
