import { useEffect, useState } from "react";

import KeySelect from "@/components/Common/KeySelect";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";

import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { REGEX } from "../../constants";

const FindMobileNumber = () => {
  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentMetaData;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    } else {
      const initialLinkedInColumn = getInitialColumn(REGEX.LINKEDINPROFILE);
      if (initialLinkedInColumn) {
        setSelectedOption({
          key: initialLinkedInColumn.name,
          keyId: initialLinkedInColumn._id,
          iconType: initialLinkedInColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={"Get the Mobile Number from any LinkedIn profile"}
          link={""}
        />
        <KeySelect
          providerName={"leadMagicKey"}
          providerTitle="LeadMagic"
          disablePersanaKey={false}
        />

        <p className="pb-1 text-base font-semibold">LinkedIn Url</p>

        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
          apiKeyName: "leadMagicKey",
        }}
      />
    </>
  );
};

export default FindMobileNumber;
