import React from "react";
import { Button } from "@chakra-ui/react";

import Dropdown from "@/components/Common/Dropdown";
import Icons from "@/components/Icons";

const ConnectAccount = () => {
  const checkAuthorized = () => {
    window.location.assign(
      `https://github.com/login/oauth/authorize?client_id=${import.meta.env.VITE_GITHUB_CLIENT_ID}&scope=user:email`,
    );
  };

  return (
    <div>
      <p className="mb-3 text-sm font-normal text-primary">
        Find people who have starred a repository.
      </p>
      <Dropdown
        animation="transition-all duration-300 ease-in-out"
        containerClassName="w-full mb-4"
        buttonWrapperClassName="w-full"
        button={
          <div className="relative flex w-full cursor-pointer items-center rounded-md border border-primary px-2 py-1">
            <p className="w-full truncate text-sm sm:text-base">
              Connect an account
            </p>{" "}
            <Icons.ArrowUpDownIcon className="ml-2 inline-block size-4 font-bold text-primary" />
          </div>
        }
        children={
          <div>
            <Button
              leftIcon={<Icons.AddOutlineIcon />}
              className="!bg-white !font-normal"
              variant="outline"
              onClick={checkAuthorized}
            >
              Connect your account
            </Button>
          </div>
        }
        classNames={"py-2 top-7"}
      />
    </div>
  );
};

export default React.memo(ConnectAccount);
