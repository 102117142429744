import { Button, Input, Switch } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Descendant } from "slate";

import KeySelect from "@/components/Common/KeySelect";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import PersanaAccordion from "@/components/Common/PersanaAccordion";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import CustomTextEditor from "../../Common/CustomTextEditor";
import ConditionalFormula from "../../Common/ConditionalFormula";
import Icons from "@/components/Icons";

import { useInstantlyCampaigns } from "@/hooks/useProviderData";
import { useEnrichStore } from "@/stores/enrich.store";
import { convertSlateToText } from "@/utils";
import { arrayToObject, transformCustomFieldsObject } from "@/lib/utils";

type CustomField = {
  id: string;
  name?: string;
  value?: Descendant[];
};

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const LeadToInstantly = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const [selectedCampaign, setSelectedCampaign] = useState<Option | null>(null);
  const [campaigns, setCampaigns] = useState<Option[]>([]);
  const [email, setEmail] = useState<any>(initialSlateValue);
  const [firstName, setFirstName] = useState<any>(initialSlateValue);
  const [lastName, setLastName] = useState<any>(initialSlateValue);
  const [companyName, setCompanyName] = useState<any>(initialSlateValue);
  const [phone, setPhone] = useState<any>(initialSlateValue);
  const [website, setWebsite] = useState<any>(initialSlateValue);
  const [personalization, setPersonalization] =
    useState<any>(initialSlateValue);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [skipInCampaign, setSkipInCampaign] = useState(false);
  const [skipInWorkspace, setSkipInWorkspace] = useState(false);

  const {
    data: instantlyCampaigns,
    isLoading,
    isFetching,
    refetch,
  } = useInstantlyCampaigns();
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);

  const handleCampaignChange = (selected: any) => {
    setSelectedCampaign(selected);
  };

  const handleCustomField = (
    id: string,
    name: string,
    value: Descendant[] | string,
  ): void => {
    setCustomFields((prevFields: CustomField[]) =>
      prevFields.map((field: CustomField) =>
        field.id === id ? { ...field, [name]: value } : field,
      ),
    );
  };

  const handleRemoveCustomField = (name: string): void => {
    const newFields = customFields?.filter(
      (field: CustomField) => field.name !== name,
    );
    setCustomFields(newFields);
  };

  const handleAddCustomField = (): void => {
    const newField: CustomField = {
      id: `custom_field_name_${customFields.length + 1}`,
      name: `Custom Field Name ${customFields.length + 1}`,
      value: initialSlateValue,
    };

    setCustomFields((prevFields: CustomField[]) => [...prevFields, newField]);
  };

  useEffect(() => {
    if (instantlyCampaigns?.success) {
      const formattedData = instantlyCampaigns.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      setCampaigns(formattedData);
    }
  }, [instantlyCampaigns]);

  const optionOptions = [
    {
      name: "First Name",
      state: firstName,
      setState: setFirstName,
    },
    {
      name: "Last Name",
      state: lastName,
      setState: setLastName,
    },
    {
      name: "Company Name",
      state: companyName,
      setState: setCompanyName,
    },
    {
      name: "Personalization",
      state: personalization,
      setState: setPersonalization,
    },
    {
      name: "Phone",
      state: phone,
      setState: setPhone,
    },
    {
      name: "Website",
      state: website,
      setState: setWebsite,
    },
  ];

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as any;
      if (enrichmentData) {
        if (campaigns?.length > 0) {
          const filteredCampaign = campaigns.find(
            (item) => item.value === enrichmentData.campaignId,
          );
          filteredCampaign && setSelectedCampaign(filteredCampaign);
        }
        const formattedCustomFields = transformCustomFieldsObject(
          enrichmentData?.customFields || {},
        );
        setCustomFields(formattedCustomFields);

        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });

        const {
          email,
          firstName,
          lastName,
          companyName,
          personalization,
          phone,
          website,
          skipInCampaign,
          skipInWorkspace,
        } = enrichmentData;
        setEmail(email);
        setFirstName(firstName);
        setLastName(lastName);
        setCompanyName(companyName);
        setPersonalization(personalization);
        setPhone(phone);
        setWebsite(website);
        setSkipInCampaign(skipInCampaign);
        setSkipInWorkspace(skipInWorkspace);
      }
    }
  }, [viewMode, selectedColumnToEdit, campaigns]);

  return (
    <>
      <div className="overflow-y-auto p-4">
        <WatchTutorial
          subText="Learn how to add generated leads to Instantly"
          link={""}
        />
        <KeySelect
          providerName={"instantlyApiKey"}
          providerTitle="Instantly"
          disablePersanaKey={true}
        />
        {/* <SelectTemplate /> */}
        <div className="my-5">
          <div className="flex items-center justify-between pb-0.5">
            <div className="flex items-center gap-2">
              <p className="text-base font-semibold">Campaign ID</p>
              <Icons.RefreshIcon
                size={19}
                className={`cursor-pointer ${isLoading || isFetching ? "animate-spin" : ""}`}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
          <MultiSelectDropdown
            isMulti={false}
            placeholder="choose a campaign"
            closeMenuOnSelect
            options={campaigns || []}
            value={selectedCampaign}
            onChange={handleCampaignChange}
          />
          <div className="flex items-center justify-between">
            <p className="block py-1 text-sm">
              The Campaign you want to add the lead to.
            </p>
          </div>
        </div>

        {/* Skip Preference */}
        <div className="flex w-full items-center justify-between rounded-[6px] bg-[#FAFAFB] px-4 py-2 mb-4">
          <p className="text-[14px] font-[500]">
            Skip lead if exist in the campaign
          </p>
          <Switch
            size={"md"}
            isChecked={skipInCampaign}
            onChange={() => setSkipInCampaign((prev) => !prev)}
          />
        </div>
        <div className="flex w-full items-center justify-between rounded-[6px] bg-[#FAFAFB] px-4 py-2">
          <p className="text-[14px] font-[500]">
            Skip lead if exist in the workspace
          </p>
          <Switch
            size={"md"}
            isChecked={skipInWorkspace}
            onChange={() => setSkipInWorkspace((prev) => !prev)}
          />
        </div>

        {/* Email */}
        <div className="py-4">
          <p className="pb-1 text-base font-semibold">Email</p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={email}
            setSlateValue={setEmail}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>

        <PersanaAccordion
          defaultIndex={[0]}
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
          }}
          items={[
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add More Details (Optional)
                </button>
              ),

              panel: (
                <div className="space-y-3">
                  {optionOptions.map((item) => (
                    <div key={item.name}>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {item?.name}
                      </p>
                      <CustomTextEditor
                        editorHeight={"4rem"}
                        slateValue={item?.state}
                        setSlateValue={item?.setState}
                        placeholder={`Start typing or use the dropdown to select a column.`}
                      />
                    </div>
                  ))}
                </div>
              ),
            },
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Custom Fields (Optional)
                </button>
              ),

              panel: (
                <>
                  {customFields?.map((field: any, index: number) => (
                    <div
                      className="mt-3 flex items-center justify-start gap-[5px]"
                      key={`other-fields-${index}`}
                    >
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">name</p>
                        <Input
                          maxW={"200px"}
                          value={field?.name || ""}
                          placeholder={"Enter name..."}
                          name={"name"}
                          onChange={(e) =>
                            handleCustomField(
                              field.id,
                              e.target.name,
                              e.target.value,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">value</p>
                        <CustomTextEditor
                          editorHeight={"1rem"}
                          slateValue={field.value}
                          setSlateValue={(val) =>
                            handleCustomField(field.id, "value", val)
                          }
                          showDetails={false}
                        />
                      </div>
                      <Button
                        className="mb-1 self-end"
                        onClick={() => {
                          handleRemoveCustomField(field.name);
                        }}
                        size={"sm"}
                      >
                        X
                      </Button>
                    </div>
                  ))}

                  {customFields?.length < 20 ? (
                    <div className="mt-5">
                      <Button size={"sm"} onClick={handleAddCustomField}>
                        + Add New Custom Field
                      </Button>
                      <span className={"block py-1 text-sm text-[#676d7e]"}>
                        Any fields you want to add to the lead. Put the name of
                        the field on the left and the value on the right.
                      </span>
                    </div>
                  ) : null}
                </>
              ),
            },
          ]}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedCampaign || !convertSlateToText(email).text}
        apiPayload={{
          apiKeyName: "instantlyApiKey",
          campaignId: selectedCampaign?.value,
          email: email,
          firstName: firstName,
          lastName: lastName,
          companyName: companyName,
          personalization: personalization,
          phone,
          website,
          skipInCampaign,
          skipInWorkspace,
          customFields:
            customFields?.length > 0 ? arrayToObject(customFields) : {},
        }}
      />
    </>
  );
};

export default LeadToInstantly;
