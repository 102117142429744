import React from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  useDisclosure,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

const MobileAlertDialogComponent: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  const screenWidth = window.innerWidth;
  const isMobile = screenWidth < 768; // Example threshold for mobile screens

  React.useEffect(() => {
    if (isMobile) {
      onOpen();
    }
  }, [isMobile, onOpen]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <Alert status="error">
              <AlertIcon />
              This site is currently optimized for Desktop-view only. Please use
              a desktop for better experience
            </Alert>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default MobileAlertDialogComponent;
