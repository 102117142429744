import leadService from "@/services/leads.service";
import { LeadSearchHistoryData, LeadSearchType } from "@/types/leads.types";
import { useCallback } from "react";
import Swal from "sweetalert2";

import Icons from "../Icons";

import { useQueryClient } from "@tanstack/react-query";
import { useGridFilter } from "../DataTable/Table/hooks/useGridFilter";
import { useTableStore } from "../DataTable/Table/hooks/useGridState.store";
import { useTable } from "../DataTable/Table/hooks/useTable";

interface Props {
  leadSearchHistoryData: LeadSearchHistoryData[];
}

const SearchHistory = ({ leadSearchHistoryData }: Props) => {
  const queryClient = useQueryClient();

  const { applyFilters: applyGridFilters } = useGridFilter();
  const { refresh, flashSelectedRows } = useTable();
  const appliedFilters = useTableStore(
    (state) => state.gridApi?.getState().filter?.filterModel || null,
  );

  const handleDelete = useCallback(
    (historyId: string) => {
      Swal.fire({
        text: "Do you want to delete records as well?",
        icon: "warning",
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        denyButtonText: "No",
        showCloseButton: true,
        preConfirm: async () => {
          await leadService.deleteLeadSearchHistory(historyId, true);
        },
        preDeny: async () => {
          await leadService.deleteLeadSearchHistory(historyId, false);
        },
      }).then(async () => {
        applyGridFilters(null);
        queryClient.refetchQueries({
          queryKey: ["lead-search-history", LeadSearchType.COMPANIES_HIRING],
        });
        flashSelectedRows(2000);
        refresh();
      });
    },
    [queryClient, applyGridFilters],
  );

  const appliedSearchHistoryId = appliedFilters?.leadSearchId?.filter;
  console.log("isFilterApplied", appliedFilters);

  return (
    <div className="w-full">
      <div className="mb-5 flex w-full items-center justify-between">
        <p className="text-xs text-darkTextGray">(Recent 10)</p>
        {!!appliedSearchHistoryId && (
          <p
            className="cursor-pointer text-xs text-dark underline"
            onClick={() => {
              applyGridFilters(null);
            }}
          >
            Clear(X)
          </p>
        )}
      </div>
      <div className="max-h-[200px] overflow-y-auto pr-[10px] pt-[10px]">
        {leadSearchHistoryData.map((history) => (
          <div
            key={history._id}
            className={`relative mb-2 flex w-full cursor-pointer items-center justify-between rounded-md p-[5px] hover:bg-slate-100 ${appliedSearchHistoryId === history._id ? "bg-slate-200" : "bg-slate-50"}`}
            onClick={() => {
              applyGridFilters({
                leadSearchId: {
                  filter: history._id,
                  filterType: "text",
                  type: "contains",
                },
              });
            }}
          >
            <p className="text-sm">
              <strong>Job Title</strong>: {history.metaData.query} <br />
              <strong>Location</strong>: {history.metaData.location}
            </p>
            <Icons.RightArrowIcon className="size-4" />
            <Icons.TrashIcon
              className="absolute -right-2 -top-2 size-[16px] text-red-500"
              onClick={(e: any) => {
                e.stopPropagation();
                handleDelete(history._id);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchHistory;
