import { useEffect, useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { Descendant } from "slate";

import KeySelect from "@/components/Common/KeySelect";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import WatchTutorial from "@/components/Common/WatchTutorial";
import { useSmartLeadsCampaigns } from "@/hooks/useProviderData";
import CustomTextEditor from "../../Common/CustomTextEditor";
import PersanaAccordion from "@/components/Common/PersanaAccordion";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { arrayToObject } from "@/lib/utils";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";

type CustomField = {
  id: string;
  name?: string;
  value?: Descendant[];
};

interface EnrichmentDataType extends EnrichmentMetaData {
  campaignId: string;
}

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const LeadToSmartLeads = () => {
  const [selectedCampaign, setSelectedCampaign] = useState<Option | null>(null);
  const [campaigns, setCampaigns] = useState<Option[]>([]);
  const [email, setEmail] = useState<any>(initialSlateValue);
  const [firstName, setFirstName] = useState<any>(initialSlateValue);
  const [lastName, setLastName] = useState<any>(initialSlateValue);
  const [phoneNumber, setPhoneNumber] = useState<any>(initialSlateValue);
  const [website, setWebsite] = useState<any>(initialSlateValue);
  const [location, setLocation] = useState<any>(initialSlateValue);
  const [companyName, setCompanyName] = useState<any>(initialSlateValue);
  const [companyUrl, setCompanyUrl] = useState<any>(initialSlateValue);
  const [linkedinProfile, setLinkedInProfile] =
    useState<any>(initialSlateValue);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const toast = useToast();
  const { data: smartLeadCampaings } = useSmartLeadsCampaigns();

  const handleCampaignChange = (selected: any) => {
    setSelectedCampaign(selected);
  };

  const handleCustomField = (
    id: string,
    name: string,
    value: Descendant[] | string,
  ): void => {
    setCustomFields((prevFields: CustomField[]) =>
      prevFields.map((field: CustomField) =>
        field.id === id ? { ...field, [name]: value } : field,
      ),
    );
  };

  const handleRemoveCustomField = (name: string): void => {
    const newFields = customFields?.filter(
      (field: CustomField) => field.name !== name,
    );
    setCustomFields(newFields);
  };

  const handleAddCustomField = (): void => {
    const newField: CustomField = {
      id: `custom_field_name_${customFields.length + 1}`,
      name: `Custom Field Name ${customFields.length + 1}`,
      value: initialSlateValue,
    };

    setCustomFields((prevFields: CustomField[]) => [...prevFields, newField]);
  };

  useEffect(() => {
    if (smartLeadCampaings?.success) {
      const formattedData = smartLeadCampaings.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      setCampaigns(formattedData);
    } else if (smartLeadCampaings && !smartLeadCampaings?.success) {
      setCampaigns([]);
      toast({
        position: "top-right",
        title: "Error",
        description: smartLeadCampaings?.error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [smartLeadCampaings]);

  const optionOptions = [
    {
      name: "First Name",
      state: firstName,
      setState: setFirstName,
    },
    {
      name: "Last Name",
      state: lastName,
      setState: setLastName,
    },
    {
      name: "Phone Number",
      state: phoneNumber,
      setState: setPhoneNumber,
    },
    {
      name: "Website",
      state: website,
      setState: setWebsite,
    },
    {
      name: "Location",
      state: location,
      setState: setLocation,
    },
    {
      name: "Company Name",
      state: companyName,
      setState: setCompanyName,
    },
    {
      name: "Company Url",
      state: companyUrl,
      setState: setCompanyUrl,
    },
    {
      name: "Linkedin Profile",
      state: linkedinProfile,
      setState: setLinkedInProfile,
    },
  ];

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType | undefined;

      if (enrichmentData) {
        if (
          enrichmentData?.campaignId &&
          smartLeadCampaings?.data?.length > 0
        ) {
          const campaign = smartLeadCampaings?.data?.find(
            (item: any) => item.id === parseInt(enrichmentData?.campaignId),
          );

          if (campaign) {
            setSelectedCampaign({
              value: campaign.id,
              label: campaign.name,
            });
          }
        }

        if (enrichmentData?.email?.length) {
          setEmail(enrichmentData.email);
        }

        if (enrichmentData?.firstName?.length) {
          setFirstName(enrichmentData.firstName);
        }

        if (enrichmentData?.lastName?.length) {
          setLastName(enrichmentData.lastName);
        }

        if (enrichmentData?.phoneNumber?.length) {
          setPhoneNumber(enrichmentData.phoneNumber);
        }

        if (enrichmentData?.website?.length) {
          setWebsite(enrichmentData.website);
        }

        if (enrichmentData?.location?.length) {
          setLocation(enrichmentData.location);
        }

        if (enrichmentData?.companyName?.length) {
          setCompanyName(enrichmentData.companyName);
        }

        if (enrichmentData?.companyUrl?.length) {
          setCompanyUrl(enrichmentData.companyUrl);
        }

        if (enrichmentData?.linkedinProfile?.length) {
          setLinkedInProfile(enrichmentData.linkedinProfile);
        }

        if (enrichmentData?.customFields) {
          const customFields = Object.entries(
            enrichmentData.customFields || {},
          ).map(([key, value]) => ({
            id: `custom_field_name_${key}`,
            name: key,
            value: value,
          }));

          setCustomFields(
            customFields.map((field) => ({
              ...field,
              value: field.value as Descendant[] | undefined,
            })),
          );
        }
      }
    }
  }, [viewMode, selectedColumnToEdit, smartLeadCampaings]);

  return (
    <>
      <div className="overflow-y-auto p-4">
        <WatchTutorial
          subText="Learn how to add generated leads to SmartLeads"
          link={""}
        />
        <KeySelect
          providerName={"smartLeadsApiKey"}
          providerTitle="SmartLead"
          disablePersanaKey={true}
        />
        {/* <SelectTemplate /> */}
        <div className="mt-5">
          <div className="flex items-center justify-between pb-0.5">
            <p className="mb-1 text-base font-semibold">Campaign ID</p>
          </div>
          <MultiSelectDropdown
            isMulti={false}
            placeholder="choose a campaign"
            closeMenuOnSelect
            options={campaigns || []}
            value={selectedCampaign}
            onChange={handleCampaignChange}
          />
          <div className="flex items-center justify-between">
            <p className="block py-1 text-sm">
              The Campaign you want to add the lead to.
            </p>
          </div>
        </div>

        {/* Email */}
        <div className="py-4">
          <p className="pb-1 text-base font-semibold">Email</p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={email}
            setSlateValue={setEmail}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>

        <PersanaAccordion
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
          }}
          items={[
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add More Details (Optional)
                </button>
              ),

              panel: (
                <div className="space-y-3">
                  {optionOptions.map((item) => (
                    <div>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {item?.name}
                      </p>
                      <CustomTextEditor
                        editorHeight={"4rem"}
                        slateValue={item?.state}
                        setSlateValue={item?.setState}
                        placeholder={`Start typing or use the dropdown to select a column.`}
                      />
                    </div>
                  ))}
                </div>
              ),
            },
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Custom Fields (Optional)
                </button>
              ),

              panel: (
                <>
                  {customFields?.map((field: any) => (
                    <div className="mt-3 flex items-center justify-start gap-[5px]">
                      <div>
                        <p className="text-base font-semibold pb-1">name</p>
                        <Input
                          maxW={"200px"}
                          value={field?.name || ""}
                          placeholder={"Enter name..."}
                          name={"name"}
                          onChange={(e) =>
                            handleCustomField(
                              field.id,
                              e.target.name,
                              e.target.value,
                            )
                          }
                        />
                      </div>
                      <div>
                        <p className="text-base font-semibold pb-1">value</p>
                        <CustomTextEditor
                          editorHeight={"1rem"}
                          slateValue={field.value}
                          setSlateValue={(val) =>
                            handleCustomField(field.id, "value", val)
                          }
                          showDetails={false}
                        />
                      </div>
                      <Button
                        className="self-end mb-1"
                        onClick={() => {
                          handleRemoveCustomField(field.name);
                        }}
                        size={"sm"}
                      >
                        X
                      </Button>
                    </div>
                  ))}

                  {customFields?.length < 20 ? (
                    <div className="mt-5">
                      <Button size={"sm"} onClick={handleAddCustomField}>
                        + Add New Custom Field
                      </Button>
                      <span className={"block py-1 text-sm text-[#676d7e]"}>
                        Any fields you want to add to the lead. Put the name of
                        the field on the left and the value on the right.
                      </span>
                    </div>
                  ) : null}
                </>
              ),
            },
          ]}
        />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedCampaign}
        apiPayload={{
          apiKeyName: "smartLeadsApiKey",
          campaignId: selectedCampaign?.value,
          email: email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          website: website,
          location: location,
          companyName: companyName,
          companyUrl: companyUrl,
          linkedinProfile: linkedinProfile,
          customFields:
            customFields?.length > 0 ? arrayToObject(customFields) : {},
        }}
      />
    </>
  );
};

export default LeadToSmartLeads;
