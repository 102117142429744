import APIKeyIntegration from "./APIKeyIntegration";
import NimblerLogo from "@/assets/svgs/nimbler.svg";

const NimblerIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"nimblerApiKey"}
      providerTitle="Nimbler"
      providerIcon={NimblerLogo}
    />
  );
};

export default NimblerIntegration;
