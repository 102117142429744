import { useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";

import { TSelectedColumnOption } from "@/types/common.types";
import { REGEX, scrapeWebsitesTutorialLink } from "../../constants";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { RadioCardGroup } from "@/components/Common/RadioCard";
import { BiDesktop, BiMobile } from "react-icons/bi";
import { IRadioCardGroup } from "@/components/Common/RadioCard/types";
import { FaPage4 } from "react-icons/fa";
import {
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Divider,
} from "@chakra-ui/react";
import CustomAccordion from "@/components/Common/CustomAccordion";

const deviceOptions: IRadioCardGroup["options"] = [
  {
    label: "Desktop",
    value: "desktop",
    icon: BiDesktop,
  },
  {
    label: "Mobile",
    value: "mobile",
    icon: BiMobile,
  },
];

const screenSizeOptions: IRadioCardGroup["options"] = [
  {
    label: "Full Page",
    value: "full",
    icon: FaPage4,
  },
  {
    label: "Default",
    value: "default",
    icon: FaPage4,
  },
];
export const ScreenshotWebsite = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [device, setDevice] = useState<string>("desktop");
  const [screenSize, setScreenSize] = useState<string>("default");
  const [isCustomSize, setIsCustomSize] = useState<boolean>(false);
  const [customSize, setCustomSize] = useState<[number, number]>([0, 0]);
  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        setDevice(enrichmentData.device || "desktop");
        setScreenSize(enrichmentData.screenSize || "default");
        setCustomSize(enrichmentData.customSize || [0, 0]);
        setIsCustomSize(enrichmentData.isCustomSize || false);
      }
    } else {
      const initialColumn = getInitialColumn(REGEX.WEBSITE);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Capture the screenshot of a website."
          link={scrapeWebsitesTutorialLink}
        />
        <p className="pb-1 text-base font-semibold">Website URL</p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <Divider className="my-4 !h-[1px] !bg-gray" />
        <CustomAccordion title="Device" isOpen={true}>
          <RadioCardGroup
            value={device}
            setValue={setDevice}
            name="devices"
            options={deviceOptions}
          />
        </CustomAccordion>
        <CustomAccordion title="Screen Size" isOpen={true}>
          <RadioCardGroup
            value={isCustomSize ? "None" : screenSize}
            setValue={(value) => {
              setScreenSize(value);
              setIsCustomSize(false);
            }}
            name="screenSize"
            options={screenSizeOptions}
          >
            <Stack
              maxW={"30%"}
              tabIndex={1}
              onFocus={() => {
                setIsCustomSize(true);
              }}
              placeItems={"center"}
              cursor="pointer"
              borderWidth="1px"
              borderRadius="md"
              boxShadow="md"
              {...(isCustomSize && {
                bg: "#3182CE",
                color: "white",
                borderColor: "#CBD5E0",
                boxShadow: "2xl",
              })}
              px={5}
              py={3}
              className="flex flex-col gap-1"
            >
              <InputGroup
                bg="white"
                color="#3182CE"
                size={"sm"}
                className="w-24"
              >
                <Input
                  className="!rounded"
                  h={"1.6em"}
                  type="number"
                  placeholder="Width"
                  value={customSize[1]}
                  onChange={(e) =>
                    setCustomSize((size) => [size[0], Number(e.target.value)])
                  }
                />
                <InputRightElement
                  className="!rounded"
                  lineHeight={"1em"}
                  maxH={"100%"}
                  fontSize={"small"}
                  color="gray"
                  pointerEvents="none"
                >
                  px
                </InputRightElement>
              </InputGroup>
              <InputGroup
                bg="white"
                color="#3182CE"
                size={"sm"}
                className="w-24"
              >
                <Input
                  h={"1.6em"}
                  type="number"
                  size={"sm"}
                  className="w-24"
                  placeholder="Height"
                  value={customSize[0]}
                  onChange={(e) =>
                    setCustomSize((size) => [Number(e.target.value), size[1]])
                  }
                />
                <InputRightElement
                  fontSize={"small"}
                  maxH={"100%"}
                  color="gray"
                  pointerEvents="none"
                >
                  px
                </InputRightElement>
              </InputGroup>
            </Stack>
          </RadioCardGroup>
        </CustomAccordion>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
          device,
          screenSize,
          customSize,
          isCustomSize,
        }}
      />
    </>
  );
};
