import APIKeyIntegration from "./APIKeyIntegration";
import ProspeoLogo from "@/assets/svgs/prospect.svg";

const ProspeoIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"prospeo"}
      providerTitle="Prospeo"
      providerIcon={ProspeoLogo}
    />
  );
};

export default ProspeoIntegration;
