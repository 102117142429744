import { Button, Input, Spinner, useToast } from "@chakra-ui/react";
import { useEffect, useMemo, useReducer, useState } from "react";
import { Descendant } from "slate";

import KeySelect from "@/components/Common/KeySelect";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import PersanaAccordion from "@/components/Common/PersanaAccordion";
import WatchTutorial from "@/components/Common/WatchTutorial";
import Icons from "@/components/Icons";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";

import {
  useHeyReachCampaigns,
  useHeyReachLinkedinAccounts,
  useProviderList,
} from "@/hooks/useProviderData";
import {
  arrayToObject,
  isValidAlphanumericOrUnderscore,
  transformCustomFieldsObject,
} from "@/lib/utils";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";
import { convertSlateToText } from "@/utils";

type CustomField = {
  id: string;
  name: string;
  value: Descendant[];
};

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

interface EnrichmentDataType extends EnrichmentMetaData {
  campaignId: string;
  linkedinAccount: string;
  leadDetailsBySlateValue: {
    firstName: typeof initialSlateValue;
    lastName: typeof initialSlateValue;
    location: typeof initialSlateValue;
    summary: typeof initialSlateValue;
    companyName: typeof initialSlateValue;
    emailAddress: typeof initialSlateValue;
    position: typeof initialSlateValue;
    about: typeof initialSlateValue;
    profileUrl: typeof initialSlateValue;
  };
}

const LeadToHeyReach = () => {
  const toast = useToast();
  const [fetchCampaigns, setFetchCampaigns] = useState(false);
  const [fetchLinkedinAccounts, setFetchLinkedinAccounts] = useState(false);
  const [campaigns, setCampaigns] = useState<Option[]>([]);
  const [linkedinAccounts, setLinkedinAccounts] = useState<Option[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Option | null>(null);
  const [selectedLinkedinAccount, setSelectedLinkedinAccount] =
    useState<Option | null>(null);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const initialState = {
    firstName: initialSlateValue,
    lastName: initialSlateValue,
    location: initialSlateValue,
    summary: initialSlateValue,
    companyName: initialSlateValue,
    emailAddress: initialSlateValue,
    position: initialSlateValue,
    about: initialSlateValue,
    profileUrl: initialSlateValue,
  };

  function reducer(state: any, action: any) {
    return { ...state, [action.key]: action.value };
  }
  const [leadDetails, dispatch] = useReducer(reducer, initialState);
  const updateDetails = (key: string, value: any) => dispatch({ key, value });

  const leadOptions = [
    {
      name: "First Name",
      state: leadDetails.firstName,
      isOptional: false,
      description: "The first name of the lead",
      setState: (value: any) => {
        updateDetails("firstName", value);
      },
    },
    {
      name: "Last Name",
      state: leadDetails.lastName,
      isOptional: false,
      description: "The last name of the lead",
      setState: (value: any) => {
        updateDetails("lastName", value);
      },
    },
    {
      name: "Personal LinkedIn URL",
      state: leadDetails.profileUrl,
      isOptional: false,
      description: "The URL of the lead's LinkedIn Profile",
      setState: (value: any) => {
        updateDetails("profileUrl", value);
      },
    },
    {
      name: "Location",
      state: leadDetails.location,
      isOptional: true,
      description: "The lead's location (e.g. London)",
      setState: (value: any) => {
        updateDetails("location", value);
      },
    },
    {
      name: "Summary",
      state: leadDetails.summary,
      isOptional: true,
      description: "A summary of the lead",
      setState: (value: any) => {
        updateDetails("summary", value);
      },
    },
    {
      name: "Company Name",
      isOptional: true,
      description: "The Company Name of the company where the lead is employed",
      state: leadDetails.companyName,
      setState: (value: any) => {
        updateDetails("companyName", value);
      },
    },
    {
      name: "Email Address",
      isOptional: true,
      description: "The email address of the lead",
      state: leadDetails.emailAddress,
      setState: (value: any) => {
        updateDetails("emailAddress", value);
      },
    },
    {
      name: "Current Position",
      state: leadDetails.position,
      isOptional: true,
      description: "The position of the lead",
      setState: (value: any) => {
        updateDetails("position", value);
      },
    },
    {
      name: "About",
      state: leadDetails.about,
      isOptional: true,
      description: "More about the lead",
      setState: (value: any) => {
        updateDetails("about", value);
      },
    },
  ];

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  const { data: providerList } = useProviderList();
  const isUserApiKeyExists = useMemo(() => {
    if (!providerList?.data) return false;

    return !!providerList.data?.filter(
      (provider) => provider.name === "heyReachApiKey",
    ).length;
  }, [providerList]);

  const {
    isLoading,
    data: heyReachCampaigns,
    refetch: refetchCampaigns,
  } = useHeyReachCampaigns(fetchCampaigns);

  const {
    isLoading: isLinkedinAccountsFetching,
    data: heyReachLinkedinAccounts,
    refetch: refetchLinkedinAccounts,
  } = useHeyReachLinkedinAccounts(fetchLinkedinAccounts);

  const handleCampaignChange = (selected: any) => {
    setSelectedCampaign(selected);
  };

  const handleLinkedinAccountChange = (selected: any) => {
    setSelectedLinkedinAccount(selected);
  };

  const handleCustomField = (
    id: string,
    name: string,
    value: Descendant[] | string,
  ): void => {
    setCustomFields((prevFields: CustomField[]) =>
      prevFields.map((field: CustomField) =>
        field.id === id ? { ...field, [name]: value } : field,
      ),
    );
  };

  const handleRemoveCustomField = (name: string): void => {
    const newFields = customFields?.filter(
      (field: CustomField) => field.name !== name,
    );
    setCustomFields(newFields);
  };

  const handleAddCustomField = (): void => {
    const newField: CustomField = {
      id: `custom_field_name_${customFields.length + 1}`,
      name: `Custom_Field_Name_${customFields.length + 1}`,
      value: initialSlateValue,
    };

    setCustomFields((prevFields: CustomField[]) => [...prevFields, newField]);
  };

  const isDisabled = useMemo(() => {
    if (
      customFields?.length &&
      customFields.some(
        (field: CustomField) => !isValidAlphanumericOrUnderscore(field.name),
      )
    ) {
      return true;
    }

    return (
      !selectedCampaign ||
      !selectedLinkedinAccount ||
      !convertSlateToText(leadDetails.firstName).text ||
      !convertSlateToText(leadDetails.lastName).text ||
      !convertSlateToText(leadDetails.profileUrl).text
    );
  }, [
    selectedCampaign,
    selectedLinkedinAccount,
    leadDetails.firstName,
    leadDetails.lastName,
    leadDetails.profileUrl,
    customFields,
  ]);

  useEffect(() => {
    if (isUserApiKeyExists) {
      if (heyReachCampaigns?.success) {
        const formattedData = heyReachCampaigns?.data?.items?.map(
          (item: any) => ({
            value: item.id,
            label: item.name,
          }),
        );

        setCampaigns(formattedData);
      } else if (heyReachCampaigns && !heyReachCampaigns?.success) {
        setCampaigns([]);
        toast({
          position: "top-right",
          title: "Error",
          description:
            heyReachCampaigns?.error.message || "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [heyReachCampaigns, isUserApiKeyExists]);

  useEffect(() => {
    if (isUserApiKeyExists) {
      if (heyReachLinkedinAccounts?.success) {
        const formattedData = heyReachLinkedinAccounts?.data?.items?.map(
          (item: any) => ({
            value: item.id,
            label: `${item.firstName || ""} ${item.lastName || ""}`,
          }),
        );

        setLinkedinAccounts(formattedData);
      } else if (
        heyReachLinkedinAccounts &&
        !heyReachLinkedinAccounts?.success
      ) {
        setLinkedinAccounts([]);
        toast({
          position: "top-right",
          title: "Error",
          description:
            heyReachLinkedinAccounts?.error.message || "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [heyReachLinkedinAccounts, isUserApiKeyExists]);

  useEffect(() => {
    if (isUserApiKeyExists) {
      if (!fetchCampaigns) {
        setFetchCampaigns(true);
      } else {
        refetchCampaigns();
        setSelectedCampaign(null);
      }

      if (!fetchLinkedinAccounts) {
        setFetchLinkedinAccounts(true);
      } else {
        refetchLinkedinAccounts();
        setSelectedLinkedinAccount(null);
      }
    }
  }, [isUserApiKeyExists, providerList]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType | undefined;

      if (enrichmentData) {
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        const formattedCustomFields = transformCustomFieldsObject(
          enrichmentData?.customFields || {},
        );
        setCustomFields(formattedCustomFields);

        const slateValueDetails = enrichmentData.leadDetailsBySlateValue;

        for (const [key, value] of Object.entries(slateValueDetails)) {
          updateDetails(key, value);
        }

        if (campaigns?.length > 0) {
          const selectedCampaign = campaigns.find(
            (item) => item.value === enrichmentData.campaignId,
          );
          selectedCampaign && setSelectedCampaign(selectedCampaign);
        }
        if (linkedinAccounts?.length > 0) {
          const selectedLinkedinAccount = linkedinAccounts.find(
            (item) => item.value === enrichmentData.linkedinAccount,
          );
          selectedLinkedinAccount &&
            setSelectedLinkedinAccount(selectedLinkedinAccount);
        }
      }
    }
  }, [viewMode, selectedColumnToEdit, campaigns]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Add lead to a campaign in HeyReach."
          link={""}
        />
        <KeySelect
          providerName={"heyReachApiKey"}
          providerTitle="HeyReach"
          disablePersanaKey={true}
        />

        <div className="mt-5">
          <div className="mb-1 flex items-center gap-1">
            <p className="mb-1 text-base font-semibold">Campaign ID</p>
            <Button
              leftIcon={<Icons.RefreshIcon />}
              size={"xs"}
              onClick={() => refetchCampaigns()}
              isLoading={isLoading}
              variant={"outline"}
              className="mx-2"
            >
              Refresh
            </Button>
            {isLoading && <Spinner size="sm" />}
          </div>

          <MultiSelectDropdown
            isMulti={false}
            placeholder="choose a campaign"
            closeMenuOnSelect
            options={campaigns || []}
            value={selectedCampaign}
            onChange={handleCampaignChange}
          />
          <div className="flex items-center justify-between">
            <p className="block py-1 text-sm">
              The Campaign you want to add the lead to
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="mb-1 flex items-center gap-1">
            <p className="mb-1 text-base font-semibold">Linkedin Account</p>
            <Button
              leftIcon={<Icons.RefreshIcon />}
              size={"xs"}
              onClick={() => refetchLinkedinAccounts()}
              isLoading={isLinkedinAccountsFetching}
              variant={"outline"}
              className="mx-2"
            >
              Refresh
            </Button>
            {isLinkedinAccountsFetching && <Spinner size="sm" />}
          </div>

          <MultiSelectDropdown
            isMulti={false}
            placeholder="choose a linkedin account"
            closeMenuOnSelect
            options={linkedinAccounts || []}
            value={selectedLinkedinAccount}
            onChange={handleLinkedinAccountChange}
          />
          <div className="flex items-center justify-between">
            <p className="block py-1 text-sm">
              The LinkedIn account you want to use for the campaign
            </p>
          </div>
        </div>

        <PersanaAccordion
          defaultIndex={[0, 1]}
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
            marginTop: "20px",
          }}
          items={[
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Lead Details
                </button>
              ),

              panel: (
                <div className="space-y-3">
                  {leadOptions.map((item) => (
                    <div key={item.name}>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {!item.isOptional && (
                          <sup className="text-red-700">*</sup>
                        )}
                        {item?.name}{" "}
                        {item.isOptional && (
                          <span className="font-title text-xs font-bold text-darkTextGray">
                            (Optional)
                          </span>
                        )}
                      </p>
                      <CustomTextEditor
                        editorHeight={"4rem"}
                        slateValue={item?.state}
                        setSlateValue={item?.setState}
                        placeholder={`Start typing or use the dropdown to select a column.`}
                      />
                      <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                        {item.description}
                      </p>
                    </div>
                  ))}
                </div>
              ),
            },
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Custom Fields (Optional)
                </button>
              ),

              panel: (
                <>
                  {customFields?.map((field: any, index: number) => (
                    <div
                      className="mt-3 flex items-start justify-start gap-[5px]"
                      key={`other-fields-${index}`}
                    >
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">name</p>
                        <Input
                          maxW={"200px"}
                          value={field?.name || ""}
                          placeholder={"Enter name..."}
                          name={"name"}
                          onChange={(e) =>
                            handleCustomField(
                              field.id,
                              e.target.name,
                              e.target.value,
                            )
                          }
                          isInvalid={
                            !isValidAlphanumericOrUnderscore(field.name)
                          }
                        />
                        <span className="text-xs leading-[1px]">
                          The name field must contain only{" "}
                          <code>alpha-numeric</code> characters or underscores{" "}
                          <code>_</code>
                        </span>
                      </div>
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">value</p>
                        <CustomTextEditor
                          editorHeight={"1rem"}
                          slateValue={field.value}
                          setSlateValue={(val) =>
                            handleCustomField(field.id, "value", val)
                          }
                          showDetails={false}
                        />
                      </div>
                      <Button
                        className="mb-1 mt-7 self-start"
                        onClick={() => {
                          handleRemoveCustomField(field.name);
                        }}
                        size={"sm"}
                      >
                        X
                      </Button>
                    </div>
                  ))}

                  {customFields?.length < 20 ? (
                    <div className="mt-5">
                      <Button size={"sm"} onClick={handleAddCustomField}>
                        + Add New Custom Field
                      </Button>
                      <span className={"block py-1 text-sm text-[#676d7e]"}>
                        Any fields you want to add to the lead. Put the name of
                        the field on the left and the value on the right.
                      </span>
                    </div>
                  ) : null}
                </>
              ),
            },
          ]}
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={isDisabled}
        apiPayload={{
          apiKeyName: "heyReachApiKey",
          campaignId: selectedCampaign?.value,
          linkedinAccount: selectedLinkedinAccount?.value,
          leadDetailsBySlateValue: {
            ...leadDetails,
          },
          customFields:
            customFields?.length > 0 ? arrayToObject(customFields) : {},
        }}
      />
    </>
  );
};

export default LeadToHeyReach;
