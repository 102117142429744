import Icons from "@/components/Icons";
import { useProviderList } from "@/hooks/useProviderData";
import { useEnrichStore } from "@/stores/enrich.store";
import { ProviderNameType, useProviderStore } from "@/stores/provider.store";
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { TProviderOption } from "../types";

interface IConfigMenuProps extends IconButtonProps {
  provider: TProviderOption;
}
export const ConfigMenu = ({ provider, ...props }: IConfigMenuProps) => {
  const { data: providerLists } = useProviderList();
  const updateProviderState = useProviderStore((state) => state.updateState);
  const isUsingUserKey = useEnrichStore((state) =>
    state.ownKeyProviderList?.includes(
      provider?.apikeyParams.keyName as ProviderNameType,
    ),
  );

  const hasApiKey = !!providerLists?.data?.find(
    (providerItem) => provider?.apikeyParams.keyName === providerItem?.name,
  )?.metaData?.accessToken;
  return (
    <Menu strategy="fixed" placement="left" offset={[0, -15]}>
      <MenuButton
        className="relative !bg-transparent hover:scale-[1.10] hover:!bg-transparent"
        as={IconButton}
        icon={
          <>
            <Icons.SettingsIcon />
            {isUsingUserKey && !hasApiKey && providerLists?.data && (
              <div className="absolute left-[20%] top-[20%] box-content !size-[5px] rounded-full border-[1px] border-[solid] border-[white] bg-[red]" />
            )}
          </>
        }
        {...props}
      />
      <MenuList boxShadow={"2xl"}>
        <MenuItem
          className={"relative"}
          py={0}
          icon={
            hasApiKey ? (
              <Icons.EditIcon fontSize={"1.2rem"} />
            ) : (
              <Icons.PlusIcon fontSize={"1.2rem"} />
            )
          }
          onClick={() => {
            updateProviderState({
              isProviderApiKeyModalOpen: true,
              providerApiKeyModalData: {
                title: provider?.label as string,
                providerName: provider?.apikeyParams
                  .keyName as ProviderNameType,
                ...(provider?.apikeyParams.extraKeyField && {
                  extraKeyField: provider?.apikeyParams.extraKeyField,
                }),
              },
            });
          }}
        >
          {hasApiKey ? "Edit API Key" : "Add API Key"}
          {isUsingUserKey && !hasApiKey && (
            <div className="absolute left-[5%] top-[20%] box-content !size-[5px] rounded-full border-[1px] border-[solid] border-[white] bg-[red]" />
          )}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
