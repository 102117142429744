export const RESPONSE_COLUMNS = [
  {
    isChecked: false,
    label: "Headquarters",
    id: "companyHeadquarters",
  },
  {
    isChecked: false,
    label: "Headcount",
    id: "companyHeadcount",
  },
  {
    isChecked: false,
    label: "Followers",
    id: "companyFollowers",
  },
  {
    isChecked: false,
    label: "Company Website",
    id: "companyWebsite",
  },
  {
    isChecked: false,
    label: "Company LinkedIn URL",
    id: "companyLinkedinUrl",
  },
  {
    isChecked: false,
    label: "Founded Year",
    id: "companyFoundedYear",
  },
  {
    isChecked: false,
    label: "Company Address",
    id: "companyAddress",
  },
];
