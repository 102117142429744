import { replaceToBr } from "@/utils";

type Props = {
  infos: string[];
  isForCompany?: boolean;
};

const ResultScreenInfo = ({ infos, isForCompany = false }: Props) => {
  return (
    <div className="flex size-full items-center justify-center">
      <div className="flex flex-col items-center rounded-md bg-light px-5 py-3  font-title tracking-wide">
        <h3 className="pb-1.5 text-lg font-semibold text-black">
          {isForCompany ? "Search Companies" : "Search People"}
        </h3>
        <div className="space-y-3">
          {infos.map((item, index) => {
            const brAdded = replaceToBr(item);
            return (
              <p
                key={item}
                className="flex items-start gap-x-2 text-[#000000ef]"
              >
                <span className="block w-4">{index + 1}.</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: brAdded,
                  }}
                ></span>
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ResultScreenInfo;
