import APIKeyIntegration from "./APIKeyIntegration";

const HeyReachIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"heyReachApiKey"}
      providerTitle="HeyReach"
      providerIcon="https://persana-assets.s3.us-west-1.amazonaws.com/HeyReach-logo.png"
    />
  );
};

export default HeyReachIntegration;
