export const defaultPromptText = `Write a short, friendly, and personalized email for this prospect, keeping it warm, conversational, and genuine.
Guidelines:
Length: Under 70 words, divided into 3 brief paragraphs.
No Subject Line: Only write the email body.
Structure:
Greeting: Begin with “Hi [First Name],” on its own line.
Paragraph 1: Start with a specific, friendly compliment about the prospect’s recent achievement or company success. Keep it natural and conversational, avoiding overly enthusiastic or “salesy” language and any overly formal terms like “LLC.”
Paragraph 2: Briefly explain how our company’s services could support their goals or improve processes. Smoothly link this to their work or industry focus so it feels relevant and helpful.
Paragraph 3: If a specific call to action is provided, use that. Otherwise, end with, “Would you be open to a quick chat to explore this further?”
Sign-Off: Conclude with “Best,” followed by the sender’s name.
Additional Notes:
Keep It Conversational: The email should sound natural, like a real conversation.
Avoid Formal or Complex Language: Use clear, simple words that feel like everyday speech.
`;
