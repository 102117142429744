import { Button, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useRef, useState } from "react";

import Icons from "@/components/Icons";

import leadService from "@/services/leads.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { getParsedCsvData } from "@/utils";
import ColumnSelection from "./ColumnSelection";
import {
  FileColumnToTableColumnMapping,
  HandleColumnMappingParams,
} from "./types";

interface Props {
  onClose?: () => void;
  isImport?: boolean;
}

const CSVImport = ({ onClose, isImport }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const toast = useToast({
    position: "top-right",
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      tableId,
      payload,
    }: {
      tableId: string;
      payload: FormData;
    }) => leadService.uploadCSV(tableId, payload),
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dragging, setDragging] = useState(false);
  const [fileColumns, setFileColumns] = useState<string[]>([]);
  const [fileColumnToTableColumnMapping, setFileColumnToTableColumnMapping] =
    useState<FileColumnToTableColumnMapping | null>(null);

  const tableId = useTableStore((state) => state.tableData._id);
  const isProcessing = useTableStore(
    (state) => state.tableData.metaData?.isProcessing,
  );

  const updateState = useEnrichStore((state) => state.updateState);

  const clearState = () => {
    setFileColumns([]);
    setFileColumnToTableColumnMapping(null);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (
      file?.type !== "text/csv" &&
      file?.type !== "application/vnd.ms-excel"
    ) {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    clearState();
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (isProcessing && isImport) {
      toast({
        position: "top-right",
        title:
          "Already one process is running. Please wait until it completes.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!fileColumns?.length && tableId) {
      const columnNames = (await getParsedCsvData(selectedFile)) as any;

      setFileColumns(columnNames?.columns);
      setFileColumnToTableColumnMapping(
        columnNames?.columns?.reduce((acc: any, column: string) => {
          acc[column] = {
            tableColumnName: {
              label: column,
              value: column,
            },
            isChecked: false,
          };
          return acc;
        }, {}),
      );

      return;
    }

    const selectedColumnsMaps = Object.keys(
      fileColumnToTableColumnMapping || {},
    ).reduce((acc: any, key) => {
      if (fileColumnToTableColumnMapping?.[key].isChecked) {
        acc[key] = fileColumnToTableColumnMapping?.[key].tableColumnName.value;
      }
      return acc;
    }, {});

    const formData = new FormData();
    formData.append("csvFile", selectedFile!);
    formData.append("fileName", selectedFile?.name || "");
    if (tableId) {
      formData.append(
        "selectedColumnsMaps",
        JSON.stringify(selectedColumnsMaps),
      );
    }
    await mutateAsync(
      {
        tableId: tableId || "new",
        payload: formData,
      },
      {
        onSuccess(response) {
          if (response?.success) {
            toast({
              title: "Success",
              description: response.message,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            if (tableId) {
              queryClient.refetchQueries({
                queryKey: ["table-running-processes", tableId],
              });
            }
            if (!tableId && response.data?.tableId) {
              navigate({
                to: "/table/$tableId",
                params: {
                  tableId: response.data.tableId,
                },
              });
            }
            handleClose();
            updateState({
              isOpenEnrichModal: false,
              selectedEnrichments: null,
            });
          } else {
            toast({
              title: "Error",
              description: response?.message || "Something went wrong",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        },
        onError(error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleColumnMapping = ({
    columnName,
    isChecked,
    tableColumnName,
  }: HandleColumnMappingParams) => {
    setFileColumnToTableColumnMapping((prev: any) => {
      return {
        ...prev,
        [columnName]: {
          isChecked,
          tableColumnName: tableColumnName || null,
        },
      };
    });
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileColumnToTableColumnMapping((prev: any) => {
      return Object.keys(prev).reduce((acc: any, key) => {
        acc[key] = {
          ...prev[key],
          isChecked: e.target.checked,
        };
        return acc;
      }, {});
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const files = [...e.dataTransfer.files];
    if (files.length > 1) {
      toast({
        title: "Error",
        description: "Please upload only one file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const file = e.dataTransfer.files?.[0] || null;

    if (file?.type !== "text/csv") {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    clearState();
    setSelectedFile(file);
  };

  const isDisabled =
    !selectedFile ||
    (fileColumns.length > 0 &&
      Object.keys(fileColumnToTableColumnMapping || {}).every(
        (key) =>
          !fileColumnToTableColumnMapping?.[key].tableColumnName ||
          !fileColumnToTableColumnMapping?.[key].isChecked,
      ));

  return (
    <div>
      <p className="mb-2">Upload a CSV for your leads.</p>
      <div className="mb-4">
        <input
          type="file"
          accept=".csv"
          ref={inputRef}
          className="hidden"
          onChange={onFileChange}
        />
        <div
          onClick={() => {
            inputRef?.current?.click();
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDropFile}
          className={`${dragging ? "border-primaryPurple" : "border-gray-200"} dark:!border-navy-700
          flex size-full cursor-pointer flex-col
          items-center justify-center rounded-xl
          border-[2px] border-dashed py-3 lg:pb-0`}
        >
          {selectedFile?.name ? (
            <p className="pb-3 text-textPurple">{selectedFile.name}</p>
          ) : (
            <>
              <Icons.FileUploadIcon className="text-[65px] text-textPurple" />
              <h4 className="pb-2 font-bold text-textPurple">
                Upload or Drag & Drop CSV
              </h4>
            </>
          )}
        </div>
      </div>
      {fileColumns?.length > 0 && tableId && fileColumnToTableColumnMapping && (
        <ColumnSelection
          fileColumns={fileColumns}
          fileColumnToTableColumnMapping={fileColumnToTableColumnMapping}
          handleColumnMapping={handleColumnMapping}
          handleSelectAll={handleSelectAll}
        />
      )}
      <div className="flex items-center justify-end gap-4">
        <Button
          className="w-[100px]"
          onClick={handleClose}
          isLoading={isPending}
        >
          Cancel
        </Button>

        <Button
          className={`w-[100px] !bg-primary-gradient !text-white ${isDisabled ? "cursor-not-allowed" : ""}`}
          disabled={isDisabled}
          onClick={handleUpload}
          isLoading={isPending}
          isDisabled={isDisabled}
        >
          {fileColumns?.length > 0 ? "Submit" : "Upload"}
        </Button>
      </div>
    </div>
  );
};

export default CSVImport;
