import { RuleGroupTypeIC } from "react-querybuilder";
import { create } from "zustand";
import { getQueryColumnUsed } from "../utils";
export type ICriteriaStore = {
  criterias: Map<string, { score: number; query: RuleGroupTypeIC }>;
  previewRowScoreModal: {
    isOpen: boolean;
  };
  updateState: (data: Partial<ICriteriaStore>) => void;
  getColumnsUsed: () => Map<string, Set<string>>;
  reset: () => void;
};
export const useLeadScoreStore = create<ICriteriaStore>((set, get) => ({
  criterias: new Map(),
  previewRowScoreModal: {
    isOpen: false,
  },
  updateState: (data) => set((state) => ({ ...state, ...data })),
  getColumnsUsed: () => {
    const columns = new Map<string, Set<string>>([["all", new Set()]]);
    get().criterias.forEach(({ query }, key) => {
      const columnsUsed = new Set(getQueryColumnUsed(query));
      columns.set(key, columnsUsed);
      columns.set("all", new Set([...columns.get("all")!, ...columnsUsed]));
    });
    return columns;
  },
  reset: () =>
    set({ criterias: new Map(), previewRowScoreModal: { isOpen: false } }),
}));
