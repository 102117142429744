import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import Swal from "sweetalert2";

import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import AnimatedLoader from "@/components/Icons/SvgIcons/AnimatedLoaders";
import leadService from "@/services/leads.service";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { filterValidObjKeys } from "@/utils";
import AccordionButton from "../../Common/AccordionButton";
import PersanaInput from "../../Common/PersanaInput";
import RadioButton from "../../Common/RadioButton";
import SearchInputAccordion from "../../Common/SearchInputAccordion";
import ActionButtons from "../Common/ActionButtons";

import { IScraperCompanySearchPayload } from "@/types/leads.types";
import Icons from "../../Icons";
import {
  iScraperCompanyTypes,
  iScraperIndustriesList,
  staticCountryWithCodes,
} from "./constants";

type Props = {
  isSearching: boolean;
  setIsSearching: (val: boolean) => void;
  setCurrentSearchData: any;
  onCloseModal?: () => void;
};

const SearchLinkedInCompanyInputsIscraper = ({
  isSearching,
  onCloseModal,
  setIsSearching,
  setCurrentSearchData,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState("search");
  const [isGettingSavedTemplates] = useState(false);
  const [inputtedValues, setInputtedValues] = useState({
    company_type: [],
    minimun_company_size: "",
    maximum_company_size: "",
    year_founded: "",
    isGreaterYearFounded: false,
    isSmallerYearFounded: false,
    website: "",
    followers: "",
    isMoreFollowers: false,
    isLessFollowers: false,
    keyword: "",
    country_codes: [],
    industries: [],
  });

  const toast = useToast();
  const { mutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: IScraperCompanySearchPayload }) =>
      leadService.searchCompanyIscraper(payload),
  });
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);

  const handleToggleSelect = (
    e: any,
    stateToUpdate: any,
    setStateToUpdate: any,
    rivalSelect: any,
  ) => {
    setStateToUpdate({
      ...stateToUpdate,
      [e.target.name]: !stateToUpdate[e.target.name],
      [rivalSelect]: false,
    });
  };

  const handleInputChange = (
    e: any,
    stateToUpdate: any,
    setStateToUpdate: any,
  ) => {
    setStateToUpdate({
      ...stateToUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handlePushToArray = (
    value: any,
    stateToUpdate: any,
    setStateToUpdate: any,
  ) => {
    if (value?.name === "company_type" && value?.value?.length > 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can only select one company type",
      });
      return;
    }
    setStateToUpdate({
      ...stateToUpdate,
      [value?.name]: value?.value,
    });
  };

  function compareValues(more: boolean, less: boolean, value: string) {
    if (!more && !less) {
      return `eq:${value}`;
    } else if (more) {
      return `gt:${value}`;
    } else if (less) {
      return `lt:${value}`;
    }
  }

  const handleSearch = async (searchType: string) => {
    console.log("searchType", searchType);
    console.log("inputtedValues", inputtedValues);

    const convertedInputtedData = {
      per_page: 20,
      company_type: inputtedValues?.company_type
        ?.map((item: any) => item?.label)
        ?.join(""),
      company_size:
        inputtedValues?.minimun_company_size &&
        inputtedValues?.maximum_company_size
          ? `${
              inputtedValues?.minimun_company_size === ""
                ? "10"
                : inputtedValues?.minimun_company_size
            }-${
              inputtedValues?.maximum_company_size === ""
                ? "100"
                : inputtedValues?.maximum_company_size
            }`
          : "",
      year_founded: inputtedValues?.year_founded
        ? compareValues(
            inputtedValues?.isGreaterYearFounded,
            inputtedValues?.isSmallerYearFounded,
            inputtedValues?.year_founded,
          )
        : "",
      website: inputtedValues?.website,
      followers: inputtedValues?.followers
        ? compareValues(
            inputtedValues?.isMoreFollowers,
            inputtedValues?.isLessFollowers,
            inputtedValues?.followers,
          )
        : "",
      keyword: inputtedValues?.keyword,
      country_codes: inputtedValues?.country_codes?.map(
        (item: any) => item?.value,
      ),
      industries: inputtedValues?.industries?.map((item: any) => item?.label),
    };

    const validInputtedData = filterValidObjKeys(convertedInputtedData);

    if (Object.keys(validInputtedData || {}).length === 0) {
      toast({
        title: "No data to search",
        description: "Please input some data to search",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
      return;
    }

    setIsSearching(true);
    await mutateAsync(
      {
        payload: validInputtedData,
      },
      {
        onSuccess: (response) => {
          setCurrentSearchData(response?.data?.payload || {});
          updateLeadState({
            searchLeadsResult: {
              profiles: response?.data.profiles || [],
              pagination: response?.data.pagination || {
                total_entries: 0,
                total_pages: 0,
                page: 1,
                per_page: 100,
              },
              apiPath: `leads/search-company-linkedin?per_page=${response?.data?.providerName === "iScraper" ? 20 : 25}&want_to_import=true&providerName=${response?.data?.providerName}`,
              columsMapping: response?.data?.columsMapping || [],
            },
          });
          setIsSearching(false);
          console.log(response, "response");
        },
        onError: (error) => {
          setIsSearching(false);
          console.log(error, "error");
        },
      },
    );
  };

  return (
    <div className="flex size-full flex-col justify-between bg-[#F8F0FF]">
      <div className="h-[5rem] grow  space-y-4 overflow-y-auto p-4">
        <p className="flex items-center space-x-2.5 p-4 text-[20px] font-semibold text-[#000]">
          <button onClick={onCloseModal}>
            <Icons.LeftCircleArrow className="text-3xl text-[#693DC7]" />
          </button>{" "}
          <span>
            Find <span className="text-[#693DC7]">Companies</span>
          </span>
        </p>
        {selectedTab === "search" ? (
          <>
            <SearchInputAccordion
              defaultIndex={0}
              button={
                <AccordionButton
                  icon={
                    <Icons.FileSearchIcon className="text-xl" color="#693DC7" />
                  }
                >
                  Keywords
                </AccordionButton>
              }
            >
              <div className="space-y-3">
                <PersanaInput
                  value={inputtedValues?.keyword}
                  placeholder={""}
                  name={"keyword"}
                  label={"Keywords"}
                  onChange={(e) => {
                    handleInputChange(e, inputtedValues, setInputtedValues);
                  }}
                  description="If a keyword is provided, we will return results that match the given keyword. The match can be partial or complete. This allows you to effectively search and filter through the results based on specific search terms or phrases."
                />
              </div>
            </SearchInputAccordion>
            <SearchInputAccordion
              button={
                <AccordionButton
                  icon={<Icons.FaceIcon className="text-xl" color="#693DC7" />}
                >
                  Companies{" "}
                </AccordionButton>
              }
            >
              <div className="space-y-3">
                <PersanaInput
                  value={inputtedValues?.website}
                  placeholder={"e.g. example.com"}
                  name={"website"}
                  label={"Company Website"}
                  type="text"
                  onChange={(e) => {
                    handleInputChange(e, inputtedValues, setInputtedValues);
                  }}
                />
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Company Type
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Privately Held"
                  // @ts-ignore
                  options={iScraperCompanyTypes}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "company_type", value: [selectedValues] },
                      inputtedValues,
                      setInputtedValues,
                    );
                  }}
                  isMulti={false}
                  closeMenuOnSelect
                  value={inputtedValues?.company_type}
                />
                <div>
                  <div className="flex items-center justify-between gap-x-3 pb-2">
                    <label className="smallTitle block">Company Size</label>
                  </div>
                  <div className="grid w-full grid-cols-[repeat(1,_43%_14%_43%)]">
                    <input
                      type={"number"}
                      value={inputtedValues?.minimun_company_size}
                      name={"minimun_company_size"}
                      className={
                        "w-full rounded border border-[#c6c6ca] px-2 py-1.5 text-sm focus:border-primary focus:outline-none focus:ring-0"
                      }
                      placeholder={"10"}
                      onChange={(e) =>
                        handleInputChange(e, inputtedValues, setInputtedValues)
                      }
                    />
                    <p className="flex size-full items-center justify-center">
                      To
                    </p>
                    <input
                      type={"number"}
                      value={inputtedValues?.maximum_company_size}
                      name={"maximum_company_size"}
                      className={
                        "w-full rounded border border-[#c6c6ca] px-2 py-1.5 text-sm focus:border-primary focus:outline-none focus:ring-0"
                      }
                      placeholder={"100"}
                      onChange={(e) =>
                        handleInputChange(e, inputtedValues, setInputtedValues)
                      }
                    />
                  </div>
                </div>
                <PersanaInput
                  value={inputtedValues?.year_founded}
                  optionComponent={
                    <div className="flex items-center gap-x-3">
                      <RadioButton
                        label={"Greater than"}
                        name={"isGreaterYearFounded"}
                        checked={inputtedValues?.isGreaterYearFounded}
                        setChecked={(e) => {
                          handleToggleSelect(
                            e,
                            inputtedValues,
                            setInputtedValues,
                            "isSmallerYearFounded",
                          );
                        }}
                      />
                      <RadioButton
                        label={"Less than"}
                        name={"isSmallerYearFounded"}
                        checked={inputtedValues?.isSmallerYearFounded}
                        setChecked={(e) => {
                          handleToggleSelect(
                            e,
                            inputtedValues,
                            setInputtedValues,
                            "isGreaterYearFounded",
                          );
                        }}
                      />
                    </div>
                  }
                  placeholder={"e.g. 1999"}
                  name={"year_founded"}
                  label={"Founded On"}
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, inputtedValues, setInputtedValues);
                  }}
                />
                <PersanaInput
                  value={inputtedValues?.followers}
                  optionComponent={
                    <div className="flex items-center gap-x-3">
                      <RadioButton
                        label={"Greater than"}
                        name={"isMoreFollowers"}
                        checked={inputtedValues?.isMoreFollowers}
                        setChecked={(e) => {
                          handleToggleSelect(
                            e,
                            inputtedValues,
                            setInputtedValues,
                            "isLessFollowers",
                          );
                        }}
                      />
                      <RadioButton
                        label={"Less than"}
                        name={"isLessFollowers"}
                        checked={inputtedValues?.isLessFollowers}
                        setChecked={(e) => {
                          handleToggleSelect(
                            e,
                            inputtedValues,
                            setInputtedValues,
                            "isMoreFollowers",
                          );
                        }}
                      />
                    </div>
                  }
                  placeholder={"e.g. 1000"}
                  name={"followers"}
                  label={"Followers"}
                  type="number"
                  onChange={(e) => {
                    handleInputChange(e, inputtedValues, setInputtedValues);
                  }}
                />
              </div>
            </SearchInputAccordion>

            <SearchInputAccordion
              button={
                <AccordionButton
                  icon={
                    <Icons.LocationOnIcon className="text-xl" color="#693DC7" />
                  }
                >
                  Locations
                </AccordionButton>
              }
            >
              <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                Country eg. USA, UK
              </p>
              <MultiSelectDropdown
                placeholder="e.g. , United States"
                // @ts-ignore
                options={staticCountryWithCodes}
                onChange={(selectedValues) => {
                  handlePushToArray(
                    { name: "country_codes", value: selectedValues },
                    inputtedValues,
                    setInputtedValues,
                  );
                }}
                value={inputtedValues?.country_codes}
              />
            </SearchInputAccordion>
            <SearchInputAccordion
              button={
                <AccordionButton
                  icon={
                    <Icons.IndustryIcon className="text-xl" color="#693DC7" />
                  }
                >
                  Industries
                </AccordionButton>
              }
            >
              <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                Industry
              </p>
              <MultiSelectDropdown
                placeholder="e.g. Computer Software , Accounting"
                // @ts-ignore
                options={iScraperIndustriesList}
                onChange={(selectedValues) => {
                  handlePushToArray(
                    { name: "industries", value: selectedValues },
                    inputtedValues,
                    setInputtedValues,
                  );
                }}
                value={inputtedValues?.industries}
              />
            </SearchInputAccordion>
          </>
        ) : (
          <div className="space-y-3">
            {isGettingSavedTemplates ? (
              <div className="flex items-center justify-center pt-[15%]">
                <AnimatedLoader className="size-12" />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <ActionButtons
        isLoading={isSearching}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        onSearch={handleSearch}
      />
    </div>
  );
};

export default SearchLinkedInCompanyInputsIscraper;
