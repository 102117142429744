import { FileRoute } from "@tanstack/react-router";

import useSocket from "@/lib/socket";
import Settings from "@/pages/Settings";
import { userStore } from "@/stores/user.store";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

export const Route = new FileRoute("/settings/").createRoute({
  component: SettingsPage,
});

function SettingsPage() {
  const { subscribe } = useSocket();
  const queryClient = useQueryClient();
  const currentUser = userStore((state) => state.currentUser);

  useEffect(() => {
    subscribe(`REFRESH_API_CALLS-${currentUser._id}`, async (data: any) => {
      console.log("SOCKET DATA 4 refresh api calls", data);
      if (data.key) {
        queryClient.refetchQueries({
          queryKey: [data.key],
        });
      }
    });
  }, [currentUser]);

  return <Settings />;
}

export default SettingsPage;
