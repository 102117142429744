import { IGetRowsParams } from "ag-grid-community";

export function buildUrlParamsInfinite(params: IGetRowsParams) {
  const urlParams: {
    limit: number;
    pageNumber: number;
    sortingKey?: string;
    sortOrder?: string;
    filter?: {
      [key: string]: string;
    };
  } = {
    limit: params.endRow - params.startRow,
    pageNumber:
      Math.floor(params.startRow / (params.endRow - params.startRow)) + 1,
  };
  if (params.sortModel.length > 0) {
    urlParams.sortingKey = params.sortModel[0].colId;
    urlParams.sortOrder = params.sortModel[0].sort === "asc" ? "ASC" : "DESC";
  }
  if (params.filterModel) {
    urlParams.filter = Object.keys(params.filterModel).reduce(
      (acc, key) => {
        if (params.filterModel[key].filter) {
          acc[key] = params.filterModel[key].filter;
        }
        return acc;
      },
      {} as {
        [key: string]: string;
      },
    );
  }
  return urlParams;
}
