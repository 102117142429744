import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";
import { filterComponentMaps } from "@/components/Enrichment/FilterModals/mappings";

const PendingQueues = () => {
  const nextProcessPayloads = useTableStore(
    (state) => state.tableData.metaData?.nextProcessPayloads,
  );

  if (!nextProcessPayloads?.length) return null;

  console.log("nextProcessPayloads", nextProcessPayloads);

  return (
    <Popover closeOnBlur placement="bottom-start">
      <PopoverTrigger>
        <Button
          className="flex !h-[35px] items-center rounded-md border !border-[#8080801c] !text-sm hover:!shadow-md"
          leftIcon={<Icons.QueueListIcon />}
        >
          Pending Queues
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          {nextProcessPayloads.map((payload, index) => (
            <div
              key={Math.random()}
              className="mb-2 bg-bgSecondary p-1 shadow-sm"
            >
              <span className="mr-3">{index + 1}.</span>
              <span>
                {(
                  filterComponentMaps as Record<
                    string,
                    { title: string } | undefined
                  >
                )[payload?.bodyData?.enrichmentName]?.title ||
                  payload?.bodyData?.enrichmentName}
              </span>
            </div>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PendingQueues;
