import { StoreApi, createStore } from "zustand";
import { createContext, useContext, useMemo } from "react";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { TProviderOption } from "./types";
import { ProviderNameType } from "@/stores/provider.store";

interface IUseWaterfallStore {
  chosenUserKeys: ProviderNameType[];
  chosenNonUserKeys: ProviderNameType[];
  initialUserKeys: ProviderNameType[] | null;
  setUserKey: (enabled: boolean, keyName: ProviderNameType) => void;
  selectedProviders: TProviderOption[];
  providerOptions: TProviderOption[];
  defaultProviders: TProviderOption[];
  setState: (state: Partial<IUseWaterfallStore>) => void;
  updateProvider: (
    providerName: string,
    newValue: Partial<TProviderOption>,
  ) => TProviderOption;
  resetState: () => void;
}

export const useWaterfallStore = <T>(
  selector: (state: IUseWaterfallStore) => T = (state) => state as T,
) => {
  const store = useContext(ProviderWaterfallContext);
  if (!store) {
    throw new Error(
      "useWaterfallStore must be used within a ProviderWaterfall",
    );
  }
  return useStoreWithEqualityFn(store, selector);
};

export const useCreateWaterfallStore = (initial: {
  defaultProviders: TProviderOption[];
  providerOptions: TProviderOption[];
  selectedProviders: TProviderOption[];
  initialUserKeys: ProviderNameType[] | null;
}) =>
  useMemo(
    () =>
      createStore<IUseWaterfallStore>((set) => ({
        chosenUserKeys: [],
        chosenNonUserKeys: [],
        initialUserKeys: initial.initialUserKeys
          ? [...initial.initialUserKeys]
          : null,
        defaultProviders: initial.defaultProviders,
        selectedProviders: initial.selectedProviders,
        providerOptions: initial.providerOptions,

        // setters
        setUserKey: (enable: boolean, keyName: ProviderNameType) =>
          set((state) => {
            const currentUserKeys = new Set(state.chosenUserKeys);
            const currentNonUserKeys = new Set(state.chosenNonUserKeys);
            if (enable) {
              currentUserKeys.add(keyName);
              currentNonUserKeys.delete(keyName);
            } else {
              currentUserKeys.delete(keyName);
              currentNonUserKeys.add(keyName);
            }
            return {
              chosenUserKeys: [...currentUserKeys],
              chosenNonUserKeys: [...currentNonUserKeys],
            };
          }),
        setState: (state) => set(state),
        updateProvider: (providerName, newValue) => {
          let option: TProviderOption | null = null;
          set((state) => ({
            selectedProviders: state.selectedProviders.map((p) => {
              if (p.value === providerName) {
                return (option = {
                  ...p,
                  ...newValue,
                });
              }
              return p;
            }),
          }));
          return option as unknown as TProviderOption;
        },
        resetState: () =>
          set({
            selectedProviders: [],
            defaultProviders: [],
          }),
      })),
    [],
  );

export const ProviderWaterfallContext =
  createContext<StoreApi<IUseWaterfallStore> | null>(null);
