import { useEffect } from "react";
import { Descendant } from "slate";

import CustomAccordion from "@/components/Common/CustomAccordion";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import { EnrichmentMetaData } from "@/types/table.types";
import { convertSlateToText } from "@/utils";
import AddMoreBtn from "../../Common/AddMoreBtn";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";
import Description from "../../Common/Description";
import NewCustomPreset from "./NewCustomPreset";
import SelectTemplate from "./SelectTemplate";
import { methods } from "./consts";

type CustomField = {
  id: string;
  name?: string;
  value?: Descendant[];
};

interface EnrichmentDataType extends EnrichmentMetaData {
  endpoint?: any;
  headers?: CustomField[];
  body?: any;
  method?: string;
}

function HttpApi() {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const {
    updateState: updatePresetState,
    endpoint,
    body,
    headers,
    selectedPreset,
    selectedMethod,
  } = usePresetsStore();
  const updateState = useEnrichStore((state) => state.updateState);

  const isSecurePreset = selectedPreset?.isSecure;

  console.log("selectedPreset", selectedPreset);

  const handleFieldChange = (
    id: string,
    name: string,
    value: Descendant[] | string,
  ) => {
    updatePresetState({
      headers: headers?.map((field: CustomField) =>
        field.id === id ? { ...field, [name]: value } : field,
      ),
    });
  };

  const setHeaders = (fields: any[]) => {
    updatePresetState({ headers: fields });
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        updatePresetState({
          body: enrichmentData.body || "",
          endpoint: enrichmentData.endpoint || "",
        });
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        enrichmentData.method &&
          updatePresetState({
            selectedMethod: {
              value: enrichmentData.method,
              label: enrichmentData.method,
            },
          });
        if (enrichmentData.headers) {
          updatePresetState({
            headers: enrichmentData.headers.map((header) => header),
          });
        }
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="grow space-y-4 overflow-y-auto p-4">
        <WatchTutorial
          subText={
            "Send or retrieve from any tool or database using a general API endpoint"
          }
          link=""
        />

        <SelectTemplate />

        {!isSecurePreset && (
          <CustomAccordion
            title="Method (Optional)"
            titleClassName="font-title text-sms"
            isDefaultOpen
          >
            <MultiSelectDropdown
              options={methods}
              isCreateAble={false}
              isMulti={false}
              closeMenuOnSelect={true}
              onChange={(selectedValue) => {
                updatePresetState({
                  selectedMethod: selectedValue as Option,
                });
              }}
              placeholder="Select an item from the dropdown..."
              value={selectedMethod}
            />
            <Description>
              Type in the HTTP method of your call, e.g. GET (default), POST,
              PATCH, PUT, or DELETE
            </Description>
          </CustomAccordion>
        )}
        <div className={isSecurePreset ? "hidden" : ""}>
          <p className="pb-1 font-title text-[0.9rem] font-semibold">
            Endpoint
          </p>
          <CustomTextEditor
            slateValue={endpoint}
            setSlateValue={(newValue) => {
              updatePresetState({
                endpoint: newValue,
              });
            }}
            isInline
            editorHeight="fit-content"
            editorClassName="!px-2 !py-[4.5px]"
            placeholder="Enter value..."
          />
          <Description>
            Enter the endpoint to send the requests to, e.g.
            https://dummy.com/products
          </Description>
        </div>
        <CustomAccordion
          title="Body (Optional)"
          titleClassName="font-title text-sm"
          isDefaultOpen
        >
          <CustomTextEditor
            slateValue={body}
            setSlateValue={(newValue) => {
              updatePresetState({
                body: newValue,
              });
            }}
            editorHeight="6rem"
            placeholder="Enter value..."
          />
          <Description>
            {
              'Enter the data body to include, e.g. {"email": "john@persana.com", "name": "John"} When using column tokens in a value, keep the key in quotes, but do not wrap the value in quotes e.g. { "email": {{email field}}, "name": {{name field}} }'
            }
          </Description>
        </CustomAccordion>

        {!isSecurePreset && (
          <CustomAccordion
            title="Header (Optional)"
            titleClassName="font-title text-sm"
          >
            <AddMoreBtn
              keyName="Key"
              handleFieldChange={handleFieldChange}
              title="Add new key value pair"
              description="Enter any header fields to set, e.g. {'API-key': 'abcd1234'}"
              moreFields={headers || []}
              setMoreFileds={setHeaders}
            />
          </CustomAccordion>
        )}
        {!isSecurePreset && <NewCustomPreset />}
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={
          isSecurePreset
            ? !convertSlateToText(body).text
            : !convertSlateToText(endpoint).text
        }
        apiPayload={{
          method: selectedMethod?.value,
          endpoint,
          body: body,
          headers: headers,
          presetId: selectedPreset?._id,
        }}
      />
    </>
  );
}

export default HttpApi;
