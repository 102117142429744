import { HStack } from "@chakra-ui/react";
import { IRadioCardGroup } from "./types";
import { RadioCard } from "./radioCard";

export function RadioCardGroup<T extends string>({
  options,
  value,
  name,
  defaultValue,
  setValue,
  ...props
}: IRadioCardGroup<T>) {
  return (
    <HStack
      {...props}
      className="inline-grid grid-cols-2 items-center gap-1 overflow-hidden rounded-lg border-none p-0.5 text-sm font-medium sm:grid-cols-4"
    >
      {options.map(({ value: val, label, icon: Icon }, index) => {
        return (
          <RadioCard
            key={value + label + index}
            isChecked={val === value}
            onChange={(e) => (e.target.checked ? setValue(val) : null)}
          >
            <Icon className="mb-1 hidden size-5 shrink-0 stroke-2 sm:inline" />
            <span>{label}</span>
          </RadioCard>
        );
      })}
      {props.children ?? null}
    </HStack>
  );
}
