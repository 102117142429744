import { useNavigate } from "@tanstack/react-router";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import AIAgentIcon from "@/assets/svgs/ai-agent-modal-icon.svg";
import { useModalStore } from "@/stores/modal.store";
import { useStarterList } from "@/hooks/useStarterList";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";

const AIAgentModal = () => {
  const toast = useToast();
  const { data } = useStarterList();
  const starterList = data?.find(
    (item) => item.name === "Persana Starter List",
  );
  const navigate = useNavigate();
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (id: string) => tableService.createAndGetStarterTable(id),
    onSuccess: (response) => {
      if (!response?.tableData) return;

      navigate({
        to: `/table/$tableId`,
        params: {
          tableId: response?.tableData._id!,
        },
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  const handleStarterList = async () => {
    starterList && (await mutateAsync(starterList?._id));
  };

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "ai-agent-modal"}
      motionPreset="scale"
      size="xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.4)" />

      <ModalContent className="rounded-[20px] p-[20px]">
        <ModalBody>
          <div className="flex flex-col gap-[12px] items-center justify-center">
            <img src={AIAgentIcon} />
            <p className="text-[20px] text-[#000000]">
              {modalData.isSuccessful
                ? "AI agent successfully built you a table!"
                : "Persana Starter List"}
            </p>
            <p className="text-[16px] text-[#7E889F] text-center">
              {modalData.isSuccessful
                ? "We found you leads based on your needs."
                : "Start with our pre-built list of leads"}
            </p>
            <Button
              className="!text-[#5024DC] text-[16px] font-medium cursor-pointer"
              variant="unstyled"
              isLoading={isPending}
              onClick={() => {
                modalData.isSuccessful
                  ? navigate({ to: "/search" })
                  : handleStarterList();
                handleModalClose();
              }}
            >
              {isPending ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Spinner size="sm" />
                </div>
              ) : modalData.isSuccessful ? (
                "View your personalized table"
              ) : (
                "View starter table"
              )}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AIAgentModal;
