import Icons from "@/components/Icons";
import { Box, Button, MenuButtonProps, Tooltip } from "@chakra-ui/react";
import { useCreateEmptyFolder } from "../hooks/useTableData";
import { useModalStore } from "@/stores/modal.store";
import { TFolderRow } from "../types";
interface CreateNewProps extends MenuButtonProps {
  newItemRoot?: TFolderRow | null;
}
export const CreateNew = ({ newItemRoot = null }: CreateNewProps) => {
  const { create } = useCreateEmptyFolder();
  const { updateState: updateModalStore } = useModalStore();
  return (
    <Box className={`relative mb-1 flex w-full justify-end items-center gap-2`}>
      <Button
        leftIcon={<Icons.PlusIcon />}
        variant={"outline"}
        size={"sm"}
        onClick={() =>
          updateModalStore({
            isOpenModal: true,
            modalData: { modalType: "get-started" },
          })
        }
      >
        Create Lead List
      </Button>
      <Tooltip label="Create Folder">
        <Button
          variant={"outline"}
          size={"sm"}
          onClick={() => create(newItemRoot)}
        >
          <Icons.FolderClosedIcon />
        </Button>
      </Tooltip>
    </Box>
  );
};
