import { useEffect, useState } from "react";
import { Button, Text, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import useTypedText from "../AIAssistant/useTypedText";

import leadService from "@/services/leads.service";
import Icons from "../Icons";
import { placeholders } from "./constants";
import { userStore } from "@/stores/user.store";

const TargetAudience = ({ callback }: { callback: (payload: any) => void }) => {
  const toast = useToast();
  const currentUser = userStore((state) => state.currentUser);
  const companyTarget = currentUser?.profileInfo?.companyTarget;
  const [inputValue, setInputValue] = useState("");

  const dynamicPlaceholder = useTypedText(placeholders);

  const { mutateAsync: searchPeopleUsingGPTMutateAsync, isPending } =
    useMutation({
      mutationFn: (input: string) =>
        leadService.searchPeopleUsingGPT({ prompt: input }),
      onSuccess: (response) => {
        if (response.type === "AGENT") {
          callback(undefined);
          return;
        }

        if (response.type === "PEOPLE_SEARCH") {
          if (response.data?.peopleSearchPayload) {
            (window.peopleSearchPayload = response.data?.peopleSearchPayload),
              callback(response.data?.peopleSearchPayload);
          }
          return;
        }
        callback(undefined);
      },
      onError: (error) => {
        callback(undefined);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      inputValue && searchPeopleUsingGPTMutateAsync(inputValue);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    companyTarget && setInputValue(companyTarget);
  }, [companyTarget]);

  return (
    <>
      <div className="flex items-center justify-center flex-col gap-2">
        <Text className="text-[18px] leading-[34px] font-[600] text-[#0F0D1C]">
          Search for your ideal customer and build your first list
        </Text>
      </div>

      <div className="w-[60%]">
        <div className="flex flex-col gap-[16px]">
          <textarea
            onKeyDown={handleKeyDown}
            value={inputValue}
            onChange={handleChange}
            placeholder={dynamicPlaceholder}
            rows={2}
            className="!h-[62px] !w-full !resize-none !rounded-md !p-2 shadow-none"
            style={{
              border: "1px solid #d8d2ec",
            }}
          />
        </div>
      </div>

      <Text className="text-[14px] text-[#4A5264]">
        Or select from Persana’s recommendations
      </Text>

      <div className="flex flex-wrap items-center justify-center w-full gap-3">
        {placeholders.slice(0, 5).map((item, ind) => (
          <div
            onClick={() => {
              setInputValue(item);
            }}
            key={ind}
            className="flex items-center justify-between p-[12px] border border-[#BD90F4] bg-[#F5F3FF] rounded-[8px] cursor-pointer"
          >
            <p className="text-[14px] text-[#5024DC]">{item}</p>
          </div>
        ))}
      </div>
      <Button
        className="!bg-[#C03EDC] !text-white !text-[16px]"
        rightIcon={<Icons.RightArrowIcon />}
        isLoading={isPending}
        onClick={() => searchPeopleUsingGPTMutateAsync(inputValue)}
        isDisabled={!inputValue}
      >
        Continue
      </Button>
    </>
  );
};

export default TargetAudience;
