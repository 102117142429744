import { Button } from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import Icons from "@/components/Icons";

const Toolbar = ({ tableId }: { tableId: string }) => {
  return (
    <Link to="/table/$tableId" params={{ tableId }} target="_blank">
      <Button
        size={"sm"}
        variant={"outline"}
        leftIcon={<Icons.ViewTableIcon />}
      >
        View Table
      </Button>
    </Link>
  );
};

export default Toolbar;
