import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";

import ResultScreenLoader from "../Common/ResultScreenLoader";
import NoResult from "../Common/NoResult";
import { UserDetailsRenderer } from "./Renderers";
import BeginSearch from "../Common/BeginSearch";

import { useSearchLeadsStore } from "@/stores/searchleads.store";

type PeopleSearchResultTableProps = {
  isSearching: boolean;
};

const PeopleSearchResultTable = ({
  isSearching,
}: PeopleSearchResultTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const [formattedProfilesData, setFormattedProfilesData] = useState<any[]>([]);
  const { searchLeadsResult, updateState } = useSearchLeadsStore();
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      cellDataType: false,
    };
  }, []);

  const colDefs: ColDef[] = [
    {
      headerName: "",
      checkboxSelection: true,
      width: 80,
      pinned: "left",
      headerCheckboxSelection: true,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      cellRenderer: UserDetailsRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "linkedInUrl",
      headerName: "LinkedIn URL",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "title",
      headerName: "Title",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "companyName",
      headerName: "Company Name",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "companyWebsite",
      headerName: "Company Website",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "location",
      headerName: "Location",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "companyLinkedInURL",
      headerName: "Company LinkedIn URL",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
  ];

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    updateState({ selectedLeads: selectedRows });
  }, []);

  const formatData = (profiles: any) => {
    const formattedData = profiles?.map((item: any) => ({
      name: item?.name?.full || "",
      linkedInUrl: item?.linkedin?.url || "",
      title: item?.linkedin?.title || "",
      companyName: item?.company?.name || "",
      companyWebsite: item?.company?.linkedin?.website || "",
      location: `${item?.linkedin?.location?.text || ""}` || "",
      companyLinkedInURL: item?.company?.linkedin?.url || "",
      employmentHistory:
        item?.linkedin?.positions?.length > 0
          ? `Worked With ${item?.linkedin?.positions?.length} Companies`
          : "",
      profilePhoto: item?.linkedin?.profile_pic || "",
      headline: item?.linkedin?.headline || "",
    }));
    setFormattedProfilesData(formattedData);
  };

  useEffect(() => {
    if (searchLeadsResult && searchLeadsResult?.profiles?.length > 0) {
      formatData(searchLeadsResult?.profiles);
    }
  }, [searchLeadsResult]);

  if (isSearching) {
    return <ResultScreenLoader />;
  }

  if (!searchLeadsResult) {
    return <BeginSearch />;
  }

  if (searchLeadsResult?.profiles?.length === 0) {
    return <NoResult />;
  }

  return (
    <div className="flex size-full flex-col justify-between">
      <div
        className="ag-theme-quartz mt-5"
        style={{ width: "100%", height: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={colDefs}
          rowData={formattedProfilesData}
          defaultColDef={defaultColDef}
          headerHeight={40}
          rowHeight={70}
          rowClass="border-1 border-gray-200"
          rowSelection="multiple"
          rowMultiSelectWithClick
          suppressColumnMoveAnimation
          suppressRowClickSelection
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    </div>
  );
};

export default PeopleSearchResultTable;
