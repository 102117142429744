import { ReactNode } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
} from "@chakra-ui/react";

type SearchInputAccordionProps = {
  button: ReactNode;
  children: ReactNode;
  btnStyle?: React.CSSProperties;
  defaultIndex?: number;
  props?: AccordionProps;
};
const SearchInputAccordion = ({
  button,
  children,
  btnStyle = {},
  defaultIndex = 1,
  props,
}: SearchInputAccordionProps) => {
  return (
    <Accordion
      defaultIndex={[defaultIndex]}
      allowMultiple
      sx={{
        transition: "all 0.3s ease",
        overflow: "visible",
        border: "1px solid #D0D4DC",
        borderRadius: "4px",
      }}
      {...props}
    >
      <AccordionItem
        rounded={"md"}
        sx={{
          transition: "all 0.3s ease",
          border: "none",
        }}
      >
        <AccordionButton
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            ...btnStyle,
          }}
        >
          {button}
          <AccordionIcon color={"#693DC7"} />
        </AccordionButton>
        <AccordionPanel pb={4} fontFamily={"Inter"} overflow={"visible"}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default SearchInputAccordion;
