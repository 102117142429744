import { useCallback, useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";

import {
  REGEX,
  companyNewsTutorialLink,
} from "../../constants";

import type { TSelectedColumnOption } from "@/types/common.types";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import OtherOptions from "./OtherOptions";
import { EnrichmentMetaData } from "@/types/table.types";

interface EnrichmentDataType extends EnrichmentMetaData {
  categories?: string[];
  newsDescriptions?: string[];
  newsTitles?: string[];
}

const CompanyNews = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<{
    categories: Option[];
    newsTitles: Option[];
    newsDescriptions: Option[];
  }>({
    categories: [],
    newsTitles: [],
    newsDescriptions: [],
  });

  const updateSelectedFilters = useCallback((key: string, value: Option[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });

        if (enrichmentData.categories) {
          updateSelectedFilters(
            "categories",
            enrichmentData.categories.map((item) => ({
              label: item,
              value: item,
            })),
          );
        }

        if (enrichmentData.newsTitles) {
          updateSelectedFilters(
            "newsTitles",
            enrichmentData.newsTitles.map((item) => ({
              label: item,
              value: item,
            })),
          );
        }

        if (enrichmentData.newsDescriptions) {
          updateSelectedFilters(
            "newsDescriptions",
            enrichmentData.newsDescriptions.map((item) => ({
              label: item,
              value: item,
            })),
          )
        };

      }
    } else {
      const initialColumn = getInitialColumn(REGEX.WEBSITE);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Get the latest news about any company"
          link={companyNewsTutorialLink}
        />
        <p className="pb-1 text-base font-semibold">Website URL</p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <OtherOptions
          updateSelectedFilters={updateSelectedFilters}
          selectedFilters={selectedFilters}
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
          categories: selectedFilters.categories.map((item) => item.value),
          newsTitles: selectedFilters.newsTitles.map((item) => item.value),
          newsDescriptions: selectedFilters.newsDescriptions.map((item) => item.value),
        }}
      />
    </>
  );
};

export default CompanyNews;
