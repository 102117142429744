import { useQuery } from "@tanstack/react-query";

import misServices, { MiscType } from "@/services/misc.services";

export const useMiscData = <ResponseT>(
  miscType: MiscType,
  params?: {
    [key: string]: string | undefined;
  },
  isEnabled: boolean = true,
) =>
  useQuery({
    queryKey: ["misc", miscType],
    queryFn: () => misServices.getMiscData<ResponseT>(miscType, params),
    staleTime: 24 * 60 * 1000, // 24 hours
    gcTime: 24 * 60 * 1000, // 24 hours
    refetchOnMount: "always",
    enabled: isEnabled,
  });
