import Templates from "@/components/Templates";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/playbooks/").createRoute({
  component: TemplatesPage,
  errorComponent: () => <div>Error</div>,
});

function TemplatesPage() {
  return <Templates />;
}

export default TemplatesPage;
