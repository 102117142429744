import { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";

import AddToTable from "./AddToTable";
import SearchRepo from "./SearchRepo";
import ConnectAccount from "./ConnectAccount";

import Icons from "@/components/Icons";
import { useModalStore } from "@/stores/modal.store";
import { useProviderList } from "@/hooks/useProviderData";

const GithubStargazersModal = () => {
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const [modalBodyUi, setModalBodyUi] = useState("connect"); // connect, search, add-to-table
  const [repoPath, setRepoPath] = useState("");
  const [totalNumOfStarGazers, settotalNumOfStarGazers] = useState(0);

  const { isLoading, data: providerData } = useProviderList();

  const closeModal = () => {
    if (modalData.metaData?.openPreviousModal) {
      updateState({
        isOpenModal: true,
        modalData: { modalType: "get-started", metaData: "" },
      });
    } else {
      updateState({
        isOpenModal: false,
        modalData: { modalType: "", metaData: "" },
      });
    }
  };

  const handleGithubConnect = () => {
    setModalBodyUi("search");
  };

  const handleBack = () => {
    if (modalBodyUi === "add-to-table") {
      setModalBodyUi("search");
    }
  };

  useEffect(() => {
    if (!isLoading && providerData?.data?.length) {
      const githubProvider = providerData.data.find(
        (provider) => provider.name === "github",
      );
      if (githubProvider) {
        setModalBodyUi("search");
      }
    }
  }, [providerData, isLoading]);

  return (
    <Modal
      isOpen={isOpenModal && modalData.modalType === "github-stargazers"}
      onClose={closeModal}
      size={"2xl"}
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="pb-4 font-poppins">
        <ModalHeader className="border-b-[1px] text-center">
          <div className="flex items-center">
            {modalBodyUi !== "connect" && modalBodyUi !== "search" && (
              <Text className="cursor-pointer" onClick={handleBack}>
                <Icons.BackIcon />
              </Text>
            )}
            <Text className="flex-1">
              {modalBodyUi === "add-to-table"
                ? "Add to Table"
                : "Find Stargazers on GitHub"}
            </Text>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && (
            <div className="flex justify-center items-center">
              <Spinner color="purple" size="sm" />
            </div>
          )}
          {modalBodyUi === "add-to-table" && !isLoading && (
            <AddToTable
              repoPath={repoPath}
              onClose={closeModal}
              totalNumOfStarGazers={totalNumOfStarGazers}
              handleGithubConnect={handleGithubConnect}
            />
          )}
          {modalBodyUi === "connect" && !isLoading && <ConnectAccount />}
          {modalBodyUi === "search" && !isLoading && (
            <SearchRepo
              handleNext={(_repoPath, starGazersCount) => {
                setModalBodyUi("add-to-table");
                setRepoPath(_repoPath);
                settotalNumOfStarGazers(starGazersCount);
              }}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GithubStargazersModal;
