import { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";

import KeySelect from "@/components/Common/KeySelect";
import WatchTutorial from "@/components/Common/WatchTutorial";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import SelectableTextEditor from "../../Common/SelectableTextEditor";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";

import { usePresetsStore } from "@/stores/presets.store";
import type { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";
import { useMutation } from "@tanstack/react-query";
import providerService from "@/services/providers.service";
import Swal from "sweetalert2";
import { Actors } from "@/types/apify.types";

interface EnrichmentDataType extends EnrichmentMetaData {
  actorId: string;
  input: string;
}

const ApiFy = () => {
  const [selectedActor, setSelectedActor] = useState<Option | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [actorsList, setActorsList] = useState<Actors[]>([]);

  const actorInput = usePresetsStore((state) => state.selectedPresetValues);
  const updateState = usePresetsStore((state) => state.updateState);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const { updateState: updateEnrichState } = useEnrichStore();

  const { mutateAsync } = useMutation({
    mutationFn: () => providerService.getApifyActors(),
  });

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      console.log("enrichmentData", enrichmentData);

      if (enrichmentData) {
        updateEnrichState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });

        updateState({
          selectedPresetValues: enrichmentData.input,
        });
      }

      if (actorsList.length > 0) {
        const selectedActor = actorsList.find(
          (actor) => actor.id === enrichmentData.actorId,
        );

        if (selectedActor) {
          setSelectedActor({
            value: selectedActor.id,
            label: selectedActor.title,
          });
        }
      }
    }
  }, [selectedColumnToEdit, isLoading]);

  const updateActorList = async () => {
    setIsLoading(true);
    await mutateAsync(undefined, {
      onSuccess: (response) => {
        setActorsList((response.data as Actors[]) || []);
        setIsLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
        });
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    updateActorList();
  }, []);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <WatchTutorial subText="Run Your Apify actors" link="" />
        <KeySelect
          providerName={"apifyApiKey"}
          providerTitle="Apify"
          disablePersanaKey={true}
        />
        <div>
          <div className="flex justify-between items-end mb-2 relative">
            <div className="flex items-center gap-1">
              <p className="text-sm font-semibold">Apify Actor</p>
              {isLoading && <Spinner size="sm" />}
            </div>
          </div>

          <MultiSelectDropdown
            options={actorsList?.map((actor) => ({
              value: actor.id,
              label: actor.title,
            }))}
            onChange={(selected) => {
              setSelectedActor(selected as Option);
            }}
            isMulti={false}
            isCreateAble={false}
            placeholder="Select actor"
            value={selectedActor}
            closeMenuOnSelect
          />
        </div>
        <SelectableTextEditor
          title="Actor's input"
          text={actorInput || ""}
          setText={(newInputText) => {
            updateState({
              selectedPresetValues: newInputText,
            });
          }}
          placeholder="Paste your actor's input here"
          rows={8}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedActor || !actorInput}
        apiPayload={{
          actorId: selectedActor?.value,
          input: actorInput,
          apiKeyName: "apifyApiKey",
          apiKeySelect: "ownApiKey",
        }}
      />
    </>
  );
};

export default ApiFy;
