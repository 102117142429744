import APIKeyIntegration from "./APIKeyIntegration";
import ZenrowsLogo from "@/assets/images/zenrows.png";

const ZenrowsIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"zenrowsApiKey"}
      providerTitle="Zenrows"
      providerIcon={ZenrowsLogo}
    />
  );
};

export default ZenrowsIntegration;
