import { useEffect, useState } from "react";

import KeySelect from "@/components/Common/KeySelect";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { REGEX } from "../../constants";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import { useEnrichStore } from "@/stores/enrich.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import CustomAccordion from "@/components/Common/CustomAccordion";
import Description from "../../Common/Description";
import { Customizations } from "./Customizations";
import { ZenRowParams } from "./types";
import { defaultZenRowParams } from "./constants";
import { EnrichmentMetaData } from "@/types/table.types";

const Zenrows = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const [zenRowsParams, setZenRowsParams] =
    useState<ZenRowParams>(defaultZenRowParams);
  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentMetaData & ZenRowParams;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
      setZenRowsParams({
        ...defaultZenRowParams,
        zenRowsProxy: enrichmentData?.zenRowsProxy,
        zenRowsProxyCountry: enrichmentData.zenRowsProxyCountry,
        zenRowsRenderJS: enrichmentData.zenRowsRenderJS,
        zenRowsOutput: enrichmentData.zenRowsOutput,
        zenRowsCssSelectors: enrichmentData.zenRowsCssSelectors,
      });
    } else {
      const initialColumn = getInitialColumn(REGEX.WEBSITE);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [selectedColumnToEdit, updateState, viewMode]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Run a Zenrows scrape and return the results"
          link={""}
        />
        <KeySelect
          providerName={"zenrowsApiKey"}
          providerTitle="Zenrows"
          disablePersanaKey={true}
        />
        <div className="py-4">
          <p className="pb-1 text-base font-semibold">Scrape URL</p>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <CustomAccordion isDefaultOpen title="Customizations">
          <Description>Enabled ZenRows Customizations</Description>
          <Customizations
            zenRowsParams={zenRowsParams}
            setZenRowsParams={setZenRowsParams}
          />
        </CustomAccordion>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          apiKeyName: "zenrowsApiKey",
          columnId: selectedOption?.keyId,
          zenRowsProxy: zenRowsParams.zenRowsProxy,
          zenRowsProxyCountry: zenRowsParams.zenRowsProxyCountry,
          zenRowsRenderJS: zenRowsParams.zenRowsRenderJS,
          zenRowsOutput: zenRowsParams.zenRowsOutput,
          zenRowsCssSelectors: zenRowsParams.zenRowsCssSelectors,
        }}
      />
    </>
  );
};

export default Zenrows;
