import { useEffect, useState } from "react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { TSelectedColumnOption } from "@/types/common.types";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import { preselectEnrichmentState } from "@/utils";
import { functions, options } from "./constants";

const RecentLinkedInPost = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const [selectedSearchType, setSelectedSearchType] = useState(
    () => options[0],
  );

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  function findObjectByKey(array: any, key: string, value: string) {
    return array.find((item: any) => item[key] === value);
  }

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);

        const selectedSearchType = findObjectByKey(
          options,
          "value",
          enrichmentData.serviceName,
        );

        if (selectedSearchType) {
          setSelectedSearchType(selectedSearchType);
        }

        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    } else {
      functions.map((func) => {
        if (func.value === selectedSearchType.value) {
          const initialColumn = func.getColumn();
          if (initialColumn) {
            setSelectedOption({
              key: initialColumn.name,
              keyId: initialColumn._id,
              iconType: initialColumn.metaData?.iconType || "url",
            });
          }
        }
      });
    }
  }, [columns, selectedSearchType]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <WatchTutorial subText={"Find Recent LinkedIn Posts"} link={""} />

        <div className="mb-4">
          <RadioGroup
            onChange={(value) => {
              const foundObject = findObjectByKey(options, "value", value);
              setSelectedSearchType(foundObject);
              setSelectedOption(null);
            }}
            value={selectedSearchType.value}
          >
            <Stack direction="row">
              {options.map((option) => (
                <Radio value={option.value} key={option.value}>
                  {option.name}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </div>

        <p className="pb-1 text-base font-semibold mt-1">
          {selectedSearchType.value === "get-company-posts"
            ? "Company Website or LinkedIn URL"
            : "LinkedIn URL"}
        </p>

        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
          serviceName: selectedSearchType.value,
        }}
      />
    </>
  );
};

export default RecentLinkedInPost;
