import { useEffect, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SelectableTextEditor from "../../Common/SelectableTextEditor";

const DraftMessage = () => {
  const [prompts, setPrompts] = useState("");

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as any;
      if (enrichmentData) {
        setPrompts(enrichmentData?.prompts || "");
      }
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="overflow-y-auto p-4">
        <SelectableTextEditor
          title="Write your prompt"
          text={prompts || ""}
          setText={(newText) => {
            setPrompts(newText);
          }}
          rows={12}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!prompts}
        apiPayload={{
          prompts,
        }}
      />
    </>
  );
};

export default DraftMessage;
