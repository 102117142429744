import { isUrl } from "@/utils";

const TextRenderer = ({ value }: { value: string }) => {
  return (
    <>
      {isUrl(value) ? (
        <a href={value} target="_blank">
          <p className="text-primary my-2 ml-1 text-[16px] font-medium">
            {value}
          </p>
        </a>
      ) : (
        <pre className="my-2 ml-1 text-[16px] font-medium text-[#677084] whitespace-pre-wrap">
          {value}
        </pre>
      )}
    </>
  );
};

export default TextRenderer;
