import { useEffect, useState } from "react";

import { TSelectedColumnOption } from "@/types/common.types";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { RESPONSE_COLUMNS, alpha2CountryCodes } from "./constants";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { Column, EnrichmentApiCallPayload } from "@/types/enrichment.types";
import SelectResponseColumn from "../../Common/SelectResponseColumn";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";

interface PayloadType extends Partial<EnrichmentApiCallPayload> {
  country?: string;
  currentJobTitles?: string[];
  pastJobTitles?: string[];
  newColumnsToCreate?: {
    name: string;
    paths: string[];
  }[];
}

function FindPeopleAtCompanyProxyCurl() {
  const [currentCompany, setCurrentCompany] =
    useState<TSelectedColumnOption | null>(null);
  const [pastCompany, setPastCompany] = useState<TSelectedColumnOption | null>(
    null,
  );
  const [responseColumns, setResponseColumns] = useState(
    () => RESPONSE_COLUMNS,
  );
  const [selectedCountry, setSelectedCountry] = useState<Option | null>(null);
  const [currentJobTitle, setCurrentJobTitle] = useState<Option[] | []>([]);
  const [pastJobTitle, setPastJobTitle] = useState<Option[] | []>([]);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const columns = useTableStore((state) => state.tableData.columns);

  const filerOptions = [
    {
      name: "Current Company",
      stateName: "currentCompany",
      description:
        "It can be a company website url or company name or company linkedin url.",
      isColumnSelector: true,
      stateValue: currentCompany,
      setStateValue: setCurrentCompany,
    },
    {
      name: "Past Company",
      stateName: "pastCompany",
      description:
        "It can be a company website url or company name or company linkedin url.",
      isColumnSelector: true,
      stateValue: pastCompany,
      setStateValue: setPastCompany,
    },
    {
      name: "Country",
      value: selectedCountry,
      setValue: setSelectedCountry,
      options: alpha2CountryCodes,
      placeholder: "Select Country",
      moreDropdownOptions: {
        limit: 1,
        isCreateAble: false,
        isMulti: false,
        closeMenuOnSelect: true,
      },
    },
    {
      name: "Current Job Title",
      warning: "You can add upto three job titles.",
      placeholder: "Add Current Job Titles to include",
      stateName: "currentJobTitles",
      value: currentJobTitle,
      setValue: setCurrentJobTitle,
      moreDropdownOptions: {
        limit: 3,
      },
    },
    {
      name: "Past Job Title",
      warning: "You can add upto three job titles.",
      placeholder: "Add Past Job Titles to include",
      stateName: "pastJobTitles",
      value: pastJobTitle,
      setValue: setPastJobTitle,
      moreDropdownOptions: {
        limit: 3,
      },
    },
  ];

  const getColIds = () => {
    const colIds = filerOptions
      .map((item) => {
        if (item?.stateValue) {
          return {
            id: item?.stateValue?.keyId,
            name: item?.stateName,
          };
        }
      })
      ?.filter((item) => item?.id);

    return colIds;
  };

  const handleSwitch = (id: string) => {
    setResponseColumns((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item,
      ),
    );
  };

  useEffect(() => {
    if (viewMode === "edit" && selectedColumnToEdit) {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as PayloadType;

      const selectedCountry = alpha2CountryCodes.find(
        (country) => country.value === enrichmentData?.country,
      );
      if (selectedCountry) {
        setSelectedCountry(selectedCountry);
      }

      console.log("enrichmentData", enrichmentData);

      Object.keys(enrichmentData || {}).forEach((key) => {
        const state = filerOptions.find((state) => state.stateName === key);

        if (state?.setValue) {
          if (Array.isArray(enrichmentData[key])) {
            const options = enrichmentData[key].map((item: string) => ({
              label: item,
              value: item,
            }));

            // @ts-ignore
            state.setValue(options);
          }
        }
      });

      if (enrichmentData.newColumnsToCreate) {
        setResponseColumns((prevState) =>
          prevState.map((item) => ({
            ...item,
            isChecked:
              enrichmentData?.newColumnsToCreate
                ?.map((item) => item.name)
                ?.includes(item.label) ?? false,
          })),
        );
      }

      (enrichmentData?.columnId as Column[])?.map((columnId: any) => {
        const relyOnColumn = columns.find(
          (column) => column._id === columnId.id,
        );

        if (relyOnColumn) {
          const state = filerOptions.find(
            (state) => state.stateName === columnId.name,
          );

          if (state?.setStateValue) {
            state.setStateValue({
              key: relyOnColumn.name,
              keyId: relyOnColumn._id,
              iconType: relyOnColumn.metaData?.iconType || "url",
            });
          }
        }
      });
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="max-h-[70vh] grow space-y-4 overflow-y-auto p-4">
        {filerOptions.map((item, index) => (
          <div key={`fpac-search-${index}`} className="space-y-1.5">
            <div className="flex items-center gap-x-1.5">
              <p className="text-base font-semibold">{item?.name}</p>{" "}
              {item?.warning && (
                <span className="inline-block pt-1 text-xs">
                  {" "}
                  ( You can add upto three job titles. )
                </span>
              )}
            </div>
            {item?.description && (
              <p className="text-xs">
                It can be a company website url or company name or company
                linkedin url.
              </p>
            )}
            {item?.isColumnSelector ? (
              <SelectColumnDropdown
                selectedOption={item?.stateValue}
                setSelectedOption={(
                  selectedOption: TSelectedColumnOption | null,
                ) => {
                  const updatedSelectedOption = {
                    ...selectedOption,
                    stateName: item?.stateName,
                  } as TSelectedColumnOption;

                  item?.setStateValue(updatedSelectedOption);
                }}
              />
            ) : (
              <MultiSelectDropdown
                value={item?.value}
                options={item?.options || []}
                onChange={(selectedValues) => {
                  if (item?.setValue) {
                    // @ts-ignore
                    item.setValue(selectedValues as Option[] | Option | null);
                  }
                }}
                isCreateAble
                isMulti
                placeholder={item?.placeholder}
                {...item?.moreDropdownOptions}
              />
            )}
          </div>
        ))}

        <SelectResponseColumn
          responseColumns={responseColumns}
          handleSwitch={handleSwitch}
        />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedCountry?.value || !currentCompany?.key}
        apiPayload={
          {
            columnId: getColIds() as Column[],
            country: selectedCountry?.value,
            currentJobTitles: currentJobTitle?.map((item) => item.value) || [],
            pastJobTitles: pastJobTitle?.map((item) => item.value) || [],
            newColumnsToCreate:
              responseColumns
                .filter((item) => item.isChecked)
                .map((item) => ({
                  name: item.label,
                  paths: item.path,
                })) || [],
          } as PayloadType
        }
      />
    </>
  );
}

export default FindPeopleAtCompanyProxyCurl;
