import { Checkbox, Tooltip } from "@chakra-ui/react";
import Icons from "@/components/Icons";
import { ZenRowParams } from "../types";

export const RenderJs = ({
  setValue,
  value,
}: {
  setValue: (value: ZenRowParams["zenRowsRenderJS"]) => void;
  value: ZenRowParams["zenRowsRenderJS"];
}) => {
  return (
    <div className="flex flex-wrap items-center rounded-lg bg-white py-3 pl-4 pr-1.5 xl:max-w-none">
      <Checkbox
        id="renderJS"
        name="renderJS"
        className="border-hover-strong focus:ring-hover mr-2 text-primary"
        onChange={(e) => setValue(e.target.checked)}
        isChecked={value}
      />
      <label htmlFor="renderJS" className="text-blue mr-1 font-medium">
        JS Rendering
      </label>
      <Tooltip
        className="inline-flex rounded-lg"
        label={
          <div className="w-64 rounded-lg p-3 sm:w-96">
            <p>
              Boost the success of your request by enabling a more advanced
              scraping mode. Also, using the premium proxy usually increases
              your success rate by 61.2%.
            </p>
          </div>
        }
      >
        <div>
          <Icons.InformationIcon className="size-4" />
        </div>
      </Tooltip>
    </div>
  );
};
