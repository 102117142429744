import { FileRoute } from "@tanstack/react-router";

import AccountMonitoring from "@/pages/AccountMonitoring";

export const Route = new FileRoute("/account-monitoring/").createRoute({
  component: AccountMonitoringPage,
  errorComponent: () => <div>Error</div>,
});

function AccountMonitoringPage() {
  return <AccountMonitoring />;
}

export default AccountMonitoringPage;
