import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTable } from "@/components/DataTable/Table/hooks/useTable";
import accountMonitoringService from "@/services/accountMonitoring.service";

export const useJobTracker = () => {
  const { selectedRowIds } = useTable();
  const queryClient = useQueryClient();
  const toast = useToast();
  const trackJobChanges = useMutation({
    mutationFn: () => {
      const payload = {
        isForAll: selectedRowIds === "all",
        selectedIds:
          selectedRowIds === "all" ? undefined : (selectedRowIds as string[]),
      };
      return accountMonitoringService.trackJobChanges(payload);
    },
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey[0] === "table-running-processes";
          },
        });
      } else {
        toast({
          title: response.error.message || "Unable to track job changes",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    },
    onError: (error) => {
      toast({
        title: error.message || "Unable to track job changes",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  return {
    trackJobChanges,
  };
};
