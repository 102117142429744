import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";

import Icons from "@/components/Icons";

import tableService from "@/services/table.service";

interface Props {
  processingId: string;
  types: string;
}

const CancelProcess = ({ processingId, types }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: ({ processingId }: { processingId: string }) =>
      tableService.deleteRunningProcess({ processingId }),
  });

  const handleCancelJob = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, cancel it!",
      showLoaderOnConfirm: true,
      cancelButtonText: "No, keep it",
      preConfirm: async () => {
        return await mutateAsync(
          { processingId },
          {
            onSuccess: async (response) => {
              if (response.success == false) {
                toast({
                  title: "Something went wrong",
                  description: response.error.message,
                  status: "error",
                  duration: 4000,
                  isClosable: true,
                  position: "top-right",
                });
                return;
              }
              queryClient.refetchQueries({
                queryKey: ["table-running-processes", types],
              });
              queryClient.refetchQueries({
                queryKey: ["user-credit-details"],
              });

              toast({
                title: "Process cancelled successfully",
                status: "success",
                duration: 1000,
                isClosable: true,
                position: "top-right",
              });
            },
            onError: (error) => {
              toast({
                title: "Something went wrong",
                description: error.message,
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            },
          },
        );
      },
    });
  };
  return (
    <Icons.CloseCircleIcon
      className="cursor-pointer text-[24px] font-extrabold text-red-600"
      onClick={handleCancelJob}
    />
  );
};

export default CancelProcess;
