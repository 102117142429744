import { Button, Input } from "@chakra-ui/react";
import { useEffect, useReducer, useState } from "react";
import { Descendant } from "slate";

import PersanaAccordion from "@/components/Common/PersanaAccordion";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";

import KeySelect from "@/components/Common/KeySelect";
import { arrayToObject, transformCustomFieldsObject } from "@/lib/utils";
import { useEnrichStore } from "@/stores/enrich.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { REGEX } from "../../constants";

type CustomField = {
  id: string;
  name?: string;
  value?: Descendant[];
};

const HubspotContact = () => {
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const id = selectedEnrichments?.id || "hubspotContact";
  const isForUpdateContact = id === "hubspotContactUpdate";

  const viewMode = useEnrichStore((state) => state.viewMode);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const initialSlateValue = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);

  const initialState = {
    firstname: initialSlateValue,
    lastname: initialSlateValue,
    jobtitle: initialSlateValue,
    phone: initialSlateValue,
    company: initialSlateValue,
    website: initialSlateValue,
    city: initialSlateValue,
    address: initialSlateValue,
    state: initialSlateValue,
    zip: initialSlateValue,
    country: initialSlateValue,
  };

  function reducer(state: any, action: any) {
    return { ...state, [action.key]: action.value };
  }

  const [contactDetails, dispatch] = useReducer(reducer, initialState);
  const updateDetails = (key: string, value: any) => dispatch({ key, value });

  const optionOptions = [
    {
      name: "First Name",
      state: contactDetails.firstname,
      description: "",
      setState: (value: any) => {
        updateDetails("firstname", value);
      },
    },
    {
      name: "Last Name",
      description: "",
      state: contactDetails.lastname,
      setState: (value: any) => {
        updateDetails("lastname", value);
      },
    },
    {
      name: "Job Title",
      description: "",
      state: contactDetails.jobtitle,
      setState: (value: any) => {
        updateDetails("jobtitle", value);
      },
    },
    {
      name: "Phone Number",
      description: "",
      state: contactDetails.phone,
      setState: (value: any) => {
        updateDetails("phone", value);
      },
    },
    {
      name: "Company",
      description: "",
      state: contactDetails.company,
      setState: (value: any) => {
        updateDetails("company", value);
      },
    },
    {
      name: "Website",
      description: "",
      state: contactDetails.website,
      setState: (value: any) => {
        updateDetails("website", value);
      },
    },
    {
      name: "Street address",
      description:
        "Street address of the company or organization, including unit number. ",
      state: contactDetails.address,
      setState: (value: any) => {
        updateDetails("address", value);
      },
    },
    {
      name: "City",
      description: "City where the company is located.",
      state: contactDetails.city,
      setState: (value: any) => {
        updateDetails("city", value);
      },
    },
    {
      name: "State/Region",
      description:
        "State or region in which the company or organization is located.",
      state: contactDetails.state,
      setState: (value: any) => {
        updateDetails("state", value);
      },
    },
    {
      name: "Postal code",
      description: "Postal or zip code of the company or organization.",
      state: contactDetails.zip,
      setState: (value: any) => {
        updateDetails("zip", value);
      },
    },
    {
      name: "Country/Region",
      description: "Country in which the company or organization is located.",
      state: contactDetails.country,
      setState: (value: any) => {
        updateDetails("country", value);
      },
    },
  ];

  const handleCustomField = (
    id: string,
    name: string,
    value: Descendant[] | string,
  ): void => {
    setCustomFields((prevFields: CustomField[]) =>
      prevFields.map((field: CustomField) =>
        field.id === id ? { ...field, [name]: value } : field,
      ),
    );
  };

  const handleRemoveCustomField = (name: string): void => {
    const newFields = customFields?.filter(
      (field: CustomField) => field.name !== name,
    );
    setCustomFields(newFields);
  };

  const handleAddCustomField = (): void => {
    const newField: CustomField = {
      id: `custom_field_name_${customFields.length + 1}`,
      name: `Custom Field Name ${customFields.length + 1}`,
      value: initialSlateValue,
    };

    setCustomFields((prevFields: CustomField[]) => [...prevFields, newField]);
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentMetaData;

      const formattedCustomFields = transformCustomFieldsObject(
        enrichmentData?.customFields || {},
      );
      setCustomFields(formattedCustomFields);

      //@ts-ignore
      const slateValueDetails = enrichmentData?.contactDetailsBySlateValue;
      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        for (const key in slateValueDetails) {
          updateDetails(key, slateValueDetails[key]);
        }
      }
    } else {
      if (!isForUpdateContact) {
        const initialLinkedInColumn = getInitialColumn(REGEX.EMAIL);
        if (initialLinkedInColumn) {
          setSelectedOption({
            key: initialLinkedInColumn.name,
            keyId: initialLinkedInColumn._id,
            iconType: initialLinkedInColumn.metaData?.iconType || "url",
          });
        }
      }
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={
            isForUpdateContact
              ? "Update HubSpot Contact by ID"
              : "Create or Update a HubSpot Contact by Domain"
          }
          link={""}
        />

        <KeySelect
          providerName={"hubspotApiKey"}
          providerTitle="HubSpot API Key"
          disablePersanaKey={true}
        />

        <p className="mt-1 pb-1 text-base font-semibold">
          {isForUpdateContact ? "Hubspot ID" : "Email"}
        </p>

        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <p className="text-purple-500 font-mediu mb-3 mt-1 text-sm">
          Note: Please connect your account
        </p>

        <PersanaAccordion
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
          }}
          items={[
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add More Contact Details (Optional)
                </button>
              ),

              panel: (
                <div className="space-y-3">
                  {optionOptions.map((item) => (
                    <div key={item.name}>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {item?.name}
                      </p>
                      <CustomTextEditor
                        editorHeight={"4rem"}
                        slateValue={item?.state}
                        setSlateValue={item?.setState}
                        placeholder={`Start typing or use the dropdown to select a column.`}
                      />
                    </div>
                  ))}
                </div>
              ),
            },
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Custom Fields (Optional)
                </button>
              ),

              panel: (
                <>
                  {customFields?.map((field: any, index: number) => (
                    <div
                      className="mt-3 flex items-center justify-start gap-[5px]"
                      key={`other-fields-${index}`}
                    >
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">name</p>
                        <Input
                          maxW={"200px"}
                          value={field?.name || ""}
                          placeholder={"Enter name..."}
                          name={"name"}
                          onChange={(e) =>
                            handleCustomField(
                              field.id,
                              e.target.name,
                              e.target.value,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">value</p>
                        <CustomTextEditor
                          editorHeight={"1rem"}
                          slateValue={field.value}
                          setSlateValue={(val) =>
                            handleCustomField(field.id, "value", val)
                          }
                          showDetails={false}
                        />
                      </div>
                      <Button
                        className="mb-1 self-end"
                        onClick={() => {
                          handleRemoveCustomField(field.name);
                        }}
                        size={"sm"}
                      >
                        X
                      </Button>
                    </div>
                  ))}

                  {customFields?.length < 20 ? (
                    <div className="mt-5">
                      <Button size={"sm"} onClick={handleAddCustomField}>
                        + Add New Custom Field
                      </Button>
                      <span className={"block py-1 text-sm text-[#676d7e]"}>
                        Any fields you want to add to the lead. Put the name of
                        the field on the left and the value on the right.
                      </span>
                    </div>
                  ) : null}
                </>
              ),
            },
          ]}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          apiKeyName: "hubspotApiKey",
          apiKeyErrorMessage: "Please connect your hubspot account",
          columnId: selectedOption?.keyId,

          contactDetailsBySlateValue: {
            ...contactDetails,
          },
          customFields:
            customFields?.length > 0 ? arrayToObject(customFields) : {},
        }}
      />
    </>
  );
};

export default HubspotContact;
