import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import Swal from "sweetalert2";
import { Box, Link, Text, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import Header from "@/components/LinkedInProfiles/Header";

import LoadingSpin from "@/assets/animated/LoadingSpin";
import linkedinProfilesService from "@/services/linkedinProfiles.service";
import { LinkedinProfileInfo } from "@/types/linkedinProfiles.type";
const LinkedInProfilesTable = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const [profiles, setProfiles] = useState<LinkedinProfileInfo[]>([]);
  const [selectedProfiles, setSelectedProfiles] = useState<
    LinkedinProfileInfo[]
  >([]);
  const [filteredProfiles, setFilteredProfiles] = useState<
    LinkedinProfileInfo[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [profileType, setProfileType] = useState<string>("all");
  const [searchInput, setSearchInput] = useState("");

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      cellDataType: false,
    };
  }, []);

  const nameRenderer = (props: any) => {
    return (
      <Link href={props.data.linkedinUrl} isExternal color="#693dc7">
        <div className="flex items-center gap-[10px]">
          <Text>{props.data.firstName + " " + props.data.lastName}</Text>
        </div>
      </Link>
    );
  };

  const colDefs: ColDef[] = [
    {
      headerName: "",
      checkboxSelection: true,
      width: 80,
      pinned: "left",
      headerCheckboxSelection: true,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "firstName",
      headerName: "Name",
      cellRenderer: nameRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "headline",
      headerName: "Headline",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "location",
      headerName: "Location",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "origin",
      headerName: "Origin",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "date",
      headerName: "Date",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
  ];

  const getImportedProfiles = async () => {
    setIsLoading(true);
    const data = await linkedinProfilesService.getLinkedinProfiles();
    //@ts-ignore
    if (data?.ok) {
      setIsLoading(false);
      //@ts-ignore
      const profileData = data?.profileInfo;
      const filtered = profileData?.filter(
        (item: LinkedinProfileInfo) => item.origin === "imported",
      );
      if (filtered?.length) {
        setProfileType("imported");
        setFilteredProfiles(filtered);
      } else {
        setFilteredProfiles(profileData);
      }
      setProfiles(profileData);
    } else {
      setIsLoading(false);
    }
  };

  const handleProfileChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setProfileType(e.target.value);
    if (e.target.value === "all") {
      setFilteredProfiles(profiles);
    } else {
      const updatedProfiles = profiles?.filter(
        (item) => item.origin === e.target.value,
      );
      setFilteredProfiles(updatedProfiles);

      setSelectedProfiles([]);
    }
  };

  const handleSearch = (query: string) => {
    setSearchInput(query);
    const formattedQuery = query.toLocaleLowerCase();
    if (formattedQuery === "") {
      if (profileType === "all") {
        setFilteredProfiles(profiles);
      } else {
        const filteredProfs = profiles.filter(
          (item) => item.origin === profileType,
        );
        setFilteredProfiles(filteredProfs);
      }
    } else {
      const searchedProfiles =
        profileType !== "all"
          ? profiles.filter(
              (item) =>
                (item?.firstName
                  ?.toLocaleLowerCase()
                  .includes(formattedQuery) ||
                  item?.lastName
                    ?.toLocaleLowerCase()
                    .includes(formattedQuery) ||
                  item?.currentCompany
                    ?.toLocaleLowerCase()
                    .includes(formattedQuery) ||
                  item?.location
                    ?.toLocaleLowerCase()
                    .includes(formattedQuery) ||
                  item?.date?.toLocaleLowerCase().includes(formattedQuery) ||
                  item?.currentTitle
                    ?.toLocaleLowerCase()
                    .includes(formattedQuery)) &&
                item?.origin === profileType,
            )
          : profiles.filter(
              (item) =>
                item?.firstName?.toLocaleLowerCase().includes(formattedQuery) ||
                item?.lastName?.toLocaleLowerCase().includes(formattedQuery) ||
                item?.currentCompany
                  ?.toLocaleLowerCase()
                  .includes(formattedQuery) ||
                item?.location?.toLocaleLowerCase().includes(formattedQuery) ||
                item?.date?.toLocaleLowerCase().includes(formattedQuery) ||
                item?.currentTitle
                  ?.toLocaleLowerCase()
                  .includes(formattedQuery),
            );
      setFilteredProfiles(searchedProfiles);
    }
  };

  const handleDeleteProfiles = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover deleted profiles.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const selectedProfileIds = selectedProfiles.map((item) => item._id);
          const res =
            await linkedinProfilesService.deleteLinkedinProfiles(
              selectedProfileIds,
            );
          if (res?.data?.message === "Profiles deleted successfully") {
            getImportedProfiles();
          } else {
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedProfiles(selectedRows);
  }, []);

  const { isPending, mutateAsync } = useMutation({
    mutationFn: () =>
      linkedinProfilesService.createTable({
        selectedRowsIds: selectedProfiles?.map((item) => item._id) || [],
      }),
    onSuccess: (response) => {
      if (response.success) {
        navigate({
          to: `/table/$tableId`,
          params: {
            tableId: response?.data?.data?.tableData._id,
          },
        });
      } else {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    getImportedProfiles();
  }, []);

  return (
    <div className="mt-5">
      <Header
        filteredProfiles={filteredProfiles}
        isButtonDisabled={selectedProfiles?.length === 0}
        isPending={isPending}
        profileType={profileType}
        searchInput={searchInput}
        isLoading={isLoading}
        handleDeleteProfiles={handleDeleteProfiles}
        handleProfileChange={handleProfileChange}
        handleSearch={handleSearch}
        handleCreateTable={mutateAsync}
      />
      {isLoading ? (
        <LoadingSpin />
      ) : filteredProfiles?.length ? (
        <Box>
          <div
            className="ag-theme-quartz mt-5"
            style={{ width: "100%", height: "80vh" }}
          >
            <AgGridReact
              ref={gridRef}
              columnDefs={colDefs}
              rowData={filteredProfiles}
              defaultColDef={defaultColDef}
              headerHeight={40}
              rowHeight={38}
              rowClass="border-1 border-gray-200"
              rowSelection="multiple"
              rowMultiSelectWithClick
              suppressColumnMoveAnimation
              suppressRowClickSelection
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </Box>
      ) : (
        <Text>No profiles found</Text>
      )}
    </div>
  );
};

export default LinkedInProfilesTable;
