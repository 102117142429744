import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import KeySelect from "@/components/Common/KeySelect";
import WatchTutorial from "@/components/Common/WatchTutorial";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import UsernameTab from "./UsernameTab";

import type { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import { REGEX } from "../../constants";
import EmailTab from "./EmailTab";

type TabName = "Email" | "Username or Linkedin URL";

const tabs: {
  name: TabName;
  value: string;
  component: (props: {
    selectedOption: TSelectedColumnOption | null;
    setSelectedOption: React.Dispatch<
      React.SetStateAction<TSelectedColumnOption | null>
    >;
    selectedUsernameOption: TSelectedColumnOption | null;
    setSelectedUsernameOption: React.Dispatch<
      React.SetStateAction<TSelectedColumnOption | null>
    >;

    selectedLinkedinOption: TSelectedColumnOption | null;
    setSelectedLinkedinOption: React.Dispatch<
      React.SetStateAction<TSelectedColumnOption | null>
    >;
  }) => JSX.Element;
}[] = [
  {
    name: "Email",
    value: "email",
    component: (props) => <EmailTab {...props} />,
  },
  {
    name: "Username or Linkedin URL",
    value: "username",
    component: (props) => <UsernameTab {...props} />,
  },
];

interface EnrichmentDataType extends EnrichmentMetaData {
  type: "email" | "username";
  dynamicText?: string;
}

const TwitterDetails = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedUsernameOption, setSelectedUsernameOption] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedLinkedinOption, setSelectedLinkedinOption] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedTab, setSelectedTab] = useState<"email" | "username">("email");

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const isDisabled =
    (selectedTab === "email" && !selectedOption?.keyId) ||
    (selectedTab === "username" &&
      !selectedUsernameOption?.keyId &&
      !selectedLinkedinOption?.keyId);

  useEffect(() => {
    if (selectedTab === "email" && viewMode !== "edit") {
      const initialColumn = getInitialColumn(REGEX.EMAIL);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    } else if (selectedTab === "username" && viewMode !== "edit") {
      const initialColumn = getInitialColumn(REGEX.LINKEDINPROFILE);
      if (initialColumn) {
        setSelectedLinkedinOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns, selectedTab]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        setSelectedTab(enrichmentData.type);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        if (enrichmentData.type === "email") {
          preselectEnrichmentState(enrichmentData, setSelectedOption);
        } else {
          preselectEnrichmentState(enrichmentData, setSelectedLinkedinOption);
        }
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="grow p-4 overflow-y-auto bg-white">
        <WatchTutorial
          subText={
            "Get the Twitter Details from the email address, twitter username or linkedin URL."
          }
          link={""}
        />
        <KeySelect
          providerName={"datagma"}
          providerTitle="Datagma"
          disablePersanaKey={true}
        />

        <Tabs>
          <TabList className="border-b-2 border-gray">
            {tabs.map((tab, index) => (
              <Tab
                fontSize={"base"}
                fontWeight={"bold"}
                key={index}
                onClick={() => {
                  setSelectedTab(tab.value as "email" | "username");
                }}
                className="flex-1"
              >
                <span
                  className={
                    tab.value === selectedTab ? "text-blue" : "text-dark"
                  }
                >
                  {tab.name}
                </span>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab, index) => {
              const Component = tab.component;
              return (
                <TabPanel key={index}>
                  {
                    <Component
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      selectedUsernameOption={selectedUsernameOption}
                      setSelectedUsernameOption={setSelectedUsernameOption}
                      selectedLinkedinOption={selectedLinkedinOption}
                      setSelectedLinkedinOption={setSelectedLinkedinOption}
                    />
                  }
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={isDisabled}
        apiPayload={{
          type: selectedTab,
          cellData: {
            emailColId: selectedOption?.keyId,
            usernameColId: selectedUsernameOption?.keyId,
            linkedinUrlColId: selectedLinkedinOption?.keyId,
          },
          extraColumns: [],
          apiKeyName: "datagma",
        }}
      />
    </>
  );
};

export default TwitterDetails;
