import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

const ScrapeWebsiteRenderer = ({ value }: { value: any }) => {
  return (
    <Accordion allowToggle className="mt-2">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              className="font-medium text-md text-[#677084]"
            >
              Show Scraped Data
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>{value}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ScrapeWebsiteRenderer;
