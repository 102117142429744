import { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import enrichment from "@/services/enrichment.service";
import { useModalStore } from "@/stores/modal.store";

const GoogleSRPModal = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState<string>("");
  const [numberOfRecords, setNumberOfRecords] = useState(1);
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const onClose = () => {
    if (modalData.metaData?.openPreviousModal) {
      updateState({
        isOpenModal: true,
        modalData: { modalType: "get-started", metaData: "" },
      });
    } else {
      updateState({
        isOpenModal: false,
        modalData: { modalType: "", metaData: "" },
      });
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const data = await enrichment.googleSrp(query, numberOfRecords);
      if (data?.success) {
        setIsLoading(false);
        setQuery("");
        setNumberOfRecords(1);
        updateState({
          isOpenModal: false,
          modalData: { modalType: "", metaData: "" },
        });
      } else {
        setIsLoading(false);
        setQuery("");
        setNumberOfRecords(1);
        updateState({
          isOpenModal: false,
          modalData: { modalType: "", metaData: "" },
        });
        toast({
          title:
            data?.data?.message || "Something went wrong. Please try again.",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      toast({
        title:
          error?.response?.data || "Something went wrong. Please try again.",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      isOpen={isOpenModal && modalData.modalType === "google-srp"}
      size={"2xl"}
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="pb-4 font-poppins">
        <ModalHeader className="border-b-[1px] text-center">
          <div className="flex items-center">
            <Text className="flex-1">Google SRP</Text>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p className="mt-4 pb-1 text-sm font-semibold ">
            {"Google Search Query"}
          </p>
          <div className="relative h-full">
            <Textarea
              rows={4}
              placeholder="Enter Query"
              //   ref={inputRef}
              resize={"none"}
              value={query}
              style={{
                height: "auto",
                border: "1px solid #c6c6ca",
                maxHeight: "15rem",
              }}
              className="!rounded-b-none !rounded-t"
              onChange={(e) => setQuery(e.target.value)}
            ></Textarea>
          </div>
          <p className="mt-4 pb-1 text-sm font-semibold ">
            {"Number of records to fetch"}
          </p>
          <div className="relative h-full">
            <NumberInput
              min={0}
              value={numberOfRecords}
              onChange={(val) => {
                setNumberOfRecords(Number(val));
              }}
            >
              <NumberInputField />
            </NumberInput>
          </div>
          <div className="mt-[20px] flex w-full items-center justify-center">
            <Button
              isDisabled={!query || !numberOfRecords}
              isLoading={isLoading}
              onClick={handleSearch}
              className={`w-[100px] rounded-[5px] !bg-[#693DC7] px-1 py-[7px] !font-title !text-sm !text-[#fff]`}
            >
              <span className="font-semibold">Search</span>
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GoogleSRPModal;
