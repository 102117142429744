import Icons from "@/components/Icons";
import MultiSelectDropdown from ".";
import { Option } from "./types";

interface Props {
  label: string;
  refreshLabel?: string;
  description: string;
  onRefresh?: () => void;
  isRefreshing?: boolean;
  options: Option[];
  selectedObject: Option | Option[] | null;
  onSelect: (value: Option | Option[] | null) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  isCreateAble?: boolean;
  isMulti?: boolean;
  isShowRefreshButton?: boolean;
}

function MultiSelectDropdownRefreshable({
  label,
  description,
  refreshLabel = "Refresh",
  onRefresh,
  isRefreshing,
  options,
  selectedObject,
  onSelect,
  isDisabled,
  isLoading,
  isCreateAble = false,
  isMulti = false,
  isShowRefreshButton = true,
}: Props) {
  return (
    <div className="space-y-1">
      <div className="flex justify-between items-center">
        <p className="font-semibold">{label}</p>
        {isShowRefreshButton && (
          <button
            className="flex items-center space-x-1.5 text-sm"
            onClick={onRefresh}
          >
            <Icons.RefreshIconDoubleArr
              className={`${isRefreshing ? "animate-spin" : ""}`}
            />
            <span className="font-medium">{refreshLabel}</span>
          </button>
        )}
      </div>
      <MultiSelectDropdown
        isMulti={isMulti}
        placeholder="Select an item from the dropdown..."
        closeMenuOnSelect={isMulti ? false : true}
        options={options}
        value={selectedObject}
        onChange={(value) => {
          onSelect(value);
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isCreateAble={isCreateAble}
      />
      <p className="text-xs text-[#717989] font-medium">{description}</p>
    </div>
  );
}

export default MultiSelectDropdownRefreshable;
