import { useRef } from "react";
import { useTableStore } from "./useGridState.store";
import { GridApi } from "ag-grid-community";
import { useUpdateCacheRow } from "./useInfiniteQuery";
import { useQueryClient } from "@tanstack/react-query";
export const useTable = () => {
  const id = useTableStore((state) => state.id);
  const queryClient = useQueryClient();
  const gridApiRef = useRef<GridApi | null>(null);
  const refreshDataRef = useRef<(() => Promise<void>) | null>(null);
  gridApiRef.current = useTableStore((state) => state.gridApi);
  refreshDataRef.current = useTableStore((state) => state.refreshData);
  const updateFnRef = useRef<any>(null);
  updateFnRef.current = useUpdateCacheRow();
  // const applyFilters = useGridFilter().applyFilters;
  const totalRowCount = useTableStore((state) => state.totalRows);
  const isTableLoading = useTableStore((state) => state.loading);
  const selectedRowIds = useTableStore((state) =>
    state.isSelectAll ? "all" : (state.state?.rowSelection as string[]) ?? [],
  );
  const selectedRowCount = useTableStore((state) =>
    state.getSelectedRowCount(),
  );
  const updateTableRow = (row: any) => {
    const id = row._id;
    if (!id) return;
    const rowModalType = gridApiRef.current?.getGridOption("rowModelType");
    if (rowModalType === "infinite") {
      const context = gridApiRef.current?.getGridOption("context");
      gridApiRef.current?.setGridOption("context", {
        ...context,
        useCache: true,
      });
      updateFnRef.current?.(row, 0);
      return gridApiRef.current?.refreshInfiniteCache();
    }
    const node = gridApiRef.current?.getRowNode(id);
    if (!node) {
      gridApiRef.current?.applyTransaction({ add: [row], addIndex: 0 });
      const newNode = gridApiRef.current?.getRowNode(id);
      if (newNode)
        gridApiRef.current?.flashCells({
          rowNodes: [newNode],
          flashDuration: 50,
        });
      return;
    }
    node.setSelected(false);
    gridApiRef.current?.applyTransaction({
      remove: [row],
    });
    gridApiRef.current?.applyTransaction({
      add: [row],
      addIndex: 0,
    });
    gridApiRef.current?.flashCells({ rowNodes: [node], flashDuration: 50 });
    node.setData(row);
    // gridApiRef.current?.redrawRows();
    // gridApiRef.current?.onSortChanged();
  };

  return {
    refresh: async (resetFilters: boolean = false, newData: any = null) => {
      if (resetFilters) {
        gridApiRef.current?.setFilterModel(null);
        gridApiRef.current?.deselectAll();
        gridApiRef.current?.onFilterChanged();
      }
      if (newData) {
        gridApiRef.current?.applyTransaction({
          add: [{ ...newData, _id: "new" }],
          addIndex: 0,
        });
      }
      queryClient.invalidateQueries({ queryKey: ["filterValues", id] });
      await refreshDataRef.current?.();
    },
    selectedRowIds,
    selectedRowCount,
    totalRowCount,
    loading: isTableLoading,
    updateTableRow,
    finished() {
      const context = gridApiRef.current?.getGridOption("context");
      queryClient.invalidateQueries({ queryKey: ["filterValues", id] });
      gridApiRef.current?.setGridOption("context", {
        ...context,
        useCache: false,
      });
    },
    flashSelectedRows: (ms: number = 1000) => {
      gridApiRef.current?.flashCells({
        rowNodes: gridApiRef.current?.getSelectedNodes(),
        flashDuration: ms,
      });
    },
  };
};
