import { FilterModel } from "ag-grid-community";
interface IBuildUrlParams {
  startRow?: number;
  endRow?: number;
  sortModel?: { colId: string; sort: "ASC" | "DESC" | "asc" | "desc" }[];
  filterModel?: FilterModel | null;
}
export function buildServerQueryParams(params: IBuildUrlParams) {
  params.startRow ??= 1;
  params.endRow ??= Number.MAX_SAFE_INTEGER;
  const urlParams: {
    pageNumber?: number;
    limit?: number;
    sortingKey?: string;
    sortOrder?: string;
    filter?: FilterModel;
  } = {
    limit: params.endRow - params.startRow,
    pageNumber:
      Math.floor(params.startRow / (params.endRow - params.startRow)) + 1,
  };
  if (params.sortModel?.length) {
    urlParams.sortingKey = params.sortModel[0].colId;
    urlParams.sortOrder = params.sortModel[0].sort === "asc" ? "ASC" : "DESC";
  }
  if (params.filterModel) {
    urlParams.filter = Object.keys(params.filterModel).reduce((acc, key) => {
      if (params.filterModel?.[key].filter) {
        acc[key] = params.filterModel[key].filter;
      }
      return acc;
    }, {} as FilterModel);
  }
  return urlParams;
}
