import { useQuery } from "@tanstack/react-query";

import leadService from "@/services/leads.service";
import { LeadSearchType } from "@/types/leads.types";

export const useLeadSearchHistory = (searchType: LeadSearchType) =>
  useQuery({
    queryKey: ["lead-search-history", searchType],
    queryFn: () => leadService.getLeadSearchHistory(searchType),
    refetchOnMount: "always",
  });
