import { Button, IconButton } from "@chakra-ui/react";
import Icons from "../Icons";
import { useNavigate } from "@tanstack/react-router";
import { allTemplates } from "../Leads/UseTemplateModal/constants";

const TemplateDetails = ({ templateId }: { templateId: string }) => {
  const navigate = useNavigate();
  const templateData = allTemplates.find(
    (item) => item.heading === decodeURIComponent(templateId),
  );

  return (
    <div>
      <div className="flex items-center gap-4  border border-[#F0EFF1] bg-white px-[16px] py-[8px]">
        <IconButton
          aria-label="back"
          variant="ghost"
          className="!rounded-full"
          icon={<Icons.ArrowLeftIcon size={24} />}
          onClick={() => navigate({ to: "/playbooks" })}
        />
        <p className="text-[24px] font-bold leading-[32px]">Playbook</p>
      </div>
      {templateData && (
        <div className="my-6 flex gap-4 p-[32px]">
          <div
            className="w-[55%] cursor-pointer rounded-md border border-[#E5E7EB] p-2"
            onClick={() => {
              window.open(templateData?.url, "_blank");
            }}
          >
            <img src={templateData?.image} alt="" />
          </div>
          <div className="flex w-[45%] flex-col gap-4 px-[60px] pt-8">
            <span className="flex flex-row place-items-center gap-4">
              {<templateData.group.icon className="size-16" />}
              <p className="text-left text-[28px] font-bold leading-[40px] text-[#0F0D1C]">
                {templateData?.heading}
              </p>
            </span>
            <p className="text-[18px] text-[#4A5264]">{templateData?.title}</p>
            <Button
              w={"fit-content"}
              className="scale-[1.2] place-self-center !bg-primary-gradient !text-[24px] font-bold !leading-[40px] hover:opacity-80"
              colorScheme="primary"
              borderRadius={"6px"}
              onClick={() => {
                window.open(templateData?.url, "_blank");
              }}
            >
              Try Now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateDetails;
