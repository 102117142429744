import PersonDetails from "./PersonDetails";

const PersonDetailsRenderer = ({ details }: { details: any }) => {
  return (
    <>
      {details.cellDetails?.metaData && (
        <div className="bg-white shadow-lg rounded-lg p-4 mb-4">
          <div className="mb-4">
            <PersonDetails cellDetails={details.cellDetails} />
          </div>
        </div>
      )}
    </>
  );
};

export default PersonDetailsRenderer;
