import APIKeyIntegration from "./APIKeyIntegration";

const DatagmaIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"datagma"}
      providerTitle="Datagma"
      providerIcon={
        "https://persana-assets.s3.us-west-1.amazonaws.com/datagma-logo.png"
      }
    />
  );
};

export default DatagmaIntegration;
