import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { InputtedData } from "../store";
import PersanaInput from "@/components/Common/PersanaInput";

interface Props {
  inputtedData: InputtedData;
  onClick: (target: string) => void;
}

const Educations = ({ inputtedData, onClick }: Props) => {
  const id = useSearchLeadsStore((state) => state.instanceId);
  const genId = (field: string) => `${id}-${field}`;

  return (
    <>
      <PersanaInput
        id={genId("educations")}
        value={inputtedData?.educations}
        placeholder={"e.g. Harvard University"}
        name={"educations"}
        label={"Educations"}
        showDropdown
        arrayOfValues={[]}
        onClick={onClick}
      />
    </>
  );
};

export default Educations;
