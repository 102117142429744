import { createStore, StoreApi } from "zustand";
import { GridApi } from "ag-grid-community";
import { createContext, useContext } from "react";
import { SelectionState, SelectionRange, Cell, Overlay } from "./types";
import { userStore } from "@/stores/user.store";
import { useStoreWithEqualityFn } from "zustand/traditional";
import _ from "lodash";

type TSelector<R> = (state: SelectionState) => R;
const defaultOverlay = { left: 0, top: 0, width: 0, height: 0 };

export const SelectionContext = createContext<StoreApi<SelectionState> | null>(
  null,
);

export const useSelectionStore = <R = SelectionState>(
  selector: TSelector<R> = (state) => state as R,
) => {
  const store = useContext(SelectionContext);
  return useStoreWithEqualityFn(
    store as unknown as StoreApi<SelectionState>,
    selector,
    (a, b) => _.isEqual(a, b),
  );
};
export const createSelectionStore = () =>
  createStore<SelectionState>((set, _, api) => {
    const getFeatures = () => {
      const state = userStore.getState();
      const currentUser = state.currentUser;
      const isUserPaid =
        currentUser?.planType?.toLowerCase() !== "free" ||
        currentUser.role === "admin";
      const features = {
        copy: isUserPaid,
        paste: isUserPaid,
        select: isUserPaid,
        delete: true,
      };
      return features;
    };
    const unSubscribe = userStore.subscribe(() =>
      set({ features: getFeatures() }),
    );
    return {
      gridApi: null,
      range: null,
      isSelecting: false,
      overlay: null,
      features: getFeatures(),
      store: api,
      setGridApi: (gridApi) => set({ gridApi }),
      setZeroRange: () => {
        set((state) => {
          const focusedCell = state.gridApi?.getFocusedCell();
          const startCell: Cell = state.range
            ? state.range.startCell
            : {
                colId: focusedCell?.column.getColId() ?? "",
                rowIndex: focusedCell?.rowIndex ?? 0,
              };
          state.gridApi?.setFocusedCell(startCell.rowIndex, startCell.colId);
          state.setRange({ startCell, endCell: startCell });
          return { isSelecting: false };
        });
      },
      setRange: (range) => {
        set((state) => {
          const gridId = state.gridApi?.getGridId();
          const overlay = deriveOverlay(range, gridId);
          return { range, overlay };
        });
      },
      setIsSelecting: (isSelecting) => set({ isSelecting }),
      clearSelection: () => set({ range: null, isSelecting: false }),
      setState: set,
      destroy: () => unSubscribe(),
    };
  });

function deriveOverlay(
  range: SelectionRange | null,
  gridId?: string,
): Overlay | null {
  if (!range) return defaultOverlay;

  const { startCell, endCell } = range;
  const [startCellPosition, endCellPosition] = [
    getCellPosition(startCell, gridId),
    getCellPosition(endCell, gridId),
  ];
  if (!startCellPosition || !endCellPosition) return defaultOverlay;
  const left = Math.min(startCellPosition.left, endCellPosition.left);
  const top = Math.min(startCellPosition.top, endCellPosition.top);
  const right = Math.max(startCellPosition.right, endCellPosition.right);
  const bottom = Math.max(startCellPosition.bottom, endCellPosition.bottom);
  const width = right - left;
  const height = bottom - top;
  return { top, left, width, height };
}
export const getGridRoot = (gridId?: string) => {
  return gridId
    ? document.querySelector(`.ag-root-wrapper[grid-id="${gridId}"]`) || null
    : null;
};
function getCellPosition(cell: Cell, gridId?: string) {
  const gridRoot = getGridRoot(gridId);
  if (!gridRoot) return null;
  const baseElementRect =
    gridRoot
      .querySelector(`.ag-center-cols-container`)
      ?.getBoundingClientRect() ?? null;
  const cellElementRect =
    gridRoot
      .querySelector(
        `.ag-row[row-index="${cell.rowIndex}"] > .ag-cell[col-id="${cell.colId}"]`,
      )
      ?.getBoundingClientRect() || null;
  if (!baseElementRect || !cellElementRect) return null;
  const relativePosition = {
    left: cellElementRect.x - baseElementRect.x,
    top: cellElementRect.y - baseElementRect.y,
    right: cellElementRect.x - baseElementRect.x + cellElementRect.width,
    bottom: cellElementRect.y - baseElementRect.y + cellElementRect.height,
  };
  return relativePosition;
}

export const getFirstCell = (
  range: SelectionRange | null,
  gridApi: GridApi,
): Cell | null => {
  if (!range) return null;
  const firstColId = [range.startCell.colId, range.endCell.colId].sort(
    (a, b) => {
      const aIndex =
        gridApi.getColumns()?.findIndex((col) => col.getColId() === a) ?? -1;
      const bIndex =
        gridApi.getColumns()?.findIndex((col) => col.getColId() === b) ?? -1;
      return aIndex - bIndex;
    },
  )[0];
  const firstRowIndex = Math.min(
    range.startCell.rowIndex,
    range.endCell.rowIndex,
  );
  return {
    colId: firstColId,
    rowIndex: firstRowIndex,
  };
};
