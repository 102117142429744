import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import userService from "@/services/user.service";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";
import CustomAccordion from "@/components/Common/CustomAccordion";
import {
  Badge,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import Icons from "@/components/Icons";
import Swal from "sweetalert2";
import TopupModal from "./TopupModal";

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

function PlansAndBilling() {
  const [userCreditsLeft, setUserCreditsLeft] = useState(0);

  const [nextCycleDate, setNextCycleDate] = useState<string | null>(null);
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);

  const creditDetails = userStore((state) => state.creditDetails);
  const subscriptionDetails = userStore((state) => state.subscriptionDetails);
  const updateModalState = useModalStore((state) => state.updateState);

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: cancelScheduledActionMutateAsync } = useMutation({
    mutationFn: async () => {
      return userService.cancelScheduledAction();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user-subscription-details"],
      });

      toast({
        title: "Scheduled action cancelled successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Failed to cancel scheduled action!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const cancelScheduledAction = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel the scheduled action?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "No, keep it",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await cancelScheduledActionMutateAsync();
      },
    });
  };

  const handleCancelSubscription = async () => {
    Swal.fire({
      title: "Cancel Subscription",
      html: 'Reach out to <a href="mailto:contact@persana.ai" style="color: #0284fe;">contact@persana.ai</a> to do the cancellation',
      icon: "info",
      confirmButtonText: "OK",
    });
  };

  useEffect(() => {
    if (creditDetails?.creditLeft != null) {
      const creditLeft = Math.max(
        0,
        (creditDetails?.creditLeft || 0) - (creditDetails?.creditUsed || 0),
      );

      setUserCreditsLeft(creditLeft);
    }
  }, [creditDetails]);

  useEffect(() => {
    if (subscriptionDetails) {
      const formattedDate = subscriptionDetails?.nextCycleDate
        ? dayjs
            .unix(subscriptionDetails?.nextCycleDate)
            .tz("America/Los_Angeles")
            .format("MMM DD, YYYY h:mm A [PST]")
        : "N/A";

      setNextCycleDate(formattedDate);
    }
  }, [subscriptionDetails]);

  return (
    <>
      <div className="mx-auto max-w-[50rem] space-y-6 pt-10 font-title">
        <div>
          <h3 className="text-[1.35rem] font-bold">Plans & Billing</h3>
          <div className="mt-1 h-[1.5px] w-full rounded-full bg-[#e6e8ec]"></div>
        </div>

        <div className="space-y-2.5 rounded-md border border-[#e6e8ec] p-4 shadow-sm">
          <div className="flex items-center justify-between">
            <p className="space-x-1.5 text-xl font-bold capitalize">
              <span>
                {" "}
                {subscriptionDetails?.scheduledAction?.oldPlan
                  ? subscriptionDetails?.scheduledAction?.oldPlan?.metadata
                      ?.planType
                  : subscriptionDetails?.currentPlan?.planType || "Free"}{" "}
              </span>
              {subscriptionDetails?.scheduledAction?.oldPlan && (
                <Badge
                  fontSize="12px"
                  colorScheme={
                    subscriptionDetails?.scheduledAction?.action === "downgrade"
                      ? "red"
                      : "green"
                  }
                  lineHeight={1}
                  paddingY={"4px"}
                  paddingX={"8px"}
                  borderRadius={"999px"}
                  textTransform={"none"}
                >
                  scheduled for{" "}
                  {subscriptionDetails?.scheduledAction?.action || ""}
                </Badge>
              )}
            </p>
            <div className="flex items-center gap-x-3">
              {subscriptionDetails?.scheduledAction?.oldPlan ? (
                <button
                  className="rounded bg-red-600 px-[10px] py-[3px] text-[0.8rem] text-white"
                  onClick={cancelScheduledAction}
                >
                  Cancel {subscriptionDetails?.scheduledAction?.action}
                </button>
              ) : (
                <>
                  {subscriptionDetails?.currentPlan?.planType && (
                    <button
                      className="btn-primary-outline rounded px-[10px] py-[3px] text-[0.8rem] duration-300"
                      onClick={() => setIsPopupModalOpen(true)}
                    >
                      Top up credits
                    </button>
                  )}
                  <button
                    onClick={() => {
                      updateModalState({
                        isOpenModal: true,
                        modalData: {
                          modalType: "pricing",
                          action: "change",
                        },
                      });
                    }}
                    className="gradientBg rounded px-[10px] py-[3px] text-[0.8rem] text-white"
                  >
                    Change plan
                  </button>
                  {subscriptionDetails?.currentPlan?.planType && (
                    <button
                      className="rounded bg-red-600 px-[10px] py-[3px] text-[0.8rem] text-white duration-300"
                      onClick={handleCancelSubscription}
                    >
                      Cancel Subscription
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {creditDetails?.creditUsed != null &&
          creditDetails?.creditLeft != null ? (
            <div className="h-2 w-full rounded-full bg-[#E4E9ED]">
              <div
                className="h-2 rounded-full bg-[#0284FE]"
                style={{
                  width: `${
                    (creditDetails?.creditUsed / creditDetails?.creditLeft) *
                    100
                  }%`,
                }}
              ></div>
            </div>
          ) : null}

          <p className="text-xs text-[#525a69]">
            {userCreditsLeft?.toLocaleString("en-US")} Persana credits left out
            of {creditDetails?.creditLeft?.toLocaleString("en-US")} available
            per month.
          </p>
        </div>

        <div className="space-y-1 text-sm text-[#16181F]">
          <p>
            The next payment of{" "}
            <span className="font-bold">
              ${subscriptionDetails?.nextCycleAmount || 0}
            </span>{" "}
            will be charged on{" "}
            <span className="font-bold">{nextCycleDate}</span>
          </p>
          <p>The billing email is {subscriptionDetails?.billingEmail}</p>
        </div>

        <div>
          <CustomAccordion
            title={`INVOICES & RECEIPTS (${subscriptionDetails?.invoices?.length || 0})`}
          >
            <div>
              <Table size="sm">
                <Thead position={"sticky"} top={0} bg={"white"}>
                  <Tr>
                    <Th>DATE</Th>
                    <Th>AMMOUNT</Th>
                    <Th>STATUS</Th>
                    <Th>DOWNLOAD</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {subscriptionDetails?.invoices &&
                  subscriptionDetails?.invoices?.length < 1 ? (
                    <Tr>
                      <Td className="!text-left">
                        No invoices or receipts found...
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                  ) : (
                    subscriptionDetails?.invoices?.map((invoice, index) => (
                      <Tr key={`subscription-invoices-${index}`}>
                        <Td className="!text-left">
                          {dayjs.unix(invoice.date).format("MM/DD/YY")}
                        </Td>
                        <Td className="!text-left">${invoice?.amount}</Td>
                        <Td className="!text-left">{invoice?.status}</Td>
                        <Td className="!pl-[2.55rem] !text-left">
                          {
                            <a href={invoice?.downloadLink}>
                              <Icons.DownloadIcon className="cursor-pointer text-base duration-200 hover:text-primary" />
                            </a>
                          }
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </div>
          </CustomAccordion>
        </div>
      </div>
      {isPopupModalOpen && (
        <TopupModal
          isOpenModal={isPopupModalOpen}
          setIsModalOpen={() => {
            setIsPopupModalOpen(!isPopupModalOpen);
          }}
          currentPlan={{
            ...subscriptionDetails?.currentPlan,
            amount: subscriptionDetails?.nextCycleAmount,
          }}
        />
      )}
    </>
  );
}

export default PlansAndBilling;
