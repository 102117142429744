import { useRef } from "react";
import { GridApi } from "ag-grid-community";
import { useToast } from "@chakra-ui/react";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { BulkRowUpdatePayload } from "@/types/table.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectionStore } from "./useSelectionStore";

export const useBulkUpdate = () => {
  const toast = useToast({
    position: "top-right",
  });
  const gridApiRef = useRef<GridApi | null>(null);
  gridApiRef.current = useSelectionStore((state) => state.gridApi);
  const updateState = useTableStore((state) => state.updateState);
  const rowsData = useTableStore((state) => state.rowsData);
  const tableData = useTableStore((state) => state.tableData);
  const isAutoRunEnabled = tableData.metaData?.isEnableAutoRun;
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: BulkRowUpdatePayload[] }) => {
      const newRowData = rowsData.map((row) => {
        const newRow = { ...row };
        payload.forEach((data) => {
          if (data.rowId === row._id) {
            newRow.cells = {
              ...newRow.cells,
              ...(data.columnId
                ? {
                    [data.columnId]: {
                      ...newRow.cells[data.columnId],
                      value: data.cellValue?.value ?? "",
                    },
                  }
                : {}),
            };
          }
        });
        return newRow;
      });
      updateState({ rowsData: newRowData });
      const promise = tableService.updateBulkRowCells({
        tableId: tableData._id,
        payload,
      });
      toast.promise(promise, {
        loading: {
          title: "Updating rows...",
          isClosable: true,
        },
        success: {
          title: "Success",
          description: "Rows updated successfully",
          isClosable: true,
        },
        error: {
          title: "Error",
          description: "Something went wrong",
          isClosable: true,
        },
      });
      return promise;
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: "Error",
          description: response.error?.message || "Something went wrong",
          status: "error",
          isClosable: true,
          duration: 5000,
        });
        updateState({
          rowsData: rowsData,
        });
        return;
      }

      if (isAutoRunEnabled) {
        const updatedRowsData = rowsData.map((row) => {
          if (!response.data?.rowIds?.includes(row._id)) return row;
          response?.data?.dependentColumnsIds?.forEach((columnId: string) => {
            if (!row.cells[columnId]) {
              row.cells[columnId] = {
                value: "queued...",
              };
            } else {
              row.cells[columnId].value = "queued...";
            }
          });
          return row;
        });

        updateState({
          rowsData: updatedRowsData,
        });

        gridApiRef.current?.refreshCells({ force: true });

        if (response?.data?.dependentColumnsIds?.length) {
          queryClient.refetchQueries({
            queryKey: ["table-running-processes", tableData._id],
          });
        }
      }
    },
    onError: (error) => {
      console.error(error);
      updateState({
        rowsData: rowsData,
      });
    },
  });
  return mutateAsync;
};
