import { Option } from "@/components/Common/MultiSelectDropdown/types";
export enum LeadSearchType {
  COMPANIES_HIRING = "companies-hiring",
}

export type LeadSearchHistoryData = {
  _id: string;
  user: string;
  updatedAt: string;
  searchTime: string;
  searchType: LeadSearchType;
  metaData: any;
};
export type LeadType = "jobChanges";
export type CRMType = "hubspot";

export type StarterListType = {
  _id: string;
  name: string;
  description: string;
  isPremium: boolean;
};

export type SearchCompanyType = {
  page?: number;
  per_page?: number;
  organization_num_employees_ranges?: string[]; // ["1,100", "1,1000"]
  organization_locations?: string[]; // ["United States"]
  q_organization_keyword_tags?: string[]; // ["sales strategy", "lead"]
  q_organization_name?: string; // "Apollo.io"
};

export type IScraperCompanySearchPayload = {
  per_page?: number;
  offset?: number;
  keyword?: string;
  followers?: string;
  industries?: string[];
  country_codes?: string[];
  company_size?: string;
  year_founded?: string;
  company_type?: string;
  website?: string;
};

export type SearchPeopleType = {
  person_titles: string[];
  person_not_titles: string[];
  person_past_titles: string[];
  person_seniorities: string[];
  q_person_name: string;
  organization_ids: string[];
  not_organization_ids: string[];
  person_past_organization_ids: string[];
  organization_industry_tag_ids: string[];
  organization_num_employees_ranges: string[];
  q_organization_keyword_tags: string[];
  q_not_organization_keyword_tags: string[];
  person_locations: string[];
  person_not_locations: string[];
  person_location_name: string;
  person_location_radius: string;
  page?: number;
  sendSlackNotifications?: boolean;
  storagePlatforms?: Option[];
};

export type SearchCompanyResponseType = {
  data: {
    companies: any[];
    pagination: {
      page: number;
      per_page: number;
      total_entries: number;
      total_pages: number;
    };
  };
};

export type SearchLeadsResponseType = {
  data: {
    profiles: any[];
    pagination: {
      page: number;
      per_page: number;
      total_entries: number;
      total_pages: number;
    };
    naturalFilters: {
      natural_query: string;
      query_title: string;
    };
    cacheKey: string;
    payload: object;
    providerName: string;
    columsMapping: any[];
    tableData?: any;
    isUseMixrank?: boolean;
  };
};

export type AdditionalProperties = {
  hs_last_record_added_at: string;
  hs_list_size: string;
  hs_list_reference_count: string;
  hs_last_record_removed_at?: string;
};

export type List = {
  listId: string;
  listVersion: number;
  createdAt: string;
  updatedAt: string;
  filtersUpdatedAt: string;
  processingStatus: string;
  createdById: string;
  processingType: string;
  objectTypeId: string;
  name: string;
  additionalProperties: AdditionalProperties;
  updatedById?: string;
};

export type Data = {
  offset: number;
  hasMore: boolean;
  lists: List[];
  total: number;
};

export type HubspotListResponseDataType = Data;
