import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

import {
  TopEnrichmentTypes,
  categories,
} from "@/components/Enrichment/constants";
import { claudeModel, gptVersion } from "@/types/enrichment.types";
import { Column } from "@/types/table.types";
import { ProviderNameType } from "./provider.store";

export type CategoryType = (typeof categories)[number]["name"];

type State = {
  activeCategory: CategoryType | "";
  searchInput: string;
  selectedEnrichments: TopEnrichmentTypes | null;
  selectedColumnToEdit: Column | null;
  isOpenEnrichModal: boolean;
  generateFormulaModal: {
    isOpen: boolean;
    type: "formula" | "condition" | "edit" | null;
  };
  examples: {
    input?: string;
    output?: string;
  }[];
  keySelect: "persanaApiKey" | "ownApiKey";
  gptVersion: gptVersion;
  claudeModel: claudeModel;
  viewMode?: "edit" | "viewOnly" | "create";
  runConditionFormula?: any;
  editedFormula?: any;
  formulaPrompt?: string;
  isInvalidConditionFormula: boolean;
  ownKeyProviderList?: ProviderNameType[];
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
  clearState: () => void;
};

const DEFAULT_STATE: State = {
  activeCategory: "All Enrichments",
  searchInput: "",
  selectedEnrichments: null,
  selectedColumnToEdit: null,
  isOpenEnrichModal: false,
  generateFormulaModal: {
    isOpen: false,
    type: "formula",
  },
  isInvalidConditionFormula: false,
  editedFormula: "",
  formulaPrompt: "",
  runConditionFormula: null,
  examples: [
    {
      input: "",
      output: "",
    },
  ],
  viewMode: "create",
  keySelect: "persanaApiKey",
  gptVersion: "gpt-4o-mini",
  claudeModel: "claude-3-opus-20240229",
  ownKeyProviderList: [],
};

export const useEnrichStore = create<State & Actions>((set) => ({
  ...DEFAULT_STATE,
  updateState: (payload) => set(() => payload),
  clearState: () =>
    set(() => ({
      ...DEFAULT_STATE,
    })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Enrich", useEnrichStore);
}
