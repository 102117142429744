import { useEffect, useState } from "react";

import bulbSvg from "@/assets/svgs/bulb.svg";
import closeSvg from "@/assets/svgs/close.svg";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";

const FreeTrialBanner = () => {
  const updateState = useModalStore((state) => state.updateState);
  const [creditsLeft, setCreditsLeft] = useState<number | null>(0);
  const creditDetails = userStore((state) => state.creditDetails);
  const isUpgradeBannerShown = userStore((state) =>
    state.isUpgradeBannerShown(),
  );
  const hideUpgradeBanner = userStore((state) => state.hideUpgradeBanner);

  useEffect(() => {
    const creditLeft = Math.max(
      0,
      (creditDetails?.creditLeft || 0) - (creditDetails?.creditUsed || 0),
    );

    setCreditsLeft(creditLeft);
  }, [creditDetails]);

  if (!isUpgradeBannerShown) {
    return null;
  }

  return (
    <>
      <div className="sticky top-0 z-10 flex items-center justify-between border border-borderGray bg-borderGray px-[32px] py-[6px]">
        <div className="flex items-center gap-[24px] text-textBlack">
          <div className="rounded-[4px] bg-lilac px-[8px] py-[2px]">
            <p className="text-[14px]">
              <span className="font-bold">{creditsLeft}</span> credits left
            </p>
          </div>
          <div className="flex items-center gap-[6px]">
            <img src={bulbSvg} alt="" width={16} height={16} />
            <p className="text-[14px]">
              {"Upgrade now to unlock all of Persana"}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-[16px]">
          <div
            onClick={() => {
              updateState({
                isOpenModal: true,
                modalData: { modalType: "pricing" },
              });
            }}
            className="flex cursor-pointer items-center justify-center rounded-[6px] border border-[#BD90F4] bg-[#F5F3FF] px-[12px] py-[4px] hover:shadow-md"
          >
            <p className="text-[14px] font-medium text-[#5024DC]">Upgrade</p>
          </div>
          <img
            src={closeSvg}
            alt=""
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={() => hideUpgradeBanner()}
          />
        </div>
      </div>
    </>
  );
};

export default FreeTrialBanner;
