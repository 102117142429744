import { twMerge } from "tailwind-merge"


function Description({ children , className }: { children: string | React.ReactNode, className?: string }) {
    return (
        <p className={twMerge("text-xs font-title font-semibold tracking-wide pt-1 pl-0.5 text-darkTextGray/90" , className)}>
            {children}
        </p>
    )
}

export default Description
