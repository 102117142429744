import { Column, FilterType } from "@/types/table.types";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";

interface Props {
  columns: Column[];
  filter: FilterType;
}

const ColumnList = ({ columns, filter }: Props) => {
  const updateState = useTableStore((state) => state.updateState);
  const tableFilters = useTableStore((state) => state.tableFilters);

  const hanleClick = (column: Column) => {
    updateState({
      tableFilters: {
        ...tableFilters,
        filters: tableFilters.filters?.map((f) => {
          if (f.id === filter.id) {
            return {
              ...f,
              column,
            };
          }
          return f;
        }),
      },
    });
  };

  return (
    <Popover placement="right-start">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <button className="flex w-full grow items-center justify-between gap-1 rounded bg-[#e7e8ec] px-2 py-1 text-sm font-medium text-[#000000]/90 duration-300 hover:bg-[#ECEEF1]">
              <span className="truncate">{filter.column.name}</span>
              <Icons.ArrowDownIcon
                className={`text-sm text-[#000000] duration-300${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </button>
          </PopoverTrigger>
          <PopoverContent
            width="fit-content"
            className="max-h-[300px] overflow-y-auto"
          >
            <PopoverArrow />
            <PopoverBody className="flex w-fit max-w-[300px] flex-col ">
              {columns.map((column, index) => (
                <button
                  key={`${column._id}-${filter.column._id}-${index}`}
                  className={`truncate rounded-md px-2 py-1.5 text-start text-[13.75px] text-[#000000]/90 duration-300 hover:bg-[#e7e8ec] ${filter.column._id === column._id ? "bg-[#e7e8ec]" : ""}`}
                  onClick={() => {
                    hanleClick(column);
                    onClose();
                  }}
                >
                  {column.name}
                </button>
              ))}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default ColumnList;
