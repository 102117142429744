import { useEffect, useCallback } from "react";
import { CellFocusedEvent } from "ag-grid-community";
import { useSelectionStore } from "./useSelectionStore";
import { getCellId } from "./utils";

export const useSelection = () => {
  useSelectAll();
  const selectionStore = useSelectionStore((state) => state.store);
  const { setRange, setZeroRange, setIsSelecting, gridApi, features } =
    useSelectionStore();

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      const isInsideCell = !!getCellId(event.target as HTMLElement);
      if (!isInsideCell) {
        setRange(null);
        return;
      }
      const { isSelecting } = selectionStore.getState();
      if (!isSelecting) {
        setRange(null);
      }
      setIsSelecting(true);
    },
    [selectionStore, setIsSelecting, setRange],
  );
  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (event.buttons !== 1) return;
      const { isSelecting } = selectionStore.getState();
      if (!isSelecting) return;
      const { rowIndex, colId } = getCellId(event.target as HTMLElement) ?? {};
      if (!colId || !rowIndex) return;
      gridApi?.setFocusedCell(+rowIndex, colId);
    },
    [gridApi, selectionStore],
  );
  const handleCellFocus = useCallback(
    (params: CellFocusedEvent) => {
      const { range, isSelecting } = selectionStore.getState();
      const focusedCell = params.api.getFocusedCell();
      if (!focusedCell) return;

      if (isSelecting && range) {
        setRange({
          ...range,
          endCell: {
            rowIndex: focusedCell.rowIndex,
            colId: focusedCell.column.getId(),
          },
        });
      } else {
        setRange({
          startCell: {
            rowIndex: focusedCell.rowIndex,
            colId: focusedCell.column.getId(),
          },
          endCell: {
            rowIndex: focusedCell.rowIndex,
            colId: focusedCell.column.getId(),
          },
        });
      }
    },
    [selectionStore, setRange],
  );

  const handleMouseUp = useCallback(() => {
    setIsSelecting(false);
  }, [setIsSelecting]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsSelecting(true);
      } else if (event.key === "Escape") {
        setZeroRange();
      }
    },
    [setIsSelecting, setZeroRange],
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsSelecting(false);
      }
    },
    [setIsSelecting],
  );
  useEffect(() => {
    if (!features.select) return;
    gridApi?.addEventListener("cellFocused", handleCellFocus);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      gridApi?.removeEventListener("cellFocused", handleCellFocus);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [
    gridApi,
    features,
    handleKeyDown,
    handleKeyUp,
    handleMouseUp,
    handleCellFocus,
    handleMouseDown,
    handleMouseMove,
  ]);
};

export const useSelectAll = () => {
  const { setRange, gridApi } = useSelectionStore();
  useEffect(() => {
    const handleSelectAll = (e: KeyboardEvent) => {
      if (!((e.ctrlKey || e.metaKey) && e.key === "a")) return;
      if (
        (e.target as HTMLElement)?.closest(".ag-cell") ||
        (e.target as HTMLElement)?.classList?.contains("ag-cell")
      ) {
        const firstRowIndex = gridApi?.getFirstDisplayedRowIndex() ?? 0;
        const lastRowIndex = gridApi?.getLastDisplayedRowIndex() ?? 0;

        const columns = gridApi?.getDisplayedCenterColumns();
        const firstColId = columns?.at(0)?.getColId();
        const lastColId = columns?.at(-2)?.getColId();

        setRange({
          startCell: {
            rowIndex: firstRowIndex,
            colId: firstColId!,
          },
          endCell: {
            rowIndex: lastRowIndex,
            colId: lastColId!,
          },
        });
      }
    };

    document.addEventListener("keyup", handleSelectAll);
    return () => {
      document.removeEventListener("keyup", handleSelectAll);
    };
  }, [gridApi]);
};
