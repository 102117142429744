import { FormEvent, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
  Text,
  InputGroup,
  IconButton,
  InputRightElement,
  Heading,
  Divider,
} from "@chakra-ui/react";
import useGetUserDetailsForAdmins from "./hooks/useGetUserDetailsForAdmins";
import ApiKeysGrid from "./list";
import { isValidEmail } from "@/lib/utils";
import Icons from "../Icons";

const AdminApiKeyManager = () => {
  const [email, setEmail] = useState("");
  const [searchedEmail, setSearchedEmail] = useState("");
  const toast = useToast();

  const { data: userDetails, isLoading: isLoadingUser } =
    useGetUserDetailsForAdmins(searchedEmail);

  const handleSearchUser = (e: FormEvent) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      toast({
        title: "Please enter a valid email address.",
        status: "error",
        duration: 2000,
      });
      return;
    }
    setSearchedEmail(email);
  };

  return (
    <form className="p-8" onSubmit={handleSearchUser}>
      <FormControl id="userEmail" mb={4}>
        <FormLabel>Search User by Email</FormLabel>
        <InputGroup>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter user email"
          />
          <InputRightElement>
            <IconButton
              type="submit"
              isDisabled={!email}
              variant={"ghost"}
              size={"sm"}
              icon={<Icons.SearchIcon />}
              onClick={handleSearchUser}
              colorScheme="purple"
              aria-label={""}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Divider />
      {/* Display User Details */}
      {isLoadingUser ? (
        <Spinner />
      ) : userDetails ? (
        <Box className="flex flex-row items-center gap-2">
          <Heading>
            {userDetails?.data?.profileInfo?.firstName}{" "}
            {userDetails?.data?.profileInfo?.lastName}
          </Heading>
          <Text>{userDetails?.data?.Email}</Text>
          <Text className="text-[#677084]">
            {userDetails?.data?.profileInfo?.companyURL}
          </Text>
        </Box>
      ) : null}
      <Divider />
      <Box className="mt-4">
        {userDetails?.data?._id && <ApiKeysGrid user={userDetails?.data} />}
      </Box>
    </form>
  );
};

export default AdminApiKeyManager;
