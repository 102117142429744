import { Box, Checkbox, Spinner, Tooltip } from "@chakra-ui/react";
import Icons from "@/components/Icons";

type CheckboxFullProps = {
  setValue: (value: boolean) => void;
  value: boolean;
  label: string;
  tooltipText?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const CheckboxFull = ({
  setValue,
  value,
  label,
  tooltipText,
  isLoading,
  isDisabled,
}: CheckboxFullProps) => {
  return (
    <Box
      pointerEvents={isDisabled ? "none" : "auto"}
      className="flex select-none flex-wrap items-center rounded-lg bg-white py-3 pl-4 pr-1.5 shadow-sm xl:max-w-none"
    >
      {isLoading ? (
        <Spinner size={"sm"} className="mr-2" />
      ) : (
        <Checkbox
          id="checkboxFull"
          name="checkboxFull"
          className="border-hover-strong focus:ring-hover mr-2 text-primary"
          onChange={(e) => setValue(e.currentTarget.checked)}
          isChecked={value}
        />
      )}
      <label htmlFor="checkboxFull" className="text-blue mr-1 font-medium">
        {label}
      </label>
      {tooltipText && (
        <Tooltip
          className="ml-auto inline-flex rounded-lg"
          label={
            <div className="w-64 rounded-lg p-3 sm:w-96">
              <p>{tooltipText}</p>
            </div>
          }
        >
          <div>
            <Icons.InformationIcon className="size-4" />
          </div>
        </Tooltip>
      )}
    </Box>
  );
};
