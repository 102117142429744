import { ButtonGroup, IconButton, Tooltip } from "@chakra-ui/react";
import { IApiKeyEditorProps } from "../types";
import Icons from "@/components/Icons";
import { ProviderNameType, useProviderStore } from "@/stores/provider.store";
import { useWaterfallStore } from "../store";
import { useProviderList } from "@/hooks/useProviderData";
import { useEnrichStore } from "@/stores/enrich.store";
import { useSetKeyPreferred } from "../hooks/useSetKeyPrefered";

export const ApiKeySelector = ({
  providerOption,
  ...props
}: IApiKeyEditorProps) => {
  const updateProviderState = useProviderStore((state) => state.updateState);
  const { data: providerLists } = useProviderList();
  const setUserKey = useWaterfallStore((state) => state.setUserKey);
  const provider = useWaterfallStore((state) =>
    state.selectedProviders.find((p) => p.value === providerOption?.value),
  );
  const setKeyPreference = useSetKeyPreferred();
  const isUsingUserKey = useEnrichStore((state) =>
    state.ownKeyProviderList?.includes(
      provider?.apikeyParams.keyName as ProviderNameType,
    ),
  );

  const isPersanaKeyEnabled = provider?.apikeyParams.enablePersanaApiKey;
  const isUserKeyEnabled = provider?.apikeyParams.enableUserApiKey;
  const hasApiKey = !!providerLists?.data?.find(
    (providerItem) => provider?.apikeyParams.keyName === providerItem?.name,
  )?.metaData?.accessToken;
  if (!provider) return null;
  return (
    <span
      onMouseDown={(e) => e.stopPropagation()}
      onKeyDownCapture={(e) =>
        !(e.key === "Escape" || e.key === "Enter") && e.stopPropagation()
      }
    >
      <ButtonGroup isAttached {...props}>
        <Tooltip
          hasArrow
          label={
            !hasApiKey
              ? "Add your API key"
              : isUsingUserKey
                ? "Update your API key"
                : "Use your own API key"
          }
        >
          <IconButton
            isDisabled={!isUserKeyEnabled}
            aria-label="api-key"
            size="sm"
            className="relative hover:!bg-white"
            // size={15}
            color={isUsingUserKey ? "#AA6BFA" : "#dbe0e6"}
            variant={"ghost"}
            onClick={(e) => {
              e.stopPropagation();
              if (isUsingUserKey || !hasApiKey) {
                updateProviderState({
                  isProviderApiKeyModalOpen: true,
                  providerApiKeyModalData: {
                    title: provider?.label as string,
                    providerName: provider?.apikeyParams
                      .keyName as ProviderNameType,
                    ...(provider?.apikeyParams.extraKeyField && {
                      extraKeyField: provider?.apikeyParams.extraKeyField,
                    }),
                  },
                });
              }
              setUserKey(true, provider.apikeyParams.keyName);
              setKeyPreference({
                preferKey: true,
                keyName: provider.apikeyParams.keyName as ProviderNameType,
              });
            }}
            icon={<Icons.KeyIcon />}
          />
        </Tooltip>
        <Tooltip
          hasArrow
          label={
            isPersanaKeyEnabled
              ? "Use Persana Credits"
              : "Persana Credits disabled. "
          }
        >
          <IconButton
            isDisabled={!isPersanaKeyEnabled}
            size="sm"
            onClick={() => {
              setUserKey(false, provider.apikeyParams.keyName);
              setKeyPreference({
                preferKey: false,
                keyName: provider.apikeyParams.keyName as ProviderNameType,
              });
            }}
            variant={"ghost"}
            color={!isUsingUserKey ? "#2bbf71" : "#dbe0e6"}
            className="flex flex-row items-center justify-center gap-[4px] rounded-[4px] px-[4px] py-[2px] text-[12px] font-[500] uppercase hover:!bg-white"
            aria-label="use-persana-api"
            icon={
              <>
                {provider?.credits || 1}
                <Icons.CoinsIcon className="text-sm" />
              </>
            }
          />
        </Tooltip>
      </ButtonGroup>
    </span>
  );
};
