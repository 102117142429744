import React from "react";
import ConfigStatus from "./ConfigStatus";

interface CardProps {
  rightIcon?: string;
  title: string;
  subtitle: string;
  isConfigured: boolean;
  isDisabled: boolean;
  configStatus: string;
  imgSrc: string;
  isActive: boolean;
}

const CustomNodeCard: React.FC<CardProps> = ({
  rightIcon,
  title,
  subtitle,
  isDisabled,
  isConfigured,
  configStatus,
  imgSrc,
  isActive,
}) => {
  return (
    <div
      className={`w-[330px] rounded-[8px] border text-center ${isDisabled && "pointer-events-none opacity-50"} ${isActive ? "border-[#5024DC]" : isConfigured ? "border-[#078A52]" : "border-[#D0D4DC]"}`}
    >
      <div className="flex items-center justify-between px-[16px] py-[12px]">
        <div className="flex items-center gap-5 text-center">
          <img src={imgSrc} alt="" className="size-[40px]" />
          <div>
            <p className="grow truncate text-[16px] font-bold">{title}</p>
            <p className="text-[14px] font-medium text-[#7E889F]">{subtitle}</p>
          </div>
        </div>
        {rightIcon && <img src={rightIcon} alt="" className="size-[24px]" />}
      </div>
      <ConfigStatus
        isConfigured={isConfigured}
        title={isConfigured ? configStatus : "Not configured"}
      />
    </div>
  );
};

export default CustomNodeCard;
