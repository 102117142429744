import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";

import Icons from "../Icons";
import { useEnrichStore } from "@/stores/enrich.store";

interface Props {
  subText: string;
  link: string;
  credits?: number;
}

const WatchTutorial = ({ subText, link, credits }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );

  return (
    <div className="mb-3">
      <div className="flex items-center justify-between">
        <p className="text-sm font-medium text-[#525a69]">{subText}</p>
        <div className="flex items-center gap-2 rounded-full bg-green-100 px-3 py-1.5 text-green-600">
          <span className="text-xs font-semibold uppercase">
            {credits ?? selectedEnrichments?.credits}
          </span>
          <Icons.CoinsIcon className="text-sm" />
        </div>
      </div>
      {link && (
        <button
          className={`flex items-center gap-x-2 rounded-full  px-2.5 py-1 text-xs font-medium text-white ${
            link
              ? "cursor-pointer bg-primary"
              : "cursor-not-allowed bg-lightGray"
          }`}
          onClick={() => {
            if (link) {
              setIsOpen(true);
            }
          }}
        >
          <Icons.PlayIcon />
          <span>Watch Tutorial</span>
        </button>
      )}

      <Modal
        isCentered
        isOpen={isOpen}
        size="3xl"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.3)" />
        <ModalContent borderRadius="lg">
          <ModalCloseButton />
          <ModalBody p={0}>
            <iframe
              src={`${link}?autoplay=1&mute=1`}
              title="YouTube video player"
              className="h-[500px] w-full"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default React.memo(WatchTutorial);
