import APIKeyIntegration from "./APIKeyIntegration";

const LGMIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"laGrowthMachineKey"}
      providerTitle="La Growth Machine"
      providerIcon={"https://persana-assets.s3.us-west-1.amazonaws.com/lgm.svg"}
    />
  );
};

export default LGMIntegration;
