import { ChangeEvent, FormEvent, LegacyRef } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  name: string;
  inputtedString: string;
  dropdownInputRef: LegacyRef<HTMLInputElement>;
  type: string;
  value: any;
  placeholder: string;
  inputClassName: string;
  onSubmit: (e: FormEvent) => void;
  setFocused: (val: boolean) => void;
  setShowOptions: (val: boolean) => void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const InputForm = ({
  name,
  inputtedString,
  dropdownInputRef,
  type,
  value,
  placeholder,
  inputClassName,
  onSubmit,
  setFocused,
  setShowOptions,
  handleInputChange,
}: Props) => {
  return (
    <form
      className="w-full"
      onSubmit={(e) => {
        onSubmit(e);
      }}
    >
      <input
        ref={dropdownInputRef}
        type={type || "text"}
        id={name}
        value={inputtedString}
        name={name}
        onFocus={() => {
          setFocused(true);
          setShowOptions(true);
        }}
        onBlur={() => setFocused(false)}
        onChange={(e) => {
          handleInputChange(e);
        }}
        className={`${twMerge(
          `${
            value?.length > 0 ? "" : "w-full"
          } rounded pl-1.5 pr-2 text-sm focus:outline-none focus:ring-0`,
          inputClassName,
        )}`}
        placeholder={value?.length > 0 ? "" : placeholder}
      />
    </form>
  );
};

export default InputForm;
