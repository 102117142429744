import { Button, useToast } from "@chakra-ui/react";

import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const ClearFilter = () => {
  const toast = useToast({
    position: "top",
  });
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (
      payload: Parameters<typeof tableService.createOrUpdateView>[0],
    ) => tableService.createOrUpdateView(payload),
  });

  const tableViews = useTableStore((state) => state.tableData.views || []);
  const selectedViewId = useTableStore(
    (state) => state.tableData.selectedViewId,
  );
  const tableId = useTableStore((state) => state.tableData._id);
  const updateState = useTableStore((state) => state.updateState);

  const currentView = tableViews.find((view) => view._id === selectedViewId)!;

  const handleClearFilter = async () => {
    updateState({
      tableFilters: {
        filters: [],
        open: false,
      },
      isFiltering: false,
    });

    // TODO: Improve it later, to do not call if filter is Empty
    await mutateAsync(
      {
        tableId,
        viewId: currentView?._id,
        bodyData: {
          filters: [],
        },
      },
      {
        onSuccess: async () => {
          await queryClient.refetchQueries({
            queryKey: ["table", tableId],
          });
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
      },
    );
  };

  return (
    <Button
      className="flex w-full items-center justify-center gap-x-2 truncate rounded-md  px-3 py-1.5 !text-sm  duration-300"
      size="sm"
      onClick={handleClearFilter}
      isLoading={isPending}
    >
      <span>Clear Filter</span>
    </Button>
  );
};

export default ClearFilter;
