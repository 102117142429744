import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import { useState } from "react";
import enrichment from "@/services/enrichment.service";
import { useNavigate } from "@tanstack/react-router";

const LocalBusinessesModal = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [numberOfResults, setNumberOfResults] = useState<number>();
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  const closeModal = () => {
    if (modalData.metaData?.openPreviousModal) {
      updateState({
        isOpenModal: true,
        modalData: { modalType: "get-started", metaData: "" },
      });
    } else {
      updateState({
        isOpenModal: false,
        modalData: { modalType: "", metaData: "" },
      });
    }
  };

  const fetchLocalBusinesses = async () => {
    try {
      setIsLoading(true);
      const response = await enrichment.localBusinesses({
        searchQuery,
        numberOfResults,
      });
      if (!response.success) {
        toast({
          title: response?.data?.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } else {
        const tableId = response?.data?.data?.tableData._id;

        if (tableId) {
          navigate({
            to: "/table/$tableId",
            params: {
              tableId,
            },
          });
        }
        closeModal();
      }
    } catch (error: any) {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isOpenModal && modalData.modalType === "local-businesses"}
      onClose={closeModal}
      size={"2xl"}
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="pb-4 font-poppins">
        <ModalHeader className="border-b-[1px] text-center">
          <div className="flex items-center">
            <Text className="flex-1">Find Local Businesses</Text>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="space-y-4">
            <div>
              <p className="text-base font-semibold pb-1">Search Query</p>
              <Input
                placeholder="Veg Restaurants in San Francisco"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div>
              <p className="text-base font-semibold pb-1">Number of Results</p>
              <p className="pb-1.5 text-xs">
                Number of results to pull. The maximum this action will pull is
                200 results (minus duplicate results).
              </p>
              <Input
                placeholder="100 (Optional)"
                type="number"
                value={numberOfResults}
                onChange={(e) => setNumberOfResults(Number(e.target.value))}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            isDisabled={!searchQuery}
            className="rounded-[5px] px-[20px] py-[6px] text-[16px]"
            style={{
              background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
              color: "#fff",
              border: "none",
              fontWeight: 500,
            }}
            onClick={fetchLocalBusinesses}
          >
            Find
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LocalBusinessesModal;
