import { AgGridReact } from "ag-grid-react";
import React from "react";
import Select from "react-select";

import { useTableStore } from "@/stores/table.store";
import Icons from "../Icons";

interface Props {
  gridRef: React.RefObject<AgGridReact>;
}

const Pagination = ({ gridRef }: Props) => {
  const totalPages = useTableStore((state) => state.totalPages);
  const currentPage = useTableStore((state) => state.queryParams.pageNumber);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateState = useTableStore((state) => state.updateState);

  if (totalPages < 2) return null;

  const options = Array.from({ length: totalPages }, (_, i) => ({
    value: i + 1,
    label: String(i + 1),
  }));

  const handleChage = (type: string) => {
    if (type === "next") {
      updateState({
        queryParams: {
          ...queryParams,
          pageNumber: currentPage + 1,
        },
      });
    } else {
      updateState({
        queryParams: {
          ...queryParams,
          pageNumber: currentPage - 1,
        },
      });
    }
    gridRef?.current?.api.redrawRows();
    gridRef?.current?.api.ensureIndexVisible(0, "top");
  };

  return (
    <div className="flex items-center gap-x-1.5">
      <PagginationButton
        type="prev"
        onClick={() => handleChage("prev")}
        disabled={currentPage === 1}
      />

      <div className="min-w-[50px]">
        <Select
          defaultValue={
            options.find((option) => option.value === queryParams.pageNumber) ||
            options[0]
          }
          options={options}
          value={
            options.find((option) => option.value === queryParams.pageNumber) ||
            options[0]
          }
          styles={{
            singleValue: (base) => ({
              ...base,
              textAlign: "center",
            }),
            input: (base) => ({
              ...base,
              caretColor: "transparent", // Hide the cursor
            }),
            option: (base) => ({
              ...base,
              whiteSpace: "nowrap", // Prevent text wrapping
            }),
          }}
          isSearchable
          isClearable={false}
          menuPosition="fixed"
          components={{
            DropdownIndicator: () => null,
          }}
          onChange={(option) => {
            if (option?.value) {
              updateState({
                queryParams: {
                  ...queryParams,
                  pageNumber: option?.value,
                },
              });
              gridRef?.current?.api.redrawRows();
              gridRef?.current?.api.ensureIndexVisible(0, "top");
            }
          }}
        />
      </div>

      <PagginationButton
        type="next"
        onClick={() => handleChage("next")}
        disabled={currentPage === totalPages}
      />
    </div>
  );
};

export default React.memo(Pagination);

const PagginationButton = ({
  type,
  onClick,
  disabled,
}: {
  type: string;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <button
      className="text-[1.635rem] text-[#693DC7] duration-300 hover:text-primaryPink disabled:opacity-50"
      onClick={onClick}
      disabled={disabled}
    >
      {type === "next" ? (
        <Icons.LeftCircleArrow className="rotate-180" />
      ) : (
        <Icons.LeftCircleArrow />
      )}
    </button>
  );
};
