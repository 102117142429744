import PostEngagementPreferences from "@/assets/images/post-engagement-preferences.png";

const Step1 = () => {
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <p className="text-lg font-semibold text-gray-800">
        Set your company details preferences
      </p>
      <hr className="border-t-2 border-purple-700 my-4" />

      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
          <img
            src={PostEngagementPreferences}
            alt="Select Leads"
            className="rounded-lg mb-4"
          />
        </div>
        <p className="text-base font-medium text-gray-800">
          Customize your preferences to convert Likes & Comments to Leads
        </p>
        <p>
          <span className="font-semibold">1. Select </span> "Company Details
          Source"
        </p>
        <p>
          <span className="font-semibold">2. Choose </span> "Configuration"
        </p>
        <p>
          <span className="font-semibold">3. Set </span> Preferences & Save
        </p>
      </div>
    </div>
  );
};

export default Step1;
