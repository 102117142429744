import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import AnimatedLoader from "@/components/Icons/SvgIcons/AnimatedLoaders";

import providerService from "@/services/providers.service";

const Oauth = ({ provider }: { provider: string }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [code, setCode] = useState<string>();

  const getServiceProvider = (provider: string, code: string) => {
    switch (provider) {
      case "github":
        return providerService.githubOAuth(code);
      case "hubspot":
        return providerService.hubSpotOAuth(code);
      case "salesforce":
        return providerService.salesforceOAuth(code);
      case "outreach":
        return providerService.outreachOAuth(code);
      case "salesoft":
        return providerService.salesoftOAuth(code);
      case "gmail":
        return providerService.gmailOAuth(code);
      case "slack":
        return providerService.slackOAuth(code);
      default:
        return providerService.githubOAuth(code);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    code && setCode(code);
  }, [location.search]);

  useEffect(() => {
    let timeout: any;

    if (code) {
      const oauthProvider = getServiceProvider(provider, code);
      oauthProvider
        .then((response) => {
          if (!response.data?.success) {
            toast({
              title: "Error",
              description: response?.data?.error || "Something went wrong",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toast({
              title: "Success",
              description: `${provider.charAt(0).toUpperCase() + provider.slice(1)} connected successfully`,
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          }

          // refetch
          queryClient.refetchQueries({
            queryKey: ["providers"],
          });

          timeout = setTimeout(() => {
            // close this current tab
            window.close();
          }, 3000);
        })
        .catch((error: any) => {
          toast({
            title: "Error",
            description:
              error?.response?.data?.message || "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });

          timeout = setTimeout(() => {
            // close this current tab
            window.close();
          }, 3000);
        });
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [provider, code]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="rounded-md bg-white px-20 py-10 shadow-md">
        <p className="font-title text-2xl font-bold">Verifying</p>
        <AnimatedLoader className="h-12 w-12" />
      </div>
    </div>
  );
};

export default Oauth;
