import Icons from "@/components/Icons";
import { useModalStore } from "@/stores/modal.store";
import { Button } from "@chakra-ui/react";

export function NoTablesView() {
  const { updateState } = useModalStore((state) => state);

  return (
    <>
      <div className="pointer-events-auto my-8 flex size-full flex-col items-center justify-center pb-16">
        <Button
          leftIcon={<Icons.PlusIcon className="text-lg" />}
          className="!font-normal"
          onClick={() =>
            updateState({
              isOpenModal: true,
              modalData: { modalType: "get-started" },
            })
          }
          variant={"outline"}
        >
          Create Lead List
        </Button>
      </div>
    </>
  );
}
