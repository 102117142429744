import Icons from "@/components/Icons";
import { IconButton } from "@chakra-ui/react";
import { ActionProps } from "react-querybuilder";

export const CloseButton = (props: ActionProps) => {
  return (
    <IconButton
      size={"xl"}
      variant={"ghost"}
      color={"black"}
      icon={<Icons.CloseIcon />}
      aria-label={""}
      onClick={props.handleOnClick}
    />
  );
};
