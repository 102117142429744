import {
  Divider,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { useMutation } from "@tanstack/react-query";

import Icons from "../Icons";

import leadService from "@/services/leads.service";
import { convertPromptToContext } from "@/utils";
import { useModalStore } from "@/stores/modal.store";

interface Props {
  answerData: {
    answer: string;
    prompt: string;
  };
}

const Conversations = ({ answerData }: Props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const chatsEndRef = useRef<HTMLDivElement>(null);
  const lastAnswerRef = useRef<HTMLDivElement | null>(null);

  const [inputValue, setInputValue] = useState("");
  const [prompts, setPrompts] = useState(() => [answerData]);

  const updateState = useModalStore((state) => state.updateState);

  console.log("myLog prompts", prompts);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ input, context }: { input: string; context: string }) =>
      leadService.searchPeopleUsingGPT({
        prompt: input,
        context: context || "",
      }),
    onSuccess: (response) => {
      if (response?.data?.tableId) {
        navigate({
          to: "/table/$tableId",
          params: {
            tableId: response?.data?.tableId,
          },
        });
        return;
      }
      setInputValue("");
      setPrompts((prev) => {
        const newPrompt = {
          prompt: prev[prev.length - 1].prompt,
          answer: response?.data?.answer || "No response",
        };
        return [...prev.slice(0, prev.length - 1), newPrompt];
      });
    },
    onError: (error) => {
      setPrompts((prev) => {
        return [...prev.slice(0, prev.length - 1)];
      });
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleAsk();
    }
  };

  const scrollToBottom = () => {
    lastAnswerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  const handleAsk = async () => {
    if (!inputValue) return;
    const newPrompt = {
      prompt: inputValue,
      answer: "FETCHING",
    };

    setPrompts((prev) => {
      return [...prev, newPrompt];
    });

    // console.log(convertPromptToContext(prompts?.slice(-3)))
    const context = convertPromptToContext(prompts?.slice(-3)) || "";

    await mutateAsync({
      input: inputValue,
      context: context,
    });
  };

  const handleHereClick = () => {
    updateState({
      isOpenModal: true,
      modalData: { modalType: "get-started" },
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [prompts]);

  return (
    <div className="">
      <h2 className="font-medium text-xl mb-2 text-center">Sales AI</h2>
      <div
        className="chats-container max-h-[400px] overflow-y-auto"
        ref={chatsEndRef}
      >
        {prompts.map((prompt, index, arr) => (
          <div
            className={index !== arr.length - 1 ? "pb-5" : ""}
            key={`prompts-${index}`}
          >
            <div className="prompts mb-3">
              <p className="font-semibold text-base text-dark mb-1">You</p>
              <p className="font-normal text-base text-dark max-w-[85%]">
                {prompt.prompt}
              </p>
            </div>
            <div
              className="answers flex flex-col items-end justify-end"
              ref={index === prompts.length - 1 ? lastAnswerRef : null}
            >
              <p className="font-semibold text-base text-dark mb-1">
                Persana AI
              </p>
              <p className="font-normal text-base text-dark max-w-[85%] text-right min-h-[20px]">
                {prompt.answer === "FETCHING" ? (
                  <div>
                    <Skeleton
                      height="15px"
                      w={"400px"}
                      borderRadius={4}
                      className="mb-1"
                    />
                    <Skeleton height="15px" w={"400px"} borderRadius={4} />
                  </div>
                ) : (
                  <>
                    <span className="block pb-1">{prompt.answer}</span>
                    <Divider className="my-1" />
                    <span className="block font-light text-sm text-darkTextGray">
                      To leverage the power of Persana’s more powerful agent{" "}
                      <br /> and prospect at scale, create a new table{" "}
                      <span
                        className="text-blue-600 cursor-pointer font-medium"
                        onClick={handleHereClick}
                      >
                        here
                      </span>
                    </span>
                  </>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="input-box mt-4 mb-2">
        <InputGroup>
          <div className="w-full">
            <Input
              style={{ borderColor: "#808080" }}
              type="text"
              resize="none"
              placeholder="Ask more..."
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={isPending}
            />
            <InputRightElement
              onClick={handleAsk}
              children={
                <IconButton
                  isLoading={isPending}
                  aria-label="Search"
                  size={"sm"}
                  icon={
                    <Icons.SearchBarButton className="text-xl text-textPurple" />
                  }
                  variant={"unstyled"}
                />
              }
            />
          </div>
        </InputGroup>
      </div>
    </div>
  );
};

export default Conversations;
