import { useNavigate } from "@tanstack/react-router";
import Icons from "../Icons";
import { allTemplates } from "../Leads/UseTemplateModal/constants";
import FindLeadsIcon from "@/assets/svgs/auto-pilot-find-leads.svg";

const PlaybooksSection = () => {
  const navigate = useNavigate();
  return (
    <div className="border border-[#F0EFF1] px-[32px] py-[16px] rounded-[8px] !min-h-[520px]">
      <div className="flex items-center justify-between mb-8">
        <p className="text-[14px] font-medium">Playbooks</p>
        <p
          className="text-[14px] font-medium text-[#5024DC] cursor-pointer hover:underline flex items-center gap-2"
          onClick={() => navigate({ to: "/playbooks" })}
        >
          See all
          <span>
            <Icons.ChevronRightIcon />
          </span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        {allTemplates?.slice(0, 4).map((item) => (
          <div
            className="bg-[#FDFCFF] border border-[#D0D4DC] p-[8px] rounded-[12px] flex items-center gap-3 h-[90px] cursor-pointer"
            onClick={() =>
              navigate({
                to: "/playbooks/$templateId",
                params: {
                  templateId: item.heading,
                },
              })
            }
          >
            <img src={FindLeadsIcon} />
            <div>
              <p className="text-[14px] text-[#0F0D1C] font-medium">
                {item.heading}
              </p>
              <p className="text-[12px] text-[#7E889F]">{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlaybooksSection;
