import APIKeyIntegration from "./APIKeyIntegration";
import IcypeasLogo from "@/assets/images/icypeas.png";

const IcypeasIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"icyPeasApiKey"}
      providerTitle="Icypeas"
      providerIcon={IcypeasLogo}
    />
  );
};

export default IcypeasIntegration;
