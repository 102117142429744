import {
  Table,
  Thead,
  Th,
  Button,
  Tbody,
  Tr,
  Td,
  InputGroup,
  IconButton,
  Input,
  Divider,
  Text,
} from "@chakra-ui/react";
import { CgClose } from "react-icons/cg";
import { PReactQueryBuilder } from ".";
import { getUUID } from "../utils";
import { useTableStore } from "@/stores/table.store";
import { useEffect } from "react";
import { useLeadScoreStore } from "./critaria.store";
import Description from "@/components/Enrichment/Common/Description";
import CustomAccordion from "@/components/Common/CustomAccordion";

export const Criterias = () => {
  const criterias = useLeadScoreStore((state) => state.criterias);
  const updateState = useLeadScoreStore((state) => state.updateState);
  const firstColumnId = useTableStore(
    (state) => state.tableData.columns[0]._id,
  );
  const cells = useTableStore((state) => state.rowsData[0]?.cells);

  useEffect(() => {
    if (!criterias.size || !firstColumnId || !cells) {
      updateState({
        criterias: new Map([
          [
            firstColumnId!,
            {
              score: 1,
              query: {
                id: getUUID(),
                rules: [
                  {
                    id: getUUID(),
                    field: firstColumnId,
                    operator: "=",
                    valueSource: "value",
                    value: cells[firstColumnId].value,
                  },
                ],
              },
            },
          ],
        ]),
      });
    }
  }, [firstColumnId, cells]);
  return (
    <Table>
      <Thead position={"sticky"} top={0} bg={"white"} zIndex={"dropdown"}>
        <Th className="!relative w-[80%]">
          <Button
            size={"sm"}
            // variant={"outline"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const id = getUUID();
              updateState({
                criterias: new Map([
                  ...criterias,
                  [
                    id,
                    {
                      score: 1,
                      query: {
                        id,
                        rules: [
                          {
                            field: "~",
                            operator: "~",
                            valueSource: "value",
                            value: "",
                          },
                        ],
                      },
                    },
                  ],
                ]),
              });
            }}
            className="mr-8"
          >
            + Add Criteria
          </Button>
          Rule
        </Th>
        <Th className="w-[20%]"> Score</Th>
      </Thead>
      <Tbody>
        {[...criterias].reverse().map(([id, item], index) => (
          <Tr key={id}>
            <Td>
              <CustomAccordion
                isDefaultOpen
                title={
                  <Description>
                    Criteria {index + 1} Conditions:
                    <IconButton
                      ml={2}
                      variant={"ghost"}
                      size={"xs"}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        criterias.delete(id);
                        updateState({
                          criterias: new Map(criterias),
                        });
                      }}
                      aria-label={""}
                      icon={<CgClose scale={2} />}
                    />
                  </Description>
                }
              >
                <Divider />
                <PReactQueryBuilder
                  query={item.query}
                  setQuery={(query) => {
                    query.id ||= getUUID();
                    const newCriatarias = new Map(criterias);
                    newCriatarias.set(id, {
                      ...criterias.get(id)!,
                      query,
                    });
                    updateState({
                      criterias: newCriatarias,
                    });
                  }}
                />
              </CustomAccordion>
            </Td>
            <Td>
              <Description>
                <Text className="whitespace-nowrap">
                  Criteria {index + 1} Score:
                </Text>
              </Description>
              <Divider />
              <InputGroup>
                <Input
                  type="number"
                  pattern="^[-+]?[0-9]*$"
                  value={item.score}
                  onChange={(e) => {
                    const newCriatarias = new Map(criterias);
                    newCriatarias.set(id, {
                      ...newCriatarias.get(id)!,
                      score: Number(e.target.value),
                    });
                    updateState({
                      criterias: newCriatarias,
                    });
                  }}
                  minW={"6em"}
                  className="w-full"
                ></Input>
              </InputGroup>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
