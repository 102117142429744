import { buildServerQueryParams } from "@/components/DataTable/Table/functions/buildUrlParams";
import { useTableStore } from "@/components/DataTable/Table/hooks/useGridState.store";
import { useTable } from "@/components/DataTable/Table/hooks/useTable";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import _ from "lodash";

export const useCreateTable = (
  monitoringType: "jobChange" | "activeJobs",
  filter: boolean,
  sort: boolean,
) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { selectedRowIds, selectedRowCount, totalRowCount } = useTable();
  const filterModel = useTableStore(
    (state) => state.state?.filter?.filterModel ?? null,
  );
  const sortModel = useTableStore(
    (state) => state.state?.sort?.sortModel ?? null,
  );
  const exportableRowCount =
    selectedRowCount === 0 ? totalRowCount : selectedRowCount;
  const mutation = useMutation({
    mutationFn: () =>
      accountMonitoringService.createTable({
        totalRows: exportableRowCount,
        monitoringType,
        isForAll: selectedRowIds === "all",
        selectedRowsIds:
          selectedRowIds === "all" ? [] : (selectedRowIds as string[]),
        filter:
          filter && filterModel
            ? buildServerQueryParams({ filterModel }).filter
            : null,
        ...(sort && sortModel
          ? _.pick(buildServerQueryParams({ sortModel }), [
              "sortingKey",
              "sortOrder",
            ])
          : {}),
      }),
    onSuccess: (response) => {
      if (response.success) {
        navigate({
          to: `/table/$tableId`,
          params: {
            tableId: response?.data?.data?.tableData._id,
          },
        });
      } else {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });
  return {
    mutation,
    exportableRowCount,
  };
};
