import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { DotLottiePlayer } from "@dotlottie/react-player";

import { useModalStore } from "@/stores/modal.store";
import teamService from "@/services/team.service";
import { Member, Team } from "@/types/team.types";
import sorryEmoji from "@/assets/animated/sorryEmoji.lottie";
import WorkspaceCard from "./WorkspaceCard";
import { useTeamData } from "@/hooks/useTeamData";

const TeamOverviewComponent = () => {
  const ownedWorkspaces = useModalStore((state) => state.ownedWorkspaces);
  const [sharedWorkspaces, setSharedWorkspaces] = useState<Team[]>([]);
  const [teamMembers, setTeamMembers] = useState<Member[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: teamData } = useTeamData();

  const updateState = useModalStore((state) => state.updateState);
  const { mutateAsync } = useMutation({
    mutationFn: () => teamService.getWorkspaces(),
    onSuccess: (response) => {
      setIsLoading(false);
      updateState({ ownedWorkspaces: response?.data?.ownedWorkspaces || [] });
      setSharedWorkspaces(response?.data?.sharedWorkspaces || []);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (teamData?.data) {
      setTeamMembers(
        teamData.data.members.filter((member) => member.role !== "admin"),
      );
    }
  }, [teamData?.data]);

  useEffect(() => {
    mutateAsync();
  }, []);

  return (
    <div className="mr-[20px]">
      <div className="max-w-[52rem] w-full mx-auto h-full shadow p-8">
        <div className="flex justify-between items-center">
          <Text className=" text-[22px] font-[600]">Team Overview</Text>
          <Button
            className="btn-primary-outline !flex !h-[48px] !min-w-fit !cursor-pointer !items-center !justify-center !rounded-[5px] !px-[28px] !py-[8px] !text-[16px] !font-semibold !text-[#302323]"
            variant={"outline"}
            isLoading={isLoading}
            onClick={() => {
              updateState({
                addTeamMembersModal: {
                  isOpen: true,
                  isCreateWorkspace: ownedWorkspaces?.length === 0,
                  isForShare: false,
                },
              });
            }}
          >
            {ownedWorkspaces?.length === 0
              ? "Create Workspace"
              : "Manage Your Workspace"}
          </Button>
        </div>

        {isLoading ? (
          ""
        ) : ownedWorkspaces?.length === 0 && sharedWorkspaces?.length === 0 ? (
          <div className="flex flex-col items-center py-16">
            <div className="w-[150px]">
              <DotLottiePlayer
                src={sorryEmoji}
                autoplay
                loop
                style={{ height: "auto", width: "auto" }}
              />
            </div>
            <Text className="text-[16px] font-semibold text-[#302323]">
              You have not created or shared any workspaces yet.
            </Text>
          </div>
        ) : (
          <Tabs className="mt-4">
            <TabList>
              <Tab>My Team</Tab>
              <Tab>Shared Workspaces</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <div className="mt-4 flex flex-col gap-4">
                  {!teamMembers.length && <p>No team members found</p>}
                  {teamMembers.map((item, index) => (
                    <div className="flex items-center gap-x-3" key={index}>
                      <Avatar
                        sx={{
                          width: "2.7rem",
                          height: "2.7rem",
                        }}
                      />
                      <div className="text-start leading-[1] relative top-[-3.5px]">
                        <p className="font-semibold text-base">
                          {`${
                            // @ts-ignore
                            item?.userId?.profileInfo?.firstName || ""
                          } ${
                            // @ts-ignore
                            item?.userId?.profileInfo?.lastName || ""
                          }`}
                        </p>
                        <p className="text-darkTextGray font-medium text-sm">
                          {
                            // @ts-ignore
                            item?.userId?.Email || ""
                          }
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  {sharedWorkspaces.map((team, index) => (
                    <WorkspaceCard
                      key={`shared-workspace-${index}`}
                      team={team}
                    />
                  ))}
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default TeamOverviewComponent;
