import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { ProviderType, providers } from "./constants";


function ProviderSelect({ onChange, value, children }: {
    onChange: (newValue: ProviderType) => void;
    value: ProviderType;
    children?: React.ReactNode;
}) {
    return (
        <div className="mt-4 px-4">
            <RadioGroup
                onChange={(newValue: ProviderType) => {
                    onChange(newValue);
                }}
                value={value}
            >
                <Stack direction="row">
                    {
                        providers.map((provider, index) => (
                            <Radio key={`use-ai-provider-${index + 1}`} size="md" name={provider.value} value={provider.value} colorScheme="primary">
                                {provider.label}
                            </Radio>
                        ))
                    }
                </Stack>
            </RadioGroup>
            {children}
        </div>
    )
}

export default ProviderSelect
