export const details = {
  companyFormalizer: {
    inputTitle: "Company Name",
    subText: "Remove inc, LLC, etc. from a company name.",
    formalizerType: "Company",
  },
  domainFormalizer: {
    inputTitle: "Domain URL",
    subText:
      "Takes in a URL as an input and normalize it to a specified standard.",
    formalizerType: "Domain",
  },
  whiteSpaceFormalizer: {
    inputTitle: "Input Text",
    subText: "Removes extra whitespace from input text.",
    formalizerType: "Whitespace",
  },
  firstNameFormalizer: {
    inputTitle: "Full Name",
    subText: "Use a pre-built formula to get a first name from full name",
    formalizerType: "First Name",
  },
  lastNameFormalizer: {
    inputTitle: "Full Name",
    subText: "Use a pre-built formula to get a last name from full name",
    formalizerType: "Last Name",
  },
};
