import BgImg from "@/assets/images/demo-bg.png";
import { Button, Link } from "@chakra-ui/react";
import VideoPreviewImg from "@/assets/images/demo-preview.png";

const Demo = () => {
  return (
    <div className="rounded-[20px] flex min-h-[290px]">
      <div className="p-[24px] w-[50%] bg-[#F5F3FF] rounded-l-[20px] flex flex-col justify-center">
        <p className="text-[24px] text-[#0F0D1C] font-[600]">
          Automate your prospecting with Perry
        </p>
        <p className="text-[16px] text-[#0F0D1C]">
          Perry combines Generative AI with data from your business and you to
          automatically predict who to prospect, when, and with what
          personalized message for maximum engagement.
        </p>
        <Link
          href="https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding"
          target="_blank"
        >
          <Button
            className="mt-[24px] !rounded-[24px] !text-[16px] !text-white hover:!shadow-md !font-[400] w-fit"
            style={{
              background: "linear-gradient(90deg, #8E32A4 0%, #5024DC 100%)",
            }}
          >
            Request a demo
          </Button>
        </Link>
      </div>
      <div
        className="p-[24px] w-[50%] rounded-r-[20px] flex items-center justify-center"
        style={{
          backgroundImage: `url(${BgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <video
          controls
          poster={VideoPreviewImg}
          style={{ width: "100%", height: "auto", borderRadius: "10px" }}
        >
          <source
            src={
              "https://persana-assets.s3.us-west-1.amazonaws.com/lucy-intro.mp4"
            }
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default Demo;
