import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import ConfettiAnimation from "@/components/Common/ConfettiAnimation";
import { getCurrentDateInYYYYMM } from "@/lib/utils";
import { useModalStore } from "@/stores/modal.store";

const WelcomeModal = () => {
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);

  const steps = [
    {
      title: "Install our Chrome extension for LinkedIn prospecting",
      buttonText: "Install",
      link: "https://chromewebstore.google.com/detail/persana-ai-on-linkedin-b2/jdbdgcibmddkccanncenaahimbfcgglj",
    },
    {
      title: "Join our Slack Community",
      buttonText: "Join",
      link: "https://join.slack.com/t/persanaaicommunity/shared_invite/zt-2hkyc3iaf-HQYWmcTIQnT9I6tcou4XEA",
    },
    {
      title: "Visit the Persana Learning Center",
      buttonText: "Visit",
      link: "https://persana.ai/learn-persana/persana-academy",
    },
    {
      title: "Book a free onboarding call with us",
      buttonText: "Book",
      link: `https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`,
    },
  ];

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  return (
    <>
      <ConfettiAnimation />
      <Modal
        isCentered
        onClose={handleModalClose}
        isOpen={isOpenModal && modalData.modalType === "welcome-modal"}
        motionPreset="scale"
        size="2xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

        <ModalContent className="rounded-[20px]">
          <ModalHeader className="text-center">
            Welcome to Persana 🎊️
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="font-[500]">
              Follow the steps to make the most of your prospecting 👇️
            </Text>
            <div className="mb-5 mt-6 flex flex-col gap-4">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center gap-3">
                    <div className="relative flex size-[26px] items-center justify-center rounded-full bg-blue-500 text-white">
                      <span className="text-[14px] font-bold">{index + 1}</span>
                    </div>
                    <Text className="text-[16px] font-[400]">{step.title}</Text>
                  </div>
                  <Link href={step.link} target="_blank">
                    <Button
                      w={"80px"}
                      size={"sm"}
                      colorScheme="primary"
                      variant="outline"
                      className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
                    >
                      {step.buttonText}
                    </Button>
                  </Link>
                </div>
              ))}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WelcomeModal;
