import { Button } from "@chakra-ui/react";
import { allTemplates } from "../Leads/UseTemplateModal/constants";

const FindCompanyTechnologies = () => {
  const data = allTemplates.find(
    (item) => item.heading === "Find Company Technologies",
  );
  return (
    <div className="my-6 flex gap-4 p-[32px]">
      <div className="flex w-[45%] flex-col gap-4 px-[60px] pt-8">
        <p className="text-left text-[28px] font-bold leading-[40px] text-[#0F0D1C]">
          Try this workflow
        </p>
        <p className="text-[18px] text-[#4A5264]">{data?.title}</p>
        <Button
          w={"fit-content"}
          h={"38px"}
          fontSize={14}
          className="!bg-primary-gradient hover:opacity-80"
          colorScheme="primary"
          borderRadius={"6px"}
          onClick={() => {
            window.open(data?.url, "_blank");
          }}
        >
          Try Now
        </Button>
      </div>
      <div
        className="w-[55%] cursor-pointer rounded-md border border-primaryPink p-2"
        onClick={() => {
          window.open(data?.url, "_blank");
        }}
      >
        <img src={data?.image} alt="" />
      </div>
    </div>
  );
};

export default FindCompanyTechnologies;
