import PerryProfile from "@/assets/images/lucy.png";
import useTypedText from "../AIAssistant/useTypedText";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import leadService from "@/services/leads.service";
import { useNavigate } from "@tanstack/react-router";
import { IconButton, useToast } from "@chakra-ui/react";
import { useTabStore } from "../PeopleSearch/store";
import Icons from "../Icons";

const placeholders = [
  "Ask me anything...",
  "Individuals who are Data Scientists at Netflix.",
  "Employees who are HR Managers at Airbnb.",
  "Employees who are Product Managers at Tesla.",
];

const ChatWithGPT = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [inputValue, setInputValue] = useState("");
  const getFirstStaleTab = useTabStore(
    (state) => state.getOrCreateFirstStateTab,
  );
  const dynamicPlaceholder = useTypedText(placeholders);

  const { mutateAsync: searchPeopleUsingGPTMutateAsync, isPending } =
    useMutation({
      mutationFn: (input: string) =>
        leadService.searchPeopleUsingGPT({ prompt: input }),
      onSuccess: (response) => {
        if (response.type === "AGENT") {
          setInputValue("");
          return;
        }

        if (response.type === "PEOPLE_SEARCH") {
          if (response.data?.peopleSearchPayload) {
            const tab = getFirstStaleTab();
            tab.store.setState({
              peopleSearchPayload: response.data?.peopleSearchPayload,
            });
            useTabStore.getState().activateTab(tab.id);
            navigate({
              to: `/search`,
            });
          }
          return;
        }

        if (!response?.data?.tableId) return;
        navigate({
          to: `/table/$tableId`,
          params: {
            tableId: response?.data?.tableId,
          },
        });
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    });

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      inputValue && searchPeopleUsingGPTMutateAsync(inputValue);
    }
  };

  return (
    <div className="bg-[#F5F3FF] rounded-[8px] border border-[#BD90F4] py-[16px] min-h-[290px] flex flex-col justify-center">
      <div
        className="mx-[16px] w-fit px-[16px] py-[10px] rounded-[6px] text-white border border-[#F0EFF1] text-[14px] font-medium"
        style={{
          background: "linear-gradient(90deg, #8E32A4 0%, #5024DC 100%)",
        }}
      >
        Chat With SalesGPT
      </div>
      <div className="bg-white py-[16px] my-[16px]">
        <div className="p-[16px] mx-[16px] border border-[#5024DC] rounded-[8px] flex items-center gap-2">
          <img src={PerryProfile} />
          <p className="text-[14px] text-[#0F0D1C]">
            Hi, I'm Perry. Your AI-Powered Autopilot. How can I be of assistance
            to you today?
          </p>
        </div>
      </div>
      <div className="mx-[16px] relative">
        <textarea
          onKeyDown={handleKeyDown}
          value={inputValue}
          onChange={handleChange}
          placeholder={dynamicPlaceholder}
          rows={1}
          className="!w-full !resize-none !rounded-[32px] !p-3 shadow-none pr-10"
          style={{
            border: "1px solid #8E32A4",
          }}
        />
        <IconButton
          size={"sm"}
          isLoading={isPending}
          aria-label="Search"
          icon={<Icons.MdOutlineArrowOutward className="text-[#C03EDC]" />}
          className="!absolute right-3 top-[45%] transform -translate-y-1/2 !rounded-full !bg-[#F5F3FF]"
        />
      </div>
    </div>
  );
};

export default ChatWithGPT;
