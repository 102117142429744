import { useTableStore } from "@/stores/table.store";
import { add_operation, apply, uses_data } from "json-logic-js";
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ColDef, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { formatQuery, jsonLogicAdditionalOperators } from "react-querybuilder";
import { useLeadScoreStore } from "./QueryBuilder/critaria.store";
import { Criterias } from "./QueryBuilder/critarias";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";

for (const [op, func] of Object.entries(jsonLogicAdditionalOperators)) {
  add_operation(op, func);
}
export const PreviewRowScorer = () => {
  const isOpenModal = useLeadScoreStore(
    (state) => state.previewRowScoreModal.isOpen,
  );
  const updateState = useLeadScoreStore((state) => state.updateState);
  const [api, setApi] = useState<GridApi>();
  const queries = useLeadScoreStore((state) => state.criterias);
  const resetData = useLeadScoreStore((state) => state.reset);
  const columnsBeingUsed = useLeadScoreStore((state) =>
    state.getColumnsUsed().get("all"),
  )!;
  const columns = useTableStore((state) => state.tableData.columns);
  const rowData = useTableStore((state) => state.rowsData);
  const parsedRows = useMemo(() => {
    if (!rowData) return [];
    return rowData.map((row) => {
      return Object.entries(row.cells)
        .map(([key, value]) => {
          return {
            [key]: value.value,
          };
        })
        .reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {});
    });
  }, [rowData, columns]);
  const jl = useMemo(() => {
    if (!queries) return null;
    const jsonLogics = [...queries.values()].map((c) => [
      c.score,
      formatQuery(c.query, "jsonlogic"),
    ]);
    const usedColumns = jsonLogics.reduce((acc, [_, logic]) => {
      return new Set([...acc, ...uses_data(logic as any)]);
    }, new Set());
    return {
      getUsedColumnIds: () => usedColumns,
      runLogic: (row: Record<string, any>) => {
        const score = jsonLogics
          .map(([score, query]) => [score, apply(query, row)])
          .reduce((acc, [score, result]) => {
            return result === true ? acc + score : acc;
          }, 0);
        return {
          score,
          ...row,
        };
      },
    };
  }, [queries]);
  const handleClose = () => {
    updateState({
      previewRowScoreModal: {
        isOpen: false,
      },
    });
  };
  const columnDefs: ColDef[] = useMemo(
    () =>
      [{ _id: "score", name: "Lead Score" }]
        .concat(columns.filter((col) => jl?.getUsedColumnIds().has(col._id)))
        .map((col) => ({
          field: col._id,
          headerName: col.name,
        })),

    [columns, jl],
  );

  useEffect(() => {
    if (!(parsedRows && jl)) return;

    api?.updateGridOptions({ rowData: parsedRows.map(jl.runLogic) });
  }, [parsedRows, api, jl]);
  if (!isOpenModal) return null;
  return (
    <Modal
      isOpen={isOpenModal}
      onClose={handleClose}
      size="6xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay
        bg="rgba(0, 0, 0, 0.1)"
        backdropFilter="revert"
        backdropInvert="80%"
        backdropBlur="2px"
      />
      <ModalContent className="!min-h-[75vh] !min-w-[75vw]">
        <ModalHeader className="p-8">
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody className=" p-2">
          <HStack className="h-[48em] !items-start gap-4">
            <Box className="max-h-full w-[70%] overflow-scroll">
              <Criterias />
              <SaveAndRunEnrichment
                onClear={resetData}
                apiPayload={{
                  queries: Array.from(queries.values()),
                  columnIds: Array.from(columnsBeingUsed),
                }}
              />
            </Box>
            <Box className="h-full max-h-[100%] w-[30%] overflow-scroll">
              <AgGridReact
                className={"ag-theme-quartz w-full"}
                rowHeight={40}
                columnDefs={columnDefs}
                // rowData={parsedRows}
                onGridReady={(params) => {
                  setApi(params.api);
                }}
              />
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
