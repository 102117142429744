import PeopleSearch from "@/components/PeopleSearch";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/search/").createRoute({
  component: SearchPage,
});

function SearchPage() {
  return <PeopleSearch />;
}

export default SearchPage;
