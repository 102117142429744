import { useEffect, useState } from "react";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { useEnrichStore } from "@/stores/enrich.store";
import type { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { REGEX } from "../../constants";

interface EnrichmentDataType extends EnrichmentMetaData {
  isUseOwnKey: boolean;
  extraColumns?: string[];
  excludedJobTitles?: Option[];
  includedJobTitles?: Option[];
  isPrioritizeJobTitle?: boolean;
  companyNameId?: string;
}

const WebsiteVisits = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const viewMode = useEnrichStore((state) => state.viewMode);

  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;
      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
          keySelect: "persanaApiKey",
        });
      }

      return;
    }

    const initialColumn = getInitialColumn(REGEX.WEBSITE);
    if (initialColumn) {
      setSelectedOption({
        key: initialColumn.name,
        keyId: initialColumn._id,
        iconType: initialColumn.metaData?.iconType || "url",
      });
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="max-h-[70vh]  grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Get website visits by providing domain"
          link={""}
        />
        <div className="py-2">
          <div className="flex w-full items-center justify-between pb-1">
            <p className="pb-1 text-base font-semibold">
              Company Website or Company Domain
            </p>
          </div>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>

        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.key}
        apiPayload={{
          columnId: selectedOption?.keyId,
          isUseOwnKey: false,
        }}
      />
    </>
  );
};

export default WebsiteVisits;
