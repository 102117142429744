import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { useJobSync } from "./useSync";

export const useJobTracker = () => {
  const syncStatus = useJobSync();
  const toast = useToast();
  const { mutateAsync: trackJobChanges, isPending: isTrackingJobChanges } =
    useMutation({
      mutationFn: (payload: {
        query: string;
        location: string;
        resultLimit?: number;
      }) => accountMonitoringService.trackActiveJobs(payload),
      onSuccess: (response) => {
        if (!response.success) {
          toast({
            title:
              response.error.message || "Unable to track Active job changes",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        }
      },
      onError: (error) => {
        toast({
          title: error.message || "Unable to track active job changes",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      },
    });

  return {
    handleJobTracking: trackJobChanges,
    isTrackingJobChanges,
    syncStatus,
  };
};
