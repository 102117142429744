import AutopilotLGM from "@/assets/images/post-engagement-lgm.png";

const Step3 = () => {
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <p className="text-lg font-semibold text-gray-800">
        Store leads on LGM (Optional)
      </p>
      <hr className="border-t-2 border-purple-700 my-4" />

      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
          <img
            src={AutopilotLGM}
            alt="Select Leads"
            className="rounded-lg mb-4"
          />
        </div>
        <p className="text-base font-medium text-gray-800">
          Save and manage your leads directly on LGM, ensuring seamless
          integration and easy access to your contact database.
        </p>
        <p>
          <span className="font-semibold">1. Select </span> "Send to LGM"
        </p>
        <p>
          <span className="font-semibold">2. Choose </span> "Configuration"
        </p>
        <p>
          <span className="font-semibold">3. Connect LGM </span> (If not
          connected already)
        </p>
        <p>
          <span className="font-semibold">4. Enable LGM Storage </span>
        </p>
        <p>
          <span className="font-semibold">5. Select your audience</span>
        </p>
      </div>
    </div>
  );
};

export default Step3;
