import APIKeyIntegration from "./APIKeyIntegration";
import RocketReachLogo from "@/assets/svgs/rocketreach.png";

const RocketReachIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"rocketReachApiKey"}
      providerTitle="Rocket Reach"
      providerIcon={RocketReachLogo}
    />
  );
};

export default RocketReachIntegration;
