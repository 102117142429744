import Icons from "@/components/Icons";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { TableView } from "@/types/table.types";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Menu,
  InputGroup,
  Input,
  InputRightElement,
  Spinner,
  MenuButton,
  Button,
  Box,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
interface Props {
  view: TableView;
}
export const TableViewItem = ({ view }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const updateState = useTableStore((state) => state.updateState);
  const tableData = useTableStore((state) => state.tableData);
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(view.name);
  const viewId = view._id;
  const tableId = tableData?._id;
  const currentViewId = tableData?.selectedViewId;
  const { mutateAsync: createOrUpdateView, isPending: isUpdating } =
    useMutation({
      mutationFn: (payload: {
        tableId: string;
        viewId: string;
        bodyData: Partial<TableView>;
      }) => tableService.createOrUpdateView(payload),
    });

  const { mutateAsync: updateSelectedView, isPending: isSelectedViewUpdating } =
    useMutation({
      mutationFn: ({ tableId, viewId }: { tableId: string; viewId: string }) =>
        tableService.updateTableDataV2(tableId, {
          selectedViewId: viewId,
        }),
    });

  const { mutateAsync: deleteView, isPending: isDeleting } = useMutation({
    mutationFn: (payload: Parameters<typeof tableService.deleteView>[0]) =>
      tableService.deleteView(payload),
  });
  const isLoading = isUpdating || isSelectedViewUpdating || isDeleting;
  if (!tableData) return null;
  const handleRenameView = () => {
    if (!tableId) return;
    createOrUpdateView(
      {
        tableId,
        viewId,
        bodyData: {
          name: editValue,
        },
      },
      {
        onSuccess: (response) => {
          if (!response.success) {
            toast({
              title: "Error",
              description: response.message || "Something went wrong",
              status: "error",
              duration: 3000,
            });
            return;
          }
          updateState({
            tableData: {
              ...tableData,
              views: response.data!,
            },
          });

          setEditing(false);
        },
        onError: (error) => {
          setEditValue(view.name);
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
          });
        },
      },
    );
  };
  const handleDuplicateView = async () => {
    if (!tableId) return;
    const copiedCurrentView = { ...view, _id: undefined };

    await createOrUpdateView(
      {
        tableId,
        viewId: "new",
        bodyData: {
          ...copiedCurrentView,
          name: `${view.name} Copy`,
        },
      },
      {
        onSuccess: (response) => {
          if (!response.success || !response.data?.length) {
            toast({
              title: "Error",
              description: response.message || "Something went wrong",
              status: "error",
              duration: 3000,
            });
            return;
          }
          updateState({
            tableData: {
              ...tableData,
              views: response.data!,
            },
          });
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
          });
        },
      },
    );
  };
  const handleDeleteView = async () => {
    if (!tableId) return;
    await deleteView(
      {
        tableId,
        viewId,
      },
      {
        onSuccess: (response) => {
          if (!response.success) {
            toast({
              title: "Error",
              description: response.message || "Something went wrong",
              status: "error",
              duration: 3000,
            });
            return;
          }
          updateState({
            tableData: {
              ...tableData,
              views: response.data!,
            },
          });
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
          });
        },
      },
    );
  };

  const handleViewChange = async () => {
    if (!tableId) return;
    updateState({
      tableData: {
        ...tableData,
        metaData: {
          ...tableData.metaData,
          // isLoadingView: true,
        },
      },
    });
    await updateSelectedView(
      { tableId, viewId: view._id },
      {
        onSuccess: async () => {
          queryClient.refetchQueries({
            queryKey: ["table", tableId],
          });
          updateState({
            tableData: {
              ...tableData,
              selectedViewId: view._id,
              metaData: {
                ...tableData.metaData,
                isLoadingView: false,
              },
            },
          });
        },
        onError: (error) => {
          updateState({
            tableData: {
              ...tableData,
              metaData: {
                ...tableData.metaData,
                isLoadingView: false,
              },
            },
          });
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
          });
        },
      },
    );
  };
  return (
    <Menu placement="end-end">
      {editing ? (
        <InputGroup size="xs" width="auto" maxWidth="20em">
          <Input
            minWidth={"20em"}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onBlur={() => handleRenameView()}
            autoFocus
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRenameView();
              } else if (e.key === "Escape") {
                setEditing(false);
              }
            }}
          />
          <InputRightElement>
            {isUpdating ? (
              <Spinner colorScheme="purple" size="xs" />
            ) : (
              <CheckIcon onClick={handleRenameView}></CheckIcon>
            )}
          </InputRightElement>
        </InputGroup>
      ) : (
        <MenuButton
          isActive={viewId === currentViewId}
          className="w-full"
          rounded={"none"}
          fontWeight={500}
          pointerEvents={isLoading ? "none" : "auto"}
          pr={0}
          opacity={viewId === currentViewId ? 1 : 0.7}
          as={Button}
          leftIcon={<Icons.ViewTableIcon />}
          colorScheme={viewId === currentViewId ? "purple" : "gray"}
          onClick={(e) => {
            if (e.target == e.currentTarget) {
              e.preventDefault();
              handleViewChange();
            }
          }}
          rightIcon={
            isLoading ? (
              <Spinner size="xs" />
            ) : (
              <Box className="mr-1 flex h-[1.5em] cursor-pointer items-center rounded-full px-1 hover:bg-primaryLightPink">
                <Icons.ChevronDownIcon />
              </Box>
            )
          }
        >
          <div className="  truncate">{view.name}</div>
        </MenuButton>
      )}
      <MenuList>
        <MenuItem onClick={() => setEditing(true)}>Edit</MenuItem>
        <MenuItem onClick={() => handleDuplicateView()}>Duplicate</MenuItem>
        <MenuItem onClick={() => handleDeleteView()} color="red.500">
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
