import {
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import WhiteList from "./WhiteList";
import Credits from "./Credits";
import UserLogin from "./UserLogin";
import PlanChange from "./PlanChange";
import AdminApiKeyManager from "@/components/ApiKeysManager/AdminApiKeyManager";
import ProvidersCreditUsage from "@/ProvidersCreditUsage/ProvidersCreditUsage";
import useAdminAccessCheck from "./hooks";

const PAdmin = () => {
  const { isLoading, data } = useAdminAccessCheck();

  if (isLoading) {
    return (
      <div className="mt-5 flex items-center justify-center">
        <Spinner size={"lg"} />
      </div>
    );
  }

  if (!data?.isAccess) {
    return (
      <div className="mt-5 flex items-center justify-center">
        <h1 className="text-lg font-bold text-red-700">Access Denied</h1>
      </div>
    );
  }

  return (
    <Tabs isFitted variant="enclosed" isLazy>
      <TabList>
        <Tab className="font-bold">Whitelisting</Tab>
        <Tab className="font-bold">Credits</Tab>
        <Tab className="font-bold">User Login</Tab>
        <Tab className="font-bold">Plan Change</Tab>
        <Tab className="font-bold">API Keys</Tab>
        <Tab className="font-bold">Provider's Credit Usage</Tab>
      </TabList>
      <TabPanels className="border">
        <TabPanel>
          <WhiteList />
        </TabPanel>
        <TabPanel>
          <Credits />
        </TabPanel>
        <TabPanel>
          <UserLogin />
        </TabPanel>
        <TabPanel>
          <PlanChange />
        </TabPanel>
        <TabPanel>
          <AdminApiKeyManager />
        </TabPanel>
        <TabPanel>
          <ProvidersCreditUsage />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default PAdmin;
