import { CustomCellRendererProps } from "ag-grid-react";
import { TRow, TRowId } from "../types";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useInterval,
  useToast,
} from "@chakra-ui/react";
import Icons from "@/components/Icons";
import { useTableState } from "../hooks/useTableState.store";
import { RowMenu } from "./RowMenu";
import { DEFAULT_FOLDER_OPEN, ROOT_FOLDER_ID } from "../constants";
import { useDeleteTable, useDeleteFolder } from "../hooks/useTableData";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { getChildCount, getRowEditDate } from "../utils";
import { TableRowEditRenderer } from "./tableRowEdit";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";
import { useModalStore } from "@/stores/modal.store";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const TableRowRendererComponent = (params: CustomCellRendererProps<TRow>) => {
  // Refs and State
  const toast = useToast();
  const elemRef = useRef<HTMLDivElement>(null);
  const menuDisclosure = useDisclosure();
  const [updatedAt, setUpdatedAt] = useState(
    dayjs(getRowEditDate(params.data)),
  );

  const { updateState: updateModalState } = useModalStore((state) => state);

  // Hooks
  const [{ mutateAsync: deleteTable }, { mutateAsync: deleteFolder }] = [
    useDeleteTable(),
    useDeleteFolder(),
  ];
  const { folderMap, showFolders, updateState, selectedRows, isSelected } =
    useTableState((state) => ({
      folderMap: state.folderMap,
      showFolders: state.showFolders,
      updateState: state.updateState,
      selectedRows: state.selected,
      isSelected: state.selected.has(params.data?._id as TRowId),
    }));

  const { mutateAsync: generateTableLink, isPending } = useMutation({
    mutationFn: (tableId: string) => tableService.generateShareLink(tableId),
  });

  const handleGenerateLink = async (e: React.MouseEvent, tableId: string) => {
    e.stopPropagation();

    generateTableLink(tableId, {
      onSuccess: (response) => {
        if (response.success) {
          updateModalState({
            isOpenModal: true,
            modalData: {
              modalType: "share-table-link",
              metaData: { link: response.data.link },
            },
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Something went wrong",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      },
    });
  };

  // Derived State
  const data = params.data;
  const childCount = useMemo(() => getChildCount(data), [data]);

  const isFolder = data?.type === "folder";
  const isClosed = useTableState(
    (state) =>
      state.folderClosedStatus.get(params.data?._id as TRowId) ??
      !DEFAULT_FOLDER_OPEN,
  );

  // Effects
  useInterval(() => {
    setUpdatedAt(dayjs(getRowEditDate(params.data)));
  }, 30000);

  useEffect(() => {
    const element = elemRef.current;
    const stopPropagation = (e: Event) => e.stopPropagation();
    if (element) {
      element.addEventListener("mousewheel", stopPropagation);
      return () => {
        element.removeEventListener("mousewheel", stopPropagation);
      };
    }
  }, []);

  // Handlers
  const handleContextMenu = (e: React.MouseEvent) => {
    menuDisclosure.onOpen();
    e.preventDefault();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (selectedRows.size) {
      params.node.setSelected(!isSelected);
      return;
    }
    if (e.key === "Enter" && e.shiftKey) {
      updateState({ editing: data?._id });
    } else if (e.key === "Backspace" && e.shiftKey) {
      if (data?.type === "folder") {
        deleteFolder(data._id);
      } else if (data?.type === "table") {
        deleteTable(data._id);
      }
    }
    if (e.key === "Escape") {
      updateState({ editing: null });
      useTableState.getState().selected.delete(data?._id as TRowId);
      updateState({ selected: new Set(useTableState.getState().selected) });
    }
  };

  const handleRowCheckbox = (e: React.MouseEvent) => {
    e.stopPropagation();
    params.node.setSelected(!isSelected);
  };

  // Rendering Logic
  if (!data) return null;
  params.registerRowDragger(params.eParentOfValue, undefined, data?.name, true);

  const folderIcons = (
    <>
      {isClosed ? (
        <Icons.FolderClosedIcon className="size-4 shrink-0" />
      ) : (
        <Icons.FolderOpenIcon className="size-4 shrink-0" />
      )}
    </>
  );

  const folderPathLabel = (
    <Box className="flex flex-row place-content-end items-center gap-1">
      {data.path
        .map((id) => folderMap?.get(id)?.name)
        .flatMap((name, index) => [
          <Icons.RightArrowIcon2 key={2 * index} className="size-4 shrink-0" />,
          <Text key={index}> {name}</Text>,
        ])}
    </Box>
  );

  const tableIcon = <Icons.ViewTableIcon className="size-4 shrink-0" />;

  const name = (
    <>
      <Tooltip label={data.name?.length > 40 ? data.name : undefined}>
        <Text className="shrink truncate">{data?.name}</Text>
      </Tooltip>
      {isFolder ? (
        <Tooltip label={`Tables in this folder (${childCount ?? 0})`}>
          <Text className="shrink-0 font-thin text-[#8080808a]">
            ({childCount ?? 0})
          </Text>
        </Tooltip>
      ) : null}
    </>
  );

  return (
    <Stack
      onContextMenu={handleContextMenu}
      onKeyDown={handleKeyDown}
      className="folder flex !size-full !flex-row place-items-center items-center gap-2 px-2"
    >
      <Box
        className="flex flex-row gap-2 rounded hover:!bg-[#5024dc] hover:!text-[white]"
        onClickCapture={handleRowCheckbox}
      >
        {isSelected ? (
          <Icons.CheckIcon className="size-4 shrink-0" />
        ) : isFolder ? (
          folderIcons
        ) : (
          tableIcon
        )}
      </Box>
      {isFolder && (
        <Icons.ChevronDownIcon
          className={`size-4 shrink-0 transition-transform ${
            isClosed ? "rotate-[-90deg]" : ""
          }`}
        />
      )}
      {name}
      <Box className="ml-auto flex shrink-0 flex-row place-content-end items-center gap-3 text-[0.9em] font-thin">
        {!showFolders && data.parentFolder?._id !== ROOT_FOLDER_ID && (
          <Tooltip label={folderPathLabel} className="min-w-fit">
            <Text className="flex max-w-[10em] shrink-0 flex-row items-center gap-1 truncate text-[#8080808a]">
              <Icons.FolderClosedIcon className="size-4 shrink-0 !font-normal" />
              {data.parentFolder?.name}
            </Text>
          </Tooltip>
        )}
        <Tooltip label={dayjs(data?.updatedAt).format("DD MMM YYYY, hh:mm A")}>
          <Text className="shrink-0 text-[#8080808a]">
            {dayjs.duration(updatedAt.diff(dayjs())).humanize(true)}
          </Text>
        </Tooltip>
        {!isFolder && (
          <Tooltip label="Share Table">
            <Button
              className="!p-0 !w-[20px] !h-[30px] !border-none"
              variant={"outline"}
              size={"sm"}
              onClickCapture={(e) => {
                handleGenerateLink(e, params.data?._id || "");
              }}
              isLoading={isPending}
            >
              <Icons.ShareIcon />
            </Button>
          </Tooltip>
        )}

        <RowMenu
          aria-label="Table Row Menu"
          variant={"ghost"}
          node={params.node}
          disclosure={menuDisclosure}
          className="shrink-0"
        />
      </Box>
    </Stack>
  );
};

export const TableRowRenderer = (params: CustomCellRendererProps<TRow>) => {
  const editing = useTableState((state) => state.editing);
  if (editing === params.data?._id) {
    return <TableRowEditRenderer {...params} />;
  } else {
    return <TableRowRendererComponent {...params} />;
  }
};
