import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import type { ColCondition } from "@/types/table.types";
import { FilterType } from "@/types/common.types";
import { availableGates } from "./constants";

interface Props {
  filter: FilterType;
  filters: FilterType[];
  updateFilter: (filter: FilterType[]) => void;
}

function AvailableGates({ filter, filters, updateFilter }: Props) {
  const hanleClick = (condition: ColCondition) => {
    const updatedFilters = filters.map((f) => {
      if (f.id === filter.id) {
        return {
          ...f,
          gateCondition: condition,
        };
      }
      return f;
    });

    updateFilter(updatedFilters);
  };

  return (
    <Popover placement="right-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <button className="w-full rounded border px-2 py-1 text-sm font-medium capitalize text-[#000000]/90 duration-300 hover:bg-[#ECEEF1]">
              {filter?.gateCondition?.name}
            </button>
          </PopoverTrigger>
          <PopoverContent className="!w-[80px]">
            <PopoverArrow />
            <PopoverBody className="flex flex-col">
              {availableGates.map((gate, index) => (
                <button
                  key={`${gate.name}-${index}-${Math.random()}`}
                  className={`truncate rounded-md px-2 py-1.5 text-start text-[13.75px] text-[#000000]/90 duration-300 hover:bg-[#e7e8ec] ${
                    filter?.gateCondition.value === gate?.value &&
                    "bg-[#e7e8ec]"
                  }`}
                  onClick={() => {
                    hanleClick(gate);
                    onClose();
                  }}
                >
                  {gate.name}
                </button>
              ))}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}

export default AvailableGates;
