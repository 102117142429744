import Icons from "@/components/Icons";
import { useProviderList } from "@/hooks/useProviderData";
import { APIResponse } from "@/lib/apiCalls";
import providerService from "@/services/providers.service";
import { useProviderStore } from "@/stores/provider.store";
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { useState } from "react";

const NewApiKeyModal = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [apiKey, setApiKey] = useState("");
  const [isPreferKey, setIsPreferKey] = useState<boolean>();
  const [secretKey, setSecretKey] = useState("");
  const [icyPeasUserId, setIcyPeasUserId] = useState(""); // TODO: make it dynamic

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: {
      apiKey: string;
      secretKey: string;
      providerId?: string;
      providerName: string;
      userId?: string;
      preferKey?: boolean;
      alwaysPreferKey?: boolean;
    }) => providerService.saveKey(payload),
  });

  const { mutateAsync: updatePreference, isPending: isSaveKeyPending } =
    useMutation({
      mutationFn: (payload: {
        providerId: string;
        preferKey: boolean;
        alwaysPreferKey: boolean;
      }) => providerService.setDefaultKeys(payload),
    });

  const { isProviderApiKeyModalOpen, providerApiKeyModalData, updateState } =
    useProviderStore();
  const { data: providerLists } = useProviderList();

  const currentProvider = providerLists?.data?.find(
    (provider) => provider.name === providerApiKeyModalData?.providerName,
  );

  const onSave = async () => {
    const responseHandlers = {
      onSuccess: (response) => {
        if (!response.success) {
          toast({
            position: "top-right",
            title: "Error",
            description: response.error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        queryClient.refetchQueries({
          queryKey: ["providers"],
          type: "all",
        });

        queryClient.refetchQueries({
          queryKey: ["smartLeadsCampaigns"],
        });

        queryClient.refetchQueries({
          predicate: (query) => {
            console.log("query.queryKey", query.queryKey);

            return (query.queryKey[query.queryKey.length - 1] as any)
              ?.isRefetch;
          },
        });
        onClose();

        toast({
          position: "top-right",
          title: "Success",
          description: response.data?.message || "API key saved successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    } as Partial<UseMutationOptions<APIResponse, Error, unknown>>;
    if (apiKey) {
      await mutateAsync(
        {
          apiKey: apiKey.trim(),
          secretKey: secretKey.trim(),
          providerId: currentProvider?._id || "",
          providerName: providerApiKeyModalData?.providerName || "",
          userId: icyPeasUserId,
          preferKey: currentProvider?.metaData?.preferKey ?? isPreferKey,
          alwaysPreferKey: isPreferKey,
        },
        responseHandlers,
      );
    } else if (isPreferKey !== undefined) {
      await updatePreference(
        {
          providerId: currentProvider?._id || "",
          preferKey: currentProvider?.metaData?.preferKey ?? isPreferKey,
          alwaysPreferKey: isPreferKey,
        },
        responseHandlers,
      );
    }
  };

  const onClose = () => {
    updateState({ isProviderApiKeyModalOpen: false });
  };

  return (
    <Modal
      isCentered
      isOpen={isProviderApiKeyModalOpen}
      size={"xl"}
      onClose={onClose}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <ModalHeader>
          {`${providerApiKeyModalData?.title} Credentials`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="m-auto flex size-full flex-col justify-center">
            <div className="flex flex-col  justify-center">
              <p className="text-left font-medium">Enter your key</p>
              <Input
                className="openAIKey mt-1 border-2 border-black px-4 py-1"
                placeholder={
                  currentProvider?.metaData?.accessToken || "********"
                }
                onChange={(e) => setApiKey(e.target.value)}
                value={apiKey}
              />
              {providerApiKeyModalData.secretKeyInput && (
                <div className="mt-4">
                  <p className="text-left font-medium">Enter your secret key</p>
                  <Input
                    className="openAIKey mt-1 border-2 border-black px-4 py-1"
                    placeholder={
                      currentProvider?.metaData?.secretKey || "********"
                    }
                    onChange={(e) => setSecretKey(e.target.value)}
                    value={secretKey}
                  />
                </div>
              )}
              {providerApiKeyModalData.extraKeyField && (
                <div className="mt-4">
                  <p className="text-left font-medium">
                    Enter your {providerApiKeyModalData.extraKeyField.label}
                  </p>
                  <Input
                    className="openAIKey mt-1 border-2 border-black px-4 py-1"
                    placeholder={
                      currentProvider?.metaData?.userId || "********"
                    }
                    onChange={(e) => setIcyPeasUserId(e.target.value)}
                    value={icyPeasUserId}
                  />
                </div>
              )}
              <div className="mt-4 flex flex-row items-center gap-2">
                <Checkbox
                  id="default"
                  isDisabled={
                    !currentProvider?.metaData?.accessToken && !apiKey
                  }
                  isChecked={
                    isPreferKey ?? !!currentProvider?.metaData?.alwaysPreferKey
                  }
                  onChange={(e) => {
                    setIsPreferKey(e.target.checked);
                  }}
                />
                <FormLabel
                  htmlFor="default"
                  className="!m-0 w-max text-[#33363b]"
                >
                  Prefer this key
                </FormLabel>
                <Tooltip
                  hasArrow
                  placement="top"
                  label={
                    "If checked, this key will be selected by default when you create a new integration."
                  }
                >
                  <Box>
                    <Icons.InformationIcon />
                  </Box>
                </Tooltip>
              </div>
              <Button
                className="integrationBtn !hover:text-primary my-4"
                isDisabled={
                  !apiKey &&
                  (isPreferKey === undefined ||
                    isPreferKey ===
                      !!currentProvider?.metaData?.alwaysPreferKey)
                }
                onClick={onSave}
                isLoading={isPending || isSaveKeyPending}
              >
                {currentProvider ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewApiKeyModal;
