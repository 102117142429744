/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";
import WatchTutorial from "@/components/Common/WatchTutorial";
import CustomAccordion from "@/components/Common/CustomAccordion";
import {
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { TSelectedColumnOption } from "@/types/common.types";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";

const FindLinkedInPostsWithPersana = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const [companyName, setCompanyName] = useState<TSelectedColumnOption | null>(
    null,
  );
  const [companyLinkedInUrl, setCompanyLinkedInUrl] =
    useState<TSelectedColumnOption | null>(null);
  const [companyDomain, setCompanyDomain] =
    useState<TSelectedColumnOption | null>(null);
  const [personLinkedIn, setPersonLinkedIn] =
    useState<TSelectedColumnOption | null>(null);
  const [limitResults, setLimitResults] = useState(10);
  const [tabIndex, setTabIndex] = useState(0);
  const [companyTabIndex, setCompanyTabIndex] = useState(2);
  const payload = useMemo(() => {
    const payload: {
      queryType:
        | "company-website"
        | "company-name"
        | "company-linkedin"
        | "person-linkedin";
      payload: TSelectedColumnOption | null;
      limit: number;
    } = {
      queryType: "person-linkedin",
      payload: personLinkedIn,
      limit: limitResults,
    };
    switch (tabIndex) {
      case 0: // person-linkedin
        return {
          ...payload,
        };
      case 1:
        switch (companyTabIndex) {
          case 0:
            return {
              ...payload,
              queryType: "company-name",
              payload: companyName,
            };
          case 1:
            return {
              ...payload,
              queryType: "company-linkedin",
              payload: companyLinkedInUrl,
            };
          case 2:
            return {
              ...payload,
              queryType: "company-website",
              payload: companyDomain,
            };
          default:
            return payload;
        }
      default:
        return payload;
    }
  }, [
    personLinkedIn,
    tabIndex,
    companyTabIndex,
    companyName,
    companyLinkedInUrl,
    companyDomain,
    limitResults,
  ]);
  const isValid = useMemo(() => {
    return !!payload.payload;
  }, [payload]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;
      const payload: TSelectedColumnOption = enrichmentData?.payload ?? null;
      const queryType = enrichmentData?.queryType ?? "person-linkedin";
      const limit: number = enrichmentData?.limit ?? 10;
      setLimitResults(limit);
      switch (queryType) {
        case "person-linkedin":
          setPersonLinkedIn(payload);
          setTabIndex(0);
          break;
        case "company-name":
          setCompanyName(payload);
          setTabIndex(1);
          setCompanyTabIndex(0);
          break;
        case "company-linkedin":
          setCompanyLinkedInUrl(payload);
          setTabIndex(1);
          setCompanyTabIndex(1);
          break;
        case "company-website":
          setCompanyDomain(payload);
          setTabIndex(1);
          setCompanyTabIndex(2);
          break;
        default:
          break;
      }
    }
  }, [viewMode, selectedColumnToEdit]);
  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={
            "Watch the tutorial to learn how to get LinkedIn Posts with Persana."
          }
          link={""}
          credits={1}
        />
        <Tabs
          px={0}
          index={tabIndex}
          onChange={(index) => setTabIndex(index)}
          variant="enclosed"
          isFitted
          size={"xs"}
          colorScheme="purple"
          mt="1rem"
        >
          <TabList>
            <Tab>Personal Profile</Tab>
            <Tab>Company Profile</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px={0}>
              <CustomAccordion
                title="Personal LinkedIn URL"
                isDefaultOpen={true}
                isOpen={true}
              >
                <SelectColumnDropdown
                  selectedOption={personLinkedIn}
                  setSelectedOption={(value: TSelectedColumnOption | null) =>
                    setPersonLinkedIn(value)
                  }
                />
              </CustomAccordion>
            </TabPanel>
            <TabPanel px="0">
              <p className="text-sm">Select company profile by:</p>
              <Tabs
                mt="4"
                size={"xs"}
                colorScheme="purple"
                isFitted
                variant={"enclosed-colored"}
                index={companyTabIndex}
                onChange={(index) => setCompanyTabIndex(index)}
              >
                <TabList>
                  <Tab>Name</Tab>
                  <Tab>LinkedIn URL</Tab>
                  <Tab>Domain</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel px={0}>
                    <CustomAccordion
                      title="Company Name"
                      isDefaultOpen={true}
                      isOpen={true}
                    >
                      <SelectColumnDropdown
                        selectedOption={companyName}
                        setSelectedOption={(
                          value: TSelectedColumnOption | null,
                        ) => setCompanyName(value)}
                      />
                    </CustomAccordion>
                  </TabPanel>
                  <TabPanel px={0}>
                    <CustomAccordion
                      title="Company LinkedIn URL"
                      isDefaultOpen={true}
                      isOpen={true}
                    >
                      <SelectColumnDropdown
                        selectedOption={companyLinkedInUrl}
                        setSelectedOption={(
                          value: TSelectedColumnOption | null,
                        ) => setCompanyLinkedInUrl(value)}
                      />
                    </CustomAccordion>
                  </TabPanel>
                  <TabPanel px={0}>
                    <CustomAccordion
                      title="Company Domain"
                      isDefaultOpen={true}
                      isOpen={true}
                    >
                      <SelectColumnDropdown
                        selectedOption={companyDomain}
                        setSelectedOption={(
                          value: TSelectedColumnOption | null,
                        ) => setCompanyDomain(value)}
                      />
                    </CustomAccordion>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>
          </TabPanels>
          <CustomAccordion
            title="Limit Results"
            isDefaultOpen={true}
            isOpen={true}
          >
            <Input
              placeholder="Enter number of results"
              value={limitResults}
              onChange={(e) =>
                isNaN(+e.target.value) ? null : setLimitResults(+e.target.value)
              }
            />
          </CustomAccordion>
        </Tabs>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment isDisabled={!isValid} apiPayload={{ ...payload }} />
    </>
  );
};

export default FindLinkedInPostsWithPersana;
