import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";
import { generateUniqueId } from "@/lib/utils";
import type { FilterType } from "@/types/table.types";

interface Props {
  filter: FilterType;
}

const Actions = ({ filter }: Props) => {
  const updateState = useTableStore((state) => state.updateState);
  const tableFilters = useTableStore((state) => state.tableFilters);

  const actions = [
    {
      name: "Duplicate",
      value: "duplicate",
      icon: <Icons.CopyIcon className="text-lg" />,
    },
    {
      name: "Delete",
      value: "delete",
      icon: <Icons.DeleteIcon className="text-lg" />,
    },
  ];

  const handleClick = (option: (typeof actions)[0]) => {
    if (option.value === "duplicate") {
      // handle duplicate
      updateState({
        tableFilters: {
          ...tableFilters,
          filters: [
            ...tableFilters.filters!,
            {
              ...filter,
              id: generateUniqueId(),
            },
          ],
        },
      });
    } else {
      updateState({
        tableFilters: {
          ...tableFilters,
          filters: tableFilters.filters?.filter((f) => f.id !== filter.id),
        },
      });
    }
  };

  return (
    <Popover placement="right-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <button className="rounded px-2 py-1 text-sm font-medium text-[#000000]/90 duration-300 hover:bg-[#ECEEF1]">
              <Icons.MoreIcon className="text-xl text-black" />
            </button>
          </PopoverTrigger>
          <PopoverContent width="fit-content">
            <PopoverArrow />
            <PopoverBody className="flex w-fit max-w-[300px] flex-col">
              {actions.map((option, index) => (
                <button
                  key={`${option.name}-${index}`}
                  className={`flex items-center gap-2 truncate rounded-md px-2 py-1.5 text-start text-[13.75px] text-[#000000]/90 duration-300 hover:bg-[#e7e8ec]`}
                  onClick={() => {
                    handleClick(option);
                    onClose();
                  }}
                >
                  {option.icon}
                  <span>{option.name}</span>
                </button>
              ))}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default Actions;
