import apiKeyService from "@/services/api-keys.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useBulkDeleteApiKeys = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ ids }: { ids: string[] }) =>
      apiKeyService.bulkDeleteApiKeys(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["apiKeys"] });
    },
  });
};

export default useBulkDeleteApiKeys;
