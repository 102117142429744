import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";

const TutorialModal = () => {
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  return (
    <Modal
      isCentered
      isOpen={isOpenModal && modalData.modalType === "tutorial"}
      size={"2xl"}
      onClose={() => {
        updateState({
          isOpenModal: false,
          modalData: { modalType: "", metaData: "" },
        });
      }}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <ModalHeader>How to use an enrichment?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modalData.metaData.videoLink ? (
            modalData.metaData.videoLink.indexOf("youtube.com/embed") !== -1 ? (
              <iframe
                src={modalData.metaData.videoLink}
                title={modalData.metaData.name}
                className="w-full min-h-[400px]"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ) : (
              <video className="rounded-[7px]" width={"100%"} controls>
                <source src={modalData.metaData.videoLink} type="video/mp4" />
              </video>
            )
          ) : null}
        </ModalBody>
        <ModalFooter>
          <button
            style={{
              background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
            }}
            className="text-l flex min-w-fit cursor-pointer items-center justify-center gap-2 rounded px-4 py-2 font-bold text-white"
            onClick={() => {
              updateState({
                isOpenModal: true,
                modalData: { modalType: "get-started", metaData: "" },
              });
            }}
          >
            Try now
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TutorialModal;
