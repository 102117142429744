import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

import Icons from "../Icons";
import { useEnrichStore } from "@/stores/enrich.store";

const Search = () => {
  const searchInput = useEnrichStore((state) => state.searchInput);
  const updateState = useEnrichStore((state) => state.updateState);

  return (
    <>
      <div className="flex items-center justify-between mb-[12px]">
        <p className="text-[20px] leading-[24px]">Enrich your search</p>
        <Button
          colorScheme="primary"
          variant="outline"
          leftIcon={<Icons.BackIcon />}
          className="!bg-light !text-textPurple !border-none h-[40px] rounded-md"
          onClick={() => {
            updateState({
              isOpenEnrichModal: false,
            });
          }}
        >
          Go Back
        </Button>
      </div>
      <InputGroup width="100%" className="mb-[20px]">
        <InputLeftElement>
          <Icons.SearchIcon size={20} color="gray" />
        </InputLeftElement>
        <Input
          style={{ borderRadius: "20px" }}
          placeholder="What you are looking for?"
          variant="outline"
          onChange={(e) => {
            updateState({
              searchInput: e.target.value,
              activeCategory: "",
            });
          }}
          value={searchInput}
        />
      </InputGroup>
    </>
  );
};

export default Search;
