import { twMerge } from "tailwind-merge";

import MainLoader from "@/assets/lottieFiles/main_loader.lottie";
import LottiePlayer from "./LottiePlayer";

function PrimaryLoader({
  className,
  loadingMessage,
}: {
  className?: string;
  loadingMessage?: string;
}) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`${twMerge("h-[100px] w-[100px]", className)}`}>
        <LottiePlayer src={MainLoader} />
      </div>
      {loadingMessage && <div>{loadingMessage}</div>}
    </div>
  );
}

export default PrimaryLoader;
