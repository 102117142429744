import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";
import { FilterType } from "@/types/common.types";

interface Props {
  filter: FilterType;
  filters: FilterType[];
  updateFilter: (filter: FilterType[]) => void;
  fields: FilterType["selectedField"][];
}

function AvailableFields({ filter, filters, updateFilter, fields }: Props) {
  const hanleClick = (field: any) => {
    const updatedFilters = filters.map((f) => {
      if (f.id === filter.id) {
        return {
          ...f,
          selectedField: field,
        };
      }
      return f;
    });

    updateFilter(updatedFilters);
  };

  return (
    <Popover placement="right-start">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <button className="flex w-full grow items-center justify-between gap-1 rounded bg-[#e7e8ec] px-2 py-1 text-sm font-medium text-[#000000]/90 duration-300 hover:bg-[#ECEEF1]">
              <span className="truncate">{filter?.selectedField?.name}</span>
              <Icons.ArrowDownIcon
                className={`text-sm text-[#000000] duration-300 shrink-0 ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </button>
          </PopoverTrigger>
          <PopoverContent
            width="fit-content"
            className="max-h-[300px] overflow-y-auto"
          >
            <PopoverArrow />
            <PopoverBody className="flex w-fit max-w-[300px] flex-col ">
              {fields.map(
                (field: FilterType["selectedField"], index: number) => (
                  <button
                    key={`${field._id}-filter-available-fields-${index}`}
                    className={`truncate rounded-md px-2 py-1.5 text-start text-[13.75px] text-[#000000]/90 duration-300 hover:bg-[#e7e8ec] ${filter.selectedField._id === field._id ? "bg-[#e7e8ec]" : ""}`}
                    onClick={() => {
                      hanleClick(field);
                      onClose();
                    }}
                  >
                    {field.name}
                  </button>
                ),
              )}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}

export default AvailableFields;
