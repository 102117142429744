import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FilterModel } from "ag-grid-community";
import { useGridFilter } from "./Table/hooks/useGridFilter";
import Icons from "../Icons";
import { useDebouncedValue } from "@/hooks/useDebouncedValue";
import { useTableStore } from "./Table/hooks/useGridState.store";
interface ITableSearchProps {
  gridFields: string[];
}
const TableSearchInfinite = ({
  gridFields: gridFieldsProp,
}: ITableSearchProps) => {
  const gridFields = useTableStore(() => gridFieldsProp);
  const [controlledValue, setControlledValue] = useState("");
  const [query, setQuery] = useState("");
  const { applyFilters: applyGridFilters, isFilterApplied } = useGridFilter();

  const defferedQuery = useDebouncedValue(query, 1000);
  // apply query to grid
  useEffect(() => {
    applyFilters(defferedQuery);
  }, [defferedQuery]);

  useEffect(() => {
    if (!isFilterApplied) {
      setControlledValue("");
    }
  }, [isFilterApplied]);
  // apply filters to grid
  const applyFilters = (search: string) => {
    if (!search && isFilterApplied) {
      applyGridFilters(null);
      return;
    }
    const filter: FilterModel = Object.fromEntries(
      gridFields.map((field) => [
        field,
        {
          filter: search,
          filterType: "text",
          type: "contains",
        },
      ]),
    );
    applyGridFilters(filter);
  };
  return (
    <InputGroup minW={"250px"} fontSize={"14px"}>
      <InputLeftElement pointerEvents="none">
        <Icons.SearchIcon color="black.300" />
      </InputLeftElement>
      <Input
        h={"38px"}
        borderRadius={"20px"}
        size={"md"}
        placeholder="Search Results.."
        onChange={(e) => {
          setControlledValue(e.target.value);
          setQuery(e.target.value);
        }}
        value={controlledValue}
      />
      <InputRightElement
        pointerEvents={controlledValue.length > 0 ? "auto" : "none"}
        onClick={() => {
          setControlledValue("");
          applyFilters("");
        }}
        opacity={controlledValue.length > 0 ? 1 : 0}
        transition={"opacity 0.3s ease"}
        cursor={"pointer"}
      >
        <Icons.CloseIcon color="black.300" />
      </InputRightElement>
    </InputGroup>
  );
};

const TableSearchClientSide = () => {
  const gridApi = useTableStore((state) => state.gridApi);
  const [query, setQuery] = useState("");

  const defferedQuery = useDebouncedValue(query, 200);
  useEffect(() => {
    gridApi?.updateGridOptions({
      quickFilterText: defferedQuery,
    });
  }, [defferedQuery]);
  useEffect(() => {
    function showNoDataOverlay() {
      const filteredDataCount = gridApi?.getDisplayedRowCount();
      if (!filteredDataCount) gridApi?.showNoRowsOverlay();
      else gridApi?.hideOverlay();
    }
    gridApi?.addEventListener("filterChanged", showNoDataOverlay);
    return () => {
      gridApi?.removeEventListener("filterChanged", showNoDataOverlay);
    };
  }, [gridApi]);
  return (
    <InputGroup minW={"370px"} fontSize={"14px"}>
      <InputLeftElement pointerEvents="none">
        <Icons.SearchIcon color="black.300" />
      </InputLeftElement>
      <Input
        h={"38px"}
        borderRadius={"20px"}
        size={"md"}
        placeholder="Search Results.."
        onChange={(e) => setQuery(e.target.value)}
        value={query}
      />
      <InputRightElement
        pointerEvents={query.length > 0 ? "auto" : "none"}
        onClick={() => {
          setQuery("");
        }}
        opacity={query.length > 0 ? 1 : 0}
        transition={"opacity 0.3s ease"}
        cursor={"pointer"}
      >
        <Icons.CloseIcon color="black.300" />
      </InputRightElement>
    </InputGroup>
  );
};

export const TableSearch = (props: ITableSearchProps) => {
  const rowModalType = useTableStore((state) =>
    state.gridApi?.getGridOption("rowModelType"),
  );
  if (rowModalType === "clientSide") {
    return <TableSearchClientSide />;
  } else if (rowModalType === "infinite") {
    return <TableSearchInfinite {...props} />;
  }
  return null;
};
