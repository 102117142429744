class questConfig {
  static API_KEY = import.meta.env.VITE_QUEST_API_KEY;
  static API_SECRET = import.meta.env.VITE_QUEST_API_SECRET;
  static ENTITY_ID = import.meta.env.VITE_QUEST_ENTITY_ID;
  static ONBOARDING_QUIZ_ID = import.meta.env.VITE_QUEST_ONBOARDING_QUIZ_ID;
  static ADMIN_ID = import.meta.env.VITE_QUEST_ADMIN_ID;
  static ADMIN_TOKEN = import.meta.env.VITE_QUEST_ADMIN_TOKEN;
  static ONBOARDING_QUIZ_HEADERS = [
    { name: "Tell us more about you and your company", desc: "" },
    {
      name: "A little more details will help us to guide you the best",
      desc: "",
    },
  ];
  static ONBOARDING_QUIZ_FIELD_GROUPING = [
    [1, 2, 3],
    [4, 5, 6],
  ];
  static ONBOARDING_QUIZ_PROGRESSBAR_HEADERS = ["About You", "Details"];
  static QUEST_BACKEND_URL = import.meta.env.VITE_QUEST_BACKEND_URL;

  static ONBOARDING_QUIZ_FIELD_TYPE = {
    "ec-ec40722b-9e49-4bae-be49-3db462fc9b9d": "textArea",
  };
  static ONBOARDING_QUIZ_FIELDS = {
    FIRST_NAME_FIELD: {
      quest_id: "ca-1f4965f0-1ff4-46ce-bfdf-3ce9342a1859",
      persana_backend_id: "firstName",
    },
    JOB_TITLE_FIELD: {
      quest_id: "ca-8a314d80-6a94-4788-8cc1-ed04f5cfd8cf",
      persana_backend_id: "jobTitle",
    },
    COMPANY_URL_FIELD: {
      quest_id: "ca-dd1f8926-f187-4e88-9399-aa1438f8cefb",
      persana_backend_id: "companyURL",
    },
    COMPANY_DESCRIPTION_FIELD: {
      quest_id: "ca-79aa9c44-0454-4223-b34f-3d36d4d0089e",
      persana_backend_id: "companyDescription",
    },
    ASSIST_YOU_FIELD: {
      quest_id: "ca-46cd68bc-f671-4545-89d2-9f115ba51408",
      persana_backend_id: "assistYouWith",
    },
    COMPANY_TARGET_FIELD: {
      quest_id: "ca-0c9b4829-148d-45de-88a2-3facc2d8456f",
      persana_backend_id: "companyTarget",
    },
  };
  static FEEDBACK_WORKFLOW_FORM_ID = "c-71837547-e00e-4688-9144-d9be4c195b8f";
  // static FEEDBACK_WORKFLOW_FORM_IDS = [
  //   "q-general-feedback",
  //   "q-report-a-bug",
  //   "q-request-a-feature",
  //   "q-contact-us",
  // ];
  static CONTACT_US_REDIRECT_LINK = "https://calendly.com/sriya-persana/30min";
  static FLAG_CONSTRAINTS = {
    ONBOARDING: "quest.sdk.onboardings",
    FEEDBACK_WORKFLOW: "quest.sdk.feedback_workflow",
  };
  static GET_STARTED_SCREEN_ID = "c-54a06289-532d-45c5-b3fd-10eed1c8b327";
  static REFERRAL_SCREN_ID = "c-d3856be6-3e24-4b62-be6d-4d547f11dda3";
}

export default questConfig;
