import { useCallback, useEffect, useMemo } from "react";
import { IRowNode } from "ag-grid-community";
import Papa from "papaparse";
import { useSwalPopover } from "@/hooks/useSwalPopover";
import { BulkRowUpdatePayload } from "@/types/table.types";
import {
  getFirstCell,
  getGridRoot,
  useSelectionStore,
} from "./useSelectionStore";
import { useBulkUpdate } from "./useBulkUpdate";
import { Cell } from "./types";

export const usePasteCSV = () => {
  const showPopover = useSwalPopover();
  const features = useSelectionStore((state) => state.features);
  const range = useSelectionStore((state) => state.range);
  const mutateBulkRows = useBulkUpdate();
  const gridApi = useSelectionStore((state) => state.gridApi);
  const setRange = useSelectionStore((state) => state.setRange);
  const pasteCSV = useCallback(
    async (csvContent: string) => {
      if (!features.paste || !gridApi) return;
      const focusedCell = gridApi.getFocusedCell();
      const firstCell: Cell | null =
        getFirstCell(range, gridApi) ??
        (focusedCell
          ? {
              rowIndex: focusedCell?.rowIndex ?? 0,
              colId: focusedCell?.column.getColId() ?? "",
            }
          : null);
      if (!firstCell) return;

      const allColumns = gridApi.getColumns();
      const totalRowCount = gridApi.getDisplayedRowCount();
      if (!allColumns) return;
      const startRowIndex = firstCell.rowIndex;
      const startColIndex = allColumns.findIndex(
        (col) => col.getColId() === firstCell.colId,
      );

      if (startColIndex === -1) return;

      const result = Papa.parse<string[]>(csvContent, {
        header: false,
        skipEmptyLines: true,
        delimitersToGuess: ["\t"],
      });
      let rows = result.data;

      if (result.errors.length > 0 && rows?.[0]?.length !== 1) {
        rows = [[csvContent]];
      }
      if (
        startColIndex + rows[0].length - 1 >= allColumns.length ||
        startRowIndex + rows.length - 1 >= totalRowCount
      ) {
        const { isConfirmed } = await showPopover({
          icon: "error",
          title: "Cannot paste into multiple cells",
          text: "Number of rows and columns at paste location are not enough",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#b91c1c",
          confirmButtonText: "Paste in single cell",
          showCancelButton: true,
          reverseButtons: true,
          allowEscapeKey: true,
        });
        if (!isConfirmed) {
          return;
        }
        rows = [[rows.toString()]];
        setRange({ startCell: firstCell, endCell: firstCell });
      } else {
        setRange({
          startCell: firstCell,
          endCell: {
            rowIndex: startRowIndex + rows.length - 1,
            colId: allColumns[startColIndex + rows[0].length - 1].getColId(),
          },
        });
      }
      if (rows.length > 1 || rows?.[0].length > 1) {
        const { isConfirmed, isDenied } = await showPopover({
          icon: "question",
          title: "Paste into multiple cells?",
          text: "Are you sure you want to paste this into multiple cells?",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#b91c1c",
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          denyButtonColor: "gray",
          showDenyButton: true,
          denyButtonText: "Paste in single cell",
        });
        if (isDenied) {
          rows = [[rows.toString()]];
          setRange({ startCell: firstCell, endCell: firstCell });
        } else if (!isConfirmed) {
          return;
        }
      }

      const updatePayload: BulkRowUpdatePayload[] = [];
      rows.forEach((row, rowIndex) => {
        const rowNode: IRowNode | null =
          gridApi.getDisplayedRowAtIndex(startRowIndex + rowIndex) ?? null;
        if (rowNode) {
          row.forEach((value, colIndex) => {
            const column = allColumns[startColIndex + colIndex];
            if (column) {
              updatePayload.push({
                columnId: column.getColId(),
                cellValue: {
                  value,
                },
                rowId: rowNode.data.rowData._id,
              });
            }
          });
        }
      });

      mutateBulkRows({ payload: updatePayload });
    },
    [gridApi, range, showPopover, setRange, mutateBulkRows, features],
  );

  return pasteCSV;
};

export const usePasteShortcut = () => {
  const gridApi = useSelectionStore((state) => state.gridApi);
  const pasteCSV = usePasteCSV();
  const container = useMemo(() => {
    const gridRoot = getGridRoot(gridApi?.getGridId());
    return (
      (gridRoot?.querySelector(".ag-center-cols-container") as HTMLElement) ??
      null
    );
  }, [gridApi]);
  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      if (!container.contains(document.activeElement)) return;
      const clipboardData = event.clipboardData;
      const pastedData = clipboardData?.getData("text");
      if (pastedData !== undefined) {
        pasteCSV(pastedData);
      }
    },
    [pasteCSV, container],
  );

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [handlePaste]);

  return pasteCSV;
};
