/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import leadService from "@/services/leads.service";

export const useStarterList = () => {
  return useQuery({
    queryKey: ["starter-list"],
    queryFn: () => leadService.getStarterList(),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};
