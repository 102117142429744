import { Avatar, Badge, Box, Link, Stack, Text } from "@chakra-ui/react";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CustomCellRendererProps } from "ag-grid-react";
import Icons from "@/components/Icons";
import { LeadEntry } from "@/types/accountMonitoring.types";
dayjs.extend(duration);
dayjs.extend(customParseFormat);
type CellRendererProps = CustomCellRendererProps<LeadEntry>;
type PropsWithTypeParam = CellRendererProps & {
  type: "current" | "previous";
};

export const UserDetailsRenderer = ({ data }: CellRendererProps) => {
  const avtarName = data?.firstName
    ? data?.firstName + " " + (data?.lastName || "")
    : data?.linkedinURL.match(/\/([^/]+)\/?$/)?.[1] || "";
  const userName = data?.firstName ? avtarName : "/" + avtarName;
  return (
    <Stack
      className="flex gap-4"
      direction={"row"}
      placeItems={"center"}
      h={"full"}
      w={"full"}
      overflow={"hidden"}
    >
      <Avatar
        color={"white"}
        bg={"#5024DC"}
        border={"solid"}
        borderWidth={"2px"}
        borderColor={"black"}
        size={"sm"}
        name={avtarName}
        src={data?.metaData?.profilePicture}
      ></Avatar>
      <Stack gap={1} lineHeight={"1em"} className="truncate">
        <Stack direction={"row"} gap={1} className="truncate">
          <Text
            className="truncate"
            fontSize={"14px"}
            textColor={!data?.firstName ? "grey" : "initial"}
          >
            {userName}
          </Text>
        </Stack>
        <Stack direction={"row"} gap={1}>
          {data?.linkedinURL && (
            <Link
              href={data?.linkedinURL}
              // target="_blank"
              className="flex items-center gap-1 text-[13px] font-normal !text-[#5024DC]"
            >
              <Icons.LinkedInIcon className="text-xs text-[#0077B5]" />
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const CompanyNameRender = ({ data, type }: PropsWithTypeParam) => {
  const companyName =
    data?.[type]?.companyName === "NA" ? "" : data?.[type]?.companyName;
  const logo = data?.[type]?.logo;
  const companyLink = data?.[type]?.url;
  if (!companyName) return null;
  return (
    <Stack
      className="flex gap-4 truncate"
      direction={"row"}
      placeItems={"center"}
      h={"full"}
      w={"full"}
    >
      <Avatar
        color={"white"}
        bg={"#5024DC"}
        border={"solid"}
        borderWidth={"2px"}
        borderColor={"black"}
        size={"sm"}
        name={companyName}
        src={logo}
      ></Avatar>

      <Stack gap={1} lineHeight={"1em"} className="truncate">
        <Box fontSize={"14px"} fontWeight={400} className="truncate">
          {companyName}
        </Box>
        {companyLink && (
          <Link
            fontSize={"13px"}
            fontWeight={"400"}
            href={companyLink}
            target="_blank"
            color={"#5024DC"}
          >
            {companyLink}
          </Link>
        )}
      </Stack>
    </Stack>
  );
};
export const StatusRenderer = (props: CellRendererProps) => {
  const status = props.data?.action;
  return (
    <Stack
      // w="full" h={"full"} placeContent={"center"} px={8}>
      className="flex h-full justify-center"
    >
      <div>
        {status === "changed_company" ? (
          <Badge
            px={3}
            borderRadius={"20px"}
            opacity={"1"}
            fontSize={"13px"}
            color={"#5024DC"}
            textTransform={"capitalize"}
          >
            New Job
          </Badge>
        ) : status === "change_title" ? (
          <Badge
            px={3}
            borderRadius={"20px"}
            opacity={"1"}
            fontSize={"13px"}
            color={"#5024DC"}
            textTransform={"capitalize"}
          >
            New Title
          </Badge>
        ) : status === "no_change" ? (
          <Badge
            px={3}
            borderRadius={"20px"}
            opacity={"0.5"}
            fontSize={"13px"}
            color={"#5024DC"}
            textTransform={"capitalize"}
          >
            No Change
          </Badge>
        ) : null}
      </div>
    </Stack>
  );
};

export const JobTitleRenderer = ({ data, type }: PropsWithTypeParam) => {
  const title = data?.[type]?.position === "NA" ? "" : data?.[type]?.position;
  const duration =
    type === "previous" ? "" : getSinceDuration(data?.current?.joinedAt);
  return (
    <Stack
      gap={1}
      w="full"
      h={"full"}
      placeContent={"center"}
      className="truncate"
    >
      <Text
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"1em"}
        className="truncate"
        color={"#677084"}
      >
        {title}
      </Text>
      <Text
        fontWeight={400}
        fontSize={"13px"}
        lineHeight={"1em"}
        className="truncate"
        color={"#7E889F"}
      >
        {duration}
      </Text>
    </Stack>
  );

  function getSinceDuration(date?: string) {
    const now = dayjs();
    const then = dayjs(date);
    const diff = dayjs.duration(now.diff(then));

    const years = diff.years();
    const months = diff.months();

    let result = "";
    if (years > 0) {
      result += `${years} year${years > 1 ? "s" : ""}`;
    }
    if (months > 0) {
      if (years > 0) {
        result += " ";
      }
      result += `${months} month${months > 1 ? "s" : ""}`;
    }
    return result ? `Since ${result}` : "";
  }
};

export const OtherRolesRenderer = ({ data }: CellRendererProps) => {
  const otherRoleCount = data?.previous ? 1 : 0; // FIXME: fix backend
  return (
    <Stack
      gap={1}
      w="full"
      h={"full"}
      placeContent={"center"}
      className="truncate"
    >
      <Text
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"1em"}
        color={"#5024DC"}
        className="truncate"
      >
        {otherRoleCount} other roles
      </Text>
    </Stack>
  );
};

export const LocationRenderer = ({ data }: CellRendererProps) => {
  return (
    <Stack
      gap={1}
      w="full"
      h={"full"}
      placeContent={"center"}
      overflow={"hidden"}
      className="truncate"
    >
      <Text
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"1em"}
        color={"#677084"}
        className="truncate"
      >
        {data?.metaData?.location?.default}
      </Text>
    </Stack>
  );
};
