/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridApi, IDatasource } from "ag-grid-community";
import { useEffect, useMemo, useRef } from "react";
import { UseDataSourceParams } from "../types";
import { useTableStore } from "./useGridState.store";
import { buildUrlParamsInfinite } from "../functions/buildUrlParamsInfinite";

export function useDataSourceServerSide<T>(params: UseDataSourceParams<T>) {
  const gridApi = useTableStore((state) => state.gridApi) as GridApi<T>;
  const setLoading = useTableStore((state) => state.setLoading);
  const queryFn = useRef<UseDataSourceParams<T>["dataSource"]>();
  const updateState = useTableStore((state) => state.updateState);
  const gridRef = useRef<GridApi<T>>();
  useEffect(() => {
    queryFn.current = params.dataSource;
    gridRef.current = gridApi;
  }, [gridApi, params.dataSource]);
  useEffect(() => {
    if (!gridApi) return;
    updateState({
      refreshData: async () => {
        setLoading(true);
        gridApi.refreshInfiniteCache();
      },
    });
  }, [gridApi]);
  return useMemo<{ datasource: IDatasource }>(
    () => ({
      cacheBlockSize: 50,
      paginationPageSize: 50,
      pagination: true,
      infiniteInitialRowCount: 50,
      datasource: {
        getRows: async (params) => {
          try {
            let data: T[] = [];
            const cache = params.context?.cache || {};
            const cacheTotal = params.context?.total ?? 0;
            const useCache = params.context?.useCache ?? false;
            if (!useCache) {
              const urlParams = buildUrlParamsInfinite(params);
              const result = await queryFn.current!(urlParams);
              const lastRow = result.totalDataCount as number;

              params.successCallback(result.data ?? [], lastRow);
              const newCache = { ...cache };
              result.data?.forEach((row, index) => {
                newCache[params.startRow + index] = row;
              });
              gridRef.current?.setGridOption("context", {
                cache: newCache,
                total: lastRow,
              });
              data = result.data ?? [];
              updateState({
                totalRows: lastRow,
              });
            } else {
              data = Array.from({
                length: params.endRow - params.startRow + 1,
              }).map((_, index) => cache[params.startRow + index] as T);
              params.successCallback(data, cacheTotal);
            }
            setLoading(false);
            gridRef.current?.hideOverlay();
            if (data?.length === 0) {
              gridRef.current?.showNoRowsOverlay();
            }
          } catch (error) {
            console.log(error);
            params.failCallback();

            setLoading(false);
            gridRef.current?.hideOverlay();
          }
        },
      },
    }),
    [],
  );
}

export function useUpdateCacheRow() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gridApi = useTableStore((state) => state.gridApi) as GridApi<any>;
  return (row: any, index: number) => {
    const context = gridApi.getGridOption("context") ?? {};
    const cache: { [key: number]: any } = context.cache;
    const total = gridApi.getGridOption("context")?.total;

    if (!cache) return;
    const [existingIndex] = (Object.entries(cache).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => value._id === row._id,
    ) ?? []) as unknown as [number, any];
    const placeArray = Array.from({ length: total! }, () => null);
    for (const index in cache) {
      placeArray[Number(index)] = cache[index];
    }
    if (existingIndex) placeArray.splice(Number(existingIndex), 1);
    placeArray.splice(Number(index), 0, row);
    const newCache = {} as { [key: number]: any };
    placeArray.forEach((row, index) => {
      if (row === null) return;
      newCache[index] = row;
    });

    gridApi.setGridOption("context", { ...context, cache: newCache });
  };
}
