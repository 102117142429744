import { Button, useToast } from "@chakra-ui/react";
import Icons from "@/components/Icons";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { useModalStore } from "@/stores/modal.store";

interface Props {
  isAnyProcessRunning: boolean;
}

const ShareTable = ({ isAnyProcessRunning }: Props) => {
  const toast = useToast();
  const tableData = useTableStore((state) => state.tableData);
  const { updateState: updateModalState } = useModalStore((state) => state);
  const { mutateAsync: generateTableLink, isPending: isGeneratingLink } =
    useMutation({
      mutationFn: (tableId: string) => tableService.generateShareLink(tableId),
    });

  const handleGenerateLink = async () => {
    generateTableLink(tableData._id, {
      onSuccess: (response) => {
        if (response.success) {
          updateModalState({
            isOpenModal: true,
            modalData: {
              modalType: "share-table-link",
              metaData: { link: response.data.link },
            },
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Something went wrong",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      },
    });
  };

  return (
    <Button
      className="btn-primary-outline"
      leftIcon={<Icons.ShareIcon />}
      isLoading={isGeneratingLink}
      onClick={() => {
        handleGenerateLink();
      }}
      isDisabled={isAnyProcessRunning}
    >
      Share
    </Button>
  );
};

export default ShareTable;
