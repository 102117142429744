import CustomAccordion from "@/components/Common/CustomAccordion";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { maxCostOptions, supportedGPTModels } from "./constants";
import { useEnrichStore } from "@/stores/enrich.store";

interface Props {
  updateSelectedOption: (key: string, value: Option[]) => void;
  selectedOptions: {
    model: Option;
    maxCost: Option;
  };
}

function OtherOptions({ selectedOptions, updateSelectedOption }: Props) {
  const keySelect = useEnrichStore((state) => state.keySelect);

  return (
    <div className="py-3">
      <CustomAccordion
        title="Model (optional)"
        titleClassName="font-title text-sm font-medium"
        isDefaultOpen={!!selectedOptions.model}
      >
        <MultiSelectDropdown
          options={supportedGPTModels}
          onChange={(selectedValues) => {
            updateSelectedOption("model", selectedValues as Option[]);
          }}
          value={selectedOptions.model}
          isMulti={false}
          isCreateAble={false}
          closeMenuOnSelect
        />
        <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
          Choose your model (Defaults to GPT 3.5). GPT-4 models are 20x more
          expensive than GPT 3.5 but very high quality.
        </p>
      </CustomAccordion>

      {keySelect !== "persanaApiKey" && (
        <CustomAccordion
          title="Max Cost (optional)"
          titleClassName="font-title text-sm font-medium"
        >
          <MultiSelectDropdown
            options={maxCostOptions}
            onChange={(selectedValues) => {
              updateSelectedOption("maxCost", selectedValues as Option[]);
            }}
            value={selectedOptions.maxCost}
            isMulti={false}
            isCreateAble={false}
            closeMenuOnSelect
          />
          <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
            Only set this if you are using your own OpenAI key. This is the
            maximum cost you want to pay OpenAI for this request (defaults to
            $0.50). GPT 3.5 Turbo is 10x cheaper, with a quality tradeoff. If
            using GPT-4, use at least 0.10
          </p>
        </CustomAccordion>
      )}
    </div>
  );
}

export default OtherOptions;
