import apiKeyService from "@/services/api-keys.service";
import { useQuery } from "@tanstack/react-query";

const useGenerateSlugs = (count: number = 1) => {
  return useQuery({
    queryKey: ["generateSlugs", count],
    queryFn: async () => {
      const response = await apiKeyService.generateSlugs(count);
      return response.data.slugs;
    },
  });
};

export default useGenerateSlugs;
