import { useState } from "react";
import { useModalStore } from "@/stores/modal.store";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";

import Icons from "@/components/Icons";

const ShareTableLinkModal = () => {
  const [textCopied, setTextCopied] = useState(false);
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  return (
    <Modal
      isCentered
      isOpen={isOpenModal && modalData.modalType === "share-table-link"}
      size={"2xl"}
      onClose={() => {
        updateState({
          isOpenModal: false,
          modalData: { modalType: "", metaData: "" },
        });
      }}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <ModalHeader>Generated Share Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="mt-2 mb-4 grid grid-cols-[repeat(1,_1fr_100px)] gap-5">
            <input
              type="text"
              className="w-full border border-[#e7e8ec] rounded-md p-2"
              value={`${window.location.origin}/table-preview?token=${modalData?.metaData?.link || ""}`}
              readOnly
            />

            <button
              className="bg-primary text-white rounded-md p-2 flex items-center justify-center"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/table-preview?token=${modalData?.metaData?.link || ""}`,
                );
                setTextCopied(true);
                setTimeout(() => {
                  setTextCopied(false);
                }, 1000);
              }}
            >
              <Icons.CopyIcon className="mr-2" />
              {textCopied ? "Copied" : "Copy"}
            </button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareTableLinkModal;
