import { useEffect, useState } from "react";

import TargetAudience from "./TargetAudience";
import RefineOptions from "./RefineOptions";
import StarterScreen from "./StarterScreen";
import { useSearchLeadsStore, useTabStore } from "../PeopleSearch/store";
import SalesGPTIcon from "@/assets/svgs/sales-gpt-logo.svg";

const PersanaMagic = ({
  callback,
  showStarterScreen = true,
}: {
  callback: () => void;
  showStarterScreen: boolean;
}) => {
  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.sideBarInput,
  );
  const [activeStep, setActiveStep] = useState(0);
  const [peopleSearchPayload, setPeopleSearchPayload] = useState<any>();
  const getFirstStaleTab = useTabStore(
    (state) => state.getOrCreateFirstStateTab,
  );
  const tab = getFirstStaleTab();

  const convertSearchPeoplePayloadLocally = (inputtedData: any) => {
    return {
      person_titles: inputtedData?.person_titles?.map(
        (title: any) => title?.label,
      ),
      person_not_titles: inputtedData?.person_not_titles?.map(
        (title: any) => title?.label,
      ),
      person_past_titles: inputtedData?.person_past_titles?.map(
        (title: any) => title?.label,
      ),
      organization_num_employees_ranges:
        inputtedData?.organization_num_employees_ranges?.map((size: any) =>
          size?.name?.replace("-", ","),
        ),
      q_organization_keyword_tags:
        inputtedData?.q_organization_keyword_tags?.map((tag: any) => tag?.name),
      q_not_organization_keyword_tags:
        inputtedData?.q_not_organization_keyword_tags?.map(
          (tag: any) => tag?.name,
        ),
      person_locations: inputtedData?.person_locations?.map(
        (location: any) => location?.label,
      ),
      person_not_locations: inputtedData?.person_not_locations?.map(
        (location: any) => location?.label,
      ),
      q_person_name: inputtedData?.q_person_name,
      q_keywords: inputtedData?.search_text,
    } as any;
  };

  const handleTargetAudienceCallback = (searchPayload: any) => {
    setActiveStep(2);
    searchPayload
      ? setPeopleSearchPayload(searchPayload)
      : setPeopleSearchPayload(undefined);
  };

  const handleRefineOptionsCallback = (showPrevious?: boolean) => {
    if (showPrevious) {
      setActiveStep(1);
      return;
    }

    const convertedInputtedData =
      convertSearchPeoplePayloadLocally(currentSearchPayload);
    const mergedPayload = { ...convertedInputtedData };
    if (peopleSearchPayload?.company_names) {
      mergedPayload.company_names = peopleSearchPayload.company_names;
    }
    if (peopleSearchPayload?.fetchedCompanies) {
      mergedPayload.fetchedCompanies = peopleSearchPayload.fetchedCompanies;
    }

    tab.store.setState({
      peopleSearchPayload: mergedPayload,
    });

    useTabStore.getState().activateTab(tab.id);
    callback();
  };

  useEffect(() => {
    showStarterScreen ? setActiveStep(0) : setActiveStep(1);
  }, [showStarterScreen]);

  return (
    <div className="w-full">
      {activeStep !== 0 && (
        <div className="py-[8px] px-[24px] flex items-center justify-center gap-2">
          <img src={SalesGPTIcon} />
          <p className="text-[32px] font-[600]">Sales GPT</p>
        </div>
      )}

      {activeStep === 0 ? (
        <div className="mx-[32px] py-[24px] px-auto flex items-center justify-center gap-[20px]">
          <StarterScreen setActiveStep={setActiveStep} />
        </div>
      ) : (
        <div className="mx-[32px] py-[24px] px-auto flex flex-col items-center justify-center gap-[20px] border border-[#E5E7EB] rounded-[16px] mb-[32px]">
          <p className="text-[16px] text-[#0F0D1C] font-bold">{`Step ${activeStep} of 2`}</p>

          {activeStep === 1 ? (
            <TargetAudience callback={handleTargetAudienceCallback} />
          ) : (
            activeStep === 2 && (
              <RefineOptions callback={handleRefineOptionsCallback} />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default PersanaMagic;
