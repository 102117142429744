import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Button, useToast } from "@chakra-ui/react";
import { countries } from "countries-list";

import AccordionButton from "../Common/AccordionButton";
import PersanaInput from "../Common/PersanaInput";
import SearchInputAccordion from "../Common/SearchInputAccordion";
import Icons from "../Icons";

import FilterIcon from "@/assets/svgs/filter.svg";
import LookalikeIcon from "@/assets/svgs/lookalike.svg";
import leadService from "@/services/leads.service";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { SearchPeopleType } from "@/types/leads.types";

import {
  extractNameAndContinent,
  filterValidObjKeys,
  generateId,
  handlePushToArray,
} from "@/utils";

type SidebarProps = {
  isSearching: boolean;
  setIsSearching: (val: boolean) => void;
  setCurrentSearchData: any;
};

interface StaticType {
  name: string;
  value?: string;
}

interface InputtedData {
  person_titles: StaticType[];
  person_past_titles: StaticType[];
  organization_ids: any;
  person_past_organization_ids: any;
  organization_num_employees_ranges: StaticType[];
  person_locations: StaticType[];
  keyword: string;
}

const Sidebar = ({
  isSearching,
  setIsSearching,
  setCurrentSearchData,
}: SidebarProps) => {
  const toast = useToast();
  const [activeTab, setActiveTab] = useState<"Filters" | "Lookalikes">(
    "Filters",
  );
  const [inputtedData, setInputtedData] = useState<InputtedData>({
    person_titles: [],
    person_past_titles: [],
    organization_ids: [],
    person_past_organization_ids: [],
    organization_num_employees_ranges: [],
    person_locations: [],
    keyword: "",
  });

  const updateLeadState = useSearchLeadsStore((state) => state.updateState);

  const { mutateAsync: searchPeopleMutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: SearchPeopleType }) =>
      leadService.searchPeopleMixrank(payload),
  });

  const handleInputChange = (
    e: any,
    stateToUpdate: any,
    setStateToUpdate: any,
  ) => {
    setStateToUpdate({
      ...stateToUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = async () => {
    const convertedInputtedData = {
      person_titles: inputtedData.person_titles.map((title) => title?.name),
      person_past_titles: inputtedData.person_past_titles.map(
        (title) => title?.name,
      ),
      organization_ids: inputtedData.organization_ids.map(
        (company: any) => company?.organization_id || company?.id,
      ),
      person_past_organization_ids:
        inputtedData.person_past_organization_ids.map(
          (company: any) => company?.organization_id || company?.id,
        ),
      organization_num_employees_ranges:
        inputtedData.organization_num_employees_ranges.map(
          (size) => size?.value,
        ),
      person_locations: inputtedData.person_locations.map(
        (location) => location?.name,
      ),
      keyword: inputtedData?.keyword,
    } as any;

    const validInputtedData = filterValidObjKeys(convertedInputtedData);

    if (Object.keys(validInputtedData || {}).length === 0) {
      toast({
        title: "No data to search",
        description: "Please input some data to search",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
      return;
    }

    setIsSearching(true);
    await searchPeopleMutateAsync(
      {
        payload: validInputtedData,
      },
      {
        onSuccess: (response) => {
          console.log("resp", response);
          setCurrentSearchData(validInputtedData || {});
          updateLeadState({
            searchLeadsResult: {
              profiles: response?.data?.profiles || [],
              pagination: response?.data?.pagination || {
                total_entries: 0,
                total_pages: 0,
              },
              apiPath: "leads/search-people?per_page=100&want_to_import=true",
            },
          });
          setIsSearching(false);
        },
        onError: (error) => {
          setIsSearching(false);
          console.log(error, "error");
        },
      },
    );
  };

  return (
    <div className="flex h-full w-full flex-col justify-between">
      {/* Top section */}
      <div className="flex gap-[16px] w-full text-[14px] leading-[20px] font-medium px-[16px] h-fit pt-[16px]">
        <div
          onClick={() => setActiveTab("Filters")}
          className={`w-[50%] pb-[16px] flex justify-between items-center cursor-pointer px-[16px] ${activeTab === "Filters" && "border-b-2 border-b-[#C03EDC]"}`}
        >
          <div className="flex items-center gap-3">
            <img src={FilterIcon} alt="" />
            <p>Filters</p>
          </div>
          <div className="!h-[18px] !w-[18px] rounded-[9px] bg-[#C03EDC] text-white text-[12px] font-medium flex items-center justify-center w-full">
            2
          </div>
        </div>
        <div
          className={`w-[50%] pb-[16px] flex justify-between items-center px-[8px] cursor-not-allowed  ${activeTab === "Lookalikes" && "border-b-2 border-b-[#C03EDC]"}`}
        >
          <div className="flex items-center gap-3">
            <img src={LookalikeIcon} alt="" />
            <p>Lookalikes</p>
          </div>
          <div className="!h-[18px] !w-[18px] rounded-[9px] bg-[#D0D4DC] text-white text-[12px] font-medium flex items-center justify-center w-full">
            0
          </div>
        </div>
      </div>

      {/* Filters Tab */}
      <div className="h-[5rem] flex-grow  space-y-4 overflow-y-auto py-4 px-2 bg-white">
        <>
          <SearchInputAccordion
            button={
              <AccordionButton
                icon={
                  <Icons.FileSearchIcon className="text-xl" color="#693DC7" />
                }
              >
                Keywords
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <PersanaInput
                value={inputtedData?.keyword}
                placeholder={""}
                name={"keyword"}
                label={"Keywords"}
                onChange={(e) => {
                  handleInputChange(e, inputtedData, setInputtedData);
                }}
                description="If a keyword is provided, we will return results that match the given keyword with name, title, headline, location or company name. The match can be partial or complete. This allows you to effectively search and filter through the results based on specific search terms or phrases."
              />
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            button={
              <AccordionButton
                icon={
                  <Icons.OfficeBuildingIcon
                    className="text-[1.2rem]"
                    color="#693DC7"
                  />
                }
              >
                Company
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <PersanaInput
                value={inputtedData?.organization_ids}
                placeholder={"e.g. Google, Facebook, Apple"}
                name={"organization_ids"}
                label={"Companies to include"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                value={inputtedData?.person_past_organization_ids}
                placeholder={"e.g. Google, Facebook, Apple"}
                name={"person_past_organization_ids"}
                label={"Past Companies to include"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              {/* <PersanaInput
                value={inputtedData?.organization_num_employees_ranges}
                placeholder={"e.g. 11-20 , 101-200"}
                name={"organization_num_employees_ranges"}
                label={"Number of Employees"}
                showDropdown
                disabledCustomSelect
                arrayOfValues={employeeSize}
                onClick={(target) => {
                  console.log("target", target);
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              /> */}
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton
                icon={
                  <Icons.SubtitleIcon
                    className="text-[1.3rem]"
                    color="#693DC7"
                  />
                }
              >
                Job Titles
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <PersanaInput
                value={inputtedData?.person_titles}
                placeholder={"e.g. CEO, CTO"}
                name={"person_titles"}
                label={"Job Titles to include"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                value={inputtedData?.person_past_titles}
                placeholder={"e.g. Intern, Manager"}
                name={"person_past_titles"}
                label={"Past Job Titles to include"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            button={
              <AccordionButton
                icon={
                  <Icons.LocationIcon className="text-xl" color="#693DC7" />
                }
              >
                Location
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <PersanaInput
                value={inputtedData?.person_locations}
                placeholder={"e.g. United States"}
                name={"person_locations"}
                label={"Country"}
                arrayOfValues={extractNameAndContinent(countries)}
                showDropdown
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
            </div>
          </SearchInputAccordion>
        </>
      </div>
      <div className="p-4 !font-title">
        <Button
          isLoading={isSearching}
          id={generateId("persana-search-button")}
          loadingText="Searching"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={`!bg-primaryPink !text-white !w-full !rounded-[6px] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide`}
          onClick={handleSearch}
          isDisabled={
            inputtedData?.person_titles?.length === 0 &&
            inputtedData?.person_past_titles?.length === 0 &&
            inputtedData?.organization_ids?.length === 0 &&
            inputtedData?.person_past_organization_ids?.length === 0 &&
            inputtedData?.person_locations?.length === 0
          }
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
