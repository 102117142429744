import APIKeyIntegration from "./APIKeyIntegration";
import FindyMailLogo from "@/assets/images/findymail.png";

const FindyMailIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"findyMail"}
      providerTitle="FindyMail"
      providerIcon={FindyMailLogo}
    />
  );
};

export default FindyMailIntegration;
