import TailwindModalLayout from "@/components/Common/TailwindModalLayout";
import { SelectedPriceType } from "./PriceBox";
import { userStore } from "@/stores/user.store";
import { useEffect, useState } from "react";
import { ConfirmUpgradeProps } from ".";

interface UpgradePlanProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  selectedPrice: SelectedPriceType;
  onConfirmUpgrade: (props: ConfirmUpgradeProps) => Promise<void>;
}

function UpgradePlanModal({
  isModalOpen,
  setIsModalOpen,
  selectedPrice,
  onConfirmUpgrade,
}: UpgradePlanProps) {
  const [totalCreditsUserWillReceive, setTotalCreditsUserWillReceive] =
    useState(0);
  const creditDetails = userStore((state) => state.creditDetails);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (creditDetails?.creditLeft != null) {
      const creditLeft = Math.max(
        0,
        Math.min(
          selectedPrice?.credits * 2,
          (creditDetails?.creditLeft || 0) -
            (creditDetails?.creditUsed || 0) +
            selectedPrice?.credits,
        ),
      );

      setTotalCreditsUserWillReceive(creditLeft);
    }
  }, [creditDetails]);

  return (
    <TailwindModalLayout
      modalOpen={isModalOpen}
      setModalOpen={handleCloseModal}
      closeOnOuterClick
      overlayClassName="bg-[rgba(0,_0,_0,_0.18)]"
      dialogClassName="!z-[2010]"
    >
      <div className="relative inline-block h-full overflow-hidden p-4 text-left align-bottom font-title transition-all sm:w-full sm:max-w-[40rem] sm:align-middle">
        <div className="space-y-8 rounded-md border bg-white p-5 font-title shadow">
          <h3 className="text-center text-xl font-bold">
            Upgrade your current plan
          </h3>
          <p
            className="rounded-md border border-[#C03EDC] px-4 py-2 text-[0.9rem]"
            style={{
              boxShadow: "2px 2px 40px 0px #373F510D",
            }}
          >
            <span className="font-bold">Summary:</span> You're about to upgrade
            to the higher plan priced at{" "}
            <span className="font-bold">
              ${selectedPrice?.price?.toLocaleString("en-US")}
            </span>
            . You'll receive{" "}
            <span className="font-bold">
              {selectedPrice?.credits?.toLocaleString("en-US")}
            </span>{" "}
            in credits, capped at twice this amount, bringing your total credits
            to{" "}
            <span className="font-bold">
              {totalCreditsUserWillReceive?.toLocaleString("en-US")}
            </span>{" "}
            (capped at{" "}
            <span className="font-bold">
              {(selectedPrice?.credits * 2)?.toLocaleString("en-US")}
            </span>
            ).
          </p>

          <div className="flex items-center justify-center space-x-6">
            <button
              className="rounded-md border border-[#D0D4DC] px-8 py-2.5 leading-none"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              className="!h-auto !rounded-md !bg-[#C03EDC] !px-8 !py-[10.5px] !font-title !leading-none !text-white"
              onClick={() => {
                onConfirmUpgrade({
                  selectedPrice,
                  isDowngrade: false,
                  isUpgrade: true,
                  bypassUpgradeModalCheck: true,
                });
                handleCloseModal();
              }}
            >
              Confirm Upgrade Plan
            </button>
          </div>
        </div>
      </div>
    </TailwindModalLayout>
  );
}

export default UpgradePlanModal;
