/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

import { useModalStore } from "@/stores/modal.store";

interface APICallOptions {
  apiPath?: string;
  payload?: any;
  headers?: any;
  params?: any;
  timeout?: number;
  url?: string;
  apiVersion?: string;
}

export interface APIResponse<T = any> {
  data?: T;
  statusCode?: number;
  totalDataCount?: number;
  pageNumber?: number;
  totalPages?: number;
  headers?: any;
  success: boolean;
  error?: any;
}

class MakeAPICall {
  private url: string;
  private payload: any;
  private headers: any;
  private params: any;
  private axios: AxiosInstance;

  constructor({
    apiPath,
    payload,
    headers,
    params,
    timeout,
    url,
    apiVersion = "v1",
  }: APICallOptions) {
    const axiosInstance = axios.create({
      timeout: (timeout || 120) * 1000, // support milliseconds
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 402) {
          useModalStore.setState({
            isOpenModal: true,
            modalData: { modalType: "pricing" },
          });
        }
        return Promise.reject(error);
      },
    );

    this.url =
      url || `${import.meta.env.VITE_BE_BASE_URL}/api/${apiVersion}/${apiPath}`;
    this.payload = payload;
    this.headers = headers || { "content-type": "application/json" };
    this.params = params;
    this.axios = axiosInstance;
  }

  private getConfig(otherConfigs?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      headers: this.headers,
      params: this.params,
      withCredentials: true,
      ...otherConfigs,
    };
  }

  public async get<T = any>(
    otherConfigs?: AxiosRequestConfig,
  ): Promise<APIResponse<T>> {
    try {
      const config = this.getConfig(otherConfigs);
      const { data }: AxiosResponse = await this.axios.get(this.url, config);
      return {
        ...data,
      };
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        return {
          success: false,
          error: error.message,
        };
      }
      if (error.response?.data) {
        return error.response.data;
      }
      throw new Error(error);
    }
  }

  public async getBlob() {
    const response = await axios.post(this.url, this.payload, {
      responseType: "blob",
      withCredentials: true,
      params: this.params,
      maxBodyLength: Infinity,
    });
    const file = response.data;
    return file;
  }

  public async patch<T = any>(
    otherConfigs?: AxiosRequestConfig,
  ): Promise<APIResponse<T>> {
    try {
      const config = this.getConfig(otherConfigs);
      const { data }: AxiosResponse = await this.axios.patch(
        this.url,
        this.payload,
        config,
      );
      return {
        ...data,
      };
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        return {
          success: false,
          error: error.message,
        };
      }
      if (error.response.data) {
        return error.response.data;
      }
      throw new Error(error);
    }
  }
  public async put<T = any>(
    otherConfigs?: AxiosRequestConfig,
  ): Promise<APIResponse<T>> {
    try {
      const config = this.getConfig(otherConfigs);
      const { data }: AxiosResponse = await this.axios.put(
        this.url,
        this.payload,
        config,
      );
      return {
        ...data,
      };
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        return {
          success: false,
          error: error.message,
        };
      }
      if (error.response.data) {
        return error.response.data;
      }
      throw new Error(error);
    }
  }

  public async post<T = any>(
    otherConfigs?: AxiosRequestConfig,
  ): Promise<APIResponse<T>> {
    try {
      const config = this.getConfig(otherConfigs);
      const { data, status, headers }: AxiosResponse = await this.axios.post(
        this.url,
        this.payload,
        config,
      );
      return {
        success: true,
        data,
        statusCode: status,
        headers,
      };
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        return {
          success: false,
          error: error.message,
        };
      }
      if (error.response.data) {
        return error.response.data;
      }
      throw new Error(error);
    }
  }

  public async delete<T = any>(
    otherConfigs?: AxiosRequestConfig,
  ): Promise<APIResponse<T>> {
    try {
      const config = this.getConfig(otherConfigs);
      const { data }: AxiosResponse = await this.axios.delete(this.url, config);
      return {
        ...data,
      };
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        return {
          success: false,
          error: error.message,
        };
      }
      if (error.response.data) {
        return error.response.data;
      }
      throw new Error(error);
    }
  }
}

export default MakeAPICall;
