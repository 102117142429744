export const RESPONSE_COLUMNS = [
  {
    isChecked: false,
    label: "Name",
    id: "personLinkedInName",
  },
  {
    isChecked: false,
    label: "Headline",
    id: "personLinkedInHeadline",
  },
  {
    isChecked: false,
    label: "Profile Photo",
    id: "personLinkedInProfilePhoto",
  },
  {
    isChecked: false,
    label: "Location",
    id: "personLinkedInLocation",
  },
  {
    isChecked: false,
    label: "Summary",
    id: "personLinkedInSummary",
  },
  {
    isChecked: false,
    label: "Organization",
    id: "personLinkedInCurrentCompany",
  },
  {
    isChecked: false,
    label: "Company LinkedIn URL",
    id: "personLinkedInCurrentCompanyUrl",
  },
  {
    isChecked: false,
    label: "Current Title",
    id: "personLinkedInCurrentTitle",
  },
  {
    isChecked: false,
    label: "School",
    id: "personLinkedInSchool",
  },
];

export const InsightColumns = [
  {
    isChecked: false,
    label: "Highlights",
    id: "personInsightsHighlights",
  },
  {
    isChecked: false,
    label: "Intro Lines",
    id: "personInsightsIntroLines",
  },
  {
    isChecked: false,
    label: "How To Use Their Personality For Sales",
    id: "personInsightsHowToUseTheirPersonalityForSales",
  },
  {
    isChecked: false,
    label: "Icebreakers",
    id: "personInsightsIcebreakers",
  },
  {
    isChecked: false,
    label: "Recommended Messaging Style",
    id: "personInsightsRecommendedMessagingStyle",
  },
  {
    isChecked: false,
    label: "Big 5 Personality Assessment",
    id: "personInsightsBig5PersonalityAssessment",
  },
  {
    isChecked: false,
    label: "DISC Personality Profile",
    id: "personInsightsDiscPersonalityProfile",
  },
  {
    isChecked: false,
    label: "Sales Call Tips",
    id: "personInsightsSalesCallTips",
  },
];
