export const preferenceOptions = [
  {
    value: "People Information",
    label: "Generate Email based on Prospect LinkedIn Enrichment",
  },
  {
    value: "Company Information",
    label: "Generate Email based on Company Description",
  },
  {
    value: "LinkedIn Connect",
    label: "Generate Email for LinkedIn Connect",
  },
  {
    value: "Custom Email Template",
    label: "Custom Email Template",
  },
  {
    value: "Static Email Template",
    label: "Static Email Template",
  },
];

export const defaultPersonalizationPrompts = {
  "Generate Email based on Prospect LinkedIn Enrichment":
    "Write a short professional compliment to send to a person based on this person’s LinkedIn description. This compliment should be able to be used in the first line of an email. Here is that person’s LinkedIn description: {{Summary}}. Keep the compliment to one sentence and under 15 word. The message should be friendly and personal. Avoid formal or exaggerated expressions, and aim for a tone that is casual, friendly, and sounds like a real person speaking.",
  "Generate Email based on Company Description":
    "I need your help researching companies and writing a first line. I'm going to give you a large body of text that has information about a company. Synthesize the information and find something unique about the company. Then finish this sentence and be sure to include the full sentence. Do not include quotation marks in your output. I was on your website and saw you're focused on.... Complete the sentence in 12 words or less. Here's the body text to analyze: {{Company_info}}",
  "Generate Email for LinkedIn Connect":
    "Write a personalized message to send when trying to connect with a person named {{person_name}} on LinkedIn. Here is that person’s LinkedIn summary: {{linkedin_summary}}. Keep the message to one sentence. The message should be friendly and personal. Avoid formal or exaggerated expressions, and aim for a tone that is casual, friendly, and sounds like a real person speaking. Keep the line under 20 words. Do not include any emojis",
  "Custom Email Template": "",
  "Static Email Template": "",
};
