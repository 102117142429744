import { useState } from "react";
import { Card, CardBody, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import Icons from "@/components/Icons";

import LoadingSpin from "@/assets/animated/LoadingSpin";
import enrichment from "@/services/enrichment.service";

type Props = {
  repoPath: string;
  onClose: () => void;
  totalNumOfStarGazers: number;
  handleGithubConnect: () => void;
};

const AddToTable = ({ repoPath, totalNumOfStarGazers, onClose }: Props) => {
  const navigate = useNavigate();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const fetchStarGazerDetails = async () => {
    try {
      setIsLoading(true);
      const response = await enrichment.githubStargazers(
        repoPath,
        totalNumOfStarGazers,
      );
      if (!response.success) {
        toast({
          title: response?.data?.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } else {
        console.log("response", response);
        const tableId = response?.data?.data?.tableData._id;
        console.log("tableId", tableId);

        if (tableId) {
          navigate({
            to: "/table/$tableId",
            params: {
              tableId,
            },
          });
        }
        onClose();
      }
    } catch (error: any) {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  const handleNewTableClick = () => {
    fetchStarGazerDetails();
  };

  if (isLoading) {
    return (
      <div className="w-full text-center">
        <Text className="mb-2 w-full text-center">Creating source...</Text>
        <LoadingSpin />
      </div>
    );
  }
  return (
    <div className="flex justify-between gap-4">
      <Card
        className="w-full cursor-pointer hover:bg-gray-50"
        onClick={handleNewTableClick}
      >
        <CardBody>
          <div className="d-flex flex-col items-center justify-center">
            <Icons.ViewTableIcon size={30} className="mb-2 text-primary" />
            <Text className="text-sm font-normal text-primaryText">
              New Blank Table
            </Text>
          </div>
        </CardBody>
      </Card>
      <Card className="w-full cursor-pointer hover:bg-gray-50">
        <CardBody>
          <div className="d-flex flex-col items-center justify-center">
            <Icons.SquarePlusIcon size={30} className="mb-2 text-primary" />
            <Text className="text-sm font-normal text-primaryText">
              Add To Existing Table
            </Text>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddToTable;
