import { useCallback, useEffect, useState } from "react";

import TablePage from "./table";
import LottiePlayer from "@/components/Common/LottiePlayer";

import MainLoader from "@/assets/lottieFiles/main_loader.lottie";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { TableData } from "@/types/table.types";

const TablePreview = () => {
  const [isRefetching, setIsRefetching] = useState(true);
  const [data, setData] = useState<TableData>();
  const updateState = useTableStore((state) => state.updateState);

  const getTableData = useCallback(
    async (token: string) => {
      setIsRefetching(true);
      const response = await tableService.getTablePreviewData(token);
      if (response.data) {
        updateState(response.data);
        setData(response.data);
      }
      setIsRefetching(false);
    },
    [updateState],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token") || "";
    getTableData(token);
  }, [getTableData]);

  if (isRefetching) {
    return (
      <div className="flex flex-col justify-center items-center h-[100vh] w-full">
        <LottiePlayer src={MainLoader} />
        <p className="text-primaryPurple font-medium text-2xl gradient-text">
          Firing up Persana's Brain
        </p>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="flex flex-col justify-center items-center h-[100vh] w-full">
        <p className="font-medium">Data not found!</p>
      </div>
    );
  }

  return (
    <div className="w-[100%] mt-5">
      <TablePage isRefetching={isRefetching} />
    </div>
  );
};

export default TablePreview;
