import { FileRoute } from "@tanstack/react-router";
import { z } from "zod";

import CompleteOnboarding from "@/pages/CompleteOnboarding";

export const Route = new FileRoute("/complete-onboarding/$token").createRoute({
  parseParams: (params) => ({
    token: z.string().parse(String(params.token)),
  }),
  component: CompleteOnboardingPage,
});

function CompleteOnboardingPage() {
  const params = Route.useParams();

  return <CompleteOnboarding token={params.token} />;
}

export default CompleteOnboardingPage;
