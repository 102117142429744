import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";

const DownloadsList = () => {
  const toast = useToast();

  const downloadsData = useTableStore(
    (state) => state.tableData.metaData?.downloadsData,
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (fileKey: string) => tableService.getCSVSignUrl(fileKey),
  });

  if (!downloadsData?.length) return null;

  const handleDownload = (fileName: string) => async () => {
    await mutateAsync(fileName, {
      onSuccess: ({ data }) => {
        if (data?.success) {
          const link = document.createElement("a");
          link.href = data.signedUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          toast({
            title: "Error",
            description: data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant={"ghost"}
        size={"sm"}
        colorScheme="primary"
        spinnerPlacement="end"
        rightIcon={<Icons.DownCircleIcon />}
        isLoading={isPending}
      >
        Exports
      </MenuButton>
      <MenuList className="max-h-[300px] max-w-[400px] overflow-y-auto p-3">
        {downloadsData.reverse().map((list) => (
          <MenuItem
            icon={<Icons.DownloadIcon />}
            key={`${list.fileName}-${Math.random()}`}
            onClick={handleDownload(list.fileName)}
          >
            {list.fileName}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default DownloadsList;
