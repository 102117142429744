import { ButtonGroup, IconButton, Badge, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { APIResponse } from "@/lib/apiCalls";
import Icons from "../Icons";
import { useDeleteDisplayedRow } from "./Table/hooks/useDeleteRow";
import { useTableStore } from "./Table/hooks/useGridState.store";
import { FilterModel } from "ag-grid-community";
import { buildServerQueryParams } from "./Table/functions/buildUrlParams";
interface DeleteRowButtonProps<T> {
  filter?: boolean;
  action: (params: {
    rowIds: string[] | "all";
    filters?: FilterModel | null;
  }) => Promise<APIResponse<T>>;
}

export const DeleteRowButton = <T,>({
  action,
  filter,
}: DeleteRowButtonProps<T>) => {
  const filterModel = useTableStore(
    (state) => state.state?.filter?.filterModel ?? null,
  );
  const toast = useToast();
  const selectedRowIds = useTableStore((state) =>
    state.isSelectAll ? "all" : (state.state?.rowSelection as string[]) ?? [],
  );
  const removeSelectedRows = useDeleteDisplayedRow();
  const mutation = useMutation({
    mutationFn: () =>
      action({
        rowIds: selectedRowIds,
        filters: filter ? buildServerQueryParams({ filterModel }).filter : null,
      }),
    onSuccess: removeSelectedRows,
    onError: () => {
      toast({
        title: "Failed to delete items",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const selectedCount = useTableStore((state) => state.getSelectedRowCount());
  return (
    <ButtonGroup isAttached>
      <IconButton
        fontSize={"14px"}
        borderRadius={"20px"}
        bg="#FFB7B3"
        color="#CC0C00"
        pointerEvents={
          selectedCount === 0 || mutation.isPending ? "none" : "auto"
        }
        // opacity={selectedCount == 0 ? "0" : "1"}
        isDisabled={selectedCount === 0}
        onClick={() => mutation.mutate()}
        isLoading={mutation.isPending}
        aria-label="Delete"
        icon={<Icons.DeleteIcon />}
      ></IconButton>
      <Badge
        opacity={selectedCount == 0 ? "0" : "1"}
        transform={"translateX(-4px)"}
        h="1.5em"
        bg="#FFB7B3"
        variant="solid"
        borderRadius={"20px"}
      >
        {selectedCount}
      </Badge>
    </ButtonGroup>
  );
};
