export const supportedGPTModels = [
  {
    value: "gpt-3.5-turbo",
    label: "GPT-3.5 Turbo - (Quality: medium, Cost: $)",
  },
  {
    value: "gpt-4-turbo",
    label: "GPT-4 Turbo - (Quality: high, Cost: $$)",
  },
  {
    value: "gpt-4o-mini",
    label: "GPT-4 Omega Mini - (Quality: high, Cost: $$)",
  },
  {
    value: "gpt-4o",
    label: "GPT-4 Omega - (Quality: highest, Cost: $$$)",
  },
];

export const maxCostOptions = [
  {
    value: "0.01",
    label: "$0.01",
  },
  {
    value: "0.03",
    label: "$0.03",
  },
  {
    value: "0.05",
    label: "$0.05",
  },
  {
    value: "0.10",
    label: "$0.10",
  },
  {
    value: "0.20",
    label: "$0.20",
  },
  {
    value: "0.30",
    label: "$0.30",
  },
  {
    value: "0.50",
    label: "$0.50",
  },
  {
    value: "1.00",
    label: "$1.00",
  },
];
