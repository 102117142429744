import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import { colConditions } from "./constants";
import { useTableStore } from "@/stores/table.store";
import type { ColCondition, FilterType } from "@/types/table.types";

interface Props {
  filter: FilterType;
}

const ColCondition = ({ filter }: Props) => {
  const updateState = useTableStore((state) => state.updateState);
  const tableFilters = useTableStore((state) => state.tableFilters);

  const hanleClick = (condition: ColCondition) => {
    updateState({
      tableFilters: {
        ...tableFilters,
        filters: tableFilters.filters?.map((f) => {
          if (f.id === filter.id) {
            return {
              ...f,
              colCondition: condition,
            };
          }
          return f;
        }),
      },
    });
  };

  return (
    <Popover placement="right-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <button className="w-full rounded border px-2 py-1 text-sm font-medium capitalize text-[#000000]/90 duration-300 hover:bg-[#ECEEF1]">
              {filter.colCondition.name}
            </button>
          </PopoverTrigger>
          <PopoverContent className="!w-[80px]">
            <PopoverArrow />
            <PopoverBody className="flex flex-col">
              {colConditions.map((condition, index) => (
                <button
                  key={`${condition.name}-${index}-${Math.random()}`}
                  className={`truncate rounded-md px-2 py-1.5 text-start text-[13.75px] text-[#000000]/90 duration-300 hover:bg-[#e7e8ec] ${
                    filter?.colCondition.value === condition?.value &&
                    "bg-[#e7e8ec]"
                  }`}
                  onClick={() => {
                    hanleClick(condition);
                    onClose();
                  }}
                >
                  {condition.name}
                </button>
              ))}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default ColCondition;
