import { Link } from "@chakra-ui/react";

export const CompanyDetailsRenderer = (props: any) => {
  return (
    <div className="flex w-full items-center gap-2">
      {props?.data?.companyWebsite ? (
        <Link href={props.data.companyWebsite} target="_blank">
          <span className="text-[#5024DC]">{props.data.companyName}</span>
        </Link>
      ) : (
        <span className="text-[#5024DC]">{props.data.companyName}</span>
      )}
    </div>
  );
};
