import { Box, Tooltip } from "@chakra-ui/react";

import premiumSVG from "@/assets/svgs/premium_icon.svg";

const PremiumIcon = () => {
  return (
    <Tooltip hasArrow label="Premium List" fontSize="xs">
      <Box as="button" className="gradient-text">
        <img src={premiumSVG} alt="" width={25} />
      </Box>
    </Tooltip>
  );
};

export default PremiumIcon;
