import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

import { useEnrichStore } from "@/stores/enrich.store";

const ModelSelect = () => {
  const claudeModel = useEnrichStore((state) => state.claudeModel);
  const updateState = useEnrichStore((state) => state.updateState);

  return (
    <>
      <div className="mt-4 px-4">
        <RadioGroup
          onChange={(
            newValue:
              | "claude-3-opus-20240229"
              | "claude-3-sonnet-20240229"
              | "claude-3-haiku-20240307",
          ) => {
            updateState({
              claudeModel: newValue,
            });
          }}
          value={claudeModel}
        >
          <Stack direction="row">
            <Radio
              size="md"
              name="claude-3-opus-20240229"
              value="claude-3-opus-20240229"
              colorScheme="primary"
            >
              Opus
            </Radio>
            <Radio
              size="md"
              name="claude-3-sonnet-20240229"
              value="claude-3-sonnet-20240229"
              colorScheme="primary"
            >
              Sonnet
            </Radio>
            <Radio
              size="md"
              name="claude-3-haiku-20240307"
              value="claude-3-haiku-20240307"
              colorScheme="primary"
            >
              Haiku
            </Radio>
          </Stack>
        </RadioGroup>
      </div>
    </>
  );
};

export default ModelSelect;
