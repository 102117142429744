import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { Button, ButtonProps, IconButton, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";
import tableService from "@/services/table.service";
import { createANewColumnName } from "../../utils";
import type { ColumnCreatePayload } from "@/types/table.types";
import { useProcessingStore } from "@/stores/processing.store";
import { useEnrichStore } from "@/stores/enrich.store";

type ActionKey =
  | "text"
  | "number"
  | "date"
  | "email"
  | "url"
  | "time"
  | "file"
  | "formula"
  | "enrichment";

const inputMenuList = [
  {
    label: "Text",
    key: "text",
    icon: <Icons.TextIcon />,
    actionKey: "text",
  },
  {
    label: "Number",
    key: "number",
    icon: <Icons.NumbersIcon />,
    isDisabled: true,
    actionKey: "number",
  },
  {
    label: "Date",
    key: "date",
    icon: <Icons.CalendarIcon />,
    isDisabled: true,
    actionKey: "date",
  },
  {
    label: "Email",
    key: "email",
    icon: <Icons.MailIcon />,
    isDisabled: true,
    actionKey: "email",
  },
  {
    label: "URL",
    key: "url",
    icon: <Icons.LinkIcon />,
    isDisabled: true,
    actionKey: "url",
  },
  {
    label: "Time",
    key: "time",
    icon: <Icons.CalendarIcon />,
    isDisabled: true,
    actionKey: "time",
  },
  {
    label: "File",
    key: "file",
    icon: <Icons.ImageIcon />,
    isDisabled: true,
    actionKey: "file",
  },
];

const topMenuList = [
  {
    label: "Formula",
    key: "formula",
    icon: <Icons.FunctionIcon />,
    actionKey: "formula",
  },
  {
    label: "Enrichment",
    key: "enrichment",
    icon: <Icons.LayersIcon />,
    actionKey: "enrichment",
  },
];

interface Props extends ButtonProps {
  onClose?: () => void;
  onOpen?: () => void;
  isOpen?: boolean;
  insertIndex?: number | null;
  isMini?: boolean;
  gridRef?: React.RefObject<AgGridReact>;
}

const AddColumn = ({
  onClose,
  insertIndex,
  isOpen,
  onOpen,
  isMini,
  gridRef,
  ...props
}: Props) => {
  const toast = useToast();
  const processingData = useProcessingStore((state) => state.processingData);

  const isAnyProcessRunning = !!processingData?.length;
  const columns = useTableStore((state) => state.tableData.columns);
  const tableId = useTableStore((state) => state.tableData._id);
  const isProcessing = useTableStore(
    (state) => state.tableData.metaData?.isProcessing,
  );
  const updateState = useTableStore((state) => state.updateState);

  const { mutateAsync: createColumnAsync } = useMutation({
    mutationFn: (payload: ColumnCreatePayload) =>
      tableService.insertColumn(tableId, payload),
  });

  const handleAddColumn = async (actionKey: ActionKey) => {
    if (isProcessing) {
      toast({
        position: "top-right",
        title:
          "Already one process is running. Please wait until it completes.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    onClose && onClose();
    switch (actionKey) {
      case "text":
        await createColumnAsync(
          {
            name: createANewColumnName("New Column", columns),
            index: insertIndex ?? undefined,
          },
          {
            onSuccess: (response) => {
              if (!response.data?.success) {
                return toast({
                  position: "top-right",
                  title:
                    response.data?.message || "Failed to create new column",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
              if (response.data?.data) {
                updateState({
                  tableData: response.data.data,
                });
              }
              const columns = gridRef?.current?.api?.getColumns();
              const newColumn = columns?.at(insertIndex ?? columns.length - 1);
              gridRef?.current?.api.ensureColumnVisible(newColumn!, "auto");
              toast({
                position: "top-right",
                title: "New column created",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            },
            onError: () => {
              toast({
                position: "top-right",
                title: "Failed to create new column",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            },
          },
        );
        break;
      case "enrichment":
        useEnrichStore.getState().updateState({
          isOpenEnrichModal: true,
          selectedEnrichments: null,
        });
        break;
      case "formula":
        useEnrichStore.getState().updateState({
          generateFormulaModal: {
            isOpen: true,
            type: "formula",
          },
        });
        break;
      default:
        break;
    }
  };
  return (
    <Menu
      onOpen={onOpen}
      arrowPadding={16}
      colorScheme="purple"
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      autoSelect
    >
      {isMini ? (
        <MenuButton
          as={IconButton}
          size="sm"
          variant="outline"
          icon={<Icons.AddIcon size={20} />}
          isDisabled={isAnyProcessRunning}
          {...props}
        />
      ) : (
        <MenuButton
          as={Button}
          size="sm"
          variant="outline"
          leftIcon={<Icons.AddIcon size={20} />}
          isDisabled={isAnyProcessRunning}
          {...props}
        >
          Add Column
        </MenuButton>
      )}
      <MenuList
        transition={"none"}
        className="p-2"
        sx={{
          shadow:
            "rgba(76, 76, 76, 0.32) 0px 0px 1px, rgba(76, 76, 76, 0.06) 0px 4px 8px, rgb(238, 238, 238) 0px 8px 48px",
          width: "max-content",
        }}
      >
        {topMenuList.map((item) => (
          <MenuItem
            className="p-2"
            key={item.key}
            onClick={() => {
              handleAddColumn(item.actionKey as ActionKey);
            }}
          >
            <div className="flex items-center gap-1">
              {item.icon}
              {item.label}
            </div>
          </MenuItem>
        ))}
        <MenuDivider />
        {inputMenuList.map((item) => (
          <MenuItem
            className="p-2"
            key={item.key}
            isDisabled={item.isDisabled}
            onClick={() => {
              handleAddColumn(item.actionKey as ActionKey);
            }}
          >
            <div className="flex items-center gap-1">
              {item.icon}
              {item.label}
            </div>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default AddColumn;
