import { GridOptions } from "ag-grid-community";
import { TRow } from "../../types";
import { useTableState } from "../useTableState.store";
import { useBuildFolderTree } from "./useFolderTree";
import { useRowStyles } from "./useRowStyles";
import { useUpdateRowData } from "./useRowData";
import { useHandleFilters } from "./useRowFilter";
import { useMoveFolders, useSyncTableState } from "../useTableData";
import { useDragRows } from "./useDragRows";
import { useConfig } from "./useConfig";
import { useHandleRowOpen } from "./useRowOpen";

export const useGridOptions = (): GridOptions<TRow> => {
  const { mutateAsync: moveFolderItems } = useMoveFolders();
  const updateState = useTableState((state) => state.updateState);

  useSyncTableState();
  useConfig();
  useBuildFolderTree();
  useUpdateRowData();
  useHandleRowOpen();
  useHandleFilters();
  useRowStyles();
  useDragRows(moveFolderItems);

  return {
    getRowId: (item) => item.data._id,
    onGridReady: (params) => updateState({ gridApi: params.api }),
    onGridPreDestroyed: () => updateState({ gridApi: null }),
    onGridSizeChanged: (e) => e.api.sizeColumnsToFit(),
    autoSizeStrategy: { type: "fitGridWidth" },
  };
};
