import { Button } from "@chakra-ui/react";

import AutoRefresh from "@/assets/images/job-change-auto-refresh.png";
import SlackLogo from "@/assets/images/slack-logo.png";

import providerService from "@/services/providers.service";
import { useProviderList } from "@/hooks/useProviderData";

const Step3 = () => {
  const { data: providerList, isPending } = useProviderList();
  const slackProvider = providerList?.data?.find(
    (provider) => provider.name === "slack",
  );
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold text-gray-800">
          Monthly Job Status Refresh with Slack Alerts
        </p>
        <Button
          isLoading={isPending}
          variant={"outline"}
          size={"sm"}
          leftIcon={<img src={SlackLogo} alt="" width={12} height={12} />}
          onClick={() => {
            !slackProvider && providerService.openSlackOAuth();
          }}
        >
          {slackProvider ? "Connected" : " Connect Slack"}
        </Button>
      </div>

      <hr className="border-t-2 border-purple-700 my-4" />
      <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
        <img src={AutoRefresh} alt="Select Leads" className="rounded-lg mb-4" />
      </div>
      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <p className="text-base font-medium text-gray-800">
          Follow these steps to enable Auto Refresh:
        </p>
        <p>
          <span className="font-semibold">1. Connect your Slack account</span>{" "}
          (if not already connected).
        </p>
        <p>
          <span className="font-semibold">2. Choose the Slack channel</span> for
          receiving job change updates.
        </p>
        <p>
          <span className="font-semibold">3. Activate Auto Refresh</span> to get
          the updates monthly.
        </p>
      </div>
    </div>
  );
};

export default Step3;
