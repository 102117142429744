import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { FormEvent, SetStateAction, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";

import CheckEmailModal from "@/components/CheckEmailModal";
import CommonCarousel from "@/components/Common/Carousel";

import PersanaLogo from "@/assets/svgs/persana-logo.svg";
import LoginFrame from "@/assets/images/login-frame.png";
import Icons from "@/components/Icons";

import userService from "@/services/user.service";
import { CarouselDataData } from "./constants";

const Login = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const navigate = useNavigate();
  const [checkEmailModalOpen, setCheckEmailModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<any>("");

  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setErrorMessage("");
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (!emailRegex.test(userInput)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    setIsLoading(true);
    e.preventDefault();
    const data = (await userService.getOtp(userInput)) as any;

    if (data?.data?.blocked) {
      //@ts-ignore
      navigate({ to: "/closed-beta" });
    }
    if (data.success) {
      if (data?.isFailedToVerifyForFreeCredits) {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          html: `
        We were unable to verify your account for free credits, send us an email at <b><a style="color:#0284fe;" href="mailto:sriya@persana.ai">sriya@persana.ai</a></b> for verification.
      `,
          focusConfirm: true,
        }).then((result) => {
          if (result.isConfirmed) {
            setCheckEmailModalOpen(true);
          }
        });
      }

      setCheckEmailModalOpen(true);
    } else {
      setErrorMessage(data?.data?.message);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: data?.isSpam
          ? data?.message
          : `
        If you would like to use Persana with a ${data?.data?.domain} account,
        please email <b><a style="color:#0284fe;" href="mailto:sriya@persana.ai">sriya@persana.ai</a></b> to request access.
      `,
        focusConfirm: true,
      });
      localStorage.clear();
    }
    setIsLoading(false);
  };

  return (
    <section className="h-[100vh] w-full bg-[#F5F3FF] flex flex-col items-center">
      <div className="py-[20px] flex items-center justify-center">
        <img src={PersanaLogo} className="w-[150px] md:w-auto" />
      </div>
      <div className="py-[36px] px-[20px] md:px-[100px] max-w-[90%] md:max-w-[80%] min-h-[74vh] flex flex-col md:flex-row items-center justify-center gap-[20px] md:gap-[54px] bg-white rounded-[16px] m-auto relative">
        <img
          src={LoginFrame}
          className="hidden md:block absolute right-0 top-0 h-auto w-auto max-h-full rounded-r-[16px] object-cover"
          style={{ height: "100%", width: "auto" }}
        />

        <div className="flex w-full md:w-[50%] flex-col items-center justify-center bg-white px-[20px] md:px-[30px] text-center relative z-10">
          <p className="mb-[20px] text-[20px] md:text-[24px] font-[700] text-[#222]">
            Welcome to <span className="text-textPurple">Persana</span>
          </p>
          <p className="mb-[20px] md:mb-[25px] text-[14px] md:text-[14px] font-[500] text-[#222]">
            Rated <span className="text-textPurple">#1</span> AI Prospecting
            Platform of 2024
          </p>

          <form
            onSubmit={handleSubmit}
            className="flex w-full flex-col gap-[20px]"
          >
            <div className="!h-12 md:!h-14 w-full">
              <InputGroup className="!h-full w-full">
                <InputLeftElement pointerEvents="none" className="!h-full">
                  <Icons.MailIcon className="text-gray-400 h-5 w-5" />
                </InputLeftElement>
                <Input
                  type="text"
                  name="email"
                  placeholder="Enter your email here"
                  className={`!h-full w-full rounded-lg border p-3 text-sm md:!text-base font-medium text-[#272727d8] placeholder:text-[#B4B4B4] focus:outline-none ${errorMessage ? "!border-[#EB5757]" : "!border-[#B4B4B4]"}`}
                  onChange={handleChange}
                />
              </InputGroup>
            </div>

            <div className="hidden md:flex !items-center !justify-center">
              <p className="cursor-pointer text-sm md:text-base">
                Having trouble signing in?
              </p>
            </div>

            <Button
              height={"auto"}
              type="submit"
              className="h-[50px] md:h-[60px] !rounded-[8px] !px-[20px] !py-[10px] md:!py-[15px] !text-[16px] md:!text-[18px] font-[600] !text-white"
              isDisabled={!userInput}
              isLoading={isLoading}
              style={{
                background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
              }}
            >
              Get Started
            </Button>

            <p className="text-center text-[12px] md:text-[14px] font-[400] text-[#222]">
              By logging in, I agree to the Terms of Service Privacy Policy, and
              AI Content Policy. Please view the policies
              <a
                target="_blank"
                href="https://persana-ai.notion.site/Persana-Help-Center-72626a6f86d64b69a0975fd7de7462c4"
                className="text-[#693DC7]"
                rel="noreferrer"
              >
                {` here`}
              </a>
              .
            </p>
          </form>
        </div>

        <div className="w-full md:w-[50%] z-[1]">
          <div
            className="py-[20px] px-[20px] md:py-[48px] md:px-[64px] bg-white rounded-[10px]"
            style={{ boxShadow: "0px 12px 40px 0px #C03EDC3D" }}
          >
            <div className="slider-container flex flex-col justify-center text-center mb-4">
              <CommonCarousel CommonCarouselData={CarouselDataData} />
            </div>
          </div>
        </div>
      </div>

      <CheckEmailModal
        isOpen={checkEmailModalOpen}
        onClose={() => setCheckEmailModalOpen(false)}
        email={userInput}
      />
    </section>
  );
};

export default Login;
