import Icons from "@/components/Icons";
import { Button, Input } from "@chakra-ui/react";
import { CustomOutput } from "./types";

interface Props {
  customOutputs: CustomOutput[];
  handleAddCustomOutput: () => void;
  handleDeleteCustomOutput: (id: string) => void;
  onCustomOutputChange: (id: string, key: string, value: string) => void;
}

const Outputs = ({
  customOutputs,
  handleAddCustomOutput,
  handleDeleteCustomOutput,
  onCustomOutputChange,
}: Props) => {
  return (
    <div>
      <p className="mb-2 text-sm font-bold">Add Custom Column Outputs</p>
      <div className="list">
        {customOutputs.map((output) => (
          <div
            className="card flex items-center gap-4 border p-2"
            key={output.id}
          >
            <div className="inputs">
              <Input
                placeholder="Field Name"
                className="mb-2"
                value={output.fieldName}
                onChange={(e) => {
                  onCustomOutputChange(output.id, "fieldName", e.target.value);
                }}
              />
              <Input
                placeholder="Description (optional)"
                value={output.description}
                onChange={(e) => {
                  onCustomOutputChange(
                    output.id,
                    "description",
                    e.target.value,
                  );
                }}
              />
            </div>
            <div className="action">
              <Button
                size="md"
                onClick={() => handleDeleteCustomOutput(output.id)}
              >
                <Icons.TrashIcon className="text-red-600" />
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Button size="sm" onClick={handleAddCustomOutput}>
        Add field
      </Button>
    </div>
  );
};

export default Outputs;
