import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import tableService from "@/services/table.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { Column } from "@/types/table.types";
import { convertSlateToText, processCellValue } from "@/utils";
import { Descendant } from "slate";
import TailwindModalLayout from "../Common/TailwindModalLayout";
import Icons from "../Icons";
import { createANewColumnName } from "../Table/utils";
import CustomTextEditor from "./Common/CustomTextEditor";
import { defaultSlateValue } from "@/utils/constants";

function GenerateFormula() {
  const queryClient = useQueryClient();
  const editedFormula = useEnrichStore((state) => state.editedFormula);
  const formulaPrompt = useEnrichStore((state) => state.formulaPrompt);

  const [prompt, setPrompt] = useState<any>([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const [generatedFormula, setGeneratedFormula] = useState<any>([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const [formulaStatus, setFormulaStatus] = useState<{
    isValid: boolean;
    message?: string;
  } | null>(null);
  const [isGeneratingFormula, setIsGeneratingFormula] = useState(false);
  const [savingFormula, setSavingFormula] = useState<boolean>(false);
  const [usedColumsInFormula, setUsedColumsInformula] = useState<
    Partial<Column | null>[]
  >([]);
  const [formulaAppliedRows, setFormulaAppliedRows] = useState<any>([]);

  const toast = useToast();
  const tableId = useTableStore((state) => state.tableData._id);
  const totalRows = useTableStore((state) => state.totalRows);
  const isProcessing = useTableStore(
    (state) => state.tableData.metaData?.isProcessing,
  );
  const tableRows = useTableStore((state) => state.rowsData);
  const updateTableState = useTableStore((state) => state.updateState);
  const generateFormulaModal = useEnrichStore(
    (state) => state.generateFormulaModal,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const { mutateAsync } = useMutation({
    mutationFn: ({
      tableId,
      payload,
    }: {
      tableId: string;
      payload: {
        prompt?: string;
        generatedFromula?: string;
      };
    }) => tableService.generateFormula(tableId, payload),
  });

  const { mutateAsync: addFormulaColumnAsync } = useMutation({
    mutationFn: ({
      tableId,
      payload,
    }: {
      tableId: string;
      payload: {
        prompt: any;
        formula: string;
        name?: string;
        totalRows: number;
      };
    }) => tableService.addFormulaColumn(tableId, payload),
  });

  const onCloseModal = () => {
    updateState({
      generateFormulaModal: {
        isOpen: false,
        type: "formula",
      },
    });
  };

  const callToGenerateFormula = useCallback(
    async ({
      prompt,
      generatedFromula,
    }: {
      prompt: string;
      generatedFromula?: string;
    }) => {
      setIsGeneratingFormula(true);

      await mutateAsync(
        {
          tableId,
          payload: {
            prompt: prompt || "",
            generatedFromula: generatedFromula || "",
          },
        },
        {
          onSuccess: (response) => {
            console.log("response", response);
            if (response?.success) {
              setGeneratedFormula([
                {
                  type: "paragraph",
                  children: [{ text: response?.data?.data?.formula }],
                },
              ]);
              if (!response?.data?.data?.usedTableColumns?.length) {
                setFormulaStatus({
                  isValid: false,
                  message: "No columns used in the formula",
                });
              } else {
                setFormulaStatus({
                  isValid: true,
                  message: "",
                });
              }
              setUsedColumsInformula(
                response?.data?.data?.usedTableColumns || [],
              );
              setFormulaAppliedRows(
                response?.data?.data?.formulaAppliedRows || [],
              );
            }
            setIsGeneratingFormula(false);
          },

          onError: (error) => {
            console.log("error", error);
            setIsGeneratingFormula(false);
          },
        },
      );
    },
    [],
  );

  const handleGenerateFormula = async () => {
    const modifiedPrompt = convertSlateToText(prompt);

    if (modifiedPrompt?.text === "") {
      toast({
        position: "top-right",
        title: "Error",
        description: "You must write a prompt!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (modifiedPrompt?.count?.length === 0) {
      toast({
        position: "top-right",
        title: "Error",
        description: "You must select at least one column!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    await callToGenerateFormula({
      prompt: modifiedPrompt.text,
    });
  };

  const examplePrompts = [
    `Get the first name from {{Full Name}} then add it to "Good Morning "`,
    `Get the username from this {{Linkedin URL}} url`,
    `Get the value after @ in {{Twitter Handle}}`,
    `Split the {{address}} by comma and select the third item, if it says "USA" then add "Lived in United States."`,
    `If {{People Count}} is greater than 50 then add "Large Company" else add "Small Company"`,
  ];

  const handleSaveFormula = async () => {
    if (isProcessing) {
      toast({
        position: "top-right",
        title:
          "Already one process is running. Please wait until it completes.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (formulaAppliedRows?.length === 0) {
      return toast({
        position: "top-right",
        title: "Error",
        description: "No formula applied to the rows",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }

    setSavingFormula(true);

    await addFormulaColumnAsync(
      {
        tableId,
        payload: {
          formula: generatedFormula,
          prompt: prompt || defaultSlateValue,
          totalRows,
          name: createANewColumnName("Formula"),
        },
      },
      {
        onSuccess: (response) => {
          console.log("response", response);

          if (!response.success || response.error) {
            return toast({
              position: "top-right",
              title: response.data?.message || "Failed to create new column",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }

          if (response?.success) {
            if (response.data?.success) {
              updateTableState({
                tableData: response.data.data,
              });
            }

            toast({
              position: "top-right",
              title: "Success",
              description: "Formula added to the table",
              status: "success",
              duration: 2000,
              isClosable: true,
            });

            queryClient.refetchQueries({
              queryKey: ["table-running-processes", tableId],
            });

            onCloseModal();
          }
        },

        onError: (error) => {
          console.log("error", error);
          return toast({
            position: "top-right",
            title: "Failed to create new column",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );

    setSavingFormula(false);
  };

  const handleUseForCondition = () => {
    if (formulaStatus?.isValid) {
      if (generateFormulaModal?.type === "edit") {
        updateState({
          editedFormula: generatedFormula,
          formulaPrompt: prompt,
        });
      } else {
        updateState({
          runConditionFormula: generatedFormula,
          isInvalidConditionFormula: false,
        });
      }
      onCloseModal();
    }
  };

  const handleFormulaEditorChange = (value: Descendant[]) => {
    try {
      const modifiedFormula = convertSlateToText(value);

      if (!modifiedFormula.text) {
        return;
      }

      setFormulaStatus({
        isValid: true,
        message: "Formula is valid",
      });

      callToGenerateFormula({
        generatedFromula: modifiedFormula?.text,
        prompt: "",
      });
    } catch (error: any) {
      console.log("error", error);
      const errorMessage = error.toString();
      setFormulaStatus({
        isValid: false,
        message: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (generateFormulaModal?.type === "edit") {
      if (editedFormula) {
        setGeneratedFormula(editedFormula);
      }

      if (formulaPrompt) {
        setPrompt(formulaPrompt);
      }
    }
  }, [editedFormula, formulaPrompt, generateFormulaModal]);

  return (
    <TailwindModalLayout
      modalOpen={generateFormulaModal?.isOpen}
      setModalOpen={onCloseModal}
      closeOnOuterClick={true}
    >
      <div className="relative inline-block h-full overflow-hidden text-left align-bottom font-title transition-all sm:my-8 sm:w-full sm:max-w-[85rem] sm:align-middle">
        <Icons.CloseIcon
          className="absolute right-4 top-4 cursor-pointer text-3xl"
          onClick={onCloseModal}
        />
        <div className="max-h-[87vh] overflow-y-auto rounded-lg bg-white p-5 font-title shadow-xl">
          <h2 className="text-2xl font-semibold tracking-wide">
            Generate Formula
          </h2>
          <div className="my-5 h-[1px] w-full bg-[#e7e8ec]" />
          <div
            className="grid"
            style={{
              gap: usedColumsInFormula?.length > 0 ? "3rem" : "1.5rem",
              gridTemplateColumns:
                usedColumsInFormula?.length > 0
                  ? "repeat(1, 35% 1fr)"
                  : "repeat(1 , 55% 1fr)",
            }}
          >
            <div>
              <div>
                <p className="pb-4 text-xl font-semibold">
                  Describe the formula you want to apply
                </p>
                <CustomTextEditor
                  slateValue={prompt}
                  setSlateValue={setPrompt}
                  actionBtn={
                    <button
                      onClick={handleGenerateFormula}
                      disabled={isGeneratingFormula}
                      className="flex items-center gap-x-1.5 rounded bg-primary px-2.5 py-1 text-sm font-medium text-white"
                    >
                      <Icons.LongLeftArrow className="inline-block rotate-180" />
                      <span>
                        {isGeneratingFormula
                          ? "Generating..."
                          : "Generate Formula"}{" "}
                      </span>
                    </button>
                  }
                  placeholder={`Get the domain from {{Email}} then make it look like a website url`}
                />
              </div>
              <div>
                <p className="pb-2 pt-4 text-xl font-semibold">
                  Or describe your own formula
                </p>
                <CustomTextEditor
                  editorHeight="7rem"
                  readonly={isGeneratingFormula}
                  slateValue={generatedFormula}
                  setSlateValue={(value: any) => {
                    setGeneratedFormula(value);
                  }}
                  isCodeView={true}
                  placeholder={`"Hi " + {{Name}} + "." + "Your email is " + {{Email}}`}
                  actionBtn={
                    <button
                      onClick={() =>
                        handleFormulaEditorChange(generatedFormula)
                      }
                      disabled={isGeneratingFormula}
                      className="flex items-center rounded-md border border-[#8080801c] bg-gray text-sm hover:shadow-md px-2.5 py-1 font-medium text-gray-500 space-x-1.5"
                    >
                      <Icons.LongLeftArrow className="inline-block rotate-180" />
                      <span>
                        {isGeneratingFormula ? "Applying..." : "Apply Formula"}{" "}
                      </span>
                    </button>
                  }
                />
              </div>
            </div>
            <div>
              {formulaStatus?.isValid === false && (
                <div
                  className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                  role="alert"
                >
                  <strong className="font-bold">Error! </strong>
                  <span className="block sm:inline">
                    {formulaStatus?.message}
                  </span>
                </div>
              )}

              {!usedColumsInFormula?.length && (
                <>
                  <p className="text-xl font-semibold">Example Prompts</p>
                  <div className="w-[90%] space-y-5 pt-3 text-sm font-medium">
                    {examplePrompts.map((item, index) => (
                      <p
                        className="grid grid-cols-[repeat(1,_1.05rem_1fr)]"
                        key={index}
                      >
                        <span className="inline-block w-4">{index + 1}.</span>{" "}
                        <span>{item}</span>
                      </p>
                    ))}
                  </div>
                </>
              )}
              {usedColumsInFormula?.length > 0 && (
                <div>
                  <p className="pb-4 text-xl font-medium">
                    {isGeneratingFormula
                      ? "Generating Preview..."
                      : "Formula Preview"}
                  </p>
                  {!isGeneratingFormula && (
                    <>
                      <div className="h-fit w-full border-x border-[#0000001a] font-title text-sm">
                        <div className="flex h-[32px] w-full items-center border-y border-[#0000001a] font-medium tracking-wide">
                          <div className="flex h-full w-[64px] items-center justify-center border-r border-[#0000001a]">
                            <p>Row</p>
                          </div>
                          <div className="flex size-full">
                            <div
                              className="grid w-full"
                              style={{
                                gridTemplateColumns: `repeat(${
                                  usedColumsInFormula?.length + 1
                                }, 1fr)`,
                              }}
                            >
                              <div className="flex h-full items-center border-r border-[#0000001a] px-2">
                                <p>Output</p>
                              </div>
                              {usedColumsInFormula?.map((item, index) => (
                                <div
                                  key={index}
                                  className={`flex h-full items-center px-2 ${
                                    index === usedColumsInFormula?.length - 1
                                      ? ""
                                      : "border-r border-[#0000001a]"
                                  }`}
                                >
                                  <p className="truncate">{item?.name}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {new Array(
                          tableRows?.length > 10 ? 10 : tableRows?.length,
                        )
                          .fill(0)
                          .map((_, Rowindex) => {
                            return (
                              <div
                                key={Rowindex}
                                className="flex h-[32px] w-full items-center border-b border-[#0000001a]"
                              >
                                <div className="flex h-full w-[64px] items-center justify-center border-r border-[#0000001a]">
                                  <p>{Rowindex + 1}</p>
                                </div>
                                <div className="flex size-full">
                                  <div
                                    className="grid w-full"
                                    style={{
                                      gridTemplateColumns: `repeat(${
                                        usedColumsInFormula?.length + 1
                                      }, 1fr)`,
                                    }}
                                  >
                                    <div className="flex h-full w-auto items-center overflow-hidden border-r border-[#0000001a] px-2">
                                      <p className="truncate">
                                        {formulaAppliedRows?.[Rowindex]}
                                      </p>
                                    </div>
                                    {usedColumsInFormula?.map((item, index) => (
                                      <div
                                        key={index}
                                        className={`flex h-full items-center overflow-hidden px-2 ${
                                          index ===
                                          usedColumsInFormula?.length - 1
                                            ? ""
                                            : "border-r border-[#0000001a]"
                                        }`}
                                      >
                                        <p className="overflow-hidden truncate">
                                          {processCellValue(
                                            tableRows?.[Rowindex]?.cells?.[
                                              item?._id ?? ""
                                            ],
                                          )}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="pt-3.5">
                        <button
                          onClick={
                            generateFormulaModal?.type === "condition" ||
                            generateFormulaModal?.type === "edit"
                              ? handleUseForCondition
                              : handleSaveFormula
                          }
                          disabled={savingFormula}
                          className="flex items-center gap-x-1.5 rounded bg-[#0A9E5C] px-2.5 py-1 text-sm font-medium text-white"
                        >
                          <Icons.CheckCircleIcon className="inline-block" />
                          {generateFormulaModal?.type === "condition" ||
                          generateFormulaModal?.type === "edit" ? (
                            <span>Use it</span>
                          ) : (
                            <span>
                              {savingFormula
                                ? "Saving..."
                                : "Save and add to the table"}
                            </span>
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </TailwindModalLayout>
  );
}

export default GenerateFormula;
