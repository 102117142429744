import { useMutation, useQuery } from "@tanstack/react-query";

import presetsService from "@/services/presets.service";

import type { PresetEnrichmentType } from "@/types/presets.types";

export const useHttpPresetsData = (enrichmentType: PresetEnrichmentType) =>
  useQuery({
    queryKey: ["presets", enrichmentType],
    queryFn: () => presetsService.fetchHttpPresets(enrichmentType),
    staleTime: 5 * 60 * 1000, // 5 minutes`
    gcTime: 5 * 60 * 1000, // 5 minutes
  });

export const useSaveHttpPreset = () =>
  useMutation({
    mutationFn: (payload: any) => presetsService.saveHttpPreset(payload),
  });
