import { useMutation, useQuery } from "@tanstack/react-query";

import userService from "@/services/user.service";

export const useUserData = () => {
  return useQuery({
    queryKey: ["user-details"],
    queryFn: async () => userService.getUser(),
    gcTime: 1000 * 60 * 60 * 24, // 1 day
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};

export const useUserSubscriptionData = () => {
  return useQuery({
    queryKey: ["user-subscription-details"],
    queryFn: async () => userService.getSubscriptionDetails(),
    gcTime: 1000 * 60 * 60 * 24, // 1 day
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};

export const useUpdateUserDetails = () =>
  useMutation({
    mutationFn: (payload: Record<string, any>) =>
      userService.updateUserDetails(payload),
  });
