import { colorList } from "@/lib/utils";
import { shuffle } from "lodash";
import { Descendant } from "slate";
import { create } from "zustand";
export const getDescriptionDesc = (description: string) => {
  return [
    {
      type: "paragraph",
      children: [{ text: description }],
    },
  ];
};
export type AutoGeneratedColumnSchema = Pick<
  ColumnSchema,
  "name" | "type" | "readonly"
> & {
  description: string;
  options: string[];
};
export type ColumnSchema = {
  type:
    | "string"
    | "number"
    | "boolean"
    | "email"
    | "url"
    | "array"
    | "enumSingle"
    | "enumMulti";
  name: string;
  description?: Descendant[];
  readonly?: true;
  options?: {
    value: string;
    color: string;
  }[];
  colorPalette?: string[];
};
export const summaryField: ColumnSchema = {
  name: "result",
  type: "string",
  readonly: true,
  description: getDescriptionDesc(
    "Exact concise answer to the users question in least words.",
  ),
};

const defaultState: ColumnStoreState = {
  mainColumn: "result",
  editingIndex: null,
  addToTable: [0],
  columns: [
    summaryField,
    {
      name: "confidence",
      type: "number",
      readonly: true,
    },
    {
      name: "evidence/reference",
      type: "array",
      readonly: true,
      description: getDescriptionDesc("References to the evidence"),
    },
  ] as const,
  colorPallet: shuffle(colorList),
};
type ColumnStoreState = {
  mainColumn: string;
  editingIndex: number | null;
  columns: ColumnSchema[];
  addToTable: number[];
  colorPallet: string[];
};
type ColumnStoreActions = {
  setEditingIndex: (index: number | null) => void;
  addColumn: (column: ColumnSchema) => void;
  toggleAddToTable: (index: number) => void;
  updateColumn: (index: number, updatedColumn: Partial<ColumnSchema>) => void;
  removeColumn: (index: number) => void;
  reset: () => void;
  isSummaryAdded: () => boolean;
  toggleSummary: () => void;
};
type ColumnStore = ColumnStoreState & ColumnStoreActions;

export const useColumnStore = create<ColumnStore>((set, get) => ({
  ...defaultState,
  toggleAddToTable: (index) => {
    set((state) => {
      const addToTable = [...state.addToTable];
      if (addToTable.includes(index)) {
        addToTable.splice(addToTable.indexOf(index), 1);
      } else {
        addToTable.push(index);
      }
      return { addToTable };
    });
  },
  setEditingIndex: (index) => set({ editingIndex: index }),
  addColumn: (column) =>
    set((state) => {
      if (state.columns.some((col) => col.name === column.name)) {
        return state;
      }
      return {
        columns: [...state.columns, column],
        editingIndex: state.columns.length,
        addToTable: [...state.addToTable, state.columns.length],
      };
    }),
  updateColumn: (index, updatedColumn) =>
    set((state) => {
      const columns = [...state.columns];
      if (state.columns.some((col) => col.name === updatedColumn.name)) {
        alert("Column name must be unique.");
        return state;
      }
      columns[index] = { ...columns[index], ...updatedColumn } as ColumnSchema;
      return { columns };
    }),
  removeColumn: (index) =>
    set((state) => ({
      columns: state.columns.filter((_, i) => i !== index),
      editingIndex: null,
    })),
  reset: () => set(defaultState),
  isSummaryAdded() {
    const columns = get().columns;
    return columns.some((col) => col.name === summaryField.name);
  },
  toggleSummary: () => {
    const { columns, addToTable, mainColumn } = get();
    const index = columns.findIndex((col) => col.name === summaryField.name);
    if (index >= 0) {
      const newColumns = columns.filter(
        (col) => col.name !== summaryField.name,
      );
      const mainColumnIndex = newColumns.findIndex(
        (col) => col.name === mainColumn,
      );
      set({
        editingIndex: null,
        addToTable: [
          ...new Set(
            addToTable.filter((i) => i !== index).concat(mainColumnIndex),
          ),
        ],
        columns: newColumns,
      });
    } else {
      set({
        editingIndex: null,
        addToTable: [...addToTable, index],
        columns: [summaryField, ...columns],
      });
    }
  },
}));
