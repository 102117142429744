import React, { useRef } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Input,
  InputGroup,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

interface Option {
  label: string;
  value: number;
}

const options: Option[] = [
  { label: "0.1M", value: 100000 },
  { label: "0.5M", value: 500000 },
  { label: "1M", value: 1000000 },
  { label: "5M", value: 5000000 },
  { label: "10M", value: 10000000 },
  { label: "25M", value: 25000000 },
  { label: "50M", value: 50000000 },
  { label: "100M", value: 100000000 },
  { label: "500M", value: 500000000 },
  { label: "1B", value: 1000000000 },
  { label: "5B", value: 5000000000 },
  { label: "10B", value: 10000000000 },
];

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const Revenue: React.FC<Props> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    onChange(inputValue);
  };

  const handleOptionClick = (optionValue: number) => {
    onChange(optionValue.toString());
    inputRef.current?.focus();
    onClose();
  };

  const formatDisplayValue = (val: string): string => {
    if (!val) return "";
    const num = parseInt(val, 10);
    return isNaN(num) ? "" : num.toLocaleString();
  };

  return (
    <Menu isLazy isOpen={isOpen} onClose={onClose}>
      <>
        <MenuButton
          as={Button}
          width="100%"
          padding={0}
          fontWeight="normal"
          className="!bg-white"
          onClick={() => {
            onOpen();
            inputRef.current?.focus();
          }}
        >
          <InputGroup>
            <Input
              ref={inputRef}
              value={formatDisplayValue(value)}
              onChange={handleInputChange}
              placeholder="e.g. 1M"
              onClick={(e) => {
                e.stopPropagation();
                inputRef.current?.focus();
                onOpen();
              }}
            />
          </InputGroup>
        </MenuButton>
        <Portal>
          <MenuList maxHeight="200px" overflowY="auto">
            {options.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </>
    </Menu>
  );
};

export default Revenue;
