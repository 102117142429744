import { useEffect, useState } from "react";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import ConditionalFormula from "../../Common/ConditionalFormula";
import KeySelect from "@/components/Common/KeySelect";
import { useEnrichStore } from "@/stores/enrich.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import { preselectEnrichmentState, updateInitialColumn } from "@/utils";
import { VStack } from "@chakra-ui/react";

const HubspotLookupContact = () => {
  const {
    viewMode,
    selectedColumnToEdit,
    updateState: updateEnrichmentState,
  } = useEnrichStore((state) => ({
    viewMode: state.viewMode,
    selectedColumnToEdit: state.selectedColumnToEdit,
    updateState: state.updateState,
  }));

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [firstName, setFirstName] = useState<TSelectedColumnOption | null>(
    null,
  );
  const [lastName, setLastName] = useState<TSelectedColumnOption | null>(null);
  const [companyName, setCompanyName] = useState<TSelectedColumnOption | null>(
    null,
  );
  const [companyDomain, setCompanyDomain] =
    useState<TSelectedColumnOption | null>(null);

  const updateColumnStates = (metadata: EnrichmentMetaData) => {
    preselectEnrichmentState(metadata, setSelectedOption, metadata?.email);
    preselectEnrichmentState(metadata, setFirstName, metadata?.firstName);
    preselectEnrichmentState(metadata, setLastName, metadata?.lastName);
    preselectEnrichmentState(metadata, setCompanyName, metadata?.companyName);
    preselectEnrichmentState(
      metadata,
      setCompanyDomain,
      metadata?.companyDomain,
    );
  };

  useEffect(() => {
    if (viewMode === "edit" && selectedColumnToEdit?.metaData) {
      const enrichmentData = selectedColumnToEdit.metaData
        .enrichmentMetaData as EnrichmentMetaData;
      if (enrichmentData) {
        updateColumnStates(enrichmentData);
        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    } else {
      updateInitialColumn("email", setSelectedOption);
      updateInitialColumn("first name", setFirstName);
      updateInitialColumn("last name", setLastName);
      updateInitialColumn("company name", setCompanyName);
      updateInitialColumn("domain", setCompanyDomain);
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial subText="Lookup record in the hubspot" link="" />

        <KeySelect
          providerName="hubspotApiKey"
          providerTitle="HubSpot API Key"
          disablePersanaKey={true}
        />

        <VStack spacing={3} align="stretch">
          {[
            {
              label: "Email",
              state: selectedOption,
              setter: setSelectedOption,
            },
            {
              label: "Email Domain (If you know the website, you can use this)",
              state: companyDomain,
              setter: setCompanyDomain,
            },
            { label: "First Name", state: firstName, setter: setFirstName },
            { label: "Last Name", state: lastName, setter: setLastName },
            {
              label: "Company Name",
              state: companyName,
              setter: setCompanyName,
            },
          ].map(({ label, state, setter }) => (
            <div key={label}>
              <p className="mt-1 pb-1 text-base font-semibold">{label}</p>
              <SelectColumnDropdown
                selectedOption={state}
                setSelectedOption={setter}
              />
            </div>
          ))}
        </VStack>

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={
          !selectedOption?.keyId &&
          !firstName?.keyId &&
          !lastName?.keyId &&
          !companyName?.keyId
        }
        apiPayload={{
          apiKeyName: "hubspotApiKey",
          apiKeyErrorMessage: "Please connect your hubspot account",
          email: selectedOption?.keyId,
          firstName: firstName?.keyId,
          lastName: lastName?.keyId,
          companyName: companyName?.keyId,
          companyDomain: companyDomain?.keyId,
        }}
      />
    </>
  );
};

export default HubspotLookupContact;
