import { TRootFolderId, TRowId } from "./types";

export const ROW_HEIGHT = 60.5;
export const ALWAYS_FOLDER_FIRST = false;
export const NEW_FOLDER_ID_PLACEHOLDER = "___new___" as TRowId;
export const NEW_FOLDER_NAME_PLACEHOLDER = "new folder";
export const ROOT_FOLDER_ID = "" as TRootFolderId;
export const ROOT_FOLDER_NAME = "___root__";

export const DEFAULT_FOLDER_OPEN = false;

export const GRID_ALLOWED_KEY_EVENTS = ["Tab", "ArrowDown", "ArrowUp"];
