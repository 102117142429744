import { twMerge } from "tailwind-merge";
import { generateId } from "@/utils";

type Props = {
  name: string;
  value: string;
  onChange: (val: any) => void;
  inputClassName: string;
  placeholder: string;
  required: boolean;
};

const TextareaField = ({
  name,
  value,
  onChange,
  inputClassName,
  placeholder,
  required,
}: Props) => {
  return (
    <textarea
      id={generateId(name)}
      value={value}
      name={name}
      onChange={onChange}
      className={`${twMerge(
        "w-full rounded  px-2 py-1.5 text-sm focus:border-primary focus:outline-none focus:ring-0",
        inputClassName,
      )}`}
      style={{
        border: "1px solid #c6c6ca",
      }}
      placeholder={placeholder}
      required={required || false}
    />
  );
};

export default TextareaField;
