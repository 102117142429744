import { useEffect } from "react";
import { useTableState } from "../useTableState.store";
import { ROW_HEIGHT } from "../../constants";
import { columnDefs } from "../../columnDefs";

export const useConfig = () => {
  const search = useTableState((state) => state.search);
  const gridApi = useTableState((state) => state.gridApi);
  const updateState = useTableState((state) => state.updateState);

  useEffect(() => {
    gridApi?.updateGridOptions({
      rowDragMultiRow: true,
      suppressRowClickSelection: true,
      rowSelection: "multiple",
      suppressHeaderFocus: true,
      animateRows: true,
      columnDefs,
      rowHeight: ROW_HEIGHT,
      headerHeight: 0,
    });
  }, [gridApi]);

  useEffect(() => {
    updateState({ showFolders: !search });
  }, [search]);
};
