export const iScraperCompanyTypes = [
  {
    label: "privately held",
    value: 6894212,
  },
  {
    label: "sole proprietorship",
    value: 2007720,
  },
  {
    label: "self-employed",
    value: 1571950,
  },
  {
    label: "partnership",
    value: 1447785,
  },
  {
    label: "public company",
    value: 1354855,
  },
  {
    label: "nonprofit",
    value: 1045022,
  },
  {
    label: "educational institution",
    value: 481679,
  },
  {
    label: "government agency",
    value: 101952,
  },
];

export const staticCountryWithCodes = [
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cabo Verde", value: "CV" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo (the Democratic Republic of the)", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curaçao", value: "CW" },
  { label: "Cyprus", value: "CY" },
  { label: "Czechia", value: "CZ" },
  { label: "Côte d'Ivoire", value: "CI" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Eswatini", value: "SZ" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands [Malvinas]", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and McDonald Islands", value: "HM" },
  { label: "Holy See", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran (Islamic Republic of)", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea (the Democratic People's Republic of)", value: "KP" },
  { label: "Korea (the Republic of)", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People's Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia (Federated States of)", value: "FM" },
  { label: "Moldova (the Republic of)", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "North Macedonia", value: "MK" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestine, State of", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Réunion", value: "RE" },
  { label: "Saint Barthélemy", value: "BL" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Martin (French part)", value: "MF" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten (Dutch part)", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "South Sudan", value: "SS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan (Province of China)", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom of Great Britain and Northern Ireland", value: "GB" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "United States of America", value: "US" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands (British)", value: "VG" },
  { label: "Virgin Islands (U.S.)", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];



export const iScraperIndustriesList = [
  {
    label: "real estate",
    value: 2998658,
  },
  {
    label: "medical practice",
    value: 2756171,
  },
  {
    label: "construction",
    value: 2550355,
  },
  {
    label: "retail",
    value: 2406583,
  },
  {
    label: "management consulting",
    value: 1676103,
  },
  {
    label: "individual & family services",
    value: 1667149,
  },
  {
    label: "information technology & services",
    value: 1606821,
  },
  {
    label: "computer software",
    value: 1565861,
  },
  {
    label: "restaurants",
    value: 1546577,
  },
  {
    label: "marketing & advertising",
    value: 1123864,
  },
  {
    label: "hospital & health care",
    value: 1042664,
  },
  {
    label: "wholesale",
    value: 881433,
  },
  {
    label: "civic & social organization",
    value: 833694,
  },
  {
    label: "health, wellness & fitness",
    value: 767028,
  },
  {
    label: "financial services",
    value: 756613,
  },
  {
    label: "hospitality",
    value: 699597,
  },
  {
    label: "facilities services",
    value: 573044,
  },
  {
    label: "electrical & electronic manufacturing",
    value: 567327,
  },
  {
    label: "consumer goods",
    value: 549639,
  },
  {
    label: "transportation/trucking/railroad",
    value: 542888,
  },
  {
    label: "consumer services",
    value: 540610,
  },
  {
    label: "machinery",
    value: 530582,
  },
  {
    label: "food & beverages",
    value: 511514,
  },
  {
    label: "internet",
    value: 501033,
  },
  {
    label: "automotive",
    value: 478519,
  },
  {
    label: "non-profit organization management",
    value: 471672,
  },
  {
    label: "sports",
    value: 471167,
  },
  {
    label: "mining & metals",
    value: 445542,
  },
  {
    label: "farming",
    value: 435589,
  },
  {
    label: "accounting",
    value: 430669,
  },
  {
    label: "food production",
    value: 417252,
  },
  {
    label: "architecture & planning",
    value: 414418,
  },
  {
    label: "education management",
    value: 394806,
  },
  {
    label: "insurance",
    value: 381272,
  },
  {
    label: "mechanical or industrial engineering",
    value: 375404,
  },
  {
    label: "apparel & fashion",
    value: 370153,
  },
  {
    label: "design",
    value: 368351,
  },
  {
    label: "legal services",
    value: 345054,
  },
  {
    label: "performing arts",
    value: 340286,
  },
  {
    label: "business supplies & equipment",
    value: 332316,
  },
  {
    label: "higher education",
    value: 327439,
  },
  {
    label: "professional training & coaching",
    value: 326525,
  },
  {
    label: "leisure, travel & tourism",
    value: 317022,
  },
  {
    label: "research",
    value: 292179,
  },
  {
    label: "entertainment",
    value: 290120,
  },
  {
    label: "e-learning",
    value: 283815,
  },
  {
    label: "religious institutions",
    value: 272423,
  },
  {
    label: "law practice",
    value: 268260,
  },
  {
    label: "publishing",
    value: 267958,
  },
  {
    label: "environmental services",
    value: 245464,
  },
  {
    label: "investment management",
    value: 240999,
  },
  {
    label: "civil engineering",
    value: 239767,
  },
  {
    label: "government administration",
    value: 237699,
  },
  {
    label: "building materials",
    value: 233833,
  },
  {
    label: "human resources",
    value: 231938,
  },
  {
    label: "media production",
    value: 231410,
  },
  {
    label: "events services",
    value: 229823,
  },
  {
    label: "recreational facilities & services",
    value: 226096,
  },
  {
    label: "telecommunications",
    value: 224759,
  },
  {
    label: "furniture",
    value: 218115,
  },
  {
    label: "package/freight delivery",
    value: 212124,
  },
  {
    label: "staffing & recruiting",
    value: 210638,
  },
  {
    label: "utilities",
    value: 206409,
  },
  {
    label: "photography",
    value: 204446,
  },
  {
    label: "supermarkets",
    value: 194294,
  },
  {
    label: "textiles",
    value: 194191,
  },
  {
    label: "primary/secondary education",
    value: 192409,
  },
  {
    label: "medical device",
    value: 183308,
  },
  {
    label: "oil & energy",
    value: 178104,
  },
  {
    label: "paper & forest products",
    value: 175760,
  },
  {
    label: "chemicals",
    value: 174162,
  },
  {
    label: "plastics",
    value: 167610,
  },
  {
    label: "cosmetics",
    value: 167233,
  },
  {
    label: "motion pictures & film",
    value: 162270,
  },
  {
    label: "veterinary",
    value: 161491,
  },
  {
    label: "music",
    value: 158219,
  },
  {
    label: "broadcast media",
    value: 142284,
  },
  {
    label: "graphic design",
    value: 140992,
  },
  {
    label: "ranching",
    value: 136217,
  },
  {
    label: "banking",
    value: 135711,
  },
  {
    label: "renewables & environment",
    value: 129999,
  },
  {
    label: "printing",
    value: 129748,
  },
  {
    label: "security & investigations",
    value: 128930,
  },
  {
    label: "online media",
    value: 123751,
  },
  {
    label: "logistics & supply chain",
    value: 121615,
  },
  {
    label: "public relations & communications",
    value: 120358,
  },
  {
    label: "information services",
    value: 120281,
  },
  {
    label: "consumer electronics",
    value: 117424,
  },
  {
    label: "pharmaceuticals",
    value: 104434,
  },
  {
    label: "luxury goods & jewelry",
    value: 100678,
  },
  {
    label: "international trade & development",
    value: 98788,
  },
  {
    label: "mental health care",
    value: 97047,
  },
  {
    label: "arts & crafts",
    value: 91493,
  },
  {
    label: "investment banking",
    value: 86647,
  },
  {
    label: "museums & institutions",
    value: 83661,
  },
  {
    label: "biotechnology",
    value: 79343,
  },
  {
    label: "fine art",
    value: 79054,
  },
  {
    label: "writing & editing",
    value: 77603,
  },
  {
    label: "industrial automation",
    value: 75700,
  },
  {
    label: "airlines/aviation",
    value: 74738,
  },
  {
    label: "executive office",
    value: 73990,
  },
  {
    label: "wine & spirits",
    value: 70903,
  },
  {
    label: "commercial real estate",
    value: 65156,
  },
  {
    label: "glass, ceramics & concrete",
    value: 64235,
  },
  {
    label: "import & export",
    value: 62507,
  },
  {
    label: "venture capital & private equity",
    value: 55842,
  },
  {
    label: "computer & network security",
    value: 54646,
  },
  {
    label: "computer games",
    value: 54232,
  },
  {
    label: "outsourcing/offshoring",
    value: 54172,
  },
  {
    label: "packaging & containers",
    value: 50395,
  },
  {
    label: "semiconductors",
    value: 50319,
  },
  {
    label: "defense & space",
    value: 50296,
  },
  {
    label: "maritime",
    value: 46718,
  },
  {
    label: "dairy",
    value: 42666,
  },
  {
    label: "aviation & aerospace",
    value: 41251,
  },
  {
    label: "computer hardware",
    value: 40155,
  },
  {
    label: "sporting goods",
    value: 39606,
  },
  {
    label: "warehousing",
    value: 38838,
  },
  {
    label: "capital markets",
    value: 38136,
  },
  {
    label: "market research",
    value: 36315,
  },
  {
    label: "newspapers",
    value: 33744,
  },
  {
    label: "translation & localization",
    value: 33524,
  },
  {
    label: "fishery",
    value: 32477,
  },
  {
    label: "alternative medicine",
    value: 32424,
  },
  {
    label: "public safety",
    value: 30038,
  },
  {
    label: "political organization",
    value: 27219,
  },
  {
    label: "philanthropy",
    value: 26942,
  },
  {
    label: "program development",
    value: 26450,
  },
  {
    label: "computer networking",
    value: 25845,
  },
  {
    label: "think tanks",
    value: 25724,
  },
  {
    label: "shipbuilding",
    value: 25244,
  },
  {
    label: "law enforcement",
    value: 24831,
  },
  {
    label: "animation",
    value: 22419,
  },
  {
    label: "fundraising",
    value: 22229,
  },
  {
    label: "government relations",
    value: 20575,
  },
  {
    label: "tobacco",
    value: 20249,
  },
  {
    label: "international affairs",
    value: 17532,
  },
  {
    label: "gambling & casinos",
    value: 17268,
  },
  {
    label: "libraries",
    value: 14591,
  },
  {
    label: "judiciary",
    value: 13538,
  },
  {
    label: "railroad manufacture",
    value: 12855,
  },
  {
    label: "software development",
    value: 12236,
  },
  {
    label: "public policy",
    value: 12219,
  },
  {
    label: "wireless",
    value: 12063,
  },
  {
    label: "military",
    value: 9983,
  },
  {
    label: "it services and it consulting",
    value: 7840,
  },
  {
    label: "alternative dispute resolution",
    value: 7619,
  },
  {
    label: "business consulting and services",
    value: 5961,
  },
  {
    label: "nanotechnology",
    value: 5673,
  },
  {
    label: "advertising services",
    value: 5618,
  },
  {
    label: "legislative office",
    value: 3986,
  },
  {
    label: "non-profit organizations",
    value: 2419,
  },
  {
    label: "staffing and recruiting",
    value: 2156,
  },
  {
    label: "hospitals and health care",
    value: 1916,
  },
  {
    label: "mobile games",
    value: 1484,
  },
  {
    label: "professional training and coaching",
    value: 1442,
  },
  {
    label: "truck transportation",
    value: 1160,
  },
  {
    label: "retail apparel and fashion",
    value: 1110,
  },
  {
    label: "wellness and fitness services",
    value: 1029,
  },
  {
    label: "technology, information and internet",
    value: 1000,
  },
  {
    label: "venture capital and private equity principals",
    value: 943,
  },
  {
    label: "human resources services",
    value: 858,
  },
  {
    label: "manufacturing",
    value: 841,
  },
  {
    label: "design services",
    value: 811,
  },
  {
    label: "renewable energy semiconductor manufacturing",
    value: 731,
  },
  {
    label: "spectator sports",
    value: 703,
  },
  {
    label: "architecture and planning",
    value: 689,
  },
  {
    label: "food and beverage services",
    value: 679,
  },
  {
    label: "biotechnology research",
    value: 674,
  },
  {
    label: "industrial machinery manufacturing",
    value: 657,
  },
  {
    label: "food and beverage manufacturing",
    value: 653,
  },
  {
    label: "motor vehicle manufacturing",
    value: 650,
  },
  {
    label: "entertainment providers",
    value: 534,
  },
  {
    label: "oil and gas",
    value: 533,
  },
  {
    label: "travel arrangements",
    value: 511,
  },
  {
    label: "research services",
    value: 508,
  },
  {
    label: "international trade and development",
    value: 465,
  },
  {
    label: "public relations and communications services",
    value: 442,
  },
  {
    label: "medical equipment manufacturing",
    value: 418,
  },
  {
    label: "online audio and video media",
    value: 415,
  },
  {
    label: "book and periodical publishing",
    value: 409,
  },
  {
    label: "appliances, electrical, and electronics manufacturing",
    value: 405,
  },
  {
    label: "computer and network security",
    value: 392,
  },
  {
    label: "education administration programs",
    value: 364,
  },
  {
    label: "wholesale building materials",
    value: 354,
  },
  {
    label: "musicians",
    value: 348,
  },
  {
    label: "machinery manufacturing",
    value: 347,
  },
  {
    label: "civic and social organizations",
    value: 322,
  },
  {
    label: "e-learning providers",
    value: 319,
  },
  {
    label: "transportation, logistics, supply chain and storage",
    value: 305,
  },
  {
    label: "retail luxury goods and jewelry",
    value: 294,
  },
  {
    label: "furniture and home furnishings manufacturing",
    value: 293,
  },
  {
    label: "broadcast media production and distribution",
    value: 277,
  },
  {
    label: "pharmaceutical manufacturing",
    value: 271,
  },
  {
    label: "chemical manufacturing",
    value: 246,
  },
  {
    label: "primary and secondary education",
    value: 239,
  },
  {
    label: "security and investigations",
    value: 229,
  },
  {
    label: "artists and writers",
    value: 213,
  },
  {
    label: "beverage manufacturing",
    value: 200,
  },
  {
    label: "mining",
    value: 194,
  },
  {
    label: "outsourcing and offshoring consulting",
    value: 187,
  },
  {
    label: "personal care product manufacturing",
    value: 178,
  },
  {
    label: "automation machinery manufacturing",
    value: 174,
  },
  {
    label: "individual and family services",
    value: 173,
  },
  {
    label: "packaging and containers manufacturing",
    value: 170,
  },
  {
    label: "textile manufacturing",
    value: 170,
  },
  {
    label: "medical practices",
    value: 169,
  },
  {
    label: "airlines and aviation",
    value: 164,
  },
  {
    label: "defense and space manufacturing",
    value: 156,
  },
  {
    label: "printing services",
    value: 149,
  },
  {
    label: "aviation and aerospace component manufacturing",
    value: 140,
  },
  {
    label: "writing and editing",
    value: 128,
  },
  {
    label: "plastics manufacturing",
    value: 123,
  },
  {
    label: "computers and electronics manufacturing",
    value: 103,
  },
  {
    label: "translation and localization",
    value: 99,
  },
  {
    label: "paper and forest product manufacturing",
    value: 96,
  },
  {
    label: "animation and post-production",
    value: 94,
  },
  {
    label: "retail office equipment",
    value: 84,
  },
  {
    label: "government relations services",
    value: 73,
  },
  {
    label: "political organizations",
    value: 66,
  },
  {
    label: "freight and package transportation",
    value: 65,
  },
  {
    label: "museums, historical sites, and zoos",
    value: 61,
  },
  {
    label: "newspaper publishing",
    value: 61,
  },
  {
    label: "recreational facilities",
    value: 56,
  },
  {
    label: "semiconductor manufacturing",
    value: 49,
  },
  {
    label: "gambling facilities and casinos",
    value: 47,
  },
  {
    label: "glass, ceramics and concrete manufacturing",
    value: 39,
  },
  {
    label: "computer hardware manufacturing",
    value: 36,
  },
  {
    label: "armed forces",
    value: 35,
  },
  {
    label: "executive offices",
    value: 29,
  },
  {
    label: "nanotechnology research",
    value: 26,
  },
  {
    label: "administration of justice",
    value: 20,
  },
  {
    label: "railroad equipment manufacturing",
    value: 12,
  },
  {
    label: "fisheries",
    value: 9,
  },
  {
    label: "maritime transportation",
    value: 9,
  },
  {
    label: "tobacco manufacturing",
    value: 8,
  },
  {
    label: "leasing non-residential real estate",
    value: 6,
  },
  {
    label: "movies, videos, and sound",
    value: 5,
  },
  {
    label: "philanthropic fundraising services",
    value: 5,
  },
  {
    label: "sporting goods manufacturing",
    value: 5,
  },
  {
    label: "public policy offices",
    value: 4,
  },
  {
    label: "retail groceries",
    value: 4,
  },
  {
    label: "wholesale import and export",
    value: 4,
  },
  {
    label: "retail art supplies",
    value: 3,
  },
  {
    label: "warehousing and storage",
    value: 3,
  },
  {
    label: "computer networking products",
    value: 2,
  },
  {
    label: "dairy product manufacturing",
    value: 2,
  },
  {
    label: "legislative offices",
    value: 2,
  },
  {
    label: "strategic management services",
    value: 2,
  },
  {
    label: "wireless services",
    value: 2,
  },
  {
    label: "mobile gaming apps",
    value: 1,
  },
  {
    label: "veterinary services",
    value: 1,
  },
];