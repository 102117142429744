import { FileRoute } from "@tanstack/react-router";

import LinkedInProfilesTable from "@/pages/LinkedInProfilesTable";

export const Route = new FileRoute("/linkedin-profiles/").createRoute({
  component: LinkedInProfilesPage,
  errorComponent: () => <div>Error</div>,
});

function LinkedInProfilesPage() {
  return <LinkedInProfilesTable />;
}

export default LinkedInProfilesPage;
