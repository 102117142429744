import { useEffect } from "react";
import { useWaterfallStore } from "./../store";
import { useEnrichStore } from "@/stores/enrich.store";
import { useProviderList } from "@/hooks/useProviderData";
import { ProviderNameType } from "@/stores/provider.store";

export const useUpdateOwnkeyList = () => {
  const { data: providerLists } = useProviderList();
  const userKeys = useWaterfallStore((state) => state.chosenUserKeys);
  const initialUserKeys = useWaterfallStore((state) => state.initialUserKeys);
  const persanaKeys = useWaterfallStore((state) => state.chosenNonUserKeys);
  const selectedProviders = useWaterfallStore(
    (state) => state.selectedProviders,
  );
  // sync ownKeyProviderList
  useEffect(() => {
    const newOwnKeyProviderList = selectedProviders
      .map((provider) => {
        const chosenToUseUserApiKey = userKeys.includes(
          provider.apikeyParams.keyName,
        );

        const chosenToUsePersanaApiKey = persanaKeys.includes(
          provider.apikeyParams.keyName,
        );

        const isPersanaKeyDisabled = !provider.apikeyParams.enablePersanaApiKey;

        const isKeyPreferred = providerLists?.data?.find((providerItem) => {
          if (provider.apikeyParams.keyName !== providerItem?.name)
            return false;
          const alwaysPreferKey = providerItem?.metaData?.alwaysPreferKey;
          const currentPreferKey = providerItem?.metaData?.preferKey;
          return alwaysPreferKey || currentPreferKey;
        });

        const isWaterfallInitialized = initialUserKeys !== null;
        const isInitiallySet = !!initialUserKeys?.includes(
          provider.apikeyParams.keyName,
        );

        if (isPersanaKeyDisabled) {
          return provider.apikeyParams.keyName;
        } else if (chosenToUseUserApiKey) {
          return provider.apikeyParams.keyName;
        } else if (chosenToUsePersanaApiKey) {
          return;
        } else if (isWaterfallInitialized) {
          if (isInitiallySet) {
            return provider.apikeyParams.keyName;
          }
        } else if (isKeyPreferred) {
          return provider.apikeyParams.keyName;
        }
      })
      .filter(Boolean) as ProviderNameType[];

    useEnrichStore.getState().updateState({
      ownKeyProviderList: [...newOwnKeyProviderList],
    });
  }, [
    selectedProviders,
    userKeys,
    providerLists,
    persanaKeys,
    initialUserKeys,
  ]);
};
