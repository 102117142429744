import { useCallback } from "react";
import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

const generateUniqueClassName = () => {
  return "custom-swal-container-" + Math.random().toString(36).substr(2, 9);
};
const handleEscape = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    Swal.close({
      isConfirmed: false,
      isDismissed: false,
      isDenied: false,
    });
    event.preventDefault();
    event.stopImmediatePropagation();
  }
};

const handleEnter = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    Swal.close({
      isConfirmed: true,
      isDismissed: false,
      isDenied: false,
    });
    event.preventDefault();
    event.stopImmediatePropagation();
  }
};

const handleClickOutside = (event: MouseEvent) => {
  const container = document.querySelector(`.swal2-container`);
  if (container && !container.contains(event.target as Node)) {
    Swal.close({
      isConfirmed: false,
      isDismissed: false,
      isDenied: false,
    });
  }
};

export const useSwalPopover = () => {
  const showPopover = useCallback(
    async (
      swalParams: SweetAlertOptions,
      anchor: HTMLElement = document.body,
    ): Promise<SweetAlertResult> => {
      const uniqueClassName = generateUniqueClassName();
      const returnFocusTo = document.activeElement as HTMLElement;
      const configure = () => {
        if (!anchor) return;
        const isAllowEscapeKey = swalParams.allowEscapeKey ?? true;
        const isAllowEnterKey = swalParams.allowEnterKey ?? true;
        const isAllowOutsideClick = swalParams.allowOutsideClick ?? true;

        const anchorPosition = anchor.getBoundingClientRect();
        const position: {
          top?: string;
          right?: string;
          bottom?: string;
          left?: string;
        } = {};

        switch (swalParams.position) {
          case "top-start":
            position.left = `${anchorPosition.left}px`;
            position.top = `${anchorPosition.top}px`;
            break;
          case "top-end":
            position.right = `${anchorPosition.right}px`;
            position.top = `${anchorPosition.top}px`;
            break;
          case "bottom-start":
            position.left = `${anchorPosition.left}px`;
            position.bottom = `${anchorPosition.bottom}px`;
            break;
          case "bottom-end":
            position.right = `${anchorPosition.right}px`;
            position.bottom = `${anchorPosition.bottom}px`;
            break;
          case "top":
            position.left = `${anchorPosition.left + anchorPosition.width / 2}px`;
            position.top = `${anchorPosition.top}px`;
            break;
          case "bottom":
            position.left = `${anchorPosition.left + anchorPosition.width / 2}px`;
            position.bottom = `${anchorPosition.bottom}px`;
            break;
          case "center":
          default:
            position.left = `${anchorPosition.left + anchorPosition.width / 2}px`;
            position.top = `${anchorPosition.top + anchorPosition.height / 2}px`;
            break;
        }

        const swalContainer = document.querySelector(
          `.${uniqueClassName}`,
        ) as HTMLElement;
        if (swalContainer) {
          swalContainer.style.position = "fixed";
          swalContainer.style.height = "auto";
          swalContainer.style.width = "fit-content";
          swalContainer.style.padding = "0.2em";
          Object.assign(swalContainer.style, position);
        }

        if (isAllowEscapeKey) {
          document.addEventListener("keydown", handleEscape);
        }
        if (isAllowEnterKey) {
          document.addEventListener("keydown", handleEnter);
        }

        if (isAllowOutsideClick) {
          document.addEventListener("mousedown", handleClickOutside);
        }

        const button = (swalContainer.querySelector(`button.swal2-confirm`) ??
          swalContainer.querySelector(`button.swal2-deny`) ??
          swalContainer.querySelector(
            `button.swal2-cancel`,
          )) as HTMLButtonElement;
        button?.focus();
      };

      const destroy = () => {
        document.removeEventListener("keydown", handleEscape);
        document.removeEventListener("keydown", handleEnter);
        document.removeEventListener("mousedown", handleClickOutside);
        returnFocusTo?.focus();
      };

      const result = await Swal.fire({
        toast: true,
        reverseButtons: true,
        ...swalParams,

        customClass: {
          ...(typeof swalParams.customClass === "string"
            ? null
            : swalParams.customClass),
          container: uniqueClassName,
        },
        didOpen: configure,
        willClose: destroy,
      });

      return result;
    },
    [],
  );
  return showPopover;
};
