import { useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";

import SearchLinkedInCompanyInputsIscraper from "@/components/Leads/FindCompanies/SearchLinkedInCompanyInputsIscraper";
import SearchLinkedInComapnyResultIscraper from "@/components/Leads/FindCompanies/SearchLinkedInComapnyResultIscraper";
import FindPeopleInputsApollo from "@/components/Leads/FindPeople/FindPeopleInputsApollo";
import FindPeopleResultApollo from "@/components/Leads/FindPeople/FindPeopleResultApollo";

import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";
import SearchLinkedInCompanyInputsV2 from "../FindCompanies/SearchLinkedInCompanyInputsV2";

type GenerateLeadsLayoutProps = {
  onCloseModal: (closeAll?: boolean) => void;
};

const GenerateLeadsLayout = ({ onCloseModal }: GenerateLeadsLayoutProps) => {
  const { modalData } = useModalStore((state) => state);
  const [currentSearchPayload, setCurrentSearchPayload] = useState<any>({});
  const [isSearching, setIsSearching] = useState(false);
  const currentUser = userStore((state) => state.currentUser);
  const isAdmin = currentUser?.role === "admin";

  const searchProviders = [
    {
      name: "linkedin-company-search",
      searchComponent: isAdmin
        ? SearchLinkedInCompanyInputsV2
        : SearchLinkedInCompanyInputsIscraper,
      resultComponent: SearchLinkedInComapnyResultIscraper,
    },
    {
      name: "apollo-people-search",
      searchComponent: FindPeopleInputsApollo,
      resultComponent: FindPeopleResultApollo,
    },
  ];

  const currentSearchProvider = searchProviders.find(
    (provider) => provider.name === modalData?.modalType,
  );

  return (
    <>
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(1, 27% 1fr)"
        gap={5}
        overflowY={"auto"}
        height={"calc(100% - 20px)"}
      >
        <GridItem className="">
          {currentSearchProvider?.searchComponent && (
            <currentSearchProvider.searchComponent
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              setCurrentSearchData={setCurrentSearchPayload}
              onCloseModal={onCloseModal}
            />
          )}
        </GridItem>
        <GridItem overflow="auto" className="relative flex flex-col">
          {currentSearchProvider?.resultComponent && (
            <currentSearchProvider.resultComponent
              isSearching={isSearching}
              currentSearchData={currentSearchPayload}
              onCloseModal={onCloseModal}
            />
          )}
        </GridItem>
      </Grid>
    </>
  );
};

export default GenerateLeadsLayout;
