import Icons from "@/components/Icons";
import StarterChecklist from "@/components/StarterChecklist";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";
import { Button, Flex } from "@chakra-ui/react";

const RightSection = () => {
  const updateState = useModalStore((state) => state.updateState);
  const subscriptionDetails = userStore((state) => state.subscriptionDetails);

  return (
    <Flex flexDirection="column" gap="4" w="full">
      <Button
        className=" flex items-center rounded-md !bg-primary-gradient  font-semibold !text-white "
        variant="secondary"
        leftIcon={<Icons.CrownIcon className="text-[#fff]" />}
        ml="auto"
        onClick={() => {
          updateState({
            isOpenModal: true,
            modalData: {
              modalType: "pricing",
              action: "change",
              openFrom: "dashboard",
              currentPlan: {
                ...subscriptionDetails?.currentPlan,
                amount: subscriptionDetails?.nextCycleAmount,
              },
            },
          });
        }}
      >
        Upgrade
      </Button>
      <StarterChecklist />
    </Flex>
  );
};

export default RightSection;
