import {
  ButtonGroup,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FilterModel } from "ag-grid-community";
import { Reorder } from "framer-motion";
import _ from "lodash";
import { useDeferredValue, useEffect, useState } from "react";
import Icons from "../Icons";
import { useGridFilter } from "./Table/hooks/useGridFilter";
import { useTableStore } from "./Table/hooks/useGridState.store";
import { userStore } from "@/stores/user.store";
type TFilterItem = { id: string; value: string; logo?: string };
interface IFilterSidebarProps {
  id: string;
  valuesSource: () => Promise<TFilterItem[] | undefined>;
  gridFields: string[];
  containerClassName?: string;
  isHideHeader?: boolean;
}
const useFilterSidebarState = ({
  id,
  valuesSource,
  gridFields: gridFieldsProp,
}: IFilterSidebarProps) => {
  const gridFields = useTableStore(() => gridFieldsProp);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState<TFilterItem[]>([]);
  const [sort, setSort] = useState<"asc" | "desc" | undefined>("asc");
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const totalRowCount = useTableStore((state) => state.totalRows);
  const query = useQuery<TFilterItem[] | undefined>({
    queryKey: ["filterValues", id], //TODO create a generic api to get filter data
    queryFn: valuesSource,
  });
  const searchDiffered = useDeferredValue(search);
  const { applyFilters: applyGridFilters, isFilterApplied } = useGridFilter();

  useEffect(() => {
    if (!isFilterApplied) {
      setSelected(new Set());
    }
  }, [isFilterApplied]);

  // apply filters to grid
  const applyFilters = (value?: string) => {
    if (typeof value === "undefined") {
      setSelected(new Set());
      if (isFilterApplied) {
        applyGridFilters(null);
      }
      return;
    }
    setSelected(new Set([value]));
    const filters: FilterModel = Object.fromEntries(
      gridFields.map((field) => [
        field,
        {
          filter: value,
          filterType: "text",
          type: "contains",
        },
      ]),
    );
    applyGridFilters(filters);
  };

  // sort, search
  useEffect(() => {
    if (query.data) {
      const filtered = _.chain(query.data)
        .uniqBy("value")
        .filter((lead) =>
          !search
            ? true
            : new RegExp(search.toLocaleLowerCase()).test(
                lead.value.toLocaleLowerCase(),
              ),
        )
        .orderBy(sortBy, sort)
        .value();

      setFilteredRows(filtered);
    }
  }, [query.data, searchDiffered, selected, sort]);
  return {
    query,
    filteredRows,
    setFilteredRows,
    search,
    setSearch,
    selected,
    toggleSelected: applyFilters,
    toggleSort: () => {
      const order = ["asc", "desc", undefined] as const;
      setSort(order[(order.indexOf(sort) + 1) % 3]);
    },
    reset: () => {
      applyFilters();
      setSearch("");
    },
    totalRowCount,
  };
  function sortBy(lead: TFilterItem) {
    if (!sort) return lead.id;
    return selected.has(lead.value) ? `000000${lead.value}` : lead.value;
  }
};
export const FilterSidebar = (props: IFilterSidebarProps) => {
  const {
    setSearch,
    search,
    filteredRows,
    setFilteredRows,
    query,
    selected,
    toggleSelected,
    toggleSort,
    reset,
  } = useFilterSidebarState(props);
  const isUpgradeBannerShown = userStore((state) =>
    state.isUpgradeBannerShown(),
  );

  return (
    <Stack
      className={`w-[250px] rounded-b-2xl border-[1px] !border-t-[0px] border-[#F0EFF1] px-[16px] pb-[32px] pt-[16px] ${props.containerClassName || ""}`}
      height={`calc(100vh - 130px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
      overflow={"clip"}
    >
      {!props.isHideHeader && (
        <Text
          className="mb-[10px] text-lg font-bold text-[#5F6B77]"
          fontSize={"16px"}
          fontWeight={500}
          color="#0F0D1C"
        >
          Companies
        </Text>
      )}
      <div className="searching">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icons.SearchIcon color="black.300" />
          </InputLeftElement>
          <Input
            size={"md"}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </InputGroup>
      </div>
      <Stack className="companies-list mt-1 pr-3">
        <div className="flex items-center gap-1 px-[1px]">
          <Text
            className="flex-1 whitespace-pre text-[#5F6B77]"
            fontSize={"14px"}
            fontWeight={400}
          >
            Warmest Companies
          </Text>
          <ButtonGroup size={"sm"}>
            <IconButton
              isLoading={query.isFetching}
              disabled={query.isFetching}
              variant={"ghost"}
              borderRadius={"50%"}
              aria-label="Sort Companies"
              title="Sort Companies"
              className="cursor-pointer"
              onClick={() => toggleSort()}
              icon={<Icons.SortIcon size={15} />}
            />
            {selected.size > 0 && (
              <IconButton
                variant={"ghost"}
                borderRadius={"50%"}
                aria-label="Clear Selection"
                title="Clear Companies"
                className="cursor-pointer"
                onClick={() => reset()}
                icon={<Icons.CloseIcon size={15} />}
              />
            )}
          </ButtonGroup>
        </div>
        <Stack
          className="custom-scrollbar flex !place-content-center !place-items-center overflow-y-auto"
          maxH={`calc(100vh - 300px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
          minH={`calc(100vh - 300px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
        >
          {query.isLoading && (
            <Stack className="p-[18px] px-[16px]">
              {Array.from({ length: 15 }).map((_, index) => (
                <Skeleton key={index} height="45px" />
              ))}
            </Stack>
          )}
          {query.data?.length === 0 ? (
            <Text
              className="whitespace-pre text-xs text-[#5F6B77] opacity-50"
              fontWeight={500}
              color="#5F6B77"
            >
              Add data to get started
            </Text>
          ) : (
            <Reorder.Group
              initial={{ width: "100%", height: "100%" }}
              as="div"
              values={filteredRows}
              onReorder={setFilteredRows}
              dragListener={false}
            >
              {!query.isLoading &&
                filteredRows?.map(({ value, logo }, index) => (
                  <Reorder.Item
                    draggable={false}
                    transition={{
                      type: "spring",
                      stiffness: 15200,
                      damping: 5000,
                    }}
                    as="div"
                    value={filteredRows[index]}
                    key={`company-list-${value}`}
                    className={`company !mb-1 flex cursor-pointer items-center gap-[10px] rounded-lg !p-[8px] hover:bg-faintGray ${
                      selected.has(value) ? "bg-primaryLightPink" : ""
                    }`}
                    onClick={() => {
                      toggleSelected(value);
                    }}
                  >
                    <img
                      src={
                        logo ||
                        "https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png"
                      }
                      alt=""
                      className="size-[20px] rounded-full"
                    />
                    <p className="text-[14px] font-normal text-dark">
                      {value || ""}
                    </p>
                  </Reorder.Item>
                ))}
            </Reorder.Group>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
