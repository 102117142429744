import APIKeyIntegration from "./APIKeyIntegration";

const PerplexityIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"perplexityApiKey"}
      providerTitle="Perplexity"
      providerIcon={
        "https://persana-assets.s3.us-west-1.amazonaws.com/perplexity.ai.png"
      }
    />
  );
};

export default PerplexityIntegration;
