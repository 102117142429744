/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import providerService from "@/services/providers.service";

export const useProviderList = () => {
  return useQuery({
    queryKey: ["providers"],
    queryFn: () => providerService.getProviderList(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
  });
};

export const useSmartLeadsCampaigns = () => {
  return useQuery({
    queryKey: ["smartLeadsCampaigns"],
    queryFn: () => providerService.getSmarLeadsCampaigns(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
  });
};

export const useInstantlyCampaigns = () => {
  return useQuery({
    queryKey: ["instantlyCampaigns"],
    queryFn: () => providerService.getInstantlyCampaigns(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
  });
};

export const useSendsparkCampaigns = (
  enabled: boolean,
  workspaceId: string,
) => {
  return useQuery({
    queryKey: ["sendsparkCampaigns"],
    queryFn: () => providerService.getSendsparkCampaigns(workspaceId),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    enabled,
  });
};

export const useSendsparkWorkspaces = (enabled: boolean) => {
  return useQuery({
    queryKey: ["sendsparkWorkspaces"],
    queryFn: () => providerService.getSendsparkWorkspaces(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    enabled,
  });
};

export const useHeyReachCampaigns = (enabled: boolean) => {
  return useQuery({
    queryKey: ["heyReachCampaigns"],
    queryFn: () => providerService.getHeyReachCampaigns(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    enabled,
  });
};

export const useHeyReachLinkedinAccounts = (enabled: boolean) => {
  return useQuery({
    queryKey: ["heyReachLinkedinAccounts"],
    queryFn: () => providerService.getHeyReachLinkedinAccounts(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    enabled,
  });
};

export const useLemListCampaigns = (enabled: boolean) => {
  return useQuery({
    queryKey: ["lemListCampaigns"],
    queryFn: () => providerService.getLemListCampaigns(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    enabled,
  });
};

export const useWoodpeckerCampaigns = (enabled: boolean) => {
  return useQuery({
    queryKey: ["woodpeckerCampaigns"],
    queryFn: () => providerService.getWoodpeckerCampaigns(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    enabled,
  });
};

export const useReplyIoCampaigns = (enabled: boolean) => {
  return useQuery({
    queryKey: ["replyIoCampaigns"],
    queryFn: () => providerService.getReplyIoCampaigns(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    enabled,
  });
};
