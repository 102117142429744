import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Text } from "@chakra-ui/react";
import { useModalStore } from "@/stores/modal.store";
import GoogleSheetIcon from "@/assets/svgs/google-sheet.svg";
import HubspotIcon from "@/assets/svgs/hubspot.svg";
import Icons from "@/components/Icons";
import { ModalType } from "@/types/modal.types";

interface ImportOptionProps {
  icon: string;
  title: string;
  description: string;
  onClick: () => void;
}

const ImportOption: React.FC<ImportOptionProps> = ({
  icon,
  title,
  description,
  onClick,
}) => (
  <div
    className="w-[350px] bg-[#F5F3FF] border border-[#F0EFF1] rounded-[8px] px-[24px] py-[16px] flex items-start gap-4 cursor-pointer hover:shadow-md"
    onClick={onClick}
  >
    <img src={icon} alt={`${title} icon`} />
    <div>
      <p className="text-[14px] text-[#373F51] font-[500]">{title}</p>
      <p className="text-[12px] text-[#4A5264]">{description}</p>
    </div>
  </div>
);

const ImportListModal: React.FC = () => {
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  const onClose = () => {
    updateState({
      isOpenModal: false,
      modalData: {
        modalType: "",
        metaData: {},
      },
    });
  };

  const openModal = (
    modalType: ModalType,
    metaData: Record<string, any> = {},
  ) => {
    updateState({
      isOpenModal: true,
      modalData: { modalType, metaData },
    });
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpenModal && modalData.modalType === "import-list-modal"}
      size={"3xl"}
      scrollBehavior="outside"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="pb-4 font-poppins">
        <ModalHeader className="border-b-[1px] border-b-dividerColor text-center">
          <div className="d-flex items-center">
            <Text className="flex-1">Import your list</Text>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="w-full flex flex-col items-center justify-center gap-4 mt-3">
            <div className="flex items-center gap-3">
              <ImportOption
                icon={GoogleSheetIcon}
                title="Import Leads in CSV"
                description="Upload, edit and convert Sheet."
                onClick={() =>
                  openModal("import-csv", { openPreviousModal: false })
                }
              />
              <ImportOption
                icon={HubspotIcon}
                title="Import Leads From Hubspot"
                description="Import and enrich Hubspot data."
                onClick={() =>
                  openModal("hubspot-contact-import", {
                    openPreviousModal: false,
                  })
                }
              />
            </div>
            <div className="flex items-center gap-3 w-full">
              <Button
                leftIcon={<Icons.PlusIcon />}
                className="flex-1 border !border-[#C03EDC] !text-[#C03EDC] hover:!bg-white hover:!shadow-md"
                variant="outline"
                onClick={() => openModal("get-started")}
              >
                See more
              </Button>
              {/* <Button
                leftIcon={<Icons.TemplateIcon />}
                className="flex-1 border !border-[#C03EDC] !text-[#C03EDC] hover:!bg-white hover:!shadow-md"
                variant="outline"
                onClick={() => openModal("use-template")}
              >
                Use Template
              </Button> */}
              <Button
                leftIcon={<Icons.MagicIcon />}
                className="flex-1 !text-white hover:shadow-md"
                variant="outline"
                style={{
                  background:
                    "linear-gradient(90deg, #8E32A4 0%, #5024DC 100%)",
                }}
                onClick={() =>
                  openModal("sales-gpt-modal", { showStarterScreen: false })
                }
              >
                Use SalesGPT
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImportListModal;
