/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import {
  Container,
  Input,
  Spinner,
  useToast,
  Button,
  TableContainer,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";

import Icons from "@/components/Icons";

import { useWhiteList } from "@/hooks/useWhiteList";
import userService from "@/services/user.service";

const customStyles = {
  headCells: {
    style: {
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold",
    },
  },
};

const WhiteList = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState("");
  const [searchText, setSearchText] = useState("");

  const { isLoading, data } = useWhiteList();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: { email: string; action: "create" | "delete" }) =>
      userService.updateWhitelistedUsers(payload.email, payload.action),
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    },
    onSuccess: async (response: any) => {
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: response.message,
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        await queryClient.refetchQueries({
          queryKey: ["whitelist"],
        });
      }
    },
  });

  const addEmail = async (allEmails: string[]) => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidEmail) {
      toast({
        title: "Invalid Email",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (allEmails.includes(email)) {
      toast({
        title: "Email already whitelisted",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    await mutateAsync({
      email,
      action: "create",
    });
    setEmail("");
  };

  const handleDelete = (email: string) => () => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      confirmButtonColor: "red",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await mutateAsync({
          email,
          action: "delete",
        });
      }
    });
  };

  const columns = [
    {
      name: "Email",
      selector: (row: string) => row,
    },
    {
      name: "Action",
      style: {
        display: "flex",
        justifyContent: "center",
      },
      selector: (row: string) => {
        return (
          <Button
            onClick={handleDelete(row)}
            isDisabled={isPending}
            isLoading={isPending}
          >
            <Icons.TrashIcon color="red" size={20} />
          </Button>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center">
        <Spinner size="xl" color="purple" />
      </div>
    );
  }

  if (!data?.success) {
    return (
      <div className="flex size-full items-center justify-center">
        <p className="text-2xl font-semibold text-red-400">{data?.message}</p>
      </div>
    );
  }

  const { data: whitelistedEmails = [] } = data;

  const filteredData = whitelistedEmails.filter((email) => {
    if (!searchText) return true;
    return email.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <Container className="mt-10" width="auto">
      <div className="add-email flex gap-3">
        <Input
          placeholder="Email to be whitelisted"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addEmail(whitelistedEmails);
            }
          }}
        />
        <Button
          colorScheme="purple"
          className="w-[200px]"
          isDisabled={!email || isPending}
          onClick={() => {
            addEmail(whitelistedEmails);
          }}
          isLoading={isPending}
        >
          Whitelist
        </Button>
      </div>

      <TableContainer className="mt-5">
        <div className="mb-1 flex items-center justify-center">
          <p className="text-center text-lg font-semibold">Whitelisted Data</p>
        </div>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icons.SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </InputGroup>

        <DataTable
          // @ts-ignore
          columns={columns}
          data={filteredData}
          customStyles={customStyles}
          pagination
        />
      </TableContainer>
    </Container>
  );
};

export default WhiteList;
