import { FC } from "react";
import {
  Avatar,
  HStack,
  Text,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import {
  TemplateGroup,
  TemplateGroups,
} from "../Leads/UseTemplateModal/constants";
import Icons from "../Icons";

type PlaybookTagFilterProps = {
  selectedGroup: TemplateGroup | null;
  setSelectedGroup: (group: TemplateGroup | null) => void;
};

export const PlaybookTagFilter: FC<PlaybookTagFilterProps> = ({
  selectedGroup,
  setSelectedGroup,
}) => {
  const handleTagClick = (group: TemplateGroup) => {
    const newGroup = selectedGroup === group ? null : group; // Toggle group selection
    setSelectedGroup(newGroup);
  };

  return (
    <HStack
      spacing="4"
      wrap="wrap"
      className="select-none"
      placeContent={"center"}
    >
      <Text className="flex flex-row place-content-center gap-2">
        <Icons.BsFilter className="size-5" />
      </Text>
      {Object.values(TemplateGroups).map((group, index) => (
        <Tag
          key={index}
          rounded={"full"}
          size="lg"
          border="solid 1px gray"
          _hover={{
            border: "solid 1px #bf3fdc",
            // color: selectedGroup === group ? undefined : "black",
          }}
          boxShadow={"none"}
          variant={selectedGroup === group ? "solid" : "outline"}
          cursor="pointer"
          className={` ${selectedGroup === group ? "!bg-primary-gradient" : ""}`}
          onClick={() => handleTagClick(group)}
        >
          <Avatar
            color={selectedGroup === group ? "white" : "gray"}
            icon={<group.icon className="size-4" />}
            size="xs"
            bg="transparent"
          />
          <TagLabel>{group.name}</TagLabel>
          {selectedGroup === group && <TagCloseButton />}
        </Tag>
      ))}
    </HStack>
  );
};
