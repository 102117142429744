import { useQuery } from "@tanstack/react-query";

import teamService from "@/services/team.service";

export const useTeamData = () => {
  return useQuery({
    queryKey: ["team-details"],
    queryFn: async () => teamService.getDetails(),
    gcTime: 1000 * 60 * 60 * 24, // 1 day
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};