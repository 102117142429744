import APIKeyIntegration from "./APIKeyIntegration";

const ReplyIoIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"replyIoApiKey"}
      providerTitle="Reply.io"
      providerIcon={
        "https://persana-assets.s3.us-west-1.amazonaws.com/reply-io.png"
      }
    />
  );
};

export default ReplyIoIntegration;
