import { useEffect, useState } from "react";
import { IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import moment from "moment";

import enrichment from "@/services/enrichment.service";
import tableService from "@/services/table.service";
import { BiStopCircle } from "react-icons/bi";
import { RunningProcessesData } from "@/types/table.types";

interface Props {
  tableId: string;
  processingId: string;
  processData?: RunningProcessesData;
}

const CancelProcess = ({ tableId, processingId, processData }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [showTooltip, setShowTooltip] = useState(false);

  const { mutateAsync } = useMutation({
    mutationFn: ({ processingId }: { processingId: string }) =>
      tableService.deleteRunningProcess({ processingId, tableId }),
  });

  const updatedAt = processData?.updatedAt;

  const isOldProcess = moment(updatedAt).isBefore(
    moment().subtract(3, "minutes"),
  );

  const handleCancelJob = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, cancel it!",
      showLoaderOnConfirm: true,
      cancelButtonText: "No, keep it",
      preConfirm: async () => {
        return await mutateAsync(
          { processingId },
          {
            onSuccess: async (response) => {
              if (response.success == false) {
                toast({
                  title: "Something went wrong",
                  description: response.error.message,
                  status: "error",
                  duration: 4000,
                  isClosable: true,
                  position: "top-right",
                });
                return;
              }

              queryClient.refetchQueries({
                queryKey: ["table", tableId],
              });

              queryClient.refetchQueries({
                queryKey: ["table-running-processes", tableId],
              });

              await enrichment.callCronJobAPI();

              toast({
                title: "Process cancelled successfully",
                status: "success",
                duration: 1000,
                isClosable: true,
                position: "top-right",
              });
            },
            onError: (error) => {
              toast({
                title: "Something went wrong",
                description: error.message,
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            },
          },
        );
      },
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOldProcess) {
      setShowTooltip(true);
      timer = setTimeout(() => {
        setShowTooltip(false);
      }, 20000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isOldProcess]);

  return (
    <Tooltip
      hasArrow
      isOpen={showTooltip}
      label={
        <p className="text-md z-50 text-center font-bold">
          {`Looks like your process is timeout out, click here to stop and then rerun for incomplete rows`}
        </p>
      }
      closeOnEsc
      closeOnClick
      padding={"12px 6px"}
      borderRadius={"6px"}
    >
      <IconButton
        size={"sm"}
        className="!rounded-full"
        variant={"ghost"}
        colorScheme="red"
        aria-label="Cancel Process"
        onClick={handleCancelJob}
      >
        <BiStopCircle />
      </IconButton>
    </Tooltip>
  );
};

export default CancelProcess;
