import { FileRoute } from "@tanstack/react-router";

import QuickStartGuide from "@/pages/QuickStartGuide";

export const Route = new FileRoute("/quick-start-guide/").createRoute({
  component: QuickStartGuidePage,
});

function QuickStartGuidePage() {
  return <QuickStartGuide />;
}

export default QuickStartGuidePage;
