import { useEffect } from "react";
import { useNavigate } from "@tanstack/react-router";

const RedirectPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({
      to: "/",
    });
  }, []);

  return <div>Redirecting....</div>;
};

export default RedirectPage;
