import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

import { createANewColumnName } from "@/components/Table/utils";
import ActionButtons from "./ActionButtons";
import { ResponseDropdownPropsTypes } from "./ResponseDropdown";

function NormalResponseButton({
  itemKey,
  value,
  handleCopyToClipboard,
  path,
  handleAddNewColumn,
}: Partial<ResponseDropdownPropsTypes>) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const [newColumnName, setNewColumnName] = useState(
    createANewColumnName(itemKey as string),
  );

  if (value == null) return null;

  return (
    <div
      className="relative w-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered || isOpen ? (
        <ActionButtons
          handleCopyToClipboard={
            handleCopyToClipboard as (value: string) => void
          }
          value={value}
          handleAddNewColumn={handleAddNewColumn as (value: {}) => void}
          path={path || ""}
          itemKey={itemKey as string}
          isOpen={isOpen}
          onToggle={onToggle}
          onClose={onClose}
          newColumnName={newColumnName}
          setNewColumnName={setNewColumnName}
        />
      ) : null}
      <div
        className={`flex w-fit items-start gap-x-1.5 rounded-md border px-4 py-1.5 ${
          isHovered ? "border-primary bg-[#8ebff73f]" : "bg-white"
        }`}
      >
        <span className="block w-fit font-semibold">{itemKey}</span>:{" "}
        <span className="text-left">
          {typeof value === "boolean" ? value?.toString() : value}
        </span>
      </div>
    </div>
  );
}

export default NormalResponseButton;
