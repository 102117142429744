import { Button, Radio, RadioGroup, Stack, Text, Box } from "@chakra-ui/react";
import { useEnrichStore } from "@/stores/enrich.store";
import { ProviderNameType, useProviderStore } from "@/stores/provider.store";
import { useEffect } from "react";

const KeySelect = ({
  providerTitle,
  providerName,
  disablePersanaKey = false,
  secretKeyInput = false,
}: {
  providerTitle: string;
  providerName: ProviderNameType;
  disablePersanaKey?: boolean;
  secretKeyInput?: boolean;
}) => {
  const keySelect = useEnrichStore((state) => state.keySelect);
  const updateState = useEnrichStore((state) => state.updateState);
  const updateProviderState = useProviderStore((state) => state.updateState);

  useEffect(() => {
    if (disablePersanaKey) {
      updateState({
        keySelect: "ownApiKey",
      });
    }
  }, [disablePersanaKey]);

  return (
    <Box p={4} borderWidth={1} borderRadius="md" boxShadow="md" mb={3}>
      <Text fontSize="md" fontWeight="medium">
        {`Choose ${providerTitle} API Key Account`}
      </Text>
      {disablePersanaKey && (
        <Text fontSize="xs" color="gray.500" mb={3}>
          Note: Please use your own API key
        </Text>
      )}
      <RadioGroup
        onChange={(newValue: "persanaApiKey" | "ownApiKey") => {
          updateState({
            keySelect: newValue,
          });
        }}
        value={keySelect}
      >
        <Stack spacing={4} direction="row">
          {!disablePersanaKey && (
            <Radio
              size="md"
              name="persanaApiKey"
              value="persanaApiKey"
              colorScheme="primary"
              isDisabled={disablePersanaKey}
            >
              Use Persana's API Key
            </Radio>
          )}
          <Radio
            size="sm"
            name="ownApiKey"
            value="ownApiKey"
            colorScheme="primary"
          >
            Use your own API key
          </Radio>
        </Stack>
      </RadioGroup>

      {keySelect === "ownApiKey" && (
        <Button
          mt={3}
          variant={"outline"}
          colorScheme="primary"
          onClick={() => {
            updateProviderState({
              isProviderApiKeyModalOpen: true,
              providerApiKeyModalData: {
                title: providerTitle,
                providerName: providerName,
                secretKeyInput,
              },
            });
          }}
          size="sm"
        >
          Add/Change API Key
        </Button>
      )}
    </Box>
  );
};

export default KeySelect;
