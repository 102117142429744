import { DotLottiePlayer } from "@dotlottie/react-player";

interface Props {
  src: string;
  autoplay?: boolean;
  loop?: boolean;
  style?: React.CSSProperties;
}

const LottiePlayer = ({ src, autoplay = true, loop = true , style = {} }: Props) => {
  return (
    <div>
      <DotLottiePlayer src={src} autoplay={autoplay} loop={loop} style={style} />
    </div>
  );
};

export default LottiePlayer;
