import { FileRoute } from "@tanstack/react-router";

import PAdmin from "@/pages/PAdmin";

export const Route = new FileRoute("/padmin/").createRoute({
  component: PAdminPage,
});

function PAdminPage() {
  return <PAdmin />;
}

export default PAdminPage;
