import React from "react";

import SelectableTextEditor from "../../Common/SelectableTextEditor";

interface Props {
  dynamicText: string;
  setDynamicText: React.Dispatch<React.SetStateAction<string>>;
}

const DynamicFieldsTab = ({ dynamicText, setDynamicText }: Props) => {
  return (
    <div>
      <SelectableTextEditor
        setText={setDynamicText}
        text={dynamicText}
        title="Write Your Dynamic input"
      />
    </div>
  );
};

export default DynamicFieldsTab;
