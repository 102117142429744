import HubspotLogo from "@/assets/images/hubsport.png";
import APIKeyIntegration from "./APIKeyIntegration";

const HubspotIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"hubspotApiKey"}
      providerTitle="Hubspot"
      providerIcon={HubspotLogo}
    />
  );
};

export default HubspotIntegration;
