import { FilterModel, GridApi } from "ag-grid-community";
import _ from "lodash";
import { useMemo, useRef } from "react";
import { useTableStore } from "./useGridState.store";

export const useGridFilter = () => {
  const gridApiRef = useRef<GridApi | null>(null);
  const appliedFilterRef = useRef<FilterModel | null>(null);
  const filtersRef = useRef<FilterModel | null>(null);
  const gridApi = useTableStore((state) => state.gridApi);
  const appliedFilters = useTableStore(
    (state) => state.gridApi?.getState().filter?.filterModel || null,
  );
  gridApiRef.current = gridApi;
  appliedFilterRef.current = appliedFilters;
  const isFilterActive = useMemo(
    () =>
      filtersRef.current !== null &&
      _.isEqual(appliedFilters, filtersRef.current),
    [appliedFilters],
  );
  function applyFilters(filters: FilterModel | null) {
    filtersRef.current = filters;
    if (!gridApiRef.current || _.isEqual(appliedFilterRef.current, filters))
      return;
    gridApiRef.current?.setFilterModel(filters);
    const context = gridApiRef.current.getGridOption("context");
    gridApiRef.current?.setGridOption("context", {
      ...context,
      useCache: false,
    });
    gridApiRef.current?.onFilterChanged();
  }
  return {
    isFilterApplied: isFilterActive,
    applyFilters,
  };
};
