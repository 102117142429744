import TeamOverviewComponent from "@/components/TeamOverview";

const TeamOverview = () => {
  return (
    <div className="mt-5 ml-2">
      <TeamOverviewComponent />
    </div>
  );
};

export default TeamOverview;
