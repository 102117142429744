function LoadingSpin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: 'auto',
        background: 'none',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width="30px"
      height="30px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <defs>
        <radialGradient
          id="ldio-tgx2r6ltqd-gradient"
          cx="0.5"
          cy="0.5"
          fx="0"
          fy="0"
          r="2"
        >
          <stop offset="0%" stopColor="#0084ff"></stop>
          <stop offset="100%" stopColor="#ffffff"></stop>
        </radialGradient>
      </defs>
      <g>
        <circle
          cx="50"
          cy="50"
          r="30"
          stroke="#ffffff"
          strokeWidth="12"
          fill="none"
          strokeOpacity="0.7"
        ></circle>
        <circle
          cx="50"
          cy="50"
          r="30"
          stroke="url(#ldio-tgx2r6ltqd-gradient)"
          strokeWidth="10"
          fill="none"
        ></circle>
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          repeatCount="indefinite"
        ></animateTransform>
      </g>
    </svg>
  );
}

export default LoadingSpin;
