import React from "react";

import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import { TSelectedColumnOption } from "@/types/common.types";

interface Props {
  selectedUsernameOption: TSelectedColumnOption | null;
  setSelectedUsernameOption: React.Dispatch<
    React.SetStateAction<TSelectedColumnOption | null>
  >;
  selectedLinkedinOption: TSelectedColumnOption | null;
  setSelectedLinkedinOption: React.Dispatch<
    React.SetStateAction<TSelectedColumnOption | null>
  >;
}

const Username = ({
  selectedUsernameOption,
  setSelectedUsernameOption,
  selectedLinkedinOption,
  setSelectedLinkedinOption,
}: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <p className="pb-1 text-base font-semibold">
          Select the Username (Optional)
        </p>
        <SelectColumnDropdown
          selectedOption={selectedUsernameOption}
          setSelectedOption={setSelectedUsernameOption}
        />
      </div>

      <div>
        <p className="pb-1 text-base font-semibold">
          Select the Linkedin URL (Optional)
        </p>
        <SelectColumnDropdown
          selectedOption={selectedLinkedinOption}
          setSelectedOption={setSelectedLinkedinOption}
        />
      </div>
    </div>
  );
};

export default Username;
