import { useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";

import { TSelectedColumnOption } from "@/types/common.types";
import { REGEX, scrapeWebsitesTutorialLink } from "../../constants";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomAccordion from "@/components/Common/CustomAccordion";

export const ZeroBounceEnrichment = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    } else {
      const initialColumn = getInitialColumn(REGEX.EMAIL);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Validate Email Address with ZeroBounce"
          link={scrapeWebsitesTutorialLink}
        />
        <CustomAccordion title="Email Address" isDefaultOpen>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </CustomAccordion>

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
        }}
      />
    </>
  );
};
