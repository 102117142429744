import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

const UrlDetailsRenderer = ({
  cellDetails,
  jobName,
}: {
  cellDetails: any;
  jobName?: string;
}) => {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 gap-0">
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  className="text-md font-medium text-[#677084]"
                >
                  {jobName === "JOB_OPENINGS"
                    ? "Show Job Opening Details"
                    : jobName === "COMPANY_TECHNOLOGIES"
                      ? "Show Technology Details"
                      : jobName === "GOOGLE_SEARCH"
                        ? "Show Google Search Results"
                        : "Show News Details"}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {cellDetails?.metaData?.responseValue?.map(
                (item: any, index: number) => (
                  <div
                    className="border-gray-300 grid grid-cols-1 gap-0 border"
                    key={index}
                  >
                    <div className="border-gray-300 border-r">
                      <a
                        href={item.url || item.link || "#"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p className="my-2 ml-1 px-3 text-[16px] font-medium text-primary">
                          {item.title}
                        </p>
                      </a>
                    </div>
                  </div>
                ),
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default UrlDetailsRenderer;
