import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import KeySelect from "@/components/Common/KeySelect";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import PersanaAccordion from "@/components/Common/PersanaAccordion";
import WatchTutorial from "@/components/Common/WatchTutorial";
import Icons from "@/components/Icons";
import SaveAndRun from "../../Common/SaveAndRun";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import providerService from "@/services/providers.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { allLeadOptions, FieldName } from "./constants";

type Payload = {
  audienceName?: string;
} & {
  [key in FieldName]: TSelectedColumnOption | null;
};

interface EnrichmentDataType extends EnrichmentMetaData {
  payload: Payload;
}

const CreateLeadLGM = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const {
    data: audiencesData = [],
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["lgmAudiences"],
    queryFn: async () => {
      const response = await providerService.getLgmAudiences();
      return response;
    },
    refetchOnMount: true,
  });

  const [selectedAudience, setSelectedAudience] = useState<Option | null>(null);
  const [leadOptions, setLeadOptions] = useState(() => {
    return allLeadOptions.reduce(
      (prevValue, currentValue) => {
        return {
          ...prevValue,
          [currentValue.fieldName]: null,
        };
      },
      {} as {
        [key in FieldName]: TSelectedColumnOption | null;
      },
    );
  });

  const isDisabled = () => {
    const {
      proEmail,
      persoEmail,
      linkedinUrl,
      twitter,
      firstname,
      lastname,
      companyUrl,
      companyName,
    } = leadOptions;

    // we need to specify at least:  proEmail or persoEmail or linkedinUrl or twitter or Firstname + Lastname + (CompanyUrl or CompanyName)
    if (
      (proEmail ||
        persoEmail ||
        linkedinUrl ||
        twitter ||
        (firstname && lastname && (companyUrl || companyName))) &&
      selectedAudience
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        const { payload } = enrichmentData;
        const audienceName = payload.audienceName;

        if (audienceName) {
          setSelectedAudience({
            value: audienceName,
            label: audienceName,
          });
        }

        setLeadOptions((prevValue) => ({
          ...prevValue,
          ...payload,
        }));
      }
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="overflow-y-auto p-4">
        <WatchTutorial subText="Add lead to  La Growth Machine" link={""} />

        <KeySelect
          providerName={"laGrowthMachineKey"}
          providerTitle="LaGrowthMachine"
          disablePersanaKey={true}
        />

        {/* Select or Create Audience */}
        <div className="mb-5 w-full">
          <div className="mb-1 flex items-center justify-between pb-0.5">
            <div className="flex items-center gap-2">
              <p className="text-base font-semibold">Audience</p>
              <Icons.RefreshIcon
                size={19}
                className={`cursor-pointer ${isLoading || isFetching ? "animate-spin" : ""}`}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
          <MultiSelectDropdown
            isMulti={false}
            placeholder="Create or Choose Audience"
            closeMenuOnSelect
            options={audiencesData}
            value={selectedAudience}
            onChange={(selectedOption) =>
              setSelectedAudience(selectedOption as Option)
            }
          />
        </div>

        {/* Lead Options */}
        <PersanaAccordion
          defaultIndex={[0]}
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
          }}
          items={[
            {
              button: (
                <div className="w-full text-left font-semibold text-black">
                  <p className="truncate"> More Details (*optional)</p>
                </div>
              ),
              panel: (
                <div className="space-y-3">
                  <span className="text-[12px] !leading-[5px] text-darkTextGray">
                    <strong>Note</strong>: You need to specify at least:{" "}
                    <strong>Professional Email</strong> or{" "}
                    <strong>Personal Email</strong> or{" "}
                    <strong>LinkedIn URL</strong> or <strong>Twitter</strong> or{" "}
                    <strong>
                      First Name + Last Name + (Company Url or Company Name)
                    </strong>
                  </span>
                  {allLeadOptions.map((option) => (
                    <div key={option.fieldName}>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {option.label}
                      </p>
                      <SelectColumnDropdown
                        selectedOption={leadOptions[option.fieldName]}
                        setSelectedOption={(
                          options: TSelectedColumnOption | null,
                        ) => {
                          setLeadOptions((prevValue) => ({
                            ...prevValue,
                            [option.fieldName]: options,
                          }));
                        }}
                        notInportal
                      />
                    </div>
                  ))}
                </div>
              ),
            },
          ]}
        />

        <ConditionalFormula />
      </div>

      <SaveAndRun
        apiPayload={{
          isUseOwnKey: true,
          apiKeyName: "laGrowthMachineKey",
          payload: {
            audienceName: selectedAudience?.value,
            ...leadOptions,
          },
        }}
        isDisabled={isDisabled()}
      />
    </>
  );
};

export default CreateLeadLGM;
