import { Descendant } from "slate";
import { Button } from "@chakra-ui/react";

import Icons from "@/components/Icons";
import Description from "./Description";
import CustomTextEditor from "./CustomTextEditor";
import { initialSlateValue } from "../FilterModals/HttpApi/consts";

interface Props {
  keyName?: string;
  title: string;
  description?: string;
  moreFields: any[];
  setMoreFileds: (fields: any[]) => void;
  handleFieldChange: (
    id: string,
    name: string,
    value: Descendant[] | string,
  ) => void;
  showAddMore?: boolean;
  showRemove?: boolean;
}

function AddMoreBtn({
  keyName,
  title,
  description,
  moreFields,
  setMoreFileds,
  handleFieldChange,
  showAddMore = true,
  showRemove = true,
}: Props) {
  const handleRemoveCustomField = (name: string): void => {
    const newFields = moreFields?.filter(
      (field: any) => field.name !== name,
    ) as any[];
    setMoreFileds(newFields);
  };

  const handleAddCustomField = (): void => {
    const newField = {
      id: `${keyName}_${moreFields.length + 1}`,
      name: `${keyName} ${moreFields.length + 1}`,
      value: initialSlateValue,
    };

    const newFields = [...moreFields, newField];
    setMoreFileds(newFields);
  };

  return (
    <div>
      {moreFields?.map((field: any) => (
        <div
          className="mt-3 grid gap-[8px]"
          style={{
            gridTemplateColumns: "repeat(1 ,30% 1fr 10%)",
          }}
        >
          <div>
            <p className="text-base font-semibold pb-1">key</p>
            <input
              value={field?.name || ""}
              name={"name"}
              className="font-title border duration-300 focus:border-primary border-lightGray rounded px-2 py-[5px] text-base w-full"
              onChange={(e: any) =>
                handleFieldChange(field.id, e.target.name, e.target.value)
              }
            />
          </div>
          <div>
            <p className="text-base font-semibold pb-1">value</p>
            <CustomTextEditor
              editorHeight={"fit-content"}
              isInline={true}
              editorClassName="!px-2 !py-[4.5px]"
              slateValue={field.value}
              setSlateValue={(val) => handleFieldChange(field.id, "value", val)}
              showDetails={false}
              placeholder="Enter value..."
              maxWidth="16rem"
            />
          </div>
          <div>
            <div className="h-7" />
            {showRemove ? (
              <Button
                className="mb-1 w-full"
                onClick={() => {
                  handleRemoveCustomField(field.name);
                }}
                size={"sm"}
              >
                X
              </Button>
            ) : null}
          </div>
        </div>
      ))}
      {showAddMore ? (
        <div className="mt-5">
          <Button size={"sm"} onClick={handleAddCustomField}>
            <Icons.AddIcon className="text-xl mr-1" /> {title}
          </Button>
          <Description className="pt-2">
            {description ? description : "Add more items"}
          </Description>
        </div>
      ) : null}
    </div>
  );
}

export default AddMoreBtn;
