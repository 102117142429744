import { userStore } from "@/stores/user.store";
import ListSection from "./ListSection";
import { Button } from "@chakra-ui/react";
import { useModalStore } from "@/stores/modal.store";
import Icons from "../Icons";
import { useTableStore } from "@/stores/table.store";

const StarterChecklist = () => {
  const selectedWorkSpace = useTableStore((state) => state.selectedWorkSpace);
  const currentUser = userStore((state) => state.currentUser);
  return (
    <div className="border border-[#D0D4DC] rounded-[12px] bg-white h-fit w-full py-[24px]">
      <div className="flex flex-col gap-[24px]">
        <div className="flex flex-col gap-[8px] px-[24px]">
          <p className="text-[20px] leading-[24px] font-medium text-[#0F0D1C]">
            {selectedWorkSpace
              ? selectedWorkSpace.name
              : `${currentUser?.profileInfo?.firstName || "User"}'s Workspace`}
          </p>
          <p className="text-sm text-[#4A5264] leading-[20px]">
            Follow these simple steps to get started in minutes.
          </p>
        </div>
        <ListSection />
        <Button
          leftIcon={<Icons.GiftIcon className="text-[#5024DC] w-[20] h-[20]" />}
          className="rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#000] !w-[95%] m-auto"
          onClick={() => {
            useModalStore.getState().updateState({
              isOpenModal: true,
              modalData: {
                modalType: "referral",
              },
            });
          }}
          variant="secondary"
        >
          Refer to earn free credits
        </Button>
      </div>
    </div>
  );
};

export default StarterChecklist;
