import { MenuProps, components } from "react-select";

const Menu = (props: MenuProps) => {
  return (
    <>
      <components.Menu {...props}>
        {props.children}
      </components.Menu>
    </>
  );
};

export default Menu;
