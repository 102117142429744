import { Tooltip } from "@chakra-ui/react";

export const CompanyDescriptionRenderer = (props: any) => {
  return (
    <div className="py-2">
      <Tooltip label={props?.data?.description} hasArrow placement="auto">
        <span className="text-[14px] font-[400]">
          {`${props?.data?.description.substring(0, 300)}...`}
        </span>
      </Tooltip>
    </div>
  );
};
