import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import LeadCSVUpload from "@/components/JobChanges/LeadCSVUpload";
import CompanyCSVUpload from "@/components/HiringIntent/CompanyCSVUpload";
import QuickAddModal from "@/components/Leads/QuickAddModal";
import ImportDataIcon from "@/assets/svgs/download.svg";
import { useModalStore } from "@/stores/modal.store";

type Props = {
  isOpen: boolean;
  loader: boolean;
  loading: boolean;
  quickAddInput: string;
  placeholder: string;
  csvType: "Lead" | "Company";
  setIsOpen: (val: boolean) => void;
  setQuickAddInput: (val: string) => void;
  handleAdd: () => void;
  fetchPaginationData: () => void;
  handleHubspotImport: () => void;
  handleQuickAdd: () => void;
};

const AccountMonitoringImportModal = ({
  isOpen,
  loader,
  quickAddInput,
  loading,
  placeholder,
  csvType,
  setIsOpen,
  setQuickAddInput,
  fetchPaginationData,
  handleAdd,
  handleHubspotImport,
  handleQuickAdd,
}: Props) => {
  const { isOpenModal, modalData } = useModalStore((state) => state);
  return (
    <>
      <Modal
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        isCentered
        size={"xl"}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent>
          <ModalHeader>
            <p className="w-full text-center text-2xl">Import Data</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="m-auto">
            <div className="flex items-center justify-center gap-5 pb-8 pt-2">
              <Button
                isLoading={loading}
                onClick={() => handleQuickAdd()}
                leftIcon={<img src={ImportDataIcon} alt="import-data" />}
                variant="solid"
                h={"38px"}
                fontSize={14}
                className="!bg-primary-gradient hover:opacity-80"
                colorScheme="primary"
                borderRadius={"6px"}
              >
                <div>Quick Add</div>
              </Button>

              <Button
                isLoading={loading}
                onClick={() => handleHubspotImport()}
                leftIcon={<img src={ImportDataIcon} alt="import-data" />}
                variant="solid"
                h={"38px"}
                fontSize={14}
                className="!bg-primary-gradient hover:opacity-80"
                colorScheme="primary"
                borderRadius={"6px"}
              >
                <div>Hubspot Import</div>
              </Button>

              {csvType === "Lead" ? (
                <LeadCSVUpload
                  onClose={() => setIsOpen(false)}
                  fetchPaginationData={fetchPaginationData}
                />
              ) : (
                <CompanyCSVUpload fetchPaginationData={fetchPaginationData} />
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isOpenModal && modalData.modalType === "quick-add" && (
        <QuickAddModal
          loader={loader}
          quickAddInput={quickAddInput}
          placeholder={placeholder}
          setQuickAddInput={setQuickAddInput}
          handleAdd={handleAdd}
        />
      )}
    </>
  );
};

export default AccountMonitoringImportModal;
