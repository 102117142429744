import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useModalStore } from "@/stores/modal.store";
import PersanaMagic from "@/components/PersanaMagic";
import {
  TabContext,
  createSearchLeadsStore,
} from "@/components/PeopleSearch/store";
import { useNavigate } from "@tanstack/react-router";

const SalesGPTModal = () => {
  const navigate = useNavigate();
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  const handleGPTSearch = () => {
    handleModalClose();
    navigate({ to: "/search" });
  };

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "sales-gpt-modal"}
      motionPreset="scale"
      size="5xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.4)" />
      <ModalContent className="rounded-[20px] p-[20px]">
        <ModalCloseButton onClick={handleModalClose} />
        <ModalBody overflowY="auto" maxH="90vh">
          <TabContext.Provider value={createSearchLeadsStore("salesGPT_1")}>
            <PersanaMagic
              callback={handleGPTSearch}
              showStarterScreen={modalData?.metaData?.showStarterScreen}
            />
          </TabContext.Provider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SalesGPTModal;
