import { OptionProps, components } from "react-select";
import { TProviderOption } from "../types";

export const Option = ({
  children,
  ...props
}: OptionProps<TProviderOption>) => (
  <components.Option {...props}>
    <div className="flex w-full flex-row place-items-center gap-2">
      {props.data?.icon ? (
        <img
          src={props.data?.icon}
          alt="2"
          className="mr-2 size-[20px] rounded-full"
        />
      ) : null}
      {children}
    </div>
  </components.Option>
);
