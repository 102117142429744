import userService from "@/services/user.service";
import { UserData } from "@/types/user.types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useUserConfig = (configKey: string | null = null) => {
  const query = useQuery({
    queryKey: ["user-details", "config"],
    queryFn: () =>
      userService.getUser(["config"]) as Promise<Pick<UserData, "config">>,
    staleTime: 1000 * 60 * 60,
  });
  const mutation = useMutation({
    mutationFn: async (config: any) => {
      if (!configKey) return null;
      return userService.updateUserConfig(configKey, config);
    },
    onSuccess: () => {
      query.refetch();
    },
  });
  return [query, mutation] as const;
};
