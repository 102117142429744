import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import GithubLogo from "@/assets/images/github.png";
import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";

const GithubIntegration = () => {
  const toast = useToast();
  const { data: providerList, refetch } = useProviderList();
  const githubProvider = providerList?.data?.find(
    (provider) => provider.name === "github",
  );

  const handleIntegration = async () => {
    providerService.openGithubOAuth();
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      providerService.deleteProvider({ providerId: githubProvider?._id || "" }),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        refetch();
        toast({
          title: response?.data?.message || "Disconnected successfully",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow dark:!bg-navy-700">
      <div className="mb-4 flex items-center gap-4">
        <img src={GithubLogo} alt={"GitHub"} className="w-[2rem]" />
        <h2 className="font-semibold">Github</h2>
      </div>
      <div>
        <p className="mb-4">{`Connect your Github Account`}</p>
        <Button
          colorScheme={githubProvider ? "red" : "green"}
          variant="outline"
          size="sm"
          isLoading={isPending}
          onClick={() => {
            if (githubProvider) {
              mutateAsync();
            } else {
              handleIntegration();
            }
          }}
        >
          {githubProvider ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default GithubIntegration;
