import { Divider } from "@chakra-ui/react";

import { CellDetails } from "@/types/table.types";
import TextRenderer from "./TextRenderer";

const AIResultRenderer = ({ value }: { value: CellDetails }) => {
  const cellDetails = value.cellDetails;

  console.log("myLog value", value);
  const inputValue =
    value.metaData?.enrichmentMetaData?.prompts || cellDetails?.metaData?.input;

  if (!value.value) return null;

  return (
    <div className="container mx-auto p-4">
      <div className="border-gray-300 grid grid-cols-2 gap-0 rounded border">
        <div className="border-gray-300 border-r">
          <h2 className="px-4 py-2 text-lg font-bold">Input</h2>
          <Divider />
          <p className="text-md px-4 py-2 text-[#677084]">{inputValue}</p>
        </div>
        <div className="border-gray-300 border-l">
          <h2 className="px-4 py-2 text-lg font-bold">Answer</h2>
          <Divider />
          <p className="text-md px-4 py-2 text-[#677084]">{value.value}</p>
        </div>
      </div>

      {!!cellDetails?.metaData?.responseValue?.confidenceScore && (
        <div className="mt-5 flex items-center gap-6">
          <div className="w-[30%]">
            <p className="rounded-md bg-[#F8F0FF] px-2 py-1 text-[14px] font-bold text-textPurple">
              Confidence Score
            </p>
          </div>
          <TextRenderer
            value={cellDetails.metaData.responseValue.confidenceScore || ""}
          />
        </div>
      )}
    </div>
  );
};

export default AIResultRenderer;
