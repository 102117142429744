import { useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTable } from "@/components/DataTable/Table/hooks/useTable";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { useModalStore } from "@/stores/modal.store";

export const useImportModelProps = () => {
  const [url, setUrl] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading] = useState(false);
  const { refresh } = useTable();
  const toast = useToast();
  const updateState = useModalStore((state) => state.updateState);
  const { mutateAsync: handleAdd, isPending: quickAddPending } = useMutation({
    mutationFn: (url: string) =>
      accountMonitoringService.quickAdd(url, "job-tracking"),
    onSuccess: (_, url) => {
      onClose();
      refresh(true, { linkedinURL: url, firstName: "" });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Failed to Add Lead",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
  return {
    loader: quickAddPending,
    loading,
    isOpen,
    quickAddInput: url,
    csvType: "Lead" as const,
    placeholder: "Enter Linkedin URL",
    handleAdd: () => handleAdd(url),
    handleHubspotImport: () => {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: "job-tracking",
          metaData: {
            callback: () => {
              refresh(true);
              onClose();
            },
          },
        },
      });
    },
    handleQuickAdd: () => {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: "quick-add",
          metaData: {
            callback: () => {
              refresh(true);
              onClose();
            },
          },
        },
      });
    },
    fetchPaginationData: () => refresh(true),
    setIsOpen: (val: boolean) => {
      if (!val) {
        onClose();
      } else {
        onOpen();
      }
    },
    setQuickAddInput: setUrl,
  };
};
