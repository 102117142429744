import { Avatar, Divider } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import Icons from "@/components/Icons";
import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";
import SidebarLinks from "./Links";

import PersanaLogo from "@/assets/images/logo.png";
import { getCurrentDateInYYYYMM } from "@/lib/utils";
import userService from "@/services/user.service";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";
import FreeTrial from "./FreeTrial";
import { planLabelMap } from "./constants";

const Sidebar = () => {
  const navigate = useNavigate();
  const updateState = useModalStore((state) => state.updateState);
  const creditDetails = userStore((state) => state.creditDetails);
  const currentUser = userStore((state) => state.currentUser);
  const subscriptionDetails = userStore((state) => state.subscriptionDetails);

  const handleLogout = async () => {
    localStorage.removeItem("persana-alradyShowedIntro");
    navigate({ to: "/login" });
    await userService.logout();
    window.location.reload();
  };

  const buttons = [
    {
      name: "Upgrade",
      icon: <Icons.CrownIcon className="size-5" />,
      onClick: () =>
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "pricing",
            action: "change",
            openFrom: "dashboard",
            currentPlan: {
              ...subscriptionDetails?.currentPlan,
              amount: subscriptionDetails?.nextCycleAmount,
            },
          },
        }),
    },
    {
      name: "Book Onboarding Call",
      icon: <Icons.PhoneIcon className="size-5" />,
      onClick: () => {
        window.open(
          `https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`,
          "_blank",
        );
      },
    },

    {
      name: "Logout",
      icon: <Icons.LogoutIcon className="size-5" />,
      onClick: handleLogout,
    },
  ];

  return (
    <div className="flex h-full">
      <div className="!duration-1000">
        <div
          className={`
            sidebar
            group fixed
            !z-[1000]
            flex
            max-h-full min-h-full w-[4em] flex-col overflow-y-auto bg-[#ffffff2e] pb-3 shadow-white/5 duration-100 hover:!w-[17rem] hover:bg-[#F9FAFB] hover:!shadow-xl hover:transition-[width] hover:duration-[400ms] dark:text-white`}
          style={{
            scrollbarWidth: "none",
          }}
        >
          <span className="absolute right-4 top-4 block cursor-pointer text-white xl:opacity-0 dark:text-white">
            <Icons.HiXIcon />
          </span>
          <div className="">
            <div className="flex items-center justify-center pb-4">
              <img
                src={PersanaLogo}
                className="pointer-events-none my-[10px] h-auto w-[30px]"
              />
            </div>
          </div>

          <ul
            className="mb-auto w-full "
            style={{
              scrollbarColor: "transparent transparent",
            }}
          >
            <SidebarLinks />
          </ul>
          <div
            // className={!isMouseLeave && isHovering ? "visible" : "invisible"}
            className="invisible group-hover:visible"
          >
            <Divider className="my-2" />
            <div className="px-5">
              <FreeTrial />
            </div>
          </div>
          <Divider className="my-2" />
          <div className="sidebar w-full">
            <div className={`invisible w-full px-5 pb-5 group-hover:visible`}>
              <div className="flex items-center justify-between space-x-10 pb-2 font-poppins">
                <div className="flex items-center gap-x-2">
                  <span className="font-medium text-[#475467]">
                    {planLabelMap[creditDetails?.planType] || "Free Plan"}
                  </span>
                </div>
                <p className="flex items-center gap-x-1 text-[#344054]">
                  <span className="font-medium text-[#AAB2BA]">
                    {creditDetails?.creditUsed || 0}
                  </span>{" "}
                  <span className="font-semibold text-[#344054]">/</span>
                  <span className="font-semibold text-[#344054]">
                    {creditDetails?.creditLeft || 0}
                  </span>
                  {/* <span className="text-[#90969c]"> credits left:</span>
                    <span className="font-semibold">
                      {creditLeft || 0}
                    </span> */}
                </p>
              </div>

              {creditDetails?.creditUsed != null &&
              creditDetails?.creditLeft != null ? (
                <div className="h-3 w-full rounded-full bg-[#E4E9ED]">
                  <div
                    className="h-3 rounded-full bg-[#0284FE]"
                    style={{
                      width: `${
                        ((creditDetails?.creditUsed > creditDetails?.creditLeft
                          ? creditDetails?.creditLeft
                          : creditDetails?.creditUsed) /
                          creditDetails?.creditLeft) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              ) : null}
            </div>

            {buttons.map((button, index) => (
              <div
                key={index}
                onClick={button.onClick}
                className="w-full cursor-pointer  py-[5px] transition-all"
              >
                <button className="relative w-full py-1 duration-300 hover:cursor-pointer hover:bg-[#dce6f15d]">
                  <div className="flex cursor-pointer items-center px-1">
                    <span
                      className={`flex min-w-12 items-center justify-center text-xs font-medium text-[#000000]/70`}
                    >
                      {button.icon}
                    </span>
                    <span
                      className={`hidden whitespace-nowrap font-medium leading-none text-[#000]/80 group-hover:block `}
                    >
                      {button.name}
                    </span>
                  </div>
                </button>
              </div>
            ))}
            <div className="pl-[8px]">
              <button className="mt-3 flex cursor-pointer items-center justify-center gap-x-2 px-1">
                <Avatar
                  bg="#dce6f15d"
                  color="#1C1C1C"
                  size={"sm"}
                  name={`${currentUser?.profileInfo?.firstName ?? ""} ${currentUser?.profileInfo?.lastName ?? ""}`.trim()}
                />
                <div className="invisible flex flex-col items-start text-[#1C1C1C] group-hover:visible">
                  <span className="truncate text-sm">
                    {currentUser?.profileInfo?.firstName}{" "}
                    {currentUser?.profileInfo?.lastName}
                  </span>
                  <span className="truncate text-[10px] ">
                    {currentUser?.Email}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedSidebar = () => (
  <CustomErrorBoundary>
    <Sidebar />
  </CustomErrorBoundary>
);

export default WrappedSidebar;
