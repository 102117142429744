import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import ProcessingBar from "@/components/Common/ProcessingBar";
import Icons from "@/components/Icons";
import CancelProcess from "./CancelProcess";

import { filterComponentMaps } from "@/components/Enrichment/FilterModals/mappings";
import { useProcessingStore } from "@/stores/processing.store";
import { RunningProcessesData } from "@/types/table.types";
import { processingTypeLabelMap } from "./constants";

interface Props {
  processesData: RunningProcessesData[];
  types: string;
}

// TODO: Use this component in main table as wel
const RunningProcesses = ({ types }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const processesData = useProcessingStore((state) => state.processingData);

  if (!processesData?.length) return null;

  const enrichmentProcesses = (processesData.filter(
    (item) => item.processingType === types,
  ) || []) as RunningProcessesData[];

  const _otherProcesses = processesData.filter(
    (item) => item.processingType !== types,
  );

  const allProcesses = [...enrichmentProcesses, ..._otherProcesses].filter(
    (item) => item._id,
  ) as RunningProcessesData[];

  if (!allProcesses.length) return null;

  const firstProcess = allProcesses[0];

  const processedPercentage =
    ((firstProcess?.metaData?.processedData || 0) /
      (firstProcess?.metaData?.totalDataToProcess || 0)) *
      100 || 0;

  const otherProcesses = allProcesses.slice(1);

  const getEnrichmentName = (process: RunningProcessesData) => {
    const enrichmentLabel = (process?.metaData?.payload?.bodyData
      ?.enrichmentName || "") as keyof typeof filterComponentMaps;

    return (
      filterComponentMaps[enrichmentLabel]?.title ||
      processingTypeLabelMap[process?.processingType] ||
      process?.processingType ||
      enrichmentLabel
    );
  };

  return (
    <>
      <div className="my-5">
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-2">
            <p className="flex-1">{getEnrichmentName(firstProcess)}</p>
            <div
              className={`max-w-[400px] ${firstProcess?.metaData?.totalDataToProcess ? "w-[200px]" : "w-[100px]"}`}
            >
              <ProcessingBar
                processedPercentage={Math.round(processedPercentage)}
                processedRows={firstProcess?.metaData?.processedData || 0}
                totalRows={firstProcess?.metaData?.totalDataToProcess || 0}
                isQueued={firstProcess.isQueued}
              />
            </div>
          </div>
          <CancelProcess processingId={firstProcess._id} types={types} />
          <div>
            {!!otherProcesses.length && (
              <div
                className="btn-primary-outline flex cursor-pointer items-center gap-1 rounded-sm px-[5px] py-[3px]"
                onClick={onOpen}
              >
                <span className="text-xs font-semibold">
                  {otherProcesses.length} more processes
                </span>
                <Icons.MoreDoubleIcon />
              </div>
            )}
          </div>
        </div>
        <p className="w-full text-left text-sm font-light text-darkTextGray">
          {firstProcess.isQueued
            ? "Queued"
            : firstProcess.processingMessage || ""}
        </p>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody className="mt-4">
            {otherProcesses.map((process) => (
              <div key={process._id} className="flex items-center gap-3">
                <div>
                  <p className="flex-1">{getEnrichmentName(process)}</p>
                  <p className="w-full text-left text-sm font-light text-darkTextGray">
                    {process.isQueued
                      ? "Queued"
                      : process.processingMessage || ""}
                  </p>
                </div>

                <div
                  className={`max-w-[400px] ${firstProcess?.metaData?.totalDataToProcess ? "w-[200px]" : "w-[100px]"}`}
                >
                  <ProcessingBar
                    processedPercentage={
                      (process.metaData?.processedData /
                        process.metaData?.totalDataToProcess) *
                      100
                    }
                    processedRows={process.metaData?.processedData}
                    totalRows={process.metaData?.totalDataToProcess}
                    isQueued={process.isQueued}
                  />
                </div>
                <CancelProcess types={types} processingId={process._id} />
              </div>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RunningProcesses;
