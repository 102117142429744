import { DefaultElement, ReactEditor, RenderElementProps } from "slate-react";

import InlineVoid from "./InlineVoid";

interface Props extends RenderElementProps {
  editor: ReactEditor;
  isCodeView?: boolean;
}

const Element = (props: Props) => {
  const { element, children } = props;

  //   @ts-ignore
  if (element.type === "inlineVoid") {
    // @ts-ignore
    return <InlineVoid {...props} buttonText={element.children[0]?.text} isCodeView={props.isCodeView} />;
  }

  return <DefaultElement {...props}>{children}</DefaultElement>;
};

export default Element;
