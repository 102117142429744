import React, { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Box,
  ButtonGroup,
  IconButton,
  Spinner,
  useToast,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import useBulkDeleteApiKeys from "./hooks/useBulkDeleteApiKeys";
import useApiKeys from "./hooks/useApiKeys";
import { ColDef, GridApi } from "ag-grid-community";
import { ApiKey } from "@/types/apiKey.types";
import Icons from "../Icons";
import CreateApiKeyModal from "./CreateApiKeyModal";
import { userStore } from "@/stores/user.store";

// Extend dayjs with relativeTime plugin
dayjs.extend(relativeTime);

interface ApiKeysGridProps {
  user?: {
    _id: string;
    Email: string;
    creditLeft: number;
    creditUsed: number;
    planType: string;
    profileInfo: {
      companyURL: string;
      firstName: string;
      lastName: string;
      companyName: string;
      companyDescription: string;
      callToAction: string;
      companyTarget: string;
    };
    totalCreditUsed: number;
  };
}

const ApiKeysGrid: React.FC<ApiKeysGridProps> = (props) => {
  const toast = useToast();
  const currentUser = userStore()
    .currentUser as unknown as ApiKeysGridProps["user"];
  const user = props.user ?? currentUser;
  const {
    data: apiKeys,
    isLoading,
    isFetching,
    refetch: refetchApiKeys,
  } = useApiKeys(user?._id);
  const bulkDeleteApiKeysMutation = useBulkDeleteApiKeys();

  const [gridApi, setGridApi] = useState<GridApi<ApiKey> | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const handleCopy = (apiKey: string) => {
    navigator.clipboard.writeText(apiKey);
    toast({
      title: "Copied",
      description: "API key copied to clipboard",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const columnDefs = useMemo(
    () =>
      [
        {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          pinned: true,
          maxWidth: 50,
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: true,

          cellRenderer: (params: any) => (
            <div className="flex flex-row items-center truncate">
              <span className="ml-2 truncate">{params.value}</span>
            </div>
          ),
        },
        {
          headerName: "API Key",
          field: "apiKey",
          sortable: true,
          filter: true,
          cellRenderer: (params: any) => (
            <>
              <IconButton
                className="mr-2"
                size="xs"
                colorScheme="purple"
                variant="outline"
                icon={<Icons.CopyIcon />}
                onClick={() => handleCopy(params.value)}
                aria-label={"Copy API Key"}
              />
              <span className="text-[#6c757dbb]">
                {params.value?.slice(0, 5) +
                  "*".repeat(params.value?.length - 5)}
              </span>
            </>
          ),
        },
        {
          headerName: "Active",
          field: "isActive",
          sortable: true,
          filter: true,
          cellRenderer: (params: any) => (params.value ? "Yes" : "No"),
        },
        {
          headerName: "Created At",
          field: "createdAt",
          sortable: true,
          filter: true,
          cellRenderer: (params: any) => (
            <Tooltip label={new Date(params.value).toLocaleString()}>
              {dayjs(params.value).fromNow()}
            </Tooltip>
          ),
        },
        {
          headerName: "Last Used",
          field: "lastUsedAt",
          sortable: true,
          filter: true,
          cellRenderer: (params: any) =>
            params.value ? (
              <Tooltip label={new Date(params.value).toLocaleString()}>
                {dayjs(params.value).fromNow()}
              </Tooltip>
            ) : (
              <Text className="text-[#6c757dbb]">never</Text>
            ),
        },
      ] as ColDef<ApiKey>[],
    [],
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    const selectedRows = gridApi?.getSelectedRows() ?? [];
    const selectedApiKeys = selectedRows.map((row) => row._id);
    setSelectedKeys(selectedApiKeys);
  };

  const handleBulkDelete = () => {
    if (selectedKeys.length === 0) {
      toast({
        title: "No keys selected",
        status: "error",
        duration: 2000,
      });
      return;
    }

    bulkDeleteApiKeysMutation.mutate(
      { ids: selectedKeys },
      {
        onSuccess: () => {
          toast({
            title: "Selected API keys deleted",
            status: "success",
            duration: 2000,
          });
          gridApi?.deselectAll();
          setSelectedKeys([]);
        },
        onError: () => {
          toast({
            title: "Error deleting API keys",
            status: "error",
            duration: 2000,
          });
        },
      },
    );
  };
  useEffect(() => {
    if (isFetching) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
    if (!apiKeys?.data?.length) {
      gridApi?.showNoRowsOverlay();
    }
  }, [isFetching, gridApi, apiKeys?.data ?? null]);
  if (!user) return <Text>No user selected</Text>;
  return (
    <Box className="ag-theme-quartz" style={{ height: "400px", width: "100%" }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box className="flex flex-row justify-end">
            <ButtonGroup size="sm" className="my-2 !ml-auto">
              <CreateApiKeyModal user={user} />
              <IconButton
                onClick={() => refetchApiKeys()}
                isLoading={isFetching}
                isDisabled={isFetching}
                variant={"outline"}
                colorScheme="purple"
                aria-label={"Reload"}
                icon={<Icons.RefreshIcon />}
              />
              <IconButton
                aria-label={"Delete"}
                icon={<Icons.TrashIcon />}
                colorScheme="red"
                onClick={handleBulkDelete}
                isDisabled={selectedKeys.length === 0}
              />
            </ButtonGroup>
          </Box>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={apiKeys?.data ?? []}
            rowSelection="multiple"
            suppressRowClickSelection
            domLayout="autoHeight"
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
          />
        </>
      )}
    </Box>
  );
};

export default ApiKeysGrid;
