import { Card, CardBody, Checkbox, Text } from "@chakra-ui/react";

import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { useTableStore } from "@/stores/table.store";

import {
  FileColumnToTableColumnMapping,
  HandleColumnMappingParams,
} from "./types";

interface Props {
  fileColumns: string[];
  fileColumnToTableColumnMapping: FileColumnToTableColumnMapping;
  handleColumnMapping: (params: HandleColumnMappingParams) => void;
  handleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ColumnSelection = ({
  fileColumns,
  fileColumnToTableColumnMapping,
  handleColumnMapping,
  handleSelectAll,
}: Props) => {
  const _tableColumns = useTableStore((state) => state.tableData.columns);
  const tableColumns = _tableColumns.map((column) => column.name);

  return (
    <div className="custom-scrollbar mb-2 max-h-[400px] w-full overflow-y-auto">
      <p className="w-full text-center font-bold underline">
        Select Columns To Add
      </p>
      <div className="flex">
        <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
      </div>
      <div className="gap-2">
        {fileColumns.map((column) => {
          const isColumnSelected =
            !!fileColumnToTableColumnMapping[column]?.isChecked;

          return (
            <div
              key={`${column} ${isColumnSelected}`}
              className="mb-1 w-full max-w-full"
            >
              <Card className="!p-2">
                <CardBody className="flex max-w-full items-center justify-between !p-[4px]">
                  <Checkbox
                    className="mr-2"
                    checked={isColumnSelected}
                    defaultChecked={isColumnSelected}
                    onChange={(e) => {
                      handleColumnMapping({
                        columnName: column,
                        isChecked: e.target.checked,
                        tableColumnName:
                          fileColumnToTableColumnMapping[column]
                            ?.tableColumnName || null,
                      });
                    }}
                    size="md"
                  />
                  <Text className="min-w-[200px] flex-1">{column}</Text>
                  <div className="w-full max-w-[304px]">
                    <MultiSelectDropdown
                      options={[...tableColumns, ...fileColumns].map(
                        (tableColumn) => ({
                          value: tableColumn,
                          label: tableColumn,
                        }),
                      )}
                      value={
                        fileColumnToTableColumnMapping[column]
                          ?.tableColumnName || null
                      }
                      isMulti={false}
                      isCreateAble
                      placeholder="Select Column or Create New"
                      closeMenuOnSelect
                      onChange={(selected) => {
                        handleColumnMapping({
                          columnName: column,
                          tableColumnName: selected,
                          isChecked: true,
                        });
                      }}
                      isDisabled={!isColumnSelected}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ColumnSelection;
