import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";

import { conditions } from "./constants";
import type { Condition } from "@/types/table.types";
import { FilterType } from "@/types/common.types";

interface Props {
  filter: FilterType;
  filters: FilterType[];
  updateFilter: (filter: FilterType[]) => void;
  conditionOptions?: FilterType["condition"][];
}

const Conditions = ({
  filter,
  filters,
  conditionOptions,
  updateFilter,
}: Props) => {
  const hanleClick = (condition: Condition) => {
    const updatedFilters = filters.map((f) => {
      if (f.id === filter.id) {
        return {
          ...f,
          condition: condition,
        };
      }
      return f;
    });

    updateFilter(updatedFilters);
  };

  return (
    <Popover placement="right-start">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <button className="flex w-full grow items-center justify-between gap-1 rounded bg-[#e7e8ec] px-2 py-1 text-sm font-medium text-[#000000]/90 duration-300 hover:bg-[#ECEEF1]">
              <span className="truncate">{filter.condition.name}</span>
              <Icons.ArrowDownIcon
                className={`text-sm text-[#000000] duration-300 flex-shrink-0 ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </button>
          </PopoverTrigger>
          <PopoverContent width="fit-content">
            <PopoverArrow />
            <PopoverBody className="flex w-fit max-w-[300px] flex-col">
              {(conditionOptions || conditions).map((option, index) => (
                <button
                  key={`${option.name}-${filter.selectedField._id}-${index}`}
                  className={`truncate rounded-md px-2 py-1.5 text-start text-[13.75px] text-[#000000]/90 duration-300 hover:bg-[#e7e8ec] ${filter.condition.name === option.name ? "bg-[#e7e8ec]" : ""}`}
                  onClick={() => {
                    hanleClick(option);
                    onClose();
                  }}
                >
                  {option.name}
                </button>
              ))}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default Conditions;
