/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";
import WatchTutorial from "@/components/Common/WatchTutorial";
import CustomAccordion from "@/components/Common/CustomAccordion";
import { TSelectedColumnOption } from "@/types/common.types";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import { Box, Flex, Switch, Text } from "@chakra-ui/react";
import { InsightColumns, RESPONSE_COLUMNS } from "./constants";
import { tableActionIcons } from "@/components/Icons/tableActionIcons";

const FindLinkedInDetailsWithPersana = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const [payload, setPayload] = useState<TSelectedColumnOption | null>(null);
  const [extraColumns, setExtraColumns] = useState<
    Record<string, string | undefined>
  >({});
  const isValid = useMemo(() => {
    return !!payload;
  }, [payload]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;
      if (enrichmentData?.payload) {
        setPayload(enrichmentData?.payload);
      }
      if (enrichmentData?.extraColumns) {
        const extraColumnsObject = enrichmentData?.extraColumns?.reduce(
          (acc, item) => {
            let index = RESPONSE_COLUMNS.findIndex(
              (column) => column.label === item,
            );
            if (index === -1) {
              index = InsightColumns.findIndex(
                (column) => column.label === item,
              );
              if (index !== -1) {
                index = RESPONSE_COLUMNS.length + index;
              }
            }

            if (index !== -1) {
              acc[index] = item;
            }
            return acc;
          },
          {} as typeof extraColumns,
        );
        setExtraColumns(extraColumnsObject);
      }
    }
  }, [viewMode, selectedColumnToEdit]);
  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={
            "Watch the tutorial to learn how to get Linkedin Profiles with Persana ."
          }
          link={""}
          credits={1}
        />

        <CustomAccordion
          description="*Required"
          title="LinkedIn URL"
          isDefaultOpen={true}
          isOpen={true}
        >
          <SelectColumnDropdown
            selectedOption={payload}
            setSelectedOption={(payload: TSelectedColumnOption | null) =>
              setPayload(payload)
            }
          />
        </CustomAccordion>
        <CustomAccordion title="Add Data as Columns" isOpen={true}>
          <Flex
            pointerEvents={viewMode === "edit" ? "none" : "auto"}
            className="!flex-col items-stretch gap-2"
          >
            {RESPONSE_COLUMNS.map((item, index) => (
              <Box
                key={index}
                className="flex w-full cursor-pointer select-none flex-row justify-between rounded pl-2 pr-4 hover:bg-[#F5F3FF]"
                py={1}
                onChange={() => {
                  setExtraColumns((state) => ({
                    ...state,
                    [index]: state[index] ? undefined : item.label,
                  }));
                }}
                onClick={() => {
                  setExtraColumns((state) => ({
                    ...state,
                    [index]: state[index] ? undefined : item.label,
                  }));
                }}
              >
                <Text className="flex items-center gap-2 text-[16px] font-[500] text-[#373F51]">
                  <tableActionIcons.text className="h-[16px]" />
                  {item.label}
                </Text>
                <Switch
                  isDisabled={viewMode === "edit"}
                  isChecked={!!extraColumns[index]}
                  variant={"sm"}
                  colorScheme="purple"
                />
              </Box>
            ))}
            <Text className="mt-2 text-[16px] font-[600] text-[#373F51]">
              AI Personal Insights
            </Text>
            {InsightColumns.map((item, index) => (
              <Box
                key={RESPONSE_COLUMNS.length + index}
                className="flex w-full cursor-pointer select-none flex-row justify-between rounded pl-2 pr-4 hover:bg-[#F5F3FF]"
                py={1}
                onClick={() => {
                  setExtraColumns((state) => ({
                    ...state,
                    [RESPONSE_COLUMNS.length + index]: state[
                      RESPONSE_COLUMNS.length + index
                    ]
                      ? undefined
                      : item.label,
                  }));
                }}
                onChange={() => {
                  setExtraColumns((state) => ({
                    ...state,
                    [RESPONSE_COLUMNS.length + index]: state[
                      RESPONSE_COLUMNS.length + index
                    ]
                      ? undefined
                      : item.label,
                  }));
                }}
              >
                <Text className="flex items-center gap-2 text-[16px] font-[500] text-[#373F51]">
                  <tableActionIcons.text className="h-[16px]" />
                  {item.label}
                </Text>
                <Switch
                  isDisabled={viewMode === "edit"}
                  isChecked={!!extraColumns[RESPONSE_COLUMNS.length + index]}
                  variant={"sm"}
                  colorScheme="purple"
                />
              </Box>
            ))}
          </Flex>
        </CustomAccordion>

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!isValid}
        apiPayload={{
          payload,
          extraColumns: [...Object.values(extraColumns)].filter(
            (item) => !!item,
          ),
        }}
      />
    </>
  );
};

export default FindLinkedInDetailsWithPersana;
