import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import Webhook from "../Webhook";
import Icons from "@/components/Icons";
import ImportFromSalesforce from "../ImportFromSalesforce";

const LeadsModal = () => {
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);

  const handleClose = (byPassPreviousModal?: boolean) => {
    if (modalData?.metaData?.openPreviousModal && !byPassPreviousModal) {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: modalData?.metaData?.previousModalType,
          metaData: "",
        },
      });
      return;
    }

    updateState({
      isOpenModal: false,
      modalData: { modalType: "" },
    });
  };

  return (
    <Modal
      isCentered
      isOpen={true}
      motionPreset="scale"
      size="2xl"
      onClose={() => {
        handleClose();
      }}
      scrollBehavior="inside"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.5)" />
      <ModalContent className="rounded-[20px] font-poppins">
        <div className="w-full flex justify-center py-3 font-title font-semibold relative border-b border-borderGray">
          <p className="text-xl text-center">
            {modalData.metaData?.modalTitle || "Modal Title"}
          </p>
          <Icons.CloseIcon
            className="absolute right-4 top-1/2 translate-y-[-50%] cursor-pointer text-[1.3rem]"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        {modalData.modalType === "webhook" && <Webhook onClose={handleClose} />}
        {modalData?.modalType === "salesforce" && (
          <ImportFromSalesforce onClose={handleClose} />
        )}
      </ModalContent>
    </Modal>
  );
};

export default LeadsModal;
