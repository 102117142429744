export const RESPONSE_COLUMNS = [
  {
    isChecked: false,
    label: "Name",
    id: "fullName",
  },
  {
    isChecked: false,
    label: "Title",
    id: "title",
  },
  {
    isChecked: false,
    label: "LinkedIn URL",
    id: "linkedinUrl",
  },
  {
    isChecked: false,
    label: "Industry",
    id: "industry",
  },
  {
    isChecked: false,
    label: "Country",
    id: "country",
  },
];

export const jobTitleFiltersOptions = [
  {
    name: "Comprehensive Search",
    value: "comprehensiveSearch",
    description:
      "Offers a broader range of results with the possibility of some irrelevant information.",
  },
  {
    name: "Precision Search",
    value: "precisionSearch",
    description: "Highly accurate results with strict filtering.",
  },
] as const;

export type JobTitleilterTypes =
  (typeof jobTitleFiltersOptions)[number]["value"];
