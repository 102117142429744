const ProgressBar = ({
  progress,
  stepsCompleted,
  totalSteps,
}: {
  progress: number;
  stepsCompleted: number;
  totalSteps: number;
}) => {
  return (
    <div className="">
      <div className="w-full bg-[#F5F3FF] rounded-[8px] h-[6px] mb-[8px]">
        <div
          className="bg-[#5024DC] h-[6px] rounded-[8px]"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-sm text-[#5024DC]">
          {progress.toFixed(0)} % Completed
        </p>
        <p className="text-sm text-[#0F0D1C]">
          {stepsCompleted} of {totalSteps} steps done
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
