import TextRenderer from "./TextRenderer";

const PersonDetails = ({ cellDetails }: { cellDetails: any }) => {
  const personDetails = cellDetails?.metaData?.responseValue || {};

  return (
    <div className="py-2 px-4 mt-4">
      {/* Name Section */}
      <a href={personDetails?.linkedin_url} target="_blank">
        <div className="flex items-center gap-[10px] cursor-pointer">
          <img
            src={personDetails?.profile_picture}
            alt=""
            width={40}
            height={40}
          />
          <div className="flex flex-col">
            <p className="text-[20px] font-bold text-[#677084]">
              {personDetails.first_name} {personDetails.last_name}
            </p>
            <p className="text-[14px] font-medium text-[#677084]">
              {personDetails?.sub_title}
            </p>
          </div>
        </div>
      </a>

      {/* Details Setion */}
      <p className="text-[16px] text-darkGray my-4">
        {personDetails?.summary || ""}
      </p>

      <div className="grid grid-cols-2 gap-2">
        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Industry
            </p>
          </div>
          <TextRenderer value={personDetails?.industry || ""} />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Languages
            </p>
          </div>
          <TextRenderer
            value={
              personDetails?.languages?.profile_languages
                ?.map((item: any) => item.name)
                ?.join(",") || ""
            }
          />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Followers
            </p>
          </div>
          <TextRenderer
            value={personDetails?.network_info?.followers_count || ""}
          />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Profile Type
            </p>
          </div>
          <TextRenderer value={personDetails?.profile_type || ""} />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Location
            </p>
          </div>
          <TextRenderer
            value={
              `${personDetails?.location?.state || ""}, ${personDetails?.location?.country || ""}` ||
              ""
            }
          />
        </div>

        <div className="flex items-center gap-6">
          <div className="w-[30%]">
            <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
              Joined Since
            </p>
          </div>
          <TextRenderer value={personDetails?.time_since_role_started || ""} />
        </div>
      </div>
    </div>
  );
};

export default PersonDetails;
