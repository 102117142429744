import APIKeyIntegration from "./APIKeyIntegration";
import PeopleDataLabsLogo from "@/assets/svgs/people_data_labs.png";

const PeopleDataLabsIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"peopleDataLabsApiKey"}
      providerTitle="PeopleDataLabs"
      providerIcon={PeopleDataLabsLogo}
    />
  );
};

export default PeopleDataLabsIntegration;
