import APIKeyIntegration from "./APIKeyIntegration";
import ApolloLogo from "@/assets/images/apolloio.png";

const ApolloIoIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"apolloio"}
      providerTitle="Apollo"
      providerIcon={ApolloLogo}
    />
  );
};

export default ApolloIoIntegration;
