import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";

import Sidebar from "./Sidebar";
import MainContent from "./MainContent";
import { useEnrichStore } from "@/stores/enrich.store";

const EnrichModal = () => {
  const isOpenEnrichModal = useEnrichStore((state) => state.isOpenEnrichModal);
  const updateState = useEnrichStore((state) => state.updateState);

  return (
    <Modal
      isCentered
      onClose={() => {
        updateState({
          isOpenEnrichModal: false,
        });
      }}
      isOpen={isOpenEnrichModal}
      motionPreset="scale"
      size="6xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

      <ModalContent className="rounded-[20px] h-[680px]">
        <div
          className="h-[18px]"
          style={{
            background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) ",
            boxShadow: "0px 6px 58px 0px rgba(121, 145, 173, 0.20)",
            borderRadius: "6px 6px 0 0",
          }}
        />
        <ModalBody className="font-primary relative inline-block transform overflow-hidden text-left align-bottom transition-all sm:my-8 sm:w-full sm:max-w-[70rem] sm:align-middle h-full w-full bg-white !p-0 !m-0">
          <div className="flex justify-between">
            <Sidebar />
            <MainContent />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EnrichModal;
