import CompanyDetails from "./CompanyDetails";

const CompanyDetailsRenderer = ({ details }: { details: any }) => {
  return (
    <>
      {details.cellDetails?.metaData && (
        <div className="bg-white shadow-lg rounded-lg p-4 mb-4">
          <div className="mb-4">
            <CompanyDetails cellDetails={details.cellDetails} />
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyDetailsRenderer;
