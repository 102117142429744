/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";
import WatchTutorial from "@/components/Common/WatchTutorial";
import CustomAccordion from "@/components/Common/CustomAccordion";
import { Box, Flex, Switch, Text } from "@chakra-ui/react";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import { TSelectedColumnOption } from "@/types/common.types";
import { tableActionIcons } from "@/components/Icons/tableActionIcons";
import { RESPONSE_COLUMNS } from "./constants";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";

const detailsPreferences = [
  { label: "Company Name", value: "name" },
  { label: "Company LinkedIn URL", value: "linkedinUrl" },
  { label: "Company Domain", value: "domain" },
];
const FindCompanyDetailsWithPersana = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const [companyDetailsPreference, setCompanyDetailsPreference] =
    useState<Option | null>(null);
  const [name, setName] = useState<TSelectedColumnOption | null>(null);
  const [linkedInUrl, setLinkedInUrl] = useState<TSelectedColumnOption | null>(
    null,
  );
  const [domain, setDomain] = useState<TSelectedColumnOption | null>(null);
  const [extraColumns, setExtraColumns] = useState<
    Record<string, string | undefined>
  >({});
  const payload = useMemo(() => {
    const payload = {
      payload: domain,
      queryType: "domain",
    };
    switch (companyDetailsPreference?.value) {
      case "name":
        return {
          payload: name,
          queryType: "name",
        };
      case "linkedinUrl":
        return {
          payload: linkedInUrl,
          queryType: "linkedinUrl",
        };
      case "domain":
        return {
          payload: domain,
          queryType: "domain",
        };
      default:
        return payload;
    }
  }, [name, linkedInUrl, domain, companyDetailsPreference]);
  const isValid = useMemo(() => {
    return !!payload.payload;
  }, [payload]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        switch (enrichmentData.queryType) {
          case "name":
            setCompanyDetailsPreference(detailsPreferences[0]);
            setName(enrichmentData.payload ?? null);
            break;
          case "linkedinUrl":
            setCompanyDetailsPreference(detailsPreferences[1]);
            setLinkedInUrl(enrichmentData.payload ?? null);
            break;
          case "domain":
            setCompanyDetailsPreference(detailsPreferences[2]);
            setDomain(enrichmentData.payload ?? null);
            break;
          default:
            break;
        }
        if (enrichmentData?.extraColumns) {
          const extraColumnsObject = enrichmentData?.extraColumns?.reduce(
            (acc, item) => {
              const index = RESPONSE_COLUMNS.findIndex(
                (column) => column.label === item,
              );

              if (index !== -1) {
                acc[index] = item;
              }
              return acc;
            },
            {} as typeof extraColumns,
          );
          setExtraColumns(extraColumnsObject);
        }
      }
    }
  }, [viewMode, selectedColumnToEdit]);
  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={
            "Watch the tutorial to learn how to get Company Details with Persana."
          }
          link={""}
          credits={1}
        />
        <Text className="text-sm font-bold mt-4 mb-2">
          Find Company Details by
        </Text>
        <MultiSelectDropdown
          isMulti={false}
          placeholder="Find company details by"
          closeMenuOnSelect
          options={detailsPreferences}
          value={companyDetailsPreference}
          onChange={(selected) => {
            if (!selected) {
              setName(null);
              setLinkedInUrl(null);
              setDomain(null);
            }
            setCompanyDetailsPreference(selected as Option);
          }}
        />
        {companyDetailsPreference?.value === "name" ? (
          <CustomAccordion
            title="Company Name"
            isDefaultOpen={true}
            isOpen={true}
          >
            <SelectColumnDropdown
              selectedOption={name}
              setSelectedOption={setName}
            />
          </CustomAccordion>
        ) : companyDetailsPreference?.value === "linkedinUrl" ? (
          <CustomAccordion
            title="Company LinkedIn URL"
            isDefaultOpen={true}
            isOpen={true}
          >
            <SelectColumnDropdown
              selectedOption={linkedInUrl}
              setSelectedOption={setLinkedInUrl}
            />
          </CustomAccordion>
        ) : (
          companyDetailsPreference?.value === "domain" && (
            <CustomAccordion
              title="Company Domain"
              isDefaultOpen={true}
              isOpen={true}
            >
              <SelectColumnDropdown
                selectedOption={domain}
                setSelectedOption={setDomain}
              />
            </CustomAccordion>
          )
        )}

        <CustomAccordion title="Add Data as Columns" isOpen={true}>
          <Flex className="!flex-col items-stretch gap-2">
            {RESPONSE_COLUMNS.map((item, index) => (
              <Box
                pointerEvents={viewMode === "edit" ? "none" : "auto"}
                key={index}
                className="flex w-full cursor-pointer select-none flex-row justify-between rounded pl-2 pr-4 hover:bg-[#F5F3FF]"
                py={1}
                onChange={() => {
                  setExtraColumns((state) => ({
                    ...state,
                    [index]: state[index] ? undefined : item.label,
                  }));
                }}
                onClick={() => {
                  setExtraColumns((state) => ({
                    ...state,
                    [index]: state[index] ? undefined : item.label,
                  }));
                }}
              >
                <Text className="flex items-center gap-2 text-[16px] font-[500] text-[#373F51]">
                  <tableActionIcons.text className="h-[16px]" />
                  {item.label}
                </Text>
                <Switch
                  isDisabled={viewMode === "edit"}
                  isChecked={!!extraColumns[index]}
                  variant={"sm"}
                  colorScheme="purple"
                />
              </Box>
            ))}
          </Flex>
        </CustomAccordion>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!isValid}
        apiPayload={{
          ...payload,
          extraColumns: [...Object.values(extraColumns)].filter(
            (item) => !!item,
          ),
        }}
      />
    </>
  );
};

export default FindCompanyDetailsWithPersana;
