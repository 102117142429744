import { Transforms } from "slate";
import { ReactEditor, RenderElementProps } from "slate-react";

import Icons from "@/components/Icons";
interface Props extends RenderElementProps {
  editor: ReactEditor;
  buttonText: string;
  isCodeView?: boolean;
}

const InlineVoid = (props: Props) => {
  const { editor, element, attributes, buttonText, children } = props;

  const handleRemove = (event: React.MouseEvent) => {
    event.preventDefault();
    // WE can perform any additional logic before removing the element
    // For example, we may want to confirm with the user.

    // Remove the InlineVoid element from the editor
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
    ReactEditor.focus(editor);
  };

  return (
    <span
      {...attributes}
      contentEditable={false}
      style={{
        // @ts-ignore
        paddingRight: element?.isCodeView ? "0" : "4px",
      }}
    >
      {
        // @ts-ignore
        element?.isCodeView ? (
          <span>
            {`{{${buttonText}}}`}
          </span>
        ) : <button
          style={{
            backgroundColor: "white",
            boxShadow: "inset 0 0 0 1px #c3c7d0",
            display: "inline-flex",
            margin: "-3.85px 1px",
            padding: "1.5px 6px",
            verticalAlign: "text-bottom",
            borderRadius: "3px",
            alignItems: "center",
            fontSize: props.isCodeView ? "1rem" : "0.875rem",
          }}
          className="space-x-2"
        >
          <span>{<Icons.TextIcon className="h-auto w-4" />}</span>
          <span className="font-medium">{buttonText}</span>{" "}
          <span className="text-[#727479]" onClick={handleRemove}>
            <Icons.CloseIcon />
          </span>
        </button>
      }
      {children}
    </span>
  );
};

export default InlineVoid;
