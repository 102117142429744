import { v4 as uuidv4 } from "uuid";

import SearchLeadsResultTable from "../Common/SearchLeadsResultTable";
import { useSearchLeadsStore } from "@/stores/searchleads.store";

type FindPeopleResultApolloProps = {
  isSearching: boolean;
  currentSearchData: any;
  onCloseModal: (closeAll?: boolean) => void;
};

const FindPeopleResultApollo = ({
  isSearching,
  currentSearchData,
  onCloseModal,
}: FindPeopleResultApolloProps) => {
  const { searchLeadsResult: searchPeopleResult, updateState } = useSearchLeadsStore();

  const inputType = {
    eventType: "input",
    iconType: "text",
    event: "doubleClick",
    selectAble: true,
  };

  const getFormattedColumnRows = (columns: any, rowsToFormat: any) => {
    const formattedData = columns?.map((item: any) => {
      switch (item.value) {
        case "Name":
          const rows = rowsToFormat?.map((item: any) => {
            return {
              id: uuidv4(),
              value: item.name || "",
            };
          });
          return {
            colId: item.id,
            rows,
          };

        case "Linkedin URL":
          const rows1 = rowsToFormat?.map((item: any) => {
            return {
              id: uuidv4(),
              value: item.linkedin_url || "",
            };
          });
          return {
            colId: item.id,
            rows: rows1,
          };

        case "Title":
          const rows2 = rowsToFormat?.map((item: any) => {
            return {
              id: uuidv4(),
              value: item?.title || "",
            };
          });
          return {
            colId: item.id,
            rows: rows2,
          };

        case "Location":
          const rows4 = rowsToFormat?.map((item: any) => {
            return {
              id: uuidv4(),
              //   value: item?.state ? `${item?.state} ,` : item?.country || "",
              value: `${item?.state ? `${item?.state} ,` : ""} ${
                item?.country || ""
              }`,
            };
          });
          return {
            colId: item.id,
            rows: rows4,
          };

        case "Employment History":
          const rows6 = rowsToFormat?.map((item: any) => {
            return {
              id: uuidv4(),
              value:
                item?.employment_history?.length > 0
                  ? `Worked With ${item?.employment_history?.length} Companies`
                  : "",
            };
          });

          return {
            colId: item.id,
            rows: rows6,
          };

        case "Company Name":
          const rows8 = rowsToFormat?.map((item: any) => {
            return {
              id: uuidv4(),
              value: item?.organization?.name || "",
            };
          });
          return {
            colId: item.id,
            rows: rows8,
          };

        case "Company Website":
          const rows9 = rowsToFormat?.map((item: any) => {
            return {
              id: uuidv4(),
              value: item?.organization?.website_url || "",
            };
          });
          return {
            colId: item.id,
            rows: rows9,
          };

        case "Company LinkedIn URL":
          const rows10 = rowsToFormat?.map((item: any) => {
            return {
              id: uuidv4(),
              value: item?.organization?.linkedin_url || "",
            };
          });
          return {
            colId: item.id,
            rows: rows10,
          };

        default:
          break;
      }
    });

    return formattedData;
  };
  const generateInitialResultColumns = () => {
    const columNames = [
      "Name",
      "Linkedin URL",
      "Title",
      "Company Name",
      "Company Website",
      "Location",
      "Company LinkedIn URL",
      "Employment History",
    ];

    const idAddedColumNames = columNames.map((item) => {
      return {
        id: uuidv4(),
        value: item,
        ...inputType,
      };
    });

    updateState({
      searchLeadsColumnNames: idAddedColumNames,
    });

    if ( searchPeopleResult && searchPeopleResult?.profiles?.length > 0) {
      const columnRows = getFormattedColumnRows(
        idAddedColumNames,
        searchPeopleResult?.profiles,
      );
      updateState({ searchLeadsResultRows: columnRows });
    }
  };
  return (
    <SearchLeadsResultTable
      isSearching={isSearching}
      currentSearchData={currentSearchData}
      getFormattedColumnRows={getFormattedColumnRows}
      generateInitialResultTable={generateInitialResultColumns}
      onCloseModal={onCloseModal}
    />
  );
};

export default FindPeopleResultApollo;
