import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Text,
  Spinner,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import useCreateApiKey from "./hooks/useCreateApiKey";
import useGenerateSlugs from "./hooks/useGenerateSlugs";
import Icons from "../Icons";
import { userStore } from "@/stores/user.store";

interface CreateApiKeyModalProps {
  user?: {
    _id: string;
    Email: string;
    creditLeft: number;
    creditUsed: number;
    planType: string;
    profileInfo: {
      companyURL: string;
      firstName: string;
      lastName: string;
      companyName: string;
      companyDescription: string;
      callToAction: string;
      companyTarget: string;
    };
    totalCreditUsed: number;
  };
}

const CreateApiKeyModal: React.FC<CreateApiKeyModalProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [apiKey, setApiKey] = useState("");
  const showApiKeyDisclosure = useDisclosure();
  const [name, setName] = useState("");
  const toast = useToast();
  const createApiKeyMutation = useCreateApiKey();
  const currentUser = userStore().currentUser; // Fetch the current user if not provided
  const user = props.user ?? currentUser;
  const { data: slugs, isLoading, refetch } = useGenerateSlugs();

  useEffect(() => {
    if (slugs && slugs.length > 0) {
      setName(slugs[0]);
    }
  }, [slugs]);

  const handleSubmit = () => {
    const selectedUser = user || currentUser; // Use the passed user or fallback to currentUser

    if (!name) {
      toast({
        title: "API Key name is required",
        status: "error",
        duration: 2000,
      });
      return;
    }

    if (!selectedUser?._id) {
      toast({
        title: "User information is missing",
        status: "error",
        duration: 2000,
      });
      return;
    }

    createApiKeyMutation.mutate(
      { name, userId: selectedUser._id }, // Pass the userId to the mutation
      {
        onSuccess: (result) => {
          toast({
            title: "API Key created successfully",
            status: "success",
            duration: 2000,
          });
          setName("");
          setApiKey(result?.data?.data?.apiKey);
          showApiKeyDisclosure.onOpen();
          refetch();
          onClose();
        },
        onError: () => {
          toast({
            title: "Error creating API Key",
            status: "error",
            duration: 2000,
          });
        },
      },
    );
  };

  const handleRegenerate = () => {
    refetch();
  };
  const handleCopyKey = () => {
    navigator.clipboard.writeText(apiKey);
    showApiKeyDisclosure.onClose();
    setName("");
    setApiKey("");
    toast({
      title: "API Key copied to clipboard",
      status: "success",
      duration: 2000,
    });
  };
  return (
    <>
      <Button
        leftIcon={<Icons.PlusIcon />}
        colorScheme="purple"
        onClick={onOpen}
      >
        Create New API Key
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          refetch();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a new API Key</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="userEmail">
              <FormLabel>
                User Email
                <Input value={user?.Email} isDisabled={true} />
              </FormLabel>
            </FormControl>
            <FormControl id="name" isRequired>
              <FormLabel>API Key Name</FormLabel>
              {isLoading ? (
                <Spinner />
              ) : (
                <InputGroup>
                  <Input
                    autoFocus
                    onFocus={(e) => e.target.select()}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter an API key name"
                  />
                  <InputRightElement>
                    <IconButton
                      variant={"ghost"}
                      size="xs"
                      onClick={handleRegenerate}
                      isDisabled={isLoading}
                      aria-label={""}
                      icon={<Icons.RefreshIcon />}
                    ></IconButton>
                  </InputRightElement>
                </InputGroup>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="purple"
              variant={"outline"}
              mr={3}
              onClick={handleSubmit}
              isLoading={createApiKeyMutation.isPending}
              isDisabled={!name}
            >
              Create
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                refetch();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showApiKeyDisclosure.isOpen}
        onClose={showApiKeyDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>API Key</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="!truncate px-[1em]">
            <Text className="truncate">
              {apiKey?.slice(0, 5) + "*".repeat(10)}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              leftIcon={<Icons.CopyIcon />}
              colorScheme="purple"
              mr={3}
              onClick={handleCopyKey}
            >
              Copy
            </Button>
            <Button
              colorScheme="purple"
              variant={"outline"}
              mr={3}
              onClick={showApiKeyDisclosure.onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateApiKeyModal;
