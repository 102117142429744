import { Checkbox } from "@chakra-ui/react";

type AllowEmptyVarProps = {
  setAllowEmptyVars: (val: boolean) => void;
  allowEmptyVars: boolean;
};
export const AllowEmptyVar = ({
  setAllowEmptyVars,
  allowEmptyVars,
}: AllowEmptyVarProps) => {
  return (
    <p className="flex items-center space-x-1 text-xs font-medium">
      <label className="flex cursor-pointer flex-row items-center gap-1">
        <Checkbox
          colorScheme="purple"
          size="sm"
          isChecked={allowEmptyVars}
          onChange={(e) => setAllowEmptyVars(e.target.checked)}
        />
        Force run for empty variables
      </label>
    </p>
  );
};
