import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import AddTeammates from "./AddTeammates";
import ManageTeam from "./ManageTeam";
import { useTeamData } from "@/hooks/useTeamData";
import { Member } from "@/types/team.types";
import { useModalStore } from "@/stores/modal.store";
import InputField from "../Common/InputField";
import { userStore } from "@/stores/user.store";
import { useMutation } from "@tanstack/react-query";
import teamService from "@/services/team.service";

function TeamOverviewModal() {
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [teamMembers, setTeamMembers] = useState<Member[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState<boolean>(false);

  const currentUser = userStore((state) => state.currentUser);
  const { addTeamMembersModal, updateState } = useModalStore((state) => state);
  const { data: teamData } = useTeamData();
  const toast = useToast();

  const { mutateAsync } = useMutation({
    mutationFn: (payload: { name: string }) =>
      teamService.creteWorkspace(payload),
  });

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const onClose = () => {
    updateState({
      addTeamMembersModal: {
        isOpen: false,
        isForShare: false,
        isCreateWorkspace: false,
      },
    })
  };


  const handleCreateWorkspace = async () => {

    if (!workspaceName) {
      toast({
        title: "Error",
        description: "Workspace name is required",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    };

    setIsCreatingWorkspace(true);
    await mutateAsync({ name: workspaceName }, {
      onSuccess: (response) => {
        if (response?.data?.success) {
          toast({
            title: "Success",
            description: "Workspace created successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          updateState({
            ownedWorkspaces: [response?.data?.data || []],
            addTeamMembersModal: {
              isOpen: true,
              isForShare: false,
              isCreateWorkspace: false,
            },
          });
        } else {
          toast({
            title: "Error",
            description: `Failed to create workspace. ${response?.data?.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
      onError: () => {
        setIsCreatingWorkspace(false);
        toast({
          title: "Error",
          description: "Failed to create workspace",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      },
    });

    setIsCreatingWorkspace(false);
  };


  useEffect(() => {
    if (teamData?.data) {
      setTeamMembers(teamData.data.members.filter(member => member.role !== "admin"));
    }
  }, [teamData?.data]);

  useEffect(() => {
    if (addTeamMembersModal.isForShare) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [addTeamMembersModal.isForShare]);

  useEffect(() => {
    if (addTeamMembersModal.isCreateWorkspace && currentUser) {
      setWorkspaceName(`${currentUser?.profileInfo?.firstName}'s workspace` || "");
    }
  }, [addTeamMembersModal.isCreateWorkspace, currentUser]);

  return (
    <Modal
      isOpen={addTeamMembersModal.isOpen}
      onClose={onClose}
      size={"xl"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton className="z-10" />
        <ModalBody>
          {
            addTeamMembersModal?.isCreateWorkspace ? (
              <div className="p-6 font-title">
                <div className="py-3">
                  <label htmlFor="workspaceName" className="font-medium text-base block pb-1">Workspace Name</label>
                  <InputField
                    id="workspaceName"
                    type="text"
                    labelClass="font-medium text-lg"
                    inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none !text-base"}
                    event={(e) => setWorkspaceName(e.target.value)}
                    value={workspaceName}
                  />
                </div>

                <p className="font-semibold text-base">Note: <span className="font-medium text-sm">Your workspace will now become a team workspace and you will become an admin</span></p>

                <Button
                  className="btn-primary-outline !flex !h-[35px] !min-w-fit !cursor-pointer !items-center !justify-center !rounded-[5px] !px-[28px] !py-[8px] !text-[14px] !font-semibold !text-[#302323] !w-full !mt-5"
                  variant={"outline"}
                  isLoading={isCreatingWorkspace}
                  onClick={handleCreateWorkspace}
                >
                  Create Workspace
                </Button>

              </div>
            ) : (
              <Tabs align="center" position="relative" variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
                <TabList>
                  <Tab>Add Teammates</Tab>
                  <Tab>Manage Team {
                    teamMembers?.length ? `(${teamMembers.length})` : ""
                  }</Tab>
                </TabList>

                <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />

                <TabPanels>
                  <TabPanel>
                    <AddTeammates changeTab={handleTabsChange} />
                  </TabPanel>
                  <TabPanel>
                    <ManageTeam changeTab={handleTabsChange} teamMembers={teamMembers} isForShare={addTeamMembersModal.isForShare} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TeamOverviewModal
