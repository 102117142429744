import APIKeyIntegration from "./APIKeyIntegration";

const ApifyIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"apifyApiKey"}
      providerTitle="Apify"
      providerIcon={
        "https://persana-assets.s3.us-west-1.amazonaws.com/apify.png"
      }
    />
  );
};

export default ApifyIntegration;
