import { Switch } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import KeySelect from "@/components/Common/KeySelect";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";

import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import { preselectEnrichmentState } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { RESPONSE_COLUMNS } from "./constants";

interface EnrichmentDataType extends EnrichmentMetaData {
  extraColumns?: string[];
}

const SearchPhoneNumber = () => {
  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  const [responseColumns, setResponseColumns] = useState(
    () => RESPONSE_COLUMNS,
  );

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const [selectedUrlOption, setSelectedUrlOption] =
    useState<TSelectedColumnOption | null>(null);

  const handleSwitch = (id: string) => {
    setResponseColumns((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item,
      ),
    );
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        if (enrichmentData.extraColumns) {
          setResponseColumns((prevState) =>
            prevState.map((item) => ({
              ...item,
              isChecked:
                enrichmentData?.extraColumns?.includes(item.label) ?? false,
            })),
          );
        }
        preselectEnrichmentState(
          enrichmentData,
          setSelectedOption,
          // @ts-ignore
          enrichmentData.cellData.email,
        );
        preselectEnrichmentState(
          enrichmentData,
          setSelectedUrlOption,
          // @ts-ignore
          enrichmentData.cellData.username,
        );
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    }
  }, [columns]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={"Get the Phone Number from any Work email"}
          link={""}
        />
        <KeySelect
          providerName={"datagma"}
          providerTitle="Datagma"
          disablePersanaKey={true}
        />

        <div className="flex flex-col gap-4">
          <div>
            <p className="pb-1 text-base font-semibold">Work Email</p>
            <SelectColumnDropdown
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <div>
            <p className="pb-1 text-base font-semibold">LinkedIn URL</p>
            <SelectColumnDropdown
              selectedOption={selectedUrlOption}
              setSelectedOption={setSelectedUrlOption}
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-[8px] pb-4">
          <div className="mb-2 flex items-center justify-between">
            <p className="text-base font-semibold">
              Add data as columns to your table
            </p>
            <p className="text-grey-400 text-[14px] text-[#676d7e]">
              {responseColumns.filter((item) => item.isChecked).length} columns
              selected
            </p>
          </div>
          <div>
            {responseColumns.map(({ id, label, isChecked }) => (
              <div
                key={id}
                className="flex w-full items-center justify-between rounded-[6px] bg-[#FAFAFB] px-4 py-2"
              >
                <p className="text-[16px] font-[500]">{label}</p>
                <Switch
                  size={"md"}
                  isChecked={isChecked}
                  id={id}
                  onChange={() => handleSwitch(id)}
                />
              </div>
            ))}
          </div>
        </div>

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId && !selectedUrlOption?.keyId}
        apiPayload={{
          extraColumns: responseColumns
            .filter((item) => item.isChecked)
            .map((item) => item.label),
          cellData: {
            email: selectedOption?.keyId || "",
            username: selectedUrlOption?.keyId || "",
          },
          ownKeyProviderList: ["Datagma"],
          providers: ["datagma"],
          apiKeyName: "datagma",
          isUseOwnKey: false,
        }}
      />
    </>
  );
};

export default SearchPhoneNumber;
