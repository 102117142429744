import APIKeyIntegration from "./APIKeyIntegration";

const WoodpeckerIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"woodpeckerApiKey"}
      providerTitle="Woodpecker"
      providerIcon={
        "https://persana-assets.s3.us-west-1.amazonaws.com/woodpecker.jpeg"
      }
    />
  );
};

export default WoodpeckerIntegration;
