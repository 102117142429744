import { useMemo } from "react";
import { Stack } from "@chakra-ui/react";
import { CustomProxy } from "./proxy";
import { RenderJs } from "./renderjs";
import { Output } from "./output";
import Icons from "@/components/Icons";
import { ICustomizationsProps } from "../types";
import { defaultZenRowParams } from "../constants";

export const Customizations = ({
  zenRowsParams = defaultZenRowParams,
  setZenRowsParams,
}: ICustomizationsProps) => {
  return (
    <Stack gap={4} className="mb-2 mt-4 rounded-lg bg-[#FAFAFB] p-4 pt-2">
      <CustomProxy
        enabled={zenRowsParams.zenRowsProxy}
        setEnabled={(value) =>
          setZenRowsParams((prev) => ({ ...prev, zenRowsProxy: value }))
        }
        value={zenRowsParams.zenRowsProxyCountry}
        setValue={(value) =>
          setZenRowsParams((prev) => ({
            ...prev,
            zenRowsProxyCountry: value,
          }))
        }
      />
      <RenderJs
        value={zenRowsParams.zenRowsRenderJS}
        setValue={(value) =>
          setZenRowsParams((prev) => ({ ...prev, zenRowsRenderJS: value }))
        }
      />
      <Output
        cssSelectors={zenRowsParams.zenRowsCssSelectors}
        setCssSelectors={(value) =>
          setZenRowsParams((prev) => ({
            ...prev,
            zenRowsCssSelectors: value,
          }))
        }
        setValue={(value) =>
          setZenRowsParams((prev) => ({ ...prev, zenRowsOutput: value }))
        }
        value={zenRowsParams.zenRowsOutput}
        options={useMemo(
          () => [
            {
              value: "auto_parse",
              label: "Auto",
              icon: Icons.TerminalIcon,
            },
            {
              value: "html",
              label: "HTML",
              icon: Icons.Html5Icon,
            },
            {
              value: "css",
              label: "CSS",
              icon: Icons.Css3Icon,
            },
            {
              value: "markdown",
              label: "Markdown",
              icon: Icons.MarkdownIcon,
            },
          ],
          [],
        )}
      />
    </Stack>
  );
};
