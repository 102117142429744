import { Button, ButtonGroup, useDisclosure } from "@chakra-ui/react";

import { usePresetsStore } from "@/stores/presets.store";
import Icons from "@/components/Icons";
import { CgTemplate } from "react-icons/cg";
import { usePresets } from "@/hooks/usePresets";
import { SaveAsTemplate } from "../../Common/SaveTemplate";
import { useEnrichStore } from "@/stores/enrich.store";

const SelectTemplate = ({ payload }: any) => {
  const modalDisclosure = useDisclosure();
  const updateState = usePresetsStore((state) => state.updateState);
  const { data: presetsData } = usePresets("persana-agent");
  const selectedPresetValues = usePresetsStore(
    (state) => state.selectedPresetValues,
  );
  const runConditionFormula = useEnrichStore(
    (state) => state.runConditionFormula,
  );
  return (
    <div className="mb-4 flex items-center justify-between">
      <span className="text-sm">
        Select templates <span className="text-[gray]">(optional)</span>
      </span>
      <ButtonGroup isAttached>
        <Button
          isDisabled={!presetsData?.length}
          leftIcon={<CgTemplate />}
          onClick={() => {
            updateState({
              isOpenPresetsModal: true,
              presetsModalData: {
                modalType: "persana-agent",
              },
            });
          }}
          className="border-gray-800/40 cursor-pointer rounded border-[0.5px] bg-[#f6f6f8] px-3 py-0.5 text-sm font-medium capitalize"
          size="xs"
        >
          use template
        </Button>
        <Button
          leftIcon={<Icons.PlusIcon className="text-lg" />}
          size="xs"
          onClick={modalDisclosure.onOpen}
          variant={"outline"}
        >
          Save as template
        </Button>
      </ButtonGroup>
      {modalDisclosure.isOpen && (
        <SaveAsTemplate
          type="persana-agent"
          isModalOpen={modalDisclosure.isOpen}
          handleClose={modalDisclosure.onClose}
          icon={<Icons.AgentIcon color="#0284fe" size={26} />}
          payload={{
            prompt: selectedPresetValues,
            condition: runConditionFormula,
            ...payload,
          }}
        />
      )}
    </div>
  );
};

export default SelectTemplate;
