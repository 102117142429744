import Filters from "@/components/Common/Filter";
import {
  availableGates,
  conditions,
} from "@/components/Common/Filter/constants";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import SpinLoader from "@/components/Common/SpinLoader";
import Icons from "@/components/Icons";
import { useProviderList } from "@/hooks/useProviderData";
import { generateUniqueId } from "@/lib/utils";
import providerService from "@/services/providers.service";
import { FilterType } from "@/types/common.types";
import { Button, Checkbox, Input, ModalBody, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { conditionOptions } from "./constants";

interface Props {
  onClose: (byPassPreviousModal?: boolean) => void;
}

interface SalesforceObjectFields extends Option {
  _id: string;
  name: string;
}

function ImportFromSalesforce({ onClose }: Props) {
  const [sObjects, setSObjects] = useState<Option[]>([]);
  const [sObjectFields, setSObjectFields] = useState<Option[]>([]);
  const [selectedSObject, setSelectedSObject] = useState<Option | null>(null);
  const [selectedSObjectFields, setSelectedSObjectFields] = useState<
    SalesforceObjectFields[] | null
  >([]);
  const [isImportAllRecords, setIsImportAllRecords] = useState(true);
  const [customLimit, setCustomLimit] = useState<number | null>(null);
  const [filters, setFilters] = useState<FilterType[]>([]);

  const toast = useToast();
  const navigate = useNavigate();

  const { data: providerList, isLoading } = useProviderList();
  const salesforceProvider = providerList?.data?.find(
    (provider) => provider.name === "salesforce",
  );

  const isSalesforceConnected = salesforceProvider?.metaData;

  const { mutateAsync: fetchObjects, isPending: isFetchObjectsLoading } =
    useMutation({
      mutationFn: (keyId: string) => {
        return providerService.getSalesForceObjects(keyId);
      },
      onSuccess: (response) => {
        console.log("response", response);
        if (response?.success) {
          setSObjects(response?.data);
        } else {
          toast({
            // @ts-ignore
            title: response?.message || "Something went wrong.",
            status: "error",
            isClosable: true,
            position: "top-right",
          });
        }
      },
    });

  const { mutateAsync: fetchFields, isPending: isFetchingFields } = useMutation(
    {
      mutationFn: ({ keyId, objName }: { keyId: string; objName: string }) => {
        return providerService.getSalesForceFields({
          keyId,
          objectName: objName,
          isForSelect: true,
        });
      },
      onSuccess: (response) => {
        if (!response?.success) {
          toast({
            // @ts-ignore
            title: response?.message || "Something went wrong.",
            status: "error",
            isClosable: true,
            position: "top-right",
          });

          return;
        }

        setSObjectFields(response?.data);
      },
    },
  );

  const { mutateAsync: importRecords, isPending } = useMutation({
    mutationFn: ({
      keyId,
      objName,
      fields,
      isImportAllRecords,
      customLimit,
    }: {
      keyId: string;
      objName: string;
      fields: string[];
      isImportAllRecords: boolean;
      customLimit: number | null;
    }) => {
      return providerService.importSalesforceRecords({
        keyId,
        objectName: objName,
        fields,
        isImportAllRecords,
        customLimit,
        filters,
      });
    },
    onSuccess: (response) => {
      console.log("response", response);

      if (!response?.success || !response?.data?.success) {
        toast({
          // @ts-ignore
          title: response?.data?.message || "Something went wrong.",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } else {
        onClose(true);
        navigate({
          to: "/table/$tableId",
          params: {
            tableId: response?.data?.data?.tableId,
          },
        });
      }
    },
  });

  const handleObjectChange = async (value: Option) => {
    setSelectedSObject(value);
    await fetchFields({
      keyId: salesforceProvider?._id || "",
      objName: value.value,
    });
  };

  const getIsActionBtnDisabled = () => {
    const isRequiredFieldsAdded =
      selectedSObject && selectedSObjectFields?.length;

    if (isImportAllRecords) {
      return !isRequiredFieldsAdded;
    } else {
      return !isRequiredFieldsAdded || customLimit === null;
    }
  };

  const onAddFilterClick = () => {
    if (selectedSObjectFields?.length) {
      setFilters([
        ...filters,
        {
          id: generateUniqueId(),
          condition: conditions[0],
          gateCondition: availableGates[0],
          value: "",
          selectedField: selectedSObjectFields?.[0],
        },
      ]);
    }
  };

  useEffect(() => {
    if (isSalesforceConnected) {
      fetchObjects(salesforceProvider?._id || "");
    }
  }, [providerList]);

  return (
    <>
      <ModalBody>
        <div className="font-title">
          {isLoading ? (
            <div className="py-10 w-full flex justify-center">
              <SpinLoader className="text-primary w-9 h-9" />
            </div>
          ) : (
            <div className="space-y-4 pb-3">
              <p className="font-medium text-sm">Enrich your salesforce data</p>

              {isSalesforceConnected ? (
                <div className="space-y-6">
                  <div className="space-y-1">
                    <p className="font-semibold">Salesforce Object</p>
                    <MultiSelectDropdown
                      isMulti={false}
                      placeholder="Select an item from the dropdown..."
                      closeMenuOnSelect
                      options={sObjects}
                      value={selectedSObject}
                      onChange={(value) => {
                        handleObjectChange(value as Option);
                      }}
                      isDisabled={!isSalesforceConnected}
                      isLoading={isFetchObjectsLoading}
                      isCreateAble={false}
                    />
                    <p className="text-xs text-[#717989] font-medium">
                      The object type to look for in your Salesforce instance.
                    </p>
                  </div>

                  <div className="space-y-1">
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold">Available fields</p>
                      {selectedSObjectFields?.length && !filters?.length ? (
                        <button
                          onClick={() => {
                            onAddFilterClick();
                          }}
                          className="flex items-center text-sm font-title space-x-1 font-medium"
                        >
                          <Icons.FilterAddIcon />
                          <span>Add Filter</span>
                        </button>
                      ) : null}
                    </div>
                    <MultiSelectDropdown
                      isMulti={true}
                      placeholder="Select an item from the dropdown..."
                      options={sObjectFields}
                      value={selectedSObjectFields}
                      onChange={(value) => {
                        setSelectedSObjectFields(
                          value as SalesforceObjectFields[],
                        );
                      }}
                      isLoading={isFetchingFields}
                      isCreateAble={false}
                    />
                    <p className="text-xs text-[#717989] font-medium">
                      The view to sync into Persana. If the view you select
                      isn't SOQL-compatible (i.e. can't be generated from a SOQL
                      query), you'll be limited to 2000 records.
                    </p>
                  </div>
                  <div className="s">
                    <div className="space-x-4">
                      <Checkbox
                        className="!text-sm !font-title !font-medium"
                        isChecked={isImportAllRecords}
                        onChange={() => {
                          setIsImportAllRecords(!isImportAllRecords);
                        }}
                      >
                        Import all records
                      </Checkbox>
                      <Checkbox
                        className="!text-sm !font-title !font-medium"
                        isChecked={!isImportAllRecords}
                        onChange={() => {
                          setIsImportAllRecords(!isImportAllRecords);
                        }}
                      >
                        Add custom limit
                      </Checkbox>
                    </div>
                    {!isImportAllRecords && (
                      <Input
                        value={customLimit !== null ? customLimit : ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          const parsedValue = parseInt(value, 10);
                          if (!isNaN(parsedValue)) {
                            setCustomLimit(parsedValue);
                          } else {
                            setCustomLimit(null);
                          }
                        }}
                        placeholder="Enter custom limit"
                        className="border-gray-300 w-full rounded-md border !px-2 !py-[7px] focus:border-blue-500 focus:outline-none max-h-[38px] mt-3"
                      />
                    )}
                  </div>
                  <div className="space-y-2.5">
                    {selectedSObjectFields?.length
                      ? filters?.map((filter, index) => (
                          <Filters
                            key={`filter_key_${index}`}
                            fields={selectedSObjectFields}
                            filters={filters}
                            index={index}
                            filter={filter}
                            onAddFilter={onAddFilterClick}
                            onFilterChange={(updatedFilters) => {
                              setFilters(updatedFilters);
                            }}
                            conditionOptions={conditionOptions}
                          />
                        ))
                      : null}
                  </div>
                </div>
              ) : (
                <Button
                  variant={"outline"}
                  className={`!w-full !py-1 !text-center ${
                    isSalesforceConnected ? "!bg-red-500" : "!bg-primary"
                  } !text-white !rounded-md !font-semibold`}
                  isLoading={isLoading}
                  onClick={() => {
                    if (!isSalesforceConnected) {
                      providerService.openSalesforceOAuth();
                    }
                  }}
                >
                  {isSalesforceConnected ? "Disconnect" : "Connect to"}{" "}
                  Salesforce
                </Button>
              )}
            </div>
          )}
        </div>
      </ModalBody>
      <div className="w-full px-6 py-3 border-t border-borderGray flex justify-end space-x-4">
        <Button
          isDisabled={getIsActionBtnDisabled()}
          isLoading={isPending}
          className="gradientBg !py-1 !text-center !text-white !rounded !font-semibold !px-5"
          onClick={() => {
            if (selectedSObject && selectedSObjectFields?.length) {
              importRecords({
                keyId: salesforceProvider?._id || "",
                objName: selectedSObject.value,
                fields: selectedSObjectFields.map((field) => field.value),
                isImportAllRecords,
                customLimit,
              });
            }
          }}
        >
          Import records to table
        </Button>

        <button
          className="py-1 text-center bg-[#F5F3FF] text-black rounded font-semibold px-5"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </button>
      </div>
    </>
  );
}

export default ImportFromSalesforce;
