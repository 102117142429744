import Select from "react-select";
import { useEffect, useMemo, useRef } from "react";
import { Reorder } from "framer-motion";
import { IProviderWaterfallProps, TProviderOption } from "./types";
import { IndicatorsContainer } from "./components/IndicatorsContainer";
import { ValueContainer } from "./components/ValueContainer";
import { Option } from "./components/Option";
import {
  ProviderWaterfallContext,
  useCreateWaterfallStore,
  useWaterfallStore,
} from "./store";
import { baseStyles } from "./styles";
import { AddMoreWaterfall } from "./components/AddMoreWaterfall";
import { DraggableItem } from "./components/DragableItem";
import { useStore } from "zustand";
import { ProviderNameType } from "@/stores/provider.store";
import { useUpdateOwnkeyList } from "./hooks/useUpdateOwnkeyList";
import { ConfigMenu } from "./components/ConfigMenu";

const ProviderWaterfallComponent = () => {
  useUpdateOwnkeyList();
  const providerOptions = useWaterfallStore((state) => state.providerOptions);
  const providerOptionsMap = useMemo(
    () => Object.fromEntries(providerOptions.map((p) => [p.value, p])),
    [providerOptions],
  );
  const dragConstraints = useRef(null);
  const selectedProviders = useWaterfallStore(
    (state) => state.selectedProviders,
  );
  const setState = useWaterfallStore((state) => state.setState);
  return (
    <>
      <Reorder.Group
        ref={dragConstraints}
        as="div"
        values={selectedProviders}
        onReorder={(selectedProviders) =>
          setState({
            selectedProviders,
          })
        }
        className="flex flex-col gap-2 bg-[#FDFDFD] py-3"
      >
        {selectedProviders.map((provider, index) => (
          <DraggableItem
            as="div"
            value={provider}
            key={provider.value}
            className="flex items-center"
            isFirst={index === 0}
            isLast={index === selectedProviders.length - 1}
            dragConstraints={dragConstraints}
          >
            <Select<TProviderOption>
              components={{
                Option,
                IndicatorsContainer,
                ValueContainer,
              }}
              isMulti={false}
              options={providerOptions}
              placeholder="Select option"
              className="flex-1"
              value={providerOptionsMap[provider.value]}
              onChange={(chosen) => {
                if (chosen !== null && typeof chosen !== "undefined") {
                  const alreadySelected = selectedProviders.find(
                    (pr) => pr.value === chosen.value,
                  );
                  const newProviders = selectedProviders.filter(
                    (pr) => pr.value !== chosen.value,
                  );
                  newProviders.splice(index, alreadySelected ? 0 : 1, chosen);
                  setState({
                    selectedProviders: newProviders,
                  });
                }
              }}
              styles={{
                control: baseStyles,
                menu: baseStyles,
                indicatorsContainer: baseStyles,
              }}
            ></Select>
            <ConfigMenu aria-label="provider-config" provider={provider} />
          </DraggableItem>
        ))}

        {selectedProviders.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <p className="text-gray-500 text-sm opacity-50">
              No providers selected
            </p>
          </div>
        )}
      </Reorder.Group>
      <AddMoreWaterfall />
    </>
  );
};

export const ProviderWaterfall = (props: IProviderWaterfallProps) => {
  const store = useCreateWaterfallStore({
    providerOptions: props.providerOptions,
    defaultProviders: props.defaultProviders,
    selectedProviders: props.selectedProviders || props.defaultProviders,
    initialUserKeys: props.initialUserKeys as ProviderNameType[],
  });
  const selectedProviders = useStore(store, (state) => state.selectedProviders);
  const updateStoreState = useStore(store, (state) => state.setState);
  useEffect(() => {
    props.setSelectedProviders(selectedProviders);
  }, [selectedProviders, props.setSelectedProviders]);
  useEffect(() => {
    updateStoreState({
      providerOptions: props.providerOptions,
      defaultProviders: props.defaultProviders,
      selectedProviders: props.selectedProviders || props.defaultProviders,
      initialUserKeys: props.initialUserKeys as ProviderNameType[],
    });
  }, [
    props.defaultProviders,
    props.providerOptions,
    props.selectedProviders || null,
    props.initialUserKeys || null,
    updateStoreState,
  ]);
  return (
    <ProviderWaterfallContext.Provider value={store}>
      <ProviderWaterfallComponent />
    </ProviderWaterfallContext.Provider>
  );
};
