import APIKeyIntegration from "./APIKeyIntegration";
import DropContactLogo from "@/assets/images/Dropcontact.png";

const DropContactIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"dropcontact"}
      providerTitle="DropContact"
      providerIcon={DropContactLogo}
    />
  );
};

export default DropContactIntegration;
