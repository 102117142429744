import StarterSection from "./StarterSection";
import RightSection from "./RightSection";
import Referral from "@/components/Leads/Referrals";
import MobileAlertDialogComponent from "@/components/alerts/MobileVIewAlert";

const Dashboard = () => {
  return (
    <>
      <div className="my-[16px] items-start justify-between gap-[12px] md:flex">
        {/* Left Section */}
        <div className="md:w-[55%]">
          <StarterSection />
        </div>
        {/* Right Section */}
        <div className="custom-scrollbar mt-[20px] mb-4 mr-4 flex  items-end justify-between gap-[15px] overflow-x-auto bg-white md:w-[45%] md:flex-col">
          <RightSection />
        </div>
      </div>
      <MobileAlertDialogComponent />
      <Referral />
    </>
  );
};

export default Dashboard;
