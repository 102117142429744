import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Button,
  ButtonGroup,
  Input,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import ProgressBar from "./ProgressBar";

import Icons from "@/components/Icons";
import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";
import { isValidURL } from "@/utils";

import GithubIcon from "@/assets/svgs/tool-github.svg";
import HubspotIcon from "@/assets/svgs/tool-hubspot.svg";
import LemlistIcon from "@/assets/svgs/tool-lemlist.svg";
import SalesforceIcon from "@/assets/svgs/tool-salesforce.svg";
import ApolloIcon from "@/assets/svgs/tool-apollo.svg";
import InstantlyIcon from "@/assets/svgs/tool-instantly.svg";
import ApifyIcon from "@/assets/svgs/tool-apify.svg";
import OutreachIcon from "@/assets/svgs/tool-outreach.svg";
import GrowthIcon from "@/assets/svgs/tool-growth.svg";
import GSuiteIcon from "@/assets/svgs/tool-g-suite.svg";
import SmarleadIcon from "@/assets/svgs/tool-smartlead.svg";
import GmailIcon from "@/assets/svgs/tool-gmail.svg";
import ContactOutIcon from "@/assets/svgs/tool-contactout.svg";
import GPTIcon from "@/assets/svgs/tool-gpt.svg";
import LinkedinIcon from "@/assets/svgs/tool-linkedin.svg";
import SalesoftIcon from "@/assets/svgs/tool-salesoft.svg";
import ClayIcon from "@/assets/svgs/tool-clay.svg";
import RB2BIcon from "@/assets/svgs/tool-rb2b.svg";
import HeyReach from "@/assets/svgs/tool-heyreach.svg";
import SendSpark from "@/assets/svgs/tool-sendspark.svg";

type StepComponentProps = {
  stepData: StepType;
  userInfo: UserInfoType;
  handleUserInfo: (key: string, value: string) => void;
};

type StepType = {
  title: string;
  subtitle: string;
  placeholder?: string;
  key: string;
  component: any;
  defaultValue?: string;
  options?: { name: string; logo: string }[];
};

type UserInfoType = {
  firstName: string;
  jobTitle: string;
  companyName: string;
  companyURL: string;
  companyDescription: string;
  assistYouWith: string[];
  companyTarget: string;
  tools: string[];
};

type Props = {
  callFrom: string;
  token: string;
  redirectUrl: string;
  onSubmit: (redirectUrl: string) => void;
};

const steps: StepType[] = [
  {
    title: "Enter your name",
    subtitle: "What's your name?",
    placeholder: "Name",
    key: "firstName",
    component: Input,
  },
  {
    title: "Enter your job title",
    subtitle: "What's your position in the company?",
    placeholder: "Job Title",
    key: "jobTitle",
    component: Input,
  },
  {
    title: "Choose name for your workspace",
    subtitle: "What's the name of your company?",
    placeholder: "Company Name",
    key: "companyName",
    component: Input,
  },
  {
    title: "Enter your company URL",
    subtitle: "What's your company website URL?",
    placeholder: "Company URL",
    key: "companyURL",
    component: Input,
    defaultValue: "https://",
  },
  {
    title: "Enter your company description",
    subtitle: "Provide a brief overview of your business",
    placeholder: "Company Description",
    key: "companyDescription",
    component: Textarea,
  },
  {
    title: "How will you be using Persana?",
    subtitle: "We’ll help you get started more smoothly to hit your goal.",
    key: "assistYouWith",
    component: Select,
    options: [
      { name: "Find new leads", logo: "" },
      { name: "Automate lead workflow", logo: "" },
      { name: "Enrich existing leads", logo: "" },
      { name: "Use AI agent", logo: "" },
      { name: "Write emails with AI", logo: "" },
      { name: "Just exploring Persana", logo: "" },
    ],
  },
  {
    title: "Which of the following tools do you use regularly?",
    subtitle: "We’ll help you get started more smoothly to hit your goal.",
    key: "tools",
    component: Select,
    options: [
      { name: "Salesforce", logo: SalesforceIcon },
      { name: "Apollo", logo: ApolloIcon },
      { name: "Hubspot", logo: HubspotIcon },
      { name: "Lemlist", logo: LemlistIcon },
      { name: "Github", logo: GithubIcon },
      { name: "Instantly", logo: InstantlyIcon },
      { name: "HeyReach", logo: HeyReach },
      { name: "G-Suite", logo: GSuiteIcon },
      { name: "", logo: SalesoftIcon },
      { name: "Outreach", logo: OutreachIcon },
      { name: "Clay", logo: ClayIcon },
      { name: "LaGrowthMachine", logo: GrowthIcon },
      { name: "Apify", logo: ApifyIcon },
      { name: "Smartlead", logo: SmarleadIcon },
      { name: "LinkedIn", logo: LinkedinIcon },
      { name: "Gmail", logo: GmailIcon },
      { name: "ChatGPT", logo: GPTIcon },
      { name: "Contactout", logo: ContactOutIcon },
      { name: "RB2B", logo: RB2BIcon },
      { name: "Sendspark", logo: SendSpark },
    ],
  },
  {
    title: "Who do you sell to?",
    subtitle: "What kinds of customers do you serve?",
    placeholder: "Sales Managers in the United States",
    key: "companyTarget",
    component: Input,
  },
];

const StepComponent = ({
  stepData,
  userInfo,
  handleUserInfo,
}: StepComponentProps) => {
  const {
    key,
    component: Component,
    options,
    placeholder,
    defaultValue,
  } = stepData;

  if (key === "assistYouWith" || key === "tools") {
    return (
      <div
        className={`${key === "tools" ? "flex flex-wrap" : "grid grid-cols-2"} gap-2`}
      >
        {options?.map((item) => (
          <div
            onClick={() => handleUserInfo(key, item.name)}
            key={item.name}
            className={`${
              key === "tools" ? "w-auto" : "w-[320px]"
            } bg-#FFFFFF flex cursor-pointer items-center gap-2 rounded-[8px] border border-[#D0D4DC] px-[24px] py-[12px] text-[14px] font-medium text-[#373F51] ${
              userInfo[key].includes(item.name) &&
              "border-[#BD90F4] bg-[#F5F3FF] text-[#5024DC]"
            }`}
          >
            {item.logo && <img src={item.logo} alt={item.name} />}
            <p>{item.name}</p>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="w-[60%]">
      <Component
        type="text"
        name={key}
        // @ts-ignore
        value={userInfo[key]}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleUserInfo(key, e.target.value)
        }
      />
    </div>
  );
};

const InHouseOnboardingModal = ({
  callFrom,
  token,
  redirectUrl,
  onSubmit,
}: Props) => {
  const navigate = useNavigate();
  const currentUser = userStore((state) => state.currentUser);
  const [step, setStep] = useState(1);
  const [userInfo, setUserInfo] = useState<UserInfoType>({
    firstName: "",
    jobTitle: "",
    companyName: "",
    companyURL: "https://",
    companyDescription: "",
    assistYouWith: [],
    tools: [],
    companyTarget: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toast = useToast();

  const isNextButtonDisabled = () => {
    const {
      firstName,
      jobTitle,
      companyName,
      companyURL,
      assistYouWith,
      tools,
      companyTarget,
      companyDescription,
    } = userInfo;
    switch (step) {
      case 1:
        return !firstName.trim();
      case 2:
        return !jobTitle.trim();
      case 3:
        return !companyName.trim();
      case 4:
        return !companyURL.trim() || companyURL === "https://";
      case 5:
        return !companyDescription.trim();
      case 6:
        return !assistYouWith.length;
      case 7:
        return !tools.length;
      case 8:
        return !companyTarget.trim();
      default:
        return false;
    }
  };

  const handleUserInfo = (key: string, value: string) => {
    setUserInfo((prev) => {
      const handleArrayUpdate = (arr: string[], item: string) =>
        arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

      return {
        ...prev,
        [key]:
          key === "tools" || key === "assistYouWith"
            ? handleArrayUpdate(prev[key], value)
            : value,
      };
    });
  };

  const handleAnswer = async () => {
    setIsSubmitting(true);
    try {
      const url = `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/${
        callFrom === "screen"
          ? `updateProfileInfoByToken/${token}`
          : "updateProfileInfo"
      }`;
      const response = await userService.updateUserProfileData(
        url,
        currentUser?.Email,
        {
          ...userInfo,
          tools: userInfo.tools?.join(","),
          assistYouWith: userInfo.assistYouWith?.join(","),
        },
      );
      if (response?.success) {
        if (callFrom !== "screen") navigate({ to: "/" });
        if (redirectUrl) onSubmit(redirectUrl);
      }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (userInfo.companyURL !== "https://" && userInfo.companyURL) {
      const handleUpdate = async () => {
        if (userInfo.companyDescription) return;
        const response = await userService.generateUserCompanyDescription(
          userInfo.companyURL,
        );
        if (response?.success && response?.data?.updated.description) {
          handleUserInfo(
            "companyDescription",
            response.data.updated.description,
          );
        }
      };
      const timeoutId = setTimeout(handleUpdate, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [userInfo.companyURL]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !isNextButtonDisabled()) {
        if (step === steps.length) {
          handleAnswer();
        } else {
          if (step === 4) {
            if (isValidURL(userInfo.companyURL)) {
              setStep((prev) => prev + 1);
            } else {
              toast({
                title: "Invalid URL",
                description: "Please enter a valid URL",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          } else {
            setStep((prev) => prev + 1);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [step, userInfo]);

  return (
    <div className="z-2 relative m-auto flex min-h-[72vh] max-w-[80%] flex-col items-center justify-center gap-[54px] rounded-[16px] border border-[#D0D4DC] !bg-white px-[100px] py-[36px]">
      <ProgressBar progress={(step / steps.length) * 100} />
      <div className="flex w-[100%] flex-col items-center justify-center gap-[40px]">
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <h3 className="text-[32px] font-medium text-[#0F0D1C]">
            {steps[step - 1].title}
          </h3>
          <p className="text-[14px] text-[#373F51]">
            {steps[step - 1].subtitle}
          </p>
        </div>
        <StepComponent
          stepData={steps[step - 1]}
          userInfo={userInfo}
          handleUserInfo={handleUserInfo}
        />
      </div>
      <ButtonGroup>
        {step !== 1 && (
          <Button
            variant={"outline"}
            className="!text-[16px]"
            leftIcon={<Icons.LeftArrowIcon />}
            onClick={() => setStep((prev) => prev - 1)}
          >
            Back
          </Button>
        )}
        <Button
          className={`!bg-[#C255F1] !text-[16px] !text-white`}
          isDisabled={isNextButtonDisabled()}
          rightIcon={<Icons.RightArrowIcon />}
          isLoading={isSubmitting}
          onClick={
            step === steps.length
              ? handleAnswer
              : () => {
                  if (step === 4) {
                    if (isValidURL(userInfo.companyURL)) {
                      setStep((prev) => prev + 1);
                    } else {
                      toast({
                        title: "Invalid URL",
                        description: "Please enter a valid URL",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  } else {
                    setStep((prev) => prev + 1);
                  }
                }
          }
        >
          {steps?.length === step ? "Submit" : "Next"}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default InHouseOnboardingModal;
