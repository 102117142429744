import { LeadSearchType } from "@/types/leads.types";
import { useDisclosure } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useJobTracker } from "./useTrack";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ANY = any;
export const useImportModelProps = () => {
  const queryClient = useQueryClient();
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");
  const [resultLimit, setResultLimit] = useState(100);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleJobTracking, isTrackingJobChanges } = useJobTracker();

  return {
    isOpen,
    isLoading: isTrackingJobChanges,
    selectedFilters: {
      jobTitle: query,
      resultLimit: resultLimit,
      location,
    } as ANY,
    updateSelectedFilters: (filter: string, val: ANY) => {
      if (filter === "jobTitle") {
        setQuery(val);
      } else if (filter === "location") {
        setLocation(val);
      } else if (filter === "resultLimit") {
        setResultLimit(val);
      }
    },
    setIsOpen: (val: boolean) => {
      setQuery("");
      setLocation("");
      setResultLimit(100);
      if (!val) {
        onClose();
      } else {
        onOpen();
      }
    },
    handleTrack: () => {
      const validatedLimit =
        typeof resultLimit === "number" ? resultLimit : 100;

      handleJobTracking({
        query,
        location,
        resultLimit: validatedLimit,
      }).then(() => {
        queryClient.refetchQueries({
          queryKey: ["lead-search-history", LeadSearchType.COMPANIES_HIRING],
        });
        onClose();
      });
    },
  };
};
