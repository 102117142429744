import GptLogo from "@/assets/svgs/chatGpt.svg";
import APIKeyIntegration from "./APIKeyIntegration";

const OpenAIIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"openAiApiKey"}
      providerTitle="Open AI"
      providerIcon={GptLogo}
    />
  );
};

export default OpenAIIntegration;
