import MakeAPICall from "@/lib/apiCalls";

export type MiscType =
  | "company-types"
  | "industries"
  | "company-sizes"
  | "company-names"
  | "seniorities"
  | "countries";

class MisServices {
  constructor(private apiCaller = MakeAPICall) {}

  async getMiscData<ResponseT>(
    type: string,
    params?: {
      [key: string]: string | undefined;
    },
  ) {
    const apiCall = new this.apiCaller({
      apiPath: `misc/${type}`,
      params,
    });
    const response = await apiCall.get<ResponseT>();
    return response;
  }

  async getDraftEmailTemplate<ResponseT>(payload: {
    firstName?: string;
    companyName?: string;
    companyDescription?: string;
    callToAction?: string;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: `misc/generate-draft-email-template`,
      payload,
    });
    const response = await apiCall.post<ResponseT>();
    return response;
  }

  async getProvidersCreditUsage<ResponseT>() {
    const apiCall = new this.apiCaller({
      apiPath: `misc/email-providers-credits-usage`,
    });
    const response = await apiCall.get<ResponseT>();
    return response;
  }
}

const misServices = new MisServices();

export default misServices;
