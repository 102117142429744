/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnrichmentMetaData } from "@/types/table.types";
import { useEffect, useReducer, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import { convertSlateToText } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";
import WatchTutorial from "@/components/Common/WatchTutorial";
import { NumberInput, NumberInputField } from "@chakra-ui/react";

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const initialState = {
  title: initialSlateValue,
  location: initialSlateValue,
  organization: initialSlateValue,
};

interface EnrichmentDataType extends EnrichmentMetaData {
  payload: typeof initialState;
  limit: number;
}

type State = typeof initialState;

type Action = {
  key: keyof State;
  value: typeof initialSlateValue;
};

function reducer(state: State, action: Action) {
  return { ...state, [action.key]: action.value };
}

const FindActiveJobs = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const [numberOfJobs, setNumberOfJobs] = useState(20);

  const [payloadDetails, dispatch] = useReducer(reducer, initialState);
  const updateDetails = (key: Action["key"], value: Action["value"]) =>
    dispatch({ key, value });

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType | undefined;
      if (enrichmentData?.payload) {
        Object.entries(enrichmentData.payload).forEach(([key, value]) => {
          dispatch({ key: key as keyof State, value });
        });
      }
      if (enrichmentData?.limit) {
        setNumberOfJobs(enrichmentData?.limit || 20);
      }
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={"Watch the tutorial to learn how to find active jobs."}
          link={""}
        />
        <div className="my-3">
          <p className="pb-1.5 text-[0.95rem] font-medium">
            <sup>*</sup>Job title{" "}
          </p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={payloadDetails.title}
            setSlateValue={(value: any) => updateDetails("title", value)}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>
        <div className="my-3">
          <p className="pb-1.5 text-[0.95rem] font-medium">Location</p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={payloadDetails.location}
            setSlateValue={(value: any) => updateDetails("location", value)}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>
        <div className="my-3">
          <p className="pb-1.5 text-[0.95rem] font-medium">Organization Name</p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={payloadDetails.organization}
            setSlateValue={(value: any) => updateDetails("organization", value)}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>
        <div className="my-3">
          <p className="pb-1.5 text-[0.95rem] font-medium">
            Number of jobs to fetch
          </p>
          <NumberInput
            min={0}
            max={100}
            value={numberOfJobs}
            onChange={(val) => {
              setNumberOfJobs(Number(val));
            }}
          >
            <NumberInputField />
          </NumberInput>
          <p className="text-xs font-title font-medium tracking-wide pt-0.5 pl-0.5 text-darkTextGray ml-2">
            Max limit is 100
          </p>
        </div>

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!convertSlateToText(payloadDetails.title).text}
        apiPayload={{
          payload: payloadDetails,
          limit: numberOfJobs,
        }}
      />
    </>
  );
};

export default FindActiveJobs;
