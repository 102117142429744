import { useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";

import { details } from "./constants";
import { preselectEnrichmentState, updateInitialColumn } from "@/utils";

import { useEnrichStore } from "@/stores/enrich.store";

import { TSelectedColumnOption } from "@/types/common.types";

const Formalizer = () => {
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const id = selectedEnrichments?.id || "companyFormalizer";
  // @ts-ignore
  const formalizerDetails = details[id];

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    } else {
      updateInitialColumn("full name", setSelectedOption);
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <WatchTutorial subText={formalizerDetails?.subText || ""} link={""} />
        <p className="pb-1 text-base font-semibold">
          {formalizerDetails?.inputTitle}
        </p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
          formalizerType: formalizerDetails?.formalizerType,
        }}
      />
    </>
  );
};

export default Formalizer;
