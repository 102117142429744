import {
  RowDragEvent,
  RowDropZoneParams,
  SelectionChangedEvent,
} from "ag-grid-community";
import { useEffect } from "react";
import { TRow, TRowId } from "../../types";
import { getMoveTarget } from "../../utils";
import { useTableState } from "../useTableState.store";
import { ROOT_FOLDER_ID } from "../../constants";

export const useDragRows = (
  moveFolderItem: (props: {
    sourceType: "folder" | "table";
    sourceId: TRowId;
    targetId: TRowId;
  }) => void,
) => {
  const gridApi = useTableState((state) => state.gridApi);
  const updateState = useTableState((state) => state.updateState);
  const OnDropToRoot = (e: RowDragEvent<TRow>) => {
    const source = e.node.data;
    if (!source) return;
    if (source.parentFolder?._id === ROOT_FOLDER_ID) return;
    moveFolderItem({
      sourceType: source.type,
      sourceId: source._id,
      targetId: ROOT_FOLDER_ID,
    });
  };
  const OnFolderItemDrop = (e: RowDragEvent<TRow>) => {
    e.nodes.forEach((node) => {
      node.setSelected(false);
      const source = node.data;
      const moveTarget = getMoveTarget(node, e.overNode || null);
      if (!moveTarget || !source) return;

      moveFolderItem({
        sourceType: source.type,
        sourceId: source._id,
        targetId: moveTarget._id,
      });
    });
  };
  useEffect(() => {
    if (!gridApi) return;
    gridApi.addEventListener("rowDragEnd", OnFolderItemDrop);
    const bodyDropZone: RowDropZoneParams = {
      getContainer: () => document.body.querySelector("#root")!,
      onDragStop: OnDropToRoot,
    };
    gridApi.addRowDropZone(bodyDropZone);
    return () => {
      if (gridApi.isDestroyed()) return;
      gridApi.removeRowDropZone(bodyDropZone);
    };
  }, [gridApi]);

  useEffect(() => {
    if (!gridApi) return;
    const handleSelection = (e: SelectionChangedEvent<TRow>) => {
      const selectedNodes = e.api.getSelectedNodes();
      updateState({
        selected: new Set(
          selectedNodes.map((node) => node.data?._id) as TRowId[],
        ),
      });
    };
    gridApi.addEventListener("selectionChanged", handleSelection);
    return () => {
      if (gridApi.isDestroyed()) return;
      gridApi.removeEventListener("selectionChanged", handleSelection);
    };
  }, [gridApi]);

  useEffect(() => {
    const root = document.querySelector(".ag-root-wrapper");
    if (!gridApi || !root) return;
    const HandleEscape = (e: Event) => {
      // @ts-expect-error ignored
      if (e.key === "Escape") {
        gridApi.deselectAll();
      }
    };
    root.addEventListener("keydown", HandleEscape);
    return () => {
      root.removeEventListener("keydown", HandleEscape);
    };
  }, [gridApi]);
};
