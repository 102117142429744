import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { createSelectionStore, SelectionContext } from "./useSelectionStore";
import Overlay from "./Overlay";
import { GridReadyEvent } from "ag-grid-community";
import { CopySelect } from "./components/CopySelect";
import { PasteSelect } from "./components/PastSelect";
import { DeleteSelect } from "./components/DeleteSelect";
type SelectProps = {
  children: ReactNode;
  enableCopyShortcut?: boolean;
  enablePasteShortcut?: boolean;
  enableDeleteShortcut?: boolean;
  enableOverlayActions?: boolean;
  enableSelectAll?: boolean;
};
const isAggGridChild = (
  child: ANY,
): child is ReactElement<
  AgGridReactProps,
  React.JSXElementConstructor<AgGridReact>
> => child?.type === AgGridReact;
export const SelectableContext = (props: SelectProps) => {
  const store = useMemo(() => createSelectionStore(), []);
  const handleGridReady = (params: GridReadyEvent) => {
    store.setState({ gridApi: params.api });
  };
  const findAndModifyAgGridChild = (children: ReactNode): ReactNode => {
    const childrenArray = React.Children.toArray(children);

    return childrenArray.map((child) => {
      if (isAggGridChild(child)) {
        const existingOnGridReady = child.props.onGridReady;
        const newOnGridReady = (params: GridReadyEvent) => {
          if (existingOnGridReady) existingOnGridReady(params);
          handleGridReady(params);
        };

        return React.cloneElement(child, {
          ...child.props,
          onGridReady: newOnGridReady,
        } as AgGridReactProps);
      } else if (React.isValidElement(child) && child.props?.children) {
        return React.cloneElement(child, {
          ...child.props,
          children: findAndModifyAgGridChild(child.props.children),
        });
      }
      return child;
    });
  };

  const modifiedChildren = findAndModifyAgGridChild(props.children);

  return (
    <SelectionContext.Provider value={store}>
      {modifiedChildren}
      {props.enableOverlayActions && <Overlay />}
      {props.enableCopyShortcut && <CopySelect />}
      {props.enableSelectAll && <PasteSelect />}
      {props.enableDeleteShortcut && <DeleteSelect />}
    </SelectionContext.Provider>
  );
};
