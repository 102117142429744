const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className="w-[60%] bg-[#F5F3FF] rounded-[6px] h-[14px]">
      <div
        className="h-[14px] rounded-[6px]"
        style={{
          width: `${progress}%`,
          background:
            "linear-gradient(124.15deg, #C870F1 43.48%, #904CE7 65.32%, #5024DC 90.27%)",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
