import { useState } from "react";
import { Button } from "@chakra-ui/react";

import SaveCustomPreset from "../../Common/SaveCustomPreset";
import { usePresetsStore } from "@/stores/presets.store";

const NewCustomPreset = () => {
  const [isOpenCustomPresetModal, setIsOpenCustomPresetModal] = useState(false);
  const updateState = usePresetsStore((state) => state.updateState);

  const toggleCustomPresetModal = () => {
    setIsOpenCustomPresetModal((prevState) => !prevState);
    updateState({
      presetsModalData: {
        modalType: "sales-emails",
      },
    });
  };

  return (
    <>
      <div className="mt-4">
        <Button size="xs" onClick={toggleCustomPresetModal}>
          Save Custom Preset
        </Button>
      </div>
      {isOpenCustomPresetModal && (
        <SaveCustomPreset
          isModalOpen={isOpenCustomPresetModal}
          handleClose={toggleCustomPresetModal}
          title="Generate Sales Emails Presets"
        />
      )}
    </>
  );
};

export default NewCustomPreset;
