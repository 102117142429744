import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SlideFade,
} from "@chakra-ui/react";

import { tableActionIcons } from "@/components/Icons/tableActionIcons";
import { TSelectedColumnOption } from "@/types/common.types";
import { CellsArr, Column } from "@/types/table.types";

interface Props {
  isNoInput: boolean;
  isSelectMenuOpen: boolean;
  columns: Column[];
  setSelectedOption:
    | React.Dispatch<React.SetStateAction<TSelectedColumnOption | null>>
    | ((value: TSelectedColumnOption) => void);
  clearState: () => void;
  rowsObjData: CellsArr;
  selectedOption: TSelectedColumnOption | null;
  inputContainerRef: React.RefObject<HTMLDivElement>;
  notInportal?: boolean;
  hideInnerContent: boolean;
}

const RenderOptions = ({
  isNoInput,
  isSelectMenuOpen,
  columns = [],
  setSelectedOption,
  clearState,
  rowsObjData,
  selectedOption,
  inputContainerRef,
  notInportal = false,
  hideInnerContent,
}: Props) => {
  return (
    <RenderDropdownOptions
      inPortal={notInportal ? false : isNoInput ? false : true}
      position={{
        width: inputContainerRef?.current?.getBoundingClientRect()?.width,
        bottom: inputContainerRef?.current?.getBoundingClientRect()?.bottom,
        left: inputContainerRef?.current?.getBoundingClientRect()?.left,
        right: inputContainerRef?.current?.getBoundingClientRect()?.right,
      }}
    >
      <SlideFade
        in={isNoInput ? true : isSelectMenuOpen}
        offsetY="20px"
        style={{
          position: "relative",
          zIndex: 99999,
          width: "100%",
          height: isSelectMenuOpen ? "fit-content" : "0px",
        }}
      >
        <div
          className={`z-[9999] w-full rounded-b ${
            isSelectMenuOpen
              ? "mb-[-13rem] h-[13rem]"
              : isNoInput
                ? "h-[13rem]"
                : "mb-0 h-0"
          } ${
            !isNoInput ? "border-t-0" : "min-w-[17rem]"
          } border border-primary bg-white ${
            isSelectMenuOpen || isNoInput
              ? "pointer-events-auto"
              : "pointer-events-none"
          } overflow-y-auto`}
        >
          {!isNoInput && (
            <p className="px-3 py-1.5 font-semibold text-[#676d7e]">All</p>
          )}
          {isNoInput || isSelectMenuOpen ? (
            <div className="h-fit w-full space-y-0">
              {columns.map((column, index) => {
                const OptionIcon =
                  tableActionIcons[column.metaData?.iconType || "text"];

                return (
                  <Box
                    key={`${column._id}-render-options`}
                    pt={1}
                    cursor={"pointer"}
                    onMouseDown={() => {
                      setSelectedOption({
                        key: column.name,
                        keyId: column._id,
                        iconType: column.metaData?.iconType || "text",
                      });

                      clearState();
                    }}
                  >
                    <Popover
                      trigger="hover"
                      key={index}
                      boundary="clippingParents"
                      placement="left-start"
                      preventOverflow
                    >
                      {({ isOpen }) => (
                        <>
                          <PopoverTrigger>
                            <div>
                              <p
                                className={`options-to-selects flex w-full items-center gap-x-2 px-3 py-1.5 text-left text-sm font-medium duration-500 hover:bg-primary/5 ${
                                  isOpen
                                    ? "bg-primary/5"
                                    : "bg-transparent hover:bg-primary/5"
                                } ${
                                  selectedOption?.keyId === column._id
                                    ? "bg-lightGreen"
                                    : ""
                                }`}
                              >
                                <span>
                                  {!!OptionIcon && (
                                    <OptionIcon className="h-auto w-4 text-[#676d7e]" />
                                  )}
                                </span>
                                <span>{column.name}</span>
                              </p>
                            </div>
                          </PopoverTrigger>

                          {!hideInnerContent && (
                            <PopoverInnerContent
                              inPortal={
                                notInportal ? false : isNoInput ? false : true
                              }
                            >
                              <div className="flex size-full flex-col gap-y-2 overflow-y-auto">
                                <PopoverContent
                                  sx={{
                                    width: "fit-content",
                                    padding: "4px",
                                    maxWidth: "27rem",
                                    maxHeight: "fit-content",
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                  }}
                                >
                                  <PopoverArrow />
                                  <PopoverBody
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "4px",
                                    }}
                                    className="options-to-select"
                                    onMouseDown={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      setSelectedOption({
                                        key: column.name,
                                        keyId: column._id,
                                        iconType:
                                          column.metaData?.iconType || "text",
                                      });

                                      clearState();
                                    }}
                                  >
                                    {(rowsObjData[column._id] || []).map(
                                      (row, index, array) => (
                                        <p
                                          className={`options-to-select border-gray-300 flex items-center gap-x-5 py-1 text-sm font-medium text-[#1D2026] ${
                                            array.length - 1 === index
                                              ? "border-b-0"
                                              : "border-b"
                                          } truncate`}
                                          key={`${index}-render-options-${Math.random()}`}
                                        >
                                          <span>{index + 1}</span>{" "}
                                          {/* <span>{row.value || "Empty Cell"}</span> */}
                                          <span>
                                            {typeof row.value === "object"
                                              ? JSON.stringify(row.value)
                                              : row.value || "Empty Cell"}
                                          </span>
                                        </p>
                                      ),
                                    )}
                                  </PopoverBody>
                                </PopoverContent>
                              </div>
                            </PopoverInnerContent>
                          )}
                        </>
                      )}
                    </Popover>
                  </Box>
                );
              })}
            </div>
          ) : null}
        </div>
      </SlideFade>
    </RenderDropdownOptions>
  );
};

export default RenderOptions;

const PopoverInnerContent = ({
  inPortal,
  children,
}: {
  inPortal: boolean;
  children: React.ReactNode;
}) => {
  return <>{inPortal ? <Portal>{children}</Portal> : children}</>;
};

const RenderDropdownOptions = ({
  children,
  inPortal,
  position,
}: {
  children: React.ReactNode;
  inPortal: boolean;
  position: any;
}) => {
  return (
    <>
      {inPortal ? (
        <Portal>
          <div
            className="absolute z-[9999]"
            style={{
              width: position?.width,
              top: position?.bottom,
              left: position?.left,
              right: position?.right,
            }}
          >
            {children}
          </div>
        </Portal>
      ) : (
        children
      )}
    </>
  );
};
