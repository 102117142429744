export const RESPONSE_COLUMNS = [
  {
    isChecked: false,
    label: "Type",
    id: "type",
  },
  {
    isChecked: false,
    label: "Display",
    id: "display",
  },
  {
    isChecked: false,
    label: "Display International",
    id: "displayInternation",
  },
  {
    isChecked: false,
    label: "Valid Since",
    id: "validSince",
  },
  {
    isChecked: false,
    label: "Last Seen",
    id: "lastSeen",
  },
  {
    isChecked: false,
    label: "Country Code",
    id: "countryCode",
  },
  
];
