import AutoPilotLeadsWorkflow from "@/components/AutoPilotLeadsWorkflow";
import { userStore } from "@/stores/user.store";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/auto-pilot/leads-workflow").createRoute({
  component: LeadsWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function LeadsWorkflowPage() {
  const currentUser = userStore((state) => state.currentUser);
  const isAdmin = currentUser.role === "admin";

  if (!isAdmin) {
    return <div>Not Found</div>;
  }

  return <AutoPilotLeadsWorkflow />;
}

export default LeadsWorkflowPage;
