import { Fragment, useCallback, useState } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import Icons from "../Icons";

import leadService from "@/services/leads.service";
import { useSearchLeadsStore } from "@/stores/searchleads.store";

type Props = {
  currentSearchPayload: any;
};

const services = [
  {
    apiPath: "leads/search-company-linkedin",
    service: leadService.searchCompanyIscraper,
  },
  {
    apiPath: "leads/search-people",
    service: leadService.searchPeople,
  },
];

const PeopleSearchResultTableActions = ({ currentSearchPayload }: Props) => {
  const [isAddingToTable, setIsAddingToTable] = useState<boolean>(false);

  const navigate = useNavigate();
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );
  const updatedLeadState = useSearchLeadsStore((state) => state.updateState);
  const { mutateAsync } = useMutation({
    mutationFn: ({ wantToImport }: { wantToImport: number }) => {
      const payload = {
        searchPayload: currentSearchPayload,
        fetchedLeads: searchLeadsResult,
        wantToImport,
      };
      const service = services.find(
        (service) =>
          service.apiPath === searchLeadsResult?.apiPath?.split("?")[0],
      )?.service;
      // @ts-ignore
      return service!(payload, searchLeadsResult?.apiPath);
    },
  });

  const handleImportLeads = async (wantToImport: number) => {
    setIsAddingToTable(true);

    await mutateAsync(
      { wantToImport },
      {
        onSuccess: (response: any) => {
          if (!response?.data?.tableData) return;

          navigate({
            to: `/table/$tableId`,
            params: {
              tableId: response?.data?.tableData._id!,
            },
          });

          updatedLeadState({
            searchLeadsResult: null,
            searchLeadsColumnNames: [],
            searchLeadsResultRows: [],
          });
          setIsAddingToTable(false);
        },
        onError: (error) => {
          console.log(error);
          setIsAddingToTable(false);
        },
      },
    );
  };

  const generateComponents = useCallback((maxValue: number) => {
    const components = [];
    const step = 100;

    for (let i = step; i <= maxValue && i <= 1000; i += step) {
      components.push(
        <Fragment key={`${i}-${Math.random()}`}>
          <MenuItem
            minH="25px"
            fontSize={"15.5px"}
            onClick={() => handleImportLeads(i)}
          >
            <span>{`Import ${i} leads`}</span>
          </MenuItem>
          {i < maxValue && <MenuDivider marginTop={1} marginBottom={1} />}
        </Fragment>,
      );
    }

    if (maxValue % step !== 0 && maxValue <= 1000) {
      components.push(
        <MenuItem
          key={maxValue}
          minH="25px"
          fontSize={"15.5px"}
          onClick={() => handleImportLeads(maxValue)}
        >
          <span>{`Import ${maxValue} leads`}</span>
        </MenuItem>,
      );
    }

    return components;
  }, []);

  return (
    <div className="flex shrink-0 justify-end">
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<Icons.ChevronDownIcon />}
          isLoading={isAddingToTable}
          loadingText="Importing..."
          variant="outline"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className="!w-fit !rounded-md !bg-primaryPink !px-4 !py-1.5 !font-title !text-sm !font-semibold !tracking-wide !text-white"
        >
          Import leads to table
        </MenuButton>
        <MenuList width={"full"}>
          {generateComponents(
            searchLeadsResult?.pagination?.total_entries ?? 0 > 1000
              ? 1000
              : searchLeadsResult?.pagination?.total_entries ?? 0,
          )}
        </MenuList>
      </Menu>
    </div>
  );
};

export default PeopleSearchResultTableActions;
