import { Box, Text, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import type { ICellRendererParams } from "ag-grid-community";
import { useCallback, useMemo } from "react";

import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";
import Icons from "../Icons";

import { queryClient } from "@/lib/queryClient";
import enrichment from "@/services/enrichment.service";
import tableService from "@/services/table.service";
import { useModalStore } from "@/stores/modal.store";
import { useProcessingStore } from "@/stores/processing.store";
import { useTableStore } from "@/stores/table.store";
import {
  EnrichmentApiCallPayload,
  TableQueueReRunPayload,
} from "@/types/enrichment.types";
import { checkErrorOrLoading, isUrl, processCellValue } from "@/utils";

const CustomCell = (props: ICellRendererParams) => {
  const toast = useToast();
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const isUserLoggedIn = queryClient.getQueryData(["user-details"]);
  const updateState = useTableStore((state) => state.updateState);
  const handleCellClick = useTableStore((state) => state.handleCellClick);
  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);
  const tableRows = useTableStore((state) => state.rowsData);
  const rowData = props?.data?.rowData;
  const row = useMemo(() => {
    return tableRows.find((item) => item._id === rowData?._id);
  }, [tableRows, rowData?._id]);
  const columnDef = props.column?.getColDef();
  const columnId = props.column?.getColId() || "";
  const cellData = useMemo(() => {
    return {
      ...row?.cells[columnId],
      value: (props.value?.value || props.value) ?? "",
    };
  }, [row, columnId, props.value]);
  const tableData = useTableStore((state) => state.tableData);
  const processingData = useProcessingStore((state) => state.processingData);
  const { updateState: updateModalState } = useModalStore((state) => state);

  const isAutoRunEnabled = tableData.metaData?.isEnableAutoRun;

  const {
    isTableQueueColumn,
    columnEvent,
    cellValue,
    isLink,
    isErrorOrLoading,
    isFalsy,
    enumColor,
    isQueuedCell,
  } = useMemo(() => {
    const isTableQueueColumn =
      columnDef?.headerComponentParams?.columnData?.metaData?.queueType ===
      "tableQueue";
    const columnEvent =
      columnDef?.headerComponentParams?.columnData?.metaData?.event;
    let cellValue = processCellValue(cellData);
    const isLink = isUrl(cellValue);
    const isQueuedCell = cellValue?.includes("queued");
    const isErrorOrLoading = checkErrorOrLoading(cellData);
    const isBoolean =
      columnDef?.headerComponentParams?.columnData?.metaData?.inputType ===
      "boolean";
    const isFalsy =
      (isBoolean && cellValue?.toString().toLowerCase() === "false") ||
      cellValue?.toString().toLowerCase() === "no";
    const isTruthy =
      (isBoolean && cellValue?.toString().toLowerCase() === "true") ||
      cellValue?.toString().toLowerCase() === "yes";
    cellValue = isBoolean
      ? isFalsy
        ? "NO"
        : isTruthy
          ? "YES"
          : cellValue
      : cellValue;
    const isEnum = ["enumSingle", "enumMulti"].includes(
      columnDef?.headerComponentParams?.columnData?.metaData?.inputType,
    );
    const options =
      columnDef?.headerComponentParams?.columnData?.metaData?.options;
    const enumColor =
      isEnum && !isErrorOrLoading
        ? options?.find((item: ANY) => item.value === cellValue)?.color
        : "";

    return {
      isTableQueueColumn,
      columnEvent,
      cellValue,
      isLink,
      isErrorOrLoading,
      isBoolean,
      isFalsy,
      isTruthy,
      isEnum,
      options,
      enumColor,
      isQueuedCell,
    };
  }, [columnDef, cellData]);

  const isAnyRunningProcess =
    (
      processingData?.filter((data) => data.processingColumnId === columnId) ||
      []
    ).length > 0;

  const { mutateAsync: enrichMutationAsync, isPending: isReRunning } =
    useMutation({
      mutationFn: (payload: Partial<EnrichmentApiCallPayload>) =>
        enrichment.callEnrichmentApi(payload),
    });

  const { mutateAsync: callToReRunTableQueue } = useMutation({
    mutationFn: (payload: TableQueueReRunPayload) =>
      tableService.callToReRunColumn(payload),
  });

  const getImgUrl = () => {
    const imageUrl = cellData?.imgURL;
    return (
      imageUrl ||
      columnDef?.headerComponentParams?.columnData?.metaData?.iconImg
    );
  };

  const handleFullView = useCallback(() => {
    updateState({
      expendedCellValue: {
        isOpen: true,
        columnName: columnDef?.headerName || "",
        columnId: props.column?.getColId() || "",
        rowId: props?.data?.rowData?._id || "",
        cellValue: props.value || "",
        rowIndex: props.node.rowIndex ?? undefined,
        showSendOption:
          columnDef?.headerComponentParams?.columnData?.metaData
            ?.enrichmentMetaData?.enrichmentName === "draftEmail" &&
          columnDef?.headerComponentParams?.columnData?.metaData
            ?.enrichmentMetaData?.showSendOption,
      },
    });
  }, [props, isTablePreviewPage]);

  const handleInfoClick = useCallback(() => {
    updateModalState({
      isOpenModal: true,
      modalData: {
        modalType: "table-details",
        metaData: {
          rowIndex: props.node.rowIndex ?? undefined,
        },
      },
    });
  }, [props, isTablePreviewPage]);

  const rerunCell = async (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.stopPropagation();

    if (isTableQueueColumn) {
      const response = await callToReRunTableQueue({
        tableId: tableData._id,
        columnId,
        payload: {
          action: "selected",
          selectedRowIds: [rowData?._id],
          ...columnDef?.headerComponentParams?.columnData?.metaData,
        },
      });

      invalidateQueriesAndUpdateState(response?.data?.dependentColumnIds || []);
      return;
    }

    const payLoad: Partial<EnrichmentApiCallPayload> = {
      ...columnDef?.headerComponentParams?.columnData?.metaData
        ?.enrichmentMetaData,
      reRunColumnId: columnId,
      selectedRowsId: [rowData?._id],
      isSelectedAll: false,
      totalDataToProcess: 1,
      action: "selected",
      tableId: tableData._id,
    };
    updateTableMetaDta({
      isProcessing: true,
      totalDataToProcess: 1,
      processingMessage: "",
    });
    await enrichMutationAsync(payLoad, {
      onSuccess: (response) => {
        if (!response.success) {
          toast({
            title:
              response?.error?.message ||
              response?.data?.message ||
              "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          updateTableMetaDta({
            isProcessing: false,
            totalDataToProcess: 0,
            processedData: 0,
          });
          return;
        }

        invalidateQueriesAndUpdateState(response?.data?.dependentColumnIds);
      },
      onError: (err) => {
        toast({
          title: err.message || "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        updateTableMetaDta({
          isProcessing: false,
          totalDataToProcess: 0,
          processedData: 0,
        });
      },
    });
  };

  const invalidateQueriesAndUpdateState = (dependentColumnIds?: string[]) => {
    queryClient.refetchQueries({
      queryKey: ["table-running-processes", tableData._id],
    });

    const updatedRows = tableRows.map((row) => {
      const newRow = { ...row };

      if (rowData?._id === row._id) {
        newRow.cells = {
          ...newRow.cells,
          [columnId]: {
            value: "queued...",
          },
        };
      }

      if (row._id === rowData?._id) {
        if (
          isAutoRunEnabled &&
          dependentColumnIds &&
          dependentColumnIds.length
        ) {
          dependentColumnIds?.forEach((columnId: string) => {
            if (!newRow.cells[columnId]) {
              console.log("adding new cell", columnId);
              newRow.cells[columnId] = {
                value: "queued...",
              };
            } else {
              newRow.cells[columnId].value = "queued...";
            }
          });
        }
      }

      return newRow;
    });

    updateState({
      tableData: {
        ...tableData,
        metaData: {
          isProcessing: true,
          processedData: 0,
          totalDataToProcess: 1,
        },
      },
      rowsData: updatedRows,
      selectedRowsId: [],
    });
  };

  const imgURL = getImgUrl();

  return (
    <>
      <div
        className={`group flex size-full items-center justify-between px-[15px] font-normal ${
          (columnEvent === "modal" && !isTablePreviewPage) ||
          (columnDef?.headerComponentParams?.columnData?.metaData
            ?.isIndexColumn &&
            !isTablePreviewPage &&
            !isAnyRunningProcess)
            ? "cursor-pointer"
            : ""
        }`}
        onContextMenu={(event) => {
          if (!isTablePreviewPage) {
            event.preventDefault();
            const { clientX, clientY } = event;
            updateState({
              contextMenuState: {
                x: clientX,
                y: clientY,
                metaData: {
                  rowId: props?.data?.rowData?._id,
                },
              },
            });
          }
        }}
        onClick={() => {
          if (columnEvent === "modal" && !isTablePreviewPage) {
            handleCellClick(
              columnDef?.headerComponentParams?.columnData,
              rowData,
            );
          }
          if (
            columnDef?.headerComponentParams?.columnData?.metaData
              ?.isIndexColumn &&
            columnEvent !== "modal" &&
            !isTablePreviewPage &&
            !isAnyRunningProcess
          )
            handleInfoClick();
        }}
        style={
          columnDef?.headerComponentParams?.columnData?.metaData?.highlightColor
            ? {
                backgroundColor:
                  columnDef?.headerComponentParams?.columnData?.metaData
                    ?.highlightColor || "#fff",
              }
            : {}
        }
      >
        {isLink && (
          <Icons.ExternalLinkIcon
            className="mr-1 shrink-0 cursor-pointer text-primary"
            onClick={() => {
              let url = cellValue;
              if (
                !cellValue.startsWith("http://") &&
                !cellValue.startsWith("https://")
              ) {
                url = "http://" + cellValue;
              }

              window.open(url, "_blank");
            }}
          />
        )}
        {!!imgURL && cellValue && !isQueuedCell && (
          <img src={imgURL} className="mr-1 size-[1em]" />
        )}
        <Box
          className={`ml-1 w-[90%] truncate font-[300] ${
            isLink ? " !text-[gray] group-hover:underline" : "text-black"
          } ${isErrorOrLoading && "italic !text-[#a89e9e]"} ${isTablePreviewPage && (props.node.rowIndex ?? -1) > 9 && !isUserLoggedIn && "blur-sm"}
          ${isFalsy ? "!text-[#000000a3]" : ""}
          `}
        >
          {enumColor ? (
            <Text
              bg={enumColor}
              className={"w-[min-content] rounded-full px-2 py-1 leading-[1em]"}
            >
              {cellValue}
            </Text>
          ) : (
            cellValue
          )}
        </Box>
        {columnDef?.headerComponentParams?.columnData?.metaData
          ?.isIndexColumn &&
          !isTablePreviewPage &&
          !isAnyRunningProcess && (
            <Icons.OpenEyeIcon
              className="shrink-0 opacity-[0.3] group-hover:opacity-100"
              size={20}
            />
          )}
        {!columnDef?.headerComponentParams?.columnData?.metaData
          ?.isIndexColumn &&
          columnEvent !== "modal" &&
          !isTablePreviewPage &&
          !isAnyRunningProcess && (
            <Icons.FullscreenIcon
              className="hidden shrink-0 cursor-pointer group-hover:inline-block"
              onClick={(e: any) => {
                e.stopPropagation();
                handleFullView();
              }}
              size={20}
            />
          )}
        {(columnDef?.headerComponentParams?.columnData?.metaData?.isShowReRun ||
          isTableQueueColumn) &&
          !isTablePreviewPage &&
          !isAnyRunningProcess &&
          !isQueuedCell && (
            <Icons.PlayIcon
              className={` ml-2 hidden shrink-0 cursor-pointer text-[#693DC7] group-hover:inline-block ${isReRunning ? "animate-pulse cursor-not-allowed" : ""}`}
              size={18}
              onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) =>
                isReRunning ? null : rerunCell(e)
              }
            />
          )}
        {columnEvent === "modal" && !isTablePreviewPage && (
          <Icons.ArrowDownIcon
            className="shrink-0 cursor-pointer text-darkTextGray !text-xs"
            size={20}
          />
        )}
      </div>
    </>
  );
};

const WrappedCustomCell = (props: ICellRendererParams) => (
  <CustomErrorBoundary>
    <CustomCell {...props} />
  </CustomErrorBoundary>
);

export default WrappedCustomCell;
