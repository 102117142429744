import Swal from "sweetalert2";
import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import SalesforceLogo from "@/assets/images/salesforce.png";
import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";
import { userStore } from "@/stores/user.store";

const SalesforceIntegration = () => {
  const toast = useToast();
  const { data: providerList, refetch, isLoading } = useProviderList();
  const salesforceProvider = providerList?.data?.find(
    (provider) => provider.name === "salesforce",
  );

  const currentUser = userStore((state) => state.currentUser);

  const isSalesforceConnected = salesforceProvider?.metaData;

  const handleIntegration = async () => {
    if (currentUser?.planType?.toLocaleLowerCase() === "free") {
      Swal.fire({
        title: "Warning!",
        icon: "warning",
        html:
          "Please contact " +
          '<b><a href="mailto:support@persana.ai">support@persana.ai</a></b> ' +
          "to upgrade your plan",
        focusConfirm: true,
      });
    } else {
      providerService.openSalesforceOAuth();
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      providerService.deleteProvider({
        providerId: salesforceProvider?._id || "",
        providerName: "salesforce",
      }),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        refetch();
        toast({
          title: response?.data?.message || "Disconnected successfully",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={SalesforceLogo} alt={"Salesforce"} className="w-[2rem]" />
        <h2 className="font-semibold">Salesforce</h2>
      </div>
      <div>
        <p className="mb-4">{`Connect your Salesforce Account`}</p>
        <Button
          colorScheme={isSalesforceConnected ? "red" : "green"}
          variant="outline"
          size="sm"
          isLoading={isPending || isLoading}
          onClick={() => {
            if (isSalesforceConnected) {
              mutateAsync();
            } else {
              handleIntegration();
            }
          }}
        >
          {isSalesforceConnected ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default SalesforceIntegration;
