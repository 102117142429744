import React from "react";

import CustomAccordion from "@/components/Common/CustomAccordion";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { companyNewsCategories } from "@/utils/constants";
import type { Option } from "@/components/Common/MultiSelectDropdown/types";

interface Props {
  updateSelectedFilters: (key: string, value: Option[]) => void;
  selectedFilters: {
    categories: Option[];
    newsTitles: Option[];
    newsDescriptions: Option[];
  };
}

const OtherOptions = ({ updateSelectedFilters, selectedFilters }: Props) => {
  return (
    <div className="mt-10">
      <CustomAccordion
        title="Filter News by selected categories (optional)"
        titleClassName="font-title text-sm font-medium"
      >
        <MultiSelectDropdown
          options={companyNewsCategories}
          onChange={(selectedValues) => {
            updateSelectedFilters("categories", selectedValues as Option[]);
          }}
          value={selectedFilters.categories}
        />
        <p className="text-xs font-title font-medium tracking-wide pt-0.5 pl-0.5 text-darkTextGray">
          Filter news by selected categories
        </p>
      </CustomAccordion>
      <CustomAccordion
        title="Filter for news titles (optional)"
        titleClassName="font-title text-sm font-medium"
      >
        <MultiSelectDropdown
          value={selectedFilters.newsTitles}
          options={[]}
          onChange={(selectedValues) => {
            updateSelectedFilters("newsTitles", selectedValues as Option[]);
          }}
        />
        <p className="text-xs font-title font-medium tracking-wide pt-0.5 pl-0.5 text-darkTextGray">
          Filter for specific words or phrases in job news (e.g. "Engineer,
          Sales, Accountant"). This does not accept AND or ORs.
        </p>
      </CustomAccordion>
      <CustomAccordion
        title="Filter for news descripton (optional)"
        titleClassName="font-title text-sm font-medium"
      >
        <MultiSelectDropdown
          options={[]}
          onChange={(selectedValues) => {
            updateSelectedFilters("newsDescriptions", selectedValues as Option[]);
          }}
          value={selectedFilters.newsDescriptions}
        />
        <p className="text-xs font-title font-medium tracking-wide pt-0.5 pl-0.5 text-darkTextGray">
          Filter for specific words or phrases in news descripton (e.g. "Hubspot,
          Smartleads, Salesforce"). This does not accept AND or ORs.
        </p>
      </CustomAccordion>
    </div>
  );
};

export default React.memo(OtherOptions);
