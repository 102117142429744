import PersanaAccordion from "@/components/Common/PersanaAccordion";
import CustomTextEditor from "./CustomTextEditor";
import { useEnrichStore } from "@/stores/enrich.store";
import Icons from "@/components/Icons";

function ConditionalFormula() {
  const runConditionFormula = useEnrichStore(
    (state) => state.runConditionFormula,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const handleUseAi = () => {
    updateState({
      generateFormulaModal: {
        isOpen: true,
        type: "condition",
      },
    });
  };

  return (
    <div className="pt-4">
      <PersanaAccordion
        btnStyle={{
          backgroundColor: "rgb(250 250 251)",
          borderRadius: "6px",
        }}
        items={[
          {
            button: (
              <button className="w-full truncate text-left font-semibold text-[#000000]">
                Conditional Run
              </button>
            ),

            panel: (
              <div className="space-y-1">
                <p className="text-[0.95rem] font-medium">
                  Conditional Formula
                </p>
                <CustomTextEditor
                  editorHeight={"5rem"}
                  isCodeView
                  slateValue={runConditionFormula}
                  placeholder={`"Hi " + {{ Name }}.`}
                  setSlateValue={(value: any) => {
                    updateState({ runConditionFormula: value });
                  }}
                  isInvalidFormula={false}
                  actionBtn={
                    <button
                      onClick={handleUseAi}
                      className="flex items-center gap-x-1.5 rounded bg-primary px-2.5 py-1 text-sm font-medium text-white"
                    >
                      <Icons.Processor className="inline-block rotate-180" />
                      <span>Use AI</span>
                    </button>
                  }
                />
                <p className="text-xs font-medium text-[#717989]">
                  Add a formula here to make your action only run if the formula
                  resolves to true.{" "}
                </p>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default ConditionalFormula;
