import { ReactNode, useEffect, useRef, useState } from "react";
import useOutsideAlerter from "@/hooks/useOutsideAlerter";

type Props = {
  button: ReactNode;
  children: ReactNode;
  classNames?: string;
  animation?: string;
  closeWrapper?: any;
  containerClassName?: string;
  buttonWrapperClassName?: string;
};

const Dropdown = (props: Props) => {
  const {
    button,
    children,
    classNames,
    animation,
    closeWrapper,
    containerClassName = "",
    buttonWrapperClassName = "",
  } = props;
  const wrapperRef = useRef(null);
  const [openWrapper, setOpenWrapper] = useState(false);
  useOutsideAlerter(wrapperRef, setOpenWrapper);

  useEffect(() => {
    if (closeWrapper) {
      setOpenWrapper(false);
    }
  }, [closeWrapper]);

  return (
    <div ref={wrapperRef} className={`relative flex ${containerClassName}`}>
      <div
        className={`flex ${buttonWrapperClassName}`}
        onMouseDown={() => setOpenWrapper(!openWrapper)}
      >
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation
            ? animation
            : "origin-top-right transition-all duration-300 ease-in-out"
        } ${openWrapper ? "scale-100" : "scale-0"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
