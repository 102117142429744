import { FieldInfo } from "@json2csv/plainjs/index.js";

export const SearchableColumns = [
  "firstName",
  "action",
  "linkedinURL",
  "current.companyName",
  "current.position",
  "previous.companyName",
  "previous.position",
  "hubspotId",
  "metaData.location.default",
];

export const ExportColumnsList: Array<string | FieldInfo<object, unknown>> = [
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "Work Email",
    value: "workemail",
  },
  {
    label: "Current Company",
    value: "current.companyName",
  },
  {
    label: "LinkedIn URL",
    value: "linkedinURL",
  },
  {
    label: "Current Position",
    value: "current.position",
  },
  {
    label: "Previous Position",
    value: "previous.position",
  },
  {
    label: "Status",
    value: "action",
  },
  {
    label: "Joined At",
    value: "current.joinedAt",
  },
  {
    label: "Previous Company",
    value: "previous.companyName",
  },
  {
    label: "Original Company",
    value: "originalCompanyToTrack",
  },
  {
    label: "Left At",
    value: "previous.leftAt",
  },
  {
    label: "Origin",
    value: "origin",
  },
  {
    label: "Hubspot ID",
    value: "hubspotId",
  },
  {
    label: "Location",
    value: "metaData.location.default",
  },
];
