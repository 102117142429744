import { Checkbox } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTableStore } from "../hooks/useGridState.store";

/**
 * Needs custom selector for infinite rowModel
 * @returns
 */
export const HeaderCheckboxSelection = () => {
  const gridApi = useTableStore((state) => state.gridApi);
  const isSelectAll = useTableStore((state) => state.isSelectAll);
  const setIsSelectAll = useTableStore((state) => state.setIsSelectAll);
  const selectedRowCount = useTableStore((state) =>
    state.getSelectedRowCount(),
  );
  const totalRowCount = useTableStore((state) => state.totalRows);
  const selectAll = () => {
    setIsSelectAll(true);
    gridApi?.forEachNode((node) => {
      node.setSelected(true);
    });
  };
  const unselectAll = () => {
    setIsSelectAll(false);
    gridApi?.getSelectedNodes().forEach((node) => {
      node.setSelected(false);
    });
  };
  const toggleSelectAll = (val: boolean) => {
    if (val) {
      selectAll();
    } else {
      unselectAll();
    }
    gridApi?.refreshCells({ suppressFlash: false });
  };
  useEffect(() => {
    if (selectedRowCount === totalRowCount) {
      if (selectedRowCount === 0) {
        setIsSelectAll(false);
        return;
      }
    }
  }, [selectedRowCount, totalRowCount]);
  return (
    <Checkbox
      isChecked={isSelectAll}
      disabled={totalRowCount === 0}
      onChange={(e) => toggleSelectAll(e.target.checked)}
      isIndeterminate={!isSelectAll && selectedRowCount > 0}
      className="m-auto place-self-center"
    />
  );
};
