import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";

type ButtonType = React.ReactElement;
type PanelType = React.ReactElement;

interface Item {
  button: ButtonType;
  panel: PanelType;
}

interface PersanaAccordionProps {
  items: Item[];
  btnStyle?: React.CSSProperties;
  defaultIndex?: number[];
}

const PersanaAccordion = ({
  items,
  btnStyle,
  defaultIndex = [],
}: PersanaAccordionProps) => {
  return (
    <Accordion allowMultiple defaultIndex={defaultIndex}>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          rounded={"md"}
          sx={{
            border: "none",
            backgroundColor: "transparent",
            "&:not(:last-child)": {
              marginBottom: "1rem",
            },
          }}
        >
          <AccordionButton
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              borderRadius: "0.3rem",
              _hover: {
                backgroundColor: "#97bde93f",
              },
              ...btnStyle,
            }}
          >
            {item?.button}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>{item?.panel}</AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default PersanaAccordion;
