import { Button } from "@chakra-ui/react";

type Variant =
  | "primary"
  | "secondary"
  | "primary-gradient"
  | "secondary-colored"
  | "success"
  | "error"
  | "white-primary";

type Props = {
  buttonText: string;
  buttonImg?: any;
  onClick: () => void;
  variant: Variant;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const getStyles = (variant: Variant) => {
  switch (variant) {
    case "primary":
      return {
        background: "#AA6BFA",
        border: "1px solid #BD90F4",
        color: "white",
        borderRadius: "6px",
      };
    case "secondary":
      return {
        background: "#F5F6F9",
        border: "1px solid #9EA4B2",
        color: "#373F51",
        borderRadius: "6px",
      };
    case "primary-gradient":
      return {
        background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
        color: "white",
        borderRadius: "6px",
      };
    case "success":
      return {
        background: "#fff",
        border: "1px solid #29CC00",
        color: "#29CC00",
        borderRadius: "6px",
      };
    case "error":
      return {
        background: "#fff",
        border: "1px solid #CC2900",
        color: "#CC2900",
        borderRadius: "6px",
      };
    case "secondary-colored":
      return {
        background: "#F5F3FF",
        border: "1px solid #F5F3FF",
        color: "#5024DC",
        borderRadius: "6px",
      };
    case "white-primary":
      return {
        background: "#fff",
        color: " #693dc7",
        border: "1px solid #693dc7",
        borderRadius: "6px",
      };
  }
};

const CustomButton = ({
  buttonImg,
  buttonText,
  onClick,
  variant,
  isLoading = false,
  isDisabled = false,
}: Props) => {
  return (
    <Button
      isLoading={isLoading}
      isDisabled={isDisabled}
      className="flex w-fit cursor-pointer items-center gap-[6px] rounded-[20px] px-[16px] py-[8px]"
      onClick={() => onClick()}
      style={getStyles(variant)}
    >
      {buttonImg && <img src={buttonImg} alt="" width={20} height={20} />}
      <p className="text-[16px] !font-medium">{buttonText}</p>
    </Button>
  );
};

export default CustomButton;
