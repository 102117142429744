/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";

export const useTableList = () => {
  return useQuery({
    queryKey: ["tables"],
    queryFn: () => tableService.getTableList(),
    staleTime: 1000 * 60 * 30, // 30 minutes
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};
export const useWorkspaceTableList = () => {
  const workspaceId = useTableStore((state) => state.selectedWorkSpace?._id);
  return useQuery({
    queryKey: ["tables", workspaceId],
    queryFn: () => tableService.getTableList(),
    staleTime: 1000 * 60 * 30, // 30 minutes
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
  });
};
