import MakeAPICall from "@/lib/apiCalls";
import { ApiKey } from "@/types/apiKey.types";

class ApiKeyService {
  constructor(private apiCaller = MakeAPICall) {}

  async createApiKey(payload: {
    name: string;
    validFor?: number;
    userId?: string;
  }) {
    const url = payload.userId
      ? `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys?userId=${payload.userId}`
      : `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys`;
    const apiCall = new this.apiCaller({
      url,
      payload: { name: payload.name, validFor: payload.validFor },
    });
    const response = await apiCall.post();
    return response;
  }

  async getApiKeys(userId?: string) {
    const url = userId
      ? `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys?userId=${userId}`
      : `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys`;
    const apiCall = new this.apiCaller({
      url,
    });
    const response = await apiCall.get<ApiKey[]>();
    return response;
  }

  async getApiKey(apiKey: string, userId?: string) {
    const url = userId
      ? `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys/${apiKey}?userId=${userId}`
      : `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys/${apiKey}`;
    const apiCall = new this.apiCaller({
      url,
    });
    const response = await apiCall.get();
    return response;
  }

  async disableApiKey(apiKey: string, userId?: string) {
    const url = userId
      ? `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys/${apiKey}/disable?userId=${userId}`
      : `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys/${apiKey}/disable`;
    const apiCall = new this.apiCaller({
      url,
    });
    const response = await apiCall.put();
    return response;
  }

  async bulkDeleteApiKeys(ids: string[], userId?: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys/bulk-delete${userId ? `/${userId}` : ""}`,
      payload: { ids },
    });
    const response = await apiCall.post();
    return response;
  }

  async generateSlugs(n: number = 1) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/api-keys/generate-slugs`,
      params: { n },
    });
    const response = await apiCall.get();
    return response;
  }
}

const apiKeyService = new ApiKeyService();

export default apiKeyService;
