import ChatWithGPT from "@/components/AutoPilotLandingPage/ChatWithGPT";
import Demo from "@/components/AutoPilotLandingPage/Demo";
import Header from "@/components/AutoPilotLandingPage/Header";
import PlaybooksSection from "@/components/AutoPilotLandingPage/PlaybooksSection";
import Triggers from "@/components/AutoPilotLandingPage/Triggers";

const AutoPilotLandingPage = () => {
  return (
    <div className="flex flex-col">
      <Header />
      <div className="flex h-full p-4 gap-1">
        <div className="w-[65%] bg-gray-100 p-4 flex flex-col gap-4">
          <Demo />
          <Triggers />
        </div>

        <div className="w-[35%] bg-gray-200 p-4 flex flex-col gap-4">
          <ChatWithGPT />
          <PlaybooksSection />
        </div>
      </div>
    </div>
  );
};

export default AutoPilotLandingPage;
