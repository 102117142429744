import SearchImg from "@/assets/svgs/search.svg";

const BeginSearch = () => {
  return (
    <div className="m-auto flex size-full w-[400px] flex-col items-center justify-center gap-[56px]">
      <img src={SearchImg} alt="" />
      <div>
        <p className="mb-[10px] w-full text-center text-[16px] font-medium leading-[24px]">
          Begin your search
        </p>
        <p className="w-full text-center text-[14px] font-[400] leading-[20px] text-[#4A5264]">
          Apply filters on the left to start choosing between all the companies
          in our database.
        </p>
      </div>
    </div>
  );
};

export default BeginSearch;
