import PeopleSearch from "@/components/PeopleSearchMixrank";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/search-mixrank/").createRoute({
  component: SearchPage,
});

function SearchPage() {
  return <PeopleSearch />;
}

export default SearchPage;
