import { ColDef } from "ag-grid-community";
import { TableRowRenderer } from "./Renderers/tableRow";
import { TRow } from "./types";

export const columnDefs: ColDef<TRow>[] = [
  {
    field: "name",
    cellRenderer: TableRowRenderer,
    sortable: false,
    editable: false,
  },
  {
    field: "createdAt",
    hide: true,
  },
  {
    field: "updatedAt",
    hide: true,
  },
];
