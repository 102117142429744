import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import CSVImport from "../ImportData/CSVImport";

const ImportCSVModal = () => {
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  const closeModal = () => {
    if (modalData.metaData?.openPreviousModal) {
      updateState({
        isOpenModal: true,
        modalData: { modalType: "get-started", metaData: "" },
      });
    } else {
      updateState({
        isOpenModal: false,
        modalData: { modalType: "", metaData: "" },
      });
    }
  };

  return (
    <Modal
      onClose={() => closeModal()}
      isOpen={isOpenModal && modalData.modalType === "import-csv"}
      size={"2xl"}
      scrollBehavior="outside"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="pb-4 font-poppins">
        <ModalHeader>
          <p className="text-2xl">Upload CSV</p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CSVImport
            onClose={() => {
              updateState({
                isOpenModal: false,
                modalData: { modalType: "", metaData: "" },
              });
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImportCSVModal;
