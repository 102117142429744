import {
  Button,
  ButtonGroup,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { FormEvent } from "react";
import _ from "lodash";

import { useCreatePreset } from "@/hooks/usePresets";
import Description from "../Description";
interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
  title?: string;
  icon: JSX.Element;
  type:
    | "open-ai"
    | "google-search"
    | "http-api"
    | "use-ai"
    | "sales-emails"
    | "persana-agent"
    | "persana-quantum";
  payload: object;
}

export const SaveAsTemplate = ({
  isModalOpen,
  handleClose,
  title,
  type,
  icon,
  payload,
}: Props) => {
  const toast = useToast({
    position: "top-right",
    duration: 5000,
    isClosable: true,
  });

  const { isPending, mutateAsync } = useCreatePreset();

  const savePresetValuesV2 = async (e: FormEvent) => {
    e.preventDefault();
    const formEl = e.target as HTMLFormElement;
    const isValid = formEl.checkValidity();
    if (!isValid) {
      formEl.reportValidity();
      return;
    }
    const formData = new FormData(formEl);
    const presetValues = {
      name: formData.get("templateName") as string,
      type: type,
      description: formData.get("description") as string,
      payload,
    };
    const result = mutateAsync(presetValues);
    toast.promise(result, {
      loading: {
        title: "Saving Template",
        description: "Please wait...",
      },
      success: {
        title: "Success",
        description: "Template saved successfully",
      },
      error(e) {
        return {
          title: "Error",
          description: e.message || "Something went wrong",
        };
      },
    });
    await result;
    handleClose();
  };

  return (
    <Modal
      size={"2xl"}
      isOpen={isModalOpen}
      onClose={handleClose}
      isCentered
      motionPreset="slideInBottom"
      scrollBehavior="inside"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent height={"fit-content"}>
        <ModalHeader className="border-b">
          <p className="flex items-center gap-x-2">
            {icon}
            <span>{title ?? _.startCase(type) + " Template"}</span>
          </p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY={"auto"}
          display={"flex"}
          flexDirection={"column"}
          flexGrow={1}
          width={"full"}
        >
          <Description>
            Save your current integration configuration as a custom template
            available to your entire workspace
          </Description>
          <form
            onSubmit={savePresetValuesV2}
            className="mt-2 flex w-full flex-col gap-4 [&_label]:!font-semibold"
          >
            <FormControl>
              <label htmlFor="templateName">Template Name</label>
              <Input
                type="text"
                required
                autoComplete="on"
                autoFocus
                autoCorrect="on"
                size={"sm"}
                name="templateName"
                className="!rounded "
                placeholder="e.g. Email Template 1"
                onInput={(e) =>
                  (e.currentTarget.value = _.capitalize(e.currentTarget.value))
                }
              />
            </FormControl>
            <FormControl>
              <label htmlFor="description">Description</label>
              <Textarea
                required
                errorBorderColor="red.300"
                name="description"
                className="!rounded"
                placeholder="e.g. This is an email template for your integration"
                size={"sm"}
              />
            </FormControl>
            <FormControl className="flex justify-end">
              <ButtonGroup className="ml-auto">
                <Button
                  isDisabled={isPending}
                  variant={"outline"}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  tabIndex={2}
                  isDisabled={isPending}
                  isLoading={isPending}
                  type="submit"
                  colorScheme="primary"
                >
                  Save
                </Button>
              </ButtonGroup>
            </FormControl>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
