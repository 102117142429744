import { Stack, Button, Box } from "@chakra-ui/react";
import CustomAccordion from "@/components/Common/CustomAccordion";
import Description from "../../Common/Description";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
// import { PreviewRowScore } from "./preview";
import { Criterias } from "./QueryBuilder/critarias";
import { useLeadScoreStore } from "./QueryBuilder/critaria.store";
import { useEnrichStore } from "@/stores/enrich.store";
import { RuleGroupTypeIC } from "react-querybuilder";
import { getUUID } from "./utils";
import { useEffect } from "react";
import WatchTutorial from "@/components/Common/WatchTutorial";

export const RowScorer = () => {
  const updateState = useLeadScoreStore((state) => state.updateState);
  const resetData = useLeadScoreStore((state) => state.reset);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const editQueries = useEnrichStore(
    (state) =>
      (state.selectedColumnToEdit?.metaData?.enrichmentMetaData as any)
        ?.queries ?? null,
  ) as { score: number; query: RuleGroupTypeIC }[] | null;
  const columnsBeingUsed = useLeadScoreStore((state) =>
    state.getColumnsUsed().get("all"),
  )!;
  const queries = useLeadScoreStore((state) => state.criterias);

  useEffect(() => {
    if (editQueries && viewMode === "edit") {
      updateState({
        criterias: new Map(
          editQueries.map((q) => [q.query.id ?? getUUID(), q]),
        ),
      });
    }
  }, [editQueries]);
  return (
    <div className="grow space-y-4 overflow-y-auto p-4">
      <WatchTutorial
        subText={
          "Generate lead score based on the selected columns and criteria"
        }
        link=""
      />

      <Stack className="h-[40h] max-h-full min-h-[40vh] min-w-full overflow-auto px-4">
        <CustomAccordion
          title={
            <Box className="flex w-full flex-row items-center justify-between">
              Lead Score
              <Button
                ml={"auto"}
                size={"sm"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  updateState({
                    previewRowScoreModal: {
                      isOpen: true,
                    },
                  });
                }}
              >
                Open Preview
              </Button>
            </Box>
          }
          titleClassName="font-title text-sm font-medium"
          isDefaultOpen
        >
          <Description>Add Row scoring criteria to the query.</Description>
          <Criterias />
        </CustomAccordion>

        <SaveAndRunEnrichment
          onClear={resetData}
          apiPayload={{
            queries: Array.from(queries.values()),
            columnIds: Array.from(columnsBeingUsed),
          }}
        />
      </Stack>
    </div>
  );
};
