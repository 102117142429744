import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Icons from "@/components/Icons";
import {
  useHttpPresetsData,
  useSaveHttpPreset,
} from "@/hooks/useHttpPresetsData";
import { useCreatePreset } from "@/hooks/usePresets";
import { usePresetsStore } from "@/stores/presets.store";
import { useQueryClient } from "@tanstack/react-query";
import AddMoreBtn from "../../Common/AddMoreBtn";
import CustomTextEditor from "../../Common/CustomTextEditor";

interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
  title: string;
  isUseV2?: boolean;
}

const SaveCustomHttpPreset = ({
  isModalOpen,
  handleClose,
  title,
  isUseV2 = false,
}: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useSaveHttpPreset();
  const { isPending: isPendingV2, mutateAsync: mutateAsyncV2 } =
    useCreatePreset();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [localEndpoint, setLocalEndpoint] = useState<any>();
  const [localBody, setLocalBody] = useState<any>();
  const [localHeaders, setLocalHeaders] = useState<any>();

  const presetsModalData = usePresetsStore((state) => state.presetsModalData);
  const body = usePresetsStore((state) => state.body);
  const headers = usePresetsStore((state) => state.headers);
  const endpoint = usePresetsStore((state) => state.endpoint);
  const selectedMethod = usePresetsStore((state) => state.selectedMethod);
  const { refetch } = useHttpPresetsData(presetsModalData.modalType!);

  // TODO: remove it later
  const savePresetValues = () => {
    const presetValues = {
      name,
      description,
      endpoint,
      body,
      headers,
      method: selectedMethod?.value || "GET",
      enrichmentType: presetsModalData.modalType,
    };
    mutateAsync(presetValues, {
      onSuccess: () => {
        handleClose();
        refetch();
        toast({
          title: "Success",
          description: "Preset saved successfully",
          status: "success",
          isClosable: true,
          duration: 5000,
          position: "top-right",
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          isClosable: true,
          duration: 5000,
          position: "top-right",
        });
      },
    });
  };

  const savePresetValuesV2 = () => {
    const presetValues = {
      name,
      description,
      payload: { endpoint, body, headers, selectedMethod },
      type: presetsModalData.modalType,
    };
    mutateAsyncV2(presetValues, {
      onSuccess: () => {
        handleClose();
        refetch();
        toast({
          title: "Success",
          description: "Preset saved successfully",
          status: "success",
          isClosable: true,
          duration: 5000,
          position: "top-right",
        });
        queryClient.invalidateQueries({
          queryKey: ["presets-v2", presetsModalData.modalType],
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          isClosable: true,
          duration: 5000,
          position: "top-right",
        });
      },
    });
  };

  const isSaving = isPendingV2 || isPending;

  useEffect(() => {
    setLocalEndpoint(endpoint);
    setLocalBody(body);
    setLocalHeaders(headers);
  }, [body, headers, endpoint]);

  return (
    <Modal
      size={"2xl"}
      isOpen={isModalOpen}
      onClose={handleClose}
      isCentered
      motionPreset="slideInBottom"
      scrollBehavior="inside"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent height={"fit-content"}>
        <ModalHeader className="border-gray-300 border-b">
          <p className="flex items-center gap-x-2">
            <Icons.HttpIcon color="#ffef00" size={26} />
            <span>{title}</span>
          </p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY={"auto"}
          paddingY={"1.5rem"}
          display={"flex"}
          flexDirection={"column"}
          flexGrow={1}
          width={"full"}
        >
          <div className="p-[0 10px 0 0] h-[35rem] overflow-auto">
            <div className="space-y-6 overflow-y-auto">
              <div className="text-sm">
                <h2 className="pb-0.5 font-semibold text-[#1d2026]">
                  Save as custom template
                </h2>
                <p className="text-[#676d7e]">
                  Save your current integration configuration as a custom
                  template available to your entire workspace
                </p>
              </div>

              {/* Input for Title */}
              <div>
                <label className="font-medium">Title</label>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="E.g. Split the names"
                  className="border-gray-300 w-full rounded-md border p-2 text-sm focus:border-blue-500 focus:outline-none"
                />
              </div>

              {/* Input for Description */}
              <div>
                <label className="font-medium">Description</label>
                <textarea
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="E.g. Split the names into first and last names"
                  className="w-full resize-none rounded-md !border-[#e2e8f0] p-2 text-sm focus:border-blue-500 focus:outline-none"
                  style={{
                    border: "1px solid #e2e8f0",
                  }}
                />
              </div>

              {/* Input for Method */}
              <div>
                <label className="font-medium">Method</label>
                <Input
                  value={selectedMethod?.value}
                  placeholder="E.g. Split the names"
                  className="border-gray-300 w-full rounded-md border p-2 text-sm focus:border-blue-500 focus:outline-none"
                  isDisabled
                />
              </div>

              {/* Input for Endpoint */}
              <div>
                <label className="font-medium">Endpoint</label>
                <CustomTextEditor
                  readonly
                  slateValue={localEndpoint}
                  setSlateValue={() => ""}
                  isInline
                  editorHeight="fit-content"
                  editorClassName="!px-2 !py-[4.5px]"
                  showDetails={false}
                />
              </div>

              {/* Input for Body */}
              <div className="pb-3">
                <label className="font-medium">Body</label>
                <CustomTextEditor
                  readonly
                  slateValue={localBody}
                  setSlateValue={() => ""}
                  editorHeight="6rem"
                  showDetails={false}
                />
              </div>
            </div>

            <div className="pb-3">
              <label className="font-medium">Headers</label>
              <AddMoreBtn
                keyName="Key"
                handleFieldChange={() => {}}
                title="Add new key value pair"
                description="Enter any header fields to set, e.g. {'API-key': 'abcd1234'}"
                moreFields={localHeaders || []}
                setMoreFileds={() => {}}
                showAddMore={false}
                showRemove={false}
              />
            </div>

            <div className="mt-5">
              <Button
                colorScheme="green"
                onClick={isUseV2 ? savePresetValuesV2 : savePresetValues}
                isDisabled={isSaving}
                isLoading={isSaving}
              >
                <Icons.CheckCircleIcon className="mr-2 text-lg" />
                Save Preset
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SaveCustomHttpPreset;
