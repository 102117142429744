import { useEffect } from "react";
import { useTableState } from "../useTableState.store";
import {
  DEFAULT_FOLDER_OPEN,
  NEW_FOLDER_ID_PLACEHOLDER,
} from "../../constants";

export const useHandleFilters = () => {
  const gridApi = useTableState((state) => state.gridApi);
  const folderClosedStatus = useTableState((state) => state.folderClosedStatus);
  const folderMap = useTableState((state) => state.folderMap);
  const showFolders = useTableState((state) => state.showFolders);
  const search = useTableState((state) => state.search);

  useEffect(() => {
    if (!(gridApi && folderMap)) return;
    gridApi.updateGridOptions({
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: (node) => {
        if (!node?.data) return false;
        if (node.data._id === NEW_FOLDER_ID_PLACEHOLDER) return true;
        if (!showFolders && node.data.type === "folder") return false;
        if (search) {
          const regex = new RegExp(search, "i");
          return (
            regex.test(node.data.name) ||
            regex.test(node.data.updatedAt.toString()) ||
            regex.test(node.data.createdAt.toString())
          );
        }
        const parentFolderChain = Array.from(node.data?.path);
        if (node.data.type === "folder") parentFolderChain.pop();
        const isParentHidden = parentFolderChain.some(
          (folderId) =>
            folderClosedStatus.get(folderId) ?? !DEFAULT_FOLDER_OPEN,
        );
        if (!node?.data) return false;

        return !isParentHidden;
      },
    });
    gridApi.onFilterChanged();
  }, [gridApi, folderMap, folderClosedStatus, showFolders, search]);
};
