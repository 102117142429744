import { Button, useToast } from "@chakra-ui/react";

import SlackLogo from "@/assets/images/slack-logo.png";
import { useProviderList } from "@/hooks/useProviderData";
import { useMutation } from "@tanstack/react-query";
import providerService from "@/services/providers.service";

interface Props {
  isFromModal?: boolean;
}

const SlackIntegration = ({ isFromModal }: Props) => {
  const toast = useToast();
  const { data: providerList, refetch } = useProviderList();
  const slackProvider = providerList?.data?.find(
    (provider) => provider.name === "slack",
  );

  const handleIntegration = async () => {
    providerService.openSlackOAuth();
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      providerService.deleteProvider({ providerId: slackProvider?._id || "" }),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        refetch();
        toast({
          title: response?.data?.message || "Disconnected successfully",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  if (isFromModal) {
    return (
      <Button
        colorScheme={slackProvider ? "red" : "green"}
        variant="outline"
        size="sm"
        isLoading={isPending}
        onClick={() => {
          if (slackProvider) {
            mutateAsync();
          } else {
            handleIntegration();
          }
        }}
      >
        {slackProvider ? "Disconnect" : "Connect"} Slack
      </Button>
    );
  }

  return (
    <div className="dark:!bg-navy-700 flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={SlackLogo} alt={"slack"} className="w-[2rem]" />
        <h2 className="font-semibold">Slack</h2>
      </div>
      <div>
        <p className="mb-4">{`Connect your Slack Account`}</p>

        <Button
          colorScheme={slackProvider ? "red" : "green"}
          variant="outline"
          size="sm"
          isLoading={isPending}
          onClick={() => {
            if (slackProvider) {
              mutateAsync();
            } else {
              handleIntegration();
            }
          }}
        >
          {slackProvider ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default SlackIntegration;
