import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

import {
  TopEnrichmentTypes,
  allEnrichments,
} from "@/components/Enrichment/constants";
import type {
  Column,
  FilterType,
  ResponseData,
  RowData,
  TableData,
  TableView,
} from "@/types/table.types";
import { Team } from "@/types/team.types";
import { Socket } from "socket.io-client";
import { useEnrichStore } from "./enrich.store";

export type State = ResponseData & {
  isShowLoader: boolean;
  selectedRowsId: string[];
  isSelectedAll: boolean;
  selectedWorkSpace: Team | null;
  tableFilters: {
    open: boolean;
    filters?: FilterType[];
  };
  currentView?: TableView;
  contextMenuState: {
    x: number;
    y: number;
    metaData: {
      rowId: string | null;
    };
  };
  expendedCellValue: {
    isOpen: boolean;
    columnName: string;
    columnId: string;
    rowId: string;
    cellValue: string;
    rowIndex?: number;
    showSendOption?: boolean;
  };
  queryParams: {
    limit: number;
    pageNumber: number;
    sortingKey?: string;
    sortOrder: "ASC" | "DESC";
    checkForFilter?: boolean;
    clearFilters?: boolean;
  };
  sortingProcessingColumnKey: string;
  initialTableAndRowsData?: ResponseData;
  isFiltering?: boolean;
  isCreatedNewColumn?: boolean;
  movedColumnIndex?: number | null;
  isTablePageReRender?: boolean;
  initialViewId?: string;
  socketInstance?: Socket;
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
  updateRowsData: (payload: RowData[]) => void;
  updateRows: (payload: ResponseData["rowsData"], isReplace?: boolean) => void;
  updateTableMetaDta: (payload: TableData["metaData"]) => void;
  deleteRow: (rowId: string) => void;
  deleteColumn: (columnId: string) => void;
  handleCellClick: (colValue: Column, cellValue: any) => void;
  updateColumns: (payload: Partial<Column>) => void;
  clearState: () => void;
  updateViews: (payload: TableView[]) => void;
  updateRowsForQueued: (payload: {
    isForSelected?: boolean;
    newColumnId?: string;
    customRowsCount?: number;
    start?: number;
    end?: number;
  }) => void;
  updateRowsCellValue: (payload: {
    rowId: string;
    columnId: string;
    cellValue: any;
  }) => void;
};

const DEFAULT_STATE: State = {
  isShowLoader: false,
  isFiltering: false,
  isCreatedNewColumn: false,
  movedColumnIndex: null,
  totalPages: 0,
  totalRows: 0,
  currentPage: 1,
  rowsData: [],
  tableData: {} as TableData,
  selectedRowsId: [],
  isSelectedAll: false,
  sortingProcessingColumnKey: "",
  initialTableAndRowsData: undefined,
  selectedWorkSpace: null,
  isTablePageReRender: false,
  tableFilters: {
    open: false,
  },
  contextMenuState: {
    x: 0,
    y: 0,
    metaData: {
      rowId: null,
    },
  },
  queryParams: {
    limit: 500,
    pageNumber: 1,
    sortingKey: "",
    sortOrder: "ASC",
    clearFilters: false,
  },
  expendedCellValue: {
    isOpen: false,
    columnName: "",
    columnId: "",
    rowId: "",
    cellValue: "",
    showSendOption: false,
  },
};

export const useTableStore = create<State & Actions>()((set) => ({
  ...DEFAULT_STATE,
  updateViews: (payload: TableView[]) => {
    set((state) => {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          views: payload,
        },
      };
    });
  },
  handleCellClick: (colValue: Column, cellvalue: any) => {
    const selectedEnrichment =
      allEnrichments.find((enrichment) => {
        return (
          enrichment.enrichmentName ===
          colValue?.metaData?.enrichmentMetaData?.enrichmentName
        );
      }) || {};

    useEnrichStore.getState().updateState({
      selectedEnrichments: {
        ...selectedEnrichment,
        showResponse: true,
        columnData: colValue,
        rowData: cellvalue,
      } as TopEnrichmentTypes,
    });
  },

  updateTableMetaDta: (payload: Partial<TableData["metaData"]>) => {
    set((state) => {
      const {
        tableData,
      }: {
        tableData: ResponseData["tableData"];
      } = state;
      return {
        tableData: {
          ...tableData,
          metaData: {
            ...tableData.metaData,
            ...payload,
          },
        },
      };
    });
  },

  updateState: (payload) => set(() => ({ ...payload })),

  updateColumns: (payload: Partial<Column>) => {
    set((state) => {
      const {
        tableData,
      }: {
        tableData: ResponseData["tableData"];
      } = state;
      const newColumns = tableData.columns.map((column) => {
        if (column._id === payload._id) {
          if (column.metaData) {
            column.metaData = {
              ...column.metaData,
              ...payload.metaData,
            };
            return {
              ...column,
            };
          }
          return {
            ...column,
            ...payload,
          };
        }
        return column;
      });
      return {
        tableData: {
          ...tableData,
          columns: newColumns,
        },
      };
    });
  },

  updateRows: (payload, isReplace = false) =>
    set((state) => {
      const {
        rowsData,
      }: {
        rowsData: ResponseData["rowsData"];
      } = state;
      return {
        rowsData:
          isReplace && payload?.length
            ? [...payload]
            : [...rowsData, ...payload],
      };
    }),

  updateRowsData: (payload: RowData[]) => {
    set((state) => {
      // Loop through the rowsData and update the cellData
      const { rowsData } = state;
      const newRowsData = rowsData.map((row) => {
        const newRow = payload.find((data) => data._id === row._id);
        if (newRow) {
          return newRow;
        }
        return row;
      });
      return { rowsData: [...newRowsData] };
    });
  },

  updateRowsForQueued: ({
    isForSelected,
    newColumnId,
    customRowsCount,
    start,
    end,
  }: {
    isForSelected?: boolean;
    newColumnId?: string;
    customRowsCount?: number;
    start?: number;
    end?: number;
  }) => {
    set((state) => {
      const { rowsData, currentPage, selectedRowsId } = state;

      const updatedRows = rowsData.map((row, index) => {
        const newRow = { ...row };
        const startIndex = (currentPage - 1) * 200;
        const pageIndex = startIndex + index;
        if (isForSelected) {
          if (selectedRowsId.includes(row._id)) {
            if (newColumnId) {
              if (newRow.cells[newColumnId]) {
                newRow.cells[newColumnId].value = "queued...";
              } else {
                newRow.cells[newColumnId] = {
                  value: "queued...",
                };
              }
            }
          }
        } else if (customRowsCount) {
          if (start && end) {
            if (pageIndex >= start - 1 && pageIndex <= end - 1) {
              if (newColumnId) {
                if (newRow.cells[newColumnId]) {
                  newRow.cells[newColumnId].value = "queued...";
                } else {
                  newRow.cells[newColumnId] = {
                    value: "queued...",
                  };
                }
              }
            }
          } else if (pageIndex < customRowsCount) {
            if (newColumnId) {
              if (newRow.cells[newColumnId]) {
                newRow.cells[newColumnId].value = "queued...";
              } else {
                newRow.cells[newColumnId] = {
                  value: "queued...",
                };
              }
            }
          }
        } else {
          if (newColumnId) {
            if (newRow.cells[newColumnId]) {
              newRow.cells[newColumnId].value = "queued...";
            } else {
              newRow.cells[newColumnId] = {
                value: "queued...",
              };
            }
          }
        }

        return newRow;
      });

      return {
        rowsData: updatedRows,
      };
    });
  },

  updateRowsCellValue: ({
    rowId,
    columnId,
    cellValue,
  }: {
    rowId: string;
    columnId: string;
    cellValue: any;
  }) => {
    set((state) => {
      const { rowsData } = state;
      const updatedRows = rowsData.map((row) => {
        if (row._id === rowId) {
          if (row.cells[columnId]) {
            row.cells[columnId] = cellValue;
          } else {
            row.cells[columnId] = {
              value: cellValue,
            };
          }
        }
        return row;
      });
      return {
        rowsData: updatedRows,
      };
    });
  },

  deleteRow: (rowId: string) => {
    set((state) => {
      const {
        rowsData,
      }: {
        rowsData: ResponseData["rowsData"];
      } = state;
      const newRowsData = rowsData.filter((row) => row._id !== rowId);
      return { rowsData: newRowsData };
    });
  },

  deleteColumn(columnId: string) {
    set((state) => {
      const {
        tableData,
      }: {
        tableData: ResponseData["tableData"];
      } = state;
      const newColumns = tableData.columns.filter(
        (column) => column._id !== columnId,
      );
      return {
        tableData: {
          ...tableData,
          columns: newColumns,
        },
      };
    });
  },

  clearState: () =>
    set(() => ({
      isSelectedAll: false,
      selectedRowsId: [],
    })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Table", useTableStore);
}
