import Confetti from "react-confetti";

const ConfettiAnimation = () => {
  return (
    <Confetti
      style={{ zIndex: 10000 }}
      width={window.innerWidth - 10}
      height={window.innerHeight - 10}
      numberOfPieces={1000}
      recycle={false}
      gravity={0.1}
    />
  );
};

export default ConfettiAnimation;
