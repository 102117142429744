import { ColDef } from "ag-grid-community";
import {
  CompanyNameRender,
  JobTitleRenderer,
  LocationRenderer,
  OtherRolesRenderer,
  StatusRenderer,
  UserDetailsRenderer,
} from "@/components/JobChanges/columns/CellRenderers";
import { WithLoader } from "@/components/DataTable/Table/columns/WithLoader";
import { HeaderCheckboxSelection } from "@/components/DataTable/Table/columns/HeaderCheckboxSelection";
import { RowCheckboxRenderer } from "@/components/DataTable/Table/columns/Checkbox";
import { LeadEntry } from "@/types/accountMonitoring.types";
export const colDefs: ColDef<LeadEntry>[] = [
  {
    headerName: "",
    width: 48,
    sortable: false,
    pinned: "left",
    headerComponent: HeaderCheckboxSelection,
    cellRenderer: RowCheckboxRenderer,
  },
  {
    field: "firstName",
    headerName: "Name",
    cellRenderer: WithLoader(UserDetailsRenderer),
    cellStyle: {},
    width: 200,
  },
  {
    field: "action",
    headerName: "Status",
    sortable: true,
    width: 125,
    filterValueGetter: (params) => params.getValue("action") ?? "newly_added",
    cellRenderer: WithLoader(StatusRenderer),
    cellStyle: {},
  },
  {
    field: "current.companyName",
    headerName: "Current Company",
    cellRenderer: WithLoader(CompanyNameRender),
    cellRendererParams: {
      type: "current",
    },
    cellStyle: {},
  },
  {
    field: "current.position",
    headerName: "Current Title",
    cellRenderer: WithLoader(JobTitleRenderer),
    cellRendererParams: {
      type: "current",
    },
    cellStyle: {},
  },
  {
    field: "previous.companyName",
    headerName: "Previous Company",
    cellRenderer: WithLoader(CompanyNameRender),
    cellRendererParams: {
      type: "previous",
    },
    cellStyle: {},
  },
  {
    field: "previous.position",
    headerName: "Previous Title",
    cellRenderer: WithLoader(JobTitleRenderer),
    cellRendererParams: {
      type: "previous",
    },
    cellStyle: {},
  },
  {
    headerName: "Other Past Roles",
    cellRenderer: WithLoader(OtherRolesRenderer),
  },
  {
    field: "hubspotId",
    headerName: "Hubspot Id",
    cellStyle: {
      color: "#677084",
      fontSize: "12px",
      fontWeight: 400,
    },
    cellRenderer: WithLoader(),
  },
  {
    field: "metaData.location.default",
    headerName: "Location",
    cellRenderer: WithLoader(LocationRenderer),
    cellStyle: {},
  },
  {
    field: "updatedAt",
    hide: true,
    sort: "desc",
  },
  {
    field: "persanaStatusChange",
    headerName: "Persana Status Change",
    cellRenderer: WithLoader(),
    cellStyle: {},
  },
];
