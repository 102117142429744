import { Button, Input, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";
import { ProviderNameType } from "@/stores/provider.store";

type Props = {
  providerName: ProviderNameType;
  providerTitle: string;
  providerIcon: string;
};

const APIKeyIntegration = ({
  providerName,
  providerTitle,
  providerIcon,
}: Props) => {
  const toast = useToast();
  const [apiKeyValue, setAPIKeyValue] = useState("");
  const { data: providerList } = useProviderList();
  const apiKey = providerList?.data?.find(
    (provider) => provider.name === providerName,
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: {
      apiKey: string;
      providerId?: string;
      providerName: string;
    }) => providerService.saveKey(payload),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: response?.data?.message || "Key saved successfully.",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={providerIcon} alt={providerTitle} className="w-[2rem]" />
        <h2 className="font-semibold">{providerTitle}</h2>
      </div>
      <div>
        <p className="mb-4">{`Add your ${providerTitle} key`}</p>
        <div className="flex items-center gap-1">
          <Input
            size={"sm"}
            placeholder={apiKey?.metaData?.accessToken || "********"}
            className="mr-5 border-2 border-black"
            onChange={(e) => setAPIKeyValue(e.target.value)}
            value={apiKeyValue}
          />

          <Button
            colorScheme={"green"}
            variant="outline"
            size="sm"
            isLoading={isPending}
            onClick={() =>
              mutateAsync({
                apiKey: apiKeyValue?.trim(),
                providerId: apiKey?._id || "",
                providerName: providerName,
              })
            }
            isDisabled={!apiKeyValue}
          >
            {apiKey ? "Update" : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default APIKeyIntegration;
