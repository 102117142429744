import { ValueContainerProps, components } from "react-select";
import { Stack } from "@chakra-ui/react";
import { TProviderOption } from "../types";

export const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<TProviderOption>) => {
  const icon = props.getValue()[0]?.icon;
  return (
    <components.ValueContainer {...props}>
      <Stack direction={"row"} className="w-full place-items-center" gap={0}>
        {icon ? (
          <img src={icon} alt="" className="mr-2 size-[20px] rounded-full" />
        ) : null}
        {children}
      </Stack>
    </components.ValueContainer>
  );
};
