import { Button } from "@chakra-ui/react";

import { usePresetsStore } from "@/stores/presets.store";

const SelectTemplate = () => {
  const updateState = usePresetsStore((state) => state.updateState);

  return (
    <div className="mb-4 flex items-center justify-between">
      <p className="text-base font-semibold ">Select templates (optional)</p>
      <Button
        onClick={() => {
          updateState({
            isOpenPresetsModal: true,
            presetsModalData: {
              modalType: "use-ai",
            },
          });
        }}
        className="border-gray-800/40 rounded border-[0.5px] bg-[#f6f6f8] px-3 py-0.5 text-sm font-medium capitalize cursor-pointer"
        size="xs"
      >
        use preset
      </Button>
    </div>
  );
};

export default SelectTemplate;
