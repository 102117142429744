export const providers = [
  {
    label: "Chat GPT",
    value: "chat-gpt",
  },
  {
    label: "Claude",
    value: "claude",
  },
] as const;

export type ProviderType = (typeof providers)[number]['value'];
