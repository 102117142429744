import { useQuery } from "@tanstack/react-query";

import userService from "@/services/user.service";

export const useWhiteList = () => {
  return useQuery({
    queryKey: ["whitelist"],
    queryFn: async () =>
      userService.getWhitelistedUsers() as unknown as {
        success: boolean;
        message?: string;
        data?: string[];
      },
    gcTime: 1000 * 60 * 60 * 24, // 1 day
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};
