import CustomButton from "../Common/CustomButton";
import ErrorIcon from "@/assets/svgs/error.svg";
import DownloadIcon from "@/assets/svgs/download.svg";
// import SaveIcon from "@/assets/svgs/save.svg";
// import SuccessIcon from "@/assets/svgs/success.svg";
import PeopleSearchResultTableActions from "./PeopleSearchResultTableActions";
import { useSearchLeadsStore } from "@/stores/searchleads.store";

type FiltersSectionProps = {
  handleClear: () => void;
  currentSearchPayload: any;
};

const FiltersSection = ({
  handleClear,
  currentSearchPayload,
}: FiltersSectionProps) => {
  const selectedLeads = useSearchLeadsStore((state) => state.selectedLeads);

  const convertToCSV = (data: any) => {
    const headers = Object.keys(data?.[0]).join(",");
    const rows = data
      .map((obj: any) => {
        const modifiedValues = Object.values(obj).map((value) =>
          typeof value === "string" ? value.replace(/,/g, "") : value,
        );
        return modifiedValues.join(",");
      })
      .join("\n");
    return headers + "\n" + rows;
  };

  const handleExport = () => {
    const payload = selectedLeads;
    const csv = convertToCSV(payload);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "people-search.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="px-[32px] py-[16px] flex items-center justify-between border border-[##F0EFF1]">
      <div className="flex items-center gap-[16px]">
        {/* <CustomButton
          buttonText="Saved Searches"
          onClick={() => {}}
          variant="secondary-colored"
          buttonImg={SaveIcon}
        /> */}
      </div>
      <div className="flex items-center gap-[12px]">
        <CustomButton
          buttonText="Clear Search"
          onClick={handleClear}
          variant="error"
          buttonImg={ErrorIcon}
        />
        {/* <CustomButton
          buttonText="Save Search"
          onClick={() => {}}
          variant="success"
          buttonImg={SuccessIcon}
        /> */}
        <CustomButton
          buttonText="Download"
          onClick={handleExport}
          variant="secondary"
          buttonImg={DownloadIcon}
          isDisabled={selectedLeads?.length === 0}
        />
        <PeopleSearchResultTableActions
          currentSearchPayload={currentSearchPayload}
        />
      </div>
    </div>
  );
};

export default FiltersSection;
