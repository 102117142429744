import { useTableStore } from "@/stores/table.store";
import { FormalizerType } from "@/types/enrichment.types";
import { Column } from "@/types/table.types";

export const createANewColumnName = (newName: string, columns?: Column[]) => {
  // If newName present in columns then create a new column with newName (matching length + 1)
  // Else create a new column with newName
  const allColumns =
    columns && columns.length > 0
      ? columns
      : useTableStore.getState().tableData?.columns || [];

  const columnNames = allColumns?.map((column) => column.name.toLowerCase());
  const matchingCount = columnNames?.filter(
    (columnName) => columnName === newName.toLowerCase(),
  ).length;

  return matchingCount ? `${newName} (${matchingCount + 1})` : newName;
};

export const getEnrichmentId = (formalizerType: FormalizerType) => {
  switch (formalizerType) {
    case "Company":
      return "companyFormalizer";
    case "Domain":
      return "domainFormalizer";
    case "First Name":
      return "firstNameFormalizer";
    case "Last Name":
      return "lastNameFormalizer";
    case "Whitespace":
      return "whiteSpaceFormalizer";
    default:
      break;
  }
};
