import { FileRoute } from "@tanstack/react-router";

import Dashboard from "@/pages/Dashboard/Dashboard";

export const Route = new FileRoute("/").createRoute({
  component: HomeComponent,
});

function HomeComponent() {
  return <Dashboard />;
}
