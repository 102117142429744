/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";

export const useTableData = (tableId: string, enabled: boolean = true) => {
  return useQuery({
    queryKey: ["table", tableId],
    queryFn: async ({ signal }) => {
      const result = await tableService.fetchTableData({
        tableId,
        signal,
      });
      if (!useTableStore.getState().initialViewId) {
        useTableStore.setState({
          initialViewId: result?.tableData?.selectedViewId,
        });
      }
      return result;
    },
    refetchOnMount: true,
    staleTime: 2 * 60 * 1000, // 2 minutes
    gcTime: 1,
    retry: 2,
    enabled,
  });
};
