import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import tableService from "@/services/table.service";
import { useModalStore } from "@/stores/modal.store";
import { useTableStore } from "@/stores/table.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

const RemoveDuplicateRows = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const tableId = useTableStore((state) => state.tableData._id);
  const totalRows = useTableStore((state) => state.totalRows);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      tableId,
      payload,
    }: {
      tableId: string;
      payload: {
        columnId: string;
        totalRows: number;
      };
    }) => tableService.removeDuplicateRows(tableId, payload),
  });

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  const handleRun = async (columnId: string) => {
    await mutateAsync(
      {
        tableId,
        payload: {
          columnId,
          totalRows,
        },
      },
      {
        onSuccess: (response) => {
          handleModalClose();
          queryClient.refetchQueries({
            queryKey: ["table-running-processes", tableId],
          });
          toast({
            title: "Remove duplicate rows",
            description: response?.data?.message || "Started Processings",
            status: "success",
            position: "top-right",
          });
        },

        onError: (error) => {
          handleModalClose();
          toast({
            title: "Remove duplicate rows",
            description: error?.message || "Failed to remove duplicate rows",
            status: "error",
          });
        },
      },
    );
  };

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "remove-duplicate-rows"}
      motionPreset="scale"
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent className="rounded-[20px]">
        <ModalCloseButton />
        <ModalHeader className="text-center">Remove Duplicate Rows</ModalHeader>
        <Divider />
        <ModalBody className="flex flex-col gap-4">
          <p className="text-[14px]">
            It checks columns for duplicate values. If we find duplicate values,
            we'll keep the instance in the oldest row and delete all others.
          </p>
          <div>
            <p className="pb-1 text-base font-semibold">
              Select column to remove duplicates
            </p>
            <SelectColumnDropdown
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </ModalBody>
        <ModalFooter className="!flex !items-center !justify-end gap-2">
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button
            isLoading={isPending}
            isDisabled={!selectedOption?.keyId}
            onClick={() => handleRun(selectedOption?.keyId || "")}
          >
            Run
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RemoveDuplicateRows;
