import React, { useRef } from "react";
import Slider from "react-slick";

interface Data {
  id: number;
  avatar: string;
  quote: string;
  author: string;
  position: string;
}

interface SliderProps {
  CommonCarouselData: Data[];
}

const CommonCarousel: React.FC<SliderProps> = ({ CommonCarouselData }) => {
  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "linear",
  };

  return (
    <>
      <Slider ref={sliderRef} {...settings}>
        {CommonCarouselData.map((testimonial) => (
          <div
            key={testimonial.id}
            className="text-black !flex !flex-col gap-3 "
          >
            <div className="flex gap-4 items-center">
              <img
                className="h-14 w-14 rounded-full"
                src={testimonial.avatar}
                alt=""
              />
              <div>
                <h3 className="text-[16px] font-[500] text-[#090909] h-6 text-left">
                  {testimonial.author}
                </h3>
                <p className="text-[14px] text-[#090909] text-left">
                  {testimonial.position}
                </p>
              </div>
            </div>
            <h2 className=" !font-medium text-sm sm:text-base sm:font-semibold h-24 text-left">
              {testimonial.quote}
            </h2>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default CommonCarousel;
