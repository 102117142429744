import { Skeleton } from "@chakra-ui/react";
import { CustomCellRendererProps } from "ag-grid-react";
import { ErrorBoundary } from "react-error-boundary";

export const WithLoader = <T extends CustomCellRendererProps>(
  Component?: (props: T) => JSX.Element | null,
) => {
  return function CellRendererWithloader(props: T) {
    const isLoading = !!(props.node.id && props.node.data);
    return (
      <ErrorBoundary fallback={<div>Error</div>}>
        <Skeleton
          isLoaded={isLoading}
          className={
            isLoading ? "flex size-full flex-row items-center" : " size-[60%] "
          }
        >
          {Component ? <Component {...props} /> : props.value}
        </Skeleton>
      </ErrorBoundary>
    );
  };
};
