import { Box, Checkbox, Tooltip } from "@chakra-ui/react";
import Icons from "@/components/Icons";

interface IZeroBounceCheckBoxProps {
  zeroBounceEnabled: boolean;
  extraColumnEnabled: boolean;
  enableZeroBounce: (value: boolean) => void;
  enableExtraColumn: (value: boolean) => void;
}
export const ZeroBounceCheckbox = ({
  zeroBounceEnabled,
  extraColumnEnabled,
  enableZeroBounce,
  enableExtraColumn,
}: IZeroBounceCheckBoxProps) => {
  return (
    <div
      className=" mb-2 flex max-w-lg shrink-0 flex-row items-center justify-between gap-4 rounded-lg border py-1.5 pl-4 pr-1.5 xl:max-w-none"
      style={{ minHeight: "52px" }}
    >
      <div className="flex items-center">
        <Checkbox
          id="zeroBounce"
          name="zeroBounce"
          className="mr-2  hover:border-[strong]"
          onChange={(e) => {
            enableZeroBounce(e.target.checked);
            enableExtraColumn(e.target.checked);
          }}
          isChecked={zeroBounceEnabled}
        />
        <label
          htmlFor="zeroBounce"
          className="mr-1 select-none font-medium"
          style={{ outline: "none" }}
        >
          Enable Zero Bounce Validation
        </label>
        <Tooltip
          className="inline-flex rounded-lg"
          label={
            <div className="w-64 rounded-lg p-3 sm:w-96">
              <p className="mb-4">
                Get Zero bounce validation for your emails.
              </p>
            </div>
          }
        >
          <div>
            <Icons.InformationIcon className="size-4" />
          </div>
        </Tooltip>
      </div>
      {false && (
        <div className="inline-flex  max-w-full shrink-0 flex-wrap items-center sm:flex-nowrap xl:w-44 2xl:w-64">
          <div className="w-full">
            <div className="relative flex items-center">
              <Checkbox
                id="zeroBounceExtraColumn"
                name="zeroBounceExtraColumn"
                className=" mr-2 hover:border-[strong]"
                onChange={(e) => {
                  enableExtraColumn(e.target.checked);
                }}
                isChecked={extraColumnEnabled}
              />
              <label
                htmlFor="zeroBounceExtraColumn"
                className="mr-1 select-none font-medium"
                style={{ outline: "none" }}
              >
                Add result as column
              </label>
            </div>
          </div>
        </div>
      )}
      <Box
        filter={!zeroBounceEnabled ? "grayscale(1)" : ""}
        className="flex items-center justify-center gap-[4px] rounded-[4px] bg-[#e6fce9] px-[4px] py-[2px] text-[#2bbf71]"
      >
        <span className="text-[12px] font-[500] uppercase">+1</span>
        <Icons.CoinsIcon className="text-sm text-[#2bbf71]" />
      </Box>
    </div>
  );
};
