import React, { useState } from "react";
import Joyride, { STATUS, ACTIONS, EVENTS } from "react-joyride";

interface JoyrideProps {
  steps: any;
  run: boolean;
  onFinish?: () => void;
  onStepChange?: (index: number) => void;
}

const JoyrideComponent: React.FC<JoyrideProps> = ({
  steps,
  run,
  onFinish,
  onStepChange,
}) => {
  const [stepIndex, setStepIndex] = useState(0);

  const handleJoyrideCallback = (data: any) => {
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && onFinish) {
      onFinish();
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextIndex);
      if (onStepChange) onStepChange(nextIndex);
    }
  };

  return (
    <Joyride
      continuous
      scrollToFirstStep
      showSkipButton={false}
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      stepIndex={stepIndex}
      locale={{
        last: "Done",
      }}
      styles={{
        options: {
          arrowColor: "#ffffff",
          backgroundColor: "#ffffff",
          overlayColor: "rgba(0, 0, 0, 0.5)",
          primaryColor: "#007bff",
          textColor: "#333333",
          zIndex: 2000,
        },
        buttonNext: {
          backgroundColor: "#5024dc",
          color: "#ffffff",
          outline: "none",
          border: "none",
        },
        buttonBack: {
          color: "#6c757d",
        },
        buttonClose: {
          display: "none",
        },
      }}
    />
  );
};

export default JoyrideComponent;
