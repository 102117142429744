import { RowDragEvent, RowDropZoneParams, RowStyle } from "ag-grid-community";
import { useEffect } from "react";
import { TRow } from "../../types";
import { useTableState } from "../useTableState.store";
import {
  DEFAULT_FOLDER_OPEN,
  NEW_FOLDER_ID_PLACEHOLDER,
  ROOT_FOLDER_ID,
} from "../../constants";
import { getMoveTarget, warnValue } from "../../utils";

export const useRowStyles = () => {
  const gridApi = useTableState((state) => state.gridApi);
  const folderClosedStatus = useTableState((state) => state.folderClosedStatus);
  const folderMap = useTableState((state) => state.folderMap);
  const showFolders = useTableState((state) => state.showFolders);
  const OnFolderItemMoveToRoot = (e: RowDragEvent<TRow>) => {
    const currentHighlightedElement =
      document.querySelector(".folder-drag-over");
    currentHighlightedElement?.classList.remove("folder-drag-over");
    if (!e.node.data) return;
    if (e.node.data.parentFolder?._id === ROOT_FOLDER_ID) return;
    document.querySelector(".ag-body")?.classList.add("folder-drag-over");
  };

  const OnFolderItemMove = (e: RowDragEvent<TRow>) => {
    e.nodes.forEach((node) => {
      const currentHighlightedElement =
        document.querySelector(".folder-drag-over");
      currentHighlightedElement?.classList.remove("folder-drag-over");

      const moveTarget = getMoveTarget(node, e.overNode || null);
      if (!moveTarget) return;
      if (moveTarget._id === ROOT_FOLDER_ID) return OnFolderItemMoveToRoot(e);

      const targetRowElement = document.querySelector(
        `[row-id="${moveTarget._id}"]`,
      );
      targetRowElement?.classList.add("folder-drag-over");
    });
  };

  const OnFolderItemDrop = async () => {
    const currentHighlightedElement =
      document.querySelector(".folder-drag-over");
    currentHighlightedElement?.classList.remove("folder-drag-over");
  };

  useEffect(() => {
    if (!gridApi) return;
    gridApi.addEventListener("rowDragMove", OnFolderItemMove);
    gridApi.addEventListener("rowDragEnd", OnFolderItemDrop);
    const bodyDropZone: RowDropZoneParams = {
      getContainer: () => document.body,
      onDragging: OnFolderItemMoveToRoot,
      onDragStop: OnFolderItemDrop,
    };
    gridApi.addRowDropZone(bodyDropZone);
    return () => {
      if (gridApi.isDestroyed()) return;
      gridApi.removeRowDropZone(bodyDropZone);
    };
  }, [gridApi]);

  useEffect(() => {
    if (!(gridApi && folderMap)) return;
    const defaultRowClasses = ["table-list-row"];
    gridApi.updateGridOptions({
      getRowClass: (params) => {
        if (!params.data) return;
        const classes = [...defaultRowClasses];
        if (params.data.type === "folder") {
          classes.push("folder");
          if (folderClosedStatus.get(params.data._id) ?? !DEFAULT_FOLDER_OPEN) {
            classes.push("closed");
          } else {
            classes.push("open");
          }
          if (params.data._id === NEW_FOLDER_ID_PLACEHOLDER) {
            classes.push("new");
          }
        }
        return classes;
      },

      getRowStyle: (params) => {
        if (!params.data) return;
        const styles: RowStyle = {};
        const depth = !showFolders
          ? 0
          : params.data.path.length ?? warnValue(0);

        if (params.data.type === "folder") {
          styles.marginLeft = `${(depth - 1) * 2}em`;
          styles.width = `calc(100% - ${(depth - 1) * 2 + 2}em)`;
          styles.zIndex = 100 - depth;
        } else if (params.data.type === "table") {
          styles.width = `calc(100% - ${depth * 2 + 2}em)`;
          styles.marginLeft = `${depth * 2}em`;
        }
        return styles;
      },
    });
  }, [folderMap, folderClosedStatus, gridApi, showFolders]);
};
