/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { useState } from "react";
import {
  useToast,
  Container,
  Input,
  Button,
  Card,
  CardBody,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import userService from "@/services/user.service";
import Icons from "@/components/Icons";

interface ProfileInfo {
  companyURL: string;
  firstName: string;
  jobTitle: string;
  companyDescription: string;
  assistYouWith: string;
  companyTarget: string;
}

interface UserData {
  _id: string;
  Email: string;
  creditLeft: number;
  totalCreditUsed: number;
  creditUsed: number;
  planType: string;
  profileInfo: ProfileInfo;
}

const Credits = () => {
  const toast = useToast({
    position: "top-right",
    isClosable: true,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userCredit, setCreditLeft] = useState(0);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (email: string) => userService.getUserDetailsForAdmins(email),
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    },
    onSuccess: async (response: any) => {
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
        return;
      }
      setEmail("");
      setUserData(response.data?.data);
      setCreditLeft(response.data?.data?.creditLeft || 0);
    },
  });

  const { mutateAsync: updateCreditAsync, isPending: isUpdating } = useMutation(
    {
      mutationFn: (payload: { email: string; credits: number }) =>
        userService.updateUserCredits(payload.email, payload.credits),
      onError: (error) => {
        toast({
          title: error.message,
          status: "error",
          isClosable: true,
        });
      },
      onSuccess: async (response: any) => {
        if (!response.success) {
          toast({
            title: response.message,
            status: "error",
            isClosable: true,
          });
          return;
        }
        setIsEditing(false);
        toast({
          title: response.message,
          status: "success",
          isClosable: true,
        });
      },
    },
  );

  const handleSearch = async () => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidEmail) {
      toast({
        title: "Invalid Email",
        status: "error",
      });
      return;
    }
    setIsEditing(false);
    await mutateAsync(email);
  };

  const handleSave = async () => {
    if (+userData!.creditLeft === +userCredit) {
      setIsEditing(false);
      return;
    }
    const payload = {
      email: userData!.Email,
      credits: userCredit,
    };
    await updateCreditAsync(payload);
  };

  const isDisabled = !email || isPending;

  return (
    <Container maxW="700px" className="mt-10 p-3 shadow-md">
      <h1 className="mb-4 text-center text-lg">User Credits Check / Update</h1>

      <div className="mb-8 flex gap-10">
        <Input
          placeholder="Search by email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setUserData(null);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button
          className={`quick-add !w-[200px] !rounded-md !bg-primary-gradient !px-4 !py-2 !text-white hover:!shadow-md ${
            isDisabled ? "cursor-not-allowed" : ""
          }`}
          disabled={isDisabled}
          onClick={handleSearch}
          isLoading={isPending}
        >
          Search
        </Button>
      </div>

      {!!userData && (
        <Card>
          <CardBody className="flex flex-row items-center justify-between">
            <div>
              <Text fontSize="md">{userData.profileInfo?.firstName}</Text>
              <Text fontSize="sm" className="text-darkGray">
                {userData.Email}
              </Text>
            </div>
            <div className="flex items-center gap-5">
              {isEditing ? (
                <Input
                  value={userCredit}
                  onChange={(e) => {
                    setCreditLeft(+e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSave();
                    }
                  }}
                />
              ) : (
                <div>
                  <Text fontSize="xl">Max: {userCredit}</Text>
                  <Text fontSize="md" color="GrayText">
                    Used: {userData?.creditUsed || 0}
                  </Text>
                </div>
              )}
              {isEditing ? (
                <Button
                  onClick={() => {
                    handleSave();
                  }}
                  isLoading={isUpdating}
                >
                  <Icons.DoneIcon size={25} className=" text-primaryPurple" />
                </Button>
              ) : (
                <Icons.EditIcon
                  size={25}
                  className="cursor-pointer text-primaryPurple"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
              )}
            </div>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default Credits;
