import { Checkbox, Tooltip } from "@chakra-ui/react";
import { countries, TCountryCode } from "countries-list";
import Select from "react-select";
import { useMemo } from "react";
import Icons from "@/components/Icons";
import { ZenRowParams } from "../types";

interface ICustomProxyProps {
  enabled: ZenRowParams["zenRowsProxy"];
  value: ZenRowParams["zenRowsProxyCountry"];
  setValue: (value: ZenRowParams["zenRowsProxyCountry"]) => void;
  setEnabled: (value: ZenRowParams["zenRowsProxy"]) => void;
}
export const CustomProxy = ({
  enabled,
  value,
  setValue,
  setEnabled,
}: ICustomProxyProps) => {
  const countryList = useMemo(
    () =>
      [{ label: "Worldwide", value: "" as TCountryCode }].concat(
        Object.entries(countries).map(([code, country]) => ({
          label: country.name,
          value: code as TCountryCode,
        })),
      ),
    [],
  );
  const selectedOption = useMemo(
    () => countryList.find((c) => c.value === value),
    [countryList, value],
  );
  return (
    <div
      className="border-blue-secondary bg-blue-shadow flex max-w-lg shrink-0 flex-wrap items-center justify-between gap-3 rounded-lg border py-1.5 pl-4 pr-1.5 xl:max-w-none"
      style={{ minHeight: "52px" }}
    >
      <div className="flex items-center">
        <Checkbox
          id="premiumProxy"
          name="premiumProxy"
          className="border-hover-strong focus:ring-hover mr-2"
          onChange={(e) => setEnabled(e.target.checked)}
          isChecked={enabled}
        />
        <label
          className="text-blue mr-1 font-medium"
          style={{ outline: "none" }}
        >
          Premium Proxies
        </label>
        <Tooltip
          className="inline-flex rounded-lg"
          label={
            <div className="w-64 rounded-lg p-3 sm:w-96">
              <p className="mb-4">
                Use premium proxies to make the request harder to block. Useful
                when your request is getting blocked.
              </p>
              <p>
                <a
                  href="https://docs.zenrows.com/features/proxy-ip"
                  target="_blank"
                  className="underline"
                  rel="noopener noreferrer"
                  style={{ outline: "none" }}
                >
                  Learn more
                </a>
                .
              </p>
            </div>
          }
        >
          <div>
            <Icons.InformationIcon className="size-4" />
          </div>
        </Tooltip>
      </div>
      <div>
        {enabled && (
          <div className="inline-flex w-64 max-w-full shrink-0 flex-wrap items-center sm:flex-nowrap xl:w-44 2xl:w-64">
            <div className="w-full">
              <div className="relative">
                <Select
                  value={selectedOption}
                  options={countryList}
                  onChange={(e) => setValue(e!.value as TCountryCode)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
