import { Button } from "@chakra-ui/react";

import Icons from "@/components/Icons";
import JobChangeImport from "@/assets/images/job-change-import.png";
import { useTabState } from "@/pages/AccountMonitoring";

const Step1 = () => {
  const isLoading = useTabState((state) => state.getCurrentLoading());
  const onImport = useTabState(
    (state) => state.getCurrentHandlers().onImport,
  ) as (val: boolean) => void;
  const importButtonTitle =
    useTabState((state) => state.getCurrentMetadata()?.importButtonTitle) ||
    "Import Data";
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold text-gray-800">
          Import Data for Job Change Tracking
        </p>
        <Button
          size={"sm"}
          leftIcon={<Icons.ImportIcon className="text-lg" />}
          isLoading={isLoading}
          onClick={() => {
            onImport?.(true);
          }}
          style={{
            background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
            color: "#fff",
          }}
        >
          <div>{importButtonTitle}</div>
        </Button>
      </div>
      <hr className="border-t-2 border-purple-700 my-4" />
      <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
        <img
          src={JobChangeImport}
          alt="Job Change Import"
          className="rounded-lg mb-4"
        />
      </div>
      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <p className="text-base font-medium text-gray-800">
          Easily import leads using one of the following methods:
        </p>
        <p>
          <span className="font-semibold">1. Quick Add:</span> Simply paste the
          LinkedIn URL of the person.
        </p>
        <p>
          <span className="font-semibold">2. Hubspot Import:</span> Connect your
          HubSpot account to import leads directly.
        </p>
        <p>
          <span className="font-semibold">3. CSV Upload:</span> Upload your list
          of leads using a CSV file.
        </p>
      </div>
    </div>
  );
};

export default Step1;
