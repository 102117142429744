import { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import Swal from "sweetalert2";
import { useNavigate } from "@tanstack/react-router";
import Cookies from "universal-cookie";

import { queryClient } from "@/lib/queryClient";
import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";

import Icons from "../Icons";

type CheckEmailModalType = {
  isOpen: boolean;
  onClose: () => void;
  email: string;
};

export default function CheckEmailModal({
  isOpen,
  onClose,
  email,
}: CheckEmailModalType) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const setCurrentUser = userStore((state) => state.setCurrentUser);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [referralCode, setReferralCode] = useState<string>();

  useEffect(() => {
    if (inputRefs.current.length > 0) {
      inputRefs.current[0].focus();
    }
  }, [inputRefs]);

  useEffect(() => {
    if (!isOpen) {
      setOtpValues(["", "", "", "", "", ""]);
    }
  }, [isOpen]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("ref");
    code && setReferralCode(code);
  }, [location.search]);

  const referralClaim = async (email: string) => {
    try {
      const response = await userService.referralClaim(email);
      const { userId, token } = response;

      const date = new Date();
      date.setHours(date.getHours() + 12);
      cookies.set("externalUserId", email, { path: "/", expires: date });
      cookies.set("questUserId", userId, { path: "/", expires: date });
      cookies.set("questUserToken", token, { path: "/", expires: date });

      await userService.claimCredits(userId, token, referralCode || "");
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  const callToSubmitOtp = async (value: string) => {
    setLoading(true);
    localStorage.removeItem("hideUpgradeModal");
    const verifyOtpResponse = await userService.verifyOtp(email, value);
    if (!verifyOtpResponse?.success) {
      setLoading(false);
      setOtpValues(["", "", "", "", "", ""]);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "The OTP is invalid / expired.", // Update this line
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      inputRefs.current[0].focus();
      return;
    } else {
      const res = await userService.getUser();
      setCurrentUser(res);
      if (verifyOtpResponse?.data?.profileInfo) {
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get("redirect");
        navigate({
          to: redirectUrl ? decodeURIComponent(redirectUrl) : ("/" as const),
          params: {},
        });
        queryClient.refetchQueries({
          queryKey: ["user-credit-details"],
        });
        queryClient.refetchQueries({
          queryKey: ["user-details"],
        });
      } else {
        if (referralCode) {
          referralClaim(email);
        }
        navigate({
          to: "/complete-onboarding/$token",
          params: {
            token: verifyOtpResponse?.data?.token,
          },
        });
      }
      setLoading(false);
    }
  };

  const handleInputChange = (index: number, event: any) => {
    const value = event?.target?.value;

    if (event?.key === "Backspace") {
      setOtpValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = "";
        if (index !== 0) {
          inputRefs.current[index - 1].focus();
        }
        return newValues;
      });
    } else {
      if (/^[0-9a-zA-Z]$/.test(value)) {
        setOtpValues((prevValues) => {
          const newValues = [...prevValues];
          newValues[index] = value;
          if (index !== otpValues.length - 1) {
            inputRefs.current[index + 1].focus();
          } else if (value !== "") {
            const otpText = newValues.join("");
            callToSubmitOtp(otpText);
            return newValues;
          }
          return newValues;
        });
      }
    }
  };

  const onPaste = (event: {
    clipboardData: { getData: (arg0: string) => string };
  }) => {
    const pastedText = event?.clipboardData
      ?.getData("text")
      ?.replace(/\s/g, "");
    const inputValues = pastedText.split("").slice(0, otpValues.length);

    setOtpValues((prevValues) => {
      const newValues = [...prevValues];
      for (let i = 0; i < newValues.length; i++) {
        newValues[i] = inputValues[i] || "";
      }
      return newValues;
    });

    if (pastedText.length >= 6) {
      callToSubmitOtp(pastedText);
    }
  };

  const handleHelpCenter = () => {
    window.open(
      "https://persana-ai.notion.site/Persana-Help-Center-72626a6f86d64b69a0975fd7de7462c4",
    );
  };

  const handleGmailInbox = () => {
    window.open("https://mail.google.com/mail/u/0/#inbox/");
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={"md"}
      scrollBehavior="outside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="p-6" rounded={"3xl"}>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col items-center text-center font-title">
            <p>
              <Icons.MailOpenIcon className="text-5xl text-[#693DC7]" />
            </p>
            <div className="flex flex-col items-center gap-1 py-4 font-title text-xl font-normal">
              <h2 className="font-medium">Please enter the code sent to</h2>
              <p className="flex items-center gap-2 text-lg font-bold">
                {email}
              </p>
            </div>
            <div className="mb-5 mt-3 flex flex-row justify-center px-2 text-center">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el!)}
                  className="form-control m-2 size-10 rounded border text-center focus:outline-primary disabled:cursor-not-allowed disabled:opacity-60"
                  type="number"
                  disabled={loading}
                  value={value}
                  onChange={(e) => handleInputChange(index, e)}
                  onKeyDown={(e) => handleInputChange(index, e)}
                  maxLength={1}
                  onPaste={onPaste}
                />
              ))}
            </div>
            <p
              onClick={handleGmailInbox}
              className="cursor-pointer pb-2 pt-0 text-sm font-bold underline"
            >
              check your inbox
            </p>
            <p>
              Need Help ?{" "}
              <span
                onClick={handleHelpCenter}
                className="cursor-pointer pb-8 pt-4 text-sm font-bold text-primary underline"
              >
                Contact Us
              </span>
            </p>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
