import { ChangeEvent } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
} from "@chakra-ui/react";

import Icons from "../Icons";
import { LinkedinProfileInfo } from "@/types/linkedinProfiles.type";

type Props = {
  profileType: string;
  searchInput: string;
  isButtonDisabled: boolean;
  isLoading: boolean;
  filteredProfiles: LinkedinProfileInfo[];
  isPending: boolean;
  handleProfileChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  handleSearch: (query: string) => void;
  handleDeleteProfiles: () => void;
  handleCreateTable: () => void;
};
const Header = ({
  profileType,
  searchInput,
  isButtonDisabled,
  filteredProfiles,
  isLoading,
  isPending,
  handleProfileChange,
  handleSearch,
  handleDeleteProfiles,
  handleCreateTable,
}: Props) => {
  return (
    <>
      <div className="my-[20px]flex items-center justify-between ">
        <Text className=" text-[22px] font-[600]">
          LinkedIn Profiles{" "}
          <span className="text-[14px] text-darkTextGray">
            ({filteredProfiles?.length} profiles)
          </span>
        </Text>
      </div>
      <div className="my-[20px] flex items-center justify-between pr-[10px]">
        <div className="flex items-center gap-[15px]">
          <Select
            width={"fit-content"}
            onChange={(e) => handleProfileChange(e)}
            value={profileType}
          >
            <option selected value="all">
              All Profiles
            </option>
            <option value="imported">Imported Profiles</option>
            <option value="visited">Visited Profiles</option>
          </Select>
          <InputGroup width="40%">
            <InputLeftElement>
              <Icons.SearchIcon size={20} color="gray" />
            </InputLeftElement>
            <Input
              placeholder="Search Profiles"
              variant="outline"
              onChange={(e) => handleSearch(e.target.value)}
              value={searchInput}
            />
          </InputGroup>
        </div>
        {filteredProfiles?.length ? (
          <div className="flex gap-2 items-center">
            <Button
              isDisabled={isButtonDisabled}
              onClick={() => handleDeleteProfiles()}
              isLoading={isLoading}
              className="rounded-[5px] px-[20px] py-[8px] text-[16px]"
              colorScheme="red"
            >
              Delete
            </Button>
            <Button
              isDisabled={filteredProfiles?.length === 0}
              isLoading={isPending}
              className="rounded-[5px] px-[20px] py-[8px] text-[16px]"
              style={{
                background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
                color: "#fff",
                border: "none",
                fontWeight: 500,
              }}
              onClick={() => handleCreateTable()}
            >
              Create New Table
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Header;
