import AutopilotHubspot from "@/assets/images/auto-pilot-hubspot.png";

const Step3 = () => {
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <p className="text-lg font-semibold text-gray-800">
        Store leads on hubspot (Optional)
      </p>
      <hr className="border-t-2 border-purple-700 my-4" />

      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
          <img
            src={AutopilotHubspot}
            alt="Select Leads"
            className="rounded-lg mb-4"
          />
        </div>
        <p className="text-base font-medium text-gray-800">
          Save and manage your leads directly in HubSpot, ensuring seamless
          integration and easy access to your contact database.
        </p>
        <p>
          <span className="font-semibold">1. Select </span> "Send to Hubspot"
        </p>
        <p>
          <span className="font-semibold">2. Choose </span> "Configuration"
        </p>
        <p>
          <span className="font-semibold">3. Connect Hubspot </span> (If not
          connected already)
        </p>
        <p>
          <span className="font-semibold">4. Enable Hubspot Storage </span>
        </p>
      </div>
    </div>
  );
};

export default Step3;
