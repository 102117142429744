import { FilterType } from "@/types/common.types";
import AvailableGates from "./AvailableGates";
import AvailableFields from "./AvailableFields";
import Conditions from "./Conditions";
import Icons from "@/components/Icons";

interface Props {
  filter: FilterType;
  filters: FilterType[];
  onFilterChange: (filters: FilterType[]) => void;
  fields: FilterType["selectedField"][];
  isShowAddBtn?: boolean;
  onAddFilter?: () => void;
  index?: number;
  conditionOptions?: FilterType["condition"][];
}

function Filters({
  filter,
  filters,
  fields,
  onFilterChange,
  isShowAddBtn,
  onAddFilter,
  index,
  conditionOptions,
}: Props) {
  return (
    <div
      key={`filter_key_${index}`}
      className="flex w-fit items-center gap-x-3"
    >
      <div className="col-condition w-[50px] min-w-[50px] max-w-[50px]">
        {index === 0 ? (
          <p>Where</p>
        ) : (
          <AvailableGates
            filter={filter}
            filters={filters}
            updateFilter={onFilterChange}
          />
        )}
      </div>
      <div className="columns w-[158px] min-w-[158px]">
        <AvailableFields
          filter={filter}
          updateFilter={onFilterChange}
          filters={filters}
          fields={fields}
        />
      </div>
      <div className="conditions  w-[92px] min-w-[92px]">
        <Conditions
          filter={filter}
          filters={filters}
          updateFilter={onFilterChange}
          conditionOptions={conditionOptions}
        />
      </div>

      <div className="input-field w-[168px] min-w-[168px]">
        {filter.condition.hasInput && (
          <input
            placeholder="Enter value"
            className="border-gray-500 flex-1 rounded border py-[3px] pl-1.5 text-sm font-medium text-[#000000]/90 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primary"
            value={filter.value}
            onChange={(e) => {
              let value = e.target.value.toString() as string | number;

              // @ts-ignore
              if (/^\d+$/.test(value)) {
                value = Number(value);
              }

              const updatedFilters = filters?.map((item) =>
                item.id === filter.id ? { ...item, value } : item,
              );

              onFilterChange(updatedFilters);
            }}
          />
        )}
      </div>
      {isShowAddBtn && filters.length - 1 === index ? (
        <button
          onClick={onAddFilter}
          className="flex items-center text-sm font-title space-x-1 font-medium"
        >
          <Icons.FilterAddIcon />
          <span>Add More</span>
        </button>
      ) : (
        <button
          onClick={() => {
            const updatedFilters = filters.filter((f) => f.id !== filter.id);
            onFilterChange(updatedFilters);
          }}
          className="ml-2 w-6 h-6 rounded-full flex justify-center items-center border-dark border hover:text-red-500 duration-300 hover:border-red-500"
        >
          <Icons.DeleteIcon />
        </button>
      )}
    </div>
  );
}

export default Filters;
