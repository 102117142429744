import MakeAPICall from "@/lib/apiCalls";
import { LinkedinProfilesType } from "@/types/linkedinProfiles.type";

class LinkedinProfilesService {
  constructor(private apiCaller = MakeAPICall) {}

  async getLinkedinProfiles() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/import-linkedin-profiles-info`,
    });
    const response = await apiCall.get<LinkedinProfilesType>();
    return response;
  }

  async deleteLinkedinProfiles(selectedProfileIds: string[]) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/delete-imported-linkedin-profiles`,
      payload: {
        profileIds: selectedProfileIds,
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async createTable(payload: {
    tableName?: string;
    selectedRowsIds?: string[];
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "chrome-extension/add-into-table",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }
}

const linkedinProfilesService = new LinkedinProfilesService();
export default linkedinProfilesService;
