export const topupOptions = [
  {
    value: "500",
    label: "500",
  },
  {
    value: "750",
    label: "750",
  },
  {
    value: "1000",
    label: "1000",
  },
  {
    value: "1500",
    label: "1500",
  },
  {
    value: "2000",
    label: "2000",
  },
  {
    value: "2500",
    label: "2500",
  },
  {
    value: "3000",
    label: "3000",
    limit: "starter",
  },
  {
    value: "3500",
    label: "3500",
  },
  {
    value: "4000",
    label: "4000",
  },
  {
    value: "4500",
    label: "4500",
  },
  {
    value: "5000",
    label: "5000",
  },
  {
    value: "5500",
    label: "5500",
  },
  {
    value: "6000",
    label: "6000",
  },
  {
    value: "6500",
    label: "6500",
  },
  {
    value: "7000",
    label: "7000",
  },
  {
    value: "7500",
    label: "7500",
  },
  {
    value: "8000",
    label: "8000",
  },
  {
    value: "8500",
    label: "8500",
  },
  {
    value: "9000",
    label: "9000",
  },
  {
    value: "9500",
    label: "9500",
  },
  {
    value: "10000",
    label: "10000",
  },
];
