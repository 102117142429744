import { useState } from "react";
import { Button, ButtonGroup, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

import AccordionButton from "../Common/AccordionButton";
import PersanaInput from "../Common/PersanaInput";
import SearchInputAccordion from "../Common/SearchInputAccordion";
import Icons from "../Icons";
import RadioButton from "../Common/RadioButton";
import { Option } from "../Common/MultiSelectDropdown/types";

import { filterValidObjKeys, generateId } from "@/utils";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { IScraperCompanySearchPayload } from "@/types/leads.types";
import leadService from "@/services/leads.service";
import CompanyTypes from "./CompanyTypes";
import CompanySizes from "./CompanySizes";
import Countries from "./Countries";
import Industries from "./Industries";
import Revenue from "./Revenue";

const CompanySidebarComponent = ({
  isForAutoPilot = false,
}: {
  isForAutoPilot?: boolean;
}) => {
  const toast = useToast();
  const [isUseMixRank] = useState(true);
  const [inputtedValues, setInputtedValues] = useState({
    company_type: [],
    minimun_company_size: "",
    maximum_company_size: "",
    year_founded: "",
    isGreaterYearFounded: false,
    isSmallerYearFounded: false,
    name: "",
    company_name_prefix: "",
    website: "",
    followers: "",
    isMoreFollowers: false,
    isLessFollowers: false,
    revenue: "",
    isMoreRevenue: true,
    isLessRevenue: false,
    keyword: "",
    country_codes: [],
    industries: [],
    excluded_industries: [],
    company_size: null,
    company_sizes: [],
    company_types: [],
    city_to_include: "",
    city_to_exclude: "",
    //TODO: Add this
    //description_keywords: "",
  });

  const { mutateAsync } = useMutation({
    mutationFn: ({
      payload,
      params = {},
    }: {
      payload: IScraperCompanySearchPayload;
      params?: any;
    }) =>
      leadService.searchCompanyIscraper(
        payload,
        "leads/search-company-linkedin",
        params,
      ),
  });
  const isSearching = useSearchLeadsStore((state) => state.isSearching);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);

  const handleInputChange = (
    e: any,
    stateToUpdate: any,
    setStateToUpdate: any,
  ) => {
    setStateToUpdate({
      ...stateToUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handleToggleSelect = (
    e: any,
    stateToUpdate: any,
    setStateToUpdate: any,
    rivalSelect: any,
  ) => {
    setStateToUpdate({
      ...stateToUpdate,
      [e.target.name]: !stateToUpdate[e.target.name],
      [rivalSelect]: false,
    });
  };

  const handlePushToArray = (
    value: any,
    stateToUpdate: any,
    setStateToUpdate: any,
  ) => {
    if (value?.name === "company_type" && value?.value?.length > 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can only select one company type",
      });
      return;
    }
    setStateToUpdate({
      ...stateToUpdate,
      [value?.name]: value?.value,
    });
  };

  function compareValues(more: boolean, less: boolean, value: string) {
    if (!more && !less) {
      return `eq:${value}`;
    } else if (more) {
      return `gt:${value}`;
    } else if (less) {
      return `lt:${value}`;
    }
  }

  const handleSearch = async () => {
    updateLeadState({ isSearching: true });
    const convertedInputtedData = {
      per_page: 100,
      company_sizes:
        inputtedValues?.company_sizes?.map(
          (company: Option) => company.value,
        ) || [],
      company_types:
        inputtedValues?.company_types?.map(
          (company: Option) => company.value,
        ) || [],
      //TODO: Update this
      // company_size: (inputtedValues?.company_size || [])
      //   ?.map((item: Option) => item?.value),
      year_founded: inputtedValues?.year_founded
        ? compareValues(
            inputtedValues?.isGreaterYearFounded,
            inputtedValues?.isSmallerYearFounded,
            inputtedValues?.year_founded,
          )
        : "",
      website: inputtedValues?.website,
      name: inputtedValues?.name,
      company_name_prefix: inputtedValues?.company_name_prefix,
      //TODO: Add this
      //description: inputtedValues?.description_keywords,
      followers: inputtedValues?.followers
        ? compareValues(
            inputtedValues?.isMoreFollowers,
            inputtedValues?.isLessFollowers,
            inputtedValues?.followers,
          )
        : "",
      revenue: inputtedValues?.revenue
        ? compareValues(
            inputtedValues?.isMoreRevenue,
            inputtedValues?.isLessRevenue,
            inputtedValues?.revenue,
          )
        : "",
      keyword: inputtedValues?.keyword,
      country_codes: inputtedValues?.country_codes?.map(
        (item: Option) => item?.value,
      ),
      industry_ids: inputtedValues?.industries?.map(
        (item: Option) => +item?.value,
      ),
      excluded_industry_ids: inputtedValues?.excluded_industries?.map(
        (item: Option) => +item?.value,
      ),
      city_to_include: inputtedValues?.city_to_include,
      city_to_exclude: inputtedValues?.city_to_exclude,
    };

    const validInputtedData = filterValidObjKeys(convertedInputtedData);

    if (Object.keys(validInputtedData || {}).length === 0) {
      toast({
        title: "No data to search",
        description: "Please input some data to search",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
      return;
    }

    await mutateAsync(
      {
        payload: validInputtedData,
        ...(isUseMixRank ? { params: { isUseMixrank: true } } : {}),
      },
      {
        onSuccess: (response) => {
          updateLeadState({
            currentSearchPayload: validInputtedData,
            isSearching: false,
            searchLeadsResult: {
              profiles: response?.data.profiles || [],
              // @ts-ignore
              pagination: {
                ...response?.data.pagination,
                // @ts-ignore
                page: response?.data?.pagination?.current_page,
              } || {
                total_entries: 0,
                total_pages: 0,
                page: 1,
                per_page: 100,
              },
              apiPath: `leads/search-company-linkedin?per_page=${response?.data?.providerName === "iScraper" ? 100 : 25}&want_to_import=true&providerName=${response?.data?.providerName}${
                response?.data?.isUseMixrank ? "&isUseMixrank=true" : ""
              }`,
              columsMapping: response?.data?.columsMapping || [],
            },
          });
          console.log(response, "response");
        },
        onError: (error) => {
          updateLeadState({ isSearching: false });
          console.log(error, "error");
        },
      },
    );
  };

  return (
    <div className=" flex size-full h-full max-h-screen flex-col justify-between">
      <div
        className={`grow space-y-4 overflow-y-auto bg-white px-2 py-4 ${isForAutoPilot ? "h-full" : "h-[5rem]"}`}
      >
        {/* Keyword */}
        <SearchInputAccordion
          defaultIndex={0}
          button={
            <AccordionButton
              icon={<Icons.FileSearchIcon className="text-lg" color="grey" />}
            >
              Keywords
            </AccordionButton>
          }
        >
          <div className="space-y-3">
            <PersanaInput
              value={inputtedValues?.keyword}
              placeholder={""}
              name={"keyword"}
              label={"Keywords"}
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
              description="If a keyword is provided, we will return results that match the given keyword. The match can be partial or complete. This allows you to effectively search and filter through the results based on specific search terms or phrases."
            />
          </div>
        </SearchInputAccordion>

        {/* Companies */}
        <SearchInputAccordion
          button={
            <AccordionButton
              icon={<Icons.FaceIcon className="text-lg" color="grey" />}
            >
              Companies{" "}
            </AccordionButton>
          }
        >
          <div className="space-y-3">
            <PersanaInput
              value={inputtedValues?.name}
              placeholder={"e.g. google"}
              name={"name"}
              label={"Company Name"}
              type="text"
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
            />
            <PersanaInput
              value={inputtedValues?.website}
              placeholder={"e.g. example.com"}
              name={"website"}
              label={"Company Website"}
              type="text"
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
            />
            {/* TODO: Add this */}
            {/* <PersanaInput
              value={inputtedValues?.description_keywords}
              placeholder={"e.g. sales, data, outbound"}
              name={"description_keywords"}
              label={"Include description keywords"}
              type="text"
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
            /> */}
            <CompanyTypes
              value={inputtedValues.company_types}
              onChange={(selectedValues) => {
                handlePushToArray(
                  { name: "company_types", value: selectedValues },
                  inputtedValues,
                  setInputtedValues,
                );
              }}
            />
            <CompanySizes
              value={inputtedValues.company_sizes as unknown as Option}
              onChange={(selectedValues) => {
                handlePushToArray(
                  { name: "company_sizes", value: selectedValues },
                  inputtedValues,
                  setInputtedValues,
                );
              }}
            />
            <PersanaInput
              value={inputtedValues?.year_founded}
              optionComponent={
                <div className="flex items-center gap-x-3">
                  <RadioButton
                    label={"Greater than"}
                    name={"isGreaterYearFounded"}
                    checked={inputtedValues?.isGreaterYearFounded}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isSmallerYearFounded",
                      );
                    }}
                  />
                  <RadioButton
                    label={"Less than"}
                    name={"isSmallerYearFounded"}
                    checked={inputtedValues?.isSmallerYearFounded}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isGreaterYearFounded",
                      );
                    }}
                  />
                </div>
              }
              placeholder={"e.g. 1999"}
              name={"year_founded"}
              label={"Founded On"}
              type="number"
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
            />
            <PersanaInput
              value={inputtedValues?.followers}
              optionComponent={
                <div className="flex items-center gap-x-3">
                  <RadioButton
                    label={"Greater than"}
                    name={"isMoreFollowers"}
                    checked={inputtedValues?.isMoreFollowers}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isLessFollowers",
                      );
                    }}
                  />
                  <RadioButton
                    label={"Less than"}
                    name={"isLessFollowers"}
                    checked={inputtedValues?.isLessFollowers}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isMoreFollowers",
                      );
                    }}
                  />
                </div>
              }
              placeholder={"e.g. 1000"}
              name={"followers"}
              label={"Followers"}
              type="number"
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
            />
            <div>
              <div className="flex justify-between pb-2">
                <p className="font-medium">Revenue</p>
                <div className="flex items-center gap-x-3">
                  <RadioButton
                    label={"Greater than"}
                    name={"isMoreRevenue"}
                    checked={inputtedValues?.isMoreRevenue}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isLessRevenue",
                      );
                    }}
                  />
                  <RadioButton
                    label={"Less than"}
                    name={"isLessRevenue"}
                    checked={inputtedValues?.isLessRevenue}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isMoreRevenue",
                      );
                    }}
                  />
                </div>
              </div>
              <Revenue
                value={inputtedValues.revenue}
                onChange={(value: string) => {
                  setInputtedValues({
                    ...inputtedValues,
                    revenue: value,
                  });
                }}
              />
            </div>
            <PersanaInput
              value={inputtedValues?.company_name_prefix}
              placeholder={"e.g. google"}
              name={"company_name_prefix"}
              label={"Company Name Prefix"}
              type="text"
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
            />
          </div>
        </SearchInputAccordion>

        {/* Locations */}
        <SearchInputAccordion
          button={
            <AccordionButton
              icon={<Icons.LocationOnIcon className="text-lg" color="grey" />}
            >
              Locations
            </AccordionButton>
          }
        >
          <div className="space-y-3">
            <Countries
              label="Select one or more countries"
              value={inputtedValues.country_codes as unknown as Option}
              onChange={(selectedValues) => {
                handlePushToArray(
                  { name: "country_codes", value: selectedValues },
                  inputtedValues,
                  setInputtedValues,
                );
              }}
            />
            {isUseMixRank && (
              <>
                <PersanaInput
                  value={inputtedValues?.city_to_include}
                  placeholder={"e.g. San Francisco"}
                  name={"city_to_include"}
                  label={"Include city or state"}
                  onChange={(e) => {
                    handleInputChange(e, inputtedValues, setInputtedValues);
                  }}
                />
                <PersanaInput
                  value={inputtedValues?.city_to_exclude}
                  placeholder={"e.g. New York"}
                  name={"city_to_exclude"}
                  label={"Exclude city or state"}
                  onChange={(e) => {
                    handleInputChange(e, inputtedValues, setInputtedValues);
                  }}
                />
              </>
            )}
          </div>
        </SearchInputAccordion>

        {/* Industries */}
        <SearchInputAccordion
          button={
            <AccordionButton
              icon={<Icons.IndustryIcon className="text-lg" color="grey" />}
            >
              Industries
            </AccordionButton>
          }
        >
          <Industries
            value={inputtedValues.industries as unknown as Option}
            onChange={(selectedValues) => {
              handlePushToArray(
                { name: "industries", value: selectedValues },
                inputtedValues,
                setInputtedValues,
              );
            }}
          />
          <Industries
            value={inputtedValues.excluded_industries as unknown as Option}
            onChange={(selectedValues) => {
              handlePushToArray(
                { name: "excluded_industries", value: selectedValues },
                inputtedValues,
                setInputtedValues,
              );
            }}
            label="Excluded Industries"
          />
        </SearchInputAccordion>
      </div>
      {!isForAutoPilot && (
        <ButtonGroup className="!bg-white p-4 !font-title">
          <Button
            // isDisabled={totalFilters <= 0}
            isLoading={isSearching}
            id={generateId("persana-company-search-button")}
            loadingText="Searching"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>
          {/* <IconButton
            colorScheme="gray"
            // isDisabled={totalFilters <= 0}
            aria-label="Save Filters"
            icon={<Icons.SaveIcon />}
            onClick={() => {
              saveFilterModalDisclosure.onOpen();
            }}
          /> */}
        </ButtonGroup>
      )}
    </div>
  );
};

export default CompanySidebarComponent;
