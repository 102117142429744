import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { useModalStore } from "@/stores/modal.store";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button,
  NumberInput,
  NumberInputField,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { useMemo, useState } from "react";
import { useGetHubspotLists } from "./hooks";

const HubspotContactImportModal = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfLeads, setNumberOfLeads] = useState(100);
  const [selectedList, setSelectedList] = useState<Option | null>(null);

  const { isLoading: isHubspotListFetching, data } = useGetHubspotLists();

  const listData = useMemo(() => {
    if (!data || !data?.lists?.length) return [];
    return data.lists.map((list) => ({
      label: list.name,
      value: list.listId,
    }));
  }, [data]);

  const onClose = () => {
    setNumberOfLeads(1);
    updateState({
      isOpenModal: false,
      modalData: {
        modalType: "",
        metaData: {},
      },
    });
  };

  const handleImport = async () => {
    setIsLoading(true);
    const res = await accountMonitoringService.hubspotContactImport(
      numberOfLeads,
      selectedList?.value as string,
    );

    if (res?.success) {
      const tableId = res?.data?.data?.tableId;
      if (tableId) {
        onClose();
        navigate({
          to: "/table/$tableId",
          params: {
            tableId,
          },
        });
      } else {
        toast({
          position: "top-right",
          title: "Contacts not found",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      toast({
        position: "top-right",
        title: res?.data?.message || "Something went wrong",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      onClose();
    }
    setIsLoading(false);
    setNumberOfLeads(100);
  };

  return (
    <>
      <Modal
        onClose={() => {
          onClose();
        }}
        isOpen={isOpenModal && modalData.modalType === "hubspot-contact-import"}
        size={"2xl"}
        scrollBehavior="outside"
        isCentered
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent className="pb-4 font-poppins">
          <ModalHeader className="border-b-dividerColor border-b-[1px] text-center">
            <div className="d-flex items-center">
              <Text className="flex-1">Hubspot Contact Import</Text>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mt-4 flex items-center gap-2 pb-1">
              <p className="text-sm font-semibold ">Number of leads to fetch</p>
            </div>
            <div className="relative h-full">
              <NumberInput
                min={0}
                value={numberOfLeads}
                onChange={(val) => {
                  setNumberOfLeads(Number(val));
                }}
              >
                <NumberInputField />
              </NumberInput>
            </div>
            <div className="mt-4 flex items-center gap-2 pb-1">
              <p className="text-sm font-semibold ">Select List</p>
            </div>
            <MultiSelectDropdown
              isMulti={false}
              placeholder="Select List"
              closeMenuOnSelect
              options={listData}
              value={selectedList}
              onChange={(selected) => setSelectedList(selected as Option)}
              isLoading={isHubspotListFetching}
            />

            <div className="mt-[20px] flex w-full items-center justify-center">
              <Button
                isDisabled={!numberOfLeads || !selectedList}
                isLoading={isLoading}
                variant="solid"
                h={"38px"}
                fontSize={14}
                className="!bg-primary-gradient hover:opacity-80"
                colorScheme="primary"
                borderRadius={"6px"}
                onClick={() => {
                  handleImport();
                }}
              >
                <span className="font-semibold">Import</span>
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HubspotContactImportModal;
