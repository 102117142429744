import Icons from '@/components/Icons';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';

interface Props {
    handleCopyToClipboard: (value: string) => void;
    handleAddNewColumn: (value: {}) => void;
    path: string;
    itemKey: string;
    value: string;
    isOpen: boolean;
    onToggle: () => void;
    onClose: () => void;
    newColumnName: string;
    setNewColumnName: (value: string) => void;
    isObjectKey?: boolean;
}

function ActionButtons({
    handleCopyToClipboard,
    value,
    handleAddNewColumn,
    path,
    itemKey,
    isOpen,
    onToggle,
    onClose,
    newColumnName,
    setNewColumnName,
    isObjectKey,
}: Props) {
    return (
        <div className="absolute right-0 top-[3px] flex h-fit items-center gap-x-2 text-sm">
            <button
                className="rounded bg-[#E7E8EC] px-2 py-1.5"
                onClick={() => {
                    if (handleCopyToClipboard) {
                        handleCopyToClipboard(value);
                    }
                }}
            >
                <Icons.CopyIconFa />
            </button>

            <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                    <button
                        onClick={() => {
                            onToggle();
                            // handleAddAsAnewColumn();
                        }}
                        className="flex items-center gap-x-1 rounded bg-[#E7E8EC] px-2 py-1 text-[12px] font-semibold"
                    >
                        <span>Add as column</span> <Icons.ChevronDownIcon />
                    </button>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent top={"-8px"} shadow={"xl"}>
                        <PopoverBody className="!font-title">
                            <p className="text-xs font-medium">Add as a new column</p>
                            <div className="grid grid-cols-[repeat(1,_62%_1fr)] gap-x-1.5 pt-1.5">
                                <div className="">
                                    <input
                                        value={newColumnName}
                                        onChange={(e) => setNewColumnName(e.target.value)}
                                        type="text"
                                        className="border-gray-400 w-full rounded border px-2 py-1 text-sm font-medium focus:border-primary focus:outline-none"
                                    />
                                </div>
                                <button
                                    onClick={() => {
                                        if (handleAddNewColumn) {
                                            handleAddNewColumn({ path, newColumnName, itemKey, isObjectKey });
                                        }
                                        onClose();
                                    }}
                                    // isLoading={isSearching}
                                    className="w-full rounded-md bg-[#1e86ff] py-1 font-title text-sm !font-semibold tracking-wide text-white"
                                >
                                    Add Column
                                </button>
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </div>
    )
}

export default ActionButtons
