import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiKeyService from "@/services/api-keys.service";

const useCreateApiKey = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      name,
      validFor,
      userId,
    }: {
      name: string;
      validFor?: number;
      userId?: string;
    }) => apiKeyService.createApiKey({ name, validFor, userId }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["apiKeys", ...(data.data?.userId ? [data.data.userId] : [])],
      });
    },
  });
};

export default useCreateApiKey;
