import { z } from "zod";
import { FileRoute } from "@tanstack/react-router";

import Oauth from "@/pages/Oauth";

export const Route = new FileRoute("/oauth/$provider").createRoute({
  parseParams: (params) => ({
    provider: z.string().parse(String(params.provider)),
  }),
  component: OauthPage,
});

function OauthPage() {
  const params = Route.useParams();
  return <Oauth provider={params.provider} />;
}

export default OauthPage;
