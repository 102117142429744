import AutopilotSlack from "@/assets/images/auto-pilot-slack.png";

const Step5 = () => {
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <p className="text-lg font-semibold text-gray-800">
        Get slack alerts (Optional)
      </p>
      <hr className="border-t-2 border-purple-700 my-4" />

      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
          <img
            src={AutopilotSlack}
            alt="Select Leads"
            className="rounded-lg mb-4"
          />
        </div>
        <p className="text-base font-medium text-gray-800">
          Receive Slack alerts for new leads discovered through your weekly
          searches, keeping you informed and ready to take action.
        </p>
        <p>
          <span className="font-semibold">1. Select </span> "Slack
          Notifications"
        </p>
        <p>
          <span className="font-semibold">2. Choose </span> "Configuration"
        </p>
        <p>
          <span className="font-semibold">3. Connect Slack </span> (If not
          connected already)
        </p>
        <p>
          <span className="font-semibold">4. Enable Slack Alerts </span>
        </p>
      </div>
    </div>
  );
};

export default Step5;
