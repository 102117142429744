import APIKeyIntegration from "./APIKeyIntegration";
import ForagerLogo from "@/assets/svgs/forager.svg";

const ForagerIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"foragerApiKey"}
      providerTitle="Forager"
      providerIcon={ForagerLogo}
    />
  );
};

export default ForagerIntegration;
