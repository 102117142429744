import { isValidEmail } from "@/lib/utils";
import userService from "@/services/user.service";
import { useQuery } from "@tanstack/react-query";

const useGetUserDetailsForAdmins = (email: string) => {
  return useQuery({
    queryKey: ["userDetails", email],
    queryFn: async () => {
      if (email) {
        const response = await userService.getUserDetailsForAdmins(email);
        return response.data;
      }
    },
    enabled: isValidEmail(email),
  });
};

export default useGetUserDetailsForAdmins;
