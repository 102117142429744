import { useEffect } from "react";
import { FileRoute, useNavigate } from "@tanstack/react-router";
import { useQueryClient } from "@tanstack/react-query";

import AnimatedLoader from "@/components/Icons/SvgIcons/AnimatedLoaders";
import userService from "@/services/user.service";

export const Route = new FileRoute("/admin/user_login").createRoute({
  component: UserLoginPage,
});

function UserLoginPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("userEmail") || "";
  const token = searchParams.get("token") || "";

  const handleUserLogin = async () => {
    localStorage.removeItem("hideUpgradeModal");

    const res = await userService.userLogin(email, token);
    if (res.success) {
      await queryClient.refetchQueries({
        queryKey: ["user-credit-details"],
      });
      await queryClient.refetchQueries({
        queryKey: ["user-details"],
      });
    } else {
      navigate({ to: "/login" });
    }
  };

  useEffect(() => {
    handleUserLogin();
  }, []);

  useEffect(() => {
    if (queryClient.getQueryData(["user-details"])) {
      navigate({ to: "/" });
    }
  }, []);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="rounded-md bg-white px-20 py-10 shadow-md">
        <p className="font-title text-2xl font-bold">Verifying</p>
        <AnimatedLoader className="size-12" />
      </div>
    </div>
  );
}

export default UserLoginPage;
