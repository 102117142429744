import sorryEmoji from "@/assets/animated/sorryEmoji.lottie";
import { useModalStore } from "@/stores/modal.store";
import { Box } from "@chakra-ui/react";
import { DotLottiePlayer } from "@dotlottie/react-player";

export function PayWall() {
  const updateModalState = useModalStore((state) => state.updateState);

  return (
    <div className="mt-20 flex items-center justify-center rounded-[2rem] bg-[rgba(255,_255,_255,_0.4)] backdrop-blur-[7px]">
      <Box
        w={500}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        className="flex flex-col items-center justify-center gap-4 px-10 py-5"
      >
        <div className="w-[120px]">
          <DotLottiePlayer
            src={sorryEmoji}
            autoplay
            loop
            style={{ height: "auto", width: "auto" }}
          />
        </div>
        <p className="text-center font-[400]">
          This feature is available only to Pro users. To access it, please
          upgrade your plan to the Pro tier using the button below. If you need
          any help,{" "}
          <a
            href="https://join.slack.com/t/persanaaicommunity/shared_invite/zt-2hkyc3iaf-HQYWmcTIQnT9I6tcou4XEA"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            join our community on Slack
          </a>
          .
        </p>
        <div>
          <button
            onClick={() => {
              updateModalState({
                isOpenModal: true,
                modalData: {
                  modalType: "pricing",
                  action: "change",
                },
              });
            }}
            className="gradientBg rounded px-[14px] py-[4px] text-base text-white"
          >
            Upgrade Now
          </button>
        </div>
      </Box>
    </div>
  );
}
