import { PlanType } from "@/types/user.types";

export const planLabelMap = {
  Free: "Free",
  growth: "Growth",
  pro: "Pro",
  persana_pro: "Persana Pro",
  starter: "Starter",
  unlimited: "Unlimited",
} as {
  [key in PlanType]: string;
};
