import AutoPilotLandingPage from "@/pages/AutoPilotLandingPage";
import { userStore } from "@/stores/user.store";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/auto-pilot/").createRoute({
  component: AutoPilotPage,
  errorComponent: () => <div>Error</div>,
});

function AutoPilotPage() {
  const currentUser = userStore((state) => state.currentUser);
  const isAdmin = currentUser.role === "admin";

  if (!isAdmin) {
    return <div>Not Found</div>;
  }
  return <AutoPilotLandingPage />;
}

export default AutoPilotPage;
