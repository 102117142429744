import { useCheckbox, Box, Stack } from "@chakra-ui/react";
import { RadioCardProps } from "./types";

export const RadioCard = ({ children, ...props }: RadioCardProps) => {
  const { getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props);

  return (
    <Box
      as="label"
      className="!h-full grow basis-0"
      {...getLabelProps()}
      {...htmlProps}
      tabIndex={1}
    >
      <input tabIndex={2} {...getInputProps()} hidden />
      <Stack
        tabIndex={1}
        placeItems={"center"}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "#3182CE",
          color: "white",
          borderColor: "#CBD5E0",
          boxShadow: "2xl",
          "& input,textarea": {
            color: "#3182CE",
            bg: "white",
          },
        }}
        _focusWithin={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        {...getCheckboxProps()}
      >
        {children}
      </Stack>
    </Box>
  );
};
