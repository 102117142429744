import { Team } from "@/types/team.types";
import { UserData } from "@/types/user.types";
import { Box } from "@chakra-ui/react";

function WorkspaceCard({ team }: { team: Team }) {
  const teamAdmin = team.members.find((member) => member.role === "admin");

  return (
    <Box className="shadow-lg py-3 px-5 rounded-md border border-textPurple mt-4 !font-title space-y-2">
      <h3 className="text-xl font-semibold capitalize">{team.name}</h3>
      <p className="font-medium">
        Admin Name :{" "}
        {(teamAdmin?.userId as UserData).profileInfo?.firstName || ""}{" "}
        {(teamAdmin?.userId as UserData).profileInfo?.lastName || ""}
      </p>
      <p>Admin Email : {(teamAdmin?.userId as UserData).Email || ""}</p>
      <p className="font-medium">Members : {team.members.length}</p>
    </Box>
  );
}

export default WorkspaceCard;
