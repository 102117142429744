import { GridApi, GridOptions } from "ag-grid-community";
import { useEffect } from "react";
import { UseDataSourceParams } from "../types";
import { useTableStore } from "./useGridState.store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useConst } from "@chakra-ui/react";
import { CLIENT_DATA_SOURCE_PRELOAD } from "../../constants";
import { buildUrlParamsPaginated } from "../functions/buildUrlParamsPaginated";

export function useDataSourceClientSide<T>(params: UseDataSourceParams<T>) {
  const queryClient = useQueryClient();
  const gridApi = useTableStore((state) => state.gridApi) as GridApi<T>;
  const setLoading = useTableStore((state) => state.setLoading);
  const updateState = useTableStore((state) => state.updateState);
  const query = useQuery({
    queryKey: ["dataSource", params.id] as const,
    queryFn: async () => {
      const urlParams = buildUrlParamsPaginated({
        ...(CLIENT_DATA_SOURCE_PRELOAD
          ? { pagination: { pageSize: 50 }, sortModel: [{ updatedAt: "desc" }] }
          : null),
      });
      const result = await params.dataSource({
        ...urlParams,
        includeCount: true,
      });
      const totalCount = result.totalDataCount ?? 0;
      if (CLIENT_DATA_SOURCE_PRELOAD && totalCount > 50) {
        const urlParams = buildUrlParamsPaginated({
          pagination: { pageSize: totalCount },
        });
        params.dataSource(urlParams).then((result) => {
          queryClient.setQueryData(["dataSource", params.id], result);
        });
      }
      const extraRows = CLIENT_DATA_SOURCE_PRELOAD
        ? (Array.from({ length: totalCount - 50 }).map((_, index) => ({
            id: `extra-${index}`,
          })) as T[])
        : [];
      return {
        ...result,
        data: [...(result.data ?? []), ...extraRows],
        totalDataCount: totalCount,
      };
    },
  });
  // set data refresh function
  useEffect(() => {
    updateState({
      refreshData: async () => {
        setLoading(true);
        await query.refetch();
        setLoading(false);
      },
    });
  }, [query.refetch]);

  // handle row data
  useEffect(() => {
    if (!gridApi) return;
    if (query.isLoading || !query.data?.data) {
      gridApi?.showLoadingOverlay();
      setLoading(true);
      return;
    } else {
      gridApi?.updateGridOptions({
        pagination: true,
        animateRows: false,
        paginationPageSize: 100,
        rowData: query.data?.data ?? [],
      });

      if (query.data?.data.length === 0) {
        gridApi?.showNoRowsOverlay();
      } else {
        gridApi?.hideOverlay();
      }
      setLoading(false);
    }
  }, [query.data, query.isLoading]);

  return useConst<Partial<GridOptions<T>>>(() => ({}));
}
