import { useState } from "react";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import Icons from "../Icons";
import {
  allTemplates,
  TemplateGroup,
  TemplateTypes,
} from "../Leads/UseTemplateModal/constants";
import { AnimatePresence, motion } from "framer-motion";
import PersanaLogo from "@/assets/images/logo.png";
import { PlaybookTagFilter } from "./filter";
const Templates = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<TemplateGroup | null>(
    null,
  );

  const [templates, setTemplates] = useState(allTemplates);
  const handleFilterChange = (
    query: string,
    selectedGroup: TemplateGroup | null,
  ) => {
    setSearchValue(query);
    setSelectedGroup(selectedGroup);
    let filteredTemplates: TemplateTypes[] = allTemplates;
    if (selectedGroup) {
      filteredTemplates = allTemplates.filter(
        (item) => item.group === selectedGroup,
      );
    }
    if (query) {
      filteredTemplates = filteredTemplates.filter(
        (item) =>
          item.heading
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()) ||
          item.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
      );
    }
    setTemplates(filteredTemplates);
  };
  return (
    <div>
      <div className="flex flex-col items-center justify-center gap-4 bg-white p-[32px]">
        <span className="flex flex-row gap-4 text-[3em] font-bold ">
          <img src={PersanaLogo} className="size-[1em]" alt="" />
          Playbooks
        </span>
        <InputGroup width="80%">
          <InputLeftElement>
            <Icons.SearchIcon size={20} color="gray" className="ml-2 mt-2" />
          </InputLeftElement>
          <Input
            rounded={"full"}
            placeholder="Search playbooks..."
            size="lg"
            className="border   focus-within:!ring-[#9634db]"
            value={searchValue}
            onChange={(event) => {
              handleFilterChange(event?.target.value, selectedGroup);
            }}
          />
        </InputGroup>
      </div>
      <PlaybookTagFilter
        selectedGroup={selectedGroup}
        setSelectedGroup={(group) => handleFilterChange(searchValue, group)}
      />
      {templates.length === 0 ? (
        <Box className="flex !h-[50vh] !w-full flex-row place-content-center items-center ">
          <Icons.TbFilterX className="size-32 opacity-10" />
        </Box>
      ) : (
        <div className="grid select-none grid-cols-1 gap-5 px-[32px] py-[16px] sm:grid-cols-2 md:grid-cols-4">
          {templates.map((item) => (
            <AnimatePresence key={item.templateId}>
              <motion.div
                onClick={() => {
                  navigate({
                    to: `/playbooks/$templateId`,
                    params: {
                      templateId: item.heading,
                    },
                  });
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="dark:!bg-navy-700  flex w-full flex-col justify-between overflow-hidden rounded-[12px]  px-[24px] py-[32px] ring-1 ring-[#a7a7a756] hover:shadow-xl hover:ring-[#bf3fdc]"
              >
                <div className="mb-4 flex flex-col gap-[20px]">
                  {/* <img src={PersanaLogo} height={30} width={30} /> */}
                  {/* <FaBuildingCircleCheck /> */}
                  <span className="flex flex-row place-items-center gap-4">
                    {<item.group.icon className="size-8" />}
                    <div className="!grow-0 text-[18px] font-medium leading-[24px]">
                      {item.heading}
                    </div>
                  </span>
                  <p className="text-[16px] text-[#4A5264]">{item.title}</p>
                </div>
                <Button
                  variant="outline"
                  className="h-[40px] w-[140px] place-self-center rounded-md !bg-primary-gradient !text-white hover:shadow"
                >
                  Try Now
                </Button>
              </motion.div>
            </AnimatePresence>
          ))}
        </div>
      )}
    </div>
  );
};

export default Templates;
