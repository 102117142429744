import { useUserConfig } from "@/hooks/useUserConfig";
import { InputtedData } from "../store";
import objectHash from "object-hash";
import { pick } from "lodash";

export interface Filter {
  isPinned?: boolean;
  naturalFilters?: {
    natural_query: string;
    query_title: string;
  };
  name?: string;
  sideBarInput: InputtedData;
  currentSearchPayload: any;
  valueLabels: Record<string, string>;
  assistantInput: string;
  savedDate?: string;
}

interface UseFilterManager {
  save: (newFilter: Filter) => void;
  list: () => Filter[];
  remove: (index: number) => void;
  update: (index: number, filter: Filter) => void;
  isSaved: (filter: Filter) => boolean;
  isSaving: boolean;
  isDeleting: boolean;
}

export const useFilterManager = (): UseFilterManager => {
  const [userConfigQuery, userConfigMutation] = useUserConfig(
    "people_search_filters",
  );

  const listFilters = (): Filter[] => {
    return (userConfigQuery.data?.config?.people_search_filters ||
      []) as Filter[];
  };

  const saveFilter = (newFilter: Filter): void => {
    const existingFilters = listFilters();
    const filterWithDate = {
      ...newFilter,
      savedDate: new Date().toISOString(),
    };

    userConfigMutation.mutate([filterWithDate, ...existingFilters]);
  };

  const removeFilter = (index: number): void => {
    const existingFilters = listFilters();
    const updatedFilters = existingFilters.filter((_, i) => i !== index);
    userConfigMutation.mutate(updatedFilters);
  };

  const isSaved = (filter: Filter): boolean => {
    const existingFilters = listFilters();
    const filterHash = objectHash(
      pick(filter, [
        "naturalFilters",
        "sideBarInput",
        "currentSearchPayload",
        "assistantInput",
      ]),
    );
    return existingFilters.some(
      (savedFilter) =>
        objectHash(
          pick(savedFilter, [
            "naturalFilters",
            "sideBarInput",
            "currentSearchPayload",
            "assistantInput",
          ]),
        ) === filterHash,
    );
  };
  const updateAtIndex = (index: number, newFilter: Filter) => {
    const existingFilters = listFilters();
    userConfigMutation.mutate(existingFilters.with(index, newFilter));
  };
  return {
    save: saveFilter,
    list: listFilters,
    remove: removeFilter,
    update: updateAtIndex,
    isSaved,
    isSaving: userConfigMutation.isPending,
    isDeleting: userConfigMutation.isPending,
  };
};
