import { Button, ButtonGroup, Text } from "@chakra-ui/react";
import Icons from "../Icons";
import Sidebar from "../PeopleSearch/Sidebar";

const RefineOptions = ({
  callback,
}: {
  callback: (showPrevious?: boolean) => void;
}) => {
  return (
    <>
      <div className="flex items-center justify-center flex-col gap-2">
        <Text className="text-[18px] leading-[34px] font-[600] text-[#0F0D1C]">
          Looking for better search results? Add more filter
        </Text>
      </div>
      <ButtonGroup>
        <Button
          variant={"outline"}
          className="!text-[16px]"
          leftIcon={<Icons.LeftArrowIcon />}
          onClick={() => callback(true)}
        >
          Back
        </Button>
        <Button
          className="!bg-[#C03EDC] !text-white !text-[16px]"
          rightIcon={<Icons.RightArrowIcon />}
          onClick={() => callback()}
        >
          Continue
        </Button>
      </ButtonGroup>
      <div className="w-[90%]">
        <Sidebar isForAutoPilot />
      </div>
    </>
  );
};

export default RefineOptions;
