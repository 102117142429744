import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { userStore } from "@/stores/user.store";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const steps = [Step1, Step2, Step3, Step4];

const Walkthrough = ({
  needHelp,
  setNeedHelp,
}: {
  needHelp: boolean;
  setNeedHelp: Dispatch<SetStateAction<boolean>>;
}) => {
  const currentUser = userStore((state) => state.currentUser);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeStep, setActiveStep] = useState(0);

  const handlePrevious = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      setActiveStep(0);
      setNeedHelp(false);
      onClose();
    }
  };

  const CurrentStep = steps[activeStep];

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem(
      `hasVisitedAutopilotPage-${currentUser._id}`,
    );
    if (!hasVisitedBefore) {
      onOpen();
      localStorage.setItem(
        `hasVisitedAutopilotPage-${currentUser._id}`,
        "true",
      );
    }
  }, [onOpen]);

  useEffect(() => {
    needHelp && onOpen();
  }, [needHelp]);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay
        bg="rgba(0, 0, 0, 0.1)"
        backdropFilter="revert"
        backdropInvert="80%"
        backdropBlur="2px"
      />
      <ModalContent>
        <ModalHeader className="h-[600px] m-auto">Autopilot</ModalHeader>
        <ModalBody minHeight="500px" display="flex" flexDirection="column">
          <CurrentStep />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size="sm"
            mr={3}
            isDisabled={activeStep === 0}
            onClick={handlePrevious}
          >
            Previous
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Walkthrough;
