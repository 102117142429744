import { useEffect, useMemo, useReducer, useState } from "react";
import { Spinner, useToast } from "@chakra-ui/react";

import KeySelect from "@/components/Common/KeySelect";
import PersanaAccordion from "@/components/Common/PersanaAccordion";
import WatchTutorial from "@/components/Common/WatchTutorial";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import {
  useProviderList,
  useSendsparkCampaigns,
  useSendsparkWorkspaces,
} from "@/hooks/useProviderData";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";

const SendsparkProspect = () => {
  const toast = useToast();
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const { data: providerList } = useProviderList();
  const isUserApiKeyExists = useMemo(() => {
    if (!providerList?.data) return false;

    return !!providerList.data?.filter(
      (provider) => provider.name === "sendsparkCredentials",
    ).length;
  }, [providerList]);

  const [fetchWorkspaces, setFetchWorkspaces] = useState(false);
  const [fetchCampaigns, setFetchCampaigns] = useState(false);
  const [workspaces, setWorkspaces] = useState<Option[]>([]);
  const [campaigns, setCampaigns] = useState<Option[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Option | null>(
    null,
  );
  const [selectedCampaign, setSelectedCampaign] = useState<Option | null>(null);
  const {
    isLoading: isLoadingCampaigns,
    data: sendsparkCampaigns,
    refetch,
  } = useSendsparkCampaigns(fetchCampaigns, selectedWorkspace?.value || "");
  const { isLoading: isLoadingWorkspaces, data: sendsparkWorkspaces } =
    useSendsparkWorkspaces(fetchWorkspaces);
  const initialSlateValue = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  const initialState = {
    contactName: initialSlateValue,
    contactEmail: initialSlateValue,
    company: initialSlateValue,
    jobTitle: initialSlateValue,
    backgroundUrl: initialSlateValue,
  };

  function reducer(state: any, action: any) {
    return { ...state, [action.key]: action.value };
  }
  const [prospectDetails, dispatch] = useReducer(reducer, initialState);
  const updateDetails = (key: string, value: any) => dispatch({ key, value });

  const prospectOptions = [
    {
      name: "Contact Name",
      state: prospectDetails.contactName,
      description: "",
      setState: (value: any) => {
        updateDetails("contactName", value);
      },
    },
    {
      name: "Contact Email",
      description: "",
      state: prospectDetails.contactEmail,
      setState: (value: any) => {
        updateDetails("contactEmail", value);
      },
    },
    {
      name: "Company Name",
      description: "",
      state: prospectDetails.company,
      setState: (value: any) => {
        updateDetails("company", value);
      },
    },
    {
      name: "Job Title",
      description: "",
      state: prospectDetails.jobTitle,
      setState: (value: any) => {
        updateDetails("jobTitle", value);
      },
    },
    {
      name: "Background URL",
      description: "",
      state: prospectDetails.backgroundUrl,
      setState: (value: any) => {
        updateDetails("backgroundUrl", value);
      },
    },
  ];

  const updateState = useEnrichStore((state) => state.updateState);

  const handleWorkspaceChange = (selected: any) => {
    setSelectedWorkspace(selected);
  };

  const handleCampaignChange = (selected: any) => {
    setSelectedCampaign(selected);
  };

  useEffect(() => {
    if (selectedWorkspace?.value) {
      if (sendsparkCampaigns?.success) {
        const formattedData =
          sendsparkCampaigns.data?.data?.response?.data?.map((item: any) => ({
            value: item._id,
            label: item.name,
          }));

        setCampaigns(formattedData);
      } else if (sendsparkCampaigns && !sendsparkCampaigns?.success) {
        setCampaigns([]);
        toast({
          position: "top-right",
          title: "Error",
          description: sendsparkCampaigns?.error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [sendsparkCampaigns, selectedWorkspace]);

  useEffect(() => {
    if (isUserApiKeyExists) {
      if (sendsparkWorkspaces?.success) {
        const formattedData = sendsparkWorkspaces?.data?.workspaces?.map(
          (item: any) => ({
            value: item.id,
            label: item.name,
          }),
        );

        setWorkspaces(formattedData);
      } else if (sendsparkWorkspaces && !sendsparkWorkspaces?.success) {
        setWorkspaces([]);
        toast({
          position: "top-right",
          title: "Error",
          description: sendsparkWorkspaces?.error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [sendsparkWorkspaces, isUserApiKeyExists]);

  useEffect(() => {
    if (selectedWorkspace?.value) {
      if (!fetchCampaigns) {
        setFetchCampaigns(true);
      } else {
        refetch();
        setSelectedCampaign(null);
      }
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    isUserApiKeyExists && setFetchWorkspaces(true);
  }, [isUserApiKeyExists]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentMetaData | undefined;

      if (enrichmentData) {
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        // @ts-ignore
        const slateValueDetails = enrichmentData.prospectDetailsBySlateValue;

        for (const [key, value] of Object.entries(slateValueDetails)) {
          updateDetails(key, value);
        }

        if (workspaces?.length > 0) {
          const selectedWorkspace = workspaces.find(
            // @ts-ignore
            (item) => item.value === enrichmentData.workspaceId,
          );
          selectedWorkspace && setSelectedWorkspace(selectedWorkspace);
        }

        if (workspaces?.length > 0 && campaigns?.length > 0) {
          const selectedCampaign = campaigns.find(
            // @ts-ignore
            (item) => item.value === enrichmentData.campaignId,
          );
          selectedCampaign && setSelectedCampaign(selectedCampaign);
        }
      }
    }
  }, [viewMode, selectedColumnToEdit, workspaces, campaigns]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <WatchTutorial
          subText="Adds a prospect to a video campaign. This will generate a dynamic video for the prospect based on your chosen campaign."
          link={""}
        />
        <KeySelect
          providerName={"sendsparkCredentials"}
          providerTitle="Sendspark"
          disablePersanaKey={true}
          secretKeyInput={true}
        />

        <div className="mt-5">
          <div className="flex items-center gap-1">
            <p className="mb-1 text-base font-semibold">Workspace ID</p>
            {isLoadingWorkspaces && <Spinner size="sm" />}
          </div>
          <MultiSelectDropdown
            isMulti={false}
            placeholder="choose a workspace"
            closeMenuOnSelect
            options={workspaces || []}
            value={selectedWorkspace}
            onChange={handleWorkspaceChange}
          />
          <div className="flex items-center justify-between">
            <p className="block py-1 text-sm">
              The ID of your Sendspark workspace
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex items-center gap-1">
            <p className="mb-1 text-base font-semibold">Dynamic Campaign ID</p>
            {isLoadingCampaigns && <Spinner size="sm" />}
          </div>

          <MultiSelectDropdown
            isMulti={false}
            placeholder="choose a campaign"
            closeMenuOnSelect
            options={campaigns || []}
            value={selectedCampaign}
            onChange={handleCampaignChange}
          />
          <div className="flex items-center justify-between">
            <p className="block py-1 text-sm">
              The ID of the Dynamic Campaign to add the prospect to
            </p>
          </div>
        </div>
        <PersanaAccordion
          defaultIndex={[0]}
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
            marginTop: "20px",
          }}
          items={[
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Prospect Details
                </button>
              ),

              panel: (
                <div className="space-y-3">
                  {prospectOptions.map((item) => (
                    <div>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {item?.name}
                      </p>
                      <CustomTextEditor
                        editorHeight={"4rem"}
                        slateValue={item?.state}
                        setSlateValue={item?.setState}
                        placeholder={`Start typing or use the dropdown to select a column.`}
                      />
                      <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                        {item.description}
                      </p>
                    </div>
                  ))}
                </div>
              ),
            },
          ]}
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedCampaign || !selectedWorkspace}
        apiPayload={{
          apiKeyName: "sendsparkCredentials",
          workspaceId: selectedWorkspace?.value,
          campaignId: selectedCampaign?.value,
          prospectDetailsBySlateValue: {
            ...prospectDetails,
          },
        }}
      />
    </>
  );
};

export default SendsparkProspect;
