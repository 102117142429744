import { FileRoute } from "@tanstack/react-router";
import TablePreview from "@/pages/TablePreview";

export const Route = new FileRoute("/table-preview/").createRoute({
  component: TablePreviewPage,
});

function TablePreviewPage() {
  return <TablePreview />;
}

export default TablePreviewPage;
