import { ColDef } from "ag-grid-community";

export const defaultColDef: ColDef = {
  sortable: true,
  cellClass: "flex size-full items-center overflow-hidden",
  filter: true,
  suppressHeaderMenuButton: true,
  getQuickFilterText: (params) => {
    const columnRootName = params.column.getColId()?.split(".")[0];
    return JSON.stringify(params.data?.[columnRootName] ?? params.value);
  },
};
