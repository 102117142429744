import SelectLeads from "@/assets/images/job-change-select-leads.png";
const Step2 = () => {
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <p className="text-lg font-semibold text-gray-800">
        Select the Leads and Start Tracking
      </p>
      <hr className="border-t-2 border-purple-700 my-4" />
      <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
        <img src={SelectLeads} alt="Select Leads" className="rounded-lg mb-4" />
      </div>
      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <p className="text-base font-medium text-gray-800">
          Here's how to start tracking:
        </p>
        <p>
          <span className="font-semibold">1. Choose the leads</span> you wish to
          monitor for job changes.
        </p>
        <p>
          <span className="font-semibold">2. Click "Refresh Job Change"</span>{" "}
          to update and view their current job status details.
        </p>
      </div>
    </div>
  );
};

export default Step2;
