/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnrichmentMetaData } from "@/types/table.types";
import { useEffect, useReducer } from "react";

import PersanaAccordion from "@/components/Common/PersanaAccordion";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import { convertSlateToText } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const initialState = {
  keywords: initialSlateValue,
  locationId: initialSlateValue,
  companyIds: initialSlateValue,
  jobType: initialSlateValue,
  salary: initialSlateValue,
  experienceLevel: initialSlateValue,
};

interface EnrichmentDataType extends EnrichmentMetaData {
  payload: typeof initialState;
}

type State = typeof initialState;

type Action = {
  key: keyof State;
  value: typeof initialSlateValue;
};

function reducer(state: State, action: Action) {
  return { ...state, [action.key]: action.value };
}

const JobSearch = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const [payloadDetails, dispatch] = useReducer(reducer, initialState);
  const updateDetails = (key: Action["key"], value: Action["value"]) =>
    dispatch({ key, value });

  const otherPayloads = [
    {
      name: "Job Type",
      state: payloadDetails.jobType,
      description:
        "it could be one of these; fullTime, partTime, contract, internship",
      setState: (value: any) => {
        updateDetails("jobType", value);
      },
    },
    {
      name: "Salary",
      state: payloadDetails.salary,
      description:
        "it could be one of these; 40k+, 60k+, 80k+, 100k+, 120k+, 140k+, 160k+, 180k+, 200k+ ",
      setState: (value: any) => {
        updateDetails("salary", value);
      },
    },
    {
      name: "Experience Level",
      state: payloadDetails.experienceLevel,
      description:
        "it could be one of these; internship, associate, director, entryLevel, midSeniorLevel. executive",
      setState: (value: any) => {
        updateDetails("experienceLevel", value);
      },
    },
  ];

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType | undefined;
      if (enrichmentData?.payload) {
        Object.entries(enrichmentData.payload).forEach(([key, value]) => {
          dispatch({ key: key as keyof State, value });
        });
      }
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <div className="my-3">
          <p className="pb-1.5 text-[0.95rem] font-medium">
            <sup>*</sup>Keywords{" "}
          </p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={payloadDetails.keywords}
            setSlateValue={(value: any) => updateDetails("keywords", value)}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>
        <div className="my-3">
          <p className="pb-1.5 text-[0.95rem] font-medium">Location ID </p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={payloadDetails.locationId}
            setSlateValue={(value: any) => updateDetails("locationId", value)}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>
        <div className="my-3">
          <p className="pb-1.5 text-[0.95rem] font-medium">Company ID</p>
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={payloadDetails.companyIds}
            setSlateValue={(value: any) => updateDetails("companyIds", value)}
            placeholder={`Start typing or use the dropdown to select a column.`}
          />
        </div>

        <PersanaAccordion
          defaultIndex={[0]}
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
            marginTop: "20px",
          }}
          items={[
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add More Details (Optional)
                </button>
              ),

              panel: (
                <div className="space-y-3">
                  {otherPayloads.map((item) => (
                    <div key={item.name}>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {item?.name}
                      </p>
                      <CustomTextEditor
                        editorHeight={"4rem"}
                        slateValue={item?.state}
                        setSlateValue={item?.setState}
                        placeholder={`Start typing or use the dropdown to select a column.`}
                      />
                      <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                        {item.description}
                      </p>
                    </div>
                  ))}
                </div>
              ),
            },
          ]}
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!convertSlateToText(payloadDetails.keywords).text}
        apiPayload={{
          payload: payloadDetails,
        }}
      />
    </>
  );
};

export default JobSearch;
