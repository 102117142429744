import InstantlyLogo from "@/assets/images/instantly.jpg";
import APIKeyIntegration from "./APIKeyIntegration";

const InstantlyIntegration = () => {
  return (
    <APIKeyIntegration
      providerName={"instantlyApiKey"}
      providerTitle="Instantly"
      providerIcon={InstantlyLogo}
    />
  );
};

export default InstantlyIntegration;
