/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import tableService from "@/services/table.service";

export const useRunningProcesses = (tableId: string) => {
  return useQuery({
    queryKey: ["table-running-processes", tableId],
    queryFn: ({ signal }) => {
      return tableService.getRunningProcesses({
        tableId,
        signal,
      });
    },
    refetchOnMount: true,
  });
};

export const useRunningProcessesByTypes = (types: string) => {
  return useQuery({
    queryKey: ["table-running-processes", types],
    queryFn: ({ signal }) => {
      return tableService.getRunningProcessesByTypes({
        signal,
        types,
      });
    },
    refetchOnMount: true,
  });
};
