import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import Sidebar from "@/components/PeopleSearch/Sidebar";

import leadService from "@/services/leads.service";
import tableService from "@/services/table.service";

import { userStore } from "@/stores/user.store";
import { TableData } from "@/types/table.types";
import { useSearchLeadsStore } from "@/components/PeopleSearch/store";
import { convertSearchPeoplePayload, filterValidObjKeys } from "@/utils";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import Icons from "@/components/Icons";

type Props = {
  isConfigured: boolean;
  isOpen: boolean;
  tableData?: TableData;
  onClose: (isRefetch: boolean) => void;
};

const frequencyList = [
  {
    label: "Daily",
    value: "1",
  },
  {
    label: "Weekly",
    value: "7",
  },
  {
    label: "Fortnightly",
    value: "15",
  },
  {
    label: "Monthly",
    value: "30",
  },
];

const Configurations = ({
  isConfigured,
  isOpen,
  tableData,
  onClose,
}: Props) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;
  const maxLeads = planType?.toLocaleLowerCase() === "free" ? 25 : 1000;
  const [isLoading, setIsLoading] = useState(false);
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );
  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.sideBarInput,
  );
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);

  const handleAutoPilot = async () => {
    setIsLoading(true);
    const convertedPayload = convertSearchPeoplePayload(currentSearchPayload);
    const validInputtedData = filterValidObjKeys(convertedPayload);
    const payload = {
      ...validInputtedData,
      frequencyToUpdate: frequencyToUpdate?.value,
      isForFindEmail: false,
      selectedLeads: [],
      customSelectionNumber: 0,
      isSelectAll: true,
      isForAutoPilot: true,
      totalEntries: maxLeads,
    };
    const response = await leadService.runAutoPilot(
      payload,
      "create-auto-pilot-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
    onClose(true);
  };

  const handleTriggerFlow = async () => {
    setIsLoading(true);
    await leadService.runAutoPilot(
      undefined,
      "update-auto-pilot-table",
      tableData?._id || "",
    );
    window.open(`/table/${tableData?._id}`, "_blank");
    onClose(true);
    setIsLoading(false);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const convertedPayload = convertSearchPeoplePayload(currentSearchPayload);
    const validInputtedData = filterValidObjKeys(convertedPayload);
    const response = await tableService.updateTableDataV2(
      tableData?._id || "",
      {
        metaData: {
          ...validInputtedData,
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      handleTriggerFlow();
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    onClose(true);
    setIsLoading(false);
  };

  useEffect(() => {
    const freq = frequencyList?.find(
      (item) => item.value === tableData?.metaData?.frequencyToUpdate,
    ) as Option;

    setFrequencyToUpdate(freq);

    updateLeadState({
      peopleSearchPayload: tableData?.metaData || null,
    });
  }, [tableData, updateLeadState]);

  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Auto Pilot - Filter Preferences</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="custom-scrollbar max-h-[500px] min-h-[500px] overflow-y-auto">
          <VStack spacing={4} align="stretch" marginBottom={4}>
            {isConfigured && (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isLoading}
              >
                Trigger Flow
              </Button>
            )}

            <Text fontSize="md" fontWeight="semibold">
              How frequently you want to fetch the new leads?
            </Text>
            <MultiSelectDropdown
              isMulti={false}
              placeholder="Frequency to update the engagement data"
              closeMenuOnSelect
              options={frequencyList}
              value={frequencyToUpdate}
              onChange={(selected) => setFrequencyToUpdate(selected as Option)}
            />
          </VStack>
          <Sidebar isForAutoPilot />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size={"sm"}
            mr={3}
            onClick={() => onClose(false)}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isLoading}
            isDisabled={!frequencyToUpdate}
            onClick={() =>
              isConfigured ? updatePreferences() : handleAutoPilot()
            }
          >
            {isConfigured ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Configurations;
