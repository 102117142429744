import { FileRoute } from "@tanstack/react-router";

import TeamOverview from "@/pages/TeamOverview";

export const Route = new FileRoute("/team-overview/").createRoute({
  component: TeamOverviewPage,
});

function TeamOverviewPage() {
  return <TeamOverview />;
}

export default TeamOverviewPage;
